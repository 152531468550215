import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { Journal, JournalButton } from 'common/components';
import { useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { SntTermsConfirmationFilters } from './SntTermsConfirmationFilters';
import { getSntTermsConfirmationColumns } from './getSntTermsConfirmationColumns';
import { queryAdminSntConfirmationOverdue, queryAdminSntConfirmatioOverdueReport } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Modal } from 'services/modal';
import { ConfirmationHistoryModal } from '../confirmation-history/ConfirmationHistoryModal';
import { ChangeConfirmationDateModal } from '../change-confirmation-date/ChangeConfirmationDateModal';
import { formatFromDate } from 'services/date-time';
import { SessionStorageService } from 'services/storage/StorageService';
import { ADMIN_PERMISSION } from 'common/constants';
import { useUser } from 'common/hooks';
import { SntStatus } from 'features/snt';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.sntId;

const filterStorageKey = 'sntTermsConfirmation';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, журнал "Фиксация нарушения сроков подтверждения/отклонения СНТ получателем"
 * arm/snt-terms-confirmation
 */
export const SntTermsConfirmation: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const user = useUser();

  const [modal, setModal] = useState<{
    modal?: 'history' | 'changeDate';
    selection?: Array<any>;
  }>({});

  const columns = useMemo(() => getSntTermsConfirmationColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminSntConfirmationOverdue, {
    filter: SessionStorageService.get(filterStorageKey) || {
      dateFrom: formatFromDate(new Date()),
      dateTo: formatFromDate(new Date()),
    },
    pagination: initialPagination,
  });

  const exportJournal = useCallback(
    async (format: 'XLSX' | 'CSV') => {
      try {
        await queryAdminSntConfirmatioOverdueReport({
          filter: queryProps.filter,
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    },
    [queryProps.filter]
  );

  const headerButtons = useMemo(
    (): Array<JournalButton> => [
      {
        id: 1,
        label: loc('common.button.exportExcel'),
        onClick: (selection: Array<any>) => exportJournal('XLSX'),
      },
      {
        id: 2,
        label: loc('common.button.exportCsv'),
        onClick: (selection: Array<any>) => exportJournal('CSV'),
      },
    ],
    [loc, exportJournal]
  );

  const actionButtons = useMemo(
    (): Array<JournalButton> => [
      {
        id: 1,
        label: loc('sntTerms.changeConfirmationDate'),
        onClick: (selection: Array<any>) => setModal({ modal: 'changeDate', selection }),
        multiselect: true,
        disabled: (selection) => {
          if (!user.checkAdminPermission(ADMIN_PERMISSION.SNT_CONFIRMATION_CONTROL)) {
            return true;
          }
          const isSameDate = selection.every(
            (row) => row.confirmDeadlineDate && row.confirmDeadlineDate === selection[0]?.confirmDeadlineDate
          );
          if (!isSameDate) {
            return true;
          }
          const correctStatus = selection.every((row) =>
            [SntStatus.DELIVERED, SntStatus.NOT_VIEWED, SntStatus.CONFIRMED_BY_OGD].includes(row.status)
          );
          if (!correctStatus) {
            return true;
          }
          return false;
        },
      },
      {
        id: 2,
        label: loc('sntTerms.confirmationHistory'),
        onClick: (selection: Array<any>) => setModal({ modal: 'history', selection }),
      },
    ],
    [loc, user]
  );

  const closeModal = () => setModal({});

  return (
    <Fragment>
      <Journal
        pageTitle={loc('sntTerms.confirmation')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={SntTermsConfirmationFilters}
        filterInitialOpened
        filterExpandable
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        filterStorageKey={filterStorageKey}
        selectable
      />

      <Modal isOpen={modal.modal === 'history'} onClose={closeModal}>
        <ConfirmationHistoryModal id={modal.selection?.[0] && getRowId(modal.selection[0])} />
      </Modal>

      <Modal isOpen={modal.modal === 'changeDate'} onClose={closeModal}>
        <ChangeConfirmationDateModal selection={modal.selection} getRowId={getRowId} />
      </Modal>
    </Fragment>
  );
};
