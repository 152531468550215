import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getBioRiskyUsersColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'iin',
    label: loc('bioRisky.userIin'),
  },
  {
    name: 'userFio',
    label: loc('bioRisky.userFio'),
    width: 350,
  },
  {
    name: 'riskCategory',
    label: loc('bioRisky.riskCategory'),
    getContent: (row) => (row.riskCategory ? loc(`bioRisky.riskCategory.${row.riskCategory}`) : null),
    width: 250,
  },
  {
    name: 'lastVerificationDate',
    label: loc('bioRisky.userVerDate'),
  },
  {
    name: 'verificationStatus',
    label: loc('bioRisky.userVerStatus'),
    getContent: (row) => (row.verificationStatus ? loc(`bioRisky.verStatus.${row.verificationStatus}`) : null),
    width: 300,
  },
  {
    name: 'invoiceCountWaitVerification',
    label: loc('bioRisky.waitCount'),
  },
  {
    name: 'invoiceCountErrorVerification',
    label: loc('bioRisky.errorCount'),
  },
  {
    name: 'invoiceProcessedCount',
    label: loc('bioRisky.invoiceProcessedCount'),
  },
];
