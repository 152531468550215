import { DataQueryFunction, httpClient } from 'rest';

export const queryVstoreUserSettingsHash: DataQueryFunction = async (variables: any) => {
  const res = await httpClient.get(`/vstore/rest/user-settings/hash`, {
    params: { ...variables },
    responseType: 'text',
  });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
