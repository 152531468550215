import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './RemainderGoodsArm.module.scss';
import { Button, DataGrid, Heading, PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';

type Props = {
  className?: string;
  onClose?: () => void;
  row: any;
};

const columnStyles = [{ width: '400px' }, { width: '500px' }];

export const RemainderGoodsArm: React.FC<Props> = ({ className, onClose, row }) => {
  const { loc } = useLocale();

  const data = useMemo(
    () => [
      [loc('common.labels.tin'), row.tin],
      [loc('common.labels.npName'), row.npName],
      [loc('common.labels.productId'), row.productId],
      [loc('common.labels.productName'), row.name],
      [loc('common.labels.productNameInImportDoc'), row.productNameInImportDoc],
      [loc('common.labels.gsvsCode'), row.gsvsCode],
      [loc('common.labels.tnvedCode'), row.tnvedCode],
      [loc('common.labels.gtin'), row.gtin],
      [loc('common.labels.pinCode'), row.pinCode],
      [loc('common.labels.docNumber'), row.docNumber],
      [loc('common.labels.productDocNumber'), row.productDocNumber],
      [loc('common.labels.movementDoc'), row.regNumber],
      [loc('common.labels.numberInMovementDoc'), row.productRegNumber],
      [loc('common.labels.manufactureOrImportDocNumber'), row.manufactureOrImportDocNumber],
      [loc('common.labels.productNumberInImportDoc'), row.productNumberInImportDoc],
      [loc('common.labels.countryName'), row.countryName],
      [loc('common.labels.physicalLabel'), row.physicalLabel],
      [loc('common.labels.virtualLabelType'), loc('remainder.vmType.' + row.virtualLabelType)],
      [loc('common.labels.price'), row.price],
      [loc('common.labels.quantity'), row.quantity],
      [loc('common.labels.reserveQuantity'), row.reserveQuantity],
      [loc('common.labels.freeQuantity'), row.freeQuantity],
      [loc('common.labels.measureUnitName'), row.measureUnitName],
      [loc('common.labels.storeName'), row.storeName],
      [loc('common.labels.storeId'), row.storeId],
      [loc('common.labels.dutyType'), loc('remainder.dutyType.' + row.dutyType)],
    ],
    [row] // eslint-disable-line
  );

  const printCard = () => {
    const widow = window.open('', 'PRINT', 'height=400,width=800');
    const div = document.getElementsByClassName('remainder-card');
    if (widow && div) {
      widow.document.write('<html lang="ru"><head><title>' + loc('admin.menu.REMAINS') + '</title>');
      widow.document.write(document.head.innerHTML);
      // widow.document.write('<head/>');
      widow.document.write('<body >');
      widow.document.write(
        '<style>.column {\n' +
          '    display: -webkit-inline-flex;\n' +
          '    width: 300px !important;\n' +
          '    display: inline-flex;\n' +
          '}</style>'
      );
      widow.document.write('<h1>' + loc('admin.menu.REMAINS') + '</h1>');
      widow.document.write(div[0].innerHTML);
      widow.document.write('</body></html>');

      widow.focus(); // necessary for IE >= 10*/

      widow.print();
      widow.close(); // widow.document.close(); // necessary for IE >= 10
    }
    return true;
  };

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('remainder.prodView')}</PageTitle>
      <BackButton onClick={onClose} />
      <div className={s.inner}>
        <Heading tag="h2">{loc('common.title.commonInfo')}</Heading>
        <div className="mb-24">
          <Button onClick={() => printCard()} variant="secondary">
            {loc('common.button.print')}
          </Button>
        </div>
        <DataGrid className="remainder-card" columnClassName="column" data={data} columnStyles={columnStyles} />
      </div>
    </div>
  );
};
