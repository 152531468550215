import React, { useCallback } from 'react';
import { queryOgdList } from 'rest/api/dictionary';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getOgdOptions({ loc }: LocaleStore, preProcessOptions?: (options: Array<any>) => Array<any>) {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await queryOgdList();
  if (Array.isArray(res.data)) {
    if (typeof preProcessOptions === 'function') {
      return preProcessOptions(res.data);
    } else {
      return [...emptyOptions, ...res.data];
    }
  }
  return emptyOptions;
}

type Props = FormSelectProps & {
  withoutThirdLevel?: boolean;
  preProcessOptions?: (options: Array<any>) => Array<any>;
};

export const FormOGDSelect: React.FC<Props> = ({ withoutThirdLevel = false, preProcessOptions, ...rest }) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionLabel = useCallback(
    (data) => {
      return data.label ?? `(${data.code}) ${choose([data.nameRu, data.nameKz])}`;
    },
    [choose]
  );

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const prepOptions = async () => {
    const options = await getOgdOptions(locale, preProcessOptions);
    if (withoutThirdLevel) {
      return options.filter((item) => item.level !== 'DISTRICT');
    } else {
      return options;
    }
  };

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => prepOptions()}
      isSearchable={true}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};
