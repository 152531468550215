export enum EeacExStatus {
  /**
   * Отправлено
   */
  SENT = 'SENT',

  /**
   * Получено
   */
  RECEIVED = 'RECEIVED',

  /**
   * Принято в обработку
   */
  IN_PROCESSING = 'IN_PROCESSING',

  /**
   * Ошибка
   */
  ERROR = 'ERROR',

  /**
   *  Сведения добавлены
   */
  DOCUMENT_ADDED = 'DOCUMENT_ADDED',

  /**
   * Сведения изменены
   */
  DOCUMENT_CHANGED = 'DOCUMENT_CHANGED',

  /**
   * Сведения обработаны
   */
  DOCUMENT_CANCELED = 'DOCUMENT_CANCELED',

  /**
   * Сведения удалены
   */
  DOCUMENT_EXCLUDED = 'DOCUMENT_EXCLUDED',

  /**
   * Документ обработан для операций
   */
  DOCUMENT_PROCESSED = 'DOCUMENT_PROCESSED',

  /**
   * Сведения предоставлены
   */
  DATA_PROVIDED = 'DATA_PROVIDED',

  /**
   * Сведения отсутствуют
   */
  DATA_ABSENT = 'DATA_ABSENT',

  /**
   * Данные не могут быть предоставлены
   */
  INFORMATION_CANNOT_BE_PROVIDED = 'INFORMATION_CANNOT_BE_PROVIDED',

  /**
   * Сведения не могут быть обработаны
   */
  PROCESSING_ERROR = 'PROCESSING_ERROR',

  /**
   * Время ожидания ответа истекло
   */
  WAITING_TIME_EXPIRED = 'WAITING_TIME_EXPIRED',
}

export enum EecDocumentGroup {
  /**
   * Входящие документы MSG.004-008
   */
  TRACEABILITY_DOCUMENT_INPUT = 'TRACEABILITY_DOCUMENT_INPUT',

  /**
   * Исходящие документы MSG.004008
   */
  TRACEABILITY_DOCUMENT_OUTPUT = 'TRACEABILITY_DOCUMENT_OUTPUT',

  /**
   * Входящий запрос по предшествующим операциям
   */
  OPERATIONS_REQUEST_INPUT = 'OPERATIONS_REQUEST_INPUT',

  /**
   * Исходящий запрос по предшествующим операциям
   */
  OPERATIONS_REQUEST_OUTPUT = 'OPERATIONS_REQUEST_OUTPUT',

  /**
   * Запрос сведений в целях осуществления контроля и мониторинга исполнения Соглашения
   */
  MONITORING_REQUEST = 'MONITORING_REQUEST',
}

export enum EecTransactionStatus {
  /**
   * Транзакция создана, но еще не в процессе.
   * Данный статус нужен для повторной отправки
   */
  CREATED = 'CREATED',

  /**
   * Транзакция в процессе
   */
  IN_PROCESS = 'IN_PROCESS',

  /**
   * Транзакция завершена
   */
  COMPLETED = 'COMPLETED',

  /**
   * Сигналы в ответ на результат отправлены, но нужно ждать 1 минуту, что не пришел сигнал ERR
   */
  WAIT_COMMIT = 'WAIT_COMMIT',

  /**
   * Во время работы возникли ошибки (ФЛК, системные и прочие)
   */
  ERROR = 'ERROR',

  /**
   * Время ожидания по транзакции истекло
   */
  EXPIRED = 'EXPIRED',

  /**
   * Количество попыток отправки исчерпано
   */
  ATTEMPTS_EXHAUSTED = 'ATTEMPTS_EXHAUSTED',
}
