import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './RegContract.module.scss';
import ss from '../../Registration.module.scss';
import { useLocale } from 'services/l10n';
import { Form, Formik, FormikHelpers } from 'formik';
import { ButtonT2 } from 'ui';
import { mutationRegistrationValidate } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { RegContractOperator } from './RegContractOperator';
import { RegContractAttorney } from './RegContractAttorney';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';
import { RegActionPage, RegSubType } from 'features/registration/types';

type Props = RegActionPage & {
  className?: string;
};

export const RegContract: React.FC<Props> = observer(({ className, onBack, onNext, regStore }) => {
  const locale = useLocale();
  const { loc } = locale;

  const formTabs: { name: RegSubType; label: string }[] = useMemo(
    () =>
      [
        {
          name: RegSubType.OPERATOR,
          label: loc('reg.OPERATOR'),
        },
        {
          name: RegSubType.ATTORNEY,
          label: loc('reg.ATTORNEY'),
        },
      ].filter((tab) => regStore.selectedSubTypes[tab.name]),
    [regStore.selectedSubTypes] // eslint-disable-line
  );

  const [currentTab, setCurrentTab] = useState<RegSubType>(formTabs[0]?.name);

  const initialValues = useMemo(() => {
    const values: any = {
      operator: {
        contractors: [{}],
        documents: [{}],
      },
      attorney: {
        documents: [{}],
      },
      mainDocumentIndex: 0,
      mainAttorneyDocIndex: 0,
    };
    return values;
  }, []); // eslint-disable-line

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      const regData = {
        ...regStore.regData,
        ...values,
      };
      await mutationRegistrationValidate({ data: regData });
      regStore.setRegData(regData);
      onNext();
    } catch (error) {
      console.error('form error', error);
      ToastService.showError(solveErrorMessage(error));
      actions.setSubmitting(false);
    }
  };

  const schema = useMemo(() => {
    if (!formTabs.length) {
      return undefined;
    }

    const operatorSchema = yup.object({
      operator: yup.object({
        startDate: yup.string().required(loc('common.error.requiredField')),
        // endDate: yup.string().required(loc('common.error.requiredField')),
        contractors: yup
          .array(
            yup.object({
              iin: yup.string().required(loc('common.error.requiredField')),
              name: yup.string().required(loc('common.error.requiredField')),
              share: yup.number().required(loc('common.error.requiredField')),
            })
          )
          .min(1, loc('common.error.requiredField')),
        documents: yup
          .array(
            yup.object({
              agreementType: yup.string().required(loc('common.error.requiredField')),
              contractType: yup.string().required(loc('common.error.requiredField')),
              num: yup.string().required(loc('common.error.requiredField')),
              startDate: yup.string().required(loc('common.error.requiredField')),
            })
          )
          .min(1, loc('common.error.requiredField')),
        _shareSum: yup.number().equals([100], loc('reg.share100')),
      }),
    });

    const attorneySchema = yup.object({
      attorney: yup.object({
        startDate: yup.string().required(loc('common.error.requiredField')),
        endDate: yup.string().required(loc('common.error.requiredField')),
        iin: yup.string().required(loc('common.error.requiredField')),
        name: yup.string().required(loc('common.error.requiredField')),
        documents: yup
          .array(
            yup.object({
              contractType: yup.string().required(loc('common.error.requiredField')),
              num: yup.string().required(loc('common.error.requiredField')),
              startDate: yup.string().required(loc('common.error.requiredField')),
            })
          )
          .min(1, loc('common.error.requiredField')),
      }),
    });

    if (formTabs.length === 2) {
      return operatorSchema.concat(attorneySchema);
    } else {
      return formTabs[0].name === RegSubType.OPERATOR ? operatorSchema : attorneySchema;
    }
  }, [formTabs, loc]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={clsx(ss.container, s.subTabs)}>
        {formTabs.map((tab) => (
          <div
            key={tab.name}
            className={clsx(s.tab, {
              [s.activeTab]: tab.name === currentTab,
            })}
            onClick={() => setCurrentTab(tab.name)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <hr className={ss.hr} />
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={clsx(s.form)}>
              {currentTab === RegSubType.OPERATOR && <RegContractOperator />}
              {currentTab === RegSubType.ATTORNEY && <RegContractAttorney />}
              <div className={ss.actions}>
                <ButtonT2 size="large" variant="secondary" onClick={() => onBack()}>
                  {loc('reg.back')}
                </ButtonT2>
                <ButtonT2 type="submit" size="large" loading={isSubmitting}>
                  {loc('reg.continue')}
                </ButtonT2>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});
