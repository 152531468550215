import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './NotBioTaxpayerModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormTextarea, FormValueProps } from 'ui';
import { mutationAdminNotBiometricsAdd } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { FormAutocompleteIIN } from 'common/components';
import { resolveTaxpayerFio } from 'common/helpers/taxpayer';

type Props = ModalChildrenProps<boolean> & {
  className?: string;
};

export const NotBioTaxpayerModal: React.FC<Props> = ({ className, onClose }) => {
  const locale = useLocale();
  const { loc } = locale;

  const sibmitHandler = async (values: any, form: FormikHelpers<any>) => {
    try {
      const res = await mutationAdminNotBiometricsAdd({ body: values });
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      form.setSubmitting(false);
    }
  };

  const onIINChange = ({ value, name, form }: FormValueProps<any>) => {
    form.setFieldValue(name, value?.tin || '');
    form.setFieldValue('fio', resolveTaxpayerFio(value, locale));
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('notBio.add')}</ModalHeader>
      <Formik<any> initialValues={{}} onSubmit={sibmitHandler}>
        {({ values, isSubmitting }) => (
          <Form>
            <Fragment>
              <ModalContent className={s.content}>
                <FormField name="iin" label={loc('common.labels.iinOnly')}>
                  <FormAutocompleteIIN onValueChange={onIINChange} />
                </FormField>
                <FormField name="fio" label={loc('common.labels.fio')} readOnly>
                  <FormTextarea rows={3} />
                </FormField>
              </ModalContent>
              <ModalFooter>
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
                <Button type="submit" size="small" disabled={!(values.iin && values.fio)} loading={isSubmitting}>
                  {loc('common.button.save')}
                </Button>
              </ModalFooter>
            </Fragment>
          </Form>
        )}
      </Formik>
    </div>
  );
};
