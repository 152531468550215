import { contentYesNo } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecTransactionJournalColumns = ({ locale }: { locale: LocaleStore }): Array<TableColumn> => {
  const { loc } = locale;
  return [
    {
      name: 'messageId',
      label: loc('eaecActions.messageId'),
      width: 300,
    },
    {
      name: 'docId',
      label: loc('eaecActions.documentId'),
      width: 300,
    },
    {
      name: 'procedureId',
      label: loc('eaecActions.procedureId'),
      width: 300,
    },
    {
      name: 'conversationId',
      label: loc('eaecActions.conversationId'),
      width: 300,
    },
    {
      name: 'interactionType',
      label: loc('eaecActions.interactionType'),
      width: 500,
    },
    {
      name: 'sendOrInputDate',
      label: loc('eaecActions.sendOrInputDate'),
    },

    {
      name: 'messageCreateDate',
      label: loc('eaecActions.messageCreateDate'),
    },
    {
      name: 'documentGroup',
      label: loc('eaecActions.documentGroup'),
      getContent: (row) => (row.documentGroup ? loc(`eaecActions.documentGroup.${row.documentGroup}`) : ''),
      width: 300,
    },
    {
      name: 'transactionStatus',
      label: loc('eaecActions.transactionStatus'),
      getContent: (row) => (row.transactionStatus ? loc(`eaecActions.transactionStatus.${row.transactionStatus}`) : ''),
      width: 300,
    },
    {
      name: 'isActive',
      label: loc('eaecActions.isActive'),
      getContent: (row) => contentYesNo(row.isActive, locale),
    },
    {
      name: 'isInitiator',
      label: loc('eaecActions.isInitiator'),
      getContent: (row) => contentYesNo(row.isInitiator, locale),
    },
    {
      name: 'resultExpireDate',
      label: loc('eaecActions.resultExpireDate'),
    },
    {
      name: 'currentExpireDate',
      label: loc('eaecActions.currentExpireDate'),
    },
    {
      name: 'sendCount',
      label: loc('eaecActions.sendCount'),
    },
    {
      name: 'signalDeliveredMessageId',
      label: loc('eaecActions.signalDeliveredMessageId'),
      width: 300,
    },
    {
      name: 'signalDeliveredDate',
      label: loc('eaecActions.signalDeliveredDate'),
    },
    {
      name: 'signalInProcessMessageId',
      label: loc('eaecActions.signalInProcessMessageId'),
      width: 300,
    },
    {
      name: 'signalInProcessDate',
      label: loc('eaecActions.signalInProcessDate'),
    },
    {
      name: 'signalErrorMessageId',
      label: loc('eaecActions.signalErrorMessageId'),
      width: 300,
    },
    {
      name: 'signalErrorDate',
      label: loc('eaecActions.signalErrorDate'),
    },
    {
      name: 'resultMessageId',
      label: loc('eaecActions.resultMessageId'),
      width: 300,
    },
    {
      name: 'resultDocId',
      label: loc('eaecActions.resultDocId'),
      width: 300,
    },
    {
      name: 'resultDate',
      label: loc('eaecActions.resultDate'),
    },
    {
      name: 'resultEnvelopeCode',
      label: loc('eaecActions.resultEnvelopeCode'),
    },
    {
      name: 'resultMessageCode',
      label: loc('eaecActions.resultMessageCode'),
    },
    {
      name: 'signalResultDeliveredMessageId',
      label: loc('eaecActions.signalResultDeliveredMessageId'),
      width: 300,
    },
    {
      name: 'signalResultDeliveredDate',
      label: loc('eaecActions.signalResultDeliveredDate'),
    },
    {
      name: 'signalResultInProcessMessageId',
      label: loc('eaecActions.signalResultInProcessMessageId'),
      width: 300,
    },
    {
      name: 'signalResultInProcessDate',
      label: loc('eaecActions.signalResultInProcessDate'),
    },
    {
      name: 'signalResultErrorMessageId',
      label: loc('eaecActions.signalResultErrorMessageId'),
      width: 300,
    },
    {
      name: 'signalResultErrorDate',
      label: loc('eaecActions.signalResultErrorDate'),
    },
  ];
};
