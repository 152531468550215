import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminAssuranceSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = { ...filter };
  if (criteria.statuses) {
    criteria.statuses = [criteria.statuses];
  }
  if (criteria.corridors) {
    criteria.corridors = [criteria.corridors];
  }

  const res = await httpClient.get(`/admin/assurance/search`, {
    params: {
      criteria: JSON.stringify(clearEmptyFields(criteria)),
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
