import React from 'react';
import clsx from 'clsx';
import s from './RowMark.module.scss';

type Props = {
  className?: string;
  color: string;
};

export const RowMark: React.FC<Props> = ({ className, color }) => {
  return <div className={clsx(className, s.wrapper, s[`color-${color}`])}></div>;
};
