import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';

export const mutationInvoicePrefillFromConfirmDocument: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { data } = variables;

  const formData = convertToFormData({
    invoice: JSON.stringify(data),
  });

  // if (false) {
  //   console.log('return errors');
  //   throw { data: fakeEsfErrors2 }; // eslint-disable-line
  // }

  const res = await httpClient.post('/invoice/prefill-from-confirm-document', formData);
  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
