import React from 'react';
import clsx from 'clsx';
import s from './ArmLayoutHeader.module.scss';
import { UserInfo } from '../user-info';
import { LangToggle } from 'common/components';

type Props = {
  className?: string;
};

export const ArmLayoutHeader: React.FC<Props> = ({ className }) => {
  return (
    <div className={clsx(className, s.wrapper)}>
      <LangToggle className={s.langToggle} />
      <UserInfo />
    </div>
  );
};
