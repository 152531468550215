import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui';

export const RemainderJournalArmColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'npName',
    label: loc('common.labels.npName'),
    style: {
      width: '220px',
    },
  },
  {
    name: 'productId',
    label: loc('common.labels.productId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'name',
    label: loc('common.labels.productName'),
    style: {
      width: '400px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '300px',
    },
  },
  {
    name: 'gsvsCode',
    label: loc('common.labels.gsvsCode'),
    style: {
      width: '200px',
    },
  },

  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'gtin',
    label: loc('common.labels.gtin'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'pinCode',
    label: loc('common.labels.pinCode'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'docNumber',
    label: loc('common.labels.docNumber'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productDocNumber',
    label: loc('common.labels.productDocNumber'),
    style: {
      width: '200px',
    },
  },

  {
    name: 'regNumber',
    label: loc('common.labels.movementDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productRegNumber',
    label: loc('common.labels.numberInMovementDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'manufactureOrImportDocNumber',
    label: loc('common.labels.manufactureOrImportDocNumber'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNumberInImportDoc',
    label: loc('common.labels.productNumberInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'countryName',
    label: loc('common.labels.countryName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'physicalLabel',
    label: loc('common.labels.physicalLabel'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'virtualLabelType',
    getContent: (row: any) => loc(`remainder.vmType.${row.virtualLabelType}`) || row.virtualLabelType,
    label: loc('common.labels.virtualLabelType'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'price',
    label: loc('common.labels.price'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'quantity',
    label: loc('common.labels.quantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'reserveQuantity',
    label: loc('common.labels.reserveQuantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'freeQuantity',
    label: loc('common.labels.freeQuantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'storeName',
    label: loc('common.labels.storeName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'storeId',
    label: loc('common.labels.storeId'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'dutyType',
    getContent: (row: any) => loc(`remainder.dutyType.${row.dutyType}`) || row.dutyType,
    label: loc('common.labels.dutyType'),
    style: {
      width: '200px',
    },
  },
];
