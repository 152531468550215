import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './AccordionPanel.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon/SvgIcon';

type Props = {
  className?: string;
  headerClassName?: string;
  renderHeader?: (opened: boolean) => React.ReactNode;
  initialOpened?: boolean;
  animated?: boolean;
  maxInnerHeight?: number;
};

export const AccordionPanel: React.FC<Props> = ({
  className,
  initialOpened = false,
  animated,
  headerClassName,
  renderHeader,
  children,
  maxInnerHeight,
}) => {
  const [opened, setOpened] = useState<boolean>(initialOpened);
  const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);
  const inner = useRef<HTMLDivElement>(null);

  const onHeaderClick = (e: React.MouseEvent) => {
    setOpened((state) => !state);
  };

  useEffect(() => {
    if (!animated) return;
    if (inner.current?.clientHeight) {
      setContentHeight(inner.current?.clientHeight);
    }
  }, [animated, children]);

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.opened]: opened,
        [s.animated]: animated,
      })}
    >
      <div className={clsx(s.header, headerClassName)} onClick={onHeaderClick}>
        <SvgIcon name={opened ? 'chevron-up' : 'chevron-down'} className={s.icon} />
        {typeof renderHeader === 'function' && renderHeader(opened)}
      </div>
      <div className={s.content} style={animated ? { height: opened ? contentHeight : 0 } : {}}>
        <div
          className={clsx(s.contentInner)}
          ref={inner}
          style={maxInnerHeight ? { maxHeight: maxInnerHeight, overflowY: 'auto' } : undefined}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
