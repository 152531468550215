import React from 'react';
import clsx from 'clsx';
import s from './UserInfo.module.scss';
import { Avatar } from 'ui/common';
import { useAppMode, useUser } from 'common/hooks';
import { useLocale } from 'services/l10n';
import { AppMode } from 'common/constants';
import { capitalizeFirstLetter } from 'common/utils';

type Props = {
  className?: string;
};

export const UserInfo: React.FC<Props> = ({ className }) => {
  const { currentUser, eaecUser } = useUser();
  const { taxpayer } = currentUser || {};
  const locale = useLocale();
  const { loc } = locale;
  const appMode = useAppMode();

  return taxpayer ? (
    <div className={clsx(className, s.wrapper)}>
      <Avatar name={taxpayer.firstNameRu} />
      <div className={s.info}>
        <div className={s.name}>
          {taxpayer.firstNameRu} {taxpayer.lastNameRu}
        </div>
        <div className={s.tin}>ИИН {taxpayer.tin}</div>
        {appMode === AppMode.ARM && (
          <div className={s.role}>{loc(`admin.user.role.${currentUser?.userServiceRoleType}`)}</div>
        )}
      </div>
    </div>
  ) : eaecUser?.isEaecUser ? (
    <div className={clsx(className, s.wrapper)}>
      <Avatar name={eaecUser.login} maxLetters={3} />
      <div className={s.info}>
        <div className={s.name}>{capitalizeFirstLetter(eaecUser.eaecUserCountyName)}</div>
      </div>
    </div>
  ) : null;
};
