import { FilterContentProps } from 'common/components';
import { getOptionsYesNo } from 'common/helpers/options';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { getSnrTypeOptions, getSORStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const SmallRetailFilters: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('common.labels.tin')}>
          <FormInput />
        </FormField>
        <FormField name="okedCode" label={loc('common.labels.oked')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="isOnlineKkm" label={loc('small.onlineKkm')}>
          <FormSelect options={getOptionsYesNo(locale)} />
        </FormField>
        <FormField name="isNdsPayer" label={loc('small.ndsPayer')}>
          <FormSelect options={getOptionsYesNo(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="snrType" label={loc('small.snrType')}>
          <FormSelect options={getSnrTypeOptions(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="isActive" label={loc('common.labels.status')}>
          <FormSelect options={getSORStatusOptions(locale)} />
        </FormField>
      </div>
    </Fragment>
  );
};
