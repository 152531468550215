import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';
import { clearEmptyFields } from 'common/utils';

export const mutationSendFeedback: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  if (process.env.NODE_ENV === 'development') {
    return { data: { success: true } };
  }

  const { attachments, ...rest } = body;

  const formData = convertToFormData(clearEmptyFields(rest));

  if (Array.isArray(attachments)) {
    attachments.forEach((file) => {
      formData.append('attachments[]', file);
    });
  }

  const res = await httpClient.post('/send-feedback', formData);
  if (res.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
