import { USER_ACCESS_EVENT_TYPE, USER_ACCESS_MODULE_TYPE } from './userAccessConstants';

export const INTERNAL_USER_ACCESS_MODULES_MAP = {
  [USER_ACCESS_MODULE_TYPE.REG_ACCOUNTING]: [
    USER_ACCESS_EVENT_TYPE.REGISTRATION,
    USER_ACCESS_EVENT_TYPE.LOGIN,
    USER_ACCESS_EVENT_TYPE.LOGOUT,
    USER_ACCESS_EVENT_TYPE.PASSWORD_RESET,
    USER_ACCESS_EVENT_TYPE.PERSONAL_DATA_EDIT,
    USER_ACCESS_EVENT_TYPE.EMAIL_CHANGE,
  ],
  [USER_ACCESS_MODULE_TYPE.ARM_USER_MANAGE]: [
    USER_ACCESS_EVENT_TYPE.ARM_USER_ADD,
    USER_ACCESS_EVENT_TYPE.ARM_USER_PERMISSION_CONFIRM,
    USER_ACCESS_EVENT_TYPE.ARM_USER_PERMISSION_DECLINE,
    USER_ACCESS_EVENT_TYPE.ARM_USER_PERMISSION_EDIT,
    USER_ACCESS_EVENT_TYPE.ARM_USER_BLOCK,
    USER_ACCESS_EVENT_TYPE.ARM_USER_RESTORE,
    USER_ACCESS_EVENT_TYPE.ARM_USER_IP_ADDRESS_CHANGE,
  ],
  [USER_ACCESS_MODULE_TYPE.MESSAGE]: [
    USER_ACCESS_EVENT_TYPE.MESSAGE_SEND_FOR_TINS,
    USER_ACCESS_EVENT_TYPE.MESSAGE_SEND_FOR_GROUP,
  ],
  [USER_ACCESS_MODULE_TYPE.NOTIFICATION]: [USER_ACCESS_EVENT_TYPE.NOTIFICATION_TEMPLATE_SAVE],
  [USER_ACCESS_MODULE_TYPE.DOWNTIME]: [USER_ACCESS_EVENT_TYPE.DOWNTIME_ADD, USER_ACCESS_EVENT_TYPE.DOWNTIME_APPROVE],
  [USER_ACCESS_MODULE_TYPE.BLOCKING]: [
    USER_ACCESS_EVENT_TYPE.BLOCKING_ADD,
    USER_ACCESS_EVENT_TYPE.BLOCKING_IMPORT,
    USER_ACCESS_EVENT_TYPE.BLOCKING_APPROVE,
    USER_ACCESS_EVENT_TYPE.BLOCKING_SETTINGS_SAVE,
  ],
  [USER_ACCESS_MODULE_TYPE.INVOICE]: [
    USER_ACCESS_EVENT_TYPE.INVOICE_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.INVOICE_PRINT,
    USER_ACCESS_EVENT_TYPE.INVOICE_JOURNAL_EXPORT,
    USER_ACCESS_EVENT_TYPE.INVOICE_CANCEL_BY_OGD,
    USER_ACCESS_EVENT_TYPE.INVOICE_RESTORE_BY_OGD,
  ],
  [USER_ACCESS_MODULE_TYPE.SNT]: [
    USER_ACCESS_EVENT_TYPE.SNT_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.SNT_PRINT,
    USER_ACCESS_EVENT_TYPE.SNT_JOURNAL_EXPORT,
    USER_ACCESS_EVENT_TYPE.SNT_CONFIRM_BY_OGD,
    USER_ACCESS_EVENT_TYPE.SNT_DECLINE_BY_OGD,
  ],
  [USER_ACCESS_MODULE_TYPE.AWP]: [
    USER_ACCESS_EVENT_TYPE.AWP_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.AWP_PRINT,
    USER_ACCESS_EVENT_TYPE.AWP_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.ECONTRACT]: [
    USER_ACCESS_EVENT_TYPE.ECONTRACT_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_PRINT,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.GTD]: [
    USER_ACCESS_EVENT_TYPE.GTD_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.GTD_VIEW,
    USER_ACCESS_EVENT_TYPE.GTD_PRINT,
    USER_ACCESS_EVENT_TYPE.GTD_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.FNO]: [
    USER_ACCESS_EVENT_TYPE.FNO_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.FNO_VIEW,
    USER_ACCESS_EVENT_TYPE.FNO_PRINT,
    USER_ACCESS_EVENT_TYPE.FNO_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.UFORM]: [
    USER_ACCESS_EVENT_TYPE.UFORM_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.UFORM_PRINT,
    USER_ACCESS_EVENT_TYPE.UFORM_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.VSTORE]: [
    USER_ACCESS_EVENT_TYPE.VSTORE_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.VSTORE_PRINT,
    USER_ACCESS_EVENT_TYPE.VSTORE_VIEW,
  ],
  [USER_ACCESS_MODULE_TYPE.VLABEL]: [
    USER_ACCESS_EVENT_TYPE.VLABEL_EXPORT,
    USER_ACCESS_EVENT_TYPE.VLABEL_HISTORY_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.TAXPAYER]: [USER_ACCESS_EVENT_TYPE.TAXPAYER_SEARCH, USER_ACCESS_EVENT_TYPE.TAXPAYER_EXPORT],
  [USER_ACCESS_MODULE_TYPE.BUSINESS_USER_ROLE]: [
    USER_ACCESS_EVENT_TYPE.BUSINESS_USER_SEARCH,
    USER_ACCESS_EVENT_TYPE.BUSINESS_USER_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.AGREEMENT]: [
    USER_ACCESS_EVENT_TYPE.AGREEMENT_VIEW,
    USER_ACCESS_EVENT_TYPE.AGREEMENT_DOWNLOAD,
    USER_ACCESS_EVENT_TYPE.AGREEMENT_JOURNAL_EXPORT,
  ],
  [USER_ACCESS_MODULE_TYPE.TRANSIT]: [USER_ACCESS_EVENT_TYPE.TRANSIT_ADD, USER_ACCESS_EVENT_TYPE.TRANSIT_SIGN],
  [USER_ACCESS_MODULE_TYPE.NEWS]: [USER_ACCESS_EVENT_TYPE.NEW_PUBLISH, USER_ACCESS_EVENT_TYPE.NEW_EDIT],
  [USER_ACCESS_MODULE_TYPE.CALENDAR]: [USER_ACCESS_EVENT_TYPE.CALENDAR_EDIT],
  [USER_ACCESS_MODULE_TYPE.DICTIONARY]: [
    USER_ACCESS_EVENT_TYPE.BIG_COMPANY_ADD,
    USER_ACCESS_EVENT_TYPE.BIG_COMPANY_DELETE,
    USER_ACCESS_EVENT_TYPE.CURRENCY_RATE_IMPORT,
    USER_ACCESS_EVENT_TYPE.MEASURE_UNIT_ADD,
    USER_ACCESS_EVENT_TYPE.SHARING_AGREEMENT_PARTICIPANT_ADD,
    USER_ACCESS_EVENT_TYPE.SHARING_AGREEMENT_PARTICIPANT_DEACTIVATE,
    USER_ACCESS_EVENT_TYPE.GSVS_IMPORT,
    USER_ACCESS_EVENT_TYPE.GSVS_ADD,
    USER_ACCESS_EVENT_TYPE.GSVS_EDIT,
    USER_ACCESS_EVENT_TYPE.GSVS_CLOSE,
    USER_ACCESS_EVENT_TYPE.GSVS_REQUEST_CONFIRM,
    USER_ACCESS_EVENT_TYPE.GSVS_REQUEST_REJECT,
    USER_ACCESS_EVENT_TYPE.REORGANIZATION_ADD,
    USER_ACCESS_EVENT_TYPE.REORGANIZATION_CANCEL,
    USER_ACCESS_EVENT_TYPE.OIL_PIN_IMPORT,
    USER_ACCESS_EVENT_TYPE.OIL_PIN_ADD,
    USER_ACCESS_EVENT_TYPE.TOBACCO_PIN_IMPORT,
    USER_ACCESS_EVENT_TYPE.TOBACCO_PIN_ADD,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_PIN_IMPORT,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_PIN_ADD,
    USER_ACCESS_EVENT_TYPE.OIL_OVD_IMPORT,
    USER_ACCESS_EVENT_TYPE.OIL_OVD_ADD,
    USER_ACCESS_EVENT_TYPE.TOBACCO_OVD_IMPORT,
    USER_ACCESS_EVENT_TYPE.TOBACCO_OVD_ADD,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_LICENSE_IMPORT,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_LICENSE_ADD,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_SPIRIT_ADD,
    USER_ACCESS_EVENT_TYPE.ALCOHOL_SPIRIT_IMPORT,
    USER_ACCESS_EVENT_TYPE.EXCISE_RATE_ADD,
    USER_ACCESS_EVENT_TYPE.EXCISE_RATE_IMPORT,
    USER_ACCESS_EVENT_TYPE.BANNED_GOODS_ADD,
    USER_ACCESS_EVENT_TYPE.BANNED_GOODS_EDIT,
    USER_ACCESS_EVENT_TYPE.BANNED_GOODS_DELETE,
    USER_ACCESS_EVENT_TYPE.PLANNED_CHECKPOINT_ADD,
    USER_ACCESS_EVENT_TYPE.PLANNED_CHECKPOINT_EDIT,
    USER_ACCESS_EVENT_TYPE.PLANNED_CHECKPOINT_DEACTIVATE,
    USER_ACCESS_EVENT_TYPE.TAXPAYER_MARK_IMPORT,
    USER_ACCESS_EVENT_TYPE.TAXPAYER_MARK_CLEAN,
  ],
  [USER_ACCESS_MODULE_TYPE.REPORT]: [
    USER_ACCESS_EVENT_TYPE.STATISTICS_REPORT_EXPORT,
    USER_ACCESS_EVENT_TYPE.USERS_REGISTRATION_REPORT_EXPORT,
  ],
};

export const EXTERNAL_USER_ACCESS_MODULES_MAP = {
  [USER_ACCESS_MODULE_TYPE.REG_ACCOUNTING]: [
    USER_ACCESS_EVENT_TYPE.REGISTRATION,
    USER_ACCESS_EVENT_TYPE.LOGIN,
    USER_ACCESS_EVENT_TYPE.LOGOUT,
    USER_ACCESS_EVENT_TYPE.PASSWORD_RESET,
    USER_ACCESS_EVENT_TYPE.PERSONAL_DATA_EDIT,
    USER_ACCESS_EVENT_TYPE.EMAIL_CHANGE,
    USER_ACCESS_EVENT_TYPE.ENTERPRISE_INVITATION_APPROVE,
    USER_ACCESS_EVENT_TYPE.ENTREPRENEUR_INVITATION_APPROVE,
    USER_ACCESS_EVENT_TYPE.PRIVATE_PRACTICE_INVITATION_APPROVE,
    USER_ACCESS_EVENT_TYPE.BLOCK_USER_BY_ADMIN,
    USER_ACCESS_EVENT_TYPE.RESTORE_USER_BY_ADMIN,
    USER_ACCESS_EVENT_TYPE.BLOCK_USER,
    USER_ACCESS_EVENT_TYPE.RESTORE_USER,
  ],
  [USER_ACCESS_MODULE_TYPE.NOTIFICATION]: [
    USER_ACCESS_EVENT_TYPE.NOTIFICATION_READ,
    USER_ACCESS_EVENT_TYPE.NOTIFICATION_DELETE,
  ],
  [USER_ACCESS_MODULE_TYPE.INVOICE]: [
    USER_ACCESS_EVENT_TYPE.INVOICE_CREATED,
    USER_ACCESS_EVENT_TYPE.INVOICE_DELIVERED,
    USER_ACCESS_EVENT_TYPE.INVOICE_CANCELED,
    USER_ACCESS_EVENT_TYPE.INVOICE_DECLINED,
    USER_ACCESS_EVENT_TYPE.INVOICE_UNREVOKE,
    USER_ACCESS_EVENT_TYPE.INVOICE_REVOKED,
    USER_ACCESS_EVENT_TYPE.INVOICE_CANCELED_BY_SNT_DECLINE,
    USER_ACCESS_EVENT_TYPE.INVOICE_CANCELED_BY_SNT_REVOKE,
    USER_ACCESS_EVENT_TYPE.INVOICE_CANCELED_BY_OGD,
  ],
  [USER_ACCESS_MODULE_TYPE.SNT]: [
    USER_ACCESS_EVENT_TYPE.SNT_CREATED,
    USER_ACCESS_EVENT_TYPE.SNT_DELIVERED,
    USER_ACCESS_EVENT_TYPE.SNT_CANCELED,
    USER_ACCESS_EVENT_TYPE.SNT_REVOKED,
    USER_ACCESS_EVENT_TYPE.SNT_DECLINED,
    USER_ACCESS_EVENT_TYPE.SNT_CONFIRMED,
  ],
  [USER_ACCESS_MODULE_TYPE.AWP]: [
    USER_ACCESS_EVENT_TYPE.AWP_CREATED,
    USER_ACCESS_EVENT_TYPE.AWP_DELIVERED,
    USER_ACCESS_EVENT_TYPE.AWP_CONFIRMED,
    USER_ACCESS_EVENT_TYPE.AWP_DECLINED,
    USER_ACCESS_EVENT_TYPE.AWP_REVOKED,
    USER_ACCESS_EVENT_TYPE.AWP_IN_TERMINATING,
    USER_ACCESS_EVENT_TYPE.AWP_TERMINATED,
  ],
  [USER_ACCESS_MODULE_TYPE.ECONTRACT]: [
    USER_ACCESS_EVENT_TYPE.ECONTRACT_CREATED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_PARTIALLY_VIEWED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_VIEWED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_REVOKED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_PARTIALLY_APPROVED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_APPROVED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_DECLINED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_TERMINATED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_CLOSED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_IN_PROCESS_OF_TERMINATION,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_PARTIALLY_TERMINATED,
    USER_ACCESS_EVENT_TYPE.ECONTRACT_TERMINATED_UNILATERALLY,
  ],
  [USER_ACCESS_MODULE_TYPE.GTD]: [USER_ACCESS_EVENT_TYPE.GTD_RECEIVED],
  [USER_ACCESS_MODULE_TYPE.FNO]: [USER_ACCESS_EVENT_TYPE.FNO_RECEIVED],
  [USER_ACCESS_MODULE_TYPE.UFORM]: [USER_ACCESS_EVENT_TYPE.UFORM_SAVE, USER_ACCESS_EVENT_TYPE.UFORM_CANCEL],
  [USER_ACCESS_MODULE_TYPE.VSTORE]: [USER_ACCESS_EVENT_TYPE.VSTORE_SAVE, USER_ACCESS_EVENT_TYPE.VSTORE_CLOSE],
  [USER_ACCESS_MODULE_TYPE.REPORT]: [USER_ACCESS_EVENT_TYPE.INVOICE_REPORT_EXPORT],
};
