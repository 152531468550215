import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { Autocomplete, AutocompleteProps } from '../controls';
import { FormValueProps } from './FormValueProps';

const emptyObj = {};

export type FormAutocompleteProps = Partial<FieldProps> &
  Omit<AutocompleteProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<any>) => void;
    component?: FC<AutocompleteProps>;
  };

export const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  field,
  form,
  meta,
  onValueChange,
  component,
  ...rest
}) => {
  const { name, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  const onSelectChange = (val: any) => {
    if (onValueChange && name && form) {
      onValueChange({ value: val, name, form });
    } else if (typeof setFieldValue === 'function' && name) {
      setFieldValue(name, val);
    }
  };

  const AutocompleteComponent = component || Autocomplete;

  return <AutocompleteComponent {...rest} {...{ name, onChange: onSelectChange, onBlur, value: value ?? emptyObj }} />;
};
