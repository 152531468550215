import React from 'react';
import clsx from 'clsx';
import s from './FilterPanelModal.module.scss';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FilterClassNames } from './FilterContentProps';
import { trimProps } from 'common/utils';
import { solveFormErrors } from 'common/form/helpers';
import { ButtonT2 } from 'ui';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
  initialValues?: any;
  onSubmit?: (values: any) => Promise<any>;
  renderContent?: (form: FormikProps<any>, classNames: FilterClassNames) => React.ReactNode;
  onClear?: (form: FormikProps<any>) => void;
};

export const FilterPanelModal: React.FC<Props> = ({
  className,
  initialValues = {},
  onSubmit = async (values: any) => console.log(values),
  renderContent,
  onClear = (form) => {
    form.setValues({});
  },
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    const trimedValues = trimProps(values);
    form.setValues(trimedValues, false);
    try {
      onSubmit(trimedValues);
    } catch (error) {
      form.setErrors(solveFormErrors(error));
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        enableReinitialize={true}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(form) => (
          <Form className={s.body}>
            <div className={s.content}>{renderContent ? renderContent(form, { columnClassName: s.column }) : null}</div>
            <div className={s.footer}>
              <ButtonT2 variant="transparent" size="small" onClick={() => onClear(form)}>
                {loc('common.button.clear')}
              </ButtonT2>
              <ButtonT2 type="submit" variant="primary" size="small">
                {loc('common.button.search')}
              </ButtonT2>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
