import React from 'react';
import clsx from 'clsx';
import s from './QrCode.module.scss';
import { API_URL } from 'common/constants';

type Props = {
  className?: string;
  regNumber: string;
};

export const QrCode: React.FC<Props> = ({ className, regNumber }) => {
  return (
    <div
      className={clsx(className, s.wrapper)}
      style={{ backgroundImage: `url(${API_URL}/assurance/qr/${regNumber})` }}
    ></div>
  );
};
