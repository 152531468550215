/**
 * Конвертирует объект в FormData
 * @param object
 * @param addBrackets - добавлять квадратные скобки в конце имен для массивов (list -> list[])
 * @returns FormData
 */
export function convertToFormDataDeep(object: { [key: string]: any }, addBrackets?: boolean) {
  const formData = new FormData();
  Object.entries<any>(addBrackets ? bracketArrays(object) : object).forEach((item) => {
    if (Array.isArray(item[1])) {
      item[1].forEach((i) => {
        formData.append(item[0], i);
      });
    } else {
      formData.append(item[0], item[1]);
    }
  });
  return formData;
}

/**
 * Add brakets '[]' to 'array props names' of an object. 'recipients' => 'recipients[]'
 * @param obj - object with props
 */
export function bracketArrays(obj: any) {
  const result: any = {};
  if (typeof obj === 'object' && obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key])) {
        result[`${key}[]`] = obj[key];
      } else {
        result[key] = obj[key];
      }
    });
  }
  return result;
}
