import React, { Fragment, useState } from 'react';
import s from './Feedback.module.scss';
import { ButtonIcon, Tooltip } from 'ui';
import { ReactComponent as Envelope } from 'assets/icons/journal-buttons/envelope.svg';
import { useLocale } from 'services/l10n';
import { Modal } from 'services/modal';
import { FeedbackModal } from './FeedbackModal';

type Props = {
  className?: string;
};

export const Feedback: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [modal, setModal] = useState(false);
  return (
    <Fragment>
      <Tooltip content={loc(`c.feedback`)} place="bottom-left">
        <ButtonIcon
          iconComponent={Envelope}
          iconClassName={s.buttonIcon}
          className={s.button}
          onClick={() => setModal(true)}
        />
      </Tooltip>

      <Modal isOpen={modal} onClose={() => setModal(false)} center scrollLock={false}>
        <FeedbackModal />
      </Modal>
    </Fragment>
  );
};
