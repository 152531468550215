import React, { Fragment } from 'react';
import { queryOgdList } from 'rest';
import { useLocale } from 'services/l10n';
import { useDictionaryLoad } from './useDictionaryLoad';

let codeMap: any = undefined;

async function fetchDictionary() {
  try {
    const res = await queryOgdList();
    if (Array.isArray(res.data) && !codeMap) {
      codeMap = Object.fromEntries(res.data.map((item: any) => [String(item.code), item]));
      console.log('created ogdCodeMap');
    }
    return res;
  } catch (error) {
    console.error(error);
  }
}

type Props = {
  code: string;
  format?: 'name' | 'codeWithName';
};

export const CodeValueOgd: React.FC<Props> = ({ code, format = 'codeWithName' }) => {
  const locale = useLocale();
  const { choose } = locale;

  const { show } = useDictionaryLoad(codeMap, fetchDictionary);

  if (!code || !show) {
    return null;
  }

  const data = codeMap?.[code];

  if (!data) {
    return <Fragment>{String(code)}</Fragment>;
  }

  return (
    <Fragment>
      {format === 'name' ? choose([data.nameRu, data.nameKz]) : `(${data.code}) ${choose([data.nameRu, data.nameKz])}`}
    </Fragment>
  );
};
