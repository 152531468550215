import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import ss from '../../Registration.module.scss';
import s from './RegContract.module.scss';
import {
  AutocompleteMUI,
  ButtonIcon,
  ButtonT2,
  FormFieldT2,
  FormInputMUIT2,
  FormRadioT2,
  FormSelectMUIT2,
  errorToString,
} from 'ui';
import { useLocale } from 'services/l10n';
import { FormAutocompleteIIN, FormLocaleDatePickerMUIT2 } from 'common/components';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { getRegContractTypeOptions } from '../../helpers';

type Props = {
  className?: string;
};

export const RegContractAttorney: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  const { values, setFieldValue, errors } = useFormikContext<any>();

  const contractTypeOptions = useMemo(() => getRegContractTypeOptions({ locale }), [locale]);

  return (
    <Fragment>
      <div className={clsx(ss.field, ss.doubleField)}>
        <FormFieldT2 className={ss.colField} name="attorney.startDate">
          <FormLocaleDatePickerMUIT2 label={loc('reg.startDate')} />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="attorney.endDate">
          <FormLocaleDatePickerMUIT2 label={loc('reg.endDate')} />
        </FormFieldT2>
      </div>
      <div className={clsx(ss.field, ss.doubleField)}>
        <FormFieldT2 className={ss.colField} name="attorney.iin">
          <FormAutocompleteIIN
            component={AutocompleteMUI}
            label={loc('reg.attorneyIin')}
            registration
            getOptionLabel={(opt) => `${opt.tin} ${chooseByKey(opt, 'name')}`}
            onValueChange={({ value }) => {
              setFieldValue('attorney.iin', value?.tin);
              setFieldValue('attorney.name', chooseByKey(value, 'name'));
            }}
          />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="attorney.name">
          <FormInputMUIT2 label={loc('reg.attorneyName')} readOnly />
        </FormFieldT2>
      </div>

      <div className={s.fieldsList}>
        <h4 className={s.fieldsTitle}>{loc('reg.attorneyDocuments')}</h4>
        {typeof getIn(errors, 'attorney.documents') === 'string' && (
          <span className={s.errorMessage}>{errorToString(getIn(errors, 'attorney.documents'))}</span>
        )}
        <FieldArray name="attorney.documents">
          {({ name, push, remove }) => {
            const array = getIn(values, name);
            return (
              <Fragment>
                {Array.isArray(array) &&
                  array.map((row, index) => (
                    <Fragment key={index}>
                      <div className={clsx(s.fieldsRow, s.document)}>
                        <span className={s.rowNumber}>
                          {index + 1}
                          {')'}
                        </span>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].contractType`}>
                          <FormSelectMUIT2 size="large" label={loc('reg.contractType')} options={contractTypeOptions} />
                        </FormFieldT2>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].num`}>
                          <FormInputMUIT2 label={loc('reg.docNumber')} />
                        </FormFieldT2>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].startDate`}>
                          <FormLocaleDatePickerMUIT2 label={loc('common.labels.date')} />
                        </FormFieldT2>
                        <ButtonIcon
                          className={s.delBtn}
                          iconName="close"
                          iconClassName={s.delIcon}
                          onClick={() => remove(index)}
                        />
                      </div>
                      <div className={s.showInEsf}>
                        <FormFieldT2 name="mainAttorneyDocIndex" label={loc('reg.showInEsf')} direction="line-reverse">
                          <FormRadioT2 radioValue={index} />
                        </FormFieldT2>
                      </div>
                    </Fragment>
                  ))}
                <ButtonT2 className={s.fieldsListBtn} variant="outlined" onClick={() => push({})}>
                  {loc('reg.addDoc')}
                </ButtonT2>
              </Fragment>
            );
          }}
        </FieldArray>
      </div>
    </Fragment>
  );
};
