import { getNestedPropsPaths } from 'common/utils';
import { EsfSection } from 'pages/esf/constants';

const FIELD_SECTION: { [key: string]: string } = {
  registrationNumber: EsfSection.A,
  num: EsfSection.A,
  date: EsfSection.A,
  datePaper: EsfSection.A,
  reasonPaper: EsfSection.A,
  turnoverDate: EsfSection.A,
  cancelReason: EsfSection.A,
  relatedInvoice: EsfSection.A,
  sellers: EsfSection.B,
  customers: EsfSection.C,
  publicOffice: EsfSection.C1,
  consignor: EsfSection.D,
  consignee: EsfSection.D,
  deliveryTerm: EsfSection.E,
  deliveryDocNum: EsfSection.F,
  deliveryDocDate: EsfSection.F,
  deliveryDocNum2: EsfSection.F,
  deliveryDocDate2: EsfSection.F,
  productSet: EsfSection.G,
  sellerParticipants: EsfSection.H,
  customerParticipants: EsfSection.H,
  sellerAgentTin: EsfSection.I,
  sellerAgentName: EsfSection.I,
  sellerAgentAddress: EsfSection.I,
  sellerAgentDocNum: EsfSection.I,
  sellerAgentDocDate: EsfSection.I,
  customerAgentTin: EsfSection.J,
  customerAgentName: EsfSection.J,
  customerAgentAddress: EsfSection.J,
  customerAgentDocNum: EsfSection.J,
  customerAgentDocDate: EsfSection.J,
  addInf: EsfSection.K,
  signatureType: EsfSection.L,
  signature: EsfSection.L,
  operatorFullname: EsfSection.L,
  inputDate_string: EsfSection.M,
  deliveryDate: EsfSection.M,
};

/**
 * Возвращает сгруппированные по разделам ошибки валидации, в соответствии с картой FIELD_SECTION
 * Используется для вывода ошибок в панели ошибок в бланке ЭСФ, и для подсветки количества ошибок в навигации по разделам.
 * Проверка отрезает начало имени поля до символов ('.' или '[') и смотрит это поле в FIELD_SECTION
 * Если раздел для ошибки не найден, то группирует в группу 'unknown' (Общие)
 * @param errors - ошибки валидации бланка
 * @returns Объект {[key: имя разедла]: Массив с данными ошибок Array<{ property, text }> }
 */
export const getSectionGroupedErrors = (
  errors: any
): {
  [key: string]: Array<{ property: string; text: string }>;
} => {
  const groups: { [key: string]: Array<{ property: string; text: string }> } = {};
  Object.entries(getNestedPropsPaths(errors)).forEach(([property, text]) => {
    if (text) {
      const section = FIELD_SECTION[String(property).split(/[.[]/)[0]] || 'unknown';
      const errorData = { property, text: String(text) };
      if (Array.isArray(groups[section])) {
        groups[section].push(errorData);
      } else {
        groups[section] = [errorData];
      }
    }
  });
  return groups;
};
