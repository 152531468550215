import { useEffect, useState } from 'react';

export const useDictionaryLoad = (codeMap: any, fetchDictionary: () => Promise<any>) => {
  const mapLoaded = !!codeMap;

  const [show, setShow] = useState<boolean>(mapLoaded);

  useEffect(() => {
    if (!mapLoaded) {
      fetchDictionary().then(() => {
        setShow(true);
      });
    }
  }, []); // eslint-disable-line

  return { show };
};
