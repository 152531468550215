import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './OgdPage.module.scss';
import { OgdTree } from './components';
import { queryAdminOGDExport, queryAdminOGDSearch, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { Button, FormField, FormInput, FormSelect, PageTitle } from 'ui/common';
import { getClosedOptions } from 'common/helpers/options';
import { FormOGDSelect } from 'common/components/controls';
import { FilterPanel } from 'common/components';

export const initialFilter: {
  code?: string;
  name?: string;
  isClosed?: string;
  bin?: string;
} = {};

type Props = {
  className?: string;
};

export const OgdPage: React.FC<Props> = ({ className }) => {
  const { data, setFilter, filter, loading } = useDataQuery(
    queryAdminOGDSearch,
    {
      filter: initialFilter,
    }
  );
  const locale = useLocale();
  const { loc } = locale;

  const ExportOGD = async () => {
    const res = await queryAdminOGDExport({ filter });
    console.log(res);
  };

  const onSubmit = async (values: any) => {
    setFilter(values);
  };

  return (
    <Fragment>
      <PageTitle>{loc('pages.ogd.pageTitle')}</PageTitle>
      <div className={clsx(className, s.wrapper, 'container')}>
        {/* <OgdFilters
          filter={filter}
          onFilterChange={setFilter}
          className={s.filters}
        /> */}
        <FilterPanel
          className={s.filters}
          initialValues={filter}
          loading={loading}
          onSubmit={onSubmit}
          onClear={(form) => form.setValues({})}
          renderColumns={() => [
            <FormField name="code" label="Код ОГД">
              <FormOGDSelect />
            </FormField>,
            <FormField name="name" label="Наименование">
              <FormInput />
            </FormField>,
            <FormField name="isClosed" label="Статус">
              <FormSelect options={getClosedOptions(locale)} />
            </FormField>,
            <FormField name="bin" label="БИН">
              <FormInput />
            </FormField>,
          ]}
          renderHeader={() => (
            <Fragment>
              <Button onClick={() => ExportOGD()}>Экспортировать</Button>
            </Fragment>
          )}
        />
        <OgdTree data={data} />
      </div>
    </Fragment>
  );
};
