import clsx from 'clsx';
import React from 'react';
import s from './Loader.module.scss';

type Props = {
  className?: string;
  loaderClassName?: string;
  overlay?: boolean;
  noMargins?: boolean;
  size?: 'medium' | 'small';
};

export const Loader: React.FC<Props> = ({ className, overlay, loaderClassName, noMargins, size = 'medium' }) => {
  return (
    <div
      className={clsx(s.wrapper, className, {
        [s.overlay]: overlay,
        [s.noMargins]: noMargins,
        [s.small]: size === 'small',
      })}
    >
      <div className={clsx(s.loader, loaderClassName)}>Loading...</div>
    </div>
  );
};
