import React from 'react';
import { FieldProps } from 'formik';
import { DatePicker, DatePickerProps } from '../controls';
import { FormValueProps } from './FormValueProps';

export type FormDatePickerProps = Partial<FieldProps> &
  Omit<DatePickerProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps) => void;
  };

export const FormDatePicker: React.FC<FormDatePickerProps> = ({ field, form, meta, onValueChange, ...rest }) => {
  const { name, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  const onDatePickerChange = (val: string) => {
    if (typeof onValueChange === 'function' && name && form) {
      onValueChange({ value: val, name, form });
    } else if (typeof setFieldValue === 'function' && name) {
      setFieldValue(name, val);
    }
  };

  return <DatePicker {...rest} {...{ name, onChange: onDatePickerChange, onBlur, value }} />;
};
