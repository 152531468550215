import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminSROImportCloseFromFile: DataMutationFunction = async (variables = {}): Promise<any> => {
  const res = await httpClient.post('/admin/dict/smallRetailOutlet/importCloseFromFile');
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
