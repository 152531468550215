import { RootStore } from 'common/stores/RootStore';
import { API_URL } from 'common/constants';
import { ToastService } from 'services/toasts';
import { fakePushError, fakePushInfo, fakePushSuccess, fakePushWarning } from './fakePush';

declare global {
  var Stomp: any;
  var SockJS: any;
  var testPush: () => void;
}

export type PushNotification = {
  body: string;
};

export type PushNotificationContent = {
  body: unknown;
  sessionId: string;
  user: string;
};

export type PushNotificationObjectBody = {
  text: string;
  level: 'ERROR' | 'SUCCESS' | 'WARN' | 'INFO';
};

const isPushNotificationObjectBody = (body: any): body is PushNotificationObjectBody => {
  if (
    body.text &&
    typeof body.text === 'string' &&
    body.level &&
    ['ERROR', 'SUCCESS', 'WARN', 'INFO'].includes(body.level)
  ) {
    return true;
  }
  return false;
};

export class PushNotificationStore {
  rootStore: RootStore;
  stompClient: any;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    if (process.env.NODE_ENV === 'development') {
      window.testPush = () =>
        [fakePushError, fakePushInfo, fakePushSuccess, fakePushWarning].forEach((push) =>
          this.showPushNotification(push)
        );
    }
  }

  showPushNotification = (notificationContent: PushNotificationContent) => {
    if (typeof notificationContent?.body === 'string') {
      let body;
      try {
        body = JSON.parse(notificationContent?.body);
      } catch (error) {
        console.info('not valid JSON');
        body = notificationContent.body;
      }

      const options = { autoClose: 10 * 1000 };

      if (isPushNotificationObjectBody(body)) {
        const types = {
          ERROR: 'error',
          SUCCESS: 'success',
          WARN: 'warning',
          INFO: 'info',
        } as const;
        ToastService.show(types[body.level], body.text, options);
      } else if (typeof body === 'string') {
        ToastService.showInfo(body, options);
      }
    }
  };

  initWebsocket = () => {
    const { notifications } = this.rootStore;
    const that = this;
    const socket = new SockJS(`${API_URL}/websocket`, null, {
      protocols_whitelist: ['websocket'],
    });
    let headers = {};
    that.stompClient = window.Stomp.over(socket);
    that.stompClient.connect(headers, function (frame: any) {
      console.log('Connected: ' + frame);
      console.log('connected, session id: ', that.stompClient);
      that.stompClient.subscribe('/user/queue/notify', function (notification: PushNotification) {
        console.log('/user/queue/notify', notification);
        try {
          const notificationContent: PushNotificationContent = JSON.parse(notification.body);
          that.showPushNotification(notificationContent);
        } catch (error) {
          console.log(error);
        }
      });
      that.stompClient.subscribe('/user/queue/countOfUnread', function (notification: PushNotification) {
        console.log('/user/queue/countOfUnread', notification);
        try {
          const notificationContent: PushNotificationContent = JSON.parse(notification.body);
          console.log('notificationContent', notificationContent);
          notifications.updateUnreadMessagesCount();
        } catch (error) {
          console.log(error);
        }
      });
    });
  };

  disconnect = () => {
    this.stompClient.disconnect();
  };
}
