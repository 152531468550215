import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from 'rest/helpers';

export const mutationEaecLogin: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  const formData = convertToFormData(body);

  const res = await httpClient.post('/eaec/login', formData);
  if (res.data.success) {
    return res;
  } else {
    throw res;
  }
};
