import React, { FC } from 'react';
import { FieldProps } from 'formik';
import { Select, SelectProps } from '../controls';
import { FormValueProps } from './FormValueProps';

export type FormSelectProps = Partial<FieldProps> &
  Omit<SelectProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<any>) => void;
    component?: FC<SelectProps>;
  };

export const FormSelect: React.FC<FormSelectProps> = ({
  field,
  form,
  meta,
  onValueChange,
  isMulti,
  component,
  ...rest
}) => {
  const { name, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  const onSelectChange = (val: any, option: any) => {
    if (onValueChange && name && form) {
      onValueChange({ value: val, name, form, option });
    } else if (typeof setFieldValue === 'function' && name) {
      setFieldValue(name, val);
    }
  };

  const SelectComponent = component || Select;

  return (
    <SelectComponent
      isMulti={isMulti}
      {...rest}
      {...{
        name,
        onChange: onSelectChange,
        onBlur,
        value: value ?? (isMulti ? [] : ''),
      }}
    />
  );
};
