import React from 'react';
import clsx from 'clsx';
import s from './PassportHistoryModal.module.scss';
import { ModalChildrenProps, ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useDataQuery } from 'rest';
import { useAppMode } from 'common/hooks';
import { queryAdminAssuranceHistory, queryAssuranceHistory } from '../api';
import { useLocale } from 'services/l10n';
import { Button, Loader } from 'ui/common';
import { AppMode } from 'common/constants';

type Props = ModalChildrenProps & {
  className?: string;
  passportId: string;
  regNumber: string;
  regDate: string;
};

export const PassportHistoryModal: React.FC<Props> = ({ className, passportId, regNumber, regDate, onClose }) => {
  const appMode = useAppMode();
  const locale = useLocale();
  const { loc } = locale;

  const { data } = useDataQuery(appMode === AppMode.ARM ? queryAdminAssuranceHistory : queryAssuranceHistory, {
    passportId,
  });

  console.log(data);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('assurance.viewHistoryTitle')}</ModalHeader>
      {Array.isArray(data) ? (
        <ModalContent className={s.content}>
          <div className={s.row}>
            <div className={s.left}>
              <div className={clsx(s.heading)}>{loc('common.labels.registrationNumber')}</div>
              <div>{regNumber}</div>
            </div>
            <div className={s.right}>
              <div className={clsx(s.heading, 'fw500')}>{loc('assurance.passportRegDateTime')}</div>
              <div>{regDate}</div>
            </div>
          </div>
          <div className={s.statuses}>
            <div className={clsx(s.row)}>
              <div className={s.left}>
                <div className={clsx(s.heading, 'fw500')}>{loc('assurance.statuses')}</div>
              </div>
              <div className={s.right}>
                <div className={clsx(s.heading, 'fw500')}>{loc('assurance.statusUpdateDate')}</div>
              </div>
            </div>
            {data.map((status) => (
              <div className={clsx(s.row, s.statusRow)}>
                <div className={s.left}>
                  <div className={clsx(s.status)}>{loc(`assurance.${status.status}`)}</div>
                </div>
                <div className={s.right}>
                  <div>{status.date}</div>
                </div>
              </div>
            ))}
          </div>
        </ModalContent>
      ) : (
        <Loader />
      )}
      <ModalFooter>
        <Button size="small" onClick={onClose}>
          {loc('common.button.close')}
        </Button>
      </ModalFooter>
    </div>
  );
};
