import { DataMutationFunction } from '../../../types';
import { httpClient } from '../../../httpClient';
import { convertToFormData } from '../../../helpers';
import { AxiosResponse } from 'axios';

export const mutationInvoiceDeleteFailed: DataMutationFunction<AxiosResponse> = async (variables): Promise<any> => {
  const { body } = variables || {};

  const formData = convertToFormData(body || {});

  const res = await httpClient.put('/invoice/deleteFailed', formData);

  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
