import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminRiskyTaxpayerChangeStatus: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body } = variables;

  const res = await httpClient.post('/admin/riskyTaxpayer/changeStatus', body);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
