import { AxiosResponse } from 'axios';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationMessagesCreate: DataMutationFunction<AxiosResponse> = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(body);

  const res = await httpClient.post('/messages/create', formData);

  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
