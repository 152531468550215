import { API_URL } from 'common/constants';
import { downloadFile } from 'rest';

type Config = {
  ids: Array<string>;
  views: Array<string>;
};

export const downloadXlsx = async (config: Config) => {
  const { ids, views } = config;
  try {
    await downloadFile({
      url: `${API_URL}/search/generateXls`,
      method: 'POST',
      body: JSON.stringify({ ids, views }),
      header: {
        name: 'content-type',
        value: 'application/json; charset=UTF-8',
      },
    });
  } catch (error) {
    throw error;
  }
  return;
};
