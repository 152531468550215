import React from 'react';
import clsx from 'clsx';
import s from './ArmLayoutFooter.module.scss';
import { useLocale } from 'services/l10n';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'common/constants';

type Props = {
  className?: string;
  minimized: boolean;
};

export const ArmLayoutFooter: React.FC<Props> = ({ className, minimized }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper, { [s.minimized]: minimized })}>
      <span>
        {loc('common.layout.support')}: <b>{SUPPORT_PHONE}</b> (<span>{loc('common.layout.hours')}</span>),{' '}
        <b>{SUPPORT_EMAIL}</b> (<span style={{ textTransform: 'lowercase' }}>{loc('common.layout.24.7')}</span>)
      </span>
      <a href="/">{loc('common.nav.guide')}</a>
      <a href="/">{loc('common.layout.video')}</a>
      <a href="/">{loc('common.layout.help')}</a>
    </div>
  );
};
