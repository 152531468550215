import React from 'react';
import clsx from 'clsx';
import s from './JournalT2.module.scss';
import { JournalButton } from '../types';
import { Button, ButtonIcon } from 'ui';
import { ReactComponent as HistoryIcon } from 'assets/icons/detail.svg';

type Props = {
  className?: string;
  iconClassName?: string;
  button: JournalButton;
  onClick: () => void;
};

export const JournalButtonT2: React.FC<Props> = ({ className, button, onClick, iconClassName }) => {
  return button.variant === 'icon' ? (
    <ButtonIcon
      className={clsx(className, s[`variant-${button.variant}`])}
      iconComponent={button.icon || HistoryIcon}
      iconClassName={iconClassName}
      onClick={onClick}
      theme="T2"
      title={button.label}
      variant="tertiary"
    />
  ) : (
    <Button
      className={clsx(className, s[`variant-${button.variant}`])}
      iconComponent={button.icon || HistoryIcon}
      iconClassName={iconClassName}
      onClick={onClick}
      theme="T2"
      title={button.label}
      variant={button.variant === 'primary' ? 'primary' : 'tertiary'}
    >
      {button.label}
    </Button>
  );
};
