import { FilterContentProps, FormDoubleDatePicker, FormLocaleDatePicker } from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getEaecMonitoringStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const EaecMonitoringJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection } = classNames || {};

  const statusOptions = useMemo(() => getEaecMonitoringStatusOptions(locale), [locale]);

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection}>
          <Field label={loc('agreements.requestDate')} noMargin>
            <FormDoubleDatePicker nameFrom="requestDateFrom" nameTo="requestDateTo" maxDate={new Date()} />
          </Field>
          <Field label={loc('agreements.responseDate')} noMargin>
            <FormDoubleDatePicker nameFrom="responseDateFrom" nameTo="responseDateTo" maxDate={new Date()} />
          </Field>
          <FormField name="reportDateFrom" label={loc('agreements.reportDateFrom')}>
            <FormLocaleDatePicker />
          </FormField>
          <FormField name="reportDateTo" label={loc('agreements.reportDateTo')}>
            <FormLocaleDatePicker />
          </FormField>
        </div>
        <div className={gridSection}>
          <FormField name="messageId" label={loc('agreements.requestId')}>
            <FormInput />
          </FormField>
          <FormField name="responseMessageId" label={loc('agreements.responseDocId')}>
            <FormInput />
          </FormField>

          <FormField name="status" label={loc('agreements.requestStatus')}>
            <FormSelect options={statusOptions} />
          </FormField>
        </div>
      </div>
    </Fragment>
  );
};
