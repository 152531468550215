import React from 'react';
import { formatToDate } from 'services/date-time';
import { LocaleStore } from 'services/l10n';
import { RowMark, RowMarkColor, TableColumn } from 'ui/common';

export const getSntTermsConfirmationColumns = ({
  locale,
}: {
  locale: LocaleStore;
}): Array<TableColumn> => {
  const { loc } = locale;

  const solveMark = (row: any) => {
    const confirmDate = formatToDate(row.customerChangeStatusDate);
    const expireDate = formatToDate(row.confirmDeadlineDate);
    let color = RowMarkColor.TRANSPARENT;
    let title = '';
    //Подтверждено/отклонено, полсле истечения срока подтверждения/отклонения.
    if (confirmDate && expireDate && expireDate < confirmDate) {
      color = RowMarkColor.YELLOW;
      title = loc('sntTerms.confirmedAfterExpired');
      //Срок подтверждения/отклонения истек.
    } else if (!confirmDate && expireDate && expireDate < new Date()) {
      color = RowMarkColor.RED;
      title = loc('sntTerms.dateExpired');
    }
    return {
      color,
      title,
    };
  };

  return [
    {
      name: 'mark',
      label: '',
      getContent: (row) => {
        return <RowMark color={solveMark(row).color} />;
      },
      getTitle: (row) => solveMark(row).title,
      hideInSettings: true,
      style: {
        width: '50px',
        textAlign: 'center',
        paddingLeft: '0',
      },
    },
    {
      name: 'registrationNumber',
      label: loc('sntTerms.registrationNumber'),
      style: {
        width: '350px',
      },
    },
    {
      name: 'number',
      label: loc('sntTerms.number'),
      style: {
        width: '270px',
      },
    },
    {
      name: 'date',
      label: loc('sntTerms.regDate'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'sntType',
      label: loc('common.snt.type'),
      getContent: (row) =>
        !!row.sntType && loc(`common.snt.type.${row.sntType}`),
      style: {
        width: '200px',
      },
    },
    {
      name: 'customerChangeStatusDate',
      label: loc('sntTerms.confirmDate'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'confirmDeadlineDate',
      label: loc('sntTerms.termDate'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'status',
      label: loc('common.snt.status'),
      getContent: (row) =>
        !!row.status && loc(`common.snt.status.${row.status}`),
      style: {
        width: '200px',
      },
    },
    {
      name: 'sellerTin',
      label: loc('common.labels.sellerTin'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'sellerName',
      label: loc('common.labels.sellerName'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'customerTin',
      label: loc('common.labels.recipientTin'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'customerName',
      label: loc('common.labels.recipientName'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'sntNotificationDate',
      label: loc('sntTerms.sntNotificationDate'),
      style: {
        width: '250px',
      },
    },
    {
      name: 'signDate',
      label: loc('sntTerms.signDate'),
      style: {
        width: '250px',
      },
    },
  ];
};
