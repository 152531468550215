import React, { CSSProperties, Fragment } from 'react';
import clsx from 'clsx';
import t1 from './Field.module.scss';
import t2 from './FieldT2.module.scss';
import { FieldDirection } from './FieldDirection';
import { FieldLabel } from './FieldLabel';
import { UITheme } from '../types';
import { ErrorSign } from '../elements/error-sign/ErrorSign';

const styles = {
  T1: t1,
  T2: t2,
};

export type UIFieldProps = {
  className?: string;
  style?: React.CSSProperties;
  direction?: FieldDirection;
  label?: string;
  labelClassName?: string;
  htmlFor?: string;
  readOnly?: boolean;
  errorMessage?: string;
  noMargin?: boolean;
  /**
   * для импута, при горизонтальном направлении, чтобы занимал все оставшееся пространство
   */
  innerFullWidth?: boolean;
  theme?: UITheme;
  errorVariant?: 'text' | 'icon' | 'inside-icon';
  errorStyle?: CSSProperties;
};

export const Field: React.FC<UIFieldProps> = ({
  className,
  style,
  children,
  direction = 'stacked',
  label,
  labelClassName,
  htmlFor,
  readOnly,
  errorMessage,
  noMargin,
  innerFullWidth,
  theme = 'T1',
  errorVariant = 'text',
  errorStyle,
}) => {
  const s = styles[theme];
  const renderErrorMessage = () =>
    !!errorMessage &&
    (errorVariant === 'text' ? (
      <span className={s.errorMessage}>{errorMessage}</span>
    ) : (
      <ErrorSign
        className={s.errorIcon}
        errorMessage={errorMessage}
        style={errorStyle}
        small={errorVariant === 'inside-icon'}
      />
    ));

  return (
    <Fragment>
      <div
        className={clsx(className, s.wrapper, s[theme], {
          [s.inline]: direction === 'line' || direction === 'line-reverse',
          [s.topMargin]: !noMargin,
          [s.errorInsideIcon]: errorVariant === 'inside-icon',
        })}
        style={style}
      >
        {!!label && (
          <FieldLabel
            label={label}
            htmlFor={htmlFor}
            direction={direction}
            readOnly={readOnly}
            className={clsx(s.label, labelClassName)}
            theme={theme}
          />
        )}
        <div className={clsx(s.inner, { [s.innerFullWidth]: innerFullWidth })}>
          {children}
          {renderErrorMessage()}
        </div>
      </div>
      {}
    </Fragment>
  );
};
