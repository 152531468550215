import React, { Fragment } from 'react';
import s from './RegAgreement.module.scss';
import { Lang, useLocale } from 'services/l10n';
import clsx from 'clsx';

type Props = {
  contentClassName?: string;
};

export const RegAgreementContent: React.FC<Props> = ({ contentClassName }) => {
  const locale = useLocale();
  const { choose, lang } = locale;

  return (
    <Fragment>
      <div className={s.contentHeader}>
        <h2>
          {choose([
            'Соглашение об использовании информационной системы\n электронных счетов-фактур',
            'Келісім ақпараттық жүйені пайдалану туралы\n электрондық шот-фактуралар',
          ])}
        </h2>
      </div>
      <div className={clsx(s.content, contentClassName)}>
        {lang === Lang.RU ? (
          <Fragment>
            <h3>Глава 1. Общие положения</h3>
            <h4>1. Понятия, используемые в настоящем Соглашении:</h4>
            <p>
              поверенный (оператор) – юридическое лицо, участвующее в отношениях, регулируемых налоговым
              законодательством Республики Казахстан по счетам-фактурам, и действующее на основании договора поручения
              или соглашения (контракта) о разделе продукции;
            </p>
            <p>
              уполномоченное физическое лицо – работник юридического лица (структурного подразделения юридического лица)
              или индивидуального предпринимателя либо лица, занимающегося частной практикой, наделенный на основании
              доверенности правами осуществлять от имени такого юридического лица (структурного подразделения
              юридического лица) или индивидуального предпринимателя либо лица, занимающегося частной практикой,
              операции в процессе документооборота в информационной системе электронных счетов-фактур. При этом право
              подписи ЭСФ дается только от имени юридического лица (структурного подразделения юридического лица);
            </p>
            <p>
              электронный документ – документ, в котором информация представлена в электронно-цифровой форме и
              удостоверена посредством электронной цифровой подписи;
            </p>
            <p>
              электронный счет-фактура (далее – ЭСФ) – документ, выписанный посредством ИС ЭСФ и соответствующий
              требованиям норм налогового законодательства и Правилам выписки счет-фактуры в электронной форме в
              информационной системе электронных счетов-фактур;
            </p>
            <p>
              электронная цифровая подпись (далее – ЭЦП) – набор электронных цифровых символов, созданный средствами
              электронной цифровой подписи, подтверждающий достоверность электронного документа, его принадлежность и
              неизменность содержания;
            </p>
            <p>
              информационная система электронных счетов-фактур (далее – ИС ЭСФ) – информационная система уполномоченного
              органа, посредством которой осуществляются выписка, отправка, прием, регистрация, обработка, передача,
              получение и хранение счетов-фактур, выписанных в электронной форме;
            </p>
            <p>участник ИС ЭСФ – лицо, подписавшее Соглашение об использовании ИС ЭСФ и зарегистрированное в ИС ЭСФ;</p>
            <p>оператор ИС ЭСФ – государственный орган, являющийся администратором ИС ЭСФ.</p>

            <h4>Глава 2. Предмет Соглашения</h4>
            <ol>
              <li>
                2. По настоящему Соглашению участник ИС ЭСФ принимает на себя обязательство по использованию ИС ЭСФ,
                посредством которой осуществляются выписка, отправка, прием, регистрация, обработка, передача, получение
                и хранение ЭСФ.
              </li>
              <li>3. Оператор ИС ЭСФ обеспечивает участнику ИС ЭСФ доступ к ИС ЭСФ.</li>
              <li>
                4. По настоящему Соглашению ЭСФ выписывается, передается и принимается посредством ИС ЭСФ, подписывается
                ЭЦП и хранится в ИС ЭСФ.
              </li>
            </ol>

            <h4>Глава 3. Права и обязанности участника ИС ЭСФ</h4>
            <ol>
              <li>
                5. Участник ИС ЭСФ наделяется правами и принимает на себя обязанности, предусмотренные Налоговым
                кодексом, Правилами, а также настоящим Соглашением.
              </li>
              <li>
                6. Физическое лицо – участник ИС ЭСФ в соответствии с настоящим Соглашением и Правилами обладает правом
                просмотра ЭСФ в ИС ЭСФ, выписанных им самим, если является уполномоченным физическим лицом юридического
                лица (структурного подразделения юридического лица) и его контрагентами.
              </li>
              <li>
                7. Участник ИС ЭСФ обязуется выписывать ЭСФ с соблюдением законодательства Республики Казахстан об
                электронном документе и ЭЦП.
              </li>
              <li>
                8. В случае смены руководителя юридического лица (структурного подразделения юридического лица)
                уполномоченный представитель участника ИС ЭСФ – юридического лица (структурного подразделения
                юридического лица) обязан обратиться:
                <ul>
                  <li>
                    1) в органы юстиции или в государственную корпорацию «Правительство для граждан» по месту нахождения
                    участника ИС ЭСФ – юридического лица (структурного подразделения юридического лица) с заявлением для
                    изменения сведений о руководителе юридического лица (структурного подразделения юридического лица) –
                    в соответствии с Законом Республики Казахстан от 17 апреля 1995 года «О государственной регистрации
                    юридических лиц и учетной регистрации филиалов и представительств»;
                  </li>
                  <li>
                    2) в Национальный удостоверяющий центр (далее – НУЦ РК) – за получением нового регистрационного
                    свидетельства в порядке, установленном законодательством Республики Казахстан.
                  </li>{' '}
                </ul>
                <span>
                  В случае если ЭСФ выписан руководителем юридического лица (структурного подразделения юридического
                  лица), который на дату выписки не являлся руководителем указанного юридического лица (структурного
                  подразделения юридического лица), то ответственность за выписанный ЭСФ несет новый руководитель.
                </span>
              </li>
              <li>
                9. Юридические лица (структурные подразделения юридического лица) или индивидуальные предприниматели
                либо лица, занимающиеся частной практикой, определяют уполномоченное физическое лицо или поверенного
                (оператора) для работы в ИС ЭСФ на основании доверенности, составляемой в электронной форме по форме
                согласно приложению к настоящему Соглашению.
              </li>
              <li>
                10. Руководитель юридического лица (структурные подразделения юридического лица) или индивидуальный
                предприниматель либо лицо, занимающееся частной практикой, вправе корректировать, просматривать
                предоставленные уполномоченному физическому лицу или поверенному (оператору) права либо лишить его этих
                прав.
              </li>
              <li>
                11. Юридическое лицо (структурное подразделение юридического лица) – участник ИС ЭСФ вправе предоставить
                право подписи ЭСФ от имени юридического лица (структурного подразделения юридического лица) нескольким
                работникам юридического лица (структурного подразделения юридического лица) одновременно.
              </li>
              <li>
                12. Юридическое лицо (структурное подразделение юридического лица) или индивидуальный предприниматель
                либо лицо, занимающееся частной практикой – участник ИС ЭСФ в случае принятия решения об отмене
                доверенности отзывает ее в срок не позднее одного календарного дня с момента принятия такого решения в
                порядке, установленном законодательством Республики Казахстан.
              </li>
              <li>
                13. Участник ИС ЭСФ в случае отказа в использовании ИС ЭСФ обязан направить оператору ИС ЭСФ уведомление
                в виде письма, телеграммы или факса с последующим представлением оригинала. При этом отказ в
                использовании ИС ЭСФ невозможен участниками ИС ЭСФ, которые обязаны выписывать ЭСФ в рамках налогового
                законодательства Республики Казахстан.
              </li>
            </ol>

            <h4>Глава 4. Ответственность</h4>

            <ol>
              <li>
                14. Участник ИС ЭСФ, а также уполномоченные физические лица, а также поверенный (оператор) несут
                ответственность за достоверность и полноту сведений, содержащихся в ЭСФ.
              </li>
              <li>15. Участник ИС ЭСФ несет:</li>
              <li>
                ответственность за правомерность проставления ЭЦП на ЭСФ, а также за последствия, возникшие в результате
                выписки ЭСФ;
              </li>
              <li>ответственность за подлинность реквизитов, указанных в настоящем Соглашении;</li>
              <li>иную ответственность, предусмотренную законодательством Республики Казахстан.</li>
              <li>16. Участник ИС ЭСФ не несет ответственность:</li>
              <li>
                за нарушения условий настоящего Соглашения, если указанные нарушения произошли в результате
                неисправности ИС ЭСФ по не зависящим от него обстоятельствам;
              </li>
              <li>
                за неисполнение условий настоящего Соглашения, если они явились результатом форс-мажорных обстоятельств.
              </li>
            </ol>
            <p>
              Для целей настоящего Соглашения «форс-мажор» означает событие, не подвластное контролю участника и
              оператора ЭСФ, имеющее непредвиденный характер. Такие события могут включать, но не исключительно: военные
              действия, природные или стихийные бедствия, эпидемию, карантин и другие.
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <h3>1 тарау. Жалпы ережелер</h3>
            <h4>1. Осы Келісімде пайдаланылатын ұғымдар:</h4>
            <p>
              Сенімді өкіл (оператор) – Қазақстан Республикасының шот-фактуралар жөніндегі салық заңнамасымен реттелетін
              қатынастарға қатысатын, өнімді бөлу туралы тапсырма шарттың немесе келісімнің (келісімшарттың) негізінде
              әрекет ететін заңды тұлға;
            </p>
            <p>
              Уәкілетті жеке тұлға - электронды шот-фактуралардың ақпараттық жүйесінде заңды тұлғаның (заңды тұлғаның
              құрылымдық бөлімшесі) немесе жеке кәсіпкер немесе жеке тәжірибемен айналысатын тұлға атынан құжат айналымы
              үрдісі операцияларын орындауға сенімхат берілген заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесі)
              немесе жеке кәсіпкер немесе жеке тәжірибемен айналысатын тұлға қызметкері. Сонымен бірге, ЭШФ-ға қол қою
              құқығы тек заңды тұлғаға (заңды тұлғаның құрылымдық бөлімшесіне) беріледі;
            </p>
            <p>
              электрондық құжат - ақпарат электрондық сандық нысанда ұсынылған және электрондық сандық қолтаңба арқылы
              куәландырылған құжат;
            </p>
            <p>
              электрондық шот-фактура (бұдан әрі - ЭСФ) - ЭШФ АЖ арқылы жазылған және салық заңнамасы және электрондық
              шот-фактура ақпараттық жүйедесінде электронды түрде шот-фактураны жазу ережесі талаптарына сәйкес құжат
              болып табылады;
            </p>
            <p>
              электрондық сандық қолтаңба (бұдан әрі - ЭСҚ) - электрондық құжаттың түпнұсқалығын, оның мазмұнының
              тұрақтылығы мен тиістілігін растайтын электрондық сандық қолтаңба арқылы жасалған электрондық сандық
              таңбалар жиынтығы;
            </p>

            <p>
              электрондық шот-фактуралардың ақпараттық жүйесі (бұдан әрі – ЭШФ АЖ) – уәкілетті органның ақпараттық
              жүйесі, ол арқылы электрондық нысанда жазып берілетін шот-фактураларды жазып беру, жіберу, қабылдау,
              тіркеу, өңдеу, беру, алу және сақтау жүзеге асырылады;
            </p>
            <p>ЭШФ АЖ қатысушы – ЭШФ АЖ пайдалану туралы келісімге қол қойған және ЭШФ АЖ-да тіркелген тұлға;</p>
            <p>ЭШФ АЖ операторы – ЭШФ АЖ әкімшісі болып табылатын мемлекеттік орган</p>

            <h4>2-тарау. Келісімнің нысанасы</h4>
            <ol>
              <li>
                2. Осы Келісім бойынша ЭШФ АЖ қатысушы өзіне ЭШФ жазып беру, жөнелту, қабылдау, тіркеу, өңдеу, беру, алу
                және сақтау жүзеге асырылатын ЭШФ АЖ пайдалану бойынша міндеттерді қабылдайды.
              </li>
              <li>3. ЭШФ операторы ЭШФ АЖ қатысушыға ЭШФ АЖ-не рұқсат беруді қамтамасыз етеді.</li>
              <li>
                4. Осы Келісім бойынша ЭШФ жазып беріледі, ЭШФ АЖ арқылы жөнелтіледі және қабылданады, ЭЦҚ қол қойылады
                және ЭШФ АЖ-де сақталады.
              </li>
            </ol>

            <h4>3 тарау. ЭШФ АЖ қатысушысының құқықтары мен міндеттері</h4>
            <ol>
              <li>
                5. ЭШФ АЖ қатысушыға Салық кодексінде, ЭШФ құжат айналымы қағидаларында, сондай-ақ осы Келісімде
                көзделген құқықтар беріледі және ол өзіне міндеттерді қабылдайды.
              </li>
              <li>
                6. Жеке тұлға – ЭШФ АЖ қатысушы осы Келісімге және ЭШФ құжат айналымы қағидаларына сәйкес ЭШФ АЖ-де өзі,
                егер уәкілетті жеке тұлға болып табылатын заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) және
                оның контрагенттері жазып берген ЭШФ қарауға құқылы.
              </li>
              <li>
                7. ЭШФ АЖ қатысушысы электрондық құжат және ЭЦҚ туралы Қазақстан Республикасының заңын сақтай отырып,
                ЭШФ жазып беруге міндеттенеді.
              </li>
              <li>
                8. Заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) жетекшісі ауысқан жағдайда, ЭШФ АЖ қатысушы
                заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) уәкілетті өкілі байланысуы қажет:
                <ul>
                  <li>
                    ЭШФ қатысушы заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) орналасқан орны бойынша
                    «Азаматтар үшін үкімет» әділет органына немесе мемлекеттік корпорацияға – заңды тұлғаның (заңды
                    тұлғаның құрылымдық бөлімшесінің) жетекшілері туралы мәліметтер өзгерту үшін өтінішпен – «Заңды
                    тұлғаларды мемлекеттік тіркеу және филиалдар мен өкілдіктерді есептік тіркеу туралы» Қазақстан
                    Республикасының 1995 жылғы 17 сәуiрдегі Заңына сәйкес;
                  </li>
                  <li>
                    2) Ұлттық куәландырушы орталығына (бұдан әрі - ҚР ҰКО) - Қазақстан Республикасының заңнамасында
                    белгіленген тәртіппен жаңа тіркеу куәлігін алу үшін
                  </li>
                </ul>
                <span>
                  Егер заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) жетекшісі ЭШФ құру мерзімінде заңды
                  тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) жетекшісі болып табылмаса, онда жаңа жетекші
                  жазылған ЭШФ үшін жауапты болады.
                </span>
              </li>
              <li>
                9. Заңды тұлға (заңды тұлғаның құрылымдық бөлімшесі) немесе жеке кәсіпкер немесе жеке тәжірибемен
                айналысатын тұлға ЭШФ АЖ-де жұмыс істеу үшін осы Келісімге қосымшаға сәйкес нысан бойынша электрондық
                нысанда жасалған сенімхат негізінде уәкілетті жеке тұлға немесе сенімді (оператор) айқындайды
              </li>
              <li>
                10. Заңды тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) жетекшісі немесе жеке кәсіпкер немесе жеке
                тәжірибемен айналысатын тұлға уәкілетті жеке тұлғаларға немесе сенімді (операторға) берілген құқықтарды
                түзетуге, қарауға не оны осы құқықтардан айыруға құқылы.
              </li>
              <li>
                11. Заңды тұлға (заңды тұлғаның құрылымдық бөлімшесі) - ЭШФ АЖ қатысушы заңды тұлға атынан заңды
                тұлғаның (заңды тұлғаның құрылымдық бөлімшесінің) бірнеше қызметкеріне бір мезгілде ЭШФ қол қою құқығын
                беруге құқылы.
              </li>
              <li>
                12. Заңды тұлға (заңды тұлғаның құрылымдық бөлімшесі) немесе жеке кәсіпкер немесе жеке тәжірибемен
                айналысатын тұлға - ЭШФ АЖ қатысушы сенімхатты жою туралы шешім қабылданған жағдайда, Қазақстан
                Республикасының заңнамасында белгіленген тәртіппен осындай шешім қабылданған сәттен бастап күнтізбелік
                бір күннен кешіктірмей оны қайтарып алады.
              </li>
              <li>
                13)ЭШФ АЖ қатысушысы ЭШФ АЖ пайдаланудан бас тартқан жағдайда, кейіннен түпнұсқасы жеткізіліп берілетін
                жазбаша хабарламаны хатпен, жеделхатпен немесе факспен ЭШФ АЖ операторына жіберуге міндетті. Бұл ретте
                ЭШФ АЖ пайдалануды қайтару Қазақстан Республикасының салық заңнамасы шеңберінде ЭШФ жазып беруге
                міндетті ЭШФ АЖ қатысушыларымен мүмкін емес.
              </li>
            </ol>

            <h4>4-тарау. Жауапкершілік</h4>
            <ol>
              <li>
                14. ЭШФ АЖ қатысушы, сондай-ақ уәкілетті жеке тұлғалар, сондай-ақ сенімді өкіл(оператор) ЭШФ-ға
                қамтылған мәліметтердің дұрыстығы мен толықтығына жауапты болады.
              </li>
              <li>15. ЭШФ АЖ қатысушысы:</li>
              <li>ЭШФ-ға ЭСҚ қойылу заңдылығы, сондай-ақ ЭШФ-ны құру нәтижесінде туындайтын зардаптар үшін жауапты;</li>
              <li>осы Келісімде көрсетілген деректемелердің түпнұсқалығы үшін жауапты;</li>
              <li>Қазақстан Республикасының заңнамасында көзделген өзге де жауаптылықта болады.</li>
              <li>16. ЭШФ АЖ қатысушы жауапты емес:</li>
              <li>
                осы Келісімнің шарттарының бұзылуына егер көрсетілген бұзушылықтар, оның мән-жайларына қатысты болмай,
                ЭШФ АЖ ақауының нәтижесінде болса;
              </li>
              <li>
                егер осы Келісімнің шарттарының орындалмауы форс-мажор мән-жайларының нәтижесі болса, бұл үшін жауапты
                болмайды.
              </li>
            </ol>
            <p>
              Осы Келісімнің мақсаты үшін «форс-мажор» қатысушы және ЭШФ операторының күтпеген сипаттағы бақылауға алуға
              болмайтын оқиғаны білдіреді. Мұндай оқиғалар әскери іс-әрекеттер, табиғи немесе зілзала апаттары, індет,
              карантин және басқаны қамтуы мүмкін, бірақ олармен шектелмейді.
            </p>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};
