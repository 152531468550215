import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './BioRiskyUsers.module.scss';
import { Button, Loader, PageTitle, Table } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { getBioRiskyUsersColumns } from './getBioRiskyUsersColumns';
import { queryAdminRiskyTaxpayerReportUsers, queryAdminRiskyTaxpayerStatistics } from '../api';

type Props = {
  className?: string;
  onClose?: () => void;
  /**
   * Данные ЮЛ из таблицы Рисковых НП
   */
  txpData: any;
};

const getProductId = (row: any) => row.id;

// Страница «Сведения по биометрии»
export const BioRiskyUsers: React.FC<Props> = ({ className, onClose, txpData }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, error } = useDataQuery(queryAdminRiskyTaxpayerStatistics, {
    tin: txpData?.tin,
  });

  const productsColumns = useMemo(() => getBioRiskyUsersColumns({ locale }), [locale]);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  const exportJournal = async (format: string) => {
    try {
      const res = await queryAdminRiskyTaxpayerReportUsers({
        filter: { tinList: [txpData?.tin] },
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('bioRisky.users')}</PageTitle>
      <BackButton onClick={onClose} />

      <h2 className={s.taxpayerName}>{txpData?.taxpayerName}</h2>
      <p className={s.bin}>
        {loc('common.labels.binOnly')}: {txpData?.tin}
      </p>
      <div className={s.buttons}>
        <Button size="small" onClick={() => exportJournal('XLSX')}>
          {loc('common.button.exportExcel')}
        </Button>
      </div>
      {Array.isArray(data) ? (
        <Table columns={productsColumns} className={s.table} rows={data} getRowId={getProductId} scrollable />
      ) : (
        <Loader />
      )}
    </div>
  );
};
