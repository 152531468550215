import { FilterContentProps, FormDoubleDatePicker, FormListUploader } from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getRiskCategoryOptions, getTxpActiveOptions, getVerStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const BioRiskyJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection } = classNames || {};

  const txpStatusOptions = useMemo(() => getTxpActiveOptions(locale), [locale]);
  const verStatusOptions = useMemo(() => getVerStatusOptions(locale), [locale]);
  const riskCategoryOptions = useMemo(() => getRiskCategoryOptions(locale), [locale]);

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection} style={{ alignItems: 'start' }}>
          <div style={{ gridRow: 'span 2' }}>
            <FormField name="iin" label={loc('common.labels.binTxp')}>
              <FormInput maxLength={12} onlyDigits />
            </FormField>
            <FormListUploader name="tinList" fileType="xlsx" labelCount={loc('bioRisky.count')} />
          </div>

          <Field label={loc('bioRisky.addDate')} noMargin>
            <FormDoubleDatePicker nameFrom="createDateFrom" nameTo="createDateTo" maxDate={new Date()} />
          </Field>

          <FormField name="isActive" label={loc('bioRisky.status')} noMargin>
            <FormSelect options={txpStatusOptions} />
          </FormField>

          <FormField name="verificationStatus" label={loc('bioRisky.verStatus')} noMargin>
            <FormSelect options={verStatusOptions} />
          </FormField>

          <div></div>
          <FormField name="riskCategory" label={loc('bioRisky.riskCategory')} noMargin>
            <FormSelect options={riskCategoryOptions} />
          </FormField>
          <Field label={loc('bioRisky.verDate')} noMargin>
            <FormDoubleDatePicker nameFrom="verificationDateFrom" nameTo="verificationDateTo" maxDate={new Date()} />
          </Field>
        </div>
      </div>
    </Fragment>
  );
};
