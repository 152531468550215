import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryStatisticReport: DataQueryFunction = async (variables: any) => {
  const { filter = {}, format = '' } = variables || {};

  try {
    const res = await downloadFile({
      url: `${API_URL}/arm/statistic/generateReport`,
      method: 'POST',
      params: { ...clearEmptyFields(filter), format },
    });
    console.log('res1', res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
