import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminGovInstDelete: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { tin } = variables;

  const res = await httpClient.delete('/admin/governmentInstitution/delete', { params: { tin } });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
