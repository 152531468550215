import React, { ReactNode } from 'react';
import clsx from 'clsx';
import './DoubleDateWrapperT2.scss';

type Props = {
  className?: string;
  renderLeft: (className: string, labelClassName: string) => ReactNode;
  renderRight: (className: string, labelClassName: string) => ReactNode;
  error?: boolean;
};

/**
 * Обертка для отображения двойного датапикера для темы Т2.
 * Определяет только стили отображения
 * renderLeft/renderRight - рендер пропсы для рендера DatePicker
 */
export const DoubleDateWrapperT2: React.FC<Props> = ({ className, renderLeft, renderRight, error }) => {
  return (
    <div
      className={clsx(className, 'double-date--t2', {
        'double-date--error': error,
      })}
    >
      {renderLeft('double-date--picker double-date--picker__left', 'double-date--picker-label')}
      {renderRight('double-date--picker double-date--picker__right', 'double-date--picker-label')}
    </div>
  );
};
