import { kk, ru } from 'date-fns/locale';
import React from 'react';
import { formatFromDate, formatToDate } from 'services/date-time';
import { Lang, useLocale } from 'services/l10n';
import { FormDatePicker, FormDatePickerProps } from 'ui/common';

type Props = FormDatePickerProps;

export const FormLocaleDatePicker: React.FC<Props> = ({ ...rest }) => {
  const { lang, loc } = useLocale();
  return (
    <FormDatePicker
      formatFromDate={formatFromDate}
      formatToDate={formatToDate}
      locale={lang === Lang.KK ? kk : ru}
      timeCaption={loc('common.labels.time')}
      placeholderText={loc('c.chooseDate')}
      {...rest}
    />
  );
};
