import React, { ChangeEvent, memo, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './Table.module.scss';
import { Checkbox } from 'ui/common/controls/checkbox';
import { RowAction, TableColumn } from './types';
import { TableRow } from './TableRow';
import { TableRowDnD } from './TableRowDnD';

const emptyColumns: Array<TableColumn> = [];

type Props = {
  className?: string;
  columns?: Array<TableColumn>;
  rows?: Array<any>;
  selectable?: boolean;
  getRowId?: (row: any, index: number) => string | number;
  getRowActions?: (row: any) => Array<RowAction>;
  getRowStyle?: (row: any) => { [key: string]: string } | undefined;
  onChangeSelected?: (selectedRows: Array<any>) => void;
  actionColumn?: boolean;
  variant?: 'default' | 'clean';
  scrollable?: boolean;
  loading?: boolean;
  loaderRender?: () => React.ReactNode;
  oneLine?: boolean;
  dragable?: boolean;
  dropable?: boolean;
  onDrop?: (item: any, row: any, monitor: any) => void;
  border?: boolean;
};

export const Table: React.FC<Props> = memo(
  ({
    className,
    columns = emptyColumns,
    rows,
    selectable = false,
    getRowId = (row: any) => row.id,
    getRowActions,
    getRowStyle = (row: any) => undefined,
    onChangeSelected,
    actionColumn,
    variant,
    scrollable,
    loading,
    loaderRender,
    oneLine,
    dragable,
    dropable,
    onDrop,
    border,
  }) => {
    const [selected, setSelected] = useState<{ [key: string]: boolean }>({});

    const [selectedAll, setSelectedAll] = useState(false);

    const filteredColumns = useMemo(() => columns.filter((col) => !(col.hidden || col.exclude)), [columns]);

    const onSelectRow = (row: any, index: number, selected: boolean, selectOne: boolean = false) => {
      if (selectOne) {
        setSelected(() => ({
          [getRowId(row, index)]: selected,
        }));
      } else {
        setSelected((state) => ({
          ...state,
          [getRowId(row, index)]: selected,
        }));
      }
    };

    const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
      if (Array.isArray(rows)) {
        const checked = e.target.checked;
        setSelectedAll(checked);
        setSelected((state) =>
          checked ? Object.fromEntries(rows.map((row, index) => [[getRowId(row, index)], true])) : {}
        );
      }
    };

    const TableRowComponent = dragable || dropable ? TableRowDnD : TableRow;

    useEffect(() => {
      if (selectedAll && Object.values(selected).some((s) => s === false)) {
        setSelectedAll(false);
      }
    }, [selected]); // eslint-disable-line

    useEffect(() => {
      if (typeof onChangeSelected === 'function' && Array.isArray(rows)) {
        onChangeSelected(rows.filter((row, index) => selected[getRowId(row, index)]));
      }
    }, [selected]); // eslint-disable-line

    useEffect(() => {
      setSelected({});
    }, [rows]); // eslint-disable-line

    return (
      <div
        className={clsx(className, s.wrapper, {
          [s.clean]: variant === 'clean',
          [s.selectable]: selectable,
          [s.oneLine]: oneLine,
          [s.border]: border,
        })}
      >
        {loading && typeof loaderRender === 'function' && <div className={s.loaderContainer}>{loaderRender()}</div>}

        <div className={clsx(s.inner, { [s.scrollable]: scrollable })}>
          <table>
            <thead>
              <tr>
                {selectable && (
                  <th className={s.checkCell}>
                    <Checkbox checked={selectedAll} onChange={(e) => onSelectAll(e)} className={s.checkBox} />
                  </th>
                )}
                {filteredColumns.map((col) => (
                  <th key={col.name} style={{ ...(col.style || {}), ...(col.width ? { width: col.width } : {}) }}>
                    {col.label}
                  </th>
                ))}
                {actionColumn && <th className={s.actionColumn}></th>}
              </tr>
            </thead>
            <tbody className={clsx({ [s.loading]: loading })}>
              {Array.isArray(rows) &&
                rows.map((row: any, index: number) => (
                  <TableRowComponent
                    key={getRowId(row, index)}
                    row={row}
                    rowIndex={index}
                    columns={filteredColumns}
                    getRowId={getRowId}
                    getRowStyle={getRowStyle}
                    getRowActions={getRowActions}
                    onSelect={onSelectRow}
                    selectable={selectable}
                    selected={selected[getRowId(row, index)]}
                    actionColumn={actionColumn}
                    dragable={dragable}
                    dropable={dropable}
                    onDrop={onDrop}
                  />
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
);
