import { isEmpty } from 'common/utils';
import { convertToFormDataDeep } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceCheckCanCreateInvoiceForSnt: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  const formData = convertToFormDataDeep({
    sntId: id,
  });

  let res: any = await httpClient.post(`/invoice/checkCanCreateInvoiceForSnt`, formData);

  // if (false) {
  //   res = {
  //     data: {
  //       errors: [
  //         {
  //           property: 'deliveryDocNum',
  //           errorCode: 'SNT_BASED_INVOICE_IMPORT_TYPE_NOT_ALLOWED',
  //           text: 'Запрет выписки ЭСФ на основании СНТ с ввозом на территорию РК',
  //         },
  //       ],
  //     },
  //   };
  // }

  if (!isEmpty(res.data?.errors)) {
    console.log('throw', res);
    throw res;
  } else {
    return res;
  }
};
