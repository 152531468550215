import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceStatisticsGet: DataQueryFunction = async () => {
  // if (process.env.NODE_ENV === 'development') {
  //   await delay(500);
  //   return {
  //     data: {
  //       tin: '900519300416',
  //       invoiceCountQuarter: 20000,
  //       invoiceSummaQuarter: 100000.0,
  //       invoiceSummaNdsQuarter: 50000.0,
  //       invoiceCountYear: 40000,
  //       invoiceSummaYear: 60000000,
  //       invoiceSummaNdsYear: 100000.0,
  //       aggregationDate: '04.11.2023',
  //       warnApproachingLimitAmount: 49000000,
  //       warnCrossingLimitAmount: 69000000,
  //     },
  //   } as AxiosResponse;
  // }

  return httpClient.get(`/invoice/statistics/get`);
};
