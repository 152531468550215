import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVirtualLabelIdByProductId: DataQueryFunction = async (variables: any) => {
  const { productId, documentNumber } = variables || {};

  const res = await httpClient.get(
    `/admin/virtualLabelChain/virtualLabelIdByProductId/${productId}?documentNumber=${documentNumber}`
  );
  // const res = { data: { data: 432456, success: true } };
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
