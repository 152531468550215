import React from 'react';
import clsx from 'clsx';
import s from './PassportVerifyPage.module.scss';
import { PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { PassportVerifyForm } from './PassportVerifyForm';
import { mutationEaecAssuranceFind } from 'rest/api/eaec';
import { generatePath, useHistory } from 'react-router';
import { PATH } from 'routing/paths';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = {
  className?: string;
};

/**
 * Страница поиска паспорта для ЕАЕС пользователя
 * /eaec/passport
 */
export const PassportVerifyPage: React.FC<Props> = ({ className }) => {
  const history = useHistory();
  const locale = useLocale();
  const { loc } = locale;

  const verifyPassport = async (values: any) => {
    try {
      const res = await mutationEaecAssuranceFind({ body: values });
      const passportId = res?.data?.passport?.stringPassportId;
      if (passportId) {
        history.push(generatePath(PATH.EAEC_PASSPORT, { id: passportId }));
      }
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('assurance.pasport')}</PageTitle>

      <div className={s.description}>
        <p className="fw500 m-0 mb-12">{loc('passport.description1')}</p>
        <p className="m-0 mb-12">{loc('passport.description2')}</p>
        <p className="m-0">{loc('passport.description3')}</p>
      </div>

      <PassportVerifyForm className={s.form} onSubmit={verifyPassport} />
    </div>
  );
};
