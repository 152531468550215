import React from 'react';
import clsx from 'clsx';
import s from './InsidePage.module.scss';
import { useLocale } from 'services/l10n';
import { PATH } from 'routing/paths';
import { NavLink } from 'react-router-dom';

type InsideMenuItem = {
  id: number;
  label: string;
  link: string;
};

type InsideMenuName = 'forDevelopers' | 'help';

type Props = {
  className?: string;
  menuName: InsideMenuName;
};

export const InsideMenu: React.FC<Props> = ({ className, menuName }) => {
  const locale = useLocale();
  const { loc } = locale;

  const menus: Record<InsideMenuName, Array<InsideMenuItem>> = {
    forDevelopers: [
      // {
      //   id: 1,
      //   label: loc('common.nav.testStand'),
      //   link: `${PATH.PAGES}${PATH.TEST_STAND}`,
      // },
      {
        id: 2,
        label: loc('common.nav.api'),
        link: `${PATH.PAGES}${PATH.API}`,
      },
    ],
    help: [
      {
        id: 1,
        label: loc('common.nav.tutor'),
        link: `${PATH.PAGES}${PATH.VIDEO_TUTOR}`,
      },
      {
        id: 2,
        label: loc('common.nav.guide'),
        link: `${PATH.PAGES}${PATH.GUIDES}`,
      },
    ],
  };

  return (
    <div className={clsx(className)}>
      {menus[menuName].map((item) => (
        <NavLink className={s.menuItem} key={item.id} to={item.link} activeClassName={s.activeLink}>
          {item.label}
        </NavLink>
      ))}
    </div>
  );
};
