import { clearEmptyFieldsDeep } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminGovInstSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1500);
  //   return { data: fakeGovInstSearch };
  // }

  const res = await httpClient.get(`/admin/governmentInstitution/search`, {
    params: {
      criteria: JSON.stringify(clearEmptyFieldsDeep(filter)),
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
