import { contentYesNo } from 'common/components';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getSmallRetailColumns = ({ locale }: { locale: LocaleStore }): Array<TableColumn> => {
  const { loc } = locale;
  return [
    {
      name: 'tin',
      label: loc('common.labels.tin'),
    },
    {
      name: 'taxpayerName',
      label: loc('common.labels.npName'),
      style: {
        width: '300px',
      },
    },
    {
      name: 'startDate',
      label: loc('small.regDate'),
    },
    {
      name: 'okedCode',
      label: loc('common.labels.oked'),
    },
    {
      name: 'secondaryOkedCodes',
      getContent: (row) => (Array.isArray(row?.secondaryOkedCodes) ? row.secondaryOkedCodes.join(', ') : ''),
      label: loc('common.labels.oked2'),
    },
    {
      name: 'isOnlineKkm',
      label: loc('small.onlineKkm'),
      getContent: (row) => contentYesNo(row.isOnlineKkm, locale),
    },
    {
      name: 'isNdsPayer',
      label: loc('small.ndsPayer'),
      getContent: (row) => contentYesNo(row.isNdsPayer, locale),
    },
    {
      name: 'snrType',
      label: loc('small.snrType'),
      getContent: (row) => loc(`small.snrType.${row.snrType}`),
    },
    {
      name: 'endDate',
      label: loc('small.closeDate'),
    },
    {
      name: 'closeCause',
      label: loc('small.closeCause'),
    },
    {
      name: 'isActive',
      label: loc('common.labels.status'),
      getContent: (row) => typeof row.isActive === 'boolean' && loc(`small.active.${row.isActive}`),
    },
  ];
};
