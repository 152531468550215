import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type Props = PropsWithChildren<{
  className?: string;
  /**
   * Ссылка внутри реакт приложения (имеет приоритет)
   */
  to?: string;
  /**
   * Ссылка в легаси приложении
   */
  legasyTo?: string;
}>;

/**
 * Компонент ссылки внутри приложения. Общий копонент ссылки для реакт и легаси фронта.
 * Рендерит либо Link либо <а/> тэг в зависимости от ссылки
 */
export const AppLink: React.FC<Props> = ({ className, to, legasyTo, children }) => {
  return to ? (
    <Link to={to} className={className}>
      {children}
    </Link>
  ) : legasyTo ? (
    <a href={legasyTo} className={className}>
      {children}
    </a>
  ) : null;
};
