import { Journal, JournalButton } from 'common/components/journal';
import React, { Fragment, useMemo, useState } from 'react';
import { queryAdminVstoreSearch, queryAdminRemainderExport, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { RemainderJournalArmColumns } from './RemainderJournalArmColumns';
import { RemainderJournalArmFilters } from './RemainderJournalArmFilters';
import { RemainderGoodsArm } from './RemainderGoodsArm';
import * as yup from 'yup';

const initialFilter = {};
const initialPagination = { rows: 10 };
const schema = yup.object({
  tin: yup.string().required('ИИН/БИН налогоплательщика является обязательным'),
});

type Props = {};

export const RemainderJournalArm: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: null,
  });

  const columns = useMemo(() => RemainderJournalArmColumns({ locale }), [locale]);

  const queryProps = useDataQuery(
    queryAdminVstoreSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
    },
    { skipInitialRequest: true }
  );

  const onCloseNested = () => setNestedPage({ opened: false, row: null });

  const headerButtons = useMemo(
    () => [
      {
        id: 1,
        label: loc('common.button.exportExcel'),
        onClick: () =>
          queryAdminRemainderExport({
            filter: queryProps.filter,
            format: 'XLSX',
          }),
      },
      {
        id: 2,
        label: loc('common.button.exportPdf'),
        onClick: () =>
          queryAdminRemainderExport({
            filter: queryProps.filter,
            format: 'PDF',
          }),
      },
      {
        id: 3,
        label: loc('common.button.exportCsv'),
        onClick: () =>
          queryAdminRemainderExport({
            filter: queryProps.filter,
            format: 'CSV',
          }),
      },
    ],
    [queryProps.filter, loc]
  );

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: (selection) => setNestedPage({ opened: true, row: selection[0] }),
    },
  ];

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.REMAINS')}
        defaultFilter={initialFilter}
        filterComponent={RemainderJournalArmFilters}
        filterValidationSchema={schema}
        filterInitialOpened
        columns={columns}
        queryProps={queryProps}
        headerButtons={headerButtons}
        hidden={nestedPage.opened}
        selectable
        actionButtons={actionButtons}
      />

      {nestedPage.opened ? <RemainderGoodsArm onClose={onCloseNested} row={nestedPage.row} /> : null}
    </Fragment>
  );
};
