import React, { Fragment } from 'react';
import s from './RegDataStep.module.scss';
import ss from '../../Registration.module.scss';
import { useLocale } from 'services/l10n';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { getAddressFieldName } from '../../helpers';
import { errorToString } from 'ui';
import { RegType } from 'features/registration/types';

type Props = {
  className?: string;
  regType: RegType;
};

export const RegDataIPAndPrivate: React.FC<Props> = ({ className, regType }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, errors } = useFormikContext<any>();

  const addressFieldName = getAddressFieldName(regType);

  const name = regType === RegType.ENTREPRENEUR ? values.individualName || values.fio : values.fio;

  return (
    <Fragment>
      <div className={clsx(ss.frame, s.frame)}>
        <div className={clsx(ss.credentials, ss.privateCredentials)}>
          <div>
            <div className={ss.label}>{loc('common.labels.name')}</div>
            <div className={ss.text}>{name}</div>
            {!!errors['individualName'] && (
              <span className={s.errorMessage}>{errorToString(errors['individualName'])}</span>
            )}
          </div>
          <div>
            <div className={ss.label}>{loc('reg.addressPlace')}</div>
            <div className={ss.text}>{values[addressFieldName]}</div>
            {!!errors[addressFieldName] && (
              <span className={s.errorMessage}>{errorToString(errors[addressFieldName])}</span>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
