import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './ScrollingLine.module.scss';
import { SvgIcon } from 'ui';

const delta = 200;
const controlWidth = 86;
const SCROLL_INTERVAL = 200;

type Props = {
  className?: string;
  innerClassName?: string;
};

export const ScrollingLine: React.FC<Props> = ({ className, innerClassName, children }) => {
  // смещение внутреннего контейнера (положительное число)
  const [innerOffset, setInnerOffset] = useState(0);
  const [showControl, setShowControl] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<any>(null);

  const getMaxOffset = () =>
    controlWidth + (innerRef.current?.scrollWidth || 0) - (wrapperRef.current?.clientWidth || 0);

  const onClickRight = () => {
    const scrollRight = () => {
      // console.log('scroll right');
      setInnerOffset((state) => {
        const newOffset = state + delta;
        const maxOffset = getMaxOffset();

        return newOffset < maxOffset ? newOffset : maxOffset;
      });
    };
    scrollRight();
    if (!intervalRef.current) {
      intervalRef.current = setInterval(scrollRight, SCROLL_INTERVAL);
    }
  };

  const onClickLeft = () => {
    const scrollLeft = () => {
      // console.log('scroll left');
      setInnerOffset((state) => {
        const newOffset = state - delta;
        return newOffset > 0 ? newOffset : 0;
      });
    };
    scrollLeft();
    if (!intervalRef.current) {
      intervalRef.current = setInterval(scrollLeft, SCROLL_INTERVAL);
    }
  };

  // eslint-disable-next-line
  useEffect(() => {
    const wrapperWidth = wrapperRef.current?.clientWidth;
    const innerWidth = innerRef.current?.scrollWidth;
    // console.log('wrapperWidth', wrapperWidth);
    // console.log('innerWidth', innerWidth);
    const needScroll = !!wrapperWidth && !!innerWidth && innerWidth > wrapperWidth;
    if (showControl !== needScroll) {
      setShowControl(needScroll);
    }
    if (!needScroll && innerOffset !== 0) {
      setInnerOffset(0);
    }
    if (needScroll) {
      const maxOffset = getMaxOffset();
      if (innerOffset > maxOffset) {
        setInnerOffset(maxOffset);
      }
    }
  });

  useEffect(() => {
    // console.log('addEventListener');
    const stopScroll = () => {
      // console.log('stop scroll');
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
    document.addEventListener('mouseup', stopScroll);

    return () => {
      // console.log('removeEventListener');
      document.removeEventListener('mouseup', stopScroll);
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      className={clsx(className, s.wrapper, {
        [s.showControl]: showControl,
      })}
    >
      <div ref={innerRef} className={clsx(innerClassName, s.inner)} style={{ left: -innerOffset }}>
        {children}
      </div>
      <div className={clsx(s.control, s.controlRight)} onMouseDown={onClickRight}>
        <SvgIcon className={s.icon} name="chevron-right" />
      </div>
      <div className={clsx(s.control, s.controlLeft)} onMouseDown={onClickLeft}>
        <SvgIcon className={s.icon} name="chevron-left" />
      </div>
    </div>
  );
};
