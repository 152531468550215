import React from 'react';
import clsx from 'clsx';
import s from './OgdTree.module.scss';
import { queryAdminOGDSearch } from 'rest';
import { useLocale } from 'services/l10n';
import { DictionaryNode } from 'ui/common';

type Props = {
  className?: string;
  data?: any;
};

export const OgdTree: React.FC<Props> = ({ className, data }) => {
  const { choose: locName, loc } = useLocale();

  const getHeader = (data: any) => {
    const { code, nameRu, nameKz, closed, rnn, bin } = data;
    return (
      <div className={s.nodeHeader}>
        <div className={s.nodeLabel}>
          ({code}) {locName([nameRu, nameKz])}
        </div>
        <div className={s.nodeRnn}>РНН: {rnn}</div>
        <div className={s.nodeBin}>БИН: {bin}</div>
        <div className={clsx(s.nodeClosed, { [s.closed]: closed })}>
          {closed ? loc('common.closed') : loc('common.opened')}
        </div>
      </div>
    );
  };

  const getChildren = (data: any) => {
    return queryAdminOGDSearch({
      filter: {
        parentCode: data.code,
      },
    }).then((res) => res.data);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      {Array.isArray(data) &&
        data.map((item) => (
          <DictionaryNode
            key={item.code}
            data={item}
            getHeader={getHeader}
            getChildren={getChildren}
            isFinal={(data) => data.level === 'DISTRICT'}
          />
        ))}
    </div>
  );
};
