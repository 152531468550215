import { LocaleStore } from 'services/l10n';
import { ASSURANCE_CORRIDOR, AssuranceStatus } from './constants';

export const getAssuranceStatusOptions = ({ loc }: LocaleStore): Array<{ value: string; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...Object.keys(AssuranceStatus).map((key) => ({
    value: key,
    label: loc(`assurance.${key}`),
  })),
];

export const getAssuranceCorridorOptions = ({ loc }: LocaleStore): Array<{ value: string; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...Object.keys(ASSURANCE_CORRIDOR).map((key) => ({
    value: key,
    label: loc(`assurance.${key}`),
  })),
];
