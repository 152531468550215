import React from 'react';
import clsx from 'clsx';
import s from './PageTitle.module.scss';
import { UITheme } from 'ui/common/types';

export type PageTitleProps = {
  className?: string;
  relative?: boolean;
  children?: React.ReactNode;
  theme?: UITheme;
};

export const PageTitle: React.FC<PageTitleProps> = ({ className, children, relative, theme = 'T1' }) => {
  return (
    <div
      className={clsx(className, s.wrapper, s[theme], {
        [s.relative]: relative,
      })}
    >
      <h1 className={s.title}>{children}</h1>
    </div>
  );
};
