import React from 'react';
import './App.scss';
import { Router } from 'react-router-dom';
import { history } from 'services/history/history';
import { Globals } from 'services/globals';
import { StoresProvider } from 'common/stores/StoresProvider';
import { RoutesProvider } from 'routing/components/RoutesProvider';

function App() {
  return (
    <Globals>
      <StoresProvider>
        {/* <Helmet titleTemplate="ЭФС | %s" defaultTitle="ЭФС" /> */}
        <Router history={history}>
          <RoutesProvider />
        </Router>
      </StoresProvider>
    </Globals>
  );
}

export default App;
