import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { ImportFromFileModal, Journal, JournalButton } from 'common/components';
import { getGovInstJournalColumns } from './getGovInstJournalColumns';
import { GovInstJournalFilters } from './GovInstJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { rowsOptionsVariant3 } from 'ui';
import { Modal } from 'services/modal';
import {
  queryAdminGovInstReport,
  queryAdminGovInstSearch,
  mutationAdminGovInstUploadFile,
  mutationAdminGovInstImportFromFile,
  queryAdminGovInstImportResult,
  mutationAdminGovInstDelete,
} from '../api';
import { formatFromDate } from 'services/date-time';
import { useStore } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';
import { GovInstAddModal } from '../gov-inst-add/GovInstAddModal';
import { getResourceUrl } from 'common/helpers';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.tin;

const filterStorageKey = 'GovInstJournal';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, Справочники -> "Справочник государственных учреждений"
 * arm/gov-inst
 */
export const GovInstJournal: React.FC<Props> = ({ className }) => {
  const { locale, user, dialog } = useStore();
  const { loc } = locale;

  const [addModal, setAddModal] = useState<boolean>(false);

  const [importModal, setImportModal] = useState<boolean>(false);

  const columns = useMemo(() => getGovInstJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(
    queryAdminGovInstSearch,
    {
      filter: SessionStorageService.get(filterStorageKey) || {
        createDateFrom: formatFromDate(new Date()),
        createDateTo: formatFromDate(new Date()),
      },
      pagination: initialPagination,
    },
    { preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }) }
  );

  const exportJournal = async (selection: Array<any>, format: string) => {
    const { tinList, ...rest } = preProcessFilter(queryProps.filter);
    try {
      const res = await queryAdminGovInstReport({
        filter: { ...rest, tinList: selection.length ? selection.map((s) => s.tin) : tinList },
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const deleteCompany = async (selection: Array<any>) => {
    const confirmed = await dialog.show({ content: loc('govInst.confirmDelete'), center: true });
    if (confirmed) {
      try {
        const res = await mutationAdminGovInstDelete({ tin: getRowId(selection[0]) });
        queryProps.refetch();
        return res;
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
        console.log(error);
      }
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.add'),
      onClick: () => setAddModal(true),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_EDIT),
    },
    {
      id: 2,
      label: loc('common.button.import'),
      onClick: () => setImportModal(true),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_EDIT),
    },
    {
      id: 3,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal(selection, 'XLSX'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.delete'),
      onClick: (selected) => deleteCompany(selected),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_EDIT),
    },
  ];

  const onCloseModal = (needRefetch?: boolean) => {
    setAddModal(false);
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.GOV_INST')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={GovInstJournalFilters}
        filterInitialOpened
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        paginationRowsOptions={rowsOptionsVariant3}
      />
      <Modal isOpen={addModal} onClose={onCloseModal} center>
        <GovInstAddModal />
      </Modal>

      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={mutationAdminGovInstImportFromFile}
          uploadFile={async (files: File[]) => {
            await mutationAdminGovInstUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryAdminGovInstImportResult}
          exampleLink={getResourceUrl('/data/downloads/ImportBIN.xlsx')}
        />
      </Modal>
    </Fragment>
  );
};

const preProcessFilter = ({ tin, ...rest }: any) => ({
  ...rest,
  tinList: [tin].filter(Boolean),
});
