import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryAdminVstoreSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  const res = await httpClient.get(`/admin/vstore/search`, {
    params: { criteria, ...toPaginationParams(pagination) },
  });
  if (res.data.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
