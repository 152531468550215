import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import s from './ProfileSelect.module.scss';
import { useStore } from 'common/hooks';
import { Loader, RadioT2 } from 'ui';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = {
  className?: string;
  modal?: boolean;
  onSelect?: (profileId: string) => void;
};

export const ProfileSelect: React.FC<Props> = ({ className, modal = false, onSelect }) => {
  const { locale, user } = useStore();
  const { loc } = locale;
  const [loading, setLoading] = useState(false);
  const [selectedProfileId, setSelectedProfileId] = useState<string | undefined>(user.currentUser?.currentProfileId);

  const profiles = user.currentUser && Array.isArray(user.currentUser?.profiles) ? user.currentUser.profiles : [];

  const sortedProfiles = [
    ...profiles.filter((p) => p.id === user.currentUser?.currentProfileId),
    ...profiles.filter((p) => p.id !== user.currentUser?.currentProfileId),
  ];

  const onSelectProfileChange = async (profileId: string) => {
    if (profileId !== user.currentUser?.currentProfileId) {
      setSelectedProfileId(profileId);
      setLoading(true);
      try {
        await user.changeProfile(profileId);
      } catch (error) {
        setSelectedProfileId(user.currentUser?.currentProfileId);
        ToastService.showError(solveErrorMessage(error));
        setLoading(false);
      }
    }
    typeof onSelect === 'function' && onSelect(profileId);
  };

  return (
    <Fragment>
      <div className={clsx(className, s.wrapper, { [s.modal]: modal })}>
        <div className={s.inner}>
          {sortedProfiles.map((profile) => (
            <div key={profile.id} className={s.profile} onClick={() => onSelectProfileChange(profile.id)}>
              <RadioT2
                className={s.radio}
                checked={selectedProfileId === profile.id}
                radioValue={profile.id}
                name="profile"
              />
              <h4 className={s.profileName}>{profile.name}</h4>
              <div className={s.details}>
                <div className={s.column}>
                  <p>{loc('common.labels.iin')}</p>
                  <p>{profile.tin || profile.iin}</p>
                </div>
                <div className={s.column}>
                  <p>{loc('common.labels.status')}</p>
                  <p>{loc(`common.userStatusType.${profile.status}`)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {loading && <Loader className={s.loader} overlay />}
    </Fragment>
  );
};
