import React from 'react';
import clsx from 'clsx';
import s from './FormListUploader.module.scss';
import { FileInput, LoadedCount } from 'ui/common';
import { useFormikContext } from 'formik';
import { readListFromTextFile, readListFromXLSXFile } from 'common/helpers/read-files';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
  name: string;
  labelFileInput?: string;
  labelCount?: string;
  /**
   * Парсер файла, по умолчанию парсит текстовый файл, со значениями через запятую
   * @returns Массив значений из файла
   */
  fileType?: 'text' | 'xlsx';
  preProcess?: (list: Array<string>) => Array<string> | undefined;
  noMargin?: boolean;
};

/**
 * Элемент для загрузки списка значений из файла (например, список ИИН в фильтрах)
 * Кнопка загрузки файла и счетчик загруженных значений
 * С подключением к форме, через имя name
 */
export const FormListUploader: React.FC<Props> = ({
  className,
  name,
  labelFileInput,
  labelCount,
  fileType = 'text',
  preProcess,
  noMargin,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, setFieldValue } = useFormikContext<any>();

  const updateFromFile = async (file?: File) => {
    if (file) {
      const parser = fileType === 'xlsx' ? readListFromXLSXFile : readListFromTextFile;
      try {
        const list = await parser(file);
        setFieldValue(name, typeof preProcess === 'function' ? preProcess(list) : list);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const clearList = () => {
    setFieldValue(name, undefined);
  };

  return (
    <div className={clsx(className, s.wrapper, { [s.noMargin]: noMargin })}>
      <FileInput
        label={
          labelFileInput ||
          (fileType === 'xlsx' ? loc('common.button.uploadFromFileXlsx') : loc('common.button.uploadFromFileTxt'))
        }
        onChange={(ev) => updateFromFile(ev?.target?.files?.[0])}
        className={s.input}
      />
      <LoadedCount label={labelCount} list={values[name]} onClear={clearList} className={s.count} />
    </div>
  );
};
