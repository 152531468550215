import React from 'react';
import clsx from 'clsx';
import s from './Status.module.scss';

export type StatusConfig = {
  [key: string]: { color: string; label: string };
};

type Props = {
  className?: string;
  config: StatusConfig;
  value: string;
};

export const Status: React.FC<Props> = ({ className, config, value }) => {
  const status = config[value];

  return (
    <span className={clsx(className, s.wrapper)} style={{ color: status?.color }}>
      {status?.label || value}
    </span>
  );
};
