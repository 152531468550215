export const USER_ACCESS_CLIENT_TYPE = {
  /**
   * Веб
   */
  WEB: 'WEB',
  /**
   * АПИ
   */
  API: 'API',
  /**
   * Мобильная версия
   */
  MOB: 'MOB',
};

export const USER_ACCESS_EVENT_LEVEL = {
  /**
   * Информация
   */
  INFO: 'INFO',

  /**
   * Ошибка
   */
  ERROR: 'ERROR',
};

export const USER_ACCESS_MODULE_TYPE = {
  /*
   * Регистрационный учет
   */
  REG_ACCOUNTING: 'REG_ACCOUNTING',

  /*
   * Управление пользователями АРМ
   */
  ARM_USER_MANAGE: 'ARM_USER_MANAGE',

  /*
   * Сообщения
   */
  MESSAGE: 'MESSAGE',

  /*
   * Оповещения
   */
  NOTIFICATION: 'NOTIFICATION',

  /*
   * Протокол простоя системы
   */
  DOWNTIME: 'DOWNTIME',

  /*
   * Протокол блокировки пользователей
   */
  BLOCKING: 'BLOCKING',

  /*
   * Электронные счета фактуры
   */
  INVOICE: 'INVOICE',

  /*
   * Сопроводительные накладные на товары
   */
  SNT: 'SNT',

  /*
   * Акты выполненных работ
   */
  AWP: 'AWP',

  /*
   * Электронные договоры
   */
  ECONTRACT: 'ECONTRACT',

  /*
   * ДТ
   */
  GTD: 'GTD',

  /*
   * ФНО 328.00
   */
  FNO: 'FNO',

  /*
   * Формы виртуального склада
   */
  UFORM: 'UFORM',

  /*
   * Склады
   */
  VSTORE: 'VSTORE',

  /*
   * Виртуальная метка
   */
  VLABEL: 'VLABEL',

  /*
   * Поиск налогоплатещиков
   */
  TAXPAYER: 'TAXPAYER',

  /*
   * Назначенные полномочия сотрудника предприятия
   */
  BUSINESS_USER_ROLE: 'BUSINESS_USER_ROLE',

  /*
   * Соглашение об использовании системы
   */
  AGREEMENT: 'AGREEMENT',

  /*
   * Реестр импорт/экспорт/транзит
   */
  TRANSIT: 'TRANSIT',

  /*
   * Новости
   */
  NEWS: 'NEWS',

  /*
   * Календарь
   */
  CALENDAR: 'CALENDAR',

  /*
   * Справочники
   */
  DICTIONARY: 'DICTIONARY',

  /*
   * Отчетность
   */
  REPORT: 'REPORT',
};

export const USER_ACCESS_EVENT_TYPE = {
  /*
   * Регистрация
   */
  REGISTRATION: 'REGISTRATION',

  /*
   * Вход
   */
  LOGIN: 'LOGIN',

  /*
   * Выход
   */
  LOGOUT: 'LOGOUT',

  /*
   * Сброс пароля
   */
  PASSWORD_RESET: 'PASSWORD_RESET',

  /*
   * Изменение личных данных
   */
  PERSONAL_DATA_EDIT: 'PERSONAL_DATA_EDIT',

  /*
   * Смена email
   */
  EMAIL_CHANGE: 'EMAIL_CHANGE',

  /*
   * Добавление нового пользователя в АРМ
   */
  ARM_USER_ADD: 'ARM_USER_ADD',

  /*
   * Подтверждение полномочий в АРМ
   */
  ARM_USER_PERMISSION_CONFIRM: 'ARM_USER_PERMISSION_CONFIRM',

  /*
   * Отклонение полномочий в АРМ
   */
  ARM_USER_PERMISSION_DECLINE: 'ARM_USER_PERMISSION_DECLINE',

  /*
   * Редактирования полномочий пользователя АРМ
   */
  ARM_USER_PERMISSION_EDIT: 'ARM_USER_PERMISSION_EDIT',

  /*
   * Блокировка пользователя в АРМ
   */
  ARM_USER_BLOCK: 'ARM_USER_BLOCK',

  /*
   * Восстановления пользователя в АРМ
   */
  ARM_USER_RESTORE: 'ARM_USER_RESTORE',

  /*
   * Изменение IP адреса пользователя АРМ
   */
  ARM_USER_IP_ADDRESS_CHANGE: 'ARM_USER_IP_ADDRESS_CHANGE',

  /*
   * Отправка сообщений администратором по списку бинов
   */
  MESSAGE_SEND_FOR_TINS: 'MESSAGE_SEND_FOR_TINS',

  /*
   * Отправка сообщений администратором для группы
   */
  MESSAGE_SEND_FOR_GROUP: 'MESSAGE_SEND_FOR_GROUP',

  /*
   * Сохранение шаблонов оповещений
   */
  NOTIFICATION_TEMPLATE_SAVE: 'NOTIFICATION_TEMPLATE_SAVE',

  /*
   * Создание протокола простоя системы
   */
  DOWNTIME_ADD: 'DOWNTIME_ADD',

  /*
   * Редактирование протокола простоя системы
   */
  DOWNTIME_UPDATE: 'DOWNTIME_UPDATE',

  /*
   * Удаление протокола простоя системы
   */
  DOWNTIME_DELETE: 'DOWNTIME_DELETE',

  /*
   * Утверждение протокола простоя системы
   */
  DOWNTIME_APPROVE: 'DOWNTIME_APPROVE',

  /*
   * Создание протокола блокировки
   */
  BLOCKING_ADD: 'BLOCKING_ADD',

  /*
   * Редактирование протокола блокировки
   */
  BLOCKING_UPDATE: 'BLOCKING_UPDATE',

  /*
   * Удаление протокола блокировки
   */
  BLOCKING_DELETE: 'BLOCKING_DELETE',

  /*
   * Импорт протоколов блокировки из файла
   */
  BLOCKING_IMPORT: 'BLOCKING_IMPORT',

  /*
   * Утверждение протокола блокировки
   */
  BLOCKING_APPROVE: 'BLOCKING_APPROVE',

  /*
   * Сохранение настроек причин блокировки
   */
  BLOCKING_SETTINGS_SAVE: 'BLOCKING_SETTINGS_SAVE',

  /*
   * Скачать ЭСФ
   */
  INVOICE_DOWNLOAD: 'INVOICE_DOWNLOAD',

  /*
   * Распечатать ЭСФ
   */
  INVOICE_PRINT: 'INVOICE_PRINT',

  /*
   * Экспортировать журнал ЭСФ
   */
  INVOICE_JOURNAL_EXPORT: 'INVOICE_JOURNAL_EXPORT',

  /**
   * Разблокировка ЭСФ администратором
   */
  INVOICE_UNLOCK: 'INVOICE_UNLOCK',

  /*
   * Аннулирование ЭСФ сотрудников ОГД
   */
  INVOICE_CANCEL_BY_OGD: 'INVOICE_CANCEL_BY_OGD',

  /*
   * Восстановление ЭСФ сотрудником ОГД
   */
  INVOICE_RESTORE_BY_OGD: 'INVOICE_RESTORE_BY_OGD',

  /*
   * Скачать СНТ
   */
  SNT_DOWNLOAD: 'SNT_DOWNLOAD',

  /*
   * Распечатать СНТ
   */
  SNT_PRINT: 'SNT_PRINT',

  /*
   * Экспортировать журнал СНТ
   */
  SNT_JOURNAL_EXPORT: 'SNT_JOURNAL_EXPORT',

  /*
   * Подтверждение СНТ инспектором ОГД
   */
  SNT_CONFIRM_BY_OGD: 'SNT_CONFIRM_BY_OGD',

  /*
   * Отклонение СНТ инспектором ОГД
   */
  SNT_DECLINE_BY_OGD: 'SNT_DECLINE_BY_OGD',

  /*
   * Скачать АВР
   */
  AWP_DOWNLOAD: 'AWP_DOWNLOAD',

  /*
   * Распечатать АВР
   */
  AWP_PRINT: 'AWP_PRINT',

  /*
   * Экспортировать журнал АВР
   */
  AWP_JOURNAL_EXPORT: 'AWP_JOURNAL_EXPORT',

  /*
   * Скачать ЭД
   */
  ECONTRACT_DOWNLOAD: 'ECONTRACT_DOWNLOAD',

  /*
   * Распечатать ЭД
   */
  ECONTRACT_PRINT: 'ECONTRACT_PRINT',

  /*
   * Экспортировать журнал ЭД
   */
  ECONTRACT_JOURNAL_EXPORT: 'ECONTRACT_JOURNAL_EXPORT',

  /*
   * Скачать ДТ
   */
  GTD_DOWNLOAD: 'GTD_DOWNLOAD',

  /*
   * Посмотреть ДТ
   */
  GTD_VIEW: 'GTD_VIEW',

  /*
   * Распечатать ДТ
   */
  GTD_PRINT: 'GTD_PRINT',

  /*
   * Экспортировать журнал ДТ
   */
  GTD_JOURNAL_EXPORT: 'GTD_JOURNAL_EXPORT',

  /*
   * Скачать ФНО 328.00
   */
  FNO_DOWNLOAD: 'FNO_DOWNLOAD',

  /*
   * Посмотреть ФНО 328.00
   */
  FNO_VIEW: 'FNO_VIEW',

  /*
   * Распечатать ФНО 328.00
   */
  FNO_PRINT: 'FNO_PRINT',

  /*
   * Экспортировать журнал ФНО 328.00
   */
  FNO_JOURNAL_EXPORT: 'FNO_JOURNAL_EXPORT',

  /*
   * Скачать Форму
   */
  UFORM_DOWNLOAD: 'UFORM_DOWNLOAD',

  /*
   * Распечатать Форму
   */
  UFORM_PRINT: 'UFORM_PRINT',

  /*
   * Экспортировать журнал Форм
   */
  UFORM_JOURNAL_EXPORT: 'UFORM_JOURNAL_EXPORT',

  /*
   * Скачать Склад
   */
  VSTORE_DOWNLOAD: 'VSTORE_DOWNLOAD',

  /*
   * Распечатать Склад
   */
  VSTORE_PRINT: 'VSTORE_PRINT',

  /*
   * Посмотреть Склад
   */
  VSTORE_VIEW: 'VSTORE_VIEW',

  /*
   * Экспорт ВМ
   */
  VLABEL_EXPORT: 'VLABEL_EXPORT',

  /*
   * Экспорт истории ВМ
   */
  VLABEL_HISTORY_EXPORT: 'VLABEL_HISTORY_EXPORT',

  /*
   * Поиск сведений о НП
   */
  TAXPAYER_SEARCH: 'TAXPAYER_SEARCH',

  /*
   * Экспорт сведений о НП
   */
  TAXPAYER_EXPORT: 'TAXPAYER_EXPORT',

  /*
   * Поиск назначенных полномочий
   */
  BUSINESS_USER_SEARCH: 'BUSINESS_USER_SEARCH',

  /*
   * Экспорт сведений назначенных полномочий
   */
  BUSINESS_USER_EXPORT: 'BUSINESS_USER_EXPORT',

  /*
   * Просмотр соглашения
   */
  AGREEMENT_VIEW: 'AGREEMENT_VIEW',

  /*
   * Загрузка соглашения
   */
  AGREEMENT_DOWNLOAD: 'AGREEMENT_DOWNLOAD',

  /*
   * Экспорт журнала соглашений
   */
  AGREEMENT_JOURNAL_EXPORT: 'AGREEMENT_JOURNAL_EXPORT',

  /*
   * Создание записи в реестре
   */
  TRANSIT_ADD: 'TRANSIT_ADD',

  /*
   * Подписание записи в реестре
   */
  TRANSIT_SIGN: 'TRANSIT_SIGN',

  /*
   * Публикация новости
   */
  NEW_PUBLISH: 'NEW_PUBLISH',

  /*
   * Редактирование опубликованной новости
   */
  NEW_EDIT: 'NEW_EDIT',

  /*
   * Изменение в календаре рабочих дней
   */
  CALENDAR_EDIT: 'CALENDAR_EDIT',

  /*
   * Добавление НП в справочник «Крупные налогоплательщики»
   */
  BIG_COMPANY_ADD: 'BIG_COMPANY_ADD',

  /*
   * Удаление НП из справочника «Крупные налогоплательщики»
   */
  BIG_COMPANY_DELETE: 'BIG_COMPANY_DELETE',

  /*
   * Импорт файла с курсами валют
   */
  CURRENCY_RATE_IMPORT: 'CURRENCY_RATE_IMPORT',

  /*
   * Добавление записи в справочник «Единицы измерения»
   */
  MEASURE_UNIT_ADD: 'MEASURE_UNIT_ADD',

  /*
   * Добавление участника в справочник «Участник СРП»
   */
  SHARING_AGREEMENT_PARTICIPANT_ADD: 'SHARING_AGREEMENT_PARTICIPANT_ADD',

  /*
   * Деактивация участника в справочнике «Участник СРП»
   */
  SHARING_AGREEMENT_PARTICIPANT_DEACTIVATE: 'SHARING_AGREEMENT_PARTICIPANT_DEACTIVATE',

  /*
   * Импорт справочника ГСВС
   */
  GSVS_IMPORT: 'GSVS_IMPORT',

  /*
   * Добавление записи в справочник ГСВС
   */
  GSVS_ADD: 'GSVS_ADD',

  /*
   * Редактирование записи в справочнике ГСВС
   */
  GSVS_EDIT: 'GSVS_EDIT',

  /*
   * Закрытие записи в справочнике ГСВС
   */
  GSVS_CLOSE: 'GSVS_CLOSE',

  /*
   * Подтверждение заявок на создание третьего уровня ГСВС
   */
  GSVS_REQUEST_CONFIRM: 'GSVS_REQUEST_CONFIRM',

  /*
   * Отклонение заявок на создание третьего уровня ГСВС
   */
  GSVS_REQUEST_REJECT: 'GSVS_REQUEST_REJECT',

  /*
   * Добавление НП в список реорганизованных организаций
   */
  REORGANIZATION_ADD: 'REORGANIZATION_ADD',

  /*
   * Деактивация НП в списке реорганизованных организаций
   */
  REORGANIZATION_CANCEL: 'REORGANIZATION_CANCEL',

  /*
   * Импорт ПИН-кодов нефтепродуктов
   */
  OIL_PIN_IMPORT: 'OIL_PIN_IMPORT',

  /*
   * Добавление ПИН-кода нефтепродуктов
   */
  OIL_PIN_ADD: 'OIL_PIN_ADD',

  /*
   * Импорт ПИН-кодов табачный изделий
   */
  TOBACCO_PIN_IMPORT: 'TOBACCO_PIN_IMPORT',

  /*
   * Добавление ПИН-кода табачный изделий
   */
  TOBACCO_PIN_ADD: 'TOBACCO_PIN_ADD',

  /*
   * Импорт ПИН-кодов алкогольной продукции
   */
  ALCOHOL_PIN_IMPORT: 'ALCOHOL_PIN_IMPORT',

  /*
   * Добавление ПИН-кода алкогольной продукции
   */
  ALCOHOL_PIN_ADD: 'ALCOHOL_PIN_ADD',

  /*
   * Импорт объектов ОВД нефтепродуктов
   */
  OIL_OVD_IMPORT: 'OIL_OVD_IMPORT',

  /*
   * Добавление объекта ОВД нефтепродуктов
   */
  OIL_OVD_ADD: 'OIL_OVD_ADD',

  /*
   * Импорт объектов ОВД табачных изделий
   */
  TOBACCO_OVD_IMPORT: 'TOBACCO_OVD_IMPORT',

  /*
   * Добавление объекта ОВД табачных изделий
   */
  TOBACCO_OVD_ADD: 'TOBACCO_OVD_ADD',

  /*
   * Импорт лицензий алкогольной продукции
   */
  ALCOHOL_LICENSE_IMPORT: 'ALCOHOL_LICENSE_IMPORT',

  /*
   * Добавление лицензии алкогольной продукции
   */
  ALCOHOL_LICENSE_ADD: 'ALCOHOL_LICENSE_ADD',

  /*
   * Добавление крепости алкогольной продукции
   */
  ALCOHOL_SPIRIT_ADD: 'ALCOHOL_SPIRIT_ADD',

  /*
   * Импортирование крепости алкогольной продукции
   */
  ALCOHOL_SPIRIT_IMPORT: 'ALCOHOL_SPIRIT_IMPORT',

  /*
   * Добавление ставки акциза
   */
  EXCISE_RATE_ADD: 'EXCISE_RATE_ADD',

  /*
   * Импортирование ставки акциза
   */
  EXCISE_RATE_IMPORT: 'EXCISE_RATE_IMPORT',

  /*
   * Добавление товаров под запретом
   */
  BANNED_GOODS_ADD: 'BANNED_GOODS_ADD',

  /*
   * Редактирование товаров под запретом
   */
  BANNED_GOODS_EDIT: 'BANNED_GOODS_EDIT',

  /*
   * Удаление товаров под запретом
   */
  BANNED_GOODS_DELETE: 'BANNED_GOODS_DELETE',

  /*
   * Добавление пункта пропуска
   */
  PLANNED_CHECKPOINT_ADD: 'PLANNED_CHECKPOINT_ADD',

  /*
   * Редактирование пункта пропуска
   */
  PLANNED_CHECKPOINT_EDIT: 'PLANNED_CHECKPOINT_EDIT',

  /*
   * Деактивация пункта пропуска
   */
  PLANNED_CHECKPOINT_DEACTIVATE: 'PLANNED_CHECKPOINT_DEACTIVATE',

  /*
   * Импорт списка НП признанных банкротами, бездействующими, непричастными к импорту
   */
  TAXPAYER_MARK_IMPORT: 'TAXPAYER_MARK_IMPORT',

  /*
   * Очистка НП признанных банкротами, бездействующими, непричастными к импорту
   */
  TAXPAYER_MARK_CLEAN: 'TAXPAYER_MARK_CLEAN',

  /*
   * Экспорт статистического отчет
   */
  STATISTICS_REPORT_EXPORT: 'STATISTICS_REPORT_EXPORT',

  /*
   * Экспорт отчета по зарегистрированным участникам
   */
  USERS_REGISTRATION_REPORT_EXPORT: 'USERS_REGISTRATION_REPORT_EXPORT',

  /*
   * Подтверждение приглашение в предприятие
   */
  ENTERPRISE_INVITATION_APPROVE: 'ENTERPRISE_INVITATION_APPROVE',

  /*
   * Подтверждение приглашение в ИП
   */
  ENTREPRENEUR_INVITATION_APPROVE: 'ENTREPRENEUR_INVITATION_APPROVE',

  /*
   * Подтверждение приглашения ЛЗЧП
   */
  PRIVATE_PRACTICE_INVITATION_APPROVE: 'PRIVATE_PRACTICE_INVITATION_APPROVE',

  /**
   * Назначены права доверенному пользователю
   */
  ENTERPRISE_PERMISSIONS_SAVE: 'ENTERPRISE_PERMISSIONS_SAVE',

  /*
   * Блокировка руководителем сотрудника
   */
  BLOCK_USER_BY_ADMIN: 'BLOCK_USER_BY_ADMIN',

  /*
   * Восстановление руководителем сотрудника
   */
  RESTORE_USER_BY_ADMIN: 'RESTORE_USER_BY_ADMIN',

  /*
   * Блокировка пользователя в ИС ЭСФ
   */
  BLOCK_USER: 'BLOCK_USER',

  /*
   * Восстановление пользователя в ИС ЭСФ
   */
  RESTORE_USER: 'RESTORE_USER',

  /*
   * Создан
   */
  INVOICE_CREATED: 'INVOICE_CREATED',

  /**
   * Удален
   */
  INVOICE_DELETED: 'INVOICE_DELETED',

  /*
   * Доставлен
   */
  INVOICE_DELIVERED: 'INVOICE_DELIVERED',

  /*
   * Аннулирован
   */
  INVOICE_CANCELED: 'INVOICE_CANCELED',

  /*
   * Отклонен
   */
  INVOICE_DECLINED: 'INVOICE_DECLINED',

  /*
   * Отклонен отзыв
   */
  INVOICE_UNREVOKE: 'INVOICE_UNREVOKE',

  /*
   * Отозван
   */
  INVOICE_REVOKED: 'INVOICE_REVOKED',

  /*
   * Аннулирован при отклонении СНТ
   */
  INVOICE_CANCELED_BY_SNT_DECLINE: 'INVOICE_CANCELED_BY_SNT_DECLINE',

  /*
   * Аннулирован при отзыве СНТ
   */
  INVOICE_CANCELED_BY_SNT_REVOKE: 'INVOICE_CANCELED_BY_SNT_REVOKE',

  /*
   * Аннулирован ИС ЭСФ для отнесения в зачет и на вычеты
   */
  INVOICE_CANCELED_BY_OGD: 'INVOICE_CANCELED_BY_OGD',

  /*
   * Создан
   */
  SNT_CREATED: 'SNT_CREATED',

  /*
   * Доставлен
   */
  SNT_DELIVERED: 'SNT_DELIVERED',

  /*
   * Аннулирован
   */
  SNT_CANCELED: 'SNT_CANCELED',

  /*
   * Отозван
   */
  SNT_REVOKED: 'SNT_REVOKED',

  /*
   * Отклонен
   */
  SNT_DECLINED: 'SNT_DECLINED',

  /*
   * Подтвержден
   */
  SNT_CONFIRMED: 'SNT_CONFIRMED',

  /*
   * Удален
   */
  SNT_DELETED: 'SNT_DELETED',

  /*
   * Создан
   */
  AWP_CREATED: 'AWP_CREATED',

  /*
   * Доставлен
   */
  AWP_DELIVERED: 'AWP_DELIVERED',

  /*
   * Подтвержден
   */
  AWP_CONFIRMED: 'AWP_CONFIRMED',

  /*
   * Отклонен
   */
  AWP_DECLINED: 'AWP_DECLINED',

  /*
   * Отозван
   */
  AWP_REVOKED: 'AWP_REVOKED',

  /*
   * В процессе расторжения
   */
  AWP_IN_TERMINATING: 'AWP_IN_TERMINATING',

  /*
   * Расторгнут
   */
  AWP_TERMINATED: 'AWP_TERMINATED',

  /*
   * Подтверждение расторжения
   */
  AWP_CONFIRMED_TERMINATION: 'AWP_CONFIRMED_TERMINATION',

  /*
   * Отклонение расторжения
   */
  AWP_DECLINED_TERMINATION: 'AWP_DECLINED_TERMINATION',

  /*
   * Удален
   */
  AWP_DELETED: 'AWP_DELETED',

  /*
   * Создан
   */
  ECONTRACT_CREATED: 'ECONTRACT_CREATED',

  /*
   * Частично просмотрен
   */
  ECONTRACT_PARTIALLY_VIEWED: 'ECONTRACT_PARTIALLY_VIEWED',

  /*
   * Просмотрен
   */
  ECONTRACT_VIEWED: 'ECONTRACT_VIEWED',

  /*
   * Отозван
   */
  ECONTRACT_REVOKED: 'ECONTRACT_REVOKED',

  /*
   * Частично утвержден
   */
  ECONTRACT_PARTIALLY_APPROVED: 'ECONTRACT_PARTIALLY_APPROVED',

  /*
   * Утвержден
   */
  ECONTRACT_APPROVED: 'ECONTRACT_APPROVED',

  /*
   * Отклонен
   */
  ECONTRACT_DECLINED: 'ECONTRACT_DECLINED',

  /*
   * Расторгнут
   */
  ECONTRACT_TERMINATED: 'ECONTRACT_TERMINATED',

  /*
   * Закрыт
   */
  ECONTRACT_CLOSED: 'ECONTRACT_CLOSED',

  /*
   * В процессе расторжения
   */
  ECONTRACT_IN_PROCESS_OF_TERMINATION: 'ECONTRACT_IN_PROCESS_OF_TERMINATION',

  /*
   * Частично расторгнут
   */
  ECONTRACT_PARTIALLY_TERMINATED: 'ECONTRACT_PARTIALLY_TERMINATED',

  /*
   * Расторгнут в одностороннем порядке
   */
  ECONTRACT_TERMINATED_UNILATERALLY: 'ECONTRACT_TERMINATED_UNILATERALLY',

  /*
   * Удален
   */
  ECONTRACT_DELETED: 'ECONTRACT_DELETED',

  /*
   * Получена ДТ
   */
  GTD_RECEIVED: 'GTD_RECEIVED',

  /*
   * Получена ФНО 328.00
   */
  FNO_RECEIVED: 'FNO_RECEIVED',

  /*
   * Сохранение формы
   */
  UFORM_SAVE: 'UFORM_SAVE',

  /*
   * Отмена формы
   */
  UFORM_CANCEL: 'UFORM_CANCEL',

  /*
   * Сохранение склада
   */
  VSTORE_SAVE: 'VSTORE_SAVE',

  /*
   * Закрытие склада
   */
  VSTORE_CLOSE: 'VSTORE_CLOSE',

  /*
   * Восстановление склада
   */
  VSTORE_RESTORE: 'VSTORE_RESTORE',

  /*
   * Загрузка отчета по ЭСФ
   */
  INVOICE_REPORT_EXPORT: 'INVOICE_REPORT_EXPORT',

  /*
   * Прочитано оповещение
   */
  NOTIFICATION_READ: 'NOTIFICATION_READ',

  /*
   * Удалено оповещение
   */
  NOTIFICATION_DELETE: 'NOTIFICATION_DELETE',

  /*
   * Удалены все оповещения
   */
  NOTIFICATION_DELETE_ALL: 'NOTIFICATION_DELETE_ALL',
};
