import React, { Fragment, PropsWithChildren, useEffect } from 'react';
import clsx from 'clsx';
import s from './AsideFilter.module.scss';
import { Form } from 'formik';
import { ButtonIcon } from 'ui/common/controls/button-icon';
import { ButtonT2 } from 'ui/theme2/controls/button/ButtonT2';
import { Loader } from 'ui/common/elements';

type Props = PropsWithChildren<{
  className?: string;
  title?: string;
  searchText?: string;
  clearText?: string;
  onClose?: () => void;
  /**
   * По умолчанию фильтр обернут в компонент Form (Formik) для встраимвания в Formik
   * Можно поменять на div
   */
  wrapperTag?: 'div';
  loading?: boolean;
}>;

export const AsideFilter: React.FC<Props> = ({
  className,
  wrapperTag,
  title,
  searchText,
  clearText,
  onClose,
  children,
  loading,
}) => {
  const Tag = wrapperTag || Form;

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if (['Escape'].includes(e.key)) {
        typeof onClose === 'function' && onClose();
        e.preventDefault();
      }
    };

    document.addEventListener('keyup', handler);
    return () => {
      document.removeEventListener('keyup', handler);
    };
  }, []); // eslint-disable-line

  return (
    <Fragment>
      <div className={s.overlay} onClick={() => typeof onClose === 'function' && onClose()} />
      <Tag className={clsx(s.wrapper)}>
        {loading && <Loader overlay />}
        <div className={s.header}>
          {title}
          {typeof onClose === 'function' && (
            <ButtonIcon
              className={s.closeButton}
              iconName="close-bold"
              iconClassName={s.closeIcon}
              onClick={() => onClose()}
            />
          )}
        </div>
        <div className={s.content}>{children}</div>
        <div className={s.footer}>
          <ButtonT2 type="submit" variant="primary" size="small">
            {searchText}
          </ButtonT2>
          <ButtonT2 variant="transparent" size="small" onClick={() => console.log('clear')}>
            {clearText}
          </ButtonT2>
        </div>
      </Tag>
    </Fragment>
  );
};
