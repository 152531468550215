import { API_URL, LEGACY_URL } from 'common/constants';
import { Lang } from 'services/l10n';

type Config = {
  ids: Array<string>;
  isDraft: boolean;
  isAdmin?: boolean;
  lang: Lang;
};

export const printInvoice = (config: Config) => {
  const { ids } = config;
  if (ids.length > 1) {
    doPrintMultipleInvoice(config);
  } else {
    doPrintInvoice(config);
  }
  return;
};

const doPrintInvoice = (config: Config) => {
  const { ids, isAdmin, isDraft, lang } = config;
  ids.forEach((id) => {
    if (isAdmin) {
      window.open(`${LEGACY_URL}/admin/invoice/printForAdmin/${id}?language=${lang}`);
    } else {
      window.open(`${LEGACY_URL}/invoice/print?id=${id}&isDraft=${isDraft}&language=${lang}`);
    }
  });
};

const doPrintMultipleInvoice = (config: Config) => {
  const { isAdmin, ids, isDraft, lang } = config;
  const url = isAdmin ? `${API_URL}/admin/invoice/printMultipleForAdmin` : `${API_URL}/invoice/printMultiple`;

  const form = document.createElement('form');
  form.action = url;
  form.method = 'POST';
  form.target = '_blank';

  const idsValue = ids.join(',');
  form.innerHTML = `<input name='ids' value='${idsValue}'> <input name='isDraft' value='${isDraft}'> <input name='language' value='${lang}'>`;

  document.body.appendChild(form);
  form.submit();
  form.remove();
};
