import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminAssuranceTakeInWork: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { id } = variables;

  const res = await httpClient.get(`/admin/assurance/takeInWork/${id}`);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
