import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryExternalUsers: DataQueryFunction = (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  // console.log(variables);

  return httpClient.get(`/admin/user/external/search`, {
    params: {
      criteria,
      ...toPaginationParams(pagination),
      sort: 'time',
      order: 'desc',
    },
  });
};
