import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { queryAdminZvtExport, queryAdminZvtPrintReport, queryAdminZvtSearch } from '../api';
import { ZvtJournalArmFilters } from './ZvtJournalArmFilters';
import { getZvtJournalArmColumns } from './getZvtJournalArmColumns';
import { formatFromDate } from 'services/date-time';
import { ZvtPageArm } from '../zvt-page-arm/ZvtPageArm';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { SessionStorageService } from 'services/storage';

const filterStorageKey = 'armZvtJournal';

const initialFilter = {
  dateTo: formatFromDate(new Date()),
  dateFrom: formatFromDate(new Date()),
  status: '',
};
const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.id;

type Props = {};

/**
 * Страница АРМ -> Виртуальный склад -> Журнал ЗВТ (заявлений о выпуске товаров )
 * arm/zvt
 */
export const ZvtJournalArmPage: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });

  const columns = useMemo(() => getZvtJournalArmColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminZvtSearch, {
    filter: SessionStorageService.get(filterStorageKey) || initialFilter,
    pagination: initialPagination,
  });

  const headerButtons = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      onClick: async () => {
        exportJournal();
      },
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: async (selection) => {
        setNestedPage({ opened: true, row: selection[0] });
      },
    },
    {
      id: 2,
      label: loc('common.button.pdf'),
      onClick: async (selection) => {
        downloadSelection(selection);
      },
    },
  ];

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  const exportJournal = async () => {
    try {
      await queryAdminZvtExport({
        filter: queryProps.filter,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const downloadSelection = async (selection: any, format: string = 'PDF') => {
    try {
      await queryAdminZvtPrintReport({
        id: getRowId(selection[0]),
        format,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('zvt.journalTitle')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={ZvtJournalArmFilters}
        filterInitialOpened
        defaultFilter={initialFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        hidden={nestedPage.opened}
        getRowId={getRowId}
        filterStorageKey={filterStorageKey}
      />
      {nestedPage.opened ? <ZvtPageArm onClose={closeNestedPage} id={getRowId(nestedPage.row)} /> : null}
    </Fragment>
  );
};
