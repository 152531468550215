import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TableRow, TableRowProps } from './TableRow';

type Props = TableRowProps & {};

export const TableRowDnD: React.FC<Props> = ({
  row,
  rowIndex,
  getRowId,
  dragable,
  dropable,
  onDrop = () => undefined,
  ...rest
}) => {
  const [, drag] = useDrag({
    item: { type: 'row', id: getRowId(row, rowIndex) },
  });

  const [, drop] = useDrop({
    accept: 'row',
    drop: (item) => onDrop(item, row, rowIndex),
  });

  return (
    <TableRow
      ref={dragable ? drag : dropable ? drop : undefined}
      row={row}
      rowIndex={rowIndex}
      getRowId={getRowId}
      {...rest}
    />
  );
};
