import React from 'react';
import { FieldProps } from 'formik';
import { Uploader, UploaderProps } from '../controls/uploader/Uploader';
import { FormValueProps } from './FormValueProps';

type Props = Partial<FieldProps> &
  UploaderProps & {
    onValueChange?: (props: FormValueProps<File | File[]>) => void;
  };

export const FormUploader: React.FC<Props> = ({ field, form, meta, multiple, onValueChange, ...rest }) => {
  const { value, name } = field || {};
  const { setFieldValue } = form || {};

  const onSelect = (files: Array<File>) => {
    const newValue = multiple ? [...(value || []), ...files] : files[0];
    if (onValueChange && name && form) {
      onValueChange({ value: newValue, name, form });
    } else if (typeof setFieldValue === 'function' && name) {
      setFieldValue(name, newValue);
    }
  };

  const onRemove = (file: File) => {
    if (typeof setFieldValue === 'function' && name) {
      if (multiple) {
        setFieldValue(name, Array.isArray(value) ? value.filter((fl) => fl !== file) : []);
      } else {
        setFieldValue(name, undefined);
      }
    }
  };

  return (
    <Uploader
      files={multiple ? value : value ? [value] : []}
      onSelect={onSelect}
      onRemove={onRemove}
      {...rest}
      multiple={multiple}
    />
  );
};
