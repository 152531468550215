import { UserStore } from 'common/stores/user-store';
import { EsfDirection } from '../constants';

// тут находим все тины (tin, branchTin, sellerAgentTin), чтобы потом проверить совпадают ли они с тин текущего юзера
export function resolveSellerTins(esfRow: any): Array<any> {
  const sellerTins = (Array.isArray(esfRow?.invoice?.sellers) ? esfRow?.invoice?.sellers : []).map(
    (seller: any) => seller.tin
  );
  const sellerBranchTins = (Array.isArray(esfRow?.invoice?.sellers) ? esfRow?.invoice?.sellers : []).map(
    (seller: any) => seller.branchTin
  );
  const sellerAgentTin = esfRow?.invoice?.sellerAgentTin;
  return [...sellerTins, ...sellerBranchTins, sellerAgentTin].filter(Boolean);
}

// ЭСФ Исходящая (OUTBOUND) - Если среди селлеров мы находим НП с tin или branchTin == currentUserTin
// или текущий юзер является оператором/поверенным (sellerAgentTin == currentUserTin)
// иначе ЭСФ Входящий (INBOUND)
export const solveEsfDirection = (esfRow: any, user: UserStore): EsfDirection => {
  const isUserSeller = resolveSellerTins(esfRow).includes(user.currentUser?.currentUserTin);
  const direction = isUserSeller ? EsfDirection.OUTBOUND : EsfDirection.INBOUND;
  // console.log(direction);
  return direction;
};
