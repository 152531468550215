import { PATH } from 'routing/paths';
import { LocaleStore } from 'services/l10n/LocaleStore';
import { MenuItem } from 'ui';

export const eaecMenu = ({ loc }: LocaleStore): Array<MenuItem> => [
  {
    id: 0,
    label: loc('admin.menu.ASSURANCE'),
    iconName: 'detail',
    link: `${PATH.EAEC_PASSPORT_VERIFY}`,
  },
];
