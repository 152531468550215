import axios from 'axios';
import { API_URL } from 'common/constants';
import { RootStore } from 'common/stores/RootStore';
import { stringify } from 'query-string';
import { createSessionInterceptor } from './createSessionInterceptor';

const deps: {
  rootStore?: RootStore;
} = {};

const httpClient = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  paramsSerializer: (params) => {
    return stringify(params);
  },
  withCredentials: true,
});

createSessionInterceptor(httpClient, deps);

function httpClientInject(root: RootStore) {
  deps.rootStore = root;
}

export { httpClient, httpClientInject };
