import { DataQueryFunction, httpClient } from 'rest';

export const queryAdminAssuranceId: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  const res = await httpClient.get(`/admin/assurance/id/${id}`);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
