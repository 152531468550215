import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { clearEmptyFieldsDeep } from 'common/utils';

export const mutationAgreementsSave: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { data } = variables;

  const res = await httpClient.post('/agreements/save', JSON.stringify(clearEmptyFieldsDeep(data)), {
    headers: { 'Content-Type': 'application/json' },
  });
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
