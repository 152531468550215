import React from 'react';
import clsx from 'clsx';
import s from './EaecLoginPage.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Button, FormField, FormInput } from 'ui/common';
import { useLocale } from 'services/l10n/useLocale';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';

const initialValues: any = {};

const schema = yup.object({});

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
};

export const EaecLoginForm: React.FC<Props> = ({ className, onSubmit }) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitHandler = async (values: any, formHelpers: FormikHelpers<any>) => {
    try {
      await onSubmit(values);
    } catch (error) {
      formHelpers.setErrors({
        password: solveErrorMessage(error),
        ...solveFormErrors(error),
      });
      formHelpers.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
      {({ values }) => {
        return (
          <Form className={clsx(className, s.form)}>
            <p className={s.formTitle}>{loc('eaec.loginText1')}</p>
            {/* "m-0 mb-8 fw500" */}
            <p className={s.formText}>{loc('eaec.loginText2')}</p>
            {/* "m-0 mb-24" */}
            <FormField name="login" label={loc('common.labels.logIn')}>
              <FormInput fullWidth={false} placeholder="KZXXXXXX" />
            </FormField>
            <p className={s.subText}>{loc('eaec.loginText3')}</p>
            <FormField name="password" label={loc('common.main.password')}>
              <FormInput fullWidth={false} placeholder="KZXXXXXX" type="password" />
            </FormField>
            <Button className={s.button} type="submit" disabled={!(values.password && values.login)}>
              {loc('common.button.login')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
