import { getLegasyUrl } from 'common/helpers';
import { SntMode } from '../constants';

/**
 * Перенавравоение на страницу бланка СНТ (в старом интерфейсе)
 */
export const navigateToSntPageLegacy = ({ mode, id }: { mode: SntMode; id?: string }) => {
  switch (mode) {
    case SntMode.new:
      window.location.assign(getLegasyUrl('/snt/new'));
      break;
    case SntMode.newPaper:
      window.location.assign(getLegasyUrl('/snt/fromPaper'));
      break;
    case SntMode.draft:
      window.location.assign(getLegasyUrl(`/snt/drafts/${id}`));
      break;
    case SntMode.fixed:
      window.location.assign(getLegasyUrl(`/snt/correction/${id}`));
      break;
    default:
      window.location.assign(getLegasyUrl(`/snt/${mode}/${id}`));
      break;
  }
};
