import { PATH } from '../paths';
import { LAYOUT } from '../layouts';
import { RootRouteConfig } from '../types';
import { PassportVerifyPage } from 'pages/assurance/passport-verify/PassportVerifyPage';
import { PassportEaecPage } from 'pages/assurance/passport-eaec/PassportEaecPage';
import { DevPage } from 'pages/common/login/DevPage';

export const routesEaecUserConfig: RootRouteConfig = {
  layout: LAYOUT.EAEC_ARM,
  routes: [
    {
      path: PATH.MAIN,
      exact: true,
      redirect: PATH.EAEC_PASSPORT_VERIFY,
    },
    {
      path: PATH.EAEC_PASSPORT_VERIFY,
      component: PassportVerifyPage,
      exact: true,
    },
    {
      path: PATH.EAEC_PASSPORT,
      component: PassportEaecPage,
    },
    {
      path: PATH.DEV,
      component: DevPage,
      exclude: process.env.NODE_ENV === 'production',
    },
    {
      path: '*',
      redirect: PATH.EAEC_PASSPORT_VERIFY,
    },
  ],
};
