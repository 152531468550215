import { CodeValueCountry } from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecDocumentsInputColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'envelopeCode',
    label: loc('eaecActions.envelopeCode'),
  },
  {
    name: 'messageId',
    label: loc('eaecActions.messageId'),
    style: {
      width: 300,
    },
  },
  // {
  //   name: 'originalDocumentId',
  //   label: loc('eaecActions.originalDocumentId'),
  //   style: {
  //     width: 300,
  //   },
  // },
  {
    name: 'senderCountryCode',
    label: loc('eaecActions.eaecCountry'),
    getContent: (row) => (row.senderCountryCode ? <CodeValueCountry code={row.senderCountryCode} withFlag /> : null),
  },
  {
    name: 'documentCreateDate',
    label: loc('eaecActions.documentCreateDateTime'),
  },
  {
    name: 'documentRegistrationNumber',
    label: loc('eaecActions.documentRegistrationNumber'),
    style: {
      width: 300,
    },
  },
  {
    name: 'writeOutDate',
    label: loc('eaecActions.writeOutDate'),
  },
  {
    name: 'refDocumentRegistrationNumber',
    label: loc('eaecActions.refDocumentRegistrationNumber'),
    style: {
      width: 300,
    },
  },
  {
    name: 'refWriteOutDate',
    label: loc('eaecActions.refWriteOutDate'),
  },
  {
    name: 'sellerIdentifier',
    label: loc('eaecActions.eaecTaxpayerId'),
  },
  {
    name: 'sellerName',
    label: loc('eaecActions.eaecTaxpayerName'),
    style: {
      width: 350,
    },
  },
  {
    name: 'customerIdentifier',
    label: loc('eaecActions.taxpayerId'),
  },
  {
    name: 'customerName',
    label: loc('eaecActions.taxpayerName'),
    style: {
      width: 350,
    },
  },
  {
    name: 'processDateTime',
    label: loc('eaecActions.processDateTime'),
  },
  {
    name: 'status',
    label: loc('eaecActions.processingStatus'),
    getContent: (row) => (row.status ? loc(`eaecActions.exStatus.${row.status}`) : ''),
  },
];
