import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getNotBioTaxpayersJournalColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'iin',
    label: loc('common.labels.iinOnly'),
  },
  {
    name: 'fio',
    label: loc('common.labels.fioShort'),
    width: 400,
  },
  {
    name: 'createDate',
    label: loc('notBio.createDate'),
    width: 250,
  },
  {
    name: 'armUser',
    label: loc('notBio.armUser'),
    getContent: (row) => [row.armUserLogin, row.armUserFio].filter(Boolean).join(' - '),
    width: 500,
  },
];
