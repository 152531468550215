import { queryTransportType } from 'rest';
import { LocaleStore } from 'services/l10n';

export async function getTransportTypeOptions({ loc, choose }: LocaleStore) {
  const res = await queryTransportType();
  if (Array.isArray(res.data)) {
    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...res.data.map((item: any) => ({
        ...item,
        name: choose([item.nameRu, item.nameKz]),
      })),
    ];
  }
  return [];
}

export const getTransportTypeOptionLabel = (data: any) => {
  return data.label ?? `${data.code} - ${data.name}`;
};

export const getTransportTypeOptionValue = (data: any) => {
  return data.value ?? data.code;
};
