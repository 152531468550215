import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const querySntBorderCrossingExport: DataQueryFunction = async (variables: any) => {
  const { filter = {}, format = '' } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  const params: { criteria: string; format?: string } = { criteria };
  if (format.trim().length > 0) params.format = format;

  try {
    const res = await downloadFile({
      url: `${API_URL}/admin/sntEaes/exportSntBorderCrossing`,
      method: 'GET',
      params: params,
    });
    console.log('res1', res);
    return res;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
