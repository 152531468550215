import { isEmpty } from './isEmpty';

/**
 * Создает новы объект с удаленными путыми полями (рекурсивно)
 * Пустые поля (null, undefined, '', {}, [], [{}])
 * @returns new object
 */
export function clearEmptyFieldsDeep(object?: any): any {
  if (Array.isArray(object)) {
    return recursiveClear(object) || [];
  }
  return recursiveClear(object) || {};
}

function recursiveClear(object?: any): any {
  if (object instanceof File) {
    return object;
  }
  if (typeof object !== 'object' || object === null) {
    return isEmpty(object) ? undefined : object;
  }
  if (Array.isArray(object)) {
    const newArray = object.map((item) => recursiveClear(item)).filter((item) => item !== undefined);
    return newArray.length ? newArray : undefined;
  }
  const newObject = Object.entries(object).reduce((acc: any, [key, value]) => {
    const newValue = recursiveClear(value);
    if (newValue !== undefined) {
      acc[key] = newValue;
    }
    return acc;
  }, {});
  return Object.keys(newObject).length ? newObject : undefined;
}
