import React, { Fragment } from 'react';
import ss from '../../Registration.module.scss';
import { useLocale } from 'services/l10n';
import { getAddressFieldName } from '../../helpers';
import clsx from 'clsx';
import { RegType } from 'features/registration/types';

type Props = {
  className?: string;
  regData: any;
  type: RegType;
  /**
   * Режим краткой информации
   */
  preview?: boolean;
  /**
   * Режим отображения в диалоге Неподписанного Соглашения
   */
  unsigned?: boolean;
};

export const RegCredentials: React.FC<Props> = ({ regData = {}, type, preview, unsigned }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  if (type === RegType.ENTERPRISE) {
    return (
      <div className={clsx(ss.credentials, ss.enterpriseCredentials)}>
        <div>
          <div className={ss.label}>{loc('reg.enterpriseName')}</div>
          <div className={ss.text}>{regData._enterpriseName || (unsigned && regData.name)}</div>
        </div>
        <div>
          <div className={ss.label}>{loc('reg.enterpriseBin')}</div>
          <div className={ss.text}>{regData.enterpriseBin || (unsigned && regData.tin)}</div>
        </div>
        <div>
          <div className={ss.label}>{loc('reg.enterpriseFio')}</div>
          <div className={ss.text}>{regData.fio}</div>
        </div>
        <div>
          <div className={ss.label}>{loc('reg.enterpriseIin')}</div>
          <div className={ss.text}>{regData.iin}</div>
        </div>
        <div>
          <div className={ss.label}>{loc('reg.enterpriseAddress')}</div>
          <div className={ss.text}>{regData._enterpriseAddress || (unsigned && chooseByKey(regData, 'address'))}</div>
        </div>
      </div>
    );
  } else if ([RegType.BAILIFF, RegType.LAWYER, RegType.MEDIATOR, RegType.NOTARY, RegType.ENTREPRENEUR].includes(type)) {
    const addressFieldName = getAddressFieldName(type);
    return (
      <div className={ss.credentials}>
        {preview ? (
          <Fragment>
            <div>
              <div className={ss.label}>{loc('common.labels.name')}</div>
              <div className={ss.text}>
                {type === RegType.ENTREPRENEUR ? regData.individualName || regData.fio : regData.fio}
              </div>
            </div>
            <div>
              <div className={ss.label}>{loc('reg.addressPlace')}</div>
              <div className={ss.text}>{regData[addressFieldName]}</div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div className={ss.label}>{loc('common.labels.name')}</div>
              <div className={ss.text}>
                {type === RegType.ENTREPRENEUR ? regData.individualName || regData.fio : regData.fio}
              </div>
            </div>
            <div>
              <div className={ss.label}>{loc('common.labels.iinOnly')}</div>
              <div className={ss.text}>{regData.iin}</div>
            </div>
            <div></div>
            <div>
              <div className={ss.label}>{loc('reg.passportNum')}</div>
              <div className={ss.text}>{regData.passportNum}</div>
            </div>
            <div>
              <div className={ss.label}>{loc('reg.issuedBy')}</div>
              <div className={ss.text}>{regData.issuedBy}</div>
            </div>
            <div>
              <div className={ss.label}>{loc('reg.issueDate')}</div>
              <div className={ss.text}>{regData.issueDate}</div>
            </div>
            <div style={{ gridColumn: '1 / span 2' }}>
              <div className={ss.label}>{loc('reg.addressPlace')}</div>
              <div className={ss.text}>
                {regData[getAddressFieldName(type)] || (unsigned && chooseByKey(regData, 'address'))}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  } else {
    return (
      <div className={ss.credentials}>
        {preview ? (
          <Fragment>
            <div>
              <div className={ss.label}>{loc('common.labels.fioShort')}</div>
              <div className={ss.text}>{regData.fio}</div>
            </div>
            <div></div>
            <div>
              <div className={ss.label}>{loc('common.labels.iinOnly')}</div>
              <div className={ss.text}>{regData.iin}</div>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div>
              <div className={ss.label}>{loc('common.labels.fioShort')}</div>
              <div className={ss.text}>{regData.fio}</div>
            </div>
            <div>
              <div className={ss.label}>{loc('common.labels.iinOnly')}</div>
              <div className={ss.text}>{regData.iin}</div>
            </div>
            <div></div>
            <div>
              <div className={ss.label}>{loc('reg.passportNum')}</div>
              <div className={ss.text}>{regData.passportNum}</div>
            </div>
            <div>
              <div className={ss.label}>{loc('reg.issuedBy')}</div>
              <div className={ss.text}>{regData.issuedBy}</div>
            </div>
            <div>
              <div className={ss.label}>{loc('reg.issueDate')}</div>
              <div className={ss.text}>{regData.issueDate}</div>
            </div>
            <div style={{ gridColumn: '1 / span 2' }}>
              <div className={ss.label}>{loc('reg.address')}</div>
              <div className={ss.text}>{regData.currentAddress || (unsigned && chooseByKey(regData, 'address'))}</div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
};
