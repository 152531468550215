import React, { Fragment } from 'react';
import { queryMeasureUnit } from 'rest';
import { useLocale } from 'services/l10n';
import { useDictionaryLoad } from './useDictionaryLoad';

let codeMap: any = undefined;

export const getMeasureUnitCode = (data: any) => data?.code;

async function fetchDictionary() {
  try {
    const res = await queryMeasureUnit();
    if (Array.isArray(res.data) && !codeMap) {
      codeMap = Object.fromEntries(res.data.map((item: any) => [String(getMeasureUnitCode(item)), item]));
      console.log('created measureUnitCodeMap');
    }
    return res;
  } catch (error) {
    console.error(error);
  }
}

type Props = {
  code: string;
  format?: 'name' | 'codeWithName';
};

export const CodeValueMeasureUnit: React.FC<Props> = ({ code, format = 'name' }) => {
  const locale = useLocale();
  const { choose } = locale;

  const { show } = useDictionaryLoad(codeMap, fetchDictionary);

  if (!code || !show) {
    return null;
  }

  const data = codeMap?.[code];

  if (!data) {
    return <Fragment>{String(code)}</Fragment>;
  }

  return (
    <Fragment>
      {format === 'name'
        ? choose([data.nameRu, data.nameKz])
        : `(${getMeasureUnitCode(data)}) ${choose([data.nameRu, data.nameKz])}`}
    </Fragment>
  );
};
