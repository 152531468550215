import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryAwpSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination, order, sort } = variables || {};

  let criteria: any = JSON.stringify(clearEmptyFields({ ...filter }));

  const res = await httpClient.get(`awp/search`, {
    params: clearEmptyFields({
      criteria,
      ...toPaginationParams(pagination),
      order,
      sort,
    }),
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
