import React from 'react';
import clsx from 'clsx';
import s from './StorageSelectModal.module.scss';
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ButtonT2 } from 'ui';
import { useNCALayer } from '../useNCALayer';
import { observer } from 'mobx-react-lite';

type Props = {
  className?: string;
};

export const StorageSelectModal: React.FC<Props> = observer(({ className }) => {
  const locale = useLocale();
  const ncaLayer = useNCALayer();
  const { loc } = locale;

  const onClose = () => {
    ncaLayer.setSelectStorageModal({ show: false, types: [] });
  };

  const selectedStorage = ncaLayer.selectedStorage;

  return (
    <Modal isOpen={ncaLayer.selectStorageModal.show} onClose={onClose} theme="T2" center>
      {() => {
        return (
          <div className={clsx(className, s.wrapper)}>
            <ModalHeader>{loc('common.title.chooseStore')}</ModalHeader>
            <ModalContent className={s.content}>
              {ncaLayer.selectStorageModal.types.map((type) => (
                <ButtonT2
                  key={type}
                  className={s.button}
                  fullWidth
                  variant={type === selectedStorage ? 'primary' : 'outlined'}
                  onClick={() => {
                    ncaLayer.setSelectedStorage(type);
                    onClose();
                  }}
                >
                  {loc(`common.storageType.${type}`)}
                </ButtonT2>
              ))}
            </ModalContent>
            <ModalFooter></ModalFooter>
          </div>
        );
      }}
    </Modal>
  );
});
