import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const querySntExport: DataQueryFunction = (variables: any) => {
  const { filter = {} } = variables || {};

  return downloadFile({
    url: `${API_URL}/snt/export`,
    method: 'GET',
    params: { criteria: JSON.stringify(clearEmptyFields(filter)) },
  });
};
