/**
 * Находит пути для всех свойств объекта:
 * { foo: { bar: 123, baz: 321 }, arr: ['a', 'b'] } => { 'foo.bar': 123, 'foo.baz': 321, 'arr[0]': 'a', 'arr[1]': 'b' }
 * @param obj - объект
 * @returns
 */
export function getNestedPropsPaths(obj: any): { [key: string]: any } {
  return getPropPaths(obj);
}

export function getPropPaths(obj: any, propPath: string = ''): { [key: string]: any } {
  if (typeof obj !== 'object' || obj === null) {
    return { [propPath]: obj };
  }
  if (Array.isArray(obj)) {
    return obj.reduce(
      (acc, item, index) => ({
        ...acc,
        ...getPropPaths(item, propPath ? `${propPath}[${index}]` : `[${index}]`),
      }),
      {}
    );
  } else {
    return Object.keys(obj).reduce(
      (acc, key) => ({
        ...acc,
        ...getPropPaths(obj[key], propPath ? `${propPath}.${key}` : key),
      }),
      {}
    );
  }
}
