import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './EaecDocumentRelated.module.scss';
import { Button, Loader, PageTitle, Table } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton, JournalButton } from 'common/components';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import {
  queryEaecDocumentInputJournalExport,
  queryEaecDocumentInputRelated,
  queryEaecDocumentOutputJournalExport,
  queryEaecDocumentOutputRelated,
  queryEaecDownloadChainZip,
} from '../api';
import { getEaecDocumentsInputColumns } from '../eaec-documents-input/getEaecDocumentsInputColumns';
import { getEaecDocumentsOutputColumns } from '../eaec-documents-output/getEaecDocumentsOutputColumns';

type Props = {
  className?: string;
  onClose?: () => void;
  docId: string;
  direction: 'input' | 'output';
};

const getRowId = (row: any) => row?.documentId;

export const EaecDocumentRelated: React.FC<Props> = ({ className, onClose, docId, direction }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);

  const [exporting, setExporting] = useState(false);

  const { data, error } = useDataQuery(
    direction === 'input' ? queryEaecDocumentInputRelated : queryEaecDocumentOutputRelated,
    {
      docId: docId,
    }
  );

  const columns = useMemo(
    () =>
      direction === 'input' ? getEaecDocumentsInputColumns({ locale }) : getEaecDocumentsOutputColumns({ locale }),
    [locale, direction]
  );

  const onChangeSelected = useCallback((rows) => setSelectedRows(rows), []);

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      onClick: () => exportJournal('XLSX'),
    },
    {
      id: 2,
      label: loc('common.button.exportPdf'),
      onClick: () => exportJournal('PDF'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 3,
      label: loc('common.button.xml'),
      onClick: (selection: Array<any>) => exportSelectionXML(selection),
    },
  ];

  const exportSelectionXML = async (selection: Array<any>) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryEaecDownloadChainZip({
          docId: getRowId(selection[0]),
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  const exportJournal = async (format: string) => {
    if (!exporting && Array.isArray(data.rows)) {
      setExporting(true);
      const exportJournal =
        direction === 'input' ? queryEaecDocumentInputJournalExport : queryEaecDocumentOutputJournalExport;
      try {
        await exportJournal({
          filter: { docIds: data.rows.map((row: any) => getRowId(row)) },
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('eaecActions.related')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <Fragment>
          <div className={s.buttons}>
            {actionButtons.map((btn) => (
              <Button
                key={btn.id}
                className={clsx(s.button, s.actionButton)}
                size="small"
                onClick={() => {
                  if (typeof btn.onClick === 'function') {
                    return btn.onClick(selectedRows, columns);
                  }
                }}
                disabled={
                  !selectedRows.length ||
                  (!btn.multiselect && selectedRows.length > 1) ||
                  (typeof btn.disabled === 'function' && btn.disabled(selectedRows))
                }
              >
                {btn.label}
              </Button>
            ))}

            {headerButtons.map((btn) => (
              <Button
                key={btn.id}
                className={clsx(s.button, s.headerButton)}
                size="small"
                onClick={() => {
                  if (typeof btn.onClick === 'function') {
                    return btn.onClick(selectedRows, columns);
                  }
                }}
              >
                {btn.label}
              </Button>
            ))}
          </div>
          <Table
            columns={columns}
            className={s.table}
            rows={data.rows}
            getRowId={getRowId}
            onChangeSelected={onChangeSelected}
            selectable
            scrollable
          />
        </Fragment>
      ) : (
        <Loader />
      )}
    </div>
  );
};
