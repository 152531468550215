import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

export const useOutsideClick2 = (ref: MutableRefObject<any>, callback: () => void, active?: boolean) => {
  const callbackRef = useRef<() => void>(() => undefined);

  const [activeHandler, setActiveHandler] = useState(active);

  callbackRef.current = callback;

  const handleClick = useCallback(
    (e: MouseEvent) => {
      if (activeHandler && ref?.current && !ref.current.contains(e.target)) {
        console.log('Outside Click!!!!');
        callbackRef.current();
      }
      console.log('отказать');
    },
    [activeHandler] // eslint-disable-line
  );

  useEffect(() => {
    if (activeHandler) {
      // console.log('set addEventListener clickOutside');
      document.addEventListener('click', handleClick);
    }
    return () => {
      // console.log('remove addEventListener clickOutside');
      document.removeEventListener('click', handleClick);
    };
  }, [handleClick, activeHandler]);

  useEffect(() => {
    setActiveHandler(active);
  }, [active]);
};
