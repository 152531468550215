import { FormLocaleDatePicker, FilterContentProps, FormOGDSelect, FormCountrySelect } from 'common/components';
import { AppMode } from 'common/constants';
import { useAppMode } from 'common/hooks';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { getAssuranceCorridorOptions, getAssuranceStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const PassportsJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const appMode = useAppMode();

  const { columnClassName } = classNames || {};

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="dateFrom" label={`${loc('assurance.documentDateFrom')}:`}>
          <FormLocaleDatePicker maxDate={new Date()} />
        </FormField>
        <FormField name="dateTo" label={`${loc('assurance.documentDateTo')}:`}>
          <FormLocaleDatePicker maxDate={new Date()} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="passportId" label={loc('assurance.passportId')}>
          <FormInput />
        </FormField>
        <FormField name="passportRegistrationNumber" label={loc('assurance.passportRegistrationNumber')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        {appMode === AppMode.ARM && (
          <FormField name="kogd" label={loc('common.labels.ogdCode')}>
            <FormOGDSelect />
          </FormField>
        )}
        <FormField name="exportCountry" label={loc('assurance.exportCountry')}>
          <FormCountrySelect onlyEaec excludeKZ />
        </FormField>
        <FormField name="statuses" label={loc('assurance.assuranceStatus')}>
          <FormSelect options={getAssuranceStatusOptions(locale)} />
        </FormField>
        <FormField name="corridors" label={loc('assurance.assuranceCorridor')}>
          <FormSelect options={getAssuranceCorridorOptions(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="shippingDocumentNumber" label={loc('assurance.shippingDocumentNumber')}>
          <FormInput />
        </FormField>
        <FormField name="certificateCtNum" label={loc('assurance.certificateCtNum')}>
          <FormInput />
        </FormField>
        {appMode === AppMode.ARM && (
          <FormField name="exporterTin" label={loc('assurance.exporterTin')}>
            <FormInput />
          </FormField>
        )}
      </div>
    </Fragment>
  );
};
