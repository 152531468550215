import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';

export const mutationCurrentUserLanguage: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { lang } = variables;

  const formData = convertToFormData({ lang });

  const res = await httpClient.post('/language', formData);
  if (res?.data?.success === true) {
    return res;
  } else {
    throw res;
  }
};
