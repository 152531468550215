import React from 'react';
import { FieldProps } from 'formik';
import { RadioBlock, RadioBlockProps } from '../controls/radio-block';
import { FormValueProps } from './FormValueProps';

type Props = Partial<FieldProps> &
  Omit<RadioBlockProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<string | boolean>) => void;
  };

export const FormRadioBlock: React.FC<Props> = ({ field, form, meta, onValueChange, ...rest }) => {
  const { name } = field || {};
  const { setFieldValue } = form || {};

  const onInputChange = (value: string | boolean) => {
    if (onValueChange && name && form) {
      onValueChange({ name, value, form });
      return;
    }
    if (setFieldValue && name) {
      setFieldValue(name, value);
      return;
    }
  };

  return <RadioBlock {...rest} {...field} onChange={onInputChange} />;
};
