import React, { ReactNode } from 'react';
import clsx from 'clsx';
import './DoubleDateWrapper.scss';

type Props = {
  className?: string;
  renderLeft: (className: string) => ReactNode;
  renderRight: (className: string) => ReactNode;
  error?: boolean;
};

/**
 * Обертка для отображения двойного датапикера.
 * Определяет только стили отбражения
 * renderLeft/renderRight - рендер пропсы для рендера DatePicker
 */
export const DoubleDateWrapper: React.FC<Props> = ({ className, renderLeft, renderRight, error }) => {
  return (
    <div
      className={clsx(className, 'double-date', {
        'double-date--error': error,
      })}
    >
      {renderLeft('double-date--picker double-date--picker__left')}
      {renderRight('double-date--picker double-date--picker__right')}
    </div>
  );
};
