import React, { SyntheticEvent, useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import { Textarea, TextareaProps } from 'ui';

export type FormTextareaProps2 = Partial<FieldProps> &
  Omit<TextareaProps, 'name' | 'onChange' | 'onBlur'> & {
    onValueChange?: (value: any, name: string, e?: SyntheticEvent) => void;
    changeOnBlur?: boolean;
  };

/**
 * Костыль для Textarea для задачи DESF-1923
 */
export const FormTextarea2: React.FC<FormTextareaProps2> = ({ field, form, meta, value, ...rest }) => {
  const { name, onBlur } = field || {};
  const { setFieldValue } = form || {};

  const [inputValue, setInputValue] = useState(value);
  const [error, setErrorValue] = useState(false);

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    if (e.target.value.indexOf('*') !== -1) setErrorValue(true);
    else setErrorValue(false);
  };

  const onInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    typeof setFieldValue === 'function' && !!name && setFieldValue(name, e.target.value);
    typeof onBlur === 'function' && onBlur(e);
  };
  //console.log('form2',value);
  useEffect(() => {
    if (value === undefined) return;
    //console.log('form2useEffect',value);
    setInputValue(value);
    if (value.indexOf('*') !== -1) setErrorValue(true);
    else setErrorValue(false);
  }, [value]);

  return (
    <Textarea {...rest} {...field} error={error} value={inputValue} onChange={onInputChange} onBlur={onInputBlur} />
  );
};
