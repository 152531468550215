import { clearEmptyFields } from 'common/utils';
import { convertToFormDataDeep, DataQueryFunction, httpClient } from 'rest';

export const mutVstoreUserSettingsSave: DataQueryFunction = async (variables: any) => {
  const { body = {} } = variables;

  const formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post(`/vstore/rest/user-settings/save`, formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
