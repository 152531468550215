import {
  FilterContentProps,
  FormDoubleDatePicker,
  FormListUploader,
} from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { ToastService } from 'services/toasts';
import {
  Field,
  FormField,
  FormInput,
  FormMultiselect,
  FormSelect,
} from 'ui/common';
import {
  getSntTermsCustomerTypeOptions,
  getSntTermsTransportTypeOptions,
  getSntTermsTypeOptions,
  getSntTransferTypeOptions,
  getTermsSntProductTypeOptions,
  getTermsSntStatusOptions,
} from '../constants';

type Props = FilterContentProps;

export const SntTermsConfirmationFilters: React.FC<Props> = ({
  form,
  classNames,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection, gridSectionExpandalbe } = classNames || {};

  const sntTypeOptions = useMemo(
    () => getSntTermsTypeOptions(locale),
    [locale]
  );

  const sntStatusOptions = useMemo(
    () => getTermsSntStatusOptions(locale),
    [locale]
  );

  const transferOptions = useMemo(
    () => getSntTransferTypeOptions(locale),
    [locale]
  );

  const customerTypeOptions = useMemo(
    () => getSntTermsCustomerTypeOptions(locale),
    [locale]
  );

  const transportTypeOptions = useMemo(
    () => getSntTermsTransportTypeOptions(locale),
    [locale]
  );

  const productTypeOptions = useMemo(
    () => getTermsSntProductTypeOptions(locale),
    [locale]
  );

  const preProcessList = (list: Array<string>, subject: 'iin' | 'number') => {
    if (Array.isArray(list)) {
      if (list.length <= 500) {
        return list;
      } else {
        ToastService.showError(loc(`sntTerms.listMax.${subject}`));
      }
    }
  };

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection}>
          <Field label={loc('sntTerms.issueDate')} noMargin>
            <FormDoubleDatePicker
              nameFrom="dateFrom"
              nameTo="dateTo"
              maxDate={new Date()}
            />
          </Field>
          <Field label={loc('snt.shippingDate')} noMargin>
            <FormDoubleDatePicker
              nameFrom="shippingDateFrom"
              nameTo="shippingDateTo"
              maxDate={new Date()}
            />
          </Field>
          <Field label={loc('sntTerms.confirmDate2')} noMargin>
            <FormDoubleDatePicker
              nameFrom="customerChangeStatusDateFrom"
              nameTo="customerChangeStatusDateTo"
              maxDate={new Date()}
            />
          </Field>
          {/* <Field label={loc('sntTerms.termDate')} noMargin>
            <FormDoubleDatePicker
              nameFrom="confirmDeadlineDateFrom"
              nameTo="confirmDeadlineDateTo"
              maxDate={new Date()}
            />
          </Field> */}
        </div>
        <div className={gridSectionExpandalbe}>
          <div className={gridSection}>
            <div>
              <FormField
                name="sellerTin"
                label={loc('common.labels.sellerTin')}
                noMargin
              >
                <FormInput />
              </FormField>
              <FormListUploader
                name="sellerTins"
                labelCount={`${loc('sntTerms.loadedIins')}:`}
                preProcess={(list) => preProcessList(list, 'iin')}
              />
            </div>
            <div>
              <FormField
                name="customerTin"
                label={loc('common.labels.recipientTin')}
                noMargin
              >
                <FormInput />
              </FormField>
              <FormListUploader
                name="customerTins"
                labelCount={`${loc('sntTerms.loadedIins')}:`}
                preProcess={(list) => preProcessList(list, 'iin')}
              />
            </div>
            <div>
              <FormField
                name="registrationNumber"
                label={loc('sntTerms.registrationNumber2')}
                noMargin
              >
                <FormInput />
              </FormField>
              <FormListUploader
                name="registrationNumberList"
                labelCount={`${loc('sntTerms.loadedNumbers')}:`}
                preProcess={(list) => preProcessList(list, 'number')}
              />
            </div>
            <div>
              <FormField name="number" label={loc('sntTerms.number')} noMargin>
                <FormInput />
              </FormField>
              <FormListUploader
                name="numberList"
                labelCount={`${loc('sntTerms.loadedNumbers')}:`}
                preProcess={(list) => preProcessList(list, 'number')}
              />
            </div>

            <FormField name="type" label={loc('common.snt.type')} noMargin>
              <FormSelect options={sntTypeOptions} />
            </FormField>
            <FormField
              name="exportImportTransferType"
              label={loc('sntTerms.transfer')}
              noMargin
            >
              <FormSelect options={transferOptions} />
            </FormField>
            <FormField
              name="customerStatus"
              label={loc('sntTerms.customerStatus')}
              noMargin
            >
              <FormSelect options={customerTypeOptions} />
            </FormField>
            <FormField
              name="transportType"
              label={loc('snt.transportType')}
              noMargin
            >
              <FormSelect options={transportTypeOptions} />
            </FormField>

            <FormField
              name="statuses"
              label={loc('common.snt.status')}
              style={{ alignSelf: 'flex-start' }}
              noMargin
            >
              <FormMultiselect
                options={sntStatusOptions}
                variant="compact-outlined"
              />
            </FormField>

            <FormField
              name="productSectionList"
              label={loc('sntTerms.productSectionList')}
              style={{ gridColumnEnd: 'span 2', alignSelf: 'flex-start' }}
              noMargin
            >
              <FormMultiselect
                options={productTypeOptions}
                variant="compact-outlined"
                rows={5}
              />
            </FormField>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
