import React, { useEffect } from 'react';
import clsx from 'clsx';
import s from './UserHistoryContent.module.scss';
import { ModalHeader } from 'services/modal';
import { Table } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { queryUserHistory, useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';

type Props = ModalChildrenProps & {
  className?: string;
  modal?: boolean;
  iin: number;
  onCloseModal?: () => void;
};

const getColumns = ({ loc, choose }: LocaleStore) => [
  {
    name: 'blockingDate',
    label: loc('common.user.history.blockingDate'),
    style: {
      width: '17%',
    },
  },
  {
    name: 'blockingReason',
    label: loc('common.user.history.blockingReason'),
    style: {
      width: '33%',
    },
  },
  {
    name: 'restoreDate',
    label: loc('common.user.history.restoreDate'),
    style: {
      width: '17%',
    },
  },
  {
    name: 'restoreReason',
    label: loc('common.user.history.restoreReason'),
    style: {
      width: '33%',
    },
  },
];

export const UserHistoryContent: React.FC<Props> = ({ className, modal = true, iin, onReady }) => {
  const locale = useLocale();
  const { data, loading } = useDataQuery(queryUserHistory, { iin });

  const columns = getColumns(locale);

  useEffect(() => {
    if (!loading && typeof onReady === 'function') onReady();
  }, [loading, onReady]);

  return !data ? null : (
    <div className={clsx(className, s.wrapper, { [s.modal]: modal })}>
      {modal && <ModalHeader>История блокировок пользователя АРМ</ModalHeader>}
      <div className={s.container}>
        <Table columns={columns} rows={data} variant="clean" getRowId={(row: any) => row.iin} />
      </div>
    </div>
  );
};
