import {
  FormAutocompleteIIN,
  FormLocaleDatePicker,
  FormMeasureUnitSelect,
  FormTaxpayerStoreSelect,
} from 'common/components/controls';
import { FilterContentProps } from 'common/components/filter-panel';
import { AppMode } from 'common/constants';
import { resolveTaxpayerNameOrFio } from 'common/helpers/taxpayer';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormValueProps } from 'ui/common';
import { MIN_PERIOD_DATE } from './helpers';

type Props = FilterContentProps;

export const SaldoReportFiltersArm: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const { values } = form;
  const locale = useLocale();
  const { loc } = locale;

  const onIINChange = ({ value, name, form }: FormValueProps<any>) => {
    form.setFieldValue(name, value || undefined);
    form.setFieldValue('_taxpayerName', resolveTaxpayerNameOrFio(value, locale));
    form.setFieldValue('_storeId', '');
  };

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('common.labels.tin')}>
          <FormAutocompleteIIN onValueChange={onIINChange} />
        </FormField>
        <FormField name="_taxpayerName" label={loc('common.labels.npName')}>
          <FormInput output />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="_storeId" label={loc('common.labels.storeName')} fast={false}>
          <FormTaxpayerStoreSelect appMode={AppMode.ARM} tin={values.tin?.tin} />
        </FormField>
        <FormField name="productId" label={loc('common.labels.productId')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="measureUnitId" label={loc('common.labels.measureUnitName')}>
          <FormMeasureUnitSelect />
        </FormField>
        <FormField name="tnvedCode" label={loc('common.labels.tnvedCode')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="_period" label={loc('saldo.period')}>
          <FormLocaleDatePicker
            placeholderText={loc('saldo.choosePeriod')}
            showMonthYearPicker
            minDate={MIN_PERIOD_DATE}
          />
        </FormField>
      </div>
    </Fragment>
  );
};
