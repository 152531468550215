import { useGlobalTheme } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Modal } from 'services/modal/Modal';
import { ModalChildrenProps } from 'services/modal/types/ModalChildrenProps';
import { DialogProps } from './types';
import { useDialog } from './useDialog';

type Props = {
  component: React.FC<DialogProps & ModalChildrenProps & { onConfirm: () => void; onReject: () => void }>;
};

export const DialogContainer: React.FC<Props> = observer(({ component: Component }) => {
  const dialog = useDialog();
  const theme = useGlobalTheme();
  return (
    <Modal isOpen={dialog.state.show} onClose={() => dialog.cancel()} theme={theme} center={dialog.state.props?.center}>
      <Component {...dialog.state.props} onConfirm={() => dialog.confirm()} onReject={() => dialog.reject()} />
    </Modal>
  );
});
