import React from 'react';
import s from './TaxpayerMainLogo.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  onClick?(): void;
};

export const TaxpayerMainLogo: React.FC<Props> = ({ className, onClick = () => {} }) => {
  return <div className={clsx(className, s.wrapper)} onClick={onClick} />;
};
