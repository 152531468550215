import React, { Fragment } from 'react';
import { SelectMethodModal } from './SelectMethodModal';
import { Modal } from 'services/modal';
import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import { QrCodeModal } from './QrCodeModal';

type Props = {
  className?: string;
};

export const AuthModals: React.FC<Props> = observer(() => {
  const { auth } = useStore();

  return (
    <Fragment>
      <Modal
        isOpen={auth.selectMethodModalState.opened}
        onClose={() => {
          typeof auth.selectMethodModalState.cancel === 'function' &&
            auth.selectMethodModalState.cancel({ message: 'canceled by user' });
          auth.setSelectMethodModalState({ opened: false });
        }}
        theme="T2"
        center
      >
        <SelectMethodModal
          onSelect={(method) =>
            typeof auth.selectMethodModalState.cb === 'function' && auth.selectMethodModalState.cb(method)
          }
          type={auth.selectMethodModalState.type}
        />
      </Modal>

      <Modal
        isOpen={auth.qrCodeModalState.opened}
        onClose={() => {
          typeof auth.qrCodeModalState.cancel === 'function' &&
            auth.qrCodeModalState.cancel({ message: 'canceled by user' });
          auth.setQrCodeModalState({ opened: false });
        }}
        theme="T2"
        center
      >
        <QrCodeModal qrUrl={auth.qrCodeModalState.qrUrl} type={auth.qrCodeModalState.type} />
      </Modal>
    </Fragment>
  );
});
