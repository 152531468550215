import { nanoid } from 'nanoid';
import { generatePath } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { SessionStorageService } from 'services/storage/StorageService';
import { TableColumn } from 'ui/common';
import { LocaleStore } from 'services/l10n';
import { getEsfStatusOptions, getEsfTypeOptions } from 'pages/esf/helpers';
import { BASE_URL } from 'common/constants';
import { getCellContent } from 'ui';
import { getDirectionOptions } from 'common/helpers';

export type PrintTableColumn = {
  name: string;
  label: string;
  width?: string | number;
};

export type PrintDataConfig = {
  title: string;
  columns: Array<PrintTableColumn>;
  rows: Array<{ [key: string]: string }>;
  filter?: Array<{ label: string; value: string }>;
};

export const printJournalKeyPrefix = 'printJournal_';

export const toPrintJournalPage = (config: PrintDataConfig) => {
  const id = nanoid(8);
  SessionStorageService.set(`${printJournalKeyPrefix}${id}`, config);
  window.open(`${BASE_URL}${generatePath(PATH.PRINT_JOURNAL, { id })}`, '_blank');
};

export const toPrintJournalPageEsf = ({
  rows,
  columns,
  filter,
  locale,
  title,
}: {
  title: string;
  columns: Array<TableColumn>;
  rows: Array<any>;
  filter?: any;
  locale: LocaleStore;
}) => {
  const { loc } = locale;
  if (Array.isArray(rows)) {
    rows.forEach((row) => {
      delete row.certificate;
      delete row.invoiceBody;
      delete row.signature;
    });

    const printRows = rows.map((row, rowIndex) =>
      Object.fromEntries(columns.map((col) => [col.name, getCellContent(row, rowIndex, col)]))
    );

    const printColumns = columns.map((col) => ({
      name: col.name,
      label: col.label,
      width: col.style?.width,
    }));

    const printFilter: Array<{ label: string; value: string }> = [
      {
        label: loc('esf.registrationNumber'),
        value: filter['regNumber'],
      },
      {
        label: loc('common.labels.accountingNumber'),
        value: filter['num'],
      },
      {
        label: loc('direction'),
        value: getDirectionOptions(locale).find((item) => item.value === filter['direction'])?.label,
      },
      {
        label: loc('c.esf.type'),
        value: getEsfTypeOptions(locale).find((item) => item.value === filter['type'])?.label,
      },
      {
        label: loc('esf.contragent'),
        value: filter['contragentTin'],
      },

      {
        label: `${loc('esf.lastUpdateDate2')} ${loc('common.labels.from')}:`,
        value: filter['lastUpdateDateFrom'],
      },
      {
        label: `${loc('esf.lastUpdateDate2')} ${loc('common.labels.to')}:`,
        value: filter['lastUpdateDateTo'],
      },
      {
        label: `${loc('esf.invoiceDate')} ${loc('common.labels.from')}:`,
        value: filter['dateFrom'],
      },
      {
        label: `${loc('esf.invoiceDate')} ${loc('common.labels.to')}:`,
        value: filter['dateTo'],
      },
      {
        label: loc('common.esf.status'),
        value:
          Array.isArray(filter['statuses']) &&
          filter['statuses']
            .map((st) => getEsfStatusOptions(locale).find((item) => item.value === st)?.label)
            .filter(Boolean)
            .join('\n'),
      },
    ].filter((item) => item.value);

    console.log({ printRows, printColumns, printFilter });

    toPrintJournalPage({
      title,
      rows: printRows,
      columns: printColumns,
      filter: printFilter,
    });
  }
};
