import { numberWithSpaces } from 'common/utils';
import { getIn } from 'formik';
import { TableColumn } from './types';

export const getCellContent = (row: any, rowIndex: number, column: TableColumn) => {
  const content = column.getContent
    ? column.getContent(row, rowIndex, getIn(row, column.name))
    : getIn(row, column.name);
  return column.numberWithSpaces ? numberWithSpaces(content) : content;
};
