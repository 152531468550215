import React, { Fragment, useEffect, useMemo, useState } from 'react';
import s from './PassportDocument.module.scss';
import { Accordion, Button, DataGrid, Field, Heading, Input, SvgIcon, Table } from 'ui/common';
import { useLocale } from 'services/l10n';
import { QrCode } from '../components/QrCode';
import {
  getConversionColumns,
  getEditingColumns,
  getEsfColumns,
  getPackingColumns,
  getProductsColumns,
  getResortingColumns,
  getSntColumns,
  getUnpackingColumns,
} from './PassportTablesColumns';
import clsx from 'clsx';
import { Modal, useDialog } from 'services/modal';
import { CodeValueOgd, ImportModal } from 'common/components';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import { capitalizeFirstLetter } from 'common/utils';
import { AppMode } from 'common/constants';
import { mutationAdminAssuranceAttachDoc, mutationAdminAssuranceDeleteAttachedDoc } from '../api';
import { ASSURANCE_CORRIDOR, AssuranceStatus } from '../helpers';
import { PassportAttachmentEsf } from './attachments/PassportAttachmentEsf';
import { PassportAttachmentFno } from './attachments/PassportAttachmentFno';
import { PassportAttachmentCertificate } from './attachments/PassportAttachmentCertificate';
import { PassportAttachmentDt } from './attachments/PassportAttachmentDt';

type Props = {
  className?: string;
  data: any;
  reloadData?: () => void;
  appMode: AppMode;
};

const columnClassNames = [s.gridLeftColumn, s.gridRightColumn];

/**
 * Форма просмотра Паспрота продеживаемости
 * Общая форма для АРМ, НП и EAEC пользователя
 */
export const PassportDocument: React.FC<Props> = ({ className, data, reloadData, appMode }) => {
  const locale = useLocale();
  const { loc } = locale;
  const dialog = useDialog();

  const [importModal, setImportModal] = useState<boolean>(false);

  // console.log(data, [data?.passport]);

  const columns = useMemo(
    () => ({
      productsColumns: getProductsColumns({ locale, appMode, data }),
      sntColumns: getSntColumns({ locale, appMode, passport: data }),
      esfColumns: getEsfColumns({ locale, appMode, passport: data }),
      packingColumns: getPackingColumns({ locale, appMode }),
      unpackingColumns: getUnpackingColumns({ locale, appMode }),
      conversionColumns: getConversionColumns({ locale, appMode }),
      editingColumns: getEditingColumns({ locale, appMode }),
      resortingColumns: getResortingColumns({ locale, appMode }),
    }),
    [locale, appMode, data]
  );

  // Группируем Сведения по изменению товара
  const operationRows = useMemo(() => {
    const rows: { [key: string]: Array<any> } = {
      operationsWithPacking: [],
      operationsWithConversion: [],
      operationsWithUnpacking: [],
      operationsWithResorting: [],
      operationsWithEditing: [],
    };
    const products: Array<any> = data?.passport?.passportProducts;
    products.forEach((prod) => {
      Object.keys(rows).forEach((op) => {
        if (Array.isArray(prod?.operationSet?.[op]) && prod?.operationSet?.[op].length) {
          rows[op] = [
            ...rows[op],
            ...prod?.operationSet?.[op].map((item: any) => ({
              ...prod,
              ...item,
            })),
          ];
        }
      });
    });
    return rows;
  }, [data]);

  const askDeleteDoc = async (doc: any) => {
    const confirmed = await dialog.show({
      content: (
        <Fragment>
          Удалить документ?
          <p>{doc.fileName}</p>
        </Fragment>
      ),
    });
    if (confirmed) {
      try {
        await mutationAdminAssuranceDeleteAttachedDoc({
          body: {
            passportId: data.passportId,
            documentId: doc.binaryDocumentId,
          },
        });
        typeof reloadData === 'function' && reloadData();
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    }
    console.log(confirmed);
  };

  const sendImportedFile = async (files: File[]) => {
    try {
      const res = await mutationAdminAssuranceAttachDoc({
        body: { passportId: data.passportId, files },
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  const onImportSuccess = (result: any) => {
    setImportModal(false);
    typeof reloadData === 'function' && reloadData();
  };

  // Вывод сообщений, с причиной попадания продуктов в красный коридор
  const checkProductsCoridors = () => {
    const products = data?.passport?.passportProducts;
    if (Array.isArray(products)) {
      let messages: any[] = [];
      products.forEach((product) => {
        if (
          product.assuranceCorridor === ASSURANCE_CORRIDOR.RED &&
          [
            'PRODUCTS_BALANCE_WITH_ORIGIN_CODE_1',
            'PRODUCTS_WITHOUT_CERTIFICATE_ORIGIN_CODE_3',
            'PRODUCTS_UNEQUAL_INVOICE_AND_IMPORT_NAME_WITH_CODE_1',
            'PRODUCTS_UNEQUAL_INVOICE_AND_CERT_NAME_WITH_CODE_3',
            'PRODUCTS_EXPORT_OF_TEMPORARY_IMPORTED_PRODUCT',
          ].includes(product.assuranceRedCorridorCause)
        ) {
          messages.push(loc(`assurance.${product.assuranceRedCorridorCause}`, product.productId));
        }
      });
      messages.slice(0, 6).forEach((message) => ToastService.showInfo(message));
    }
  };

  useEffect(() => {
    // Для Паспортов в статусе «Отправлено на проверку в ОГД», «На рассмотрении в ОГД» проверяем коридоры для продуктов
    if (
      appMode !== AppMode.EAEC &&
      (data?.assuranceStatus === AssuranceStatus.SEND_FOR_VERIFICATION ||
        data?.assuranceStatus === AssuranceStatus.UNDER_CONSIDERATION)
    ) {
      checkProductsCoridors();
    }
  }, []); // eslint-disable-line

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.headSection}>
        <Heading className={s.mainTitle} tag="h2">
          {loc('assurance.pasport')}
        </Heading>
        <Heading className={s.mainSubTitle} tag="h2">
          {loc('assurance.pasportSubTitle')}
        </Heading>
        <Field label={loc(`assurance.passportId`)} direction="line" className={s.idField}>
          <Input fullWidth={false} output value={data?.stringPassportId} />
        </Field>
        <QrCode className={s.qrCode} regNumber={data?.passport?.passportRegistrationNumber} />
      </div>

      <div className={s.section}>
        <Heading className={s.title} tag="h2">
          {loc('assurance.section.a')}
        </Heading>
        <DataGrid
          data={[
            [loc('assurance.passportRegistrationNumber'), data?.passport?.passportRegistrationNumber],
            [loc('assurance.passportDate'), data?.passport?.passportDate],
            [loc('assurance.assuranceStatus'), loc(`assurance.${data?.assuranceStatus}`)],
          ]}
          columnClassNames={columnClassNames}
        />
      </div>

      <div className={s.section}>
        <Heading className={s.title} tag="h2">
          {loc('assurance.section.b')}
        </Heading>
        <DataGrid
          data={[
            [loc('assurance.exporterTin'), data?.passport?.exporterTin],
            [loc('assurance.exporter'), data?.passport?.exporterName],
            [loc('assurance.address'), data?.passport?.exporterAddress],
            [loc('common.labels.ogd'), `(${data?.passport?.kogd}) ${data?.passport?.kogdName}`],
          ]}
          columnClassNames={columnClassNames}
        />
      </div>

      <div className={s.section}>
        <Heading className={s.title} tag="h2">
          {loc('assurance.section.c')}
        </Heading>
        <DataGrid
          data={[
            [loc('assurance.customerTin'), data?.passport?.customerTin],
            [loc('assurance.customer'), data?.passport?.customerName],
            [loc('assurance.address'), data?.passport?.customerAddress],
            [loc('common.labels.country'), capitalizeFirstLetter(data?.passport?.exportCountryName, true)],
          ]}
          columnClassNames={columnClassNames}
        />
      </div>

      {/* Раздел D. Сведения по документам и товарным позициям, подлежащим заверению */}
      <div className={s.section}>
        <Heading className={s.title} tag="h2">
          {loc('assurance.section.d')}
        </Heading>
        <Table
          columns={columns.sntColumns}
          className={clsx(s.table, s.docTable)}
          rows={[data?.passport]}
          getRowId={(row) => row.passportId}
        />
        <Table
          columns={columns.esfColumns}
          className={clsx(s.table, s.docTable)}
          rows={[data?.passport]}
          getRowId={(row) => row.passportId}
        />
        <Heading className={s.subTitle} tag="h3">
          {loc('assurance.title.products')}
        </Heading>
        <Table
          columns={columns.productsColumns}
          className={s.table}
          rows={data?.passport?.passportProducts}
          getRowId={(row, index) => index}
          scrollable
          selectable
        />
        {appMode === AppMode.ARM && (
          <Fragment>
            <Heading className={s.subTitle} tag="h3">
              {loc('assurance.title.documents')}
            </Heading>
            {Array.isArray(data?.assurancePassportAttachmentDocs) && data?.assurancePassportAttachmentDocs.length ? (
              <DataGrid
                className={s.addDocGrid}
                data={data.assurancePassportAttachmentDocs.map((doc: any) => [
                  doc.fileName,
                  doc.uploadDate,
                  <div className={s.addDocCloseButton} onClick={() => askDeleteDoc(doc)}>
                    <SvgIcon name="close" />
                  </div>,
                ])}
                columnClassNames={[s.addDocLinks, s.addDocDate, s.addDocClose]}
              />
            ) : (
              <div>{loc('assurance.noDocuments')}</div>
            )}
            {data?.assuranceStatus === AssuranceStatus.UNDER_CONSIDERATION && (
              <Button className={s.addDocButton} variant="secondary" onClick={() => setImportModal(true)}>
                {loc('assurance.addDocument')}
              </Button>
            )}

            <Modal isOpen={importModal} onClose={() => setImportModal(false)}>
              <ImportModal onSuccess={onImportSuccess} sendImported={sendImportedFile} />
            </Modal>
          </Fragment>
        )}
      </div>

      {/* Раздел E. Сведения по изменению товара */}
      {!(appMode === AppMode.EAEC) && (
        <div className={s.section}>
          <Heading className={s.title} tag="h2">
            {loc('assurance.section.e')}
          </Heading>
          {!!operationRows.operationsWithPacking.length && (
            <Fragment>
              <Heading className={s.subTitle} tag="h3">
                {loc('assurance.title.Packing')}
              </Heading>
              <Table
                columns={columns.packingColumns}
                className={s.table}
                rows={operationRows.operationsWithPacking}
                getRowId={(row, index) => index}
                scrollable
              />
            </Fragment>
          )}
          {!!operationRows.operationsWithUnpacking.length && (
            <Fragment>
              <Heading className={s.subTitle} tag="h3">
                {loc('assurance.title.Unpacking')}
              </Heading>
              <Table
                columns={columns.unpackingColumns}
                className={s.table}
                rows={operationRows.operationsWithUnpacking}
                getRowId={(row, index) => index}
                scrollable
              />
            </Fragment>
          )}
          {!!operationRows.operationsWithConversion.length && (
            <Fragment>
              <Heading className={s.subTitle} tag="h3">
                {loc('assurance.title.Conversion')}
              </Heading>
              <Table
                columns={columns.conversionColumns}
                className={s.table}
                rows={operationRows.operationsWithConversion}
                getRowId={(row, index) => index}
                scrollable
              />
            </Fragment>
          )}
          {!!operationRows.operationsWithEditing.length && (
            <Fragment>
              <Heading className={s.subTitle} tag="h3">
                {loc('assurance.title.Editing')}
              </Heading>
              <Table
                columns={columns.editingColumns}
                className={s.table}
                rows={operationRows.operationsWithEditing}
                getRowId={(row, index) => index}
                scrollable
              />
            </Fragment>
          )}
          {!!operationRows.operationsWithResorting.length && (
            <Fragment>
              <Heading className={s.subTitle} tag="h3">
                {loc('assurance.title.Resorting')}
              </Heading>
              <Table
                columns={columns.resortingColumns}
                className={s.table}
                rows={operationRows.operationsWithResorting}
                getRowId={(row, index) => index}
                scrollable
              />
            </Fragment>
          )}
        </div>
      )}

      {/* Раздел F. Дополнительные сведения */}
      <div className={s.section}>
        <Heading className={s.title} tag="h2">
          {loc('assurance.section.f')}
        </Heading>
        <DataGrid
          data={[
            [loc('assurance.ogdEmployeeFullName'), data.ogdEmployeeFullName],
            [loc('assurance.ogdEmployeeIin'), data.ogdEmployeeIin],
            [loc('common.labels.ogd'), <CodeValueOgd code={data?.ogdEmployeeOgdCode} />],
            [loc('assurance.ogdEmployeeSignature'), <span className="word-break">{data?.ogdSignature}</span>],
            [loc('assurance.systemSignature'), <span className="word-break">{data?.systemSignature}</span>],
            [loc('assurance.rejectionReason'), data.rejectionReason],
            [loc('assurance.cancelReason'), data.cancelReason && loc(`assurance.reason.${data.cancelReason}`)],
          ]}
          columnClassNames={columnClassNames}
        />
      </div>

      {!!data?.passportRelatedDocuments?.invoiceInfo && (
        <Accordion
          className={s.accordion}
          headerClassName={s.accordionHeader}
          iconClassName={s.accordionIcon}
          renderHeader={() => loc('assurance.attach.1')}
          initialOpened={false}
        >
          <PassportAttachmentEsf data={data?.passportRelatedDocuments?.invoiceInfo} />
        </Accordion>
      )}
      {Array.isArray(data?.passportRelatedDocuments?.passportGtdAppendices) && (
        <Accordion
          className={s.accordion}
          headerClassName={s.accordionHeader}
          iconClassName={s.accordionIcon}
          renderHeader={() => loc('assurance.attach.2')}
          initialOpened={false}
        >
          <PassportAttachmentDt data={data?.passportRelatedDocuments?.passportGtdAppendices} />
        </Accordion>
      )}
      {Array.isArray(data?.passportRelatedDocuments?.documentFnoList) && (
        <Accordion
          className={s.accordion}
          headerClassName={s.accordionHeader}
          iconClassName={s.accordionIcon}
          renderHeader={() => loc('assurance.attach.3')}
          initialOpened={false}
        >
          <PassportAttachmentFno data={data?.passportRelatedDocuments?.documentFnoList} />
        </Accordion>
      )}
      {Array.isArray(data?.passportRelatedDocuments?.certificateInfoList) && (
        <Accordion
          className={s.accordion}
          headerClassName={s.accordionHeader}
          iconClassName={s.accordionIcon}
          renderHeader={() => loc('assurance.attach.4')}
          initialOpened={false}
        >
          <PassportAttachmentCertificate data={data?.passportRelatedDocuments?.certificateInfoList} />
        </Accordion>
      )}
    </div>
  );
};
