import React from 'react';
import clsx from 'clsx';
import s from './ResetPasswordPage.module.scss';
import { CardMain } from 'ui';
import { useLocale } from 'services/l10n';
import { ResetPasswordForm } from './ResetPasswordForm';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { mutationResetPasswordRequest } from 'rest';

type Props = {
  className?: string;
};

export const ResetPasswordPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const sibmitFrom = async (values: any) => {
    return mutationResetPasswordRequest({ body: values });
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <CardMain className={s.card}>
        <h1>{loc('reset.password')}</h1>
        <ResetPasswordForm onSubmit={sibmitFrom} />
      </CardMain>
      <div className={s.links}>
        <Link to={PATH.MAIN}>{loc('reset.return')}</Link>
      </div>
    </div>
  );
};
