import React, { Fragment } from 'react';
import classNames from 'classnames';
import s from './Breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import { ReactComponent as Home } from 'assets/icons/home.svg';
import { SvgIcon } from '../svg-icon';
import clsx from 'clsx';

export type Crumb = {
  label: string | React.ReactNode;
  link?: string | null;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

type Props = {
  className?: string;
  crumbs: Array<Crumb>;
  variant?: 'main' | 'taxpayer';
};

export const Breadcrumbs: React.FC<Props> = ({ className, crumbs, variant = 'main' }) => {
  return (
    <div className={classNames(className, s.wrapper, s[variant])}>
      {!!crumbs[0] && (
        <Link to={crumbs[0].link || '/'} className={clsx(s.link, s.homeLink)}>
          <SvgIcon component={Home} className={s.homeIcon} />
          {crumbs[0].label}
        </Link>
      )}
      {crumbs.slice(1).map((crumb, index) => (
        <Fragment key={index}>
          <span className={s.divider}>{variant === 'taxpayer' ? <span className={s.dot} /> : '/'}</span>
          {crumb.link ? (
            <Link to={crumb.link} className={s.link}>
              {crumb.label}
            </Link>
          ) : typeof crumb.onClick === 'function' ? (
            <a
              href={crumb.link || ''}
              className={s.link}
              onClick={(e) => {
                e.preventDefault();
                if (typeof crumb.onClick === 'function') {
                  crumb.onClick(e);
                }
              }}
            >
              {crumb.label}
            </a>
          ) : (
            <span className={s.label}>{crumb.label}</span>
          )}
        </Fragment>
      ))}
    </div>
  );
};
