import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryUser: DataQueryFunction = (variables: any) => {
  const { iin } = variables || {};

  return httpClient.get(`arm/get/${iin}`);
  // return Promise.resolve({
  //   data: {
  //     iin: '931212350075',
  //     email: 'alibaevweb@gmail.com',
  //     mobile: '+7(747)2391026',
  //     ogdCode: '3917',
  //     userServiceRoleType: 'OGD',
  //     active: true,
  //     blocked: false,
  //     mobileUser: false,
  //     blockingType: 'MANUAL',
  //     incorrectPasswordCounter: 0,
  //     ipAddress: '10.2.1.181',
  //     passwordExpirationDate: '12.06.2021',
  //     name: 'Асылбеков Асылхан Серикович',
  //     ogdName: 'УГД по г.Костанай',
  //     checkpointCode: '1',
  //     adminPermissions: [
  //       'TRANSIT_REGISTRY_EDIT',
  //       'TOBACCO_OVD_VIEW',
  //       'BIG_COMPANY_VIEW',
  //       'TOBACCO_PIN_VIEW',
  //       'TAXPAYER_MARK_VIEW',
  //       'STATISTIC_REPORT',
  //       'REREGISTRATION_VIEW',
  //       'FNO_VIEW',
  //       'TAXPAYER_SEARCH',
  //       'ECM_CARRIER_VIEW',
  //       'BANNED_GOOD_VIEW',
  //       'CERTIFICATE_VIEW',
  //       'PERMISSION_VIEW',
  //       'CHECK_ACTIVE_REGISTRATION',
  //       'VIEW_VIRTUAL_LABEL_REMAINDERS',
  //       'PLANNED_CHECKPOINT_VIEW',
  //       'ECONTRACT_VIEW',
  //       'SNT_EAES_VIEW',
  //       'UFORM_VIEW',
  //       'CALENDAR_VIEW',
  //       'INVOICE_VIEW',
  //       'NDS_CUSTOM_MANIFEST_PAYMENTS_VIEW',
  //       'REGISTERED_USER_REPORT',
  //       'ALCOHOL_LICENSE_VIEW',
  //       'AGREEMENT_ACTIONS',
  //       'OIL_PIN_VIEW',
  //       'OIL_OVD_VIEW',
  //       'VIRTUAL_LABEL_VIEW',
  //       'OGD_VIEW',
  //       'TAXPAYER_BLOCK_VIEW',
  //       'INVOICE_ACTIONS',
  //       'TAXPAYER_STORE_VIEW',
  //       'GTD_VIEW',
  //       'DOWNTIME_VIEW',
  //       'MEASURE_UNIT_VIEW',
  //       'SNT_OGD_ACTIONS',
  //       'SHARING_PARTICIPANT_VIEW',
  //       'AWP_VIEW',
  //       'ALCOHOL_SPIRIT_PERCENT_VIEW',
  //       'BANK_VIEW',
  //       'SNT_VIEW',
  //       'PERMISSIVE_DOCUMENT_VIEW',
  //       'EXCISE_RATE_VIEW',
  //       'ALCOHOL_PIN_VIEW',
  //       'REORGANIZATION_VIEW',
  //       'ECM_AUTO_VIEW',
  //       'TRANSIT_REGISTRY_VIEW',
  //       'SNT_BORDER_CROSSING_REPORT',
  //       'CURRENCY_RATE_VIEW',
  //       'GSVS_VIEW',
  //     ],
  //     adminPermissionInfos: [
  //       { permission: 'ECONTRACT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'SNT_EAES_VIEW', status: 'CONFIRMED' },
  //       { permission: 'UFORM_VIEW', status: 'CONFIRMED' },
  //       { permission: 'CALENDAR_VIEW', status: 'CONFIRMED' },
  //       { permission: 'EXCISE_RATE_VIEW', status: 'CONFIRMED' },
  //       {
  //         permission: 'NDS_CUSTOM_MANIFEST_PAYMENTS_VIEW',
  //         status: 'CONFIRMED',
  //       },
  //       { permission: 'REGISTERED_USER_REPORT', status: 'CONFIRMED' },
  //       { permission: 'INVOICE_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TRANSIT_REGISTRY_VIEW', status: 'CONFIRMED' },
  //       { permission: 'REORGANIZATION_VIEW', status: 'CONFIRMED' },
  //       { permission: 'OIL_PIN_VIEW', status: 'CONFIRMED' },
  //       { permission: 'SNT_BORDER_CROSSING_REPORT', status: 'CONFIRMED' },
  //       { permission: 'OIL_OVD_VIEW', status: 'CONFIRMED' },
  //       { permission: 'VIRTUAL_LABEL_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TRANSIT_REGISTRY_EDIT', status: 'CONFIRMED' },
  //       { permission: 'TOBACCO_OVD_VIEW', status: 'CONFIRMED' },
  //       { permission: 'GTD_VIEW', status: 'CONFIRMED' },
  //       { permission: 'DOWNTIME_VIEW', status: 'CONFIRMED' },
  //       { permission: 'MEASURE_UNIT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TAXPAYER_STORE_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TOBACCO_PIN_VIEW', status: 'CONFIRMED' },
  //       { permission: 'AWP_VIEW', status: 'CONFIRMED' },
  //       { permission: 'SHARING_PARTICIPANT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'FNO_VIEW', status: 'CONFIRMED' },
  //       { permission: 'ALCOHOL_SPIRIT_PERCENT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'PERMISSION_VIEW', status: 'CONFIRMED' },
  //       { permission: 'PLANNED_CHECKPOINT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'PERMISSIVE_DOCUMENT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'ALCOHOL_LICENSE_VIEW', status: 'CONFIRMED' },
  //       { permission: 'ALCOHOL_PIN_VIEW', status: 'CONFIRMED' },
  //       { permission: 'ECM_AUTO_VIEW', status: 'CONFIRMED' },
  //       { permission: 'CURRENCY_RATE_VIEW', status: 'CONFIRMED' },
  //       { permission: 'AGREEMENT_ACTIONS', status: 'CONFIRMED' },
  //       { permission: 'OGD_VIEW', status: 'CONFIRMED' },
  //       { permission: 'GSVS_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TAXPAYER_BLOCK_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TAXPAYER_MARK_VIEW', status: 'CONFIRMED' },
  //       { permission: 'INVOICE_ACTIONS', status: 'CONFIRMED' },
  //       { permission: 'BIG_COMPANY_VIEW', status: 'CONFIRMED' },
  //       { permission: 'SNT_OGD_ACTIONS', status: 'CONFIRMED' },
  //       { permission: 'STATISTIC_REPORT', status: 'CONFIRMED' },
  //       { permission: 'REREGISTRATION_VIEW', status: 'CONFIRMED' },
  //       { permission: 'TAXPAYER_SEARCH', status: 'CONFIRMED' },
  //       { permission: 'ECM_CARRIER_VIEW', status: 'CONFIRMED' },
  //       { permission: 'BANNED_GOOD_VIEW', status: 'CONFIRMED' },
  //       { permission: 'CERTIFICATE_VIEW', status: 'CONFIRMED' },
  //       { permission: 'SNT_VIEW', status: 'CONFIRMED' },
  //       { permission: 'BANK_VIEW', status: 'CONFIRMED' },
  //       { permission: 'VIEW_VIRTUAL_LABEL_REMAINDERS', status: 'CONFIRMED' },
  //       { permission: 'CHECK_ACTIVE_REGISTRATION', status: 'CONFIRMED' },
  //     ],
  //   },
  // });
};
