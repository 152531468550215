import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './LetterTabs.module.scss';
import { NavLink } from 'react-router-dom';
import { Badge } from 'ui/common/elements/badge/Badge';

const TAB_HEIGHT = 42;

export type LetterTabsItem = {
  name: string;
  label: string;
  title?: string;
  /**
   * href для ссылки
   */
  to?: string;
  /**
   * число для бэджа с количеством ошибок, для навигации в бланках
   */
  count?: number;
};

const letter = (tab: LetterTabsItem) => <span className={s.letter}>{tab.label}</span>;

type Props = {
  className?: string;
  tabs: Array<LetterTabsItem>;
  activeTabName?: string;
  onChange: (name: string) => void;
};

export const LetterTabs: React.FC<Props> = ({ className, tabs, activeTabName, onChange }) => {
  const activeTabIndex = useMemo(() => tabs.findIndex((tab) => tab.name === activeTabName), [activeTabName, tabs]);

  return (
    <ul className={clsx(className, s.wrapper)}>
      {tabs.map((tab) => {
        return (
          <li
            key={tab.name}
            className={clsx(s.tab, {
              [s.active]: tab.name === activeTabName,
            })}
            style={{ height: `${TAB_HEIGHT}px` }}
          >
            {tab.to ? (
              <NavLink to={tab.to} className={s.link}>
                {letter(tab)}
              </NavLink>
            ) : (
              <button
                className={s.button}
                onClick={() => onChange(tab.name)}
                type="button"
                title={tab.title || tab.label}
              >
                {letter(tab)}
                {!!tab.count && <Badge className={s.badge} number={tab.count} />}
              </button>
            )}
          </li>
        );
      })}
      <div className={s.decoration} style={{ top: `${TAB_HEIGHT / 2 + activeTabIndex * TAB_HEIGHT}px` }}></div>
    </ul>
  );
};
