import { DataQueryFunction, httpClient } from 'rest';

export const queryEaecDocumentOutputId: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  const res = await httpClient.get(`/admin/eec/traceability/document/output/docId/${id}`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
