import React, { useCallback } from 'react';
import { queryIsgoSystemsActiveList } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps, UITheme } from 'ui/common';

async function getIsgoSystems(): Promise<any[]> {
  const res = await queryIsgoSystemsActiveList();
  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export const getIsgoSystemOptionValue = (data: any) => {
  return data?.value ?? data?.code;
};

const getOptionLabel = (data: any, locale: LocaleStore) => {
  const { chooseByKey } = locale;
  return data?.label ?? chooseByKey(data, 'name');
};

type Props = FormSelectProps & {
  theme?: UITheme;
};

export const FormIsgoSystemSelect: React.FC<Props> = ({ theme, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const fetchOptions = useCallback(async () => {
    const systems = await getIsgoSystems();
    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...systems,
    ];
  }, [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={fetchOptions}
      isSearchable={true}
      getOptionLabel={(opt) => getOptionLabel(opt, locale)}
      getOptionValue={getIsgoSystemOptionValue}
      noOptionsMessage={noOptionsMessage}
      theme={theme}
    />
  );
};
