/**
 * Предобработка ошибок перед обновлением в форме.
 * Например для смены имен полей (tin на _taxpayer у получателя), для корректного отображения ошибок
 */
export function preSetErrors(errors: any) {
  // console.log('preSetErrors errors', errors);
  const newErrors = { ...errors };
  if (Array.isArray(errors.customers)) {
    newErrors.customers = errors.customers.map((customer: any) => {
      const newCustomer =
        typeof customer === 'object' && customer !== null
          ? {
              ...customer,
            }
          : customer;
      if (newCustomer.tin) {
        newCustomer['_taxpayer'] = customer.tin;
        delete newCustomer.tin;
      }
      return newCustomer;
    });
  }

  // console.log('preSetErrors newErrors', newErrors);
  return newErrors;
}
