import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './DictionaryNode.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon';

type Props = {
  className?: string;
  childNode?: boolean;
  data: any;
  getHeader: (data: any) => React.ReactNode | string;
  getChildren: (data: any) => Promise<Array<any>>;
  isFinal: (data: any) => boolean;
};

export const DictionaryNode: React.FC<Props> = ({ className, childNode, data, getHeader, getChildren, isFinal }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [childrenData, setChildrenData] = useState<Array<any> | null>(null);

  const final = isFinal(data);

  const onClick = () => {
    if (!final) {
      setExpanded((state) => !state);
    }
  };

  useEffect(() => {
    if (expanded) {
      getChildren(data).then((arr) => {
        setChildrenData(arr);
      });
    }
  }, [expanded, data, getChildren]);

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.expanded]: expanded,
        [s.childNode]: childNode,
        [s.final]: final,
      })}
    >
      <div className={s.header} onClick={onClick}>
        {!final && <SvgIcon name="chevron" className={s.chevron} />}
        {getHeader(data)}
      </div>
      <div className={clsx(s.child, { [s.expanded]: expanded })}>
        {expanded &&
          !final &&
          Array.isArray(childrenData) &&
          childrenData.map((item) => (
            <DictionaryNode
              key={item.code}
              childNode={true}
              data={item}
              getHeader={getHeader}
              getChildren={getChildren}
              isFinal={isFinal}
            />
          ))}
      </div>
    </div>
  );
};
