import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './SendAdminMessageForm.module.scss';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import {
  Button,
  FileInput,
  FormCheckbox,
  FormField,
  FormInput,
  FormMultiselect,
  FormSelect,
  FormTextarea,
  Tag,
} from 'ui/common';
import { useLocale } from 'services/l10n';
import { getGroupsOptions, getThemeOptions } from './constants';
import { clearPrivateProps, formatFileSize } from 'common/utils';
import { readListFromTextFile } from 'common/helpers/read-files';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { useDialog } from 'services/modal';
import { sanitize } from 'dompurify';

const initialValues = {
  recipients: [],
};

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
};

export const SendAdminMessageForm: React.FC<Props> = ({ className, onSubmit }) => {
  const locale = useLocale();
  const { loc } = locale;
  const dialog = useDialog();

  const schema = useMemo(
    () =>
      yup.object({
        text: yup.string().required(loc('common.error.requiredField')),
        textKz: yup.string().required(loc('common.error.requiredField')),
      }),
    [loc]
  );

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    console.log(values);

    if (Array.isArray(values.groups) && values.groups.length) {
      let confirmationHtml = '';
      if (values.groups.length === 3) {
        confirmationHtml = loc('adminMessages.confirmation', loc('adminMessages.conf.ALL'));
      } else {
        confirmationHtml = loc(
          'adminMessages.confirmation',
          values.groups.map((g: string) => loc(`adminMessages.conf.${g}`)).join(', ')
        );
      }

      const confirmed = await dialog.show({
        content: <span dangerouslySetInnerHTML={{ __html: sanitize(confirmationHtml) }} />,
        width: 520,
        center: true,
      });
      if (!confirmed) {
        actions.setSubmitting(false);
        return;
      }
    }

    try {
      await onSubmit(clearPrivateProps(values));
      ToastService.showSuccess(loc('adminMessages.createSuccess'));
    } catch (error) {
      console.error('form error', error);
      ToastService.showError(solveErrorMessage(error));
      actions.setSubmitting(false);
    }
  };

  const groupsOptions = useMemo(() => getGroupsOptions({ locale }), [locale]);
  const themeOptions = useMemo(() => getThemeOptions({ locale }), [locale]);

  const updateRecipientsFromFile = async ({
    file,
    formProps: { values, setFieldValue },
  }: {
    file?: File;
    formProps: FormikProps<any>;
  }) => {
    console.log(file);
    const fileMaxSize = 100 * 1024;
    if (file?.type !== 'text/plain') {
      ToastService.showError(loc('adminMessages.error.fileTxt'));
      return;
    }
    if (file && file.size <= fileMaxSize) {
      const list = await readListFromTextFile(file);
      if (list.every((i) => /^\d+?/.test(i))) {
        setFieldValue('recipients', [...(values['recipients'] || []), ...list]);
      } else {
        ToastService.showError(loc('adminMessages.error.fileTxt'));
        return;
      }
    } else {
      ToastService.showError(loc('adminMessages.error.fileSize', formatFileSize(fileMaxSize)));
      return;
    }
  };

  return (
    <Formik<any> initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
      {(formProps) => {
        const { values, isSubmitting } = formProps;
        return (
          <Form className={clsx(className, s.wrapper)}>
            <FieldArray name="recipients">
              {({ remove, push }) => (
                <div className={s.groupPanel}>
                  <div className={s.groups}>
                    <FormField name="groups" label={loc('adminMessages.group')}>
                      <FormMultiselect
                        options={groupsOptions}
                        allOption
                        allLabel={loc('adminMessages.ALL')}
                        checkOptionsChange
                        updateValueOnOptionsChange
                        variant="compact"
                      />
                    </FormField>
                  </div>
                  <div className={s.topRow}>
                    <FormField name="_iinInput" label={loc('common.labels.iin')} className={s.iinInput}>
                      <FormInput />
                    </FormField>
                    <Button
                      className={s.addButton}
                      type="button"
                      onClick={() => {
                        const value = values['_iinInput'];
                        if (value && !values.recipients.includes(value)) {
                          push(value);
                        }
                      }}
                    >
                      {loc('common.button.add')}
                    </Button>
                    <FileInput
                      label={loc('common.button.uploadFromFileTxt')}
                      accept=".txt"
                      fullWidth={false}
                      className={s.fileInput}
                      onChange={(ev) =>
                        updateRecipientsFromFile({
                          file: ev?.target?.files?.[0],
                          formProps,
                        })
                      }
                    />
                  </div>
                  <div className={s.bottomRow}>
                    <Fragment>
                      {Array.isArray(values.recipients) &&
                        values.recipients.map((iin: string, index: number) => (
                          <Tag className={s.iinTag} key={`${iin}_${index}`} onClose={() => remove(index)}>
                            {iin}
                          </Tag>
                        ))}
                    </Fragment>
                  </div>
                </div>
              )}
            </FieldArray>
            <div className={s.content}>
              <FormField name="confirm" label={loc('adminMessages.confirm')} direction="line-reverse">
                <FormCheckbox />
              </FormField>
              <FormField name="messageTheme" label={loc('adminMessages.messageTheme')} className="mt-16">
                <FormSelect options={themeOptions} className={s.themeSelect} />
              </FormField>
              <FormField name="textKz" label={loc('adminMessages.textKz')}>
                <FormTextarea rows={6} />
              </FormField>
              <FormField name="text" label={loc('adminMessages.textRu')}>
                <FormTextarea rows={6} />
              </FormField>

              <Button
                type="submit"
                className="mt-8"
                disabled={!(values.recipients?.length || values.groups?.length)}
                loading={isSubmitting}
              >
                {loc('common.button.send')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
