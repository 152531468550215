import React from 'react';
import clsx from 'clsx';
import t1 from './ModalHeader.module.scss';
import t2 from './ModalT2Header.module.scss';
import { UITheme } from 'ui';

const styles = {
  T1: t1,
  T2: t2,
};

export type ModalHeaderProps = {
  className?: string;
  theme?: UITheme;
};

export const ModalHeader: React.FC<ModalHeaderProps> = ({ className, children, theme = 'T1' }) => {
  const s = styles[theme];
  return <div className={clsx(className, s.wrapper)}>{children}</div>;
};
