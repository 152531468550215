import { LocaleStore } from 'services/l10n';
import { Calculation, EsfPaperReason } from '../constants';
import { ESF_FILTER_IN_WORK_STATUSES, ESF_FILTER_STATUSES, EsfType } from 'features/esf';
import { getNotSelectedOptions } from 'common/helpers';

export const getEsfTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: EsfType.ORDINARY_INVOICE,
    label: loc(`c.esf.type.ORDINARY_INVOICE`),
  },
  {
    value: EsfType.FIXED_INVOICE,
    label: loc(`c.esf.type.FIXED_INVOICE`),
  },
  {
    value: EsfType.ADDITIONAL_INVOICE,
    label: loc(`c.esf.type.ADDITIONAL_INVOICE`),
  },
];

export const getEsfStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return ESF_FILTER_STATUSES.map((st) => ({
    value: st,
    label: loc(`common.esf.status.${st}`),
  }));
};

export const getEsfStatusInWorkOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return ESF_FILTER_IN_WORK_STATUSES.map((st) => ({
    value: st,
    label: loc(`common.esf.status.${st}`),
  }));
};

export const getCalcDirectionOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: Calculation.DIRECT,
    label: loc('esf.calc.direct'),
  },
  {
    value: Calculation.REVERSE,
    label: loc('esf.calc.reverse'),
  },
];

export const getCalcTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: Calculation.AUTO,
    label: loc('esf.calc.auto'),
  },
  {
    value: Calculation.MANUAL,
    label: loc('esf.calc.manual'),
  },
];

const sections = ['A', 'B', 'C', 'C1', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

export const getEsfCanReasonFixOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...sections.map((section) => ({
    value: `${loc('esf.canreason.fix')} ${section}`,
    label: `${loc('esf.canreason.fix')} ${section}`,
  })),
  {
    value: `${loc('esf.other')}`,
    label: `${loc('esf.other')}`,
  },
];

export const getEsfCanReasonErrorOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...sections.map((section) => ({
    value: `${loc('esf.canreason.error')} ${section}`,
    label: `${loc('esf.canreason.error')} ${section}`,
  })),
  {
    value: `${loc('esf.other')}`,
    label: `${loc('esf.other')}`,
  },
];

export const getTruOriginCodeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
];

export const getNdsRateOptions = (
  { loc }: LocaleStore,
  isNdsNotRk: boolean,
  withEmpty = true
): Array<{ value: any; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc(isNdsNotRk ? 'common.labels.noNds.notRk' : 'common.labels.noNds'),
        },
      ]
    : []),
  { value: '12', label: '12%' },
  { value: '8', label: '8%' }, // # COVID-19 акция
  { value: '0', label: '0%' },
  { value: '13', label: '13%' },
  { value: '14', label: '14%' },
  { value: '15', label: '15%' },
  { value: '16', label: '16%' },
  { value: '20', label: '20%' },
  { value: '28', label: '28%' },
];

export const getEsfReasonPaperOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> =>
  Object.values(EsfPaperReason).map((r) => ({
    value: r,
    label: loc(`esf.reasonPaper.${r}`),
  }));

export const getPublicOfficeBikOptions = (locale: LocaleStore): Array<{ value: any; label: string }> => {
  const { loc } = locale;
  return [
    ...getNotSelectedOptions(locale),
    ...['KKMFKZ2A', 'NBRKKZKX'].map((code) => ({
      value: code,
      label: `${code} (${loc(`esf.publicOffice.${code}`)})`,
    })),
  ];
};
