import React from 'react';
import clsx from 'clsx';
import s from '../../InsidePage.module.scss';
import { useLocale } from 'services/l10n';
import { InsideMenu } from '../../InsideMenu';
import { InsideBreadcrumbs } from 'pages/inside-pages/InsideBreadcrumbs';

type Props = {
  className?: string;
};

export const TestStandPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <InsideBreadcrumbs section="forDevelopers" pageCrumbs={[{ label: loc('common.nav.testStand') }]} />
      <div className={s.content}>
        <article className={s.article}>
          <h1>Тестовый стенд ИС ЭСФ</h1>
          <p>
            Уважаемые налогоплательщики! Комитет государственных доходов Министерства финансов Республики Казахстан
            уведомляет Вас о предоставлении тестового стенда Web-приложения «Электронные счета-фактуры» с новым
            механизмом аутентификации в ИС ЭСФ, а также заверения счета-фактуры в электронной форме без использования
            java-апплетов.
          </p>
          <p>
            Для работы на тестовом стенде необходимо зарегистрироваться на тестовом стенде ИС ЭСФ (тестовый стенд) с
            использованием собственных действующих сертификатов НУЦ (НУЦ РК).
          </p>
          <h2>Тестовый стенд Web-приложения «Электронные счета-фактуры» с новым механизмом аутентификации</h2>
          <p>
            Уважаемые пользователи информационной системы "Электронные счет-фактуры"! Комитет государственных доходов на
            бесплатной основе предоставляет тестовый стенд для работы с функционалом по сопроводительной накладной на
            товары (СНТ) в модуле "Виртуальный склад" информационной системы "Электронные счет-фактуры", запуск которой
            на продуктивной среде будет осуществлен с 1 июля 2020 года.
          </p>
          <p>Тестовый стенд доступен по ссылкам:</p>
          <h3>Для WEB приложения:</h3>
          <p>
            <a href="https://test3.esf.kgd.gov.kz:8443/esf-web/">https://test3.esf.kgd.gov.kz:8443/esf-web/</a>
            <br />
            <a href="http://test3.esf.kgd.gov.kz:8080/esf-web/">http://test3.esf.kgd.gov.kz:8080/esf-web/</a>
          </p>
          <h3>Для API сервисов:</h3>
          <p>
            <a href="https://test3.esf.kgd.gov.kz:8443/esf-web/">https://test3.esf.kgd.gov.kz:8443/esf-web/</a>
            <br />
            <a href="http://test3.esf.kgd.gov.kz:8080/esf-web/">http://test3.esf.kgd.gov.kz:8080/esf-web/</a>
          </p>
        </article>
        <InsideMenu menuName="forDevelopers" className={s.insideMenu} />
      </div>
    </div>
  );
};
