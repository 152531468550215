import { createCachedQuery } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryDictSubsidyListActive: DataQueryFunction = createCachedQuery(async () => {
  const res = await httpClient.get(`/dict/subsidy/list/active`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
});
