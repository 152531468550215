import { Journal } from 'common/components/journal';
import React, { useMemo } from 'react';
import { queryExternalUsers, queryExternalUsersExport, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { getUserAccessColumns } from './getUserAccessColumns';
import { UserAccessFilterContent } from './UserAccessFilterContent';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { preproccessUACriteria } from './helpers';

const initialFilter = {};
const initialPagination = { rows: 10 };
const pageType = 'external';

type Props = {};

/**
 * АРМ -> Мониторинг ИС ЭСФ -> Журнал действий внешних пользователей ИС ЭСФ
 * /arm/external-users
 */
export const ExternalUsersPage: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const columns = useMemo(
    () => getUserAccessColumns({ locale }),
    [locale] // eslint-disable-line
  );

  const queryProps = useDataQuery(
    queryExternalUsers,
    {
      filter: initialFilter,
      pagination: initialPagination,
    },
    {
      preProcessParams: (variables) => ({
        ...variables,
        filter: preproccessUACriteria(variables.filter),
      }),
    }
  );

  const editedTins = queryProps.data?.editedTins;
  const incompleteResult = queryProps.data?.incompleteResult;

  const exportJournal = async (format: string) => {
    try {
      const res = await queryExternalUsersExport({
        filter: preproccessUACriteria(queryProps.filter),
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons = [
    {
      id: 1,
      label: 'Скачать в XLSX',
      onClick: () => exportJournal('XLSX'),
    },
    {
      id: 2,
      label: 'Скачать в PDF',
      onClick: () => exportJournal('PDF'),
    },
    {
      id: 3,
      label: 'Скачать в CSV',
      onClick: () => exportJournal('CSV'),
    },
  ];

  return (
    <Journal
      pageTitle={loc('userAccess.external.pageTitle')}
      filterComponent={UserAccessFilterContent}
      filterComponentProps={{ pageType, editedTins, incompleteResult }}
      queryProps={queryProps}
      headerButtons={headerButtons}
      columns={columns}
    />
  );
};
