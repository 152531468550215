import React, { FC, useEffect } from 'react';
import s from '../../TaxpayerMainPage.module.scss';
import { LocaleStore } from 'services/l10n';
import { ButtonT2, TableColumn } from 'ui';
import { BoardButton, formatPrice } from '../../helpers';
import { getIn } from 'formik';
import { PERMISSION } from 'common/constants';
import { useStore } from 'common/hooks';
import { queryInvoiceSearch, useDataQuery } from 'rest';
import { formatFromDate } from 'services/date-time';
import { AppLink, Board } from 'common/components';
import { EsfBoardFilter } from './EsfBoardFilter';
import {
  ESF_FILTER_IN_WORK_STATUSES,
  ESF_FILTER_STATUSES,
  ESF_STATUS_CLASSES,
  EsfDirection,
  EsfMode,
  EsfStatus,
  downloadEsfPdf,
  navigateToEsfPage,
  solveEsfDirection,
} from 'features/esf';
import { subMonths } from 'date-fns';
import { BoardComponentProps } from '../boardTypes';
import { UserStore } from 'common/stores/user-store';
import { useHistory } from 'react-router-dom';

const getRowId = (row: any) => row?.stringInvoiceId;

const initialPagination = { rows: 5 };
const initialFilter = {
  direction: 'INBOUND',
  dateFrom: formatFromDate(subMonths(new Date(), 3)),
  dateTo: formatFromDate(new Date()),
  order: 'DESC',
  sort: 'invoice.date',
};

export const EsfBoard: FC<BoardComponentProps> = ({ className, boardParams, opened, onLoad }) => {
  const { locale, user } = useStore();
  const { loc, lang } = locale;

  const history = useHistory();

  const queryProps = useDataQuery(
    queryInvoiceSearch,
    {
      filter: initialFilter,
      pagination: initialPagination,
      tin: user.currentUser?.currentUserTin,
    },
    {
      skipInitialRequest: true,
      preProcessParams: (variables) => {
        console.log({ variables });
        const { filter, tin } = variables || {};
        let newFilter: any = {};
        if (filter.regNumber) {
          newFilter['tins[]'] = tin;
        } else {
          const { direction, ...restFilter } = filter || {};
          newFilter = {
            ...restFilter,
            'tins[]': tin,
            direction: direction === 'IN_WORK' ? 'OUTBOUND' : direction,
          };
          if (direction === 'IN_WORK') {
            newFilter['statuses[]'] = ESF_FILTER_IN_WORK_STATUSES;
          }
          if (direction === 'OUTBOUND') {
            newFilter['statuses[]'] = ESF_FILTER_STATUSES;
          }
        }
        console.log({ filter, newFilter });
        return { ...variables, filter: newFilter, regNumber: filter.regNumber };
      },
    }
  );

  const columns = getEsfJournalColumns({ locale, user });

  const menuButtons: Array<BoardButton> = [
    {
      id: 1,
      label: loc('common.button.open'),
      onClick: async (row) => {
        navigateToEsfPage({ mode: EsfMode.show, selection: [row], history });
      },
      disabled: (row) => {
        const enabled =
          user.checkPermission(PERMISSION.INVOICE_VIEW) &&
          ![EsfStatus.DELETED, EsfStatus.DRAFT].includes(row.invoiceStatus);
        return !enabled;
      },
    },
    {
      id: 24,
      label: loc('common.button.pdf'),
      onClick: async (row) => {
        return downloadEsfPdf({
          ids: [getRowId(row)],
          isDraft: row?.invoiceStatus === EsfStatus.DRAFT,
          lang,
        });
      },
      disabled: (row) => {
        const enabled = user.checkPermission(PERMISSION.INVOICE_VIEW) && row.invoiceStatus !== EsfStatus.DELETED;
        return !enabled;
      },
    },
  ];

  useEffect(() => {
    if (opened) {
      queryProps.refetch();
    }
  }, [opened]); // eslint-disable-line

  useEffect(() => {
    if (queryProps.data) {
      onLoad();
    }
  }, [queryProps.data]); // eslint-disable-line

  return (
    <Board
      columns={columns}
      className={className}
      getRowId={getRowId}
      menuButtons={menuButtons}
      queryProps={queryProps}
      getRows={(data) => data?.invoiceInfoList}
      filterComponent={EsfBoardFilter}
      renderLinkButton={() => (
        <AppLink to={boardParams.link}>
          <ButtonT2 variant="outlined" className={s.toJournalButton}>
            {loc('common.button.toJournal')}
          </ButtonT2>
        </AppLink>
      )}
    />
  );
};

export const getEsfJournalColumns = ({
  locale: { loc },
  user,
}: {
  locale: LocaleStore;
  user: UserStore;
}): Array<TableColumn> => [
  {
    name: 'invoice.num',
    label: loc('esf.invoiceNum'),
    alias: 'NUM',
  },
  {
    name: 'invoiceStatus',
    label: loc('common.esf.status'),
    getContent: (row) => {
      const status: EsfStatus = row.invoiceStatus || EsfStatus.DRAFT;
      return <span className={ESF_STATUS_CLASSES[status] || ''}>{loc(`common.esf.status.${status}`)}</span>;
    },
    getTitle: (row) => {
      return row.invoiceStatus ? loc(`common.esf.status.${row.invoiceStatus}`) : loc(`common.esf.status.DRAFT`);
    },
  },
  {
    name: 'contragent',
    label: loc('common.labels.recipientName'),
    getContent: (row) =>
      solveEsfDirection(row, user) === EsfDirection.INBOUND
        ? getIn(row, 'invoice.sellers[0].name')
        : getIn(row, 'invoice.customers[0].name'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('esf.registrationNumber'),
    alias: 'REGISTRATION_NUMBER',
    getContent: (row) => row.registrationNumber || loc('common.noNumber'),
    width: 300,
  },
  {
    name: 'invoice.productSet.totalPriceWithTax',
    label: loc('esf.totalPriceWithTax'),
    getContent: (...args) => formatPrice(args[2]),
    showTotal: true,
  },

  {
    name: 'invoice.date',
    label: loc('esf.invoiceDate'),
  },
];
