import { AppMode } from 'common/constants';
import { AppModeContext } from 'common/contexts';
import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { LocalesLoader } from 'services/l10n';
import { LayoutName } from '../layouts';
import { LAYOUT_MAP } from '../layoutsMap';
import 'react-toastify/dist/ReactToastify.css';
import { ToastService } from 'services/toasts/ToastService';
import { Dialog } from 'common/components/modals/dialog';
import { DialogContainer } from 'services/modal/dialog/DialogContainer';
import { StorageSelectModal } from 'services/nca-layer/storage-select/StorageSelectModal';
import { PushNotifications } from 'services/push-notifications';
import { AuthModals, ProfileSelectModal } from 'common/components';
import { Modal } from 'services/modal';
import { UserChecksModals } from 'features/user-checks-modals';

type Props = RouteComponentProps & {
  children: React.ReactNode;
  layoutName: LayoutName;
};

export const LayoutContainer: React.FC<Props> = observer(({ children, layoutName, ...routeProps }) => {
  const { locale, user, userChecks } = useStore();
  const { lang } = locale;
  const { currentUser, isUserAuthed, profileSelectModal, setProfileSelectModal } = user;

  const layout = LAYOUT_MAP[layoutName];
  const LayoutComponent = layout.component;

  useEffect(() => {
    console.log('LayoutContainer MOUNTED!!');
  }, []);

  const appMode: AppMode = layout.appMode || AppMode.GUEST;

  const key = `${currentUser?.currentUserTin}_${currentUser?.currentProfileId}_${lang}`;

  // console.log('layoutKey', key);

  return (
    <AppModeContext.Provider value={appMode}>
      <LocalesLoader key={key + layoutName} localeNames={layout.locales} withDefault>
        <LayoutComponent key={key} {...(layout.props || {})} {...routeProps}>
          {children}
        </LayoutComponent>
        <ToastContainer {...ToastService.config} />
        <DialogContainer component={Dialog} />
        <StorageSelectModal />
        {isUserAuthed && <PushNotifications key={`notifications_${key}`} />}
        <AuthModals />
        {appMode === AppMode.TAXPAYER && (
          <Modal isOpen={!!profileSelectModal} theme="T2" marginTop={150} scrollLock={false}>
            <ProfileSelectModal
              onSelect={(profileId) => {
                if (profileId === user.currentUser?.currentProfileId) {
                  userChecks.afterLoginChecks();
                }
                setProfileSelectModal(false);
              }}
            />
          </Modal>
        )}
        <UserChecksModals />
      </LocalesLoader>
    </AppModeContext.Provider>
  );
});
