import { LocaleNames } from 'services/l10n/helpers/constants';
import { RootRouteConfig, RouteConfig, RouteData } from '../types';
import { LayoutName } from 'routing/layouts';

const flattenRoutes = (
  routes: Array<RouteConfig>,
  defaultLayout: LayoutName,
  prefix: string,
  defaultLocales: Array<LocaleNames>
): Array<RouteData> =>
  routes
    .filter((route) => !route.exclude)
    .flatMap((route) =>
      route.children
        ? flattenRoutes(route.children, route.layout || defaultLayout, prefix + route.path, [
            ...defaultLocales,
            ...(route.locales || []),
          ])
        : {
            path: prefix + route.path || '',
            component: route.component || null,
            layout: route.layout || defaultLayout,
            exact: !!route.exact,
            redirect: route.redirect ?? null,
            update: route.update || 'none',
            locales: Array.from(new Set([...defaultLocales, ...(route.locales || [])])),
          }
    );

export const generateRoutes = (routesConfigs: RootRouteConfig[]): Array<RouteData> =>
  routesConfigs.reduce((acc: Array<RouteData>, config) => {
    return [...acc, ...flattenRoutes(config.routes, config.layout, '', config.locales || [])];
  }, []);
