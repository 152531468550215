import { AppMode } from 'common/constants';
import { AppModeContext } from 'common/contexts';
import { useContext } from 'react';
import { UITheme } from 'ui';

/**
 * Возвращает тему по умолчанию для текущего режима приложения.
 * В Арме, Еаес, Гость используется тема - T1
 * В "Кабинете налогоплательщика" - T2
 */
export const useGlobalTheme = (): UITheme => {
  const mode = useContext(AppModeContext);
  return [AppMode.TAXPAYER, AppMode.GUEST].includes(mode) ? 'T2' : 'T1';
};
