import React from 'react';
import clsx from 'clsx';
import s from './PageSizeSelect.module.scss';
import { rowsOptionsArm, rowsOptionsTaxpayer } from './rowsOptions';
import { Select } from 'ui/common/controls/select/Select';
import { UITheme } from 'ui/common/types';

type Props = {
  className?: string;
  pageSize: number;
  onPageSizeChange?: (value: number) => void;
  rowsOptions?: Array<{
    value: number;
    label: string;
  }>;
  theme?: UITheme;
};

export const PageSizeSelect: React.FC<Props> = ({
  className,
  pageSize,
  onPageSizeChange,
  theme = 'T1',
  rowsOptions,
}) => {
  const onSelectChange = (value: any) => {
    if (typeof onPageSizeChange === 'function') {
      onPageSizeChange(value);
    }
  };

  return (
    <Select
      className={clsx(s.wrapper, className, s[theme])}
      size="small"
      fullWidth={false}
      options={rowsOptions || (theme === 'T1' ? rowsOptionsArm : rowsOptionsTaxpayer)}
      value={pageSize}
      onChange={onSelectChange}
      short
      theme={theme}
    />
  );
};
