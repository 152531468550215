import React from 'react';
import { useLocale } from 'services/l10n';
import { DatePickerProps, FormValueProps } from 'ui/common';
import { DoubleDateWrapperT2 } from 'ui/theme2/controls/date-picker/DoubleDateWrapperT2';
import { FormLocaleDatePickerT2 } from './FormLocaleDatePickerT2';
import { FormFieldT2 } from 'ui';

type Props = Omit<DatePickerProps, 'onChange' | 'onBlur' | 'value'> & {
  nameFrom: string;
  nameTo: string;
  onValueChange?: (props: FormValueProps) => void;
};

/**
 * Двойной датапикер (дата с/по) с подключением к форме и локализацией.
 * В форме можно использовать с элементом Field (дополнительно подключать к форме, через FormField не нужно).
 * Использует общие пропсы для каждого пикера, если будет нужно, можно разделить пропсы.
 */
export const FormDoubleDatePickerT2: React.FC<Props> = ({
  className,
  nameFrom,
  nameTo,
  error,
  onValueChange,
  ...rest
}) => {
  const { loc } = useLocale();
  return (
    <DoubleDateWrapperT2
      className={className}
      error={error}
      renderLeft={(pickerClassName, pickerLabelClassName) => (
        <FormFieldT2
          className={pickerClassName}
          labelClassName={pickerLabelClassName}
          direction="line"
          name={nameFrom}
          label={loc('common.labels.from')}
          noMargin
          innerFullWidth
        >
          <FormLocaleDatePickerT2 onValueChange={onValueChange} {...rest} />
        </FormFieldT2>
      )}
      renderRight={(pickerClassName, pickerLabelClassName) => (
        <FormFieldT2
          className={pickerClassName}
          labelClassName={pickerLabelClassName}
          direction="line"
          name={nameTo}
          label={loc('common.labels.to')}
          noMargin
          innerFullWidth
        >
          <FormLocaleDatePickerT2 onValueChange={onValueChange} {...rest} />
        </FormFieldT2>
      )}
    />
  );
};
