import React from 'react';
import clsx from 'clsx';
import s from './Badge.module.scss';

type Props = {
  className?: string;
  number?: number;
};

export const Badge: React.FC<Props> = ({ className, number }) => {
  return <div className={clsx(className, s.wrapper)}>{number}</div>;
};
