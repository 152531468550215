import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/chevron-left.svg';
import { ReactComponent as ChevronUp } from 'assets/icons/chevron-up.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as Detail } from 'assets/icons/detail.svg';
import { ReactComponent as Diamond } from 'assets/icons/diamond.svg';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as EditFilled } from 'assets/icons/edit-filled.svg';
import { ReactComponent as History } from 'assets/icons/history.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as CloseBold } from 'assets/icons/close-bold.svg';
import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as DeleteOutline } from 'assets/icons/delete-outline.svg';
import { ReactComponent as CaretDown } from 'assets/icons/caret-down.svg';
import { ReactComponent as CaretUp } from 'assets/icons/caret-up.svg';
import { ReactComponent as Search } from 'assets/icons/search.svg';

export const svgIconMap: { [key: string]: React.FC } = {
  chevron: Chevron,
  detail: Detail,
  diamond: Diamond,
  edit: Edit,
  'edit-filled': EditFilled,
  history: History,
  close: Close,
  'close-bold': CloseBold,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'chevron-down': ChevronDown,
  delete: Delete,
  'delete-outline': DeleteOutline,
  'caret-down': CaretDown,
  'caret-up': CaretUp,
  search: Search,
};
