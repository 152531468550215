import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormDataDeep } from 'rest/helpers';
import { clearEmptyFieldsDeep, clearPrivateProps } from 'common/utils';

export const mutationRegistrationValidate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { data } = variables;
  const formData = convertToFormDataDeep({ regForm: JSON.stringify(clearEmptyFieldsDeep(clearPrivateProps(data))) });

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1500);
  //   throw {
  //     success: false,
  //     errors: [
  //       { property: 'registrationTypes', text: 'Данный ИИН уже зарегистрирован как ФЛ' },
  //       { property: 'currentAddress', text: 'Данный ИИН уже зарегистрирован как ИП' },
  //       { property: 'individualName', text: 'Ошибка ИИН' },
  //       // { property: 'fio', text: 'Ошибка Пароля' },
  //     ],
  //   };
  // }

  const res = await httpClient.post('/registration/validate', formData);
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
