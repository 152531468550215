import { API_URL, AppMode, DocDirection } from 'common/constants';
import { getLegasyUrl } from 'common/helpers';
import { UserStore } from 'common/stores/user-store';
import { downloadFile } from 'rest';

/**
 * Определяет направление документа АВР (Входящий, Исходящий)
 */
export const solveAwpDirection = (awpRow: any, user: UserStore): DocDirection.INBOUND | DocDirection.OUTBOUND => {
  if (awpRow.senderTin === user.currentUser?.currentUserTin) {
    return DocDirection.OUTBOUND;
  }
  return DocDirection.INBOUND;
};

/**
 * Перенавравоение на страницу бланка АВР (в старом интерфейсе)
 */
export const navigateToAwpPage = (mode: string, awpId: string) => {
  window.location.assign(getLegasyUrl(`/awp/${mode}/${awpId}`));
};

export const downloadAwpPdf = async (ids: Array<string>, appMode?: AppMode) => {
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url =
      appMode === AppMode.ARM ? `${API_URL}/admin/awp/printReport/${id}/PDF` : `${API_URL}/awp/printReport/${id}/PDF`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};
