import { FilterContentProps } from 'common/components';
import { FormCountrySelect, FormMeasureUnitSelect, FormTaxpayerStoreSelect } from 'common/components/controls';
import { AppMode } from 'common/constants';
import React, { Fragment, useEffect, useMemo } from 'react';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { getVirtualLabelTypeOptions } from '../constants';

// const getDocTypeOptions = ({ loc }: LocaleStore) => [
//   {
//     value: '',
//     label: loc('common.option.notSelected'),
//   },

//   ...['DOCUMENT_GTD', 'DOCUMENT_FNO', 'PRIMARY_SNT', 'MANUFACTURE', 'BALANCE', 'BALANCE_CORRECTION', 'DETAILING'].map(
//     (type) => ({
//       value: type,
//       label: loc(`remainder.type.${type}`),
//     })
//   ),
// ];

const getDutyTypes = ({ loc }: LocaleStore) => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: 'CCT',
    label: 'ЕТТ',
  },
  {
    value: 'WTO',
    label: 'ВТО',
  },
  {
    value: 'EAEU',
    label: 'ЕАЭС',
  },
  {
    value: 'NOT_INSTALLED',
    label: 'Не установлен',
  },
];

type Props = FilterContentProps;

export const RemainderJournalArmFilters: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, setFieldValue } = form;

  // const docTypeOptions = useMemo(() => getDocTypeOptions(locale), [locale]);

  const vmOptions = useMemo(() => getVirtualLabelTypeOptions(locale), [locale]);

  const dutyTypes = useMemo(() => getDutyTypes(locale), [locale]);

  useEffect(() => {
    setFieldValue('storeId', '');
  }, [values.tin, setFieldValue, locale]);

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('common.labels.tin')}>
          <FormInput />
        </FormField>
        <FormField name="storeId" label={loc('common.labels.tpStore')} fast={false}>
          <FormTaxpayerStoreSelect appMode={AppMode.ARM} tin={values.tin} />
        </FormField>
        {/* <FormField name="type" label={loc('common.labels.docNumberType')}>
          <FormSelect options={docTypeOptions} />
        </FormField> */}
        <FormField name="virtualLabelType" label={loc('common.labels.virtualLabelType')}>
          <FormSelect options={vmOptions} />
        </FormField>
        <FormField name="dutyType" label={loc('common.labels.dutyType')}>
          <FormSelect options={dutyTypes} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="docNumber" label={loc('common.labels.docNumber')}>
          <FormInput />
        </FormField>
        <FormField name="productDocNumber" label={loc('common.labels.productDocNumber')}>
          <FormInput />
        </FormField>
        <FormField name="regNumber" label={loc('common.labels.movementDoc')}>
          <FormInput />
        </FormField>
        <FormField name="productRegNumber" label={loc('common.labels.numberInMovementDoc')}>
          <FormInput />
        </FormField>
        <FormField name="manufactureOrImportDocNumber" label={loc('common.labels.manufactureOrImportDocNumber')}>
          <FormInput />
        </FormField>
        <FormField name="productNumberInImportDoc" label={loc('common.labels.productNumberInImportDoc')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="name" label={loc('common.labels.productName')}>
          <FormInput />
        </FormField>
        <FormField name="productNameInImportDoc" label={loc('common.labels.productNameInImportDoc')}>
          <FormInput />
        </FormField>
        <FormField name="productId" label={loc('common.labels.productId')}>
          <FormInput />
        </FormField>
        <FormField name="physicalLabel" label={loc('common.labels.physicalLabel')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="gsvsCode" label={loc('common.labels.gsvsCode')}>
          <FormInput />
        </FormField>
        <FormField name="tnvedCode" label={loc('common.labels.tnvedCode')}>
          <FormInput />
        </FormField>
        <FormField name="gtin" label={loc('common.labels.gtin')}>
          <FormInput />
        </FormField>
        <FormField name="countryCode" label={loc('common.labels.prodCountry')}>
          <FormCountrySelect />
        </FormField>
        <FormField name="measureUnitCode" label={loc('common.labels.measureUnitName')}>
          <FormMeasureUnitSelect />
        </FormField>
      </div>
    </Fragment>
  );
};
