import React, { ChangeEvent } from 'react';
import s from './TableT2.module.scss';
import { Checkbox } from 'ui/common/controls';
import { TableColumn } from 'ui/common/components/table/types';
import clsx from 'clsx';
import { getCellContent } from 'ui/common/components/table/helpers';

export type TableRowPropsT2 = {
  row: any;
  rowIndex: number;
  columns: Array<TableColumn>;
  getRowId: (row: any, index: number) => string | number;
  getRowStyle: (row: any) => { [key: string]: string } | undefined;
  onSelect: (row: any, index: number, selected: boolean, selectOne?: boolean) => void;
  selectable: boolean;
  selected?: boolean;
};

export const TableRowT2 = React.forwardRef<any, TableRowPropsT2>(
  ({ row, columns, getRowStyle, onSelect, selectable, selected = false, rowIndex }, ref) => {
    const onRowClick = () => {
      if (!selectable) return;
      onSelect(row, rowIndex, true, true);
    };

    const selectHandle = (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      onSelect(row, rowIndex, checked);
    };

    return (
      <tr
        className={clsx({ [s.selectedRow]: selected })}
        style={getRowStyle(row)}
        onClick={() => onRowClick()}
        ref={ref}
      >
        {selectable && (
          <td className={s.checkCell}>
            <Checkbox className={s.checkBox} checked={selected} onChange={selectHandle} theme="T2" />
          </td>
        )}
        {columns.map((col) => {
          const content = getCellContent(row, rowIndex, col);
          return (
            <td
              key={col.name}
              style={col.style ? { ...col.style, width: undefined } : undefined}
              title={
                col.getTitle
                  ? col.getTitle(row)
                  : typeof content === 'string' || typeof content === 'number'
                  ? String(content)
                  : undefined
              }
            >
              {content}
            </td>
          );
        })}
      </tr>
    );
  }
);
