import { DocDirection } from 'common/constants';
import { UserStore } from 'common/stores/user-store';

/**
 * Определяет направление документа СНТ (Входящий, Исходящий)
 */
export const solveSntDirection = (sntRow: any, user: UserStore): DocDirection.INBOUND | DocDirection.OUTBOUND => {
  if (sntRow.creatorTin === user.currentUser?.currentUserTin && sntRow.creatorType !== 'RECIPIENT') {
    return DocDirection.OUTBOUND;
  }
  return DocDirection.INBOUND;
};
