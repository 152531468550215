import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './ChangeConfirmationDateModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormTextarea } from 'ui/common';
import { FormLocaleDatePicker } from 'common/components';
import * as yup from 'yup';
import { mutChangeConfirmDeadlineDate } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { formatToDate } from 'services/date-time';
import { useStore } from 'common/hooks';

type Props = ModalChildrenProps & {
  className?: string;
  selection?: Array<any>;
  getRowId: (row: any) => string;
};

export const ChangeConfirmationDateModal: React.FC<Props> = ({ className, onClose, getRowId, selection }) => {
  const { auth, locale } = useStore();
  const { loc } = locale;

  const schema = yup.object({
    date: yup.string().required(loc('common.error.requiredField')),
    changeReason: yup.string().required(loc('common.error.requiredField')),
  });

  const initialValues = useMemo(() => ({ date: selection && selection[0]?.confirmDeadlineDate }), [selection]);

  const minDate = formatToDate(selection && selection[0]?.confirmDeadlineDate);

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    console.log(values);
    const sntIdList = selection ? selection.map((row) => getRowId(row)) : [];
    const hash = JSON.stringify({ sntIdList, ...values });
    const signData = await auth.getSignature(hash);
    try {
      const res = await mutChangeConfirmDeadlineDate({
        body: {
          sntChangeDeadlineRequestString: JSON.stringify({
            sntIdList,
            ...values,
            hash,
            certificate: signData.cert?.base64Pem || '',
            signature: signData.sign || '',
          }),
        },
      });
      typeof onClose === 'function' && onClose();
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('sntTerms.changeConfirmationDate.title')}</ModalHeader>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
        {() => {
          return (
            <Form className={clsx(className, s.wrapper)}>
              <ModalContent className={s.content}>
                <FormField name="date" label={loc('sntTerms.termDate')}>
                  <FormLocaleDatePicker className={s.datepicker} minDate={minDate} />
                </FormField>
                <FormField name="changeReason" label={loc('sntTerms.changeReason')}>
                  <FormTextarea className={s.textarea} />
                </FormField>
              </ModalContent>
              <ModalFooter>
                <Button type="button" variant="secondary" size="small" onClick={onClose}>
                  {loc('common.button.cancel')}
                </Button>
                <Button type="submit" size="small">
                  {loc('common.button.save')}
                </Button>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
