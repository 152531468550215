import React from 'react';
import clsx from 'clsx';
import s from './Tabs.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon/SvgIcon';
import { Tab } from './Tab';

type Props<T extends Tab> = {
  className?: string;
  tabClassName?: string;
  tabs?: Readonly<Array<T>>;
  activeTabName?: string;
  onTabsChange?: (tab: T) => void;
};

export const Tabs = <T extends Tab>({ className, tabClassName, tabs, activeTabName, onTabsChange }: Props<T>) => {
  return Array.isArray(tabs) ? (
    <div className={clsx(className, s.wrapper)}>
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={clsx(s.tab, tabClassName, {
            [s.activeTab]: activeTabName === tab.name,
          })}
          style={tab.width ? { width: tab.width } : {}}
          onClick={() => typeof onTabsChange === 'function' && onTabsChange(tab)}
        >
          {!!tab.icon && <SvgIcon className={s.tabIcon} component={tab.icon} />}
          {tab.label}
        </div>
      ))}
    </div>
  ) : null;
};
