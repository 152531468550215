import { FormikProps, getIn } from 'formik';

/**
 * Обновление поля формы, только в случае если новое значение не равно старому (deep, JSON.stringify ===)
 */
export function setFieldValueDiffer(
  field: string,
  value: any,
  form: FormikProps<any>,
  shouldValidate?: boolean | undefined
) {
  const { values, setFieldValue } = form;
  if (JSON.stringify(prepValue(getIn(values, field))) !== JSON.stringify(prepValue(value))) {
    console.log('setValueFieldIfDiffer', { field, value });
    setFieldValue(field, value, shouldValidate);
  }
}

function prepValue(value: any) {
  if (Array.isArray(value)) {
    return [...value].sort();
  }
  return value;
}
