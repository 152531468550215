import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getQoldayRegistryJournalColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'bin',
    label: loc('common.labels.iin'),
  },
  {
    name: 'taxpayerName',
    label: loc('common.labels.npName'),
    width: 400,
  },
  {
    name: 'createDate',
    label: loc('qolday.createdDate'),
  },
  {
    name: 'closeDate',
    label: loc('qolday.closeDate'),
  },
  {
    name: 'status',
    label: loc('common.labels.status'),
    getContent: (row) => !!row.status && loc(`qolday.status.${row.status}`),
  },
];
