export const USER_SERVICE_ROLE = {
  /**
   * «Супер пользователь»
   *
   * Ведение журнала пользователей АРМ ССПП и назначение прав (только для пользователей с ролью «Администратор ЦА»)
   */
  SUPER_ADMIN: 'SUPER_ADMIN',

  /**
   * «Администратор ЦА»
   *
   * Ведение журнала пользователей АРМ ССПП и назначение прав (только для ролей «Администратор ОГД», «Сотрудник ОГД»,
   * «Специалист службы поддержки»)
   */
  ADMIN: 'ADMIN',

  /**
   * «Администратор ОГД»
   *
   * Работает в пределах своего ОГД.
   * Ведение журнала пользователей АРМ ССПП и назначение прав (только для ролей «Сотрудник ОГД»)
   */
  OGD_ADMIN: 'OGD_ADMIN',

  /**
   * «Специалист службы поддержки»
   *
   * Работает в пределах своего ОГД.
   */
  SUPPORT: 'SUPPORT',

  /**
   * «Сотрудник ОГД»
   *
   * Работает в пределах своего ОГД.
   */
  OGD: 'OGD',

  /**
   * «Сотрудник ИБ» (ИБ - информационная безпасность)
   */
  IS_USER: 'IS_USER',

  /**
   * «Сотрудник ЦА» (ЦА - центральный аппарат)
   */
  CA_USER: 'CA_USER',

  /**
   * «Администратор НСИ» (НСИ - нормативно-справочная информация)
   */
  NSI_ADMIN: 'NSI_ADMIN',
};
