import React, { ChangeEvent, SyntheticEvent } from 'react';
import clsx from 'clsx';
import t1 from './Checkbox.module.scss';
import t2 from './CheckboxT2.module.scss';
import { UITheme } from 'ui/common/types';

const styles = {
  T1: t1,
  T2: t2,
};

export type CheckboxProps = {
  className?: string;
  inputClassName?: string;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  theme?: UITheme;
};

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  inputClassName,
  readOnly,
  disabled,
  onChange,
  error,
  theme = 'T1',
  ...rest
}) => {
  const s = styles[theme];
  const changeHandler = readOnly
    ? (e: SyntheticEvent) => {
        e.preventDefault();
        return false;
      }
    : onChange;

  return (
    <span
      className={clsx(className, s.wrapper, s[theme], {
        [s.readOnly]: readOnly || disabled,
        [s.error]: error,
      })}
    >
      <input
        type="checkbox"
        className={clsx(s.input, inputClassName)}
        onChange={changeHandler}
        readOnly
        disabled={disabled}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
        {...rest}
      />
      <span className={clsx(s.inner)}></span>
    </span>
  );
};
