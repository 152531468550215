import React, { FC } from 'react';
import t1 from './Button.module.scss';
import t2 from './ButtonT2.module.scss';
import clsx from 'clsx';
import { SvgIcon } from 'ui/common/elements/svg-icon';
import { UITheme } from 'ui/common/types';
import { Loader } from 'ui/common/elements/loader/Loader';

const styles = {
  T1: t1,
  T2: t2,
};

export type ButtonProps = {
  className?: string;
  style?: any;
  disabled?: boolean;
  fullWidth?: boolean;
  type?: 'submit' | 'button';
  variant?: 'primary' | 'secondary' | 'tertiary' | 'outlined' | 'transparent' | 'primary-upper' | 'secondary-upper';
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  fontSize?: 'default' | 'small';
  iconSide?: 'right' | 'left';
  iconName?: string;
  iconComponent?: React.FC;
  iconClassName?: string;
  iconStyle?: { [key: string]: any };
  onClick?: (e: React.SyntheticEvent) => void;
  loading?: boolean;
  theme?: UITheme;
  title?: string;
};

export const Button: FC<ButtonProps> = ({
  children,
  className,
  style,
  disabled = false,
  fullWidth = false,
  type = 'button',
  variant = 'primary',
  size = 'default',
  fontSize = 'default',
  onClick = () => {},
  iconName,
  iconSide = 'left',
  iconComponent,
  iconClassName,
  iconStyle,
  loading,
  theme = 'T1',
  title,
}) => {
  const s = styles[theme];

  const hasIcon = !!(iconComponent || iconName);

  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(s.wrapper, className, s[variant], s[size], s[theme], {
        [s.fullWidth]: fullWidth,
        [s.fzSmall]: fontSize === 'small',
        [s.iconLeft]: hasIcon && iconSide === 'left',
        [s.iconRight]: hasIcon && iconSide === 'right',
        [s.loading]: loading,
      })}
      style={style}
      disabled={disabled || loading}
      title={title}
    >
      {hasIcon && (
        <SvgIcon name={iconName} component={iconComponent} className={clsx(s.icon, iconClassName)} style={iconStyle} />
      )}
      {children}
      {loading && <Loader className={s.loaderOverlay} loaderClassName={s.loader} />}
    </button>
  );
};
