import { FormikProps, getIn } from 'formik';

/**
 * Добавляет значение value в массив field.
 * Если значение value уже есть в массиве, то не делает ничего.
 * Если значения value нет в массиве, или нет массива в поле field,
 * то создает новый массив с добавленным значением value, и обновляет им поле field
 */
export function addValueToArrayField(
  field: string,
  value: any,
  form: FormikProps<any>,
  shouldValidate?: boolean | undefined
) {
  const { values, setFieldValue } = form;
  const arrayField = getIn(values, field);
  if (Array.isArray(arrayField)) {
    if (!arrayField.includes(value)) {
      setFieldValue(field, [...arrayField, value], shouldValidate);
    }
  } else {
    setFieldValue(field, [value], shouldValidate);
  }
}

/**
 * Удаляет значение value из массива field.
 * Если значения value нет в массиве или нет массива в поле field, то не делает ничего.
 * Если значение value есть массиве, то создает новый массив с удаленным значением value, и обновляет им поле field
 */
export function removeValueFromArrayField(
  field: string,
  value: any,
  form: FormikProps<any>,
  shouldValidate?: boolean | undefined
) {
  const { values, setFieldValue } = form;
  const arrayField = getIn(values, field);
  if (Array.isArray(arrayField)) {
    if (arrayField.includes(value)) {
      setFieldValue(
        field,
        arrayField.filter((item) => item !== value),
        shouldValidate
      );
    }
  }
}
