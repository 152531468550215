import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Journal, JournalButton } from 'common/components';
import { getNotBioTaxpayersJournalColumns } from './getNotBioTaxpayersJournalColumns';
import { NotBioTaxpayersJournalFilters } from './NotBioTaxpayersJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { rowsOptionsVariant3 } from 'ui';
import { Modal } from 'services/modal';
import { NotBioTaxpayerModal } from '../not-bio-taxpayer/NotBioTaxpayerModal';
import { queryAdminNotBiometricsReport, queryAdminNotBiometricsSearch, mutationAdminNotBiometricsDelete } from '../api';
import { formatFromDate } from 'services/date-time';
import { useStore } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.iin;

const filterStorageKey = 'NotBioTaxpaers';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, Справочники -> "НП для регистрации без биометрии"
 * arm/not-bio-taxpayers
 */
export const NotBioTaxpayersJournal: React.FC<Props> = ({ className }) => {
  const { locale, dialog, user } = useStore();
  const { loc } = locale;

  const [taxpayerModal, setTaxpayerModal] = useState<{
    show: boolean;
  }>({
    show: false,
  });

  const columns = useMemo(() => getNotBioTaxpayersJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminNotBiometricsSearch, {
    filter: SessionStorageService.get(filterStorageKey) || {
      createDateFrom: formatFromDate(new Date()),
      createDateTo: formatFromDate(new Date()),
    },
    pagination: initialPagination,
  });

  const exportJournal = async (format: string, selection: Array<any>) => {
    const { iin, ...rest } = queryProps.filter;
    try {
      const res = await queryAdminNotBiometricsReport({
        filter: { ...rest, iinList: selection.length ? selection.map((s) => getRowId(s)) : iin ? [iin] : undefined },
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  const deleteTaxpayer = async (selection: Array<any>) => {
    const confirmed = await dialog.show({ content: loc('notBio.confirmDelete'), center: true });
    if (confirmed) {
      try {
        const res = await mutationAdminNotBiometricsDelete({ iin: getRowId(selection[0]) });
        queryProps.refetch();
        return res;
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
        throw error;
      }
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.add'),
      onClick: () => setTaxpayerModal({ show: true }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT),
    },
    {
      id: 2,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal('XLSX', selection),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 2,
      label: loc('common.button.delete'),
      onClick: (selected) => deleteTaxpayer(selected),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT),
    },
  ];

  const onCloseModal = (needRefetch?: boolean) => {
    setTaxpayerModal({ show: false });
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('notBio.title')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={NotBioTaxpayersJournalFilters}
        filterInitialOpened
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        paginationRowsOptions={rowsOptionsVariant3}
      />

      <Modal isOpen={taxpayerModal.show} onClose={onCloseModal} center>
        <NotBioTaxpayerModal />
      </Modal>
    </Fragment>
  );
};
