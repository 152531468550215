export const BASE_URL = process.env.PUBLIC_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const LEGACY_URL = process.env.REACT_APP_LEGACY_URL;

console.log({ BASE_URL, API_URL, LEGACY_URL });

export const LOGIN_METHOD: 'CERTIFICATE' | 'SIGN_XML' = 'CERTIFICATE';

export const LEGACY_REGISTRATION_URL = `${LEGACY_URL}/login/#!/registration`;

export const LANG_COOKIE_NAME = 'language';

/**
 * Для разработки в оффлайн режиме, когда недоступны тестовые сервера. Пользователь подменяется на заглушку
 */
export const OFFLINE_DEV = false;
