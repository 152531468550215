import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminRiskyTaxpayerImportFromFile: DataMutationFunction = async (): Promise<any> => {
  const res = await httpClient.post('/admin/riskyTaxpayer/importFromFile');
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
