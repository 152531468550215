import React from 'react';
import clsx from 'clsx';
import s from './SendAdminMessagePage.module.scss';
import { useLocale } from 'services/l10n';
import { useCheckAdminPermission } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';
import { PageTitle } from 'ui/common';
import { SendAdminMessageForm } from './SendAdminMessageForm';
import { mutationAdminMessagesCreate } from './api';

type Props = {
  className?: string;
};

/**
 * Страница "Рассылка сообщений"
 * /arm/send-message
 */
export const SendAdminMessagePage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  useCheckAdminPermission(ADMIN_PERMISSION.MESSAGES_SEND);

  const onSubmit = async (values: any) => {
    console.log(values);
    return mutationAdminMessagesCreate({ body: values });
  };

  return (
    <div className={clsx(className, s.wrapper, 'container')}>
      <PageTitle>{loc('adminMessages.sendMessages')}</PageTitle>
      <SendAdminMessageForm onSubmit={onSubmit} />
    </div>
  );
};
