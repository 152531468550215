import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';
import { DialogProps } from 'services/modal/dialog/types';
import { Fragment } from 'react';
import React from 'react';

export class NCALayerStore {
  rootStore: RootStore;
  webSocket: WebSocket;
  selectStorageModal: { show: boolean; types: Array<string> } = {
    show: false,
    types: [],
  };
  selectedStorage: string = 'PKCS12';
  selectStorageResolve: (value: string | PromiseLike<string>) => void;

  hasValidationErrors: boolean = false;
  nclModules = {
    esfSigner: 'com.osdkz.esf.signer.esfSigner',
    commonUtils: 'kz.gov.pki.knca.commonUtils',
    basics: 'kz.gov.pki.knca.basics',
  };

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      selectStorageModal: observable,
      selectedStorage: observable,
      setSelectedStorage: action,
      setSelectStorageModal: action,
    });
    this.rootStore = rootStore;
    this.init().then((message) => console.log(message));
  }

  showMessage(props: DialogProps) {
    const dialog = this.rootStore.dialog;
    console.info(props);
    dialog.showAlert(props);
  }

  storageSelect(types: Array<string>): Promise<string> {
    return new Promise((resolve, reject) => {
      this.selectStorageResolve = resolve;
      this.setSelectStorageModal({ show: true, types });
      return undefined;
    });
  }

  init(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.webSocket = new WebSocket('wss://127.0.0.1:13579/');
      this.webSocket.onopen = (e) => {
        // console.log('ws onopen', e);
        this.validateEsfModule()
          .then((message) => resolve(message))
          .catch((err) => reject(err));
      };
      this.webSocket.onerror = (e) => {
        const { loc } = this.rootStore.locale;

        console.log('ws onerror', e);
        this.showMessage({
          title: loc('common.title.attention'),
          content: (
            <Fragment>
              <span>{loc('common.error.layerNotRanned')}:</span>{' '}
              <a href="https://pki.gov.kz/ncalayer/">https://pki.gov.kz/ncalayer/</a>
            </Fragment>
          ),
          center: true,
          style: { whiteSpace: 'pre', width: 650 },
        });
        reject(e);
      };
    });
  }

  getStorageType(): Promise<string> {
    return new Promise((resolve, reject) => {
      const obj = {
        module: this.nclModules.commonUtils,
        method: 'getActiveTokens',
      };
      this.sendMessage(JSON.stringify(obj)).then((res) => {
        if (res.code === '200') {
          const storageTypes = res.responseObject;
          // const types = [
          //   'AKKaztokenStore',
          //   'AKKZIDCardStore',
          //   'AKEToken72KStore',
          //   'AKEToken5110Store',
          //   'AKJaCartaStore',
          //   'JKS',
          //   'AKAKEYStore',
          // ];
          if (Array.isArray(storageTypes) && storageTypes.length) {
            storageTypes.push('PKCS12');
            this.storageSelect(storageTypes).then((type) => resolve(type));
          } else {
            resolve('PKCS12');
          }
        }
      });
    });
  }

  sendMessage(jsonMessage: any): Promise<any> {
    return new Promise((resolve) => {
      this.checkConnection().then(() => {
        this.webSocket.send(jsonMessage);
        this.webSocket.onmessage = (e) => {
          if (e?.data) {
            const res = JSON.parse(e.data);
            resolve(res);
          }
        };
      });
    });
  }

  checkConnection(): Promise<any> {
    if (this.webSocket.readyState === WebSocket.OPEN && !this.hasValidationErrors) {
      return Promise.resolve(true);
    } else {
      return this.init();
    }
  }

  validateEsfModule(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.webSocket.send(
        JSON.stringify({
          module: 'kz.gov.pki.ncalayerservices.accessory',
          method: 'getBundles',
        })
      );
      this.webSocket.onmessage = (event) => {
        const { loc } = this.rootStore.locale;
        if (event && event.data) {
          const res = JSON.parse(event.data);
          // console.log('ws res', res);
          if (res.result && res.result.version) {
            resolve(`Соединение с NCALayer v.${res.result.version} установлено`);
          } else if (res['kz.gov.pki.api.layer.NCALayerServices']) {
            // по наличию модуля сервисов понимаем что вернулся ответ со списком модулей
            if (res['com.osdkz.esf.signer']) {
              if (res['com.osdkz.esf.signer'] === '1.1.0' || res['com.osdkz.esf.signer'] === '1.2.0') {
                this.hasValidationErrors = false;
                resolve('ошибок нет');
              } else {
                this.hasValidationErrors = true;
                this.showMessage({
                  title: loc('common.title.attention'),
                  content: loc('common.error.layerWrongVersion'),
                  center: true,
                  style: { whiteSpace: 'pre-wrap', width: 650 },
                });
                // this.showMessage({ content: 'layer_wrong_version' });
                reject();
              }
            } else {
              this.showMessage({
                title: loc('common.title.attention'),
                content: loc('common.error.absentModule'),
                center: true,
                style: { whiteSpace: 'pre-wrap', width: 650 },
              });
              this.hasValidationErrors = true;
              reject();
            }
          }
        }
      };
    });
  }

  certificateValidate(cert: any): any {
    // # Проверка, что юзер пользуется своим сертификатом
    if (cert.serialNumber !== '350EC915F562E0A99D6FE69B1181D7CD543BF928') {
      const user = this.rootStore.user;
      const { loc } = this.rootStore.locale;
      if (user.currentUser?.login) {
        const sdn = this.getSubjectDN(cert.subjectDn);
        if (user.currentUser?.login !== sdn.iin) {
          this.showMessage({
            title: loc('common.title.attention'),
            content: loc('common.error.certificateNotOwn'),
            center: true,
            style: { whiteSpace: 'pre-wrap', width: 650 },
          });
        }
        return false;
      }
    }
    return true;
  }

  getCertificate(authCert = false): Promise<{ [key: string]: string }> {
    return new Promise((resolve, reject) => {
      this.getStorageType().then((storageType) => {
        console.log('type', storageType);
        const obj = {
          module: this.nclModules.esfSigner,
          method: authCert ? 'auth' : 'signPlainData',
          data: 'get_cert',
          storageName: storageType,
        };
        this.webSocket.send(JSON.stringify(obj));
        this.webSocket.onmessage = (event) => {
          if (event?.data) {
            const res = JSON.parse(event.data);
            if (res.code === '200') {
              const cert = res.responseObject?.keyInfo;
              // is_cert_valid = that.certificateValidate(cert, auth_cert) //todo проверка сертификата
              // if is_cert_valid
              //   cb(cert) if _.isFunction(cb)
              // console.log(cert);
              resolve(cert);
            } else {
              reject();
            }
          }
        };
      });
    });
  }

  getAuthXmlDsigDeprecated(xml: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getStorageType().then((storageType) => {
        const obj = {
          module: this.nclModules.commonUtils,
          method: 'signXml',
          args: [storageType, 'AUTHENTICATION', xml, '', ''],
        };
        this.webSocket.send(JSON.stringify(obj));
        this.webSocket.onmessage = (event) => {
          if (event && event.data) {
            const res = JSON.parse(event.data);
            if (res.code === '200') {
              resolve(res.responseObject);
            } else {
              reject(res);
            }
          }
        };
      });
    });
  }

  getAuthXmlDsig(xml: string) {
    return new Promise((resolve, reject) => {
      this.getStorageType().then((storageType) => {
        const { lang } = this.rootStore.locale;
        const obj = {
          module: this.nclModules.basics,
          method: 'sign',
          args: {
            allowedStorages: [storageType],
            format: 'xml',
            data: xml,
            signingParams: {
              decode: 'false',
              encapsulate: 'true',
              digested: 'false',
              tsaProfile: null,
            },
            signerParams: {
              extKeyUsageOids: ['1.3.6.1.5.5.7.3.2'],
              chain: [],
              locale: lang,
            },
          },
        };
        this.webSocket.send(JSON.stringify(obj));
        this.webSocket.onmessage = (event) => {
          if (event?.data) {
            const res = JSON.parse(event.data);
            if (res?.status && res?.body?.result) {
              resolve(res.body.result[0]);
            }
          }
        };
      });
    });
  }

  getSubjectDN(str: string): {
    iin: string;
    bin: string;
    email: string;
    name: string;
    title: string;
  } {
    const obj: { [key: string]: string } = {};

    str.split(',').forEach((val: string) => {
      const tmp = val.split('=');
      obj[tmp[0].trim()] = tmp[1].trim();
    });

    return {
      iin: obj['SERIALNUMBER'] ? obj['SERIALNUMBER'].replace('IIN', '') : '',
      bin: obj['OU'] ? obj['OU'].replace('BIN', '') : '',
      email: obj['E'] ? obj['E'].toLowerCase() : obj['EMAILADDRESS'] ? obj['EMAILADDRESS'].toLowerCase() : '',
      name: obj['CN']
        ? obj['CN'].replace(/[\wа-яЁА-ЯЁ]\S*/g, (t) => t.charAt(0).toUpperCase() + t.substr(1).toLowerCase())
        : '',
      title: obj['O'] ? obj['O'].replace(/"/g, '').replace(/\\/g, "'") : '',
    };
  }

  /**
   * data - строка или массив типа [{id: <id>, hash: <hash>}, ...]
   * */
  getSignature(data: any | Array<any>): Promise<{ cert: any; sign: string | any }> {
    // console.log('data', data);
    return new Promise((resolve, reject) => {
      this.getStorageType().then((storageType) => {
        const options = { storageType };
        if (Array.isArray(data)) {
          this.signArrayData(data, options)
            .then((res) => {
              const { keyInfo, ...resRest } = res;
              resolve({ cert: keyInfo, sign: resRest });
            })
            .catch((res) => reject(res));
        } else {
          this.signPlainData(data, options)
            .then((res) => {
              resolve({ cert: res.keyInfo, sign: res.signature });
            })
            .catch((res) => reject(res));
        }
      });
    });
  }

  signArrayData(data: Array<any>, options: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const dataToSign: { [key: string]: any } = {};
      data.forEach((item) => (dataToSign[item.id] = item.hash));
      const obj = {
        module: this.nclModules.esfSigner,
        method: 'signPlainDataMap',
        data: dataToSign,
        storageName: options.storageType || 'PKCS12',
      };
      this.sendMessage(JSON.stringify(obj)).then((res) => {
        if (res.code === '200') resolve(res.responseObject);
        if (res.code === '500') reject(res);
      });
    });
  }

  signPlainData(data: any, options: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      const obj = {
        module: this.nclModules.esfSigner,
        method: 'signPlainData',
        data: data,
        storageName: options.storageType || 'PKCS12',
      };
      this.sendMessage(JSON.stringify(obj)).then((res) => {
        if (res.code === '200') resolve(res.responseObject);
        if (res.code === '500') reject(res);
      });
    });
  }

  setSelectedStorage(type: string) {
    this.selectedStorage = type;
    if (typeof this.selectStorageResolve === 'function') {
      this.selectStorageResolve(this.selectedStorage);
    }
  }

  setSelectStorageModal(props: { show: boolean; types: Array<string> }) {
    this.selectStorageModal = props;
  }
}
