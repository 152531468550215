/**
 * режим в котором открыт бланк (новый, просмотр, редактирование)
 * определяет намерение пользователя, какую операцию делает пользователь
 * это значение определяет путь бланка /esf/{mode}/id
 */
export enum EsfMode {
  show = 'show',
  new = 'new',
  newPaper = 'new-paper',
  draft = 'draft',
  copy = 'copy',
  copyDraft = 'copy-draft',
  fixed = 'fixed',
  additional = 'additional',
  imported = 'imported',
  fromSnt = 'from-snt',
}

export enum EsfType {
  ORDINARY_INVOICE = 'ORDINARY_INVOICE',
  FIXED_INVOICE = 'FIXED_INVOICE',
  ADDITIONAL_INVOICE = 'ADDITIONAL_INVOICE',
}

/**
 * Направление документа ЭСФ (входящий, исходящий, в работе)
 */
export enum EsfDirection {
  /**
   * Исходящий
   */
  OUTBOUND = 'OUTBOUND',
  /**
   * Входящий
   */
  INBOUND = 'INBOUND',
  /**
   * В работе
   */
  IN_WORK = 'IN_WORK',
}

export enum EsfStatus {
  /**
   * В очереди
   */
  IN_QUEUE = 'IN_QUEUE',

  /**
   * В обработке
   */
  IN_PROCESSING = 'IN_PROCESSING',

  /**
   * Не просмотрен
   */
  CREATED = 'CREATED',

  /**
   * Доставленный
   */
  DELIVERED = 'DELIVERED',

  /**
   * Аннулированный
   */
  CANCELED = 'CANCELED',

  /**
   * Аннулирован ИС ЭСФ для отнесения в зачет и на вычеты
   */
  CANCELED_BY_OGD = 'CANCELED_BY_OGD',

  /**
   * Аннулирован при отклонении СНТ
   */
  CANCELED_BY_SNT_DECLINE = 'CANCELED_BY_SNT_DECLINE',

  /**
   * Аннулирован при отзыве СНТ
   */
  CANCELED_BY_SNT_REVOKE = 'CANCELED_BY_SNT_REVOKE',

  /**
   * Отозванный
   */
  REVOKED = 'REVOKED',

  /**
   * Импортированный
   */
  IMPORTED = 'IMPORTED',

  /**
   * Черновик
   */
  DRAFT = 'DRAFT',

  /**
   * Ошибочный
   */
  FAILED = 'FAILED',

  /**
   * Удаленный
   */
  DELETED = 'DELETED',

  /**
   * Отклоненный
   */
  DECLINED = 'DECLINED',

  /**
   * Заблокирован ИС ЭСФ
   */
  SEND_TO_ISGO = 'SEND_TO_ISGO',

  /**
   * В ожидании биометрической идентификации
   */
  WAIT_BIOMETRICS_VERIFICATION = 'WAIT_BIOMETRICS_VERIFICATION',

  /**
   * Биометрическая идентификация не пройдена в срок
   */
  FAILED_BIOMETRICS_VERIFICATION = 'FAILED_BIOMETRICS_VERIFICATION',
}

export const ESF_STATUS_CLASSES: { [key in EsfStatus]?: string } = {
  [EsfStatus.CREATED]: 'statusColorYellow',
  [EsfStatus.DELIVERED]: 'statusColorGreen',
  [EsfStatus.CANCELED]: 'statusColorBlack',
  [EsfStatus.CANCELED_BY_OGD]: 'statusColorBlack',
  [EsfStatus.CANCELED_BY_SNT_DECLINE]: 'statusColorBlack',
  [EsfStatus.CANCELED_BY_SNT_REVOKE]: 'statusColorBlack',
  [EsfStatus.REVOKED]: 'statusColorBlack',
  [EsfStatus.IMPORTED]: 'statusColorGray',
  [EsfStatus.DRAFT]: 'statusColorGray',
  [EsfStatus.FAILED]: 'statusColorRed',
  [EsfStatus.DECLINED]: 'statusColorRed',
  [EsfStatus.SEND_TO_ISGO]: 'statusColorBlack',
  [EsfStatus.WAIT_BIOMETRICS_VERIFICATION]: 'statusColorYellow',
  [EsfStatus.FAILED_BIOMETRICS_VERIFICATION]: 'statusColorRed',
};
