/**
 * capitalizes the first letter of a string
 * @param string
 * @param everyWord: boolean - capitalizes the first letter of every word in a string
 */
export function capitalizeFirstLetter(string: string, everyWord?: boolean): string {
  if (typeof string !== 'string') {
    return '';
  }
  if (everyWord) {
    return string.toLowerCase().replace(/(^.|\s.)/g, (m) => m.toUpperCase());
  } else {
    return string.toLowerCase().replace(/(^.)/g, (m) => m.toUpperCase());
  }
}
