import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAssuranceSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = { ...filter };
  if (criteria.statuses) {
    criteria.statuses = [criteria.statuses];
  }
  if (criteria.corridors) {
    criteria.corridors = [criteria.corridors];
  }

  const res = await httpClient.get(`/assurance/search`, {
    params: {
      criteria: JSON.stringify(clearEmptyFields(criteria)),
      ...toPaginationParams(pagination),
    },
  });

  console.log('res data', res.data);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
