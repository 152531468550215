import React from 'react';
import clsx from 'clsx';
import s from './Input.module.scss';
import { SvgIcon } from 'ui/common/elements';
import { InputProps } from './types';

export const Input: React.FC<InputProps> = ({
  className,
  inputClassName,
  error,
  fullWidth = true,
  value,
  readOnly,
  output,
  theme = 'T1',
  icon,
  iconSide = 'left',
  iconClassName,
  onIconClick,
  suffix,
  suffixClassName,
  variant,
  label,
  ...rest
}) => {
  const wrapperClassName = clsx(className, s[theme], {
    [s.error]: error,
    [s.fullWidth]: fullWidth,
  });

  const getInput = (cname?: string) => (
    <input
      {...rest}
      className={clsx(cname, inputClassName, s.input, s[theme], {
        'control-error': error,
        [s.hasValue]: !!value,
        [s.output]: output,
        [s.productTableInput]: variant === 'product-table',
      })}
      value={value}
      readOnly={readOnly || output}
    />
  );

  return icon || suffix ? (
    <span className={clsx(s.wrapper, wrapperClassName, icon ? s[`withIcon-${iconSide}`] : '')}>
      {getInput()}
      {!!icon && (
        <span
          className={clsx(s.iconWrapper, {
            [s.clickable]: typeof onIconClick === 'function',
          })}
          onClick={typeof onIconClick === 'function' ? (e) => onIconClick(e, value, rest.name) : undefined}
        >
          <SvgIcon
            className={clsx(iconClassName, s.icon)}
            {...(typeof icon === 'string' ? { name: icon } : { component: icon })}
          />
        </span>
      )}
      {suffix && <span className={clsx(s.suffix, suffixClassName)}>{suffix}</span>}
    </span>
  ) : (
    getInput(wrapperClassName)
  );
};
