import { convertToFormDataDeep } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryIsgoInvoiceGetWithRelated: DataQueryFunction = async (variables: any) => {
  const { ids } = variables || {};

  const formData = convertToFormDataDeep({ invoiceIds: ids?.join() });

  const res = await httpClient.post(`/isgo/invoice/get/with/related`, formData, {});
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
