import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import s from './BoardRow.module.scss';
import { ButtonIcon, TableColumn, getCellContent, useOutsideClick2 } from 'ui';
import { ReactComponent as MoreSvg } from 'assets/icons/journal-buttons/more.svg';
import { BoardButton } from 'pages/main-taxpayer/helpers';
import { isPromise } from 'formik';

type Props = {
  className?: string;
  row: any;
  rowIndex: number;
  columns: Array<TableColumn>;
  menuButtons: Array<BoardButton>;
  setExporting: React.Dispatch<React.SetStateAction<boolean>>;
};

export const BoardRow: React.FC<Props> = ({ className, columns, row, rowIndex, menuButtons, setExporting }) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const dropdownRef = useRef<any>();
  useOutsideClick2(
    dropdownRef,
    () => {
      if (showMenu) {
        setShowMenu(false);
      }
    },
    showMenu
  );

  const onMenuButtonClick = (btn: BoardButton) => {
    // if (typeof btn.onClick === 'function') {
    //   return btn.onClick(row, rowIndex);
    // }

    if (typeof btn.onClick === 'function') {
      const result = btn.onClick(row, rowIndex);
      if (isPromise(result)) {
        setExporting(true);
        result.finally(() => setExporting(false));
      }
      return result;
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      {columns.map((col) => {
        const content = getCellContent(row, rowIndex, col);
        return (
          <div
            className={s.cell}
            key={col.name}
            title={
              col.getTitle
                ? col.getTitle(row)
                : typeof content === 'string' || typeof content === 'number'
                ? String(content)
                : undefined
            }
          >
            {content}
          </div>
        );
      })}

      <div className={s.actionColumn}>
        <ButtonIcon
          key={'more'}
          className={s.menuButton}
          iconClassName={s.menuButtonIcon}
          iconComponent={MoreSvg}
          onClick={() => setShowMenu((state) => !state)}
        />
        {showMenu && (
          <ul className={clsx(s.moreButtons)} ref={dropdownRef}>
            {menuButtons.map((btn) => (
              <li
                key={btn.id}
                className={s.moreButton}
                onClick={() => {
                  setShowMenu(false);
                  onMenuButtonClick(btn);
                }}
              >
                {btn.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
