import React, { Fragment, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './ZvtPageArm.module.scss';
import { DataGrid, Heading, Loader, PageTitle, Table, TableColumn } from 'ui';
import { LocaleStore, useLocale } from 'services/l10n';
import { BackButton, CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import { useDataQuery } from 'rest';
import { queryAdminZvtId } from '../api';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';

const getProductRowId = (row: any) => row?.id;

type Props = {
  className?: string;
  onClose?: () => void;
  id: string;
};

export const ZvtPageArm: React.FC<Props> = ({ className, onClose, id }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, error } = useDataQuery(queryAdminZvtId, {
    id,
  });

  const productsColumns = useMemo(() => getProductsColumns({ locale }), [locale]);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('zvt.pageTitle')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <div className={s.inner}>
          <DataGrid
            data={[
              [loc('zvt.reciveDate'), data.inputDate],
              [loc('zvt.initiated.title'), data.initiated],
              [loc('zvt.regNumber'), data.registrationNumber],
              [loc('zvt.consigneeTin.title'), data.consigneeTin],
              [loc('zvt.consigneeName'), data.consigneeName],
              [loc('zvt.consigneeAdress'), data.consigneeAddress],
              [loc('zvt.status'), data.astana1Status ? loc(`zvt.status.${data.astana1Status}`) : ''],
              [loc('zvt.customsCode'), data.customsCode],
              [loc('zvt.depCountry'), <CodeValueCountry code={data.departureCountry} />],
              [loc('zvt.destCountry'), <CodeValueCountry code={data.destinationCountry} />],
              [loc('common.labels.currencyCode'), data.currencyCode],
              [loc('common.labels.currencyRate'), data.currencyRate],
              [loc('common.labels.goodsAmount'), data.goodsAmount],
              [loc('zvt.totalCost'), data.totalCost],
            ]}
          />

          {Array.isArray(data.products) && (
            <Fragment>
              <Heading className={s.goodAmount} tag="h3">
                {loc('zvt.products')}
              </Heading>
              <Table columns={productsColumns} rows={data.products} getRowId={getProductRowId} scrollable />
            </Fragment>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const getProductsColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'rowNum',
    label: loc('zvt.p.rowNum'),
    style: { width: 100 },
  },
  {
    name: 'productName',
    label: loc('zvt.p.productName'),
    style: { width: 250 },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: { width: 120 },
  },
  {
    name: 'status',
    label: loc('common.labels.status'),
    getContent: (row) => (row.status ? loc(`zvt.p.status.${row.status}`) : ''),
  },
  {
    name: 'quantity',
    label: loc('zvt.p.quantity'),
    style: { width: 120 },
  },
  {
    name: 'measureUnitCode',
    label: loc('common.labels.measureUnitName'),
    getContent: (row: any) => <CodeValueMeasureUnit code={row.measureUnitCode} />,
  },
  {
    name: 'weight',
    label: loc('zvt.p.weight'),
    style: { width: 120 },
  },
  {
    name: 'price',
    label: loc('zvt.p.price'),
    width: 150,
    style: { width: 120 },
  },
  {
    name: 'totalCost',
    label: loc('zvt.p.totalCost'),
    width: 150,
    style: { width: 120 },
  },
  {
    name: 'originCountry',
    label: loc('zvt.p.originCountry'),
    getContent: (row: any) => <CodeValueCountry code={row.originCountry} />,
  },
  {
    name: 'canExport',
    label: loc('zvt.p.canExport'),
    getContent: (row: any) => (typeof row.canExport === 'boolean' ? loc(`zvt.p.canExport.${row.canExport}`) : ''),
    style: { width: 120 },
  },
];
