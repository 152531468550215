import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecMonitoringJournalColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'messageId',
    label: loc('agreements.requestId'),
    width: 300,
  },
  {
    name: 'requestDocDate',
    label: loc('agreements.requestDocDate'),
  },
  {
    name: 'reportDateFrom',
    label: loc('agreements.reportDateFrom'),
  },
  {
    name: 'reportDateTo',
    label: loc('agreements.reportDateTo'),
  },
  {
    name: 'responseMessageId',
    label: loc('agreements.responseDocId'),
    width: 300,
  },
  {
    name: 'responseDate',
    label: loc('agreements.responseDateTime'),
  },

  {
    name: 'status',
    label: loc('agreements.requestStatus'),
    getContent: (row) => !!row.status && loc(`eaecActions.exStatus.${row.status}`),
  },
];
