import { useState, useEffect, useCallback, useRef } from 'react';
import { AxiosError, AxiosResponse } from 'axios';
import {
  DataQueryFilter,
  DataQueryFunction,
  DataQueryOptions,
  DataQueryPagination,
  DataQueryProps,
  DataQuerySorting,
  DataQueryVariables,
} from '../types';

export const useDataQuery = (
  query: DataQueryFunction | null,
  initialVariables: DataQueryVariables = {},
  options?: DataQueryOptions
): DataQueryProps => {
  const [params, setParams] = useState<{
    pagination: DataQueryPagination;
    sorting: DataQuerySorting;
    filter: DataQueryFilter;
    [key: string]: any;
  }>({
    ...initialVariables,
    pagination: initialVariables?.pagination || {},
    sorting: initialVariables?.sorting || {},
    filter: initialVariables?.filter || {},
  });
  const { interval, skipInitialRequest, preProcessParams } = options || {};

  const skippingInitialRequest = useRef(skipInitialRequest);

  const [loading, setLoading] = useState(!skipInitialRequest);
  const [response, setResponse] = useState<any>(null);
  const [error, setError] = useState<AxiosError | null>(null);

  const fetchData = useCallback(async () => {
    if (!query) return;
    setLoading(true);
    try {
      const newParams = { ...params, filter: { ...params.filter } };
      const res = await query(typeof preProcessParams === 'function' ? preProcessParams(newParams) : newParams);
      setResponse(res);
      setLoading(false);
    } catch (err) {
      setError(err as any);
      setResponse(null);
      setLoading(false);
    }
  }, [params]); // eslint-disable-line

  const refetch = useCallback(() => {
    setParams((state) => ({ ...state }));
  }, []);

  const setFilter = useCallback((newFilter: DataQueryFilter | ((state: DataQueryFilter) => DataQueryFilter)): void => {
    setParams((state) => ({
      ...state,
      pagination: {
        ...state.pagination,
        pageNum: 1,
      },
      sorting: {
        order: 'desc',
      },
      filter: typeof newFilter === 'function' ? newFilter(state.filter) : newFilter,
    }));
  }, []);

  const setPagination = useCallback(
    (newPagination: DataQueryPagination | ((state: DataQueryPagination) => DataQueryPagination)): void => {
      setParams((state) => ({
        ...state,
        pagination: typeof newPagination === 'function' ? newPagination(state.pagination) : newPagination,
      }));
    },
    []
  );

  const setSorting = useCallback(
    (newSorting: DataQuerySorting | ((state: DataQuerySorting) => DataQuerySorting)): void => {
      setParams((state) => ({
        ...state,
        sorting: typeof newSorting === 'function' ? newSorting(state.sorting) : newSorting,
      }));
    },
    []
  );

  const setVariables = useCallback(
    (newValiables: DataQueryVariables | ((state: DataQueryVariables) => DataQueryVariables)): void => {
      setParams((state) => ({
        ...(typeof newValiables === 'function' ? newValiables(state) : newValiables),
        filter: state.filter,
        pagination: state.pagination,
        sorting: state.sorting,
      }));
    },
    []
  );

  const setData = useCallback((newData: any): void => {
    setResponse((response: AxiosResponse) => ({
      ...response,
      data: typeof newData === 'function' ? newData(response.data) : newData,
    }));
  }, []);

  useEffect(() => {
    if (skippingInitialRequest.current) {
      console.log('skippingInitialRequest');
    } else {
      fetchData();
    }
    skippingInitialRequest.current = false;
  }, [fetchData]);

  useEffect(() => {
    if (interval) {
      const intervalId = setInterval(() => fetchData(), interval);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [fetchData, interval]);

  // console.log(params);

  return {
    response,
    data: response?.data,
    loading,
    error,
    refetch,
    filter: params.filter,
    pagination: params.pagination,
    sorting: params.sorting,
    setFilter,
    setPagination,
    setSorting,
    setVariables,
    setData,
  };
};
