import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceActiveRelatedInvoices: DataQueryFunction = async (variables: any) => {
  const { num, date } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   return Promise.resolve({
  //     data: [{ id: 21040965577629696, num: '12345', date: '20.07.2022' }],
  //   });
  // }

  const res = await httpClient.get(`/invoice/activeRelatedInvoices`, {
    params: { num, date },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
