import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './ProductTable.module.scss';
import { ProductTableRow } from './ProductTableRow';
import { ProductTableTotalsRow } from './ProductTableTotalsRow';
import { ProductTableButton, ProductTableColumn, ProductFormProps } from './types';
import { ButtonIcon } from 'ui/common/controls/button-icon/ButtonIcon';
import { Checkbox } from 'ui/common/controls/checkbox/Checkbox';

const emptyRows: Array<any> = [];
const emptyColumns: Array<ProductTableColumn> = [];

const defaultGetRowId = (row: any) => row.id;

type Props = {
  className?: string;
  columns: Array<ProductTableColumn>;
  getRowId?: (row: any, index: number) => string | number;
  scrollable?: boolean;
  rows?: Array<any>;
  errorsRows?: Array<any>;
  /**
   * Словарь значений для ряда Итого: имя поля итого -> значение
   */
  totalsValues: any;
  /**
   * Таблица соответствия для ряда Итого: имя столбца -> имя поля итого
   */
  totalsFields: { [key: string]: string };
  headerButtons?: Array<ProductTableButton>;
  onRowChange: (rowValue: any, index: number) => void;
  rowFormComponent?: FC<ProductFormProps>;
  editable?: boolean;
};

export const ProductTable: React.FC<Props> = ({
  className,
  columns = emptyColumns,
  scrollable,
  getRowId = defaultGetRowId,
  totalsValues,
  totalsFields,
  rows = emptyRows,
  errorsRows = emptyRows,
  headerButtons = [],
  onRowChange,
  rowFormComponent,
  editable,
}) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});

  const [selectedAll, setSelectedAll] = useState(false);

  // console.log('render ProductTable', rows, { selected, selectedAll });

  const filteredColumns = useMemo(() => columns.filter((col) => !col.hidden), [columns]);

  const onSelectRow = (row: any, index: number, selected: boolean) => {
    setSelected((state) => ({
      ...state,
      [getRowId(row, index)]: selected,
    }));
  };

  const onSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setSelectedAll(checked);
    setSelected((state) =>
      checked ? Object.fromEntries(rows.map((row, index) => [[getRowId(row, index)], true])) : {}
    );
  };

  useEffect(() => {
    // console.log('select all effect', { selectedAll, selected });
    let all = !!rows.length;
    for (let index = 0; index < rows.length; index++) {
      if (!selected[getRowId(rows[index], index)]) {
        all = false;
        break;
      }
    }
    if (all !== selectedAll) {
      setSelectedAll(all);
    }
  }, [selected, selectedAll, getRowId, rows]);

  useEffect(() => {
    setSelected({});
  }, [rows]);

  const selectedRows: Array<{ rowIndex: number; row: any }> = useMemo(
    () =>
      rows.reduce((acc, row, index) => {
        if (selected[getRowId(row, index)]) {
          acc.push({ rowIndex: index, row });
        }
        return acc;
      }, []),
    [rows, selected, getRowId]
  );

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <div className={s.headerButtons}>
          {headerButtons.map((btn) => (
            <ButtonIcon
              key={btn.id}
              className={s.headerButton}
              iconComponent={btn.icon}
              title={btn.label}
              variant={btn.variant}
              onClick={() => {
                if (typeof btn.onClick === 'function') {
                  return btn.onClick(selectedRows);
                }
              }}
              theme="T2"
              disabled={btn.disabled && btn.disabled(selectedRows)}
            />
          ))}
        </div>
      </div>
      <div className={clsx(s.inner, { [s.scrollable]: scrollable })}>
        <table>
          <thead>
            <tr>
              <th className={s.checkCell} style={{ width: 40 }}>
                <Checkbox
                  checked={selectedAll}
                  onChange={(e) => onSelectAll(e)}
                  className={s.checkBox}
                  theme="T2"
                  disabled={!editable}
                />
              </th>
              {filteredColumns.map((col) => (
                <th
                  key={col.name}
                  style={{
                    ...(col.style || {}),
                    width: col.width || 100,
                  }}
                  data-name={col.name}
                  title={col.label}
                >
                  {col.label}
                </th>
              ))}
            </tr>
            <tr className={s.numberTr}>
              <th></th>
              {filteredColumns.map((col) => (
                <th key={col.name}>{col.number}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row: any, index: number) => (
              <ProductTableRow
                key={getRowId(row, index)}
                rowIndex={index}
                row={row}
                errors={errorsRows[index]}
                columns={filteredColumns}
                onChange={onRowChange}
                productFormComponent={rowFormComponent}
                selected={selected[getRowId(row, index)]}
                onSelect={onSelectRow}
                editable={editable}
              />
            ))}
            <ProductTableTotalsRow columns={columns} totalsValues={totalsValues} totalsFields={totalsFields} />
          </tbody>
        </table>
      </div>
    </div>
  );
};
