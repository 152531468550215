import React from 'react';
import clsx from 'clsx';
import s from './SaldoReportPage.module.scss';
import { PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { queryAdminSaldoExport } from './api';
import { SaldoReportForm } from './SaldoReportForm';
import { AppMode } from 'common/constants';
import { clearPrivateProps } from 'common/utils';

const initialValues = {};

type Props = {
  className?: string;
};

/**
 * АРМ -> Отчетность -> Оборотная ведомость по определению товарного баланса
 * /arm/saldo-report
 */
export const SaldoReportPageArm: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const downloadReport = (values: any, format: string) => {
    console.log('downloadReport', format);
    return queryAdminSaldoExport({
      filter: clearPrivateProps({ ...values, tin: values.tin?.tin }),
      format,
    });
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('saldo.title')}</PageTitle>
      <SaldoReportForm onSubmit={downloadReport} appMode={AppMode.ARM} initialValues={initialValues} />
    </div>
  );
};
