import {
  FormLocaleDatePicker,
  FilterContentProps,
  FormCountrySelect,
} from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { getZvtStatusOptions } from '../constants';

type Props = FilterContentProps;

export const ZvtJournalArmFilters: React.FC<Props> = ({ classNames, form }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { columnClassName } = classNames || {};

  const statusOptions = getZvtStatusOptions(locale);

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="dateFrom" label={`${loc('zvt.initiated.from')}:`}>
          <FormLocaleDatePicker maxDate={new Date()} />
        </FormField>
        <FormField name="dateTo" label={`${loc('zvt.initiated.to')}:`}>
          <FormLocaleDatePicker maxDate={new Date()} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="status" label={loc('zvt.status')}>
          <FormSelect options={statusOptions} />
        </FormField>
        <FormField name="tin" label={loc('common.labels.iin')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="registrationNumber" label={loc('zvt.regNumberZvt')}>
          <FormInput />
        </FormField>
        <FormField name="gtdRegNum" label={loc('zvt.regNumberDt')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="departureCountryCode" label={loc('zvt.depCountry')}>
          <FormCountrySelect />
        </FormField>
      </div>
    </Fragment>
  );
};
