import React from 'react';
import clsx from 'clsx';
import s from './InsideNewsCard.module.scss';
import { DT_FORMAT, formatDate } from 'services/date-time';
import { useLocale } from 'services/l10n';
import { generatePath, Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import DOMPurify from 'dompurify';

type Props = {
  className?: string;
  data: any;
  variant: 'main' | 'second' | 'third';
};

export const InsideNewsCard: React.FC<Props> = ({ className, data, variant }) => {
  const locale = useLocale();
  const { choose } = locale;

  return (
    <Link
      to={`${PATH.PAGES}${generatePath(PATH.INSIDE_NEWS_ITEM, { id: data.id })}`}
      className={clsx(className, s.wrapper, s[variant])}
    >
      <h3 className={s.title}>{choose([data.titleRu, data.titleKz])}</h3>
      {variant !== 'third' && (
        <p
          className={s.snippet}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choose([data.txtRu, data.txtKz])) }}
        ></p>
      )}
      <p className={s.date}>{formatDate(data.publishedDate, DT_FORMAT.HUMAN_DATE_TIME, DT_FORMAT.ISO)}</p>
    </Link>
  );
};
