import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './RegDataStep.module.scss';
import ss from '../../Registration.module.scss';
import { useLocale } from 'services/l10n';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { ButtonT2 } from 'ui';
import { getAddressFieldName } from '../../helpers';
import { RegDataFl } from './RegDataFl';
import { RegDataEnterprise } from './RegDataEnterprise';
import { ToastService } from 'services/toasts';
import { findErrorData, solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { mutationRegistrationValidate } from 'rest';
import { capitalizeFirstLetter } from 'common/utils';
import { RegDataIPAndPrivate } from './RegDataIPAndPrivate';
import { observer } from 'mobx-react-lite';
import { RegDataFromCertificate } from './RegDataFromCertificate';
import { RegActionPage, RegType } from 'features/registration/types';

const schema = yup.object({
  // password: yup.string().trim().required('Введите пароль').test('isValid', 'Недопустимое значение', checkPassword),
  // repeatPassword: yup
  //   .string()
  //   .trim()
  //   .required('Повторите введенный выше пароль!')
  //   .oneOf([yup.ref('password')], 'Пароли не совпадают'),
  // email: yup.string().required('Введите email').email(),
});

type Props = RegActionPage & {
  className?: string;
};

export const RegDataStep: React.FC<Props> = observer(({ className, onBack, onNext, regStore }) => {
  const locale = useLocale();
  const { loc, chooseByKey, lang } = locale;

  const formTabs: {
    type: RegType;
    label: string;
    /**
     * fields - имена полей во вкладке, для определения в какой вкладке находится поле для вывода ошибок
     */
    fields: Array<string>;
  }[] = useMemo(
    () =>
      [
        {
          type: RegType.INDIVIDUAL,
          label: loc('common.profileType.INDIVIDUAL'),
          fields: [
            'iin',
            'fio',
            'mobile',
            'issueDate',
            'issuedBy',
            'passportNum',
            'password',
            'repeatPassword',
            'email',
          ],
        },
        {
          type: RegType.ENTREPRENEUR,
          label: loc('common.profileType.ENTREPRENEUR'),
          fields: ['currentAddress', 'individualName'],
        },
        {
          type: RegType.ENTERPRISE,
          label: loc('common.profileType.COMPANY'),
          fields: ['enterpriseBin', 'actsOnTheBasis'],
        },
        {
          type: RegType.NOTARY,
          label: loc('common.profileType.NOTARY'),
          fields: [getAddressFieldName(RegType.NOTARY)],
        },
        {
          type: RegType.LAWYER,
          label: loc('common.profileType.LAWYER'),
          fields: [getAddressFieldName(RegType.LAWYER)],
        },
        {
          type: RegType.MEDIATOR,
          label: loc('common.profileType.MEDIATOR'),
          fields: [getAddressFieldName(RegType.MEDIATOR)],
        },
        {
          type: RegType.BAILIFF,
          label: loc('common.profileType.BAILIFF'),
          fields: [getAddressFieldName(RegType.BAILIFF)],
        },
      ].filter((tab) => tab.type === RegType.INDIVIDUAL || regStore.selectedTypes[tab.type]),
    [loc, regStore.selectedTypes]
  );

  const [currentTab, setCurrentTab] = useState<RegType>(RegType.INDIVIDUAL);

  const initialValues = useMemo(() => {
    /**
     * Рег данные для НЕрезидента из данных сертификата
     */
    if (regStore.taxpayerFromCertificate) {
      const { taxpayerIin, taxpayerBin, taxpayerCompanies } = regStore.taxpayerFromCertificate || {};
      const values: any = {
        iin: taxpayerIin?.tin,
        enterpriseBin: taxpayerBin?.tin,
        fio: capitalizeFirstLetter(
          ['lastName', 'firstName', 'middleName']
            .map((name) => chooseByKey(taxpayerIin, name))
            .filter(Boolean)
            .join(' '),
          true
        ),
        mobile: '',
        issueDate: '',
        issuedBy: '',
        passportNum: '',
        currentAddress: '',
        password: regStore.regData?.password || '',
        repeatPassword: regStore.regData?.repeatPassword || '',
        email: regStore.regData?.email || '',
      };

      if (Array.isArray(taxpayerCompanies) && taxpayerCompanies[0]) {
        values.enterpriseBin = taxpayerCompanies[0].tin;
        values._enterpriseAddress = chooseByKey(taxpayerCompanies[0], 'address');
        values._enterpriseName = chooseByKey(taxpayerCompanies[0], 'name');
      }

      if (regStore.biometricsNotRequired) {
        values.currentAddress = chooseByKey(regStore.taxpayerFromCertificate?.taxpayerIin, 'address');
        regStore.selectedTypesArray
          .filter((type) => type !== RegType.ENTERPRISE)
          .forEach((type) => {
            values[getAddressFieldName(type)] =
              regStore.taxpayerFromCertificate?.taxpayerIin?.addressesByRegType?.[type]?.[lang] ||
              values.currentAddress;
          });
        values.individualName = chooseByKey(regStore.taxpayerFromCertificate?.taxpayerIin, 'name');
      }

      return values;
    } else {
      /**
       * Рег данные для DID
       */
      const { didAuthData, enterprises, registrationTypes } = regStore.did || {};
      const values: any = {
        iin: didAuthData?.iin,
        enterpriseBin: didAuthData?.bin,
        fio: capitalizeFirstLetter(
          [didAuthData?.lastName, didAuthData?.firstName, didAuthData?.patronymic].filter(Boolean).join(' '),
          true
        ),
        mobile: didAuthData?.phone,
        issueDate: didAuthData?.issueDate,
        issuedBy: didAuthData?.authority,
        passportNum: didAuthData?.idCardNumber,
        currentAddress: '',
        password: regStore.regData?.password || '',
        repeatPassword: regStore.regData?.repeatPassword || '',
        email: regStore.regData?.email || '',
        individualName: chooseByKey(regStore.did, 'taxpayerName'),
      };

      if (Array.isArray(registrationTypes)) {
        registrationTypes
          .filter((type) => type !== RegType.ENTERPRISE)
          .forEach((type) => {
            values[getAddressFieldName(type)] = regStore.getAddressFromDid(type, locale);
          });
      }

      if (Array.isArray(enterprises) && enterprises[0]) {
        values.enterpriseBin = enterprises[0].tin;
        values._enterpriseAddress = chooseByKey(enterprises[0], 'address');
        values._enterpriseName = chooseByKey(enterprises[0], 'name');
      }
      return values;
    }
  }, []); // eslint-disable-line

  const [validateError, setValidateError] = useState<any>(undefined);

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    try {
      let regData;
      if (regStore.biometricsNotRequired) {
        regData = {
          ...values,
          registrationTypes: regStore.selectedTypesArray,
          '@c': '.RegistrationFormV2',
        };
        if (regStore.taxpayerFromCertificate?.isUserRegistered) {
          regData.repeatPassword = regData.password;
        }
      } else if (regStore.nonResident) {
        regData = {
          ...values,
          registrationTypes: regStore.selectedTypesArray,
          isNonResident: true,
          '@c': '.RegistrationFormV2',
        };
        if (regStore.taxpayerFromCertificate?.isUserRegistered) {
          regData.repeatPassword = regData.password;
        }
      } else {
        regData = {
          ...values,
          registrationTypes: regStore.selectedTypesArray,
          didState: regStore.did?.state,
          '@c': '.RegistrationFormV2',
        };
        if (regStore.did?.activeRegistrationTypes?.includes('INDIVIDUAL')) {
          regData.repeatPassword = regData.password;
        }
      }
      await mutationRegistrationValidate({
        data: regData,
      });
      regStore.setRegData(regData);
      onNext();
    } catch (error) {
      console.error(error);
      const errorMessage = solveErrorMessage(error);
      if (errorMessage) {
        ToastService.showError(errorMessage);
      } else {
        const formErrors = solveFormErrors(error);
        form.setErrors(formErrors);
        activateTabWithError(formErrors);
        setValidateError(error);
      }
      form.setSubmitting(false);
    }
  };

  const activateTabWithError = (formErrors: any) => {
    let tabWithError: typeof formTabs[number] | undefined;
    formTabs.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (formErrors[field]) {
          tabWithError ??= tab;
        }
      });
    });
    if (tabWithError) {
      setCurrentTab(tabWithError.type);
    }
  };

  //Вывод ошибок не соответсвующих полям формы
  useEffect(() => {
    const exclude = formTabs.flatMap((tab) => tab.fields);
    const errors = findErrorData(validateError);
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        if (typeof error.text === 'string' && !exclude.includes(error.property)) {
          ToastService.showError(error.text);
        }
      });
    }
  }, [validateError, formTabs]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={clsx(ss.container, s.subTabs)}>
        {formTabs.map((tab) => (
          <div
            key={tab.type}
            className={clsx(s.tab, {
              [s.activeTab]: tab.type === currentTab,
            })}
            onClick={() => setCurrentTab(tab.type)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <hr className={ss.hr} />
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ isSubmitting }) => {
          return (
            <Form className={clsx(s.form, ss.container)}>
              {currentTab === RegType.INDIVIDUAL ? (
                regStore.registrationByCertificate ? (
                  <RegDataFromCertificate regStore={regStore} />
                ) : (
                  <RegDataFl regStore={regStore} />
                )
              ) : null}
              {currentTab === RegType.ENTERPRISE && <RegDataEnterprise regStore={regStore} />}
              {[RegType.NOTARY, RegType.MEDIATOR, RegType.BAILIFF, RegType.LAWYER, RegType.ENTREPRENEUR].includes(
                currentTab
              ) && <RegDataIPAndPrivate regType={currentTab} />}
              <div className={ss.actions}>
                <ButtonT2 size="large" variant="secondary" onClick={() => onBack()}>
                  {loc('reg.back')}
                </ButtonT2>
                <ButtonT2 type="submit" size="large" loading={isSubmitting}>
                  {loc('reg.continue')}
                </ButtonT2>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});
