import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from '../../types';

export const queryAdminOGDExport: DataQueryFunction = (variables: any) => {
  const { filter = {} } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  // return httpClient.post(
  //   `/admin/dict/taxOrganization/exportToXlsx`,
  //   {},
  //   {
  //     params: { criteria },
  //   }
  // );

  return downloadFile({
    url: `${API_URL}/admin/dict/taxOrganization/exportToXlsx`,
    method: 'POST',
    params: { criteria },
  });
};
