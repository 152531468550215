import { clearEmptyFields, delay } from 'common/utils';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';
import { fakeIsgoRelated } from './fakeIsgoInvoiceGetNotifications';
import { AxiosResponse } from 'axios';

export const mutationIsgoInvoiceActionConfirmRelated: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  console.log({ body });

  if (process.env.NODE_ENV === 'development') {
    await delay(2000);
    return {
      data: fakeIsgoRelated,
    } as AxiosResponse;
  }

  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('/isgo/invoice/action/confirm/get/with/related', formData);
  if (res?.data?.success === false || res?.data?.errors) {
    throw res;
  } else {
    return res;
  }
};
