import { EsfStatus } from 'features/esf';
import { mutationInvoiceDeleteImported, mutationInvoiceDraftsDelete } from 'rest';

export const deleteEsf = (status: EsfStatus, ids: Array<string>) => {
  if (status === EsfStatus.DRAFT) {
    return mutationInvoiceDraftsDelete({ ids });
  } else if (status === EsfStatus.IMPORTED) {
    return mutationInvoiceDeleteImported({ ids });
  } else {
    return Promise.reject();
  }
};
