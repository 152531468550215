import React, { Fragment } from 'react';
import { FormField, FormInput, FormSelect } from 'ui/common';
import { FormOGDSelect } from 'common/components/controls';
import { getOptionsUserServiceRole, getOptionsYesNo } from 'common/helpers/options';
import { useLocale } from 'services/l10n';
import { FilterContentProps } from 'common/components';

type Props = FilterContentProps;

export const ArmUsersFilters: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const locale = useLocale();

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="iin" label="ИИН">
          <FormInput />
        </FormField>
        <FormField name="roleType" label="Роль в системе">
          <FormSelect options={getOptionsUserServiceRole(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="name" label="ФИО">
          <FormInput />
        </FormField>
        <FormField name="blocked" label="Заблокирован">
          <FormSelect options={getOptionsYesNo(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="ogdCode" label="Код ОГД">
          <FormOGDSelect />
        </FormField>
        <FormField name="mobileUser" label="Пользователь мобильного приложения КГД">
          <FormSelect options={getOptionsYesNo(locale)} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="ogdName" label="Наименование ОГД">
          <FormInput />
        </FormField>
      </div>
    </Fragment>
  );
};
