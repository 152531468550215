import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from '../../types';

export const queryArmUsersExport: DataQueryFunction = (variables: any) => {
  const { filter = {} } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  return downloadFile({
    url: `${API_URL}/arm/users/export`,
    method: 'GET',
    params: { criteria },
  });
};
