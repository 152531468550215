import React from 'react';
import { queryCertificateNumbersList } from 'rest';
import { FormAutocomplete, FormAutocompleteProps } from 'ui/common';

type Props = FormAutocompleteProps & {
  className?: string;
};

export const FormAutocompleteDeclaration: React.FC<Props> = ({ field, className, ...rest }) => {
  return (
    <FormAutocomplete
      className={className}
      variant="product-table"
      loadOptions={loadOptions}
      getSingleValue={getFADSingleValue}
      field={
        field
          ? {
              ...field,
              value: typeof field.value === 'string' ? { value: field.value } : field.value,
            }
          : undefined
      }
      {...rest}
    />
  );
};
async function loadOptions(term: string) {
  const { data } = await queryCertificateNumbersList();
  return Array.isArray(data)
    ? data
        .filter((num: unknown) => String(num).toLowerCase().includes(term.toLowerCase()))
        .map((num: unknown) => ({ value: String(num), label: String(num) }))
    : [];
}

export const getFADSingleValue = (data: any) => {
  return data?.value || '';
};
