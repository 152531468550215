import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import s from './SmallRetailTaxpayerPage.module.scss';
import { Button, Card, DataGrid, Loader, PageFooter, PageTitle } from 'ui/common';
import { contentYesNo } from 'common/components';
import { mutationSROSaveTaxpayer, querySROCurrent } from '../api';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Modal } from 'services/modal';
import { SmallRetailHistory } from './SmallRetailHistory';
import { useStore } from 'common/hooks';

type Props = {};

export const SmallRetailTaxpayerPage: React.FC<Props> = () => {
  const { auth, locale, user } = useStore();
  const { loc } = locale;

  const [modal, setModal] = useState<boolean>(false);

  const { data, refetch } = useDataQuery(querySROCurrent);

  // Если данных по МТТ нет, то приходит путой объект в data.data.
  const sroData = data?.data?.value || data?.data;

  const onCloseModal = () => setModal(false);

  const changeActivation = async (isActive: boolean) => {
    const hash = JSON.stringify({ ...sroData, isActive });
    const signData = await auth.getSignature(hash);
    try {
      await mutationSROSaveTaxpayer({
        body: {
          certificate: signData.cert?.base64Pem || '',
          signature: signData.sign || '',
          value: hash,
        },
      });
      refetch();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <div className={clsx(s.wrapper)}>
      <PageTitle>{loc('small.smallRetail')}</PageTitle>

      {!sroData ? (
        <Loader />
      ) : (
        <div className={clsx('container', s.content)}>
          <Card className={s.card}>
            <DataGrid
              data={[
                [
                  loc('common.labels.loginIin'),
                  // DESF-1753 Если данные пустые то отображать Логин (ИИН\БИН)
                  sroData.tin || user.currentUser?.login,
                ],
                [loc('common.labels.npName'), sroData.npName],
                [loc('small.regDate'), sroData.startDate],
                [
                  loc('common.labels.status'),
                  <span className={clsx({ [s.danger]: !sroData.isActive })}>
                    {typeof sroData.isActive === 'boolean'
                      ? loc(`small.active.${sroData.isActive}`)
                      : // DESF-1753 Если данные пустые то выводим статус "Не действует"
                        loc(`small.active.false`)}
                  </span>,
                ],
              ]}
              columnClassNames={[s.leftColumn, '']}
            />
          </Card>

          <Card className={s.card}>
            <DataGrid
              data={[
                [loc('common.labels.oked'), sroData.okedCode],
                [loc('common.labels.oked2'), sroData.okedCode2],
                [loc('small.onlineKkm'), contentYesNo(sroData.isOnlineKkm, locale)],
                [loc('small.ndsPayer'), contentYesNo(sroData.isNdsPayer, locale)],
                [loc('small.snrType'), sroData.snrType !== undefined && loc(`small.snrType.${sroData.snrType}`)],
              ]}
              columnClassNames={[s.leftColumn, '']}
            />
          </Card>

          {sroData.isActive === false && (
            <Card className={s.card}>
              <DataGrid
                data={[
                  [loc('small.closeDate'), sroData.endDate],
                  [loc('small.closeCause'), sroData.closeCause],
                ]}
                columnClassNames={[s.leftColumn, '']}
              />
            </Card>
          )}

          <PageFooter>
            {/* Если данные не пустые то выводим кнопки действий */}
            {sroData.tin && (
              <Fragment>
                <Button onClick={() => setModal(true)} variant="secondary-upper">
                  {loc('small.viewHistory')}
                </Button>
                <Button className="ml-12" onClick={() => changeActivation(!sroData.isActive)} variant="primary-upper">
                  {loc(sroData.isActive ? 'common.button.reject' : 'small.return')}
                </Button>
              </Fragment>
            )}
          </PageFooter>
        </div>
      )}

      <Modal isOpen={modal} onClose={onCloseModal} wait>
        <SmallRetailHistory />
      </Modal>
    </div>
  );
};
