import React, { useEffect } from 'react';
import clsx from 'clsx';
import s from './MainPage.module.scss';
import { useLocale } from 'services/l10n';
import { ButtonT2 } from 'ui';
import { NewsBlock } from './news-block/NewsBlock';
import { MainLoginForm } from './login/MainLoginForm';
import { useAuth } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import { AuthModals } from 'common/components/modals/auth-modals/AuthModals';
import { PATH } from 'routing/paths';
import { Link } from 'react-router-dom';

type Props = {
  className?: string;
};

export const MainPage: React.FC<Props> = observer(({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const auth = useAuth();

  const submitLogin = async (values: any) => {
    await auth.login(values.login, values.password);
  };

  useEffect(() => {
    return () => {
      auth.clearUserData();
    };
  }, [auth]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <h3>{loc('common.logoTitle')}</h3>
      <div className={s.grid}>
        <div className={s.card}>
          <p>{loc('common.main.prompt')}</p>
          {auth.loginFormShowed ? (
            <MainLoginForm onSubmit={submitLogin} initialValues={{ login: auth.iin, password: '' }} />
          ) : (
            <div className={s.buttons}>
              <ButtonT2
                size="large"
                onClick={() => {
                  auth.startAuthProcess();
                }}
                disabled={auth.inProgress}
              >
                {loc('common.main.login')}
              </ButtonT2>
              <Link to={PATH.REGISTRATION}>
                <ButtonT2 size="large" variant="outlined" className={s.regButton}>
                  {loc('common.main.reg')}
                </ButtonT2>
              </Link>
            </div>
          )}
        </div>
        <div className={s.news}>
          <NewsBlock />
        </div>
      </div>
      <AuthModals />
    </div>
  );
});
