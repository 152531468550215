import { CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecOperationsColumns = ({
  locale: { loc },
  direction,
}: {
  locale: LocaleStore;
  direction: 'input' | 'output';
}): Array<TableColumn> => [
  {
    name: 'index',
    label: loc('eaecActions.p.index'),
    getContent: (_, index) => index + 1,
    style: {
      width: '50px',
    },
  },
  {
    name: 'documentNumber',
    label: loc(direction === 'output' ? 'eaecActions.documentRegistrationNumber' : 'eaecActions.esfRegNum'),
    style: {
      width: '300px',
    },
  },
  {
    name: 'writeOutDate',
    label: loc(direction === 'output' ? 'eaecActions.writeOutDate' : 'eaecActions.esfDate'),
  },
  {
    name: 'sellerTin',
    label: loc('common.labels.sellerTin'),
  },
  {
    name: 'sellerName',
    label: loc('common.labels.sellerName'),
  },
  {
    name: 'customerTin',
    label: loc('common.labels.recipientTin'),
  },
  {
    name: 'customerName',
    label: loc('common.labels.recipientName'),
  },
  {
    name: 'goodOrder',
    label: loc(direction === 'output' ? 'eaecActions.p.productPosition' : 'eaecActions.p.numberEsf'),
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
  },
  {
    name: 'goodsName',
    label: loc('common.labels.productName'),
  },
  {
    name: 'measureUnitCode',
    label: loc('common.labels.measureUnitName'),
    getContent: (row) => (row.measureUnitCode ? <CodeValueMeasureUnit code={row.measureUnitCode} /> : ''),
  },
  {
    name: 'quantity',
    label: loc('common.labels.quantity'),
  },
  {
    name: 'dtRegistrationNumber',
    label: loc('eaecActions.p.dtRegistrationNumber'),
  },
  {
    name: 'dtGoodsNumber',
    label: loc('eaecActions.p.positionInDt'),
  },
  {
    name: 'zvtSellerCountryCode',
    label: loc('eaecActions.zvtCountry'),
    getContent: (row) => (row.zvtSellerCountryCode ? <CodeValueCountry code={row.zvtSellerCountryCode} /> : ''),
  },
];
