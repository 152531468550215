import { clearEmptyFieldsDeep } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminNotBiometricsSearch: DataQueryFunction = async (variables: any) => {
  const {
    filter: { iin, ...rest },
    pagination,
  } = variables || {};

  const criteria = { ...rest, innList: iin ? [iin] : undefined };

  console.log(criteria);

  const res = await httpClient.get(`/admin/biometricsNotRequiredTaxpayer/search`, {
    params: {
      criteria: JSON.stringify(clearEmptyFieldsDeep(criteria)),
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
