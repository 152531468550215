import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './InputPagination.module.scss';
import { UITheme } from 'ui/common/types/Theme';
import { ButtonIcon } from 'ui/common/controls';

type Props = {
  className?: string;
  currentPage?: number;
  onPageChange: (value: number) => void;
  maxLength?: number;
  lastPage?: boolean;
  firstTitle?: string;
  nextTitle?: string;
  prevTitle?: string;
  theme?: UITheme;
};

export const InputPagination: React.FC<Props> = ({
  className,
  currentPage = 1,
  onPageChange,
  maxLength = 3,
  lastPage,
  firstTitle = 'Первая страница',
  nextTitle = 'Следующая страница',
  prevTitle = 'Предыдущая страница',
  theme = 'T1',
}) => {
  const [page, setPage] = useState<number>(currentPage);

  const onInputChange = (event: React.FocusEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/[^0-9]/g, '');
    setPage(Number(newValue) || 1);
  };

  const onInputBlur = () => {
    onPageChange(page || 1);
  };

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  return (
    <div className={clsx(s.wrapper, className, s[theme])}>
      <ButtonIcon
        iconName="chevron-left"
        className={clsx(s.control)}
        iconClassName={s.controlIcon}
        onClick={() => onPageChange(1)}
        title={firstTitle}
        disabled={currentPage === 1}
      />
      <ButtonIcon
        iconName="chevron-left"
        className={clsx(s.control)}
        iconClassName={s.controlIcon}
        onClick={() => onPageChange(currentPage - 1)}
        title={prevTitle}
        disabled={currentPage === 1}
      />
      <input
        className={s.pageInput}
        type="text"
        value={page}
        onChange={onInputChange}
        onBlur={onInputBlur}
        maxLength={maxLength}
        style={{ width: `${24 + maxLength * 9}px` }}
      />
      <ButtonIcon
        iconName="chevron-right"
        className={clsx(s.control)}
        iconClassName={s.controlIcon}
        onClick={() => onPageChange(currentPage + 1)}
        title={nextTitle}
        disabled={lastPage}
      />
    </div>
  );
};
