import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './EaecCodeGeneration.module.scss';
import { Button, InfiniteScroll, Loader, PageTitle, Table, TableColumn } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { Modal } from 'services/modal';
import { EaecCodeGeneratonModal } from '../components/EaecCodeGeneratonModal';
import { queryAdminEaecUserHistorySearch } from './api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

const getRowId = (row: any) => row?.id;

const initialPagination = { rows: 20 };

type Props = {
  className?: string;
};

/**
 * Страница АРМ - Генерирование кода для таможенной службы
 * /arm/eaec-code-generation
 */
export const EaecCodeGeneration: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const { data, setPagination, error } = useDataQuery(queryAdminEaecUserHistorySearch, {
    pagination: initialPagination,
  });

  const columns = useMemo(() => getEaecUsersColumn(locale), [locale]);

  const onModalClose = (success: boolean) => {
    setShowGenerateModal(false);
    if (success) {
      setPagination((p) => ({ ...p, pageNum: 1 }));
    }
  };

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  return (
    <div className={clsx(className, s.wrapper, 'container')}>
      <PageTitle>{loc('eaec.codeGeneration')}</PageTitle>
      <div className={s.topHeader}>
        <Button onClick={() => setShowGenerateModal(true)}>{loc('eaec.generate')}</Button>
      </div>
      <div className={s.content}>
        <InfiniteScroll
          loadNext={() => {
            setPagination((p) => ({ ...p, pageNum: data?.currPage + 1 }));
          }}
          nextRows={data?.rows}
          isFirstPart={data?.currPage === 1}
          hasMore={data?.lastBlock === false}
          getRowId={getRowId}
          renderLoader={() => <Loader />}
        >
          {(rows) => (!!rows.length ? <Table columns={columns} rows={rows} getRowId={getRowId} /> : null)}
        </InfiniteScroll>
      </div>
      <Modal isOpen={showGenerateModal} center onClose={onModalClose}>
        <EaecCodeGeneratonModal />
      </Modal>
    </div>
  );
};

const getEaecUsersColumn = (locale: LocaleStore): Array<TableColumn> => {
  const { loc } = locale;
  return [
    {
      name: 'updateUserName',
      label: loc('eaec.lastUpdateUserName'),
      style: {
        width: 300,
      },
    },
    {
      name: 'updateDate',
      label: loc('common.labels.date'),
      style: {
        width: 200,
      },
    },
    {
      name: 'countryName',
      label: loc('common.labels.country'),
      style: {
        width: 200,
      },
    },
    {
      name: 'generatedCount',
      label: loc('eaec.codes'),
      style: {
        width: 200,
      },
    },
  ];
};
