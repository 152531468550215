import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React, { Fragment, ReactNode, useEffect } from 'react';

type Props = {
  children: (count: number) => ReactNode;
};

export const MessagesCounterProvider: React.FC<Props> = observer(({ children }) => {
  const { messages } = useStore();

  useEffect(() => {
    messages.fetchUnreadCounter();
  }, [messages]);

  return <Fragment> {children(messages.countOfUnread)}</Fragment>;
});
