import React, { ChangeEvent, useState } from 'react';
import clsx from 'clsx';
import s from './Uploader.module.scss';
import { ReactComponent as PaperClipIcon } from './paper-clip.svg';
import { SvgIcon } from 'ui/common/elements';
import { ButtonIcon } from '../button-icon';
import { nanoid } from 'nanoid';
import { FileInput } from '../file-input';
import { UITheme } from 'ui/common/types';
import { formatFileSize } from 'common/utils';

export type UploaderProps = {
  className?: string;
  fileInputClassName?: string;
  label?: string;
  files?: Array<File>;
  onSelect?: (files: Array<File>) => void;
  onRemove?: (file: File) => void;
  multiple?: boolean;
  fileAppearance?: 'line' | 'image';
  showFileSize?: boolean;
  fullWidth?: boolean;
  theme?: UITheme;
};

export const Uploader: React.FC<UploaderProps> = ({
  className,
  fileInputClassName,
  label,
  files,
  onSelect = (file) => console.log(file),
  onRemove = (filename) => console.log(filename),
  multiple,
  fileAppearance = 'line',
  showFileSize,
  theme = 'T1',
  fullWidth,
}) => {
  const [key, setKey] = useState<string>(nanoid());

  const inputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    if (!event.target.files) return;
    const files = Array.from(event.target.files);
    onSelect(files);
    setKey(nanoid());
  };

  return (
    <div className={clsx(className, s.wrapper, s[theme])}>
      <FileInput
        key={key}
        onChange={inputChange}
        multiple={multiple}
        label={label}
        theme={theme}
        fullWidth={fullWidth}
        className={fileInputClassName}
      />
      {Array.isArray(files) &&
        files.map((file, index) =>
          fileAppearance === 'line' ? (
            <div key={index} className={s.fileLine} title={file.name}>
              <SvgIcon className={s.paperClipIcon} component={PaperClipIcon} />
              <span className={s.filename}>{file.name}</span>
              {showFileSize && <span className={s.filesize}>({formatFileSize(file.size)})</span>}
              <ButtonIcon
                iconName="close"
                className={s.closeButton}
                iconClassName={s.closeIcon}
                onClick={() => onRemove(file)}
              />
            </div>
          ) : fileAppearance === 'image' ? (
            <div key={index} className={s.fileImage} title={file.name}>
              <img src={URL.createObjectURL(file)} alt={file.name} className={s.image} />
              <div className={s.filename}>{file.name}</div>
              <ButtonIcon
                iconName="close"
                className={s.closeButton}
                iconClassName={s.closeIcon}
                onClick={() => onRemove(file)}
              />
            </div>
          ) : null
        )}
    </div>
  );
};
