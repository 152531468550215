import { useEffect } from 'react';
import { useDataQuery } from 'rest';
import { queryBiometricsDocumentRequired } from 'rest/api/biometrics/queryBiometricsDocumentRequired';
import { useLocale } from 'services/l10n';
import { ToastService } from 'services/toasts';

/**
 * DESF-1953
 * Проверка требования прохождения биометрии для рисковых НП
 */
export const useCheckBiometricsRequired = ({ showWarning }: { showWarning?: boolean }) => {
  const { loc } = useLocale();
  const { data } = useDataQuery(queryBiometricsDocumentRequired);

  useEffect(() => {
    if (showWarning && data?.required === true) {
      ToastService.showWarning(
        `<h3>${loc('bio_risky.message.title')}</h3> <p>${loc('bio_risky.message.content', [
          data.date,
        ])}</p> <h4><a href="/esf-web/biometrics/document/oauth2/authorization/did">${loc(
          'bio_risky.message.link'
        )}</a></h4>`,

        {
          autoClose: false,
          closeOnClick: false,
          closeButton: true,
        }
      );
    }
  }, [data, loc, showWarning]);

  return { data };
};
