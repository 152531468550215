import { DataQueryFunction, httpClient } from 'rest';

export const queryAdminSROImportCloseFileMeta: DataQueryFunction = async (
  variables: any
) => {
  const res = await httpClient.get(
    `/admin/dict/smallRetailOutlet/importCloseFileMeta`
  );
  if (res.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
