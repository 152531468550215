import debounce from 'debounce-promise';
import { queryVlcFindDocumentProducts } from '../api';

const debouncedFindDocumentProducts = debounce(queryVlcFindDocumentProducts, 500);

export async function fetchProducts(docNumber: string): Promise<any[]> {
  let products = [];
  try {
    const res = await debouncedFindDocumentProducts({
      documentNumber: docNumber,
    });
    if (Array.isArray(res.data) && res.data.length) {
      products = res.data;
    }
  } catch (error) {
    console.error(error);
  }
  return products;
}
