import {
  CodeValueCountry,
  dateRangeValue,
  FilterT2Tag,
  getCountryOptionLabel,
} from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getZvtJournalTxpColumns = ({
  locale: { loc },
}: {
  locale: LocaleStore;
}): Array<TableColumn> => [
  {
    name: 'registrationNumber',
    label: loc('zvt.regNumber'),
    width: 300,
  },
  {
    name: 'initiated',
    label: loc('zvt.initiated'),
    labelTitle: loc('zvt.initiated.title'),
  },
  {
    name: 'astana1Status',
    label: loc('zvt.status'),
    labelTitle: loc('zvt.status.title'),
    getContent: (row) =>
      row.astana1Status ? loc(`zvt.status.${row.astana1Status}`) : '',
  },
  {
    name: 'totalCost',
    label: loc('zvt.totalCost'),
  },
  {
    name: 'currencyCode',
    label: loc('common.labels.currencyCode'),
  },
  {
    name: 'departureCountry',
    label: loc('zvt.depCountry'),
    labelTitle: loc('zvt.depCountry.title'),
    getContent: (row) => <CodeValueCountry code={row.departureCountry} />,
  },
  {
    name: 'documentGtdRegNum',
    label: loc('common.dt'),
    labelTitle: loc('zvt.dt.title'),
  },
];

export const getZvtJournalTxpFilterTags = ({
  locale,
}: {
  locale: LocaleStore;
}): Array<FilterT2Tag> => {
  const { loc } = locale;
  return [
    {
      name: 'status',
      getValue: (values) => loc(`zvt.status.${values.status}`),
    },
    {
      name: ['dateFrom', 'dateTo'],
      getValue: (values) => `${dateRangeValue(values.dateFrom, values.dateTo)}`,
    },
    {
      name: 'registrationNumber',
      label: loc('zvt.regNumber'),
    },
    {
      name: 'gtdRegNum',
      label: loc('zvt.regNumberDt'),
    },
    {
      name: 'departureCountryCode',
      getValue: (values) => {
        return getCountryOptionLabel(values.departureCountryCode, locale);
      },
    },
  ];
};
