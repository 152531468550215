import { getNotSelectedOptions } from 'common/helpers/options';
import { LocaleStore } from 'services/l10n';
import { AgreementData, AgreementsData, RegType } from './types';

export const getRegActOnOptions = ({ locale }: { locale: LocaleStore }) => {
  const { loc } = locale;
  return [
    ...getNotSelectedOptions(locale),
    {
      label: loc('reg.actsOn.certificate'),
      value: 'certificate',
    },
    {
      label: loc('reg.actsOn.order'),
      value: 'order',
    },
    {
      label: loc('reg.actsOn.power_of_attorney'),
      value: 'power_of_attorney',
    },
    {
      label: loc('reg.actsOn.charter'),
      value: 'charter',
    },
    {
      label: loc('reg.actsOn.position'),
      value: 'position',
    },
  ];
};

export const getRegContractTypeOptions = ({ locale }: { locale: LocaleStore }) => {
  const { loc } = locale;
  return [
    {
      label: loc('reg.contractType.GENERAL'),
      value: 'GENERAL',
    },
    {
      label: loc('reg.contractType.ADDITIONAL'),
      value: 'ADDITIONAL',
    },
  ];
};

export const getRegAgreementTypeOptions = ({ locale }: { locale: LocaleStore }) => {
  const { loc } = locale;
  return ['AGENCY_AGREEMENT', 'JOINT_ACTIVITY_AGREEMENT', 'PRODUCTION_SHARING_AGREEMENT'].map((type) => ({
    label: loc(`reg.agreementType.${type}`),
    value: type,
  }));
};

// export const checkPasswordStrength = (val: string) => {
//   strength = userActions.checkPassStrength val, self.$passwordStrength
//   percent = Math.min(100, val.length / 14 * 100)
//   self.passwordStrengthWidth(percent)
//   return strength
// }

export function scorePassword(pass: string) {
  var score = 0;
  if (!pass || pass.length < 8) {
    return 1;
  }

  // Рейтинг уникальности символов
  var letters: { [key: string]: number } = {};
  pass.split('').forEach((letter) => {
    letters[letter] = (letters[letter] || 0) + 1;
    score += 6 / letters[letter];
  });

  var variations: { [key: string]: boolean } = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = -1;
  for (var check in variations) {
    variationCount += variations[check] === true ? 1 : 0;
  }
  score += variationCount * 10;

  return score;
}

export function checkPassword(pass?: string): boolean {
  if (!pass || pass.length < 8) {
    return false;
  }

  var variations: { [key: string]: boolean } = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
  };

  let variationCheck = true;
  for (var check in variations) {
    if (!variations[check]) {
      variationCheck = false;
    }
  }

  return variationCheck;
}

export const getAddressFieldName = (type: RegType) =>
  [RegType.BAILIFF, RegType.LAWYER, RegType.MEDIATOR, RegType.NOTARY].includes(type)
    ? `${type.toLowerCase()}Address`
    : 'currentAddress';

export const AGREEMENTS_FIELD_NAMES: { [key in RegType]: string } = {
  [RegType.INDIVIDUAL]: 'physicalAgreement',
  [RegType.ENTREPRENEUR]: 'individualAgreement',
  [RegType.ENTERPRISE]: 'enterpriseAgreement',
  [RegType.NOTARY]: 'notaryAgreement',
  [RegType.LAWYER]: 'lawyerAgreement',
  [RegType.MEDIATOR]: 'mediatorAgreement',
  [RegType.BAILIFF]: 'bailiffAgreement',
};

export const prepareTotalRegData = (regData: any, agreements: AgreementsData) => {
  function prepareAgreement(agreement: AgreementData | undefined) {
    if (!agreement) {
      return undefined;
    }
    const data = JSON.parse(JSON.stringify(agreement));
    delete data.confirmed;
    return data;
  }

  const data: any = {
    regForm: JSON.stringify(regData),
  };

  Object.keys(agreements).forEach((agg) => {
    if (agreements[agg as RegType]) {
      data[AGREEMENTS_FIELD_NAMES[agg as RegType]] = JSON.stringify(prepareAgreement(agreements[agg as RegType]));
    }
  });

  return data;
};

export const getAgreementHtml = () => {
  const agreementHtml = document.querySelector('.agreementHtml')?.innerHTML;
  return agreementHtml;
};
