import React, { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './EsfCounter.module.scss';
import { useLocale } from 'services/l10n';
import { numberWithSpaces } from 'common/utils';
import { ReactComponent as InfoIcon } from 'assets/icons/info-flat.svg';
import { SvgIcon, Tooltip } from 'ui';
import { useStore } from 'common/hooks';
import { queryInvoiceStatisticsGet, repeatableRequest } from 'rest';
import { getIn } from 'formik';
import { formatItems, formatPrice } from '../helpers';

type Props = {
  className?: string;
};

export const EsfCounter: React.FC<Props> = ({ className }) => {
  const { user } = useStore();
  const locale = useLocale();
  const { loc } = locale;

  const ref = useRef(null);

  const [warning, setWarning] = useState<'warning' | 'crossWarning' | null>(null);
  const [counterData, setCounterData] = useState<any>(null);

  useEffect(() => {
    if (user.currentUser?.currentProfileId !== 'individual') {
      return repeatableRequest({
        pattern: [2 * 1000, 4 * 1000, 8 * 1000, 16 * 1000],
        request: queryInvoiceStatisticsGet,
        isSuccess: (res) => Boolean(res?.data?.aggregationDate),
        onSuccess: ({ data }) => {
          setCounterData(data);
          // Для НДСника и ГУ не должны отображаться подсказки в таблице при приближении и превышении;
          // И для филиалов не должны отображаться тоже DESF-1974
          const headOffice = getIn(user.currentUser, 'currentProfile.enterpriseTaxpayerInfo.headOffice');
          if (
            data &&
            !(
              getIn(user.currentUser, 'currentProfile.enterpriseTaxpayerInfo.certificateNum') ||
              user.currentUser?.isGU ||
              (headOffice && headOffice?.tin !== user.currentUser?.currentUserTin)
            )
          ) {
            setWarning(
              data.invoiceSummaYear > data.warnCrossingLimitAmount
                ? 'crossWarning'
                : data.invoiceSummaYear > data.warnApproachingLimitAmount
                ? 'warning'
                : null
            );
          }
        },
      });
    }
  }, [user.currentUser]);

  return (
    <div
      ref={ref}
      className={clsx(className, s.wrapper, warning && s[warning], {
        [s.visualyHidden]: !counterData,
      })}
    >
      {counterData ? (
        <Fragment>
          <div className={s.header}>
            <h3>{loc('esfCounter.title')}</h3>
            <div className={s.date}>
              <span>{counterData.aggregationDate}</span>
              <span>{loc('esfCounter.actuality')}</span>
            </div>
          </div>
          <section>
            <div className={s.row}>
              <span className={s.label}>{loc('esfCounter.year')}</span>
              <span className={s.value}>{formatItems(counterData.invoiceCountYear, locale)}</span>
            </div>
            <div className={s.row}>
              <span className={s.label}>
                {loc('esfCounter.turn')}{' '}
                {typeof counterData.warnCrossingLimitAmount === 'number' && (
                  <Tooltip
                    content={loc(
                      `esfCounter.${warning}`,
                      `${numberWithSpaces(counterData.warnCrossingLimitAmount.toFixed(2).replace('.', ','))} ₸`
                    )}
                    place="bottom-left"
                  >
                    <SvgIcon className={s.infoIcon} component={InfoIcon} />
                  </Tooltip>
                )}
              </span>
              <span className={s.value} style={{ color: 'var(--warningColor)' }}>
                {formatPrice(counterData.invoiceSummaYear)}
              </span>
            </div>
            <div className={s.row}>
              <span className={s.label}>{loc('esfCounter.nds')}</span>
              <span className={s.value}>{formatPrice(counterData.invoiceSummaNdsYear)}</span>
            </div>
          </section>
          <section>
            <div className={s.row}>
              <span className={s.label}>{loc('esfCounter.quarter')}</span>
              <span className={s.value}>{formatItems(counterData.invoiceCountQuarter, locale)}</span>
            </div>
            <div className={s.row}>
              <span className={s.label}>{loc('esfCounter.turn')}</span>
              <span className={s.value}>{formatPrice(counterData.invoiceSummaQuarter)}</span>
            </div>
            <div className={s.row}>
              <span className={s.label}>{loc('esfCounter.nds')}</span>
              <span className={s.value}>{formatPrice(counterData.invoiceSummaNdsQuarter)}</span>
            </div>
          </section>
        </Fragment>
      ) : null}
    </div>
  );
};
