import React from 'react';
import clsx from 'clsx';
import s from './EaecLoginPage.module.scss';
import { EaecLoginForm } from './EaecLoginForm';
import { useAuth } from 'common/hooks';

type Props = {
  className?: string;
};

export const EaecLoginPage: React.FC<Props> = ({ className }) => {
  const auth = useAuth();

  const loginEaecUser = (values: any) => {
    return auth.loginEaecUser(values.login, values.password);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <EaecLoginForm onSubmit={loginEaecUser} />
    </div>
  );
};
