import React from 'react';
import clsx from 'clsx';
import { UITheme } from 'ui/common/types';

const defOnChange = () => {};

type Props = {
  className?: string;
  selectedClassName?: string;
  radioValue: string | boolean;
  value?: string | boolean;
  onChange?: (value: string | boolean) => void;
  theme?: UITheme;
  disabled?: boolean;
};

export const RadioBlockItem: React.FC<Props> = ({
  className,
  children,
  selectedClassName = '',
  radioValue,
  value,
  onChange = defOnChange,
  theme = 'T2',
  disabled,
}) => {
  return (
    <button
      type="button"
      className={clsx(className, {
        [selectedClassName]: value === radioValue,
      })}
      onClick={() => onChange(radioValue)}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
