import { AxiosResponse } from 'axios';
import { clearEmptyFields } from 'common/utils';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationIsgoInvoiceActionConfirmHash: DataMutationFunction<AxiosResponse> = async (
  variables
): Promise<any> => {
  const { body } = variables || {};

  if (process.env.NODE_ENV === 'development') {
    return { data: { data: { hashList: { 45: 'ljkfhlfgsdfgsdkfgkdshg' } }, success: true } };
  }

  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('/isgo/invoice/action/confirm/hash', formData);

  if (res?.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
