import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryVSRemainderGoodsSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  // if (process.env.NODE_ENV === 'development') {
  //   return { data: fakeVSRemainderGoodsSearch };
  // }

  const res = await httpClient.get(`/vstore/rest/remainderGoods/search`, {
    params: { criteria, ...toPaginationParams(pagination) },
  });

  return res;
};
