import { clearEmptyFields, clearPrivateProps } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const createCriteria = (filter: any) =>
  JSON.stringify(
    clearPrivateProps(
      clearEmptyFields({
        ...filter,
        recipientGroups: filter.recipientGroups && [filter.recipientGroups],
      })
    )
  );

export const queryAdminMessagesSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = createCriteria(filter);

  // httpClient.get(`/admin/messages/search`, {
  const res = await httpClient.get(`/admin/messages/search`, {
    params: {
      criteria,
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
