import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination, sorting = {} } = variables || {};

  let criteria: any = JSON.stringify(clearEmptyFields({ ...filter }));

  const res = await httpClient.get('snt/search', {
    params: clearEmptyFields({
      criteria,
      ...toPaginationParams(pagination),
      ...sorting,
    }),
  });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
