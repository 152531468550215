import { Taxpayer } from 'common/stores/user-store';
import { LocaleStore } from 'services/l10n';

export function resolveTaxpayerFio(taxpayer: Taxpayer | undefined, locale: LocaleStore) {
  if (!taxpayer) return '';
  const { choose } = locale;
  const lastName = choose([taxpayer.lastNameRu, taxpayer.lastNameKz]);
  const firstName = choose([taxpayer.firstNameRu, taxpayer.firstNameKz]);
  const middleName = choose([taxpayer.middleNameRu, taxpayer.middleNameKz]);
  return [lastName, firstName, middleName].filter(Boolean).join(' ');
}

export function resolveTaxpayerName(taxpayer: Taxpayer | undefined, locale: LocaleStore) {
  if (!taxpayer) return '';
  const { choose } = locale;
  return choose([taxpayer.nameRu, taxpayer.nameKz]);
}

export function resolveTaxpayerNameOrFio(taxpayer: Taxpayer | undefined, locale: LocaleStore) {
  return resolveTaxpayerName(taxpayer, locale) || resolveTaxpayerFio(taxpayer, locale);
}

export function resolveTaxpayerAdress(taxpayer: Taxpayer | undefined, locale: LocaleStore) {
  if (!taxpayer) return '';
  const { choose } = locale;
  return choose([taxpayer.addressRu, taxpayer.addressKz]);
}
