import { LocaleStore } from 'services/l10n';

export enum ZvtStatus {
  RELEASED = 'RELEASED',
  CANCELED = 'CANCELED',
}

export const getZvtStatusOptions = ({
  loc,
}: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: ZvtStatus.RELEASED,
    label: loc('zvt.status.RELEASED'),
  },
  {
    value: ZvtStatus.CANCELED,
    label: loc('zvt.status.CANCELED'),
  },
];
