import React, { useEffect, useMemo, useState } from 'react';
import './Select.scss';
import ReactSelect, { MenuListProps, components } from 'react-select';
import clsx from 'clsx';
import { SelectProps } from './SelectProps';

const emptyArray: Array<any> = [];
const emptyOptions: Array<any> = [];
const defaultgetOptionValue = (opt: any) => opt.value;

export const Select: React.FC<SelectProps> = ({
  className,
  options,
  variant = 'default',
  value = null,
  fetchOptions,
  getOptionValue = defaultgetOptionValue,
  filterOption,
  isSearchable = false,
  onChange,
  fullWidth = true,
  size = 'default',
  placeholder = '',
  noOptionsMessage = () => null,
  valueAsOption = false,
  isMulti,
  theme = 'T1',
  short,
  menuPlacement = 'auto',
  error,
  isDisabled,
  readOnly,
  label,
  components,
  menuWidth = '100%',
  ...rest
}) => {
  const [optionsState, setOptionsState] = useState<Array<any>>(options || emptyOptions);

  const valueOption = useMemo(() => {
    if (valueAsOption) {
      if (value) {
        return value;
      }
      if (Array.isArray(optionsState)) {
        return optionsState.find((opt) => getOptionValue(opt) === '');
      }
    } else {
      if (Array.isArray(optionsState)) {
        if (isMulti) {
          if (Array.isArray(value)) {
            const optionsMap = Object.fromEntries(optionsState.map((opt) => [getOptionValue(opt), opt]));
            return value.map((v) => optionsMap[v] || null);
          }
        } else {
          return optionsState.find((opt) => String(getOptionValue(opt)) === String(value));
        }
      }
    }
    return null;
  }, [value, optionsState, getOptionValue, isMulti, valueAsOption]);

  const onChangeOption = (opt: any) => {
    // console.log('onChange', opt);
    if (typeof onChange === 'function') {
      if (valueAsOption) {
        onChange(opt, opt);
      } else {
        if (isMulti) {
          onChange(Array.isArray(opt) ? opt.map((o) => getOptionValue(o)) : emptyArray, opt);
        } else {
          onChange(getOptionValue(opt), opt);
        }
      }
    }
  };

  const onInputChange = (any: any) => {
    // console.log(any);
  };

  const hasValue: boolean = isMulti
    ? Array.isArray(value) && !!value.length
    : !!value || value === false || value === 0;

  useEffect(() => {
    if (typeof fetchOptions === 'function') {
      fetchOptions().then((options) => setOptionsState(options));
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    // console.log('setOptionsState effect');
    setOptionsState(options || emptyOptions);
  }, [options]);

  return (
    <ReactSelect
      classNamePrefix={'select'}
      {...rest}
      options={optionsState}
      value={valueOption}
      getOptionValue={getOptionValue}
      filterOption={filterOption}
      isSearchable={isSearchable}
      className={clsx(className, 'select', `select${theme}`, size, {
        fullWidth,
        hasValue,
        selectShort: short,
        waitingOptions: typeof fetchOptions === 'function' && optionsState.length === 0,
        productTable: variant === 'product-table',
        selectError: !!error,
        'control-error': !!error,
      })}
      onChange={onChangeOption}
      onInputChange={onInputChange}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      isMulti={isMulti}
      menuPlacement={menuPlacement}
      maxMenuHeight={300}
      minMenuHeight={300}
      isDisabled={isDisabled || readOnly}
      components={variant === 'product-table' ? { MenuList: MenuListForProductTable, ...components } : components}
      styles={{
        menu: (base) => ({ ...base, '--menu-width': typeof menuWidth === 'number' ? `${menuWidth}px` : menuWidth }),
      }}
    />
  );
};

const MenuListForProductTable = (props: MenuListProps) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <components.MenuList {...props}>{props.children}</components.MenuList>
    </div>
  );
};
