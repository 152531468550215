import React from 'react';
import clsx from 'clsx';
import s from './MessageModal.module.scss';
import { DT_FORMAT, formatFromDate } from 'services/date-time';
import { Lang, useLocale } from 'services/l10n';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { ButtonT2 } from 'ui';
import { sanitize } from 'dompurify';

type Props = ModalChildrenProps & {
  className?: string;
  message: any;
};

export const MessageModal: React.FC<Props> = ({ className, message, onClose }) => {
  const locale = useLocale();
  const { loc, lang } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header>{loc('c.messages.one')}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <div className={s.inner}>
          <div className={s.info}>
            <div className={s.row}>
              <b>{loc('c.messages.from')}</b>
              {formatFullName(message.senderTin, message.senderFullname)}
            </div>
            <div className={s.row}>
              <b>{loc('c.messages.to')}</b>
              {formatFullName(message.recipientTin, message.recipientFullname)}
            </div>
            <div className={s.row}>
              <b>{loc('c.messages.date')}</b>
              {formatFromDate(new Date(message.createdTimeStamp), DT_FORMAT.MESSAGE_DATE_TIME)}
            </div>
            <div className={s.row}>
              <b>{loc('c.messages.operatorLogin')}</b>
              {message.operatorLogin}
            </div>
            <div className={s.row}>
              <b>{loc('c.messages.operatorFullname')}</b>
              {message.operatorFullname}
            </div>
          </div>

          <div
            className={s.text}
            dangerouslySetInnerHTML={{ __html: sanitize(lang === Lang.RU ? message.text : message.textKz) }}
          />
        </div>
      </ModalT2Content>
      <ModalT2Footer>
        <ButtonT2 size="small" onClick={onClose}>
          {loc('common.button.close')}
        </ButtonT2>
      </ModalT2Footer>
    </div>
  );
};

const formatFullName = (tin: string, name: string) => {
  return tin && name ? `${tin} (${name})` : name || '';
};
