import { AxiosResponse } from 'axios';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryMessagesNewInboundCount: DataQueryFunction = async () => {
  if (process.env.NODE_ENV === 'development') {
    return {
      data: 12,
    } as AxiosResponse;
  }

  return httpClient.get(`/messages/getNewInboundMessageCount`);
};
