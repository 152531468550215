import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { EaecDocumentsInputFilters } from './EaecDocumentsInputFilters';
import { getEaecDocumentsInputColumns } from './getEaecDocumentsInputColumns';
import { queryEaecDocumentInputJournalExport, queryEaecDownloadChainZip, queryEaecDocumentInputSearch } from '../api';
import { EaecProductsPage } from '../eaec-products/EaecProductsPage';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Modal } from 'services/modal';
import { EaecHistoryModal } from '../eaec-history/EaecHistoryModal';
import { rowsOptionsVariant3 } from 'ui';
import { SessionStorageService } from 'services/storage';
import { formatFromDate } from 'services/date-time';
import { EaecDocumentRelated } from '../eaec-document-related/EaecDocumentRelated';

const filterStorageKey = 'EaecDocumentsInput_filter';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.documentId;

type Props = {};

// Страница АРМ "Взаимодействие со странами ЕАЭС" ->
// "Получение сведений о товарах и связанных с ними операциях из стран ЕАЭС"
// /arm/eaec-documents-input
export const EaecDocumentsInput: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [productsPage, setProductsPage] = useState<{
    opened: boolean;
    row?: any;
  }>({
    opened: false,
  });
  const [relatedPage, setRelatedPage] = useState<{
    opened: boolean;
    row?: any;
  }>({
    opened: false,
  });

  const [historyModal, setHistoryModal] = useState<{
    opened: boolean;
    row?: any;
  }>({
    opened: false,
  });

  const [exporting, setExporting] = useState(false);

  const columns = useMemo(() => getEaecDocumentsInputColumns({ locale }), [locale]);

  const defaultFilter = {
    fromCreateDate: formatFromDate(new Date()),
    toCreateDate: formatFromDate(new Date()),
  };

  const queryProps = useDataQuery(
    queryEaecDocumentInputSearch,
    {
      filter: SessionStorageService.get(filterStorageKey) || defaultFilter,
      pagination: initialPagination,
    },
    {
      preProcessParams: (variables) => {
        const { filter } = variables;
        return {
          ...variables,
          filter: preProcessFilter(filter),
        };
      },
    }
  );

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      onClick: () => exportJournal('XLSX'),
    },
    {
      id: 2,
      label: loc('common.button.exportPdf'),
      onClick: () => exportJournal('PDF'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('eaecActions.productsInfo'),
      onClick: (selection: Array<any>) => setProductsPage({ opened: true, row: selection[0] }),
    },
    {
      id: 2,
      label: loc('eaecActions.related'),
      onClick: (selection: Array<any>) => setRelatedPage({ opened: true, row: selection[0] }),
    },
    {
      id: 3,
      label: loc('common.button.xml'),
      onClick: (selection: Array<any>) => exportSelectionXML(selection),
    },
    {
      id: 4,
      label: loc('eaecActions.statusHistory'),
      onClick: (selection: Array<any>) => setHistoryModal({ opened: true, row: selection[0] }),
    },
  ];

  const closeProductsPage = useCallback(() => {
    setProductsPage({ opened: false });
  }, []);

  const closeRelatedPage = useCallback(() => {
    setRelatedPage({ opened: false });
  }, []);

  const exportSelectionXML = async (selection: Array<any>) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryEaecDownloadChainZip({
          docId: getRowId(selection[0]),
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  const exportJournal = async (format: string) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryEaecDocumentInputJournalExport({
          filter: preProcessFilter(queryProps.filter),
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.EAEC_DOCUMENTS_INPUT')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={EaecDocumentsInputFilters}
        filterInitialOpened
        filterExpandable
        defaultFilter={defaultFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        hidden={productsPage.opened || relatedPage.opened}
        getRowId={getRowId}
        paginationRowsOptions={rowsOptionsVariant3}
        filterStorageKey={filterStorageKey}
      />
      {productsPage.opened ? (
        <EaecProductsPage id={getRowId(productsPage.row)} onClose={closeProductsPage} direction="input" />
      ) : null}
      {relatedPage.opened ? (
        <EaecDocumentRelated docId={getRowId(relatedPage.row)} onClose={closeRelatedPage} direction="input" />
      ) : null}

      <Modal isOpen={historyModal.opened} onClose={() => setHistoryModal({ opened: false })}>
        <EaecHistoryModal docId={getRowId(historyModal.row)} />
      </Modal>
    </Fragment>
  );
};

const preProcessFilter = (filter: any) => {
  return {
    ...filter,
    documentRegistrationNumber: undefined,
    documentRegistrationNumbers: [
      ...(filter.documentRegistrationNumbers || []),
      filter.documentRegistrationNumber,
    ].filter(Boolean),
    senderTin: undefined,
    senderTins: [...(filter.senderTins || []), filter.senderTin].filter(Boolean),
    customerTin: undefined,
    customerTins: [...(filter.customerTins || []), filter.customerTin].filter(Boolean),
    tnvedCode: undefined,
    tnvedCodes: [...(filter.tnvedCodes || []), filter.tnvedCode].filter(Boolean),
    dtNumber: undefined,
    dtNumbers: [...(filter.dtNumbers || []), filter.dtNumber].filter(Boolean),
  };
};
