/**
 * Создает новый массив из элементов первого массива, которых нет во втором массиве
 */
export function arrayDifference<T = any>(array1: Array<T>, array2: Array<T>): Array<T> {
  if (Array.isArray(array1) && Array.isArray(array2)) {
    return array1.filter((x) => !array2.includes(x));
  } else if (Array.isArray(array1) && !Array.isArray(array2)) {
    return [...array1];
  } else {
    return [];
  }
}
