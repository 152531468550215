export const PERMISSION_STATUS = {
  // В ожидании
  TO_CONFIRM: 'TO_CONFIRM',

  // Подтверждено
  CONFIRMED: 'CONFIRMED',

  // Отклонено
  DECLINED: 'DECLINED',
};
