import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './Accordion.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon';

type Props = {
  className?: string;
  headerClassName?: string;
  iconClassName?: string;
  openedClassName?: string;
  /**
   * Начальное состояние (можно изменять для управления состоянием из родителя)
   */
  initialOpened?: boolean;
  renderHeader: (opened: boolean) => React.ReactNode;
  animated?: boolean;
  /**
   * contentHeightUpdate - метод для обновления высоты контейнера с анимацией
   */
  children: ((opened: boolean, contentHeightUpdate: () => void) => ReactNode) | ReactNode;
};

export const Accordion: React.FC<Props> = ({
  className,
  headerClassName,
  iconClassName,
  openedClassName,
  children,
  initialOpened = true,
  renderHeader,
  animated,
}) => {
  const [opened, setOpened] = useState(initialOpened);
  const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);
  const inner = useRef<HTMLDivElement>(null);

  const contentHeightUpdate = useCallback(() => {
    if (inner.current?.clientHeight) {
      setContentHeight(inner.current?.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (!animated) return;
    contentHeightUpdate();
  }, [animated, contentHeightUpdate]);

  const onHeaderClick = (e: React.MouseEvent) => {
    setOpened((state) => !state);
  };

  useEffect(() => {
    setOpened(initialOpened);
  }, [initialOpened]);

  return (
    <div
      className={clsx(
        className,
        s.wrapper,
        {
          [s.opened]: opened,
          [s.animated]: animated,
        },
        opened ? openedClassName : ''
      )}
    >
      <div className={clsx(s.header, headerClassName)} onClick={onHeaderClick}>
        {renderHeader(opened)}
        <SvgIcon name={opened ? 'chevron-up' : 'chevron-down'} className={clsx(s.icon, iconClassName)} />
      </div>
      <div className={s.content} style={animated && contentHeight ? { height: opened ? `${contentHeight}px` : 0 } : {}}>
        <div className={s.contentInner} ref={inner}>
          {typeof children === 'function' ? children(opened, contentHeightUpdate) : children}
        </div>
      </div>
    </div>
  );
};
