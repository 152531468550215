import React, { Fragment } from 'react';
import { components, SingleValueProps } from 'react-select';

type Props = SingleValueProps & {
  getOptionValue: (option: any) => any;
};

export const SelectMUISingleValue: React.FC<Props> = ({ children, getOptionValue, ...props }) => {
  const singleValue = getOptionValue(props.getValue()?.[0]) ? children : '';

  return (
    <Fragment>
      <components.SingleValue {...props}>{singleValue}</components.SingleValue>
    </Fragment>
  );
};
