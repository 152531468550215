import { RegistrationStore } from './RegistrationStore';

export enum RegType {
  /**
   * Физическое лицо
   */
  INDIVIDUAL = 'INDIVIDUAL',
  /**
   * Индивидуальный предприниматель
   */
  ENTREPRENEUR = 'ENTREPRENEUR',
  /**
   * Юридическое лицо
   */
  ENTERPRISE = 'ENTERPRISE',
  /**
   * Нотариус
   */
  NOTARY = 'NOTARY',
  /**
   * Адвокат
   */
  LAWYER = 'LAWYER',
  /**
   * Медиатор
   */
  MEDIATOR = 'MEDIATOR',
  /**
   * Частный судебный исполнитель
   */
  BAILIFF = 'BAILIFF',
}

export enum RegSubType {
  OPERATOR = 'operator',
  ATTORNEY = 'attorney',
}

export type RegActionPage = {
  onNext: () => void;
  onBack: () => void;
  regStore: RegistrationStore;
};

export type RegStep = 'typeSelect' | 'regData' | 'contract' | 'agreement' | 'done';

export type RegSelectedTypes = { [key in RegType]?: true };

export type RegSelectedSubTypes = { [key in RegSubType]?: true };

export type AgreementsData = {
  [key in RegType]?: AgreementData;
};

export type AgreementData = {
  certificate?: string;
  signature?: string;
  content?: string;
  tin?: string;
  name?: string;
  confirmed?: boolean;
};

export type DidData = {
  didAuthData?: any;
  registrationTypes?: any[];
  activeRegistrationTypes?: any[];
  state?: string;
  enterprises?: any[];
  addresses?: Array<{
    type: string;
    addressRu: string;
    addressKz: string;
  }>;
  individualAddressRu?: string;
  individualAddressKz?: string;
  taxpayerNameRu?: string;
  taxpayerNameKz?: string;
};
