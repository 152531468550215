import React, { Fragment, useEffect, useMemo } from 'react';
import s from './RegDataStep.module.scss';
import ss from '../../Registration.module.scss';
import { FormFieldT2, FormSelectMUIT2, FormValueProps } from 'ui';
import { useLocale } from 'services/l10n';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { RegistrationStore } from '../../RegistrationStore';
import { getRegActOnOptions } from 'features/registration/helpers';

type Props = {
  className?: string;
  regStore: RegistrationStore;
};

export const RegDataEnterprise: React.FC<Props> = ({ regStore }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  const { values, setFieldValue } = useFormikContext<any>();

  const enterprises = useMemo(
    () => regStore.did?.enterprises || regStore.taxpayerFromCertificate?.taxpayerCompanies || {},
    [regStore]
  );

  const actOnOptions = useMemo(() => getRegActOnOptions({ locale }), [locale]);

  const enterprisesOptions = useMemo(() => {
    if (Array.isArray(enterprises) && !!enterprises.length) {
      return enterprises.map((ent) => ({ value: ent.tin, label: chooseByKey(ent, 'name') }));
    }
  }, [enterprises, chooseByKey]);

  useEffect(() => {
    if (values.actsOnTheBasis) {
      setFieldValue('actsOnTheBasis', undefined);
    }
  }, [values.enterpriseBin]); // eslint-disable-line

  const onEnterpriseBinChange = ({ value, form }: FormValueProps) => {
    const { setFieldValue } = form;
    if (Array.isArray(enterprises) && !!enterprises.length) {
      const enterprise = enterprises.find((ent) => ent.tin === value);
      setFieldValue('enterpriseBin', value);
      setFieldValue('_enterpriseAddress', chooseByKey(enterprise, 'address'));
      setFieldValue('_enterpriseName', chooseByKey(enterprise, 'name'));
    }
  };

  return (
    <div className={s.enterpriseData}>
      <FormFieldT2 className={ss.field} name="enterpriseBin">
        <FormSelectMUIT2
          className={ss.control}
          label={loc('common.labels.company')}
          size="large"
          options={enterprisesOptions}
          onValueChange={onEnterpriseBinChange}
        />
      </FormFieldT2>
      {values.enterpriseBin ? (
        <Fragment>
          <FormFieldT2 className={ss.field} name="actsOnTheBasis">
            <FormSelectMUIT2
              className={ss.control}
              label={loc('reg.actsOnTheBasis')}
              size="large"
              options={actOnOptions}
            />
          </FormFieldT2>
          <div className={clsx(ss.frame, s.frame)}>
            <div className={clsx(ss.credentials, ss.enterpriseCredentials)}>
              <div>
                <div className={ss.label}>{loc('reg.enterpriseName')}</div>
                <div className={ss.text}>{values._enterpriseName}</div>
              </div>
              <div>
                <div className={ss.label}>{loc('reg.enterpriseBin')}</div>
                <div className={ss.text}>{values.enterpriseBin}</div>
              </div>
              <div>
                <div className={ss.label}>{loc('reg.enterpriseFio')}</div>
                <div className={ss.text}>{values.fio}</div>
              </div>
              <div>
                <div className={ss.label}>{loc('reg.enterpriseIin')}</div>
                <div className={ss.text}>{values.iin}</div>
              </div>
              <div>
                <div className={ss.label}>{loc('reg.enterpriseAddress')}</div>
                <div className={ss.text}>{values._enterpriseAddress}</div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : null}
    </div>
  );
};
