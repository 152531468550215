import React, { Fragment, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './ZvtPageTaxpayer.module.scss';
import { Breadcrumbs, DataGridT2, Heading, Loader, PageTitleT2, TableColumn, TableT2 } from 'ui';
import { LocaleStore, useLocale } from 'services/l10n';
import { BackButton, CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import { useDataQuery } from 'rest';
import { queryZvtId } from '../api';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import { PATH } from 'routing/paths';

const getProductRowId = (row: any) => row?.id;

type Props = {
  className?: string;
  onClose?: () => void;
  id: string;
};

export const ZvtPageTaxpayer: React.FC<Props> = ({ className, onClose, id }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, error } = useDataQuery(queryZvtId, {
    id,
  });

  const productsColumns = useMemo(() => getProductsColumns({ locale }), [locale]);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitleT2>{loc('zvt.pageTitle')}</PageTitleT2>
      <div className="mb-8">
        <Breadcrumbs
          crumbs={[
            { label: loc('common.main.main'), link: PATH.MAIN },
            { label: loc('common.virtualStore') },
            { label: loc('zvt.journalTitle'), onClick: () => typeof onClose === 'function' && onClose() },
            { label: data?.registrationNumber },
          ]}
          variant="taxpayer"
        />
      </div>
      <BackButton onClick={onClose} theme="T2" />
      {!!data ? (
        <div className={s.inner}>
          <DataGridT2
            data={[
              [loc('zvt.reciveDate'), data.inputDate],
              [loc('zvt.initiated.title'), data.initiated],
              [loc('zvt.regNumber'), data.registrationNumber],
              [loc('zvt.consigneeTin.title'), data.consigneeTin],
              [loc('zvt.consigneeName'), data.consigneeName],
              [loc('zvt.consigneeAdress'), data.consigneeAddress],
            ]}
            data2={[
              [loc('zvt.status'), data.astana1Status ? loc(`zvt.status.${data.astana1Status}`) : ''],
              [loc('zvt.customsCode'), data.customsCode],
              [loc('zvt.depCountry'), <CodeValueCountry code={data.departureCountry} />],
              [loc('zvt.destCountry'), <CodeValueCountry code={data.destinationCountry} />],
              [loc('common.labels.currencyCode'), data.currencyCode],
              [loc('common.labels.currencyRate'), data.currencyRate],
              [loc('zvt.totalCost'), data.totalCost],
            ]}
          />

          {Array.isArray(data.products) && (
            <Fragment>
              <Heading className={s.goodAmount} tag="h3" theme="T2">
                {loc('common.labels.goodsAmount')}: {data.goodsAmount}
              </Heading>
              <TableT2
                columns={productsColumns}
                rows={data.products}
                getRowId={getProductRowId}
                scrollable
                resizableColumns
              />
            </Fragment>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const getProductsColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'rowNum',
    label: loc('zvt.p.rowNum'),
    width: 70,
  },
  {
    name: 'productName',
    label: loc('zvt.p.productName'),
    width: 250,
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    width: 120,
  },
  {
    name: 'status',
    label: loc('common.labels.status'),
    getContent: (row) => (row.status ? loc(`zvt.p.status.${row.status}`) : ''),
  },
  {
    name: 'quantity',
    label: loc('zvt.p.quantity'),
    width: 150,
  },
  {
    name: 'measureUnitCode',
    label: loc('common.labels.measureUnitName'),
    getContent: (row: any) => <CodeValueMeasureUnit code={row.measureUnitCode} />,
  },
  {
    name: 'weight',
    label: loc('zvt.p.weight'),
    width: 150,
  },
  {
    name: 'price',
    label: loc('zvt.p.price'),
    width: 150,
  },
  {
    name: 'totalCost',
    label: loc('zvt.p.totalCost'),
    width: 150,
  },
  {
    name: 'originCountry',
    label: loc('zvt.p.originCountry'),
    getContent: (row: any) => <CodeValueCountry code={row.originCountry} />,
  },
  {
    name: 'canExport',
    label: loc('zvt.p.canExport'),
    getContent: (row: any) => (typeof row.canExport === 'boolean' ? loc(`zvt.p.canExport.${row.canExport}`) : ''),
    width: 170,
  },
];
