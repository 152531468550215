import { FormikProps, getIn } from 'formik';
import { EsfFormValues, EsfFormValuesProduct } from '../types';
import { checkStatuses } from './checkStatuses';
import { Calculation, EsfPaperReason, EsfTxpStatus, EsfVersion } from '../constants';
import { setFieldValueDiffer, solveErrorMessage, transformFormErrors } from 'common/form/helpers';
import { LocaleStore } from 'services/l10n';
import {
  mutationInvoicePrefillFromConfirmDocument,
  queryCheckRegistered,
  queryConvertToAdditionalInvoiceInfo,
  queryConvertToFixedInvoiceInfo,
  queryInvoiceDraftId,
  queryInvoiceFindTnvedSuccessorCodes,
  queryInvoiceGetPaperDays,
  queryInvoiceGetTaxpayerStatuses,
  queryInvoiceId,
  queryInvoiceIsSharingParticipant,
  querySntFindRelatedInvoices,
  queryTaxpayerAutocompleteBins,
} from 'rest';
import { preprocessFormData, preprocessToFormData } from './preprocessFormData';
import { DialogStore } from 'services/modal';
import { parseToNumber, removeDecZero, toFixedFloat } from 'common/utils';
import { formatFromDate, formatToDate } from 'services/date-time';
import { subYears } from 'date-fns';
import { Taxpayer, UserStore } from 'common/stores/user-store';
import { EsfMode, EsfStatus, EsfType } from 'features/esf';
import { resolveTaxpayerPropsFromUser } from 'common/helpers/taxpayer/resolveTaxpayerProps';
import { ToastService } from 'services/toasts';
import { resolveTaxpayerFio, resolveTaxpayerNameOrFio } from 'common/helpers';
import { queryInvoiceValidationErrors } from 'rest/api/esf/queryInvoiceValidationErrors';
import { preSetErrors } from './valdate';

export class LogicEsf {
  ///////////////////
  // NEW, NEW_PAPER
  ///////////////////
  /**
   * Формирование начальных данных для нового бланка и бланка на бумажнном носителе
   */
  static createNewFormData = async ({
    mode,
    user,
    locale,
    version = EsfVersion.V2,
    reasonPaper,
  }: {
    mode: EsfMode;
    user: UserStore;
    locale: LocaleStore;
    version?: EsfVersion;
    reasonPaper?: EsfPaperReason;
  }): Promise<{ values: EsfFormValues }> => {
    const { tin, name, address, kogd, certificateSeries, certificateNum, headOffice } = resolveTaxpayerPropsFromUser(
      user,
      locale
    );
    // TODO получение show_branch_tin из preferences, расчет branch_tin
    // TODO Автозаполнение селлеров из проекта, Автозаполнение агента из проекта
    const values: EsfFormValues = {
      _mode: mode,
      _calcDirection: Calculation.DIRECT,
      _calcType: Calculation.AUTO,
      _version: version,
      _invoiceStatus: EsfStatus.DRAFT,
      invoiceType: EsfType.ORDINARY_INVOICE,
      // registrationNumber: 'KZZZ-12312312',
      date: formatFromDate(new Date()),
      turnoverDate: formatFromDate(new Date()),
      reasonPaper,
      sellers: [
        {
          _taxpayer: { tin },
          name,
          address,
          kogd,
          certificateSeries: headOffice?.resident ? headOffice?.certificateSeries : certificateSeries,
          certificateNum: headOffice?.resident ? headOffice?.certificateNum : certificateNum,
        },
      ],
      customers: [{ countryCode: 'KZ' }],
      productSet: {
        currencyCode: 'KZT',
        products: [{ turnoverSize: '0', ndsAmount: '0', priceWithTax: '0', catalogTruId: '1' }],
        totalPriceWithoutTax: '0',
        totalExciseAmount: '0',
        totalTurnoverSize: '0',
        totalNdsAmount: '0',
        totalPriceWithTax: '0',
      },
      sellerParticipants: [],
      customerParticipants: [],
      deliveryTerm: { hasContract: true },
      consignor: {},
      consignee: {
        countryCode: 'KZ',
      },
      publicOffice: {},
    };

    // проверяем нулевого юзера проверками checkIsUserSharingParticipant
    //todo переделать на _isSharingParticipant
    try {
      const { data: isSharingParticipant } = await queryInvoiceIsSharingParticipant({
        tin,
      });
      if (typeof isSharingParticipant === 'boolean') {
        if (values.sellers[0]._taxpayer) {
          values.sellers[0]._taxpayer._isSharingParticipant = isSharingParticipant;
        }
      }
    } catch (error) {
      console.error(error);
    }

    if (mode === EsfMode.newPaper) {
      await this.enrichPaperDays(values);
    }

    return { values };
  };

  ///////////////////
  // DRAFT
  ///////////////////
  /**
   * Формирование начальных данных для бланка, в режиме черновик
   */
  static createDraftFormData = async ({
    id,
  }: {
    id: string;
  }): Promise<{ values: EsfFormValues; errors?: any } | undefined> => {
    let values;
    try {
      const res = await queryInvoiceDraftId({ id });
      values = res.data?.invoice ? preprocessToFormData(res.data, EsfMode.draft) : undefined;
    } catch (err) {
      ToastService.showError(solveErrorMessage(err));
      throw err;
    }

    return values ? { values } : undefined;
  };

  ///////////////////
  // FIXED, ADDITIONAL
  ///////////////////
  /**
   * Формирование начальных данных для бланка, в режиме корректировки и в режиме дополнительный
   */
  static createFixedAdditionalFormData = async ({
    id,
    mode,
    locale,
  }: {
    id: string;
    mode: EsfMode.fixed | EsfMode.additional;
    locale: LocaleStore;
  }): Promise<{ values: EsfFormValues; errors?: any } | undefined> => {
    let values;
    try {
      const res =
        mode === EsfMode.fixed
          ? await queryConvertToFixedInvoiceInfo({ id })
          : await queryConvertToAdditionalInvoiceInfo({ id });
      values = res.data?.invoice ? preprocessToFormData(res.data, mode) : undefined;
      if (values) {
        values.date = formatFromDate(new Date());
        values._invoiceStatus = EsfStatus.DRAFT;
        delete values._stringInvoiceId;
        delete values.registrationNumber;
        delete values.num;
        delete values.datePaper;
        delete values.reasonPaper;
        await this.enrichEsfTaxpayers(values, locale);
        await this.enrichPaperDays(values);
      }
    } catch (err) {
      ToastService.showError(solveErrorMessage(err));
      throw err;
    }

    return values ? { values } : undefined;
  };

  ///////////////////
  // SHOW
  ///////////////////
  /**
   * Формирование начальных данных для бланка, в в режиме просмотра
   */
  static createShowFormData = async ({
    id,
    mode,
  }: {
    id: string;
    mode: EsfMode;
  }): Promise<{ values: EsfFormValues; errors?: any } | undefined> => {
    let values, errors;
    try {
      const res = await queryInvoiceId({ id });
      values = res.data?.invoice ? preprocessToFormData(res.data, mode) : undefined;
    } catch (err) {
      ToastService.showError(solveErrorMessage(err));
      throw err;
    }

    try {
      const res = await queryInvoiceValidationErrors({ id });
      errors = Array.isArray(res.data?.errors) ? res.data?.errors : [];
    } catch (err) {
      ToastService.showError(solveErrorMessage(err));
      throw err;
    }
    return values ? { values, errors: preSetErrors(transformFormErrors(errors)) } : undefined;
  };

  //
  //
  ///////////////// Бизнес-логика раздела А /////////////////
  //
  //

  static getMinTurnoverDate = (form: FormikProps<EsfFormValues>) => {
    const { values } = form;
    return values.invoiceType === EsfType.ADDITIONAL_INVOICE
      ? new Date(Math.max(formatToDate(values.turnoverDate)?.valueOf() || 0, subYears(new Date(), 5).valueOf()))
      : undefined;
  };

  /**
   * Обогащает данные формы, датами для заполнения на бумажном носителе;
   * # Исключаем даты простоя и блокировки в календаре "Дата выписки на бумажном носителе";
   * меняет входящее значение values, и возвращает его
   */
  static enrichPaperDays = async (values: EsfFormValues) => {
    try {
      const { data } = await queryInvoiceGetPaperDays();
      console.log(data);
      if (data) {
        values._paperDays = data;
      }
      return values;
    } catch (error) {
      console.log(error);
    }
  };

  //
  //
  ///////////////// Бизнес-логика раздела B /////////////////
  //
  //

  /**
   * Проверка что первый продавец - D. Лизингодатель
   */
  static isFirstSellerLessor(form: FormikProps<EsfFormValues>) {
    const sellers = form.values.sellers;
    return sellers[0].statuses?.includes(EsfTxpStatus.LESSOR);
  }

  //
  //
  ///////////////// Бизнес-логика раздела С /////////////////
  //
  //

  /**
   * Если в поле 20 "Категория получателя" указана категория "F - нерезидент" возможность ручного ввода."
   * Запрещаем изменять реквизиты в исправленном СФ
   * */
  static isCustomerNameAddressEditable(form: FormikProps<EsfFormValues>, index: number) {
    return (
      form.values.customers[index].statuses?.includes(EsfTxpStatus.NONRESIDENT) &&
      form.values.invoiceType !== EsfType.FIXED_INVOICE
    );
  }

  /**
   * Условие для возможности редактировать 18.1. Код страны
   */
  static isCountryCodeEditable(form: FormikProps<EsfFormValues>) {
    const {
      values: { sellers, productSet, _sntData },
    } = form;

    return (
      checkStatuses(sellers[0]?.statuses, [EsfTxpStatus.FORWARDER, EsfTxpStatus.EXPORTER, EsfTxpStatus.TRANSPORTER]) ||
      productSet?.ndsRateType ||
      this.isCountryFieldExclusion(_sntData)
    );
  }

  /**
   * #DESF-1668 Исключение для ЭСФ на основании СНТ с отметкой
   * 8.3. «Вывоз на переработку», // 8.4 «Временный вывоз», 9.2 «В пределах одного лица на территории стран ЕАЭС»
   * Используется для определения доступности редактирования полей Код страны 18.1 и 26.4.
   */
  static isCountryFieldExclusion(sntData: any) {
    return (
      ['TEMPORARY_EXPORT', 'EXPORT_FOR_PROCESSING'].includes(getIn(sntData, 'documentInfo.snt.sntExport.exportType')) ||
      getIn(sntData, 'documentInfo.snt.transferType') === 'ONE_PERSON_IN_EAEU'
    );
  }

  ///////////////// Бизнес-логика раздела D /////////////////

  static isConsigneeCountryCodeEditable(form: FormikProps<EsfFormValues>) {
    const {
      values: { sellers, productSet, _sntData },
    } = form;

    const enabled =
      checkStatuses(sellers[0]?.statuses, [
        EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT,
        EsfTxpStatus.EXPORTER,
        EsfTxpStatus.TRANSPORTER,
      ]) ||
      productSet.ndsRateType ||
      this.isCountryFieldExclusion(_sntData);

    return enabled;
  }

  //
  //
  ///////////////// Бизнес-логика раздела E /////////////////
  //
  //

  /**
   * Проверка доступности редактирования поля deliveryTerm.accountNumber
   */
  static isDeliveryTermAccountNumberEditable(form: FormikProps<EsfFormValues>) {
    const { values } = form;
    const { sellers, customers } = values;

    return (
      getIn(values, 'deliveryTerm.hasContract') &&
      checkStatuses(sellers[0]?.statuses, [EsfTxpStatus.EXPORTER]) &&
      customers[0].countryCode !== 'KZ'
    );
  }

  //
  //
  ///////////////// Бизнес-логика раздела F /////////////////
  //
  //

  static isSntDeliveryDocNum(form: FormikProps<EsfFormValues>) {
    const { values } = form;
    const result = this.isSntDocNumber(values.deliveryDocNum) || this.isSntDocNumber(values.deliveryDocNum2);
    return result;
  }

  static isSntDocNumber(num?: string) {
    return /KZ-SNT-[0-9]{4}-[0-9]{12}-[0-9]{8}-[0-9]{8}/.test(num || '');
  }

  /**
   * Предзаполнение формы при добавлении (изменении) документа подтверждающего поставку.
   * Возвращает новые данные формы.
   */
  static async prefillFromDeliveryDoc(
    form: FormikProps<EsfFormValues>,
    dialog: DialogStore,
    locale: LocaleStore
  ): Promise<EsfFormValues> {
    const {
      values: { _mode, _version, _calcDirection, _calcType, _invoiceStatus, sellers, customers, productSet },
    } = form;
    let newFormValues: EsfFormValues = {} as EsfFormValues;
    try {
      const { data } = await mutationInvoicePrefillFromConfirmDocument({ data: preprocessFormData(form.values) });
      newFormValues = {
        ...data.invoice,
        _mode,
        _calcDirection,
        _calcType,
        _version,
        _invoiceStatus,
      };

      if (Array.isArray(newFormValues.productSet.products)) {
        newFormValues.productSet.products = this.calculateAllProductRows(newFormValues);
      }

      if (Array.isArray(newFormValues.sellers)) {
        newFormValues.sellers = await Promise.all(
          newFormValues.sellers.map(async (seller) => {
            const { tin, ...rest } = (seller as any) || {};
            if (tin && typeof tin === 'string') {
              const checks = typeof tin === 'string' ? await this.taxpayerChecksSellerCustomer('seller', tin) : {};
              return { ...rest, _taxpayer: { tin, ...checks } };
            }
            return seller;
          })
        );
      }

      if (Array.isArray(newFormValues.customers)) {
        newFormValues.customers = await Promise.all(
          newFormValues.customers.map(async (customer) => {
            const { tin, ...rest } = (customer as any) || {};
            if (tin && typeof tin === 'string') {
              const checks = typeof tin === 'string' ? await this.taxpayerChecksSellerCustomer('customer', tin) : {};
              return { ...rest, _taxpayer: { tin, ...checks } };
            }
            return customer;
          })
        );
      }

      // При создании ЭСФ с категорией поставщика D «Лизингодатель» и категорией получателя С «Лизингополучатель», Система должна проверять наличие ранее выписанного ЭСФ в действующем статусе («Не просмотрен», «Доставлен») на основании СНТ.
      // При отсутствии ЭСФ система должна выводить пользователю сообщение «Необходимо выписать ЭСФ на товары лизинга по СНТ. Заполнить в раздел G данные по товарам из выбранной СНТ?».
      if (
        [data.documentType, data.documentType2].includes('SNT') &&
        sellers?.length === 1 &&
        sellers[0]?.statuses?.includes(EsfTxpStatus.LESSOR) &&
        customers?.[0]?.statuses?.includes(EsfTxpStatus.LESSEE)
      ) {
        try {
          const { data: relatedInvoices } = await querySntFindRelatedInvoices({ id: data.stringDocumentId });
          let confirmed: boolean | null = null;
          if (relatedInvoices?.length === 0) {
            confirmed = await dialog.show({
              title: locale.loc('esf.prefillG.title'),
              content: locale.loc('esf.prefillG.content'),
            });
          }

          if (relatedInvoices?.length !== 0 || !confirmed) {
            newFormValues.productSet = productSet;
          }
        } catch (error) {}
      }
    } catch (error) {
      throw error;
    }
    console.log({ newFormValues });
    return newFormValues;
  }

  //
  //
  ///////////////// Бизнес-логика раздела G /////////////////
  //
  //

  /**
   * Проверка, есть ли поставщики, которые являются плтальщиками НДС
   * @return true - Если есть хотябы один поставщик, плательщик НДС. Иначе false
   */
  static checkNdsPayer(form: FormikProps<EsfFormValues>) {
    const {
      values: { sellers },
    } = form;

    let result = false;

    sellers.forEach((seller) => {
      if (seller.certificateSeries || checkStatuses(seller.statuses, [EsfTxpStatus.BROKER, EsfTxpStatus.FORWARDER])) {
        result = true;
      }
    });

    return result;
  }

  /**
   * Проверка условия для заполнения Поля "33.1. Код валюты* значением KZT"
   *
   * Поле "33.1. Код валюты* " должно автоматически заполняться значением "KZT" без возможности редактирования,
   * если в поле 10 "Категория поставщика" не указаны категории "E - участник СРП", или "G - экспортер", или "H - международный перевозчик"
   * и в поле 20 "Категория получателя" не указана категория "G - Участник СРП или сделки, заключенной в рамках СРП"
   */
  static isCurencyCodeKZT(form: FormikProps<EsfFormValues>) {
    const { values } = form;
    let fillKZT = true;
    values.sellers.forEach((seller) => {
      if (
        seller.statuses?.includes(EsfTxpStatus.EXPORTER) ||
        seller.statuses?.includes(EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT) ||
        seller.statuses?.includes(EsfTxpStatus.TRANSPORTER)
      ) {
        fillKZT = false;
      }
    });
    values.customers.forEach((customer) => {
      if (customer.statuses?.includes(EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT)) {
        fillKZT = false;
      }
    });

    return fillKZT;
  }

  static async updateTnvedSuccessorCodes(form: FormikProps<EsfFormValues>) {
    const { values, setFieldValue } = form;
    const { turnoverDate } = values;

    const mainRegNum = getIn(values, 'relatedInvoice.registrationNumber');
    const products = getIn(values, 'productSet.products', []);
    try {
      const { data } = await queryInvoiceFindTnvedSuccessorCodes({ turnoverDate, mainRegNum });
      if (Array.isArray(data) && Array.isArray(products) && data.length === products.length) {
        data.forEach((prod: any) => {
          if (prod.newCode && prod.oldCode && prod.newCode !== prod.oldCode) {
            products[prod.rowNum].unitCode = prod.newCode;
          }
          if (prod.newGsvsCode && prod.oldGsvsCode && prod.newGsvsCode !== prod.oldGsvsCode) {
            products[prod.rowNum].catalogTruId = prod.newGsvsCode;
          }
          products[prod.rowNum]._successorCode = prod;
          //TODO это логика для доступности полей, реализовать в форме продукта
          // if (!prod.newCode && !prod.exists) {
          //   setFieldValue(`productSet.products[${prod.rowNum}]._successorCode`, 'doesntExist');
          // }
          // if (prod.oldCode && !prod.newCode) {
          //   setFieldValue(`productSet.products[${prod.rowNum}]._successorCode`, 'doesntExist');
          // }
          // if (prod.newWithdrawal !== prod.oldWithdrawal) {
          //   setFieldValue(`productSet.products[${prod.rowNum}]._successorCode`, 'withdrawalChanged');
          // }
        });

        setFieldValue('productSet.products', [...products]);
      } else {
        console.log("queryInvoiceFindTnvedSuccessorCodes result isn't array!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  static isTreeDigitUnitPrice({ values }: FormikProps<EsfFormValues>) {
    return (
      checkStatuses(values.sellers[0]?.statuses, [EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT, EsfTxpStatus.EXPORTER]) &&
      checkStatuses(values.customers[0]?.statuses, [EsfTxpStatus.NONRESIDENT]) &&
      values.productSet.currencyCode !== 'KZT'
    );
  }

  /**
   *
   * @param fieldName имя поля которое было изменено
   * @param fieldValue новое значение поля
   * @param rowValues текущее значения формы продукта
   * @param formValues текущее значения (values) основной формы бланка ЭСФ
   * @returns новое значение (values) формы продукта
   */
  static calculateProductRow({
    fieldName,
    fieldValue,
    rowValues,
    formValues,
  }: {
    fieldName?: string;
    fieldValue?: string;
    rowValues: any;
    formValues: EsfFormValues;
  }): any {
    const calculateOrder: { [key: string]: number } = {
      quantity: 5,
      unitPrice: 6,
      priceWithoutTax: 7,
      exciseAmount: 9,
      turnoverSize: 10,
      ndsRate: 11,
      ndsAmount: 12,
      priceWithTax: 13,
    };

    function solveNdsMultiplier(formValues: any) {
      let multiplier = 1;

      const { sellers } = formValues;

      let totalShare = 0;
      let ndsShare = 0;

      if (Array.isArray(sellers)) {
        sellers.forEach((seller) => {
          if (seller.certificateSeries) {
            ndsShare += parseToNumber(seller.shareParticipation);
          }
          totalShare += parseToNumber(seller.shareParticipation);
        });
        if (ndsShare && totalShare) {
          multiplier = ndsShare / totalShare;
        }
      }
      return multiplier;
    }

    const newRowValues =
      fieldName && (fieldValue || fieldValue === '') ? { ...rowValues, [fieldName]: fieldValue } : { ...rowValues };
    const ndsMultiplier = solveNdsMultiplier(formValues);

    console.log('calculateProductRow');

    let fieldOrder = fieldName ? calculateOrder[fieldName] : 0; // Позиция в цепи расчета, для ручного расчета
    if (fieldOrder !== undefined) {
      // ПРЯМОЙ РАСЧЕТ
      if (formValues['_calcDirection'] === Calculation.DIRECT) {
        if (formValues['_calcType'] === Calculation.AUTO) {
          fieldOrder = 0; // Если прямой-автоматический расчет, пересчитываем все поля
        }

        // Стоимость товаров, работ, услуг без косвенных налогов
        if (fieldOrder < calculateOrder['priceWithoutTax']) {
          newRowValues.priceWithoutTax = removeDecZero(
            toFixedFloat(parseToNumber(newRowValues.quantity) * parseToNumber(newRowValues.unitPrice), 2)
          );
        }

        // Размер оборота по реализации
        if (fieldOrder < calculateOrder['turnoverSize']) {
          newRowValues.turnoverSize = removeDecZero(
            toFixedFloat(parseToNumber(newRowValues.priceWithoutTax) + parseToNumber(newRowValues.exciseAmount), 2)
          );
        }

        // НДС- Сумма
        if (fieldOrder < calculateOrder['ndsAmount']) {
          newRowValues.ndsAmount = removeDecZero(
            toFixedFloat(
              (parseToNumber(newRowValues.ndsRate) / 100) * parseToNumber(newRowValues.turnoverSize) * ndsMultiplier,
              2
            )
          );
        }

        // Стоимость товаров, работ, услуг с учетом косвенных налогов
        if (fieldOrder < calculateOrder['priceWithTax']) {
          newRowValues.priceWithTax = removeDecZero(
            toFixedFloat(
              parseToNumber(newRowValues.priceWithoutTax) +
                parseToNumber(newRowValues.exciseAmount) +
                parseToNumber(newRowValues.ndsAmount),
              2
            )
          );
        }

        // ОБРАТНЫЙ РАСЧЕТ
      } else if (formValues['_calcDirection'] === Calculation.REVERSE) {
        console.log('обратный');

        // НДС- Сумма
        newRowValues.ndsAmount = removeDecZero(
          toFixedFloat(
            parseToNumber(newRowValues.priceWithTax) *
              (parseToNumber(newRowValues.ndsRate) / (parseToNumber(newRowValues.ndsRate) + 100)) *
              ndsMultiplier,
            2
          )
        );

        // Размер оборота по реализации
        newRowValues.turnoverSize = removeDecZero(
          toFixedFloat(parseToNumber(newRowValues.priceWithTax) - parseToNumber(newRowValues.ndsAmount), 2)
        );

        // Стоимость товаров, работ, услуг без косвенных налогов
        newRowValues.priceWithoutTax = removeDecZero(
          toFixedFloat(parseToNumber(newRowValues.turnoverSize) - parseToNumber(newRowValues.exciseAmount), 2)
        );

        // Цена (тариф) за единицу
        if (parseToNumber(newRowValues.quantity) === 0) {
          newRowValues.unitPrice = '';
          //TODO вывод ошибки деления на ноль
        } else {
          newRowValues.unitPrice = removeDecZero(
            toFixedFloat(parseToNumber(newRowValues.priceWithoutTax) / parseToNumber(newRowValues.quantity), 2)
          );
        }
      }
    }

    return newRowValues;
  }

  static calculateAllProductRows(formValues: EsfFormValues) {
    if (Array.isArray(formValues.productSet?.products) && formValues.productSet.products.length) {
      return formValues.productSet.products.map((rowValues) => this.calculateProductRow({ formValues, rowValues }));
    }
  }

  static processEsfProduct(productData: any, quantity: number) {
    const result: any = {
      truOriginCode: '',
      description: productData.name,
      tnvedName: productData.productNameInImportDoc,
      unitCode: productData.tnvedCode,
      unitNomenclature: productData.measureUnitCode,
      quantitativeUnitNomenclature: productData.quantitativeUnitNomenclature,
      quantity: quantity,
      unitPrice: productData.unitPrice,
      productDeclaration: productData.manufactureOrImportDocNumber,
      productNumberInDeclaration: productData.productNumberInImportDoc,
      catalogTruId: `${productData.gsvsCode}${productData.physicalLabel ? `[${productData.physicalLabel}]` : ''}${
        productData.productId ? `<${productData.productId}>` : ''
      }(${productData.storeId})`,
    };

    //TODO Добавить поле isWithdrawal в данные продукта на беке, если это не сложная операция

    let truOriginCode: string | number = '';
    switch (productData.originType) {
      case 'UNKNOWN':
        truOriginCode = 5;
        break;
      case 'IMPORT':
        if (productData.isWithdrawal) {
          truOriginCode = 1;
        } else {
          truOriginCode = 2;
        }
        break;
      case 'MANUFACTURE':
        if (productData.isWithdrawal) {
          truOriginCode = 3;
        } else {
          truOriginCode = 4;
        }
        break;
    }

    result.truOriginCode = truOriginCode;
    return result;
  }

  /**
   * проверка ЭСФ на Лизинг
   */
  static isLessorLessee(values: EsfFormValues) {
    const { sellers, customers } = values;
    return !!(
      sellers.length === 1 &&
      sellers[0].statuses?.includes(EsfTxpStatus.LESSOR) &&
      customers[0].statuses?.includes(EsfTxpStatus.LESSEE)
    );
  }

  static isCatalogTruIdEditable(productValues: EsfFormValuesProduct, values: EsfFormValues): boolean {
    const { truOriginCode } = productValues;
    console.log('isCatalogTruIdEditable', String(truOriginCode) === '6' && this.isLessorLessee(values));
    return String(truOriginCode) === '6' && this.isLessorLessee(values);
  }

  ///////////////// Бизнес-логика раздела J /////////////////

  static fillProjectCustomer(project: any, form: FormikProps<EsfFormValues>, locale: LocaleStore) {
    let customerAgentDocNum = '';
    let customerAgentDocDate = '';
    if (project) {
      // that.model.globals.customers_project = _.cloneDeep(project);

      if (Array.isArray(project.contracts)) {
        const mainContract = project.contracts.find((contr: any) => contr.main);
        if (mainContract) {
          customerAgentDocNum = mainContract.num;
          customerAgentDocDate = mainContract.startDate;
        }
      }

      setFieldValueDiffer('customerAgentDocNum', customerAgentDocNum, form);
      setFieldValueDiffer('customerAgentDocDate', customerAgentDocDate, form);

      if (Array.isArray(project.owners)) {
        // diference = project.owners.length - Invoice[table_types.customer] - 1;
        // sign = Math.sign(diference);
        // while (diference !== 0) {
        //   if (sign > 0) {
        //     addBlock(table_types.customer);
        //   } else {
        //     removeBlock(table_types.customer);
        //   }
        //   diference -= sign;
        // }
        const customers: Array<any> = project.owners.map((owner: any) => {
          const customer: EsfFormValues['customers'][number] = {
            // добавляем флаги _withStatuses, _withShare, что получаетель добавлен вместе со статусами и долей
            _taxpayer: { ...(owner.taxpayer || {}), _withStatuses: true, _withShare: true },
            statuses: [],
            shareParticipation: owner.shareParticipation,
          };
          if (project.type === 'ATTORNEY_TRUSTER') {
            customer.statuses?.push(EsfTxpStatus.PRINCIPAL);
          }
          if (project.type === 'OPERATOR_CONTRACTOR') {
            if (project.checkShareParticipant) {
              customer.statuses?.push(EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT);
            } else {
              customer.statuses?.push(EsfTxpStatus.JOINT_ACTIVITY_PARTICIPANT);
            }
          }
          return customer;
        });
        console.log('new customers', customers);
        setFieldValueDiffer('customers', customers, form);
      }
    }
  }

  ///////////////// Бизнес-логика раздела H /////////////////

  /**
   * Создание списка продкутов участников (продавцов, получателей), на основе их доли
   * Если доли не указаны или продавцов/получателей меньше 2х, то список равен undefined
   * Списки создаются на фронте, в базе списки не хранятся
   *
   * @param param: formProps - пропсы формы бланка ЭСФ
   * Создает списки и добавляет их в данные формы
   */
  static updateParticipantsProducts(formProps: FormikProps<EsfFormValues>) {
    const { values } = formProps;

    let isSellerNdsPayer = false;
    if (Array.isArray(values.sellers)) {
      values.sellers.forEach((seller: any) => {
        if (!!seller.certificateSeries) {
          isSellerNdsPayer = true;
        }
      });
    }

    (['sellers', 'customers'] as const).forEach((type) => {
      const participantsProductsName = type === 'sellers' ? 'sellerParticipants' : 'customerParticipants';
      let totalShare = 0;
      if (Array.isArray(values[type])) {
        values[type].forEach((part: any) => {
          totalShare += parseToNumber(part.shareParticipation);
        });
      }
      if (Array.isArray(values[type]) && values[type].length > 1) {
        const participantsProducts = values[type].map((part: any) => {
          let multiplier = undefined;
          const share = parseToNumber(part.shareParticipation);
          if (totalShare && share) {
            multiplier = share / totalShare;
          }
          return {
            tin: part.tin,
            reorganizedTin: part.reorganizedTin,
            productShares: this.solveProdutsShares({
              products: values.productSet?.products || [],
              multiplier,
              isNdsPayer: !!part.certificateSeries,
              isSellerNdsPayer,
              type,
            }),
          };
        });
        console.log(type, participantsProducts);
        setFieldValueDiffer(participantsProductsName, participantsProducts, formProps);
      } else {
        console.log(type, 'undef');
        setFieldValueDiffer(participantsProductsName, undefined, formProps);
      }
    });
  }

  /**
   * Расчет полей продуктов в зависимости от доли
   */
  static solveProdutsShares({
    products,
    multiplier,
    isNdsPayer,
    isSellerNdsPayer,
    type,
  }: {
    products: Array<any>;
    multiplier?: number;
    isNdsPayer: boolean;
    isSellerNdsPayer: boolean;
    type: string;
  }): Array<any> {
    const deniedFields = [
      'description',
      'priceWithoutTax',
      'exciseAmount',
      'turnoverSize',
      'ndsAmount',
      'priceWithTax',
      'additional',
    ];

    return products.map((product: { [key: string]: any }) =>
      Object.fromEntries(
        Object.entries(product)
          .filter((entry) => !deniedFields.includes(entry[0]))
          .map(([key, value]) => {
            let newValue = value;
            if (key === 'quantity') {
              if (multiplier) {
                newValue = removeDecZero(toFixedFloat(parseToNumber(value) * multiplier, 6));
              } else {
                newValue = 0;
              }
            }
            if (key === 'ndsRate') {
              // Для продавца, если не прлательщик НДС, не заполняем ставку
              // Для получателя, если продавец не плательщик НДС, не заполняем ставку
              if ((type === 'seller' && isNdsPayer) || (type === 'customer' && isSellerNdsPayer)) {
                newValue = undefined;
              }
            }
            return [key, newValue];
          })
      )
    );
  }

  ///////////////// Бизнес-логика общая /////////////////

  /**
   * Вычисляет доступен ли бланк для редактирования (в зависимости от режима в котором открыт)
   * @param form - объект формы
   * @returns boolean
   */
  static getFormEditable(form: FormikProps<EsfFormValues>) {
    return form.values['_invoiceStatus'] === EsfStatus.DRAFT && form.values['_mode'] !== EsfMode.show;
  }

  /**
   * проверки НП на блокировки перед обновлением продавца;
   *
   */
  static isTaxpayerBlocked(taxpayer?: Taxpayer) {
    const isEnterprise = ![
      EsfTxpStatus.INDIVIDUAL,
      EsfTxpStatus.LAWYER,
      EsfTxpStatus.BAILIFF,
      EsfTxpStatus.MEDIATOR,
      EsfTxpStatus.NOTARY,
    ].includes(taxpayer?.type as EsfTxpStatus);
    if (
      (taxpayer?.type === EsfTxpStatus.INDIVIDUAL && taxpayer.isBlockedAsIndividual) ||
      (isEnterprise && taxpayer?.isBlockedAsEnterprise)
    ) {
      return true;
    }
    return false;
  }

  /**
   * проверки НП после обновления продавца/получателя;
   *
   * type - 'seller' | 'customer';
   */
  static async taxpayerChecksSellerCustomer(
    type: 'seller' | 'customer',
    tin: string
  ): Promise<{
    _taxpayerStatuses?: any;
    _isSharingParticipant?: boolean | undefined;
    _isRegistered?: any;
  }> {
    const requests = [
      //подгружает статусы НП (блокировки, делегирование, реорганизация)
      queryInvoiceGetTaxpayerStatuses({ tin, type }),
      //подгружает флаг _isSharingParticipant
      queryInvoiceIsSharingParticipant({ tin }),
      //проверка что НП зарегестрирован
      queryCheckRegistered({ iin: tin }),
    ];
    const checks: { _taxpayerStatuses?: any; _isSharingParticipant?: boolean; _isRegistered?: any } = {};
    const res = await Promise.allSettled(requests);
    if (res[0].status === 'fulfilled' && res[0].value.data) {
      checks._taxpayerStatuses = res[0].value?.data;
    }
    if (res[1].status === 'fulfilled' && typeof res[1].value.data === 'boolean') {
      checks._isSharingParticipant = res[1].value?.data;
    }
    if (res[2].status === 'fulfilled' && res[2].value.data?.data) {
      checks._isRegistered = res[2].value.data?.data;
    }
    return checks;
  }

  /**
   * Запрашиваем и Добавляем данные НП для всех поставщиков и получателей: _taxpayer;
   * Заполняем необходимые поля формы из полученых данных;
   * Изменеяет переданное значение values и возвращает его;
   */
  static enrichEsfTaxpayers = async (values: EsfFormValues, locale: LocaleStore) => {
    const taxpayerIds = [];
    if (Array.isArray(values.sellers)) {
      taxpayerIds.push(values.sellers.map((seller) => seller._taxpayer?.tin));
    }
    if (Array.isArray(values.customers)) {
      taxpayerIds.push(values.customers.map((customer) => customer._taxpayer?.tin));
    }

    const list = Array.from(new Set(taxpayerIds.flat()));

    if (list.length) {
      try {
        const res = await queryTaxpayerAutocompleteBins({ list: JSON.stringify(list) });
        if (Array.isArray(res.data)) {
          if (Array.isArray(values.sellers)) {
            values.sellers.forEach((seller) => {
              const txp = res.data.find((txp: any) => txp.tin === seller._taxpayer?.tin);
              if (txp) {
                seller._taxpayer = txp;
              }
            });
          }
          if (Array.isArray(values.customers)) {
            values.customers.forEach((customer) => {
              const txp = res.data.find((txp: any) => txp.tin === customer._taxpayer?.tin);
              if (txp) {
                customer._taxpayer = txp;
              }
            });
          }
        }
      } catch (error) {
        console.log(error);
      }

      const { chooseByKey } = locale;

      // # Обновляем имена, адреса, когд, свидетельства НДС всех поставщиков
      if (Array.isArray(values.sellers)) {
        values.sellers.forEach((seller) => {
          seller.kogd = seller._taxpayer?.kogd;
          seller.address = chooseByKey(seller._taxpayer, 'address');
          if (seller.statuses?.includes(EsfTxpStatus.INDIVIDUAL)) {
            seller.name = resolveTaxpayerFio(seller._taxpayer, locale);
          } else {
            seller.name = resolveTaxpayerNameOrFio(seller._taxpayer, locale);
          }
          if (seller._taxpayer?.headOffice?.resident) {
            seller.certificateSeries = seller._taxpayer?.headOffice?.certificateSeries;
            seller.certificateNum = seller._taxpayer?.headOffice?.certificateNum;
          } else {
            seller.certificateSeries = seller._taxpayer?.certificateSeries;
            seller.certificateNum = seller._taxpayer?.certificateNum;
          }
        });
      }

      // # Обновляем имена, адреса всех получателей
      if (Array.isArray(values.customers)) {
        values.customers.forEach((customer) => {
          customer.address = chooseByKey(customer._taxpayer, 'address');
          if (customer.statuses?.includes(EsfTxpStatus.INDIVIDUAL)) {
            customer.name = resolveTaxpayerFio(customer._taxpayer, locale);
          } else {
            customer.name = resolveTaxpayerNameOrFio(customer._taxpayer, locale);
          }
        });
      }
    }

    return values;
  };
}
