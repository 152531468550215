import { DocumentLink, VirtualChainLink } from 'common/components/links';
import { AppMode } from 'common/constants';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { RowMark, TableColumn } from 'ui/common';
import { AssuranceStatus, DOC_ACTIVITY } from '../helpers';

function createDocumentLink({ regNumber, appMode }: { regNumber: string; appMode?: AppMode }) {
  return appMode === AppMode.ARM ? (
    <DocumentLink regNumber={regNumber} linkType="PDF" appMode={appMode}>
      {regNumber}
    </DocumentLink>
  ) : (
    regNumber
  );
}

export const getProductsColumns = ({
  locale: { loc },
  appMode,
  data,
}: {
  locale: LocaleStore;
  appMode?: AppMode;
  data: any;
}): Array<TableColumn> => [
  {
    name: 'assuranceCorridor',
    label: loc('assurance.assuranceCorridor'),
    getContent: (row) => <RowMark color={row.assuranceCorridor} />,
    getTitle: (row) => loc(`assurance.${row.assuranceCorridor}`),
    style: {
      width: '100px',
      textAlign: 'center',
      paddingLeft: '0',
    },
    exclude: appMode === AppMode.EAEC,
  },
  {
    name: 'sntProductNumber',
    label: loc('assurance.sntProductNumber'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceProductNumber',
    label: loc('assurance.invoiceProductNumber'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceProductName',
    label: loc('common.labels.productName'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'quantity',
    label: loc('common.labels.quantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'manufactureOrImportDocumentNumber',
    label: loc('common.labels.manufactureOrImportDocNumber'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.manufactureOrImportDocumentNumber,
      }),
    style: {
      width: '400px',
    },
  },
  {
    name: 'productNumberInImportDoc',
    label: loc('common.labels.productNumberInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'sntImport',
    label: loc('common.sntImport'),
    style: {
      width: '200px',
    },
    exclude: appMode === AppMode.EAEC,
  },
  {
    name: 'originDocumentNumber',
    label: loc('assurance.originDocumentNumber'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.uFormNumber,
      }),
    style: {
      width: '400px',
    },
    exclude: appMode === AppMode.EAEC,
  },
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    getContent: (row) =>
      appMode === AppMode.ARM ? (
        <VirtualChainLink
          // to={`${PATH.ARM}${PATH.VIRTUAL_LABEL_CHAIN}?id=${row.productId}&documentNumber=${data?.passport?.sntRegistrationNumber}`}
          productId={row.productId}
          documentRegNumber={data?.passport?.sntRegistrationNumber}
          newTab
        >
          {row.productId}
        </VirtualChainLink>
      ) : (
        row.productId
      ),
    style: {
      width: '200px',
    },
    exclude: appMode === AppMode.EAEC,
  },
  {
    name: 'sntReturn',
    label: loc('common.sntReturn'),
    style: {
      width: '200px',
    },
    exclude: appMode === AppMode.EAEC,
  },
];

export const getSntColumns = ({
  locale: { loc },
  appMode,
  passport,
}: {
  locale: LocaleStore;
  appMode: AppMode;
  passport: any;
}): Array<TableColumn> => [
  {
    name: 'sntRegistrationNumber',
    label: loc('assurance.sntRegistrationNumber'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.sntRegistrationNumber,
      }),
    style: {
      width: '400px',
    },
  },
  {
    name: 'sntDate',
    label: loc('assurance.sntDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'sntShippingDate',
    label: loc('assurance.shippingDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'sntStatus',
    label: loc('common.snt.status'),
    getContent: (row) => loc(`assurance.activeStatus.${DOC_ACTIVITY[passport.assuranceStatus as AssuranceStatus]}`),
    style: {
      width: '200px',
    },
  },
];

export const getEsfColumns = ({
  locale: { loc },
  appMode,
  passport,
}: {
  locale: LocaleStore;
  appMode: AppMode;
  passport: any;
}): Array<TableColumn> => [
  {
    name: 'invoiceRegistrationNumber',
    label: loc('assurance.invoiceRegistrationNumber'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.invoiceRegistrationNumber,
      }),
    style: {
      width: '400px',
    },
  },
  {
    name: 'invoiceDate',
    label: loc('assurance.invoiceDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceTurnoverDate',
    label: loc('assurance.invoiceTurnoverDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceStatus',
    label: loc('common.esf.status'),
    getContent: (row) => loc(`assurance.activeStatus.${DOC_ACTIVITY[passport.assuranceStatus as AssuranceStatus]}`),
    style: {
      width: '200px',
    },
  },
];

export const getPackingColumns = ({
  locale: { loc },
  appMode,
}: {
  locale: LocaleStore;
  appMode: AppMode;
}): Array<TableColumn> => [
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceProductName',
    label: loc('common.labels.productName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('assurance.PackingForm'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.registrationNumber,
      }),
    style: {
      width: '150px',
    },
  },
  {
    name: 'date',
    label: loc('assurance.PackingDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
];

export const getUnpackingColumns = ({
  locale: { loc },
  appMode,
}: {
  locale: LocaleStore;
  appMode: AppMode;
}): Array<TableColumn> => [
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceProductName',
    label: loc('common.labels.productName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('assurance.UnpackingForm'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.registrationNumber,
      }),
    style: {
      width: '150px',
    },
  },
  {
    name: 'date',
    label: loc('assurance.UnpackingDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
];

export const getConversionColumns = ({
  locale: { loc },
  appMode,
}: {
  locale: LocaleStore;
  appMode: AppMode;
}): Array<TableColumn> => [
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceProductName',
    label: loc('common.labels.productName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'sourceMeasureUnitName',
    label: loc('assurance.sourceMeasureUnit'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'sourceQuantity',
    label: loc('assurance.sourceQuantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'targetMeasureUnitName',
    label: loc('assurance.targetMeasureUnit'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'targetQuantity',
    label: loc('assurance.targetQuantity'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('assurance.ConversionForm'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.registrationNumber,
      }),
    style: {
      width: '150px',
    },
  },
  {
    name: 'date',
    label: loc('assurance.ConversionDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
];

export const getEditingColumns = ({
  locale: { loc },
  appMode,
}: {
  locale: LocaleStore;
  appMode: AppMode;
}): Array<TableColumn> => [
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'sourceProductName',
    label: loc('assurance.sourceProductName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'targetProductName',
    label: loc('assurance.targetProductName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('assurance.EditingForm'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.registrationNumber,
      }),
    style: {
      width: '150px',
    },
  },
  {
    name: 'date',
    label: loc('assurance.EditingDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
];

export const getResortingColumns = ({
  locale: { loc },
  appMode,
}: {
  locale: LocaleStore;
  appMode: AppMode;
}): Array<TableColumn> => [
  {
    name: 'productId',
    label: loc('common.labels.prodId'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'sourceTnvedCode',
    label: loc('assurance.sourceTnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'sourceProductName',
    label: loc('assurance.sourceProductName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'targetTnvedCode',
    label: loc('assurance.targetTnvedCode'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'targetProductName',
    label: loc('assurance.targetProductName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'productNameInImportDoc',
    label: loc('common.labels.productNameInImportDoc'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'measureUnitName',
    label: loc('common.labels.measureUnitName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'registrationNumber',
    label: loc('assurance.ResortingForm'),
    getContent: (row) =>
      createDocumentLink({
        appMode,
        regNumber: row.registrationNumber,
      }),
    style: {
      width: '150px',
    },
  },
  {
    name: 'date',
    label: loc('assurance.ResortingDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'tin',
    label: loc('common.labels.tin'),
    style: {
      width: '200px',
    },
  },
];
