import React from 'react';
import clsx from 'clsx';
import s from './ResetPasswordForm.module.scss';
import { useLocale } from 'services/l10n';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { ButtonT2, FormFieldT2, FormInputT2 } from 'ui';
import { Link } from 'react-router-dom';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import { PATH } from 'routing/paths';

const initialValues = {};

const schema = yup.object({});

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
};

export const ResetPasswordForm: React.FC<Props> = ({ className, onSubmit }) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      const res = await onSubmit({
        login: values.login.trim(),
        email: values.email.trim(),
      });
      ToastService.showSuccess(solveErrorMessage(res));
    } catch (error) {
      const message = solveErrorMessage(error);
      ToastService.showError(message);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {({ isSubmitting }) => {
        return (
          <Form className={clsx(className, s.form)}>
            <FormFieldT2 name="login" errorVariant="inside-icon">
              <FormInputT2 className={s.control} placeholder={loc('common.main.loginIin')} />
            </FormFieldT2>

            <FormFieldT2 name="email" errorVariant="inside-icon">
              <FormInputT2 className={s.control} placeholder={loc('common.labels.email')} />
            </FormFieldT2>

            <ButtonT2 className={s.button} disabled={isSubmitting} type="submit" size="large" fullWidth>
              {loc('common.button.send')}
            </ButtonT2>
            <div className={s.links}>
              <Link to={PATH.CHANGE_EMAIL}>{loc('reset.dontknow')}</Link>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
