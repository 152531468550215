import { convertToFormData, DataMutationFunction, httpClient } from 'rest';

export const mutationAdminSROUpdate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(body);

  const res = await httpClient.post('/admin/dict/smallRetailOutlet/update', formData);
  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
