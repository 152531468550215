import { clearEmptyFields } from 'common/utils';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryCheckRegisteredTypes: DataQueryFunction = async (variables) => {
  const { iin, tin } = variables || {};
  const res = await httpClient.get(`/checkRegisteredTypes`, { params: clearEmptyFields({ iin, tin }) });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
