import React from 'react';
import clsx from 'clsx';
import s from './PassportActionLogModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { Button, Loader, Table, TableColumn } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { queryAdminAssuranceActions, queryEaecAssuranceActions } from '../api';
import { CodeValueCountry } from 'common/components';
import { useAppMode } from 'common/hooks';
import { AppMode } from 'common/constants';

const getRowId = (row: any) => row?.id;

type Props = ModalChildrenProps & {
  className?: string;
  passportId: string;
};

const getColumns = ({ loc, choose }: LocaleStore): TableColumn[] => [
  {
    name: 'userId',
    label: loc('common.labels.logIn'),
    style: {
      width: '15%',
    },
  },
  {
    name: 'country',
    label: loc('common.labels.country'),
    getContent: (row) => <CodeValueCountry code={row.countryCode} />,
    style: {
      width: '15%',
    },
  },
  {
    name: 'ipAddresses',
    label: loc('common.labels.ipAddress'),
    style: {
      width: '17%',
    },
  },
  {
    name: 'date',
    label: loc('common.labels.date'),
    style: {
      width: '17%',
    },
  },
  {
    name: 'actionType',
    label: loc('assurance.action'),
    getContent: (row) => row.actionType && loc(`assurance.action.${row.actionType}`),
    style: {
      width: '36%',
    },
  },
];

export const PassportActionLogModal: React.FC<Props> = ({ className, passportId, onClose }) => {
  const locale = useLocale();
  const { loc } = locale;

  const appMode = useAppMode();

  const { data } = useDataQuery(appMode === AppMode.EAEC ? queryEaecAssuranceActions : queryAdminAssuranceActions, {
    passportId,
  });

  const columns = getColumns(locale);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('assurance.actionLog')}</ModalHeader>
      <ModalContent className={s.content}>
        {!Array.isArray(data) ? <Loader /> : <Table columns={columns} rows={data} getRowId={getRowId} />}
      </ModalContent>
      <ModalFooter>
        <Button size="small" onClick={onClose}>
          {loc('common.button.close')}
        </Button>
      </ModalFooter>
    </div>
  );
};
