import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './PassportEaecPage.module.scss';
import { Button, Loader, PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { queryEaecAssuranceReport, queryEaecAssuranceId } from '../api';
import { PassportDocument } from '../passport/PassportDocument';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { AppMode } from 'common/constants';
import { useHistory, useParams } from 'react-router';
import { PATH } from 'routing/paths';
import { PassportComments } from '../passport-comments/PassportComments';
import { Modal } from 'services/modal';
import { PassportActionLogModal } from '../passport-action-log/PassportActionLogModal';

type Props = {};

export const PassportEaecPage: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const { id: paramsId } = useParams<{ id: string }>();
  const history = useHistory();

  const [actionLogModal, setActionLogModal] = useState<boolean>(false);

  const { data, refetch, error } = useDataQuery(queryEaecAssuranceId, {
    id: paramsId,
  });

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  const downloadPDF = async () => {
    try {
      await queryEaecAssuranceReport({
        ids: [paramsId],
        format: 'PDF',
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const goToSearchPage = () => {
    history.push(PATH.EAEC_PASSPORT_VERIFY);
  };

  return (
    <div className={clsx('container', s.wrapper)}>
      <PageTitle>{loc('assurance.pasport')}</PageTitle>
      <BackButton onClick={goToSearchPage} />
      {!data ? (
        <Loader />
      ) : (
        <div className={s.inner}>
          <PassportDocument data={data} reloadData={refetch} appMode={AppMode.EAEC} />
          <PassportComments passportId={paramsId} />
          <div className={s.actionButtons}>
            <Button variant="secondary" size="small" onClick={() => setActionLogModal(true)}>
              {loc('assurance.actionLog')}
            </Button>
            <Button size="small" onClick={() => downloadPDF()}>
              {loc('common.button.pdf')}
            </Button>
            <Button variant="secondary" size="small" onClick={goToSearchPage}>
              {loc('common.button.close')}
            </Button>
          </div>
          <Modal isOpen={actionLogModal} onClose={() => setActionLogModal(false)} scrollLock={false}>
            <PassportActionLogModal passportId={data.passportId} />
          </Modal>
        </div>
      )}
    </div>
  );
};
