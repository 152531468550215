import React, { Fragment, PropsWithChildren, ReactNode } from 'react';
import clsx from 'clsx';
import s from '../PassportDocument.module.scss';

type Props = PropsWithChildren<{
  className?: string;
  span?: number;
  spanRows?: number;
  label?: ReactNode;
  value?: ReactNode;
  noValue?: boolean;
  /**
   * BorderBottom
   */
  bb?: boolean;
  /**
   * BorderTop
   */
  bt?: boolean;
  /**
   * BorderLeft
   */
  bl?: boolean;
  /**
   * BorderRight
   */
  br?: boolean;
  textAlign?: 'left' | 'right';
}>;

/**
 * Passport Attachments Table Cell
 */
export const PACell: React.FC<Props> = ({
  className,
  span = 1,
  spanRows = 1,
  children,
  label,
  value,
  noValue,
  bb,
  bt = true,
  bl = true,
  br,
  textAlign = 'left',
}) => {
  return (
    <div
      className={clsx(className, s.attCell, { [s.bb]: bb, [s.bt]: bt, [s.bl]: bl, [s.br]: br })}
      style={{ gridColumn: `span ${span}`, gridRow: `span ${spanRows}`, textAlign }}
    >
      {children ? (
        children
      ) : (
        <Fragment>
          <span className={s.attCellLabel}>{typeof label === 'function' ? label() : label} </span>
          {!noValue && <span className={s.attCellValue}>{typeof value === 'function' ? value() : value || '-'}</span>}
        </Fragment>
      )}
    </div>
  );
};
