export const debounce = <F extends Function>(func: F, timeout = 300): F => {
  let timer: ReturnType<typeof setTimeout>;
  return function (this: any) {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, timeout);
  } as unknown as F;
};
