import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './TaxpayerMainPage.module.scss';
import { Accordion, PageTitleT2, SvgIcon } from 'ui';
import { observer } from 'mobx-react-lite';
import { ReactComponent as EsfIcon } from 'assets/icons/esf.svg';
import { ReactComponent as SntIcon } from 'assets/icons/snt.svg';
import { ReactComponent as AwpIcon } from 'assets/icons/awp.svg';
import { ReactComponent as EcontractIcon } from 'assets/icons/econtract.svg';
import { PATH } from 'routing/paths';
import { EsfCounter } from './components/EsfCounter';
import { getLegasyUrl } from 'common/helpers';
import { AppLink } from 'common/components';
import { EsfBoard } from './boards/esf-board/EsfBoard';
import { SntBoard } from './boards/snt-board/SntBoard';
import { BoardParams } from './boards/boardTypes';
import { BioWarning } from './components/BioWarning';
import { AwpBoard } from './boards/awp-board/AwpBoard';
import { EcontractBoard } from './boards/econtract-board/EcontractBoard';
import { useStore } from 'common/hooks';
import { Feedback } from './feedback/Feedback';

type Props = {
  className?: string;
};

/**
 * Главная страница кабинета НП (после авторизации)
 */
export const TaxpayerMainPage: React.FC<Props> = observer(({ className }) => {
  const { locale, user } = useStore();
  const { loc } = locale;

  const boards: Array<BoardParams> = [
    {
      name: 'esf',
      title: loc('common.esfFullm'),
      className: s.boardEsf,
      icon: EsfIcon,
      link: PATH.ESF_JOURNAL,
      component: EsfBoard,
    },
    {
      name: 'snt',
      title: loc('common.sntFull'),
      className: s.boardSnt,
      icon: SntIcon,
      link: PATH.SNT_JOURNAL,
      component: SntBoard,
    },
    {
      name: 'awp',
      title: loc('common.awp'),
      className: s.boardAwp,
      icon: AwpIcon,
      legasylink: getLegasyUrl('/awp-journal'),
      component: AwpBoard,
    },
    {
      name: 'econtract',
      title: loc('common.econtract'),
      className: s.boardEcontract,
      icon: EcontractIcon,
      legasylink: getLegasyUrl('/e-contract'),
      component: EcontractBoard,
    },
  ];

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitleT2>{loc('common.main.main')}</PageTitleT2>
      <div className={s.linksRow}>
        <a className={s.oldVersionLink} href={'/esf-web/#!/home'}>
          {loc('common.oldVersion')}
        </a>
      </div>
      <div className={s.topRow}>
        {/* <ButtonT2 iconComponent={PlusIcon} className={s.createDoc}>
          {loc('common.button.createDoc')}
        </ButtonT2> */}
        <div className={s.grid}>
          <div className={s.boards}>
            {boards.map((board, index) => {
              const BoardComponent = board.component;
              return (
                <Accordion
                  key={board.name}
                  className={clsx(s.board, board.className)}
                  headerClassName={s.boardHeader}
                  iconClassName={s.boardArrowIcon}
                  initialOpened={user.profileSelectModal === false && board.name === 'esf'} // после выбора профиля открывает панел ЭСФ
                  animated
                  renderHeader={() => (
                    <Fragment>
                      <AppLink to={board.link} legasyTo={board.legasylink}>
                        <span className={s.boardIcon}>
                          <SvgIcon component={board.icon} />
                        </span>
                      </AppLink>
                      <h2>{board.title}</h2>
                    </Fragment>
                  )}
                >
                  {(opened, contentHeightUpdate) => (
                    <BoardComponent
                      key={board.name + user.profileSelectModal} // предотвращяет загрузку журнала до выбора профиля
                      boardParams={board}
                      opened={opened}
                      onLoad={contentHeightUpdate}
                    />
                  )}
                </Accordion>
              );
            })}
          </div>
          <div className={s.aside}>
            <EsfCounter />
            <BioWarning className={s.bioWarning} />
          </div>
        </div>
      </div>
      <Feedback />
    </div>
  );
});
