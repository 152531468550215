import clsx from 'clsx';
import React, { Fragment } from 'react';
import { queryCountries } from 'rest';
import { useLocale } from 'services/l10n';
import { useDictionaryLoad } from './useDictionaryLoad';
import s from './CodeValueCountry.module.scss';
import { CountryFlag } from 'ui';

let codeMap: any = undefined;

export const getCountryCode = (countryData: any) => countryData?.alphaCode2;

async function fetchDictionary() {
  try {
    const res = await queryCountries();
    if (Array.isArray(res.data) && !codeMap) {
      codeMap = Object.fromEntries(res.data.map((item: any) => [String(getCountryCode(item)), item]));
      console.log('created countryCodeMap');
    }
    return res;
  } catch (error) {
    console.error(error);
  }
}

type Props = {
  className?: string;
  code: string;
  format?: 'name' | 'codeWithName';
  withFlag?: boolean;
  capitalize?: boolean;
};

export const CodeValueCountry: React.FC<Props> = ({
  code,
  format = 'name',
  withFlag,
  className,
  capitalize = true,
}) => {
  const locale = useLocale();
  const { chooseByKey } = locale;

  const { show } = useDictionaryLoad(codeMap, fetchDictionary);

  if (!code || !show) {
    return null;
  }

  const data = codeMap?.[code];

  if (!data) {
    return <Fragment>{String(code)}</Fragment>;
  }

  const formatName = (data: any, capitalize: boolean) => {
    const name = chooseByKey(data, 'name');
    return capitalize ? name.toLowerCase() : name;
  };

  return (
    <span className={clsx(className, { [s.withFlag]: withFlag, [s.capitalize]: capitalize })}>
      {withFlag && <CountryFlag className={s.flag} countryCode={code} />}
      {format === 'name' ? formatName(data, capitalize) : `(${getCountryCode(data)}) - ${formatName(data, capitalize)}`}
    </span>
  );
};
