import { contentYesNo } from 'common/components';
import { getNotSelectedOptions } from 'common/helpers/options';
import { LocaleStore } from 'services/l10n';

export enum AdminMessageGroup {
  ENTERPRISE = 'ENTERPRISE',
  INDIVIDUAL = 'INDIVIDUAL',
  ENTREPRENEUR = 'ENTREPRENEUR',
}

export const getGroupsOptions = ({ locale, withNotSelected }: { locale: LocaleStore; withNotSelected?: boolean }) => {
  const { loc } = locale;
  return [
    ...(withNotSelected ? getNotSelectedOptions(locale) : []),
    {
      label: loc('adminMessages.ENTERPRISE'),
      value: AdminMessageGroup.ENTERPRISE,
    },
    {
      label: loc('adminMessages.INDIVIDUAL'),
      value: AdminMessageGroup.INDIVIDUAL,
    },
    {
      label: loc('adminMessages.ENTREPRENEUR'),
      value: AdminMessageGroup.ENTREPRENEUR,
    },
  ];
};

export const getThemeOptions = ({ locale }: { locale: LocaleStore }) => {
  const { loc } = locale;
  return [
    ...getNotSelectedOptions(locale),
    {
      label: loc('adminMessages.NOTIFICATION'),
      value: 'NOTIFICATION',
    },
    {
      label: loc('adminMessages.BLOCKING'),
      value: 'BLOCKING',
    },
    {
      label: loc('adminMessages.UNBLOCKING'),
      value: 'UNBLOCKING',
    },
    {
      label: loc('adminMessages.OTHER'),
      value: 'OTHER',
    },
  ];
};

export enum MessageReadStatus {
  READ = 'READ',
  NOT_READ = 'NOT_READ',
  NOT_REQUIRED_TO_READ = 'NOT_REQUIRED_TO_READ',
}

export const getIsReadStatusOptions = ({ locale }: { locale: LocaleStore }) => {
  const { loc } = locale;
  return [
    ...getNotSelectedOptions(locale),
    {
      label: loc('common.yes'),
      value: MessageReadStatus.READ,
    },
    {
      label: loc('common.no'),
      value: MessageReadStatus.NOT_READ,
    },
    {
      label: loc('adminMessages.confirmNotRequired'),
      value: MessageReadStatus.NOT_REQUIRED_TO_READ,
    },
  ];
};

//Не используется
export const solveGroupArray = (data: any, locale: LocaleStore): string => {
  const { loc } = locale;
  if (Array.isArray(data.group)) {
    if (data.group.length === 3) {
      return loc('adminMessages.ALL');
    } else {
      return data.group.map((g: string) => loc(`adminMessages.${g}`)).join(', ');
    }
  }
  return '';
};

export const solveIsReadStatus = (data: any, locale: LocaleStore): string => {
  const { loc } = locale;
  return data.requiredToRead ? loc(contentYesNo(data.isRead)) : loc(`adminMessages.confirmNotRequired`);
};
