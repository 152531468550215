import { DataQueryFunction, httpClient } from 'rest';

export const queryAdminRiskyTaxpayerHistory: DataQueryFunction = async ({ tin }: any) => {
  const res = await httpClient.get(`/admin/riskyTaxpayer/arm/history/${tin}`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
