import React from 'react';
import clsx from 'clsx';
import s from './PageFooter.module.scss';

type Props = {
  className?: string;
};

export const PageFooter: React.FC<Props> = ({ className, children }) => {
  return <div className={clsx(className, s.wrapper, 'container')}>{children}</div>;
};
