import React from 'react';
import './Select.scss';
import { SelectMUISingleValue } from './SelectMUISingleValue';
import { Select } from './Select';
import { SelectProps } from './SelectProps';
import clsx from 'clsx';

const defaultgetOptionValue = (opt: any) => opt.value;

export const SelectMUI: React.FC<SelectProps> = ({
  className,
  label,
  getOptionValue = defaultgetOptionValue,
  ...rest
}) => {
  return (
    <div className={clsx(className, 'select-mui-container')}>
      <Select
        {...rest}
        className={'select-mui'}
        getOptionValue={getOptionValue}
        components={{
          SingleValue: (props) => <SelectMUISingleValue {...props} getOptionValue={getOptionValue} />,
        }}
      />
      <label className="select-mui-label">{label}</label>
    </div>
  );
};
