import clsx from 'clsx';
import React from 'react';
import { UITheme } from 'ui/common/types';
import s from './Heading.module.scss';

type Props = {
  className?: string;
  tag?: 'h1' | 'h2' | 'h3';
  noMargin?: boolean;
  theme?: UITheme;
  /**
   * Варианты размера независмо от тега
   */
  size?: '20' | '24' | '30';
};

export const Heading: React.FC<Props> = ({ className, tag, children, noMargin, theme = 'T1' }) => {
  const H = tag || 'h1';
  return (
    <H
      className={clsx(className, s.heading, s[theme], {
        [s.noMargin]: noMargin,
      })}
    >
      {children}
    </H>
  );
};
