import { DataMutationFunction } from '../../../types';
import { httpClient } from '../../../httpClient';
import { convertToFormData } from '../../../helpers';
import { clearEmptyFields } from 'common/utils';

export const mutationInvoiceCreate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('/invoice/create', formData);
  if (res?.data?.success === false || res?.data?.errors) {
    throw res;
  } else {
    return res;
  }
};
