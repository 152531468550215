import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { DocumentLinkParams, DocumentTargetUrl, getDocumentTargetUrl } from './helpers';
import { PATH } from 'routing/paths';
import { stringify } from 'query-string';

type Props = {
  className?: string;
} & DocumentLinkParams;

/**
 * Универсальная ссылка на скачивание документа (pdf, xls и тд)
 *
 */
export const DocumentLink: React.FC<Props> = ({
  className,
  children,
  linkType,
  id,
  docType,
  regNumber,
  appMode,
  newTab = true,
}) => {
  // если уже есть id документа, то прямую ссылку можно сформировать сразу и запрос не нужен
  let directUrl: DocumentTargetUrl = getDocumentTargetUrl({
    docType,
    linkType,
    id,
    regNumber,
    appMode,
  });

  return directUrl.url ? (
    directUrl.outsideLink ? (
      <a
        className={clsx(className)}
        href={directUrl.url}
        target={newTab ? '_blank' : undefined}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link
        className={clsx(className)}
        to={directUrl.url}
        target={newTab ? '_blank' : undefined}
        rel="noopener noreferrer"
      >
        {children}
      </Link>
    )
  ) : (
    <Link
      className={clsx(className)}
      to={{
        pathname: PATH.DOCUMENT_LINK,
        search: stringify({
          regNumber,
          linkType,
          appMode,
        }),
      }}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </Link>
  );
};
