import { action, computed, makeObservable, observable } from 'mobx';
import { AgreementsData, DidData, RegSelectedSubTypes, RegSelectedTypes, RegStep, RegType } from './types';
import { LocaleStore } from 'services/l10n';

export class RegistrationStore {
  currentStep: RegStep;
  selectedTypes: RegSelectedTypes = {};
  selectedSubTypes: RegSelectedSubTypes = {};
  agreements: AgreementsData = {};
  /**
   * Регистрационные данные пользователя (ИИН, ФИО, телефон ...)
   */
  regData: any;
  /**
   * Данные от digital ID
   */
  did?: DidData;

  /**
   * Для регистрации нерезидента по сертификату
   */
  nonResident: boolean = false;

  /**
   * Для регистрации лиц с органиченными возможностями
   */
  biometricsNotRequired: boolean = false;

  /**
   * Данные НП из сертификата
   */
  taxpayerFromCertificate: any;

  constructor(initialStep: RegStep, biometricsNotRequired: boolean) {
    makeObservable(this, {
      currentStep: observable,
      selectedTypes: observable,
      selectedTypesArray: computed,
      selectedSubTypes: observable,
      nonResident: observable,
      registrationByCertificate: computed,
      taxpayerFromCertificate: observable,
      regData: observable,
      agreements: observable,
      did: observable,
      setCurrentStep: action,
      setSelectedTypes: action,
      setSelectedSubTypes: action,
      setNonResident: action,
      setTaxpayerFromCertificate: action,
      setRegData: action,
      setDid: action,
    });
    this.currentStep = initialStep;
    this.biometricsNotRequired = biometricsNotRequired;
  }

  get selectedTypesArray() {
    return (Object.keys(this.selectedTypes) as RegType[]).filter((type) => this.selectedTypes[type]);
  }

  get registrationByCertificate() {
    return this.nonResident || this.biometricsNotRequired;
  }

  setCurrentStep(step: RegStep) {
    this.currentStep = step;
  }

  setSelectedTypes(types: RegSelectedTypes | ((state: RegSelectedTypes) => RegSelectedTypes)) {
    this.selectedTypes = typeof types === 'function' ? types(this.selectedTypes) : types;
  }

  setSelectedSubTypes(subTypes: RegSelectedSubTypes | ((state: RegSelectedSubTypes) => RegSelectedSubTypes)) {
    this.selectedSubTypes = typeof subTypes === 'function' ? subTypes(this.selectedSubTypes) : subTypes;
  }

  setNonResident(value: boolean) {
    this.nonResident = value;
  }

  setTaxpayerFromCertificate(value: boolean) {
    this.taxpayerFromCertificate = value;
  }

  setRegData(data: any) {
    this.regData = data;
  }

  setAgreement(type: RegType, data: any | ((data: any) => any)) {
    this.agreements[type] = typeof data === 'function' ? data(this.agreements[type] || {}) : data;
  }

  setDid(data: any) {
    this.did = data;
  }

  getAddressFromDid(type: RegType, locale: LocaleStore) {
    const privateAddressNames: { [key in RegType]?: string } = {
      [RegType.ENTREPRENEUR]: 'INDIVIDUAL_ENTREPRENEUR',
      [RegType.LAWYER]: 'LAWYER',
      [RegType.BAILIFF]: 'BAILIFF',
      [RegType.MEDIATOR]: 'MEDIATOR',
      [RegType.NOTARY]: 'NOTARY',
    };

    let result = locale.chooseByKey(this.did, 'individualAddress');

    if (privateAddressNames[type] && this.did?.addresses && Array.isArray(this.did?.addresses)) {
      this.did.addresses.forEach((address) => {
        if (address.type === privateAddressNames[type]) {
          result = locale.chooseByKey(address, 'address');
        }
      });
    }

    return result;
  }
}
