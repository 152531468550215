import React from 'react';
import clsx from 'clsx';
import s from './TaxpayerLayoutHeader.module.scss';
import { UserInfoT2 } from '../user-info';
import { LangToggleT2 } from 'common/components/lang-toggle';
import { LogButton } from 'common/components/log-button';
import { Badge, ButtonIcon } from 'ui';
import { ReactComponent as Envelope } from 'assets/icons/journal-buttons/envelope.svg';
import { ReactComponent as Bell } from 'assets/icons/journal-buttons/bell.svg';
import { ReactComponent as IdCard } from 'assets/icons/journal-buttons/id-card.svg';
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { AppLink } from 'common/components';
import { getLegasyUrl } from 'common/helpers';
import { MessagesCounterProvider } from 'features/messages';
import { NotificationsCounterProvider } from 'features/notifications';
import { observer } from 'mobx-react-lite';
import { useStore } from 'common/hooks';

type Props = {
  className?: string;
};

export const TaxpayerLayoutHeader: React.FC<Props> = observer(({ className }) => {
  const { locale, userChecks } = useStore();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      {!!userChecks.armInvite && (
        <ButtonIcon
          className={clsx(s.armInvite, s.button)}
          iconComponent={KeyIcon}
          iconClassName={clsx(s.buttonIcon, s.armInviteIcon)}
          theme="T2"
          title={loc('adminInvite.titleArm')}
          variant="tertiary"
          onClick={userChecks.showArmInvite}
        />
      )}
      <Link className={s.armLink} to={PATH.ARM}>
        <ButtonIcon
          className={s.button}
          iconComponent={IdCard}
          iconClassName={s.buttonIcon}
          // onClick={onClick}
          theme="T2"
          title={loc('common.arm')}
          variant="tertiary"
        />
      </Link>

      <Link className={s.buttonlink} to={PATH.MESSAGES}>
        <MessagesCounterProvider>
          {(count) => (
            <ButtonIcon
              className={s.button}
              iconComponent={Envelope}
              iconClassName={s.buttonIcon}
              theme="T2"
              title={loc('c.messages.title')}
              variant="tertiary"
            >
              {!!count && <Badge className={s.badge} number={count} />}
            </ButtonIcon>
          )}
        </MessagesCounterProvider>
      </Link>

      <AppLink className={s.buttonlink} legasyTo={getLegasyUrl('/notifications')}>
        <NotificationsCounterProvider>
          {(count) => (
            <ButtonIcon
              className={s.button}
              iconComponent={Bell}
              iconClassName={s.buttonIcon}
              theme="T2"
              title={loc('c.notify.title')}
              variant="tertiary"
            >
              {!!count && <Badge className={s.badge} number={count} />}
            </ButtonIcon>
          )}
        </NotificationsCounterProvider>
      </AppLink>

      <UserInfoT2 className={s.userInfo} />
      <LangToggleT2 className={s.langToggle} />
      <LogButton action="logout" />
    </div>
  );
});
