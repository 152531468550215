import { LocaleStore } from 'services/l10n';
import { getUserAccessAddInfo } from './helpers';
import { TableColumn } from 'ui';

export const getUserAccessColumns = ({ locale }: { locale: LocaleStore }): Array<TableColumn> => {
  const { loc } = locale;
  return [
    {
      name: 'login',
      label: loc('common.labels.login'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'tin',
      label: loc('common.labels.bin'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'userFullName',
      label: loc('common.labels.fio'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'ogdCode',
      label: loc('common.labels.ogdCode'),
      style: {
        width: '150px',
      },
    },
    {
      name: 'ogdName',
      label: loc('common.labels.ogd'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'ipAddressesAsString',
      label: loc('common.labels.ipAddress'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'moduleType',
      label: loc('common.labels.eventModule'),
      getContent: (row: any) => (row.moduleType ? loc(`userAccess.moduleType.${row.moduleType}`) : ''),
      style: {
        width: '200px',
      },
    },
    {
      name: 'level',
      label: loc('common.labels.eventLevel'),
      getContent: (row: any) => (row.level ? loc(`userAccess.eventLevel.${row.level}`) : ''),
      style: {
        width: '200px',
      },
    },
    {
      name: 'time',
      label: loc('common.labels.eventTime'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'type',
      label: loc('common.labels.eventType'),
      getContent: (row: any) => (row.type ? loc(`userAccess.eventType.${row.type}`) : ''),
      style: {
        width: '200px',
      },
    },
    {
      name: 'userCreateName',
      label: loc('common.labels.fiocreate'),
      style: {
        width: '200px',
      },
    },
    {
      name: 'addInfo',
      label: loc('common.labels.addInfo'),
      getContent: (row: any) => {
        // return row.body?.values?.toString();
        // return Array.isArray(row.body) ? row.body.join(', ') : row.body;
        return getUserAccessAddInfo(row);
      },
      style: {
        width: '200px',
      },
    },
  ];
};
