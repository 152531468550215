import React, { CSSProperties, useState } from 'react';
import clsx from 'clsx';
import s from './ErrorSign.module.scss';
import { SvgIcon } from '../svg-icon/SvgIcon';
import { ReactComponent as ErrorSignIcon } from 'assets/icons/error-sign.svg';

type Props = {
  className?: string;
  errorMessage?: string;
  small?: boolean;
  style?: CSSProperties;
};

export const ErrorSign: React.FC<Props> = ({ className, errorMessage, small, style }) => {
  const [showTooltip, setShowTooltip] = useState<boolean | null>(null);
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.small]: small,
        [s.showTooltip]: !!showTooltip,
        [s.hideTooltip]: showTooltip === false,
      })}
      onClick={() => setShowTooltip((s) => !s)}
      onMouseLeave={() => showTooltip === false && setShowTooltip(null)}
      style={style}
    >
      <SvgIcon className={s.icon} component={ErrorSignIcon} />
      <div className={s.tooltipContainer}>
        <div className={s.tooltip}>{errorMessage}</div>
      </div>
    </div>
  );
};
