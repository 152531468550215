import { clearEmptyFields } from 'common/utils';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationAdminApproveInvitation: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('/admin/approveInvitation', formData);

  if (res?.data?.success === false || res?.data?.errors) {
    throw res;
  } else {
    return res;
  }
};
