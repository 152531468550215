import { LocaleStore } from 'services/l10n';

export const VirtualLabelTypes = ['ORDINARY', 'RESERVE', 'TEMPORAL_EXPORT', 'TEMPORAL_IMPORT'];

export const getVirtualLabelTypeOptions = ({ loc }: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...['ORDINARY', 'RESERVE', 'TEMPORAL_EXPORT', 'TEMPORAL_IMPORT'].map((type) => ({
    value: type,
    label: loc(`remainder.vmType.${type}`),
  })),
];
