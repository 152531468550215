import { DataQueryFunction, httpClient } from 'rest';

export const querySROHistory: DataQueryFunction = async (variables: any) => {
  const res = await httpClient.get(`/dict/smallRetailOutlet/history`);
  if (res.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
