import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './AdminMessagePage.module.scss';
import { DataGrid, Loader, PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';
import { solveIsReadStatus } from '../constants';

type Props = {
  className?: string;
  onClose?: () => void;
  data: any;
};

export const AdminMessagePage: React.FC<Props> = ({ className, onClose, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  const gridData = useMemo(() => {
    return [
      [loc('adminMessages.messageId'), data.messageId],
      [
        loc('adminMessages.group'),
        !!data.recipientGroup ? loc(`adminMessages.${data.recipientGroup}`) : data.recipientTin,
      ],
      [loc('adminMessages.sendDate'), data.sendDate],
      [loc('adminMessages.messageTheme'), data.theme && loc(`adminMessages.${data.theme}`)],
      [loc('adminMessages.textRu'), data.textRu],
      [loc('adminMessages.textKz'), data.textKz],
      [loc('adminMessages.ogdUserIin'), data.ogdUserIin],
      [loc('adminMessages.ogdUserName'), data.ogdUserName],
      [loc('adminMessages.isRead'), solveIsReadStatus(data, locale)],
      [loc('adminMessages.readerIin'), data.taxpayerUserIin],
      [loc('adminMessages.readDate'), data.taxpayerReadDate],
      [loc('common.labels.ipAddress'), data.taxpayerMainIpAddress],
    ];
  }, [loc, data, locale]);

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('adminMessages.pageTitle')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <div className={s.inner}>
          <DataGrid data={gridData} columnClassNames={[s.leftColumn, '']} />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
