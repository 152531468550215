import React, { Fragment } from 'react';
import { numberWithSpaces } from 'common/utils';
import { LocaleStore } from 'services/l10n';

export const formatPrice = (number: number) =>
  typeof number === 'number' ? (
    <Fragment>
      {numberWithSpaces(number.toFixed(2).replace('.', ','))}
      <abbr>₸</abbr>
    </Fragment>
  ) : null;

export const formatItems = (number: number, locale: LocaleStore) =>
  typeof number === 'number' ? (
    <Fragment>
      {numberWithSpaces(number)}
      <abbr>{locale.loc('esfCounter.items')}</abbr>
    </Fragment>
  ) : null;

export type BoardButton = {
  id: number | string;
  label: string;
  onClick?: (row: any, rowIndex: number) => void | Promise<any>;
  disabled?: (row: any) => boolean;
  icon?: React.FunctionComponent<any>;
};
