import React from 'react';
import clsx from 'clsx';
import s from './MessageRow.module.scss';
import { DT_FORMAT, formatFromDate } from 'services/date-time';
import { Lang, useLocale } from 'services/l10n';
import { sanitize } from 'dompurify';

type Props = {
  className?: string;
  message: any;
  onClick: (message: any) => void;
};

export const MessageRow: React.FC<Props> = ({ className, message, onClick }) => {
  const locale = useLocale();
  const { loc, lang } = locale;

  return (
    <div className={clsx(className, s.wrapper)} onClick={onClick}>
      <div className={s.date}>{formatFromDate(new Date(message.createdTimeStamp), DT_FORMAT.MESSAGE_DATE_TIME)}</div>
      <div className={s.fromTo}>
        <span className={s.name}>
          <b>{loc('c.messages.from')}</b>
          {formatFullName(message.senderTin, message.senderFullname)}
        </span>
        <span className={s.name}>
          <b>{loc('c.messages.to')}</b>
          {formatFullName(message.recipientTin, message.recipientFullname)}
        </span>
      </div>
      <div
        className={s.text}
        dangerouslySetInnerHTML={{ __html: sanitize(lang === Lang.RU ? message.text : message.textKz) }}
      />
    </div>
  );
};

const formatFullName = (tin: string, name: string) => {
  return tin && name ? `${tin} (${name})` : name || '';
};
