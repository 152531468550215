import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryRegistrationOauth2State: DataQueryFunction = async ({ state }) => {
  // if (process.env.NODE_ENV === 'development') {
  //   await delay(2000);
  //   return { data: test11 } as AxiosResponse;
  // }

  const res = await httpClient.get(`/registration/oauth2/state/${state}`);
  if (res.data?.didAuthData) {
    return res;
  } else {
    throw res;
  }
};

// const fakeSuccess = {
//   didAuthData: {
//     idCardNumber: '051588362',
//     iin: '871103300333',
//     lastName: 'МУСИН',
//     firstName: 'ИЛЬНУР',
//     patronymic: 'ВИНИРОВИЧ',
//     phone: '+77055751750',
//     authority: 'МИНИСТЕРСТВО ВНУТРЕННИХ ДЕЛ РК',
//     issueDate: '24.08.2022',
//     expireDate: '23.08.2032',
//   },
//   // registrationTypes: ['NOTARY'],
//   // registrationTypes: ['INDIVIDUAL', 'ENTREPRENEUR', 'NOTARY'],
//   registrationTypes: ['INDIVIDUAL'],
//   state: 'AEdkl4ZABLhAfUfrxr7QhmX5FQRWgEn7zaFo9nHToS4=',
//   addresses: [
//     {
//       type: 'NOTARY',
//       addressRu: 'addressRu NOTARY',
//       addressKz: 'addressKz NOTARY',
//     },
//     {
//       type: 'INDIVIDUAL_ENTREPRENEUR',
//       addressRu: 'addressRu INDIVIDUAL_ENTREPRENEUR',
//       addressKz: 'addressKz INDIVIDUAL_ENTREPRENEUR',
//     },
//   ],
//   individualAddressRu: 'individualAddressRu',
//   individualAddressKz: 'individualAddressKz',
//   taxpayerNameRu: 'taxpayerNameRu',
//   taxpayerNameKz: 'taxpayerNameKz',
// };

// const fakeError = {
//   errorCode: 'ACCESS_DENIED',
//   errorDescription: 'User denied access',
//   registrationTypes: ['INDIVIDUAL'],
//   state: 'uX8IgYl-QNL7CJ0KoSM2fUwJzStWPkySRQxvah7_frw=',
// };

// const fakeEnterprises = {
//   didAuthData: {
//     idCardNumber: '051577361',
//     iin: '871103300333',
//     lastName: 'МУСИН',
//     firstName: 'ИЛЬНУР',
//     patronymic: 'ВИНИРОВИЧ',
//     phone: '+77055751750',
//     authority: 'МИНИСТЕРСТВО ВНУТРЕННИХ ДЕЛ РК',
//     issueDate: '24.08.2022',
//     expireDate: '23.08.2032',
//   },
//   registrationTypes: ['INDIVIDUAL', 'ENTERPRISE'],
//   state: '72Q18TCCQT5PM8m67RXCZwcUhJDANIy7xLrYgR7df3Q=',
//   enterprises: [
//     {
//       tin: '121040014412',
//       nameRu: 'Товарищество с ограниченной ответственностью "ElectroLab"',
//       nameKz: '"ElectroLab" жауапкершілігі шектеулі серіктестігі',
//       addressRu: 'Казахстан, Алматинская обл., г. Алматы, ул. ЖАМБЫЛ, д. 176, кв. (офис) 1',
//       addressKz: 'Қазақстан, Алматы обл., Алматы қ.',
//       resident: true,
//       type: 'NOT_SET',
//       state: 'NOT_SET',
//       resourceUser: false,
//       enterpriseAdministrator: '871103300332',
//       kogd: '0001',
//       isBlockedAsIndividual: false,
//       isBlockedAsEnterprise: false,
//     },
//     {
//       tin: '121040010000',
//       nameRu: 'ОАО "Асем"',
//       nameKz: 'ОАО "Асем',
//       addressRu: 'Казахстан, Алматинская обл., г. Алматы, ул. ЖАМБЫЛ, д. 200, кв. (офис) 100',
//       addressKz: 'Қазақстан, Алматы обл., Алматы қ.',
//       resident: true,
//       type: 'NOT_SET',
//       state: 'NOT_SET',
//       resourceUser: false,
//       enterpriseAdministrator: '871103300332',
//       kogd: '0001',
//       isBlockedAsIndividual: false,
//       isBlockedAsEnterprise: false,
//     },
//   ],
// };

// const test1 = {
//   didAuthData: {
//     idCardNumber: '040806040',
//     iin: '000216651206',
//     lastName: 'НАЗЫМОВА',
//     firstName: 'НАЗЕРКЕ',
//     patronymic: 'КАЙРАТКЫЗЫ',
//     phone: '+77473077615',
//     authority: 'МИНИСТЕРСТВО ВНУТРЕННИХ ДЕЛ РК',
//     issueDate: '24.02.2016',
//     expireDate: '23.02.2026',
//     birthDate: '16.02.2000',
//   },
//   registrationTypes: ['INDIVIDUAL', 'ENTREPRENEUR'],
//   state: 'AEdkl4ZABLhAfUfrxr7QhmX5FQRWgEn7zaFo9nHToS4=',
//   enterprises: [
//     {
//       tin: '130240013649',
//       nameRu: 'Товарищество с ограниченной ответственностью "Open Systems Development"',
//       nameKz: '"Open Systems Development" жауапкершілігі шектеулі серіктестігі',
//       addressRu: 'Тестовый адрес\tТестовый адрес',
//       addressKz: 'Тестовый адрес\tТестовый адрес',
//       certificateSeries: '60001',
//       certificateNum: '0039821',
//       resident: true,
//       type: 'NOT_SET',
//       state: 'WORK',
//       resourceUser: false,
//       enterpriseAdministrator: '000216651206',
//       kogd: '0001',
//       isBlockedAsIndividual: false,
//       isBlockedAsEnterprise: false,
//     },
//   ],
//   individualAddressRu: 'Казахстан, Акмолинская обл., г. Астана, ул. АБЫЛАЙ ХАНА, д. 2, кв. (офис) 503 А',
//   individualAddressKz: 'Қазақстан, Ақмола обл., Астана қ.',
//   taxpayerNameRu: 'Ип Наз',
//   taxpayerNameKz: 'Ип Наз',
// };

// const test11 = {
//   didAuthData: {
//     idCardNumber: '040806040',
//     iin: '000216651207',
//     lastName: 'НАЗЫМОВА',
//     firstName: 'НАЗЕРКЕ',
//     patronymic: 'КАЙРАТКЫЗЫ',
//     phone: '+77473077615',
//     authority: 'МИНИСТЕРСТВО ВНУТРЕННИХ ДЕЛ РК',
//     issueDate: '24.02.2016',
//     expireDate: '23.02.2026',
//     birthDate: '16.02.2000',
//   },
//   registrationTypes: ['INDIVIDUAL', 'ENTREPRENEUR'],
//   state: 'gE1A6WBRx9TuoQmjoRNnxnkd8-DrXbxZ-FWbmsOGuHA=',
//   individualAddressRu: 'Адрес',
//   individualAddressKz: 'Адрес',
//   taxpayerNameKz: 'ИП Наз',
//   activeRegistrationTypes: ['LAWYER', 'INDIVIDUAL', 'MEDIATOR'],
// };
