import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from '../../helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination, regNumber } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1000);
  //   return {
  //     data: fakeEsfJournal,
  //   } as AxiosResponse;
  // }

  let url = '';

  if (regNumber) {
    url = `invoice/regNumber/${regNumber}`;
  } else {
    url = `/invoice/searchlist`;
  }

  const res = await httpClient.get(url, {
    params: {
      ...clearEmptyFields(filter),
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
