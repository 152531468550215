import { useRef, useEffect } from 'react';

export const useIsFirstRenderRef = () => {
  const isFirstRef = useRef(true);

  useEffect(() => {
    if (isFirstRef.current) {
      return () => {
        isFirstRef.current = false;
      };
    }
  });

  // Solve react Strict Mode
  useEffect(() => {
    return () => {
      isFirstRef.current = true;
    };
  }, []);

  return isFirstRef;
};
