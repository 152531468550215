import React, { Fragment, useMemo, useState } from 'react';
import { useLocale } from 'services/l10n/useLocale';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Journal, JournalButton } from 'common/components';
import { getQoldayRegistryJournalColumns } from './getQoldayRegistryJournalColumns';
import { QoldayRegistryJournalFilters } from './QoldayRegistryJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { Loader, rowsOptionsVariant3 } from 'ui';
import { Modal } from 'services/modal';
import { QoldayHistoryModal } from '../qolday-history-modal/QoldayHistoryModal';
import { queryAdminQoldayExport, queryAdminQoldaySearch } from '../api';
import { formatFromDate } from 'services/date-time';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.id;

const filterStorageKey = 'QoldayRegistry';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, справочник "Участники проекта Е-Tamga"
 * arm/qolday-registry
 */
export const QoldayRegistryJournal: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [modal, setModal] = useState<{
    show: boolean;
    row: any;
  }>({
    show: false,
    row: undefined,
  });

  const [exporting, setExporting] = useState(false);

  const columns = useMemo(() => getQoldayRegistryJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminQoldaySearch, {
    filter: SessionStorageService.get(filterStorageKey) || {
      createdFrom: formatFromDate(new Date()),
      createdTo: formatFromDate(new Date()),
    },
    pagination: initialPagination,
  });

  const exportJournal = async (format: string, selection: Array<any>) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryAdminQoldayExport({
          filter: { ...queryProps.filter, ids: selection.map((s) => getRowId(s)) },
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal('XLSX', selection),
    },
    {
      id: 2,
      label: loc('common.button.exportCsv'),
      onClick: (selection: Array<any>) => exportJournal('CSV', selection),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 3,
      label: loc('qolday.history'),
      onClick: (selected) => setModal({ show: true, row: selected[0] }),
    },
  ];

  const onCloseModal = () => setModal({ show: false, row: undefined });

  return (
    <Fragment>
      {exporting && <Loader overlay />}
      <Journal
        pageTitle={loc('qolday.title')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={QoldayRegistryJournalFilters}
        filterInitialOpened
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        paginationRowsOptions={rowsOptionsVariant3}
      />

      <Modal isOpen={modal.show} onClose={onCloseModal}>
        <QoldayHistoryModal docId={getRowId(modal.row)} />
      </Modal>
    </Fragment>
  );
};
