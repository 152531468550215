import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';

export const mutationSuperUserLogin: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  let formData = new FormData();
  formData.append('certificate', body.certificate);
  formData.append('login', body.login);
  formData.append('password', body.password);
  formData.append('superAdmin', body.superAdmin);
  formData.append('submit', 'Войти');

  try {
    const res = await httpClient.post('/admin_login', formData);
    if (res.data.success) {
      return res;
    } else {
      console.log('here');
      throw res.data.message;
    }
  } catch (error) {
    throw error;
  }
};
