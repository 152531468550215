import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import s from './EaecLayout.module.scss';
import clsx from 'clsx';
import { EaecLayoutFooter } from './EaecLayoutFooter';
import { EaecLayoutHeader } from './EaecLayoutHeader';

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

export const EaecLayout: React.FC<Props> = (props) => {
  const { children } = props;

  useEffect(() => {
    console.log('MainLayout MOUNTED!!');
  }, []);

  return (
    <div className={clsx(s.wrapper)}>
      <div className={s.header}>
        <EaecLayoutHeader />
      </div>
      <div className={s.content}>{children}</div>
      <div className={s.footer}>
        <EaecLayoutFooter />
      </div>
    </div>
  );
};
