import React from 'react';
import clsx from 'clsx';
import s from './EaecLayoutHeader.module.scss';
import { LangToggle } from 'common/components';
import { MainLogo } from 'ui/common/elements/logo/MainLogo';
import { useLocale } from 'services/l10n';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
};

export const EaecLayoutHeader: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.inner}>
        <Link to={PATH.MAIN} className={s.logoLink}>
          <MainLogo text={loc('common.logoTitle')} />
        </Link>
        <LangToggle className={s.langToggle} buttonClassName={s.langToggleButton} />
      </div>
    </div>
  );
};
