import React, { Fragment } from 'react';
import { queryCurency } from 'rest';
import { useLocale } from 'services/l10n';
import { useDictionaryLoad } from './useDictionaryLoad';

let codeMap: any = undefined;
let symbolMap: any = undefined;

export const getCurencyCode = (curencyData: any) => curencyData?.code;
export const getCurencySymbol = (curencyData: any) => curencyData?.symbol;

async function fetchDictionary() {
  try {
    const res = await queryCurency();
    if (Array.isArray(res.data)) {
      if (!codeMap) {
        codeMap = Object.fromEntries(res.data.map((item: any) => [String(getCurencyCode(item)), item]));
        console.log('created curency CodeMap');
      }
      if (!symbolMap) {
        symbolMap = Object.fromEntries(res.data.map((item: any) => [String(getCurencySymbol(item)), item]));
        console.log('created curency symbolMap');
      }
    }
    return res;
  } catch (error) {
    console.error(error);
  }
}

type Props = {
  className?: string;
  code?: string | number;
  symbol?: string;
  format?: 'name' | 'nameWithSymbol';
};

export const CodeValueCurency: React.FC<Props> = ({ code, symbol, format = 'name', className }) => {
  const locale = useLocale();
  const { choose } = locale;

  const { show } = useDictionaryLoad(codeMap && symbolMap, fetchDictionary);

  if (!(code || symbol) || !show) {
    return null;
  }

  const data = code ? codeMap?.[code] : symbol ? symbolMap?.[symbol] : undefined;

  if (!data) {
    return <Fragment>{String(code || symbol)}</Fragment>;
  }

  return (
    <span className={className}>
      {format === 'name'
        ? choose([data.nameRu, data.nameKz])
        : `${getCurencySymbol(data)} - ${choose([data.nameRu, data.nameKz])}`}
    </span>
  );
};
