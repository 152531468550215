import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from '../../InsidePage.module.scss';
import { Lang, useLocale } from 'services/l10n';
import { InsideMenu } from '../../InsideMenu';
import { InsideBreadcrumbs } from 'pages/inside-pages/InsideBreadcrumbs';

type Props = {
  className?: string;
};

export const VideoTutorPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc, lang } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <InsideBreadcrumbs section="help" pageCrumbs={[{ label: loc('common.nav.tutor') }]} />
      <div className={s.content}>
        <article className={s.article}>
          <h1>{loc('common.nav.tutor')}</h1>
          <div className={s.section}>
            {(lang === Lang.RU ? videosRu : videosKk).map((video) => (
              <Fragment>
                <h3>{video.title}</h3>
                <div className={s.video}>
                  <iframe
                    width="100%"
                    src={video.url}
                    title="Қазақстанның батыс қақпасын күзетуде"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe>
                </div>
              </Fragment>
            ))}
          </div>
        </article>
        <InsideMenu menuName="help" className={s.insideMenu} />
      </div>
    </div>
  );
};

const videosRu = [
  {
    title: 'Урок 1. Регистрация в ИС ЭСФ',
    url: 'https://www.youtube.com/embed/c0e-ko3sogA',
  },
  {
    title: 'Урок 2. Создание склада, заведение остатков на ВС',
    url: 'https://www.youtube.com/embed/SlOcKRjMOGE',
  },
  {
    title: 'Урок 3. Оформление СНТ на импорт со стран ЕАЭС',
    url: 'https://www.youtube.com/embed/t3uokxECQRY',
  },
  {
    title: 'Урок 4. Проставление отметок ОГД на первичную СНТ',
    url: 'https://www.youtube.com/embed/33v1tzuwkd4',
  },
  {
    title: 'Урок 5. Оформление СНТ на реализацию внутри РК',
    url: 'https://www.youtube.com/embed/ulpMc-gh7xY',
  },
  {
    title: 'Урок 6. СНТ реализация РК алко',
    url: 'https://www.youtube.com/embed/QO7ItxkSCQQ',
  },
  {
    title: 'Урок 7. Оформление СНТ по нефтепродуктам',
    url: 'https://www.youtube.com/embed/w_r8SI49OaA',
  },
  {
    title: 'Урок 8. Оформление СНТ по табачной продукции',
    url: 'https://www.youtube.com/embed/nThkbbHsc5o',
  },
  {
    title: 'Урок 9. Оформление СНТ на экспорт ЕАЭС',
    url: 'https://www.youtube.com/embed/YKNQQRIU16E',
  },
  {
    title: 'Урок 10. Оформление СНТ на экспорт третьи страны',
    url: 'https://www.youtube.com/embed/iCsiPCIIHrE',
  },
  {
    title: 'Урок 11.Подтверждение и отклонение СНТ',
    url: 'https://www.youtube.com/embed/XAI0maqGm5s',
  },
  {
    title: 'Урок 12. Возврат товара по СНТ',
    url: 'https://www.youtube.com/embed/GkxJt_WWK9A',
  },
  {
    title: 'Урок 13 Детализация, комплектация, разукомлектация',
    url: 'https://www.youtube.com/embed/l1RyEhBvkUA',
  },
  {
    title: 'Урок 14. Детализация товара на ВС конвертация единиц измерения, пересортица',
    url: 'https://www.youtube.com/embed/CvMaBvZHJt0',
  },
  {
    title: 'Урок 15 Производство товара на ВС, списание в прозводство',
    url: 'https://www.youtube.com/embed/YKNQQRIU16E',
  },
  {
    title: 'Урок 16. Списание товара с ВС',
    url: 'https://www.youtube.com/embed/pIx1FT6PyTs',
  },
  {
    title: 'Видеоурок 17. Сопоставление СНТ на импорт с ФНО 328.00',
    url: 'https://www.youtube.com/embed/kfMQRPKOa9k',
  },
  {
    title: 'Урок 18. Выписка ЭСФ на основании СНТ',
    url: 'https://www.youtube.com/embed/zRHdhvjxi4Q',
  },
  {
    title: 'Урок 19. Отмена списания',
    url: 'https://www.youtube.com/embed/ak7TW3yP1eg',
  },
  {
    title: 'Урок 20. Поиск остатков',
    url: 'https://www.youtube.com/embed/kp2KuCtWoow',
  },
  {
    title: 'Урок 21. Оформление СНТ по пиву и пивным напиткам при реализации в литрах',
    url: 'https://www.youtube.com/embed/tUjbyIu9jas',
  },
  {
    title: 'Урок 22. Оформление СНТ на нераспределенном складе',
    url: 'https://www.youtube.com/embed/Ufx7JO2OX0w',
  },
  {
    title: 'Урок 23. оформление СНТ на мобильном приложении ИС ЭСФ',
    url: 'https://www.youtube.com/embed/uuS7Q6YCXbs',
  },
  {
    title: 'Урок 24. Подтверждение СНТ на подакцизную продукцию',
    url: 'https://www.youtube.com/embed/wxLx7oRIJPM',
  },
  {
    title: 'Урок 25 В каких случаях СНТ подлежит подтверждению',
    url: 'https://www.youtube.com/embed/_RVHkzjIUpU',
  },
  {
    title: 'Урок 26. Выписка СНТ и ЭСФ в адрес контрагента',
    url: 'https://www.youtube.com/embed/atVx1MIEYUI',
  },
  {
    title: 'Урок 27. Выписка СНТ и ЭСФ на розницу',
    url: 'https://www.youtube.com/embed/6kZyacG5Dnc',
  },
];

const videosKk = [
  {
    title: '1 Бейнесабақ Салық төлеушілерді іздеу және қарау',
    url: 'https://www.youtube.com/embed/aKKIg2xELac',
  },
  {
    title: '2 Бейнесабақ Тағайындалған өкілеттіктерді қарау',
    url: 'https://www.youtube.com/embed/UnuzbXnpkqQ',
  },
  {
    title: '3 Бейнесабақ Келісімдерді іздеуге және көруге арналған',
    url: 'https://www.youtube.com/embed/HysvbfB4qt4',
  },
  {
    title: '4 Бейнесабақ Қайта тіркеу туралы ақпаратты қарау',
    url: 'https://www.youtube.com/embed/x8RpTQt1-_M',
  },
  {
    title: '5 Бейнесабақ Ағымдағы тіркеуді тексеру',
    url: 'https://www.youtube.com/embed/KIbLmV1dgok',
  },
  {
    title: '6 Бейнесабақ Тұтынушыларды бұғаттау журналы',
    url: 'https://www.youtube.com/embed/EL5bAgPf5So',
  },
  {
    title: '7 Бейнесабақ ЭШФ іздеу, қарау және басып шығару',
    url: 'https://www.youtube.com/embed/VIYUH440OKE',
  },
  {
    title: '8 Бейнесабақ Тауарларға ілеспе жүкқұжаттарды ТІЖ іздеу, қарау және басып шығару',
    url: 'https://www.youtube.com/embed/k2E4EG-nCvQ',
  },
  {
    title: '9 Бейнесабақ Өнімді бақылау тізбегі',
    url: 'https://www.youtube.com/embed/Ya1krD_D3x4',
  },
  {
    title: '10 Бейнесабақ Салық төлеушілердің виртуалды қоймаларын іздеу және қарау',
    url: 'https://www.youtube.com/embed/9eWgZTquVv0',
  },
  {
    title: '11 Бейнесабақ Кіру және іздеу Салық есептілігінің 328 нысаны',
    url: 'https://www.youtube.com/embed/aAqcle-WaL0',
  },
  {
    title: '12 Бейнесабақ Кеден декларациясын іздеу, қарау ТД',
    url: 'https://www.youtube.com/embed/KAleu8qNQaQ',
  },
  {
    title: '13 Бейнесабақ АЖО ҚҚМ де формаларды іздеу және қарау',
    url: 'https://www.youtube.com/embed/RF2VK33lIlk',
  },
  {
    title: '14 Бейнесабақ СТ КЗ СТ 1 сертификаттарын қарау',
    url: 'https://www.youtube.com/embed/__qKoz1F500',
  },
  {
    title: '15 Бейнесабақ ЕАЭО шеңберінде ТІЖ қарау, МКО инспекторының растау қабылдамауыwmv',
    url: 'https://www.youtube.com/embed/VtZW0F0PYOA',
  },
  {
    title: '16 Бейнесабақ ЕАЭО елдеріне экспортқа тауарға ілеспе құжаттарды куәландыру функционалымен жұмыс істе',
    url: 'https://www.youtube.com/embed/HvpWNLE5ZT4',
  },
  {
    title: '17 Бейнесабақ Функционал куәландыру және паспортты қарау қадағалану ца',
    url: 'https://www.youtube.com/embed/EfUc05PVkkg',
  },
  {
    title: '18 Бейнесабақ Тіркелген қатысушылар бойынша есеп v2',
    url: 'https://www.youtube.com/embed/l0ap97RotSA',
  },
  {
    title: '19 Бейнесабақ Экспорттық бақылау бойынша есеп',
    url: 'https://www.youtube.com/embed/o6jDq_fDBrQ',
  },
  {
    title: '20 Бейнесабақ «ЭШФ АЖ қатысушылары және құжат айналымы бойынша есепті қалыптастыру»',
    url: 'https://www.youtube.com/embed/LUEfIVWs5Mg',
  },
  {
    title: '21 Бейнесабақ «Тауар балансын айқындау жөніндегі айналым сальдо ведомосі» есебін қалыптастыру',
    url: 'https://www.youtube.com/embed/Svi1d4LaqyA',
  },
  {
    title: '22 Бейнесабақ Салык салушылардын тауар калдыктарын накты уакыт тартиптемесинде карау',
    url: 'https://www.youtube.com/embed/KAzh9EQgiBQ',
  },
  {
    title: '23 Бейнесабақ Мобилди косымшадагы дэшбордтар',
    url: 'https://www.youtube.com/embed/NH8JLtlx8Ec',
  },
  {
    title: '24 Бейнесабақ ЕАЭО щеңберіндегі тауарларға ілеспе жүкқұжаты ТІЖ',
    url: 'https://www.youtube.com/embed/fCfoSAcPeUI',
  },
];
