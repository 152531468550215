import { LEGACY_URL } from 'common/constants';
import React, { ReactNode } from 'react';
import { USER_ACCESS_EVENT_TYPE, USER_ACCESS_MODULE_TYPE } from './userAccessConstants';

function firstValueFrom_DocumentLongListBody(row: any): string {
  const values = row.body?.valuesAsString;
  return values && Array.isArray(values) && values[0];
}

function firstValueFrom_DocumentChangeStatusBody(row: any): string {
  const values = row.body?.successIds;
  return values && Array.isArray(values) && values[0];
}

function valueFrom_DocumentLongBody(row: any): string {
  return row.body?.valueAsString;
}

export const getUserAccessAddInfo = (row: any): ReactNode => {
  // REG_ACCOUNTING
  if (row.type === USER_ACCESS_EVENT_TYPE.EMAIL_CHANGE) {
    //ChangeEmailBody
    return `Email ${row.body?.oldEmail} был изменен на новый ${row.body?.newEmail}`;
  }

  // INVOICE
  if (row.moduleType === USER_ACCESS_MODULE_TYPE.INVOICE) {
    let invoiceId = '';
    if (row.type === USER_ACCESS_EVENT_TYPE.INVOICE_CREATED) {
      invoiceId = firstValueFrom_DocumentLongListBody(row);
    } else {
      invoiceId = firstValueFrom_DocumentChangeStatusBody(row);
    }
    return <a href={`${LEGACY_URL}/#!/esf/show/${invoiceId}`}>{invoiceId}</a>;
  }

  // SNT
  if (row.moduleType === USER_ACCESS_MODULE_TYPE.SNT) {
    let sntId = '';
    if (row.type === USER_ACCESS_EVENT_TYPE.SNT_CREATED) {
      sntId = firstValueFrom_DocumentLongListBody(row);
    } else {
      sntId = firstValueFrom_DocumentChangeStatusBody(row);
    }
    return <a href={`${LEGACY_URL}/#!/snt/show/${sntId}`}>{sntId}</a>;
  }

  // AWP
  if (row.moduleType === USER_ACCESS_MODULE_TYPE.AWP) {
    let awpId = '';
    if (row.type === USER_ACCESS_EVENT_TYPE.AWP_CREATED) {
      awpId = firstValueFrom_DocumentLongListBody(row);
    } else {
      awpId = firstValueFrom_DocumentChangeStatusBody(row);
    }
    return <a href={`${LEGACY_URL}/#!/awp/show/${awpId}`}>{awpId}</a>;
  }

  // ECONTRACT
  if (row.moduleType === USER_ACCESS_MODULE_TYPE.ECONTRACT) {
    let econtractId = '';
    if (row.type === USER_ACCESS_EVENT_TYPE.ECONTRACT_CREATED) {
      econtractId = firstValueFrom_DocumentLongListBody(row);
    } else {
      econtractId = firstValueFrom_DocumentChangeStatusBody(row);
    }
    return <a href={`${LEGACY_URL}/#!/e-contract/${econtractId}`}>{econtractId}</a>;
  }

  // UFORM
  if (row.type === USER_ACCESS_EVENT_TYPE.UFORM_SAVE || row.type === USER_ACCESS_EVENT_TYPE.UFORM_CANCEL) {
    return valueFrom_DocumentLongBody(row);
  }

  // VSTORE
  if (row.type === USER_ACCESS_EVENT_TYPE.VSTORE_SAVE || row.type === USER_ACCESS_EVENT_TYPE.VSTORE_CLOSE) {
    return valueFrom_DocumentLongBody(row);
  }

  //NOTIFICATION
  if (
    row.type === USER_ACCESS_EVENT_TYPE.NOTIFICATION_READ ||
    row.type === USER_ACCESS_EVENT_TYPE.NOTIFICATION_DELETE
  ) {
    return valueFrom_DocumentLongBody(row);
  }
};
