export const rowsOptionsArm = [
  { value: 10, label: '10' },
  { value: 20, label: '20' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
];

export const rowsOptionsTaxpayer = [
  { value: 15, label: '15' },
  { value: 25, label: '25' },
  { value: 40, label: '40' },
  { value: 50, label: '50' },
];

export const rowsOptionsTaxpayerFull = [
  { value: 15, label: '15' },
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 300, label: '300' },
  { value: 1000, label: '1000' },
];

export const rowsOptionsVariant3 = [
  { value: 10, label: '10' },
  { value: 200, label: '200' },
  { value: 600, label: '600' },
  { value: 800, label: '800' },
  { value: 1000, label: '1000' },
];
