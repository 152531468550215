import React from 'react';
import clsx from 'clsx';
import s from './ProfileSelectModal.module.scss';
import { ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { ProfileSelect } from './ProfileSelect';
import { useStore } from 'common/hooks';

type Props = {
  className?: string;
  onSelect: (profileId: string) => void;
};

export const ProfileSelectModal: React.FC<Props> = ({ className, onSelect }) => {
  const { locale } = useStore();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header>{loc('common.title.selectProfile')}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <ProfileSelect modal onSelect={onSelect} />
      </ModalT2Content>
      <ModalT2Footer className={s.footer} />
    </div>
  );
};
