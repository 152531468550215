import { AxiosResponse } from 'axios';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';
import { OFFLINE_DEV } from 'common/constants';
import { fakeUser } from './fakeUser';

export const queryCurrentUser: DataQueryFunction = async () => {
  if (process.env.NODE_ENV === 'development' && OFFLINE_DEV) {
    return {
      data: fakeUser,
    } as AxiosResponse;
  }

  return httpClient.get(`currentUser`);
};
