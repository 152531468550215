import { API_URL } from 'common/constants';
import { DataQueryFunction, downloadFile } from 'rest';

export const queryAdminZvtPrintReport: DataQueryFunction<{ id: string; format: string }> = async (variables) => {
  const { id, format } = variables || {};

  const res = await downloadFile({
    url: `${API_URL}/admin/zvt/printReport/${id}/${format}`,
    method: 'GET',
  });
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
