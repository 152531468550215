import { Taxpayer, UserStore } from 'common/stores/user-store';
import { LocaleStore } from 'services/l10n';
import { resolveTaxpayerAdress, resolveTaxpayerFio, resolveTaxpayerNameOrFio } from '.';
import { toJS } from 'mobx';

/**
 * Данные НП, обогащенные формулиравкой имнем и адресса в зависимости от вида деятельности
 */
export type TaxpayerProps = Taxpayer & {
  name: string;
  address: string;
};

/**
 * Вычисление основных данных налогоплательщика (TaxpayerProps) из данных пользователя, в зависимости от вида деятельности, филилала и тд.
 */
export function resolveTaxpayerPropsFromUser(user: UserStore, locale: LocaleStore): TaxpayerProps {
  const result: TaxpayerProps = {
    tin: '',
    name: '',
    address: '',
    kogd: '',
    certificateSeries: '',
    certificateNum: '',
  };
  const userPrivatePractice = ['LAWYER', 'BAILIFF', 'MEDIATOR', 'NOTARY'].includes(
    user.currentUser?.documentParticipantType || ''
  );
  const profile = user.getCurrentProfile();
  console.log('profile', toJS(profile));
  if (!profile) {
    console.log('no profile');
  } else if (profile.id === 'individual') {
    result.name = profile.name;
    result.tin = profile.tin;
  } else {
    const taxpayer = profile.enterpriseTaxpayerInfo;
    result.tin = taxpayer?.tin || '';
    result.kogd = taxpayer?.kogd || '';
    result.certificateSeries = taxpayer?.certificateSeries || '';
    result.certificateNum = taxpayer?.certificateNum || '';
    result.headOffice = taxpayer?.headOffice;
    if (userPrivatePractice) {
      result.name = resolveTaxpayerFio(taxpayer, locale);
    }
    result.name = resolveTaxpayerNameOrFio(taxpayer, locale);
    result.address = resolveTaxpayerAdress(taxpayer, locale);
  }

  console.log('result', result);

  return result;
}

/**
 * Возвращает данные налогоплательщика или данные Доверителя если текущий профиль Поверенного
 */
export function resolveTaxpayerPropsConsiderAttorney(user: UserStore, locale: LocaleStore): TaxpayerProps {
  if (user.currentUser?.currentUserProject?.currentProjectParticipantType === 'ATTORNEY') {
    const owner = user.currentUser?.currentUserProject.owners?.[0];
    const taxpayer = owner.taxpayer;
    taxpayer.name = resolveTaxpayerFio(taxpayer, locale);
    taxpayer.address = resolveTaxpayerAdress(taxpayer, locale);
    return taxpayer;
  } else {
    return resolveTaxpayerPropsFromUser(user, locale);
  }
}
