import { DataQueryFunction } from 'rest/types';

export function createCachedQuery(query: DataQueryFunction): DataQueryFunction {
  // кешированные данные
  const cache: { [key: string]: any } = {};
  // отложенные запросы на время получения данных
  const requests: { [key: string]: any } = {};

  return async (vars: any, options) => {
    return new Promise(async (resolve, reject) => {
      const key = JSON.stringify(vars);

      // Если данные уже есть то отдаем из кеша
      if (cache[key]) {
        resolve(cache[key]);
      } else {
        // Если данных еще нет, но уже запущен запрос на получение, сохраняем хендлеры запроса
        if (Array.isArray(requests[key])) {
          requests[key].push({ resolve, reject });
          // console.log(requests[key]);
        } else {
          requests[key] = [{ resolve, reject }];
          try {
            const res = await query(vars, options);
            cache[key] = res;
            // отдаем данные всем запросам, пришедшим за время получения данных
            requests[key].forEach((request: any) => {
              request.resolve(res);
            });
          } catch (error) {
            requests[key].forEach((request: any) => {
              request.reject(error);
            });
          } finally {
            requests[key] = undefined;
          }
        }
      }
    });
  };
}
