import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';
import { solveIsReadStatus } from '../constants';

export const getAdminMessageJournalColumns = ({ locale }: { locale: LocaleStore }): Array<TableColumn> => {
  const { loc } = locale;
  return [
    {
      name: 'messageId',
      label: loc('adminMessages.messageId'),
      style: {
        width: '150px',
      },
    },
    {
      name: 'theme',
      label: loc('adminMessages.messageTheme'),
      getContent: (row) => !!row.theme && loc(`adminMessages.${row.theme}`),
    },
    {
      name: 'recipientGroup',
      label: loc('adminMessages.group'),
      getContent: (row) => (!!row.recipientGroup ? loc(`adminMessages.${row.recipientGroup}`) : row.recipientTin),
    },
    {
      name: 'sendDate',
      label: loc('adminMessages.sendDate'),
    },
    {
      name: 'textRu',
      label: loc('adminMessages.textRu'),
      style: {
        width: '300px',
      },
    },
    {
      name: 'textKz',
      label: loc('adminMessages.textKz'),
      style: {
        width: '300px',
      },
    },
    {
      name: 'ogdUserIin',
      label: loc('adminMessages.ogdUserIin'),
    },
    {
      name: 'ogdUserName',
      label: loc('adminMessages.ogdUserName'),
      style: {
        width: '300px',
      },
    },
    {
      name: 'readStatus',
      label: loc('adminMessages.isRead'),
      getContent: (row) => solveIsReadStatus(row, locale),
    },
    {
      name: 'taxpayerUserIin',
      label: loc('adminMessages.readerIin'),
    },
    {
      name: 'taxpayerReadDate',
      label: loc('adminMessages.readDate'),
    },
    {
      name: 'taxpayerMainIpAddress',
      label: loc('common.labels.ipAddress'),
    },
  ];
};
