import { AppMode } from 'common/constants';
import { UserStore } from 'common/stores/user-store';
import { LocaleStore } from 'services/l10n';
import { MenuItem } from 'ui';
import { eaecMenu } from '.';
import { adminMenu } from './adminMenu';
import { mainMenu } from './mainMenu';

export const generateMenu = (appMode: AppMode, locale: LocaleStore, user: UserStore): Array<MenuItem> => {
  const menu =
    appMode === AppMode.TAXPAYER
      ? mainMenu(locale, user)
      : appMode === AppMode.ARM
      ? adminMenu(locale, user)
      : appMode === AppMode.EAEC
      ? eaecMenu(locale)
      : [];
  return menu
    .map((tab) => ({
      ...tab,
      childrenItems: tab.childrenItems ? tab.childrenItems.filter((item) => !item.disabled) : [],
    }))
    .filter((tab) => (tab.childrenItems.length || tab.link) && !tab.disabled);
};
