import { CodeValueCountry } from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getZvtJournalArmColumns = ({
  locale: { loc },
}: {
  locale: LocaleStore;
}): Array<TableColumn> => [
  {
    name: 'registrationNumber',
    label: loc('zvt.regNumber'),
    width: 300,
  },
  {
    name: 'consigneeTin',
    label: loc('zvt.consigneeTin'),
    labelTitle: loc('zvt.consigneeTin.title'),
  },
  {
    name: 'initiated',
    label: loc('zvt.initiated'),
    labelTitle: loc('zvt.initiated.title'),
  },
  {
    name: 'astana1Status',
    label: loc('zvt.status'),
    labelTitle: loc('zvt.status.title'),
    getContent: (row) =>
      row.astana1Status ? loc(`zvt.status.${row.astana1Status}`) : '',
  },
  {
    name: 'totalCost',
    label: loc('zvt.totalCost'),
  },
  {
    name: 'currencyCode',
    label: loc('common.labels.currencyCode'),
  },
  {
    name: 'departureCountry',
    label: loc('zvt.depCountry'),
    labelTitle: loc('zvt.depCountry.title'),
    getContent: (row) => <CodeValueCountry code={row.departureCountry} />,
  },
  {
    name: 'documentGtdRegNum',
    label: loc('common.dt'),
    labelTitle: loc('zvt.dt.title'),
  },
];
