import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './SmallRetailForm.module.scss';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { FormField, FormInput, FormSelect, FormTextarea } from 'ui/common';
import { getOptionsYesNo } from 'common/helpers/options';
import { useLocale } from 'services/l10n';
import { FormAutocompleteIIN, FormLocaleDatePicker } from 'common/components/controls';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { getSnrTypeOptions, getSORStatusOptions } from '../helpers';
import { ToastService } from 'services/toasts';
import { resolveTaxpayerNameOrFio } from 'common/helpers/taxpayer';

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
  data?: any;
  renderModalFooter?: (props: FormikProps<any>) => React.ReactNode;
};

const schema = yup.object({});

export const SmallRetailForm: React.FC<Props> = ({ className, onSubmit, data, renderModalFooter }) => {
  const locale = useLocale();
  const { loc } = locale;
  const initialValues = useMemo(
    () =>
      data
        ? {
            ...data,
            tin: { tin: data.tin },
            secondaryOkedCodes: Array.isArray(data.secondaryOkedCodes) ? data.secondaryOkedCodes.join(' ') : '',
          }
        : {},
    [data]
  );

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    const submitData = {
      ...values,
      tin: values?.tin?.tin,
      secondaryOkedCodes: values.secondaryOkedCodes ? values.secondaryOkedCodes.split(' ').filter(Boolean) : undefined,
    };
    console.log('submitData', submitData);
    try {
      await onSubmit(submitData);
    } catch (error) {
      console.dir(error);
      ToastService.showError(solveErrorMessage(error));
      form.setErrors(solveFormErrors(error));
      form.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formProps) => {
        const { setFieldValue } = formProps;
        return (
          <Form className={clsx(className, s.wrapper)}>
            <div className={s.content}>
              <div className={s.column}>
                <FormField name="tin" label={loc('common.labels.tin')}>
                  <FormAutocompleteIIN
                    onValueChange={({ value }) => {
                      console.log(value);
                      setFieldValue('tin', value || undefined);
                      setFieldValue('npName', resolveTaxpayerNameOrFio(value, locale));
                      setFieldValue('isNdsPayer', value ? !!value?.certificateNum : undefined);
                    }}
                  />
                </FormField>
                <FormField name="npName" label={loc('common.labels.npName')}>
                  <FormInput disabled />
                </FormField>
                <FormField name="startDate" label={loc('small.regDate')}>
                  <FormLocaleDatePicker />
                </FormField>
                <FormField name="endDate" label={loc('small.closeDate')}>
                  <FormLocaleDatePicker />
                </FormField>
                <FormField name="okedCode" label={loc('common.labels.oked')}>
                  <FormInput />
                </FormField>
                <FormField name="secondaryOkedCodes" label={loc('common.labels.oked2')}>
                  <FormInput />
                </FormField>
                <FormField name="isOnlineKkm" label={loc('small.onlineKkm')}>
                  <FormSelect options={getOptionsYesNo(locale)} />
                </FormField>
                <FormField name="isNdsPayer" label={loc('small.ndsPayer')}>
                  <FormSelect options={getOptionsYesNo(locale)} isDisabled />
                </FormField>
              </div>
              <div className={s.column}>
                <FormField name="snrType" label={loc('small.snrType')}>
                  <FormSelect options={getSnrTypeOptions(locale)} />
                </FormField>

                <FormField name="closeCause" label={loc('small.closeCause')}>
                  <FormTextarea />
                </FormField>

                <FormField name="isActive" label={loc('common.labels.status')}>
                  <FormSelect options={getSORStatusOptions(locale)} />
                </FormField>
              </div>
            </div>
            {typeof renderModalFooter === 'function' && renderModalFooter(formProps)}
          </Form>
        );
      }}
    </Formik>
  );
};
