import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from '../../types';

export const queryAdminRemainderExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, format = 'XLSX' } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  return downloadFile({
    url: `${API_URL}/admin/vstore/remainder/export/${format}`,
    method: 'GET',
    params: { criteria },
  });
};
