import React, { CSSProperties, useMemo } from 'react';
import { FastField, Field as FormikField, FieldConfig, FieldProps, getIn } from 'formik';
import { Field } from '../form/Field';
import { FieldDirection } from '../form/FieldDirection';
import { nanoid } from 'nanoid';
import { UITheme } from '../types';

export type FormFieldProps = FieldConfig & {
  className?: string;
  style?: React.CSSProperties;
  label?: string;
  labelClassName?: string;
  children: React.ReactElement | ((props: any) => React.ReactNode);
  direction?: FieldDirection;
  fast?: boolean;
  noMargin?: boolean;
  innerFullWidth?: boolean;
  // pass to child
  disabled?: boolean;
  readOnly?: boolean;
  changeOnBlur?: boolean;
  theme?: UITheme;
  errorVariant?: 'text' | 'icon' | 'inside-icon';
  errorStyle?: CSSProperties;
};

export const FormField: React.FC<FormFieldProps> = ({
  className,
  style,
  children,
  label,
  labelClassName,
  direction,
  fast = true,
  noMargin,
  innerFullWidth,
  theme = 'T1',
  errorVariant,
  errorStyle,
  ...childRest
}) => {
  const id = useMemo(() => `${childRest.name}__${nanoid(5)}`, [childRest.name]);

  const FieldComp = fast ? FastField : FormikField;

  return (
    <FieldComp {...childRest}>
      {(fieldProps: FieldProps<any>) => {
        const { form, field } = fieldProps;
        const error = getIn(form.errors, field.name);
        const errorMessage = errorToString(error);

        const childProps = {
          ...fieldProps,
          ...childRest,
          id,
          error: !!errorMessage,
        };
        return (
          <Field
            direction={direction}
            label={label}
            htmlFor={id}
            readOnly={childRest.readOnly || childRest.readOnly}
            className={className}
            style={style}
            errorMessage={errorMessage}
            labelClassName={labelClassName}
            noMargin={noMargin}
            innerFullWidth={innerFullWidth}
            theme={theme}
            errorVariant={errorVariant}
            errorStyle={errorStyle}
          >
            {typeof children === 'function'
              ? children(childProps)
              : 'type' in children
              ? React.cloneElement(children, childProps)
              : null}
          </Field>
        );
      }}
    </FieldComp>
  );
};

export const errorToString = (error: any) => {
  return error ? (Array.isArray(error) ? error.join('\n') : String(error)) : undefined;
};
