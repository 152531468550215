export const formatFileSize = (sizeInBytes: number) => {
  if (sizeInBytes <= 0) {
    return `${sizeInBytes}`;
  }
  if (sizeInBytes < 1024) {
    return `${sizeInBytes} b`;
  }
  if (sizeInBytes < 1024 ** 2) {
    return `${(sizeInBytes / 1024).toFixed(0)} Kb`;
  }
  if (sizeInBytes < 1024 ** 3) {
    return `${(sizeInBytes / 1024 ** 2).toFixed(1)} Mb`;
  }
  if (sizeInBytes < 1024 ** 4) {
    return `${(sizeInBytes / 1024 ** 3).toFixed(1)} Gb`;
  }
  return `${(sizeInBytes / 1024 ** 4).toFixed(1)} Tb`;
};
