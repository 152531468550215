import { FilterContentProps, FormDoubleDatePicker } from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getEaecDocumentGroupOptions } from '../helpers';
import { getOptionsYesNo } from 'common/helpers';

type Props = FilterContentProps;

export const EaecTransactionJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection } = classNames || {};

  const statusOptions = useMemo(() => getEaecDocumentGroupOptions(locale), [locale]);
  const yesNoOptions = useMemo(() => getOptionsYesNo(locale), [locale]);

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection}>
          <FormField name="messageId" label={loc('eaecActions.messageId')}>
            <FormInput />
          </FormField>
          <FormField name="docId" label={loc('eaecActions.documentId')}>
            <FormInput />
          </FormField>
          <FormField name="procedureId" label={loc('eaecActions.procedureId')}>
            <FormInput />
          </FormField>
          <FormField name="conversationId" label={loc('eaecActions.conversationId')}>
            <FormInput />
          </FormField>
          <FormField name="interactionType" label={loc('eaecActions.interactionType')}>
            <FormInput />
          </FormField>
          <FormField name="documentGroup" label={loc('eaecActions.documentGroup')}>
            <FormSelect options={statusOptions} />
          </FormField>
          <FormField name="isActive" label={loc('eaecActions.isActive')}>
            <FormSelect options={yesNoOptions} />
          </FormField>
          <div></div>
          <Field label={loc('eaecActions.sendOrInputDate')} noMargin>
            <FormDoubleDatePicker nameFrom="sendOrInputDateFrom" nameTo="sendOrInputDateTo" maxDate={new Date()} />
          </Field>
          <Field label={loc('eaecActions.messageCreateDate')} noMargin>
            <FormDoubleDatePicker nameFrom="messageCreateDateFrom" nameTo="messageCreateDateTo" maxDate={new Date()} />
          </Field>
        </div>
      </div>
    </Fragment>
  );
};
