/**
 * Раздел приложения, где находится пользователь
 */
export enum AppMode {
  /**
   * Не авторизованный пользователь
   */
  GUEST = 'GUEST',

  /**
   * Кабинет налогоплательщика
   */
  TAXPAYER = 'TAXPAYER',

  /**
   * Кабинет администатора (АРМ)
   */
  ARM = 'ARM',

  /**
   * Кабинет ЕАЕС пользователей таможенной службы
   */
  EAEC = 'EAEC',
}

export function isAppMode(value: any): value is AppMode {
  return typeof value === 'string' && Object.values(AppMode).includes(value as AppMode);
}
