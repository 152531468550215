import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import s from './JournalT2.module.scss';
import { JournalButton } from '../types';
import { JournalButtonT2 } from './JournalButtonT2';
import { ReactComponent as MoreSvg } from 'assets/icons/journal-buttons/more.svg';
import { useOnResize, useOutsideClick2 } from 'ui';

type Props = {
  actionButtons: JournalButton[];
  onActionClick: (btn: JournalButton) => void;
};

export const JournalT2ActionButtonsInner: React.FC<Props> = ({ actionButtons, onActionClick }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const [showMore, setShowMore] = useState<boolean>(false);
  const [state, setState] = useState<{ visibleButtons: JournalButton[]; moreButtons: JournalButton[] } | undefined>(
    undefined
  );

  const dropdownRef = useRef<any>();
  useOutsideClick2(
    dropdownRef,
    () => {
      if (showMore) {
        setShowMore(false);
      }
    },
    showMore
  );

  useLayoutEffect(() => {
    const buttons = innerRef.current?.querySelectorAll('button');
    const rightBorder = (wrapperRef.current?.getBoundingClientRect().right || 9999) - 49; // 49 - ширина кнопки more;
    console.log({ rightBorder });
    const visibleButtons: JournalButton[] = [];
    const moreButtons: JournalButton[] = [];
    if (buttons) {
      Array.from(buttons).forEach((btn, index) => {
        if (btn.getBoundingClientRect().right < rightBorder) {
          visibleButtons.push(actionButtons[index]);
        } else {
          moreButtons.push(actionButtons[index]);
        }
        // console.log(index, btn);
      });
    }
    setState({ moreButtons, visibleButtons });
  }, []); // eslint-disable-line

  return (
    <div className={s.actionButtons} ref={wrapperRef}>
      <div className={s.actionButtonsInner} ref={innerRef}>
        {(state?.visibleButtons || actionButtons).map((btn) => (
          <JournalButtonT2
            key={btn.id}
            className={s.actionButton}
            iconClassName={s.actionButtonIcon}
            button={btn}
            onClick={() => onActionClick(btn)}
          />
        ))}
      </div>
      {!!state?.moreButtons.length && (
        <Fragment>
          <div className={s.divider}></div>
          <div className={s.moreActionsMenu}>
            <JournalButtonT2
              key={'more'}
              className={s.actionButton}
              iconClassName={s.actionButtonIcon}
              button={{
                variant: 'icon',
                icon: MoreSvg,
                id: 'more',
                label: '',
              }}
              onClick={() => setShowMore((state) => !state)}
            />
            {state?.moreButtons && showMore && (
              <div className={s.moreButtons} ref={dropdownRef}>
                {state?.moreButtons.map((btn) => (
                  <li
                    key={btn.id}
                    className={s.moreButton}
                    onClick={() => {
                      setShowMore(false);
                      onActionClick(btn);
                    }}
                  >
                    {btn.label}
                  </li>
                ))}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export const JournalT2ActionButtons: React.FC<Props> = (props) => {
  const [key, setKey] = useState(0);

  useOnResize(() => setKey(Date.now()), { throttleTimer: 100 });

  useEffect(() => {
    setKey(Date.now());
  }, [props.actionButtons]);

  return <JournalT2ActionButtonsInner key={key} {...props} />;
};
