import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminEaecUserHistorySearch: DataQueryFunction = async (variables: any) => {
  const { pagination } = variables || {};

  const res = await httpClient.get(`/admin/eaec/user/history/search`, {
    params: {
      ...toPaginationParams(pagination),
      sort: 'updateDate',
      order: 'desc',
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
