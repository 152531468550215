import React from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import s from './DevPage.module.scss';
import { Button, FormField, FormInput } from 'ui/common';

type Props = {
  onSubmit(values: any): Promise<any>;
  initialValues: any;
};

const schema = yup.object({
  login: yup.string().trim().required('Введите логин'),
  password: yup.string().trim().required('Введите пароль'),
});

export const DevLoginForm: React.FC<Props> = ({ onSubmit, initialValues = { login: '', password: '' } }) => {
  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await onSubmit({
        login: values.login.trim(),
        password: values.password.trim(),
      });
    } catch (error) {
      console.log('error', error);
      typeof error === 'string' && actions.setErrors({ username: error });
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form className={s.form}>
          <FormField name="login" label="Login">
            <FormInput className={s.control} />
          </FormField>

          <FormField name="password" label="Password">
            <FormInput type="password" className={s.control} />
          </FormField>

          <div className={s.submit}>
            <Button disabled={isSubmitting || !isValid || !dirty} type="submit">
              Войти
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
