import { contentYesNo } from 'common/components/journal';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getArmUsersColumns = (locale: LocaleStore): TableColumn[] => [
  {
    name: 'iin',
    label: 'ИИН участника',
    style: {
      width: '200px',
    },
  },
  {
    name: 'ogdCode',
    label: 'Код ОГД',
    style: {
      width: '100px',
    },
  },
  {
    name: 'ogdName',
    label: 'Наименование ОГД',
    style: {
      width: '350px',
    },
  },
  {
    name: 'userServiceRoleType',
    label: 'Роль в системе',
    getContent: (row: any) => locale.loc(`admin.user.role.${row.userServiceRoleType}`),
    style: {
      width: '200px',
    },
  },
  {
    name: 'blocked',
    label: 'Заблокирован',
    getContent: (row: any) => locale.loc(contentYesNo(row.blocked)),
    style: {
      width: '130px',
    },
  },
  {
    name: 'mobileUser',
    label: 'Пользователь мобильного приложения КГД',
    getContent: (row: any) => locale.loc(contentYesNo(row.mobileUser)),
    style: {
      width: '230px',
    },
  },
];
