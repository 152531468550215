import { API_URL } from 'common/constants';
import { clearEmptyFieldsDeep } from 'common/utils';
import { DataQueryFunction, downloadFile } from 'rest';

export const queryEaecOperationInputJournalExport: DataQueryFunction = (variables: any) => {
  const { filter = {}, format } = variables || {};

  const criteria = JSON.stringify(clearEmptyFieldsDeep(filter));

  return downloadFile({
    url: `${API_URL}/admin/eec/traceability/operation/request/input/generateReport`,
    method: 'GET',
    params: { criteria, format },
  });
};
