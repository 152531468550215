/**
 * create new object without "private" ("_name") values from object (deep)
 * @param object
 * @returns new object
 */
export function clearPrivateProps(object: any): any {
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map((item) => clearPrivateProps(item));
  }
  return Object.entries(object).reduce((acc: any, [key, value]) => {
    if (!key.startsWith('_')) {
      if (value && typeof value === 'object' && !(value instanceof File)) {
        acc[key] = clearPrivateProps(value);
      } else {
        acc[key] = value;
      }
    }
    return acc;
  }, {});
}
