import { clearEmptyFields } from 'common/utils';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryVstoreTnvedCriteria: DataQueryFunction = async ({ code, date, codes }) => {
  const res = await httpClient.post(
    `/vstore/rest/tnved/criteria`,
    JSON.stringify(
      clearEmptyFields({
        code,
        date,
        codes,
      })
    ),
    { headers: { 'Content-Type': 'application/json' } }
  );

  return res;
};
