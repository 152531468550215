import { ASSURANCE_CORRIDOR, AssuranceStatus } from '.';
import { mutationAdminAssuranceActionHash, mutationAdminAssuranceChangeStatus } from '../api';
import { AuthStore } from 'common/stores/auth-store/AuthStore';

export async function changeStatusWithSign({
  passportId,
  actionType,
  cause,
  auth,
}: {
  passportId: string;
  actionType: string;
  cause?: string;
  auth: AuthStore;
}) {
  let hash: any;
  let signData: any = {};
  try {
    hash = await mutationAdminAssuranceActionHash({
      body: {
        passportId: passportId,
        actionType,
        cause,
      },
    });
  } catch (error) {
    console.log('mutationAdminAssuranceActionHash error', error);
    throw error;
  }

  if (hash) {
    try {
      signData = await auth.getSignature(hash?.data?.hash);
      console.log(signData);
    } catch (error) {
      throw error;
    }
  }

  try {
    const res = await mutationAdminAssuranceChangeStatus({
      body: {
        certificate: signData.cert?.base64Pem || '',
        signature: signData.sign || '',
        assuranceActionBody: hash?.data?.hash,
        passportId,
      },
    });
    return res;
  } catch (error) {
    console.log('mutationAdminAssuranceChangeStatus error', error);
    throw error;
  }
}

/**
 * Условия при которых доступно заверение/отказ в заверении
 * @param passport объект паспорта или сторка в журнале паспортов
 */
export function allowAssurance(passport: any) {
  const assuranceCorridor = passport?.passport?.assuranceCorridor || passport?.assuranceCorridor;
  const assuranceStatus = passport?.assuranceStatus;
  return assuranceCorridor === ASSURANCE_CORRIDOR.RED && assuranceStatus === AssuranceStatus.UNDER_CONSIDERATION;
}

/**
 * Условия при которых доступно "принять в работу"
 * @param passport объект паспорта или сторка в журнале паспортов
 */
export function allowTakeToWork(passport: any) {
  const assuranceCorridor = passport?.passport?.assuranceCorridor || passport?.assuranceCorridor;
  const assuranceStatus = passport?.assuranceStatus;
  return assuranceCorridor === ASSURANCE_CORRIDOR.RED && assuranceStatus === AssuranceStatus.SEND_FOR_VERIFICATION;
}

/**
 * Условия при которых доступно "Скачать QR-код":
 * Отображать кнопку Скачать QR-код только для заверенных Паспартов (статусы: Заверено ИС ЭСФ, Заверено ОГД после проверки)
 * @param passport объект паспорта или сторка в журнале паспортов
 */
export function allowDownloadQR(passport: any) {
  const assuranceStatus = passport?.assuranceStatus;
  return (
    assuranceStatus === AssuranceStatus.ASSURED_BY_ESF || assuranceStatus === AssuranceStatus.ASSURED_AFTER_VERIFICATION
  );
}
