import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceValidationErrors: DataQueryFunction = async ({ id }) => {
  // if (process.env.NODE_ENV === 'development') {
  //   return {
  //     data: {
  //       errors: [
  //         {
  //           documentId: 21495569101242368,
  //           property: 'customers',
  //           errorCode: 'MAIN_AND_FIXED_INVOICES_CUSTOMERS_NOT_EQUALS',
  //           text: 'Получатели основной и исправленной СФ не совпадают',
  //         },
  //         {
  //           documentId: 21495569101242368,
  //           property: 'customers[0].statuses',
  //           errorCode: 'MAIN_AND_FIXED_INVOICES_RETAIL_WRONG_STATUS',
  //           text: 'Отметка «J Розничная реализация» не совпадает со значением, указанным в основном ЭСФ',
  //         },
  //       ],
  //     },
  //   } as AxiosResponse;
  // }

  return httpClient.get(`/invoice/validationErrors/${id}`);
};
