import React from 'react';
import clsx from 'clsx';
import s from './QoldayHistoryModal.module.scss';
import { ModalContent, ModalHeader } from 'services/modal';
import { Loader, Table } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { queryAdminQoldayHistory } from '../api';

const getRowId = (row: any) => row?.id;

type Props = ModalChildrenProps & {
  className?: string;
  docId: string;
};

const getColumns = ({ loc, choose }: LocaleStore) => [
  {
    name: 'created',
    label: loc('qolday.statusDate'),
    style: {
      width: '65%',
    },
  },
  {
    name: 'status',
    label: loc('common.labels.status'),
    getContent: (row: any) => (row.status ? loc(`qolday.status.${row.status}`) : ''),
    style: {
      width: '35%',
    },
  },
];

export const QoldayHistoryModal: React.FC<Props> = ({ className, docId }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data } = useDataQuery(queryAdminQoldayHistory, { id: docId });

  const columns = getColumns(locale);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('qolday.historyTitle')}</ModalHeader>
      <ModalContent className={s.content}>
        {!data ? <Loader /> : <Table columns={columns} rows={data} variant="clean" getRowId={getRowId} />}
      </ModalContent>
    </div>
  );
};
