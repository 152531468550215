import React from 'react';
import { httpClientInject } from 'rest/httpClient';
import { RootStore } from './RootStore';

type Props = {};

const rootStore = new RootStore();

httpClientInject(rootStore);

export const storesContext = React.createContext<RootStore>(rootStore);

export const StoresProvider: React.FC<Props> = ({ children }) => {
  // Устанавливаем язык по умолчанию, пока не получили данные пользователя или для неавторизованого пользователя
  rootStore.locale.setDefaultLang();

  return <storesContext.Provider value={rootStore}>{children}</storesContext.Provider>;
};
