import React from 'react';
import clsx from 'clsx';
import s from './TableT2.module.scss';
import { TableTab } from 'ui/common/components/table/types';
import { SvgIcon } from 'ui/common/elements/svg-icon/SvgIcon';

type Props = {
  className?: string;
  tabClassName?: string;
  tabs?: Array<TableTab>;
  activeTabName?: string;
  onTabsChange?: (tab: TableTab) => void;
  disabled?: boolean;
};

export const TableT2Tabs: React.FC<Props> = ({
  className,
  tabClassName,
  tabs,
  activeTabName,
  onTabsChange,
  disabled,
}) => {
  return Array.isArray(tabs) ? (
    <div className={clsx(className, s.tabs)}>
      {tabs.map((tab) => (
        <div
          key={tab.name}
          className={clsx(s.tab, tabClassName, {
            [s.activeTab]: activeTabName === tab.name,
          })}
          style={tab.width ? { width: tab.width } : {}}
          onClick={() => typeof onTabsChange === 'function' && !disabled && onTabsChange(tab)}
        >
          <SvgIcon className={s.tabIcon} component={tab.icon} />
          {tab.label}
        </div>
      ))}
    </div>
  ) : null;
};
