import React, { Fragment, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './EaecMonitoringProducts.module.scss';
import { Loader, PageTitle, Table, TableColumn } from 'ui';
import { LocaleStore, useLocale } from 'services/l10n';
import { BackButton, CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import { useDataQuery } from 'rest';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import { queryAdminEecMonitoringDocId } from '../api';

const getProductRowId = (row: any, index: number) => row?.index;

type Props = {
  className?: string;
  onClose?: () => void;
  docId: string;
};

export const EaecMonitoringProducts: React.FC<Props> = ({ className, onClose, docId }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, error } = useDataQuery(queryAdminEecMonitoringDocId, {
    docId,
  });

  const productsColumns = useMemo(() => getProductsColumns({ locale }), [locale]);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('agreements.products')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <div className={s.inner}>
          {Array.isArray(data.eecMonitoringProducts) && (
            <Fragment>
              {/* <Heading className={s.goodAmount} tag="h3">
                {loc('agreements.products')}
              </Heading> */}
              <Table
                columns={productsColumns}
                rows={data.eecMonitoringProducts}
                getRowId={getProductRowId}
                scrollable
              />
            </Fragment>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

const getProductsColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'productPosition',
    label: loc('agreements.p.number'),
    style: { width: 100 },
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: { width: 120 },
  },
  {
    name: 'destinationCode',
    label: loc('agreements.p.destinationCode'),
  },
  {
    name: 'quantity',
    label: loc('common.labels.prodQuantity'),
    style: { width: 120 },
  },
  {
    name: 'measureUnitCode',
    label: loc('common.labels.measureUnitName'),
    getContent: (row: any) => <CodeValueMeasureUnit code={row.measureUnitCode} />,
  },
  {
    name: 'destinationCountryCode',
    label: loc('common.labels.countryCode'),
    getContent: (row: any) => <CodeValueCountry code={row.destinationCountryCode} format="codeWithName" />,
  },
];
