import React, { useCallback, useRef } from 'react';
import clsx from 'clsx';
import s from './FileInput.module.scss';
import { SvgIcon } from 'ui/common/elements/svg-icon';
import { ReactComponent as UploadIcon } from './upload.svg';
import { UITheme } from 'ui/common/types';

export type FileInputProps = {
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  label?: string;
  multiple?: boolean;
  accept?: string;
  theme?: UITheme;
};

export const FileInput: React.FC<FileInputProps> = ({
  onChange,
  onBlur,
  className,
  disabled,
  multiple,
  accept,
  label,
  fullWidth = true,
  theme = 'T1',
}) => {
  const labelRef = useRef<any>();

  const onLabelKeyDown = useCallback((e: React.KeyboardEvent<HTMLLabelElement>) => {
    if ([' ', 'Enter'].includes(e.key)) {
      typeof labelRef.current?.click === 'function' && labelRef.current?.click();
      e.preventDefault();
    }
  }, []);

  return (
    <label
      className={clsx(className, s.wrapper, s[theme], {
        [s.disabled]: disabled,
        [s.fullWidth]: fullWidth,
      })}
      onKeyDown={onLabelKeyDown}
      tabIndex={0}
      ref={labelRef}
    >
      <SvgIcon className={s.uploadIcon} component={UploadIcon}>
        <UploadIcon />
      </SvgIcon>
      <input
        className={s.input}
        type="file"
        onChange={onChange}
        onBlur={onBlur}
        multiple={multiple}
        disabled={disabled}
        accept={accept}
      />
      {label}
    </label>
  );
};
