import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryLoginTicket: DataQueryFunction = async () => {
  const res = await httpClient.get(`/ajax/login/ticket`, { responseType: 'text' });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
