import { FormLocaleDatePicker, FilterContentProps, FormListUploader } from 'common/components';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getGroupsOptions, getIsReadStatusOptions, getThemeOptions, MessageReadStatus } from '../constants';

type Props = FilterContentProps;

export const AdminMessageJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values } = form;

  const { columnClassName } = classNames || {};

  const groupsOptions = useMemo(() => getGroupsOptions({ locale, withNotSelected: true }), [locale]);
  const themeOptions = useMemo(() => getThemeOptions({ locale }), [locale]);
  const isReadStatusOptions = useMemo(() => getIsReadStatusOptions({ locale }), [locale]);

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="sendDateFrom" label={`${loc('adminMessages.sendDate')} ${loc('common.labels.from')}:`}>
          <FormLocaleDatePicker showTimeSelect />
        </FormField>
        <FormField name="sendDateTo" label={`${loc('adminMessages.sendDate')} ${loc('common.labels.to')}:`}>
          <FormLocaleDatePicker showTimeSelect />
        </FormField>

        <FormField name="recipientGroups" label={loc('adminMessages.group')}>
          <FormSelect options={groupsOptions} />
        </FormField>
        <FormField name="messageTheme" label={loc('adminMessages.messageTheme')}>
          <FormSelect options={themeOptions} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="recipientTin" label={loc('common.labels.recipientTin')}>
          <FormInput />
        </FormField>

        <Field label={loc('adminMessages.uploadFromFile')}>
          <FormListUploader name="recipientTins" labelCount={`${loc('adminMessages.iinsLoaded')}:`} />
        </Field>
      </div>
      <div className={columnClassName}>
        <FormField name="messageReadStatus" label={loc('adminMessages.isReadStatus')}>
          <FormSelect options={isReadStatusOptions} />
        </FormField>

        <FormField
          name="readDateFrom"
          label={`${loc('adminMessages.readDate')} ${loc('common.labels.from')}:`}
          disabled={values['messageReadStatus'] !== MessageReadStatus.READ}
          fast={false}
        >
          <FormLocaleDatePicker showTimeSelect />
        </FormField>
        <FormField
          name="readDateTo"
          label={`${loc('adminMessages.readDate')} ${loc('common.labels.to')}:`}
          disabled={values['messageReadStatus'] !== MessageReadStatus.READ}
          fast={false}
        >
          <FormLocaleDatePicker showTimeSelect />
        </FormField>

        <FormField
          name="tin"
          label={loc('adminMessages.readerIin')}
          disabled={values['messageReadStatus'] !== MessageReadStatus.READ}
          fast={false}
        >
          <FormInput />
        </FormField>
        <Field label={loc('adminMessages.readersFromFile')}>
          <FormListUploader name="tins" labelCount={`${loc('adminMessages.readersLoaded')}:`} />
        </Field>
      </div>
      <div className={columnClassName}>
        <FormField name="ipAddress" label={loc('common.labels.ipAddress')}>
          <FormInput />
        </FormField>
        <Field label={loc('adminMessages.ipsFromFile')}>
          <FormListUploader name="ipAddresses" labelCount={`${loc('adminMessages.ipsLoaded')}:`} />
        </Field>
      </div>
    </Fragment>
  );
};
