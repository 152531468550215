import { clearEmptyFields } from 'common/utils';
import { convertToFormData, DataMutationFunction, httpClient } from 'rest';

export const mutationAdminAssuranceDeleteAttachedDoc: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(clearEmptyFields(body));

  const res = await httpClient.post('/admin/assurance/deleteAttachedDoc', formData);
  if (res.data?.success) {
    return res;
  } else {
    console.log('here');
    throw res.data;
  }
};
