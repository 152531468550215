import React, { useEffect } from 'react';
import clsx from 'clsx';
import s from './SmallRetailHistory.module.scss';
import { ModalHeader } from 'services/modal';
import { Table, TableColumn } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { querySROHistory } from '../api/querySROHistory';
import { ModalContent } from 'services/modal/components/ModalContent';

type Props = ModalChildrenProps & {
  className?: string;
};

const getColumns = ({ loc, choose }: LocaleStore): Array<TableColumn> => [
  {
    name: 'isActive',
    label: loc('common.labels.status'),
    getContent: (row) => typeof row.isActive === 'boolean' && loc(`small.active.${row.isActive}`),
    style: {},
  },
  {
    name: 'date',
    label: loc('common.labels.date'),
    getContent: (row) => (row.isActive ? row.startDate : row.endDate),
    style: {},
  },
  {
    name: 'tin',
    label: loc('common.labels.iin'),
    style: {},
  },
];

export const SmallRetailHistory: React.FC<Props> = ({ className, onReady, show }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data, loading } = useDataQuery(querySROHistory);

  const columns = getColumns(locale);

  const rows = Array.isArray(data?.data?.collection) ? data?.data?.collection : [];

  useEffect(() => {
    if (!loading && typeof onReady === 'function') onReady();
  }, [loading, onReady]);

  return !show ? null : (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('small.smallRetailHistory')}</ModalHeader>
      <ModalContent className={s.content}>
        <div className={s.container}>
          <Table columns={columns} rows={rows} variant="clean" getRowId={(row: any) => row.iin} />
        </div>
      </ModalContent>
    </div>
  );
};
