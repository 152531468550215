import { LocaleStore } from 'services/l10n';

export enum QoldayStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const getQoldayStatusOptions = ({ loc }: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: QoldayStatus.ACTIVE,
    label: loc('qolday.status.ACTIVE'),
  },
  {
    value: QoldayStatus.INACTIVE,
    label: loc('qolday.status.INACTIVE'),
  },
];
