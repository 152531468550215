import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from '../PassportDocument.module.scss';
import { PACell } from './PACell';
import { useLocale } from 'services/l10n';
import { CertificateForm } from 'features/certificates';
import { CodeValuePackingType } from 'common/components';

type Props = {
  className?: string;
  data: any;
};

/**
 * Приложение 4. Сертификат происхождения
 */
export const PassportAttachmentCertificate: React.FC<Props> = ({ className, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.attachment)}>
      {Array.isArray(data) &&
        data.map((cert, index) =>
          cert.form === CertificateForm.CT01 ? (
            <div key={index} className={s.attInner}>
              <div className={s.attInnerHeader}>{loc('common.cert.CT01')}</div>
              <div className={s.attInnerBody}>
                <div className={clsx(s.attTable)}>
                  <div className={s.attGrid} style={{ gridTemplateColumns: 'repeat(4, 1fr)' }}>
                    <PACell span={2} label={loc('cert.senderName')} value={cert.senderName} />
                    <PACell span={2} label={loc('cert.senderAddress')} value={cert.senderAddress} />

                    {/* ////// */}

                    <PACell span={2} label={loc('cert.recipientName')} value={cert.recipientName} />
                    <PACell span={2} label={loc('cert.recipientAddress')} value={cert.recipientAddress} />

                    {/* ////// */}

                    <PACell span={1} label={loc('cert.transportType')} value={cert.transportType} />
                    <PACell span={1} label={loc('cert.transportType2')} value={cert.transportType} />
                    <PACell span={1} label={loc('cert.destinationPoint')} value={cert.destinationPoint} />
                    <PACell span={1} label={loc('cert.departurePoint')} value={cert.departurePoint} />

                    {/* ////// */}

                    <PACell span={1} label={loc('cert.number')} value={cert.number} />
                    <PACell
                      span={1}
                      label={loc('cert.certificateOriginCountry')}
                      value={cert.certificateOriginCountry || loc('common.country.KZ')}
                    />
                    <PACell span={1} label={loc('cert.toCountry')} value={cert.toCountry} />
                    <PACell span={1} label={loc('cert.invoiceDateNum')} value={cert.invoiceDateNum} />
                  </div>

                  {Array.isArray(cert.products) && (
                    <div className={s.attGrid} style={{ gridTemplateColumns: '70fr 391fr 391fr 140fr' }}>
                      <PACell span={1} label="№" bb noValue />
                      <PACell span={1} label={loc('cert.productName')} bb noValue />
                      <PACell span={1} label={loc('cert.criteria')} bb noValue />
                      <PACell span={1} label={loc('common.labels.prodQuantity')} bb noValue />
                      {[...cert.products].map((product: any, index) => (
                        <Fragment key={index}>
                          <PACell span={1} value={String(index + 1)} bt={false} />
                          <PACell span={1} value={product.productName} bt={false} />
                          <PACell span={1} value={product.criteria} bt={false} />
                          <PACell span={1} value={product.quantity} bt={false} />
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div key={index} className={s.attInner}>
              <div className={s.attInnerHeader}>{loc('common.cert.CTKZ')}</div>
              <div className={s.attInnerBody}>
                <div className={clsx(s.attTable)}>
                  <div className={s.attGrid} style={{ gridTemplateColumns: 'repeat(2, 1fr)' }}>
                    <PACell span={1} label={loc('cert.manufacturerName')} value={cert.manufacturerName} />
                    <PACell span={1} label={loc('cert.manufacturerAddress')} value={cert.manufacturerAddress} />

                    {/* ////// */}

                    <PACell span={1} label={loc('cert.recipientName2')} value={cert.recipientName} />
                    <PACell span={1} label={loc('cert.recipientAddress2')} value={cert.recipientAddress} />

                    {/* ////// */}

                    <PACell span={1} label={loc('cert.aim')} value={cert.aim} />
                    <PACell span={1} label={loc('cert.number')} value={cert.number} />

                    {/* ////// */}

                    <PACell
                      span={1}
                      label={loc('cert.certificateOriginCountry')}
                      value={cert.certificateOriginCountry || loc('common.country.KZ')}
                    />
                    <PACell span={1} label={loc('cert.note')} value={cert.note} />
                  </div>

                  {Array.isArray(cert.products) && (
                    <div className={s.attGrid} style={{ gridTemplateColumns: '70fr 210fr 287fr 287fr 140fr' }}>
                      <PACell span={1} label="№" bb noValue />
                      <PACell span={1} label={loc('cert.quantNtype')} bb noValue />
                      <PACell span={1} label={loc('cert.productName')} bb noValue />
                      <PACell span={1} label={loc('cert.criteria')} bb noValue />
                      <PACell span={1} label={loc('cert.weight')} bb noValue />
                      {[...cert.products].map((product: any, index) => (
                        <Fragment key={index}>
                          <PACell span={1} value={String(index + 1)} bt={false} />
                          <PACell
                            span={1}
                            value={
                              <Fragment>
                                <span>{product.cargoQuantity}</span> <CodeValuePackingType code={product.packingType} />
                              </Fragment>
                            }
                            bt={false}
                          />
                          <PACell span={1} value={product.productName} bt={false} />
                          <PACell span={1} value={product.criteria} bt={false} />
                          <PACell span={1} value={product.weight} bt={false} />
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        )}
    </div>
  );
};
