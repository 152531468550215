import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import s from './IsgoNotificationModal.module.scss';
import { ButtonT2, Loader, TableColumn, TableT2 } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { useStore } from 'common/hooks';
import { LocaleStore } from 'services/l10n';

type Props = ModalChildrenProps & {
  className?: string;
  invoices: Array<any>;
  notification: any;
  // onAnswer?: (type: 'CONFIRM' | 'DECLINE') => void;
  submitting: boolean;
  onDone: () => void;
  onConfirm: (rows: any[]) => void;
  onDecline: (rows: any[]) => void;
};

/**
 * Проверка наличия у юзера ИС ГО уведомлений
 * */
export const IsgoNotificationModal: React.FC<Props> = ({
  className,
  invoices,
  notification,
  onConfirm,
  onDecline,
  submitting,
}) => {
  const { locale } = useStore();
  const { loc } = locale;

  const [selected, setSelected] = useState<any[]>([]);

  const columns = getTableColumns({ locale });

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header className={s.header}>{loc(`c.isgoNotify.title`)}</ModalT2Header>
      <ModalT2Content className={s.content}>
        {notification && Array.isArray(invoices) ? (
          <Fragment>
            <h3>{loc('c.isgoNotify.text1')}</h3>
            <p>
              {loc('c.isgoNotify.text2', [
                notification.senderName,
                notification.senderTin,
                notification.isgoSystemName,
              ])}
            </p>
            <p>{loc('c.isgoNotify.text3')}</p>
            {Array.isArray(invoices) && (
              <TableT2
                rows={invoices}
                columns={columns}
                selectable
                variant="rounded"
                onChangeSelected={(rows) => setSelected(rows)}
              />
            )}
          </Fragment>
        ) : (
          <Loader overlay />
        )}
      </ModalT2Content>
      <ModalT2Footer>
        <ButtonT2
          onClick={() => onDecline(selected)}
          variant="transparent"
          loading={submitting}
          disabled={!selected.length}
        >
          {loc('c.isgoNotify.decline')}
        </ButtonT2>
        <ButtonT2 onClick={() => onConfirm(selected)} loading={submitting} disabled={!selected.length}>
          {loc('common.button.confirm')}
        </ButtonT2>
      </ModalT2Footer>
    </div>
  );
};

const getTableColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'invoiceRegistrationNumber',
    label: loc('common.labels.registrationNumber'),
    width: 320,
  },

  {
    name: 'invoiceType',
    label: loc('c.esf.type'),
    getContent: (row) => (row.invoiceType ? loc(`c.esf.type.${row.invoiceType}`) : ''),
    width: 280,
  },

  // {
  //   name: 'invoiceStatus',
  //   label: loc('common.esf.status'),
  //   getContent: (row) => (row.invoiceStatus ? loc(`common.esf.status.${row.invoiceStatus}`) : ''),
  //   width: 155,
  // },
];
