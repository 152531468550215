import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryDocumentFindIdByRegNumber: DataQueryFunction = async (variables: any) => {
  const { registrationNumber } = variables || {};

  const res = await httpClient.get(`/document/findIdByRegNumber`, {
    params: { registrationNumber },
  });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
