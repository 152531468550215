export const ADMIN_PERMISSION = {
  /* 1. Администрирование: */

  // Управление пользователями АРМ
  USERS_MANAGE: 'USERS_MANAGE',
  // Выгрузка пользователей ОГД
  OGD_USERS_EXPORT: 'OGD_USERS_EXPORT',
  // Просмотр пользователей ОГД
  OGD_USERS_VIEW: 'OGD_USERS_VIEW',
  // Журнал действий внутренних пользователей
  USER_ACCESS_INTERNAL_VIEW: 'USER_ACCESS_INTERNAL_VIEW',
  // Журнал действий внешних пользователей
  USER_ACCESS_EXTERNAL_VIEW: 'USER_ACCESS_EXTERNAL_VIEW',
  // Генерирование кода для таможенной службы
  EAEC_USERS_MANAGE: 'EAEC_USERS_MANAGE',

  /* 2. Мониторинг ИС ЭСФ: */

  // Параметры системы
  SYSTEM_PARAMS: 'SYSTEM_PARAMS',
  // Рассылка сообщений
  MESSAGES_SEND: 'MESSAGES_SEND',
  // Просмотр «Журнал сообщений»
  MESSAGES_VIEW: 'MESSAGES_VIEW',
  // Оповещения
  NOTIFICATION_TEMPLATES_EDIT: 'NOTIFICATION_TEMPLATES_EDIT',
  // Управление ПО
  SOFT_MANAGE: 'SOFT_MANAGE',
  // Мониторинг
  MONITORING: 'MONITORING',
  // Статистика
  STATISTICS_VIEW: 'STATISTICS_VIEW',
  // Просмотр протоколов простоя системы
  DOWNTIME_VIEW: 'DOWNTIME_VIEW',
  // Ведение протоколов простоя системы
  DOWNTIME_EDIT: 'DOWNTIME_EDIT',
  // Публикация новостей
  NEWS_PUBLISH: 'NEWS_PUBLISH',
  // Удаление новостей
  NEWS_DELETE: 'NEWS_DELETE',
  // Просмотр календаря рабочих дней
  CALENDAR_VIEW: 'CALENDAR_VIEW',
  // Ведение календаря рабочих дней
  CALENDAR_EDIT: 'CALENDAR_EDIT',

  /* 3. Регистрационный учет:  */

  // Поиск налогоплательщиков
  TAXPAYER_SEARCH: 'TAXPAYER_SEARCH',
  // Просмотр назначенных полномочий сотрудника предприятия
  PERMISSION_VIEW: 'PERMISSION_VIEW',
  // Поиск, просмотр и печать Соглашений
  AGREEMENT_ACTIONS: 'AGREEMENT_ACTIONS',
  // Перерегистрация
  REREGISTRATION_VIEW: 'REREGISTRATION_VIEW',
  // Проверка действующей регистрации
  CHECK_ACTIVE_REGISTRATION: 'CHECK_ACTIVE_REGISTRATION',
  // Просмотр блокировки пользователей системы
  TAXPAYER_BLOCK_VIEW: 'TAXPAYER_BLOCK_VIEW',
  // Ведение блокировки пользователей системы
  TAXPAYER_BLOCK_EDIT: 'TAXPAYER_BLOCK_EDIT',
  // Просмотр журнала МПТ
  MPT_VIEW: 'MPT_VIEW',

  /* 4. Виртуальный склад:  */

  // Просмотр виртуальных меток
  VIRTUAL_LABEL_VIEW: 'VIRTUAL_LABEL_VIEW',
  // Просмотр цепочек виртуальных меток
  VIRTUAL_LABEL_CHAIN_VIEW: 'VIRTUAL_LABEL_CHAIN_VIEW',
  // Просмотр складов
  TAXPAYER_STORE_VIEW: 'TAXPAYER_STORE_VIEW',
  // Просмотр ФНО 328.00
  FNO_VIEW: 'FNO_VIEW',
  // Просмотр ДТ
  GTD_VIEW: 'GTD_VIEW',
  // Просмотр форм ВС
  UFORM_VIEW: 'UFORM_VIEW',
  //Просмотр остатков товара на ВС
  VIEW_VIRTUAL_LABEL_REMAINDERS: 'VIEW_VIRTUAL_LABEL_REMAINDERS',
  //Просмотр журнала ЗВТ
  DOCUMENT_ZVT_VIEW: 'DOCUMENT_ZVT_VIEW',

  /* 5. Электронные счета-фактуры:  */

  // Поиск, просмотр и печать ЭСФ
  INVOICE_VIEW: 'INVOICE_VIEW',
  // Аннулирование и восстановление ЭСФ
  INVOICE_ACTIONS: 'INVOICE_ACTIONS',
  // Журнал оплаты на КС счет
  NDS_CUSTOM_MANIFEST_PAYMENTS_VIEW: 'NDS_CUSTOM_MANIFEST_PAYMENTS_VIEW',
  // Поиск запросов в ИС ГО
  ISGO_INVOICE_SEARCH: 'ISGO_INVOICE_SEARCH',
  // Просмотр отчета по визуализации продуктов ЭСФ
  INVOICE_PRODUCTS_VIEW: 'INVOICE_PRODUCTS_VIEW',
  // Разблокировка блокированных ЭСФ по данным ИС ГО
  ISGO_UNLOCK: 'ISGO_UNLOCK',

  /* 6. Акты выполненных работ:  */

  // Просмотр АВР
  AWP_VIEW: 'AWP_VIEW',

  /* 7. Электронные договоры:  */

  // Просмотр ЭД
  ECONTRACT_VIEW: 'ECONTRACT_VIEW',

  /* 8. Сопроводительные накладные на товары:  */

  // Просмотр СНТ
  SNT_VIEW: 'SNT_VIEW',
  // Просмотр отчета по визуализации продуктов СНТ
  SNT_PRODUCTS_VIEW: 'SNT_PRODUCTS_VIEW',
  // Контроль сроков СНТ
  SNT_EXPIRE_CONTROL: 'SNT_EXPIRE_CONTROL',
  // Изменение сроков подтверждения\отклонения
  SNT_CONFIRMATION_CONTROL: 'SNT_CONFIRMATION_CONTROL',

  /* 9. Экспортный контроль:  */

  // Просмотр реестра импорт/экспорт/транзит
  TRANSIT_REGISTRY_VIEW: 'TRANSIT_REGISTRY_VIEW',
  // Редактирование реестра импорт/экспорт/транзит
  TRANSIT_REGISTRY_EDIT: 'TRANSIT_REGISTRY_EDIT',
  // Просмотр разрешительных документов
  PERMISSIVE_DOCUMENT_VIEW: 'PERMISSIVE_DOCUMENT_VIEW',
  // Просмотр СНТ в рамках ЕАЭС
  SNT_EAES_VIEW: 'SNT_EAES_VIEW',
  // Подтверждение/отклонение СНТ инспектором ОГД
  SNT_OGD_ACTIONS: 'SNT_OGD_ACTIONS',
  // Изменение даты и времени пересечения границы в СНТ
  SNT_OGD_EDIT: 'SNT_OGD_EDIT',

  /* 10. Просмотр справочников:
   * 11. Ведение справочников:
   */

  // Банки (Просмотр)
  BANK_VIEW: 'BANK_VIEW',
  // Крупные налогоплательщики (Просмотр, Ведение)
  BIG_COMPANY_VIEW: 'BIG_COMPANY_VIEW',
  BIG_COMPANY_EDIT: 'BIG_COMPANY_EDIT',
  // Курсы валют (Просмотр, Ведение)
  CURRENCY_RATE_VIEW: 'CURRENCY_RATE_VIEW',
  CURRENCY_RATE_EDIT: 'CURRENCY_RATE_EDIT',
  // Единицы измерения (Просмотр, Ведение)
  MEASURE_UNIT_VIEW: 'MEASURE_UNIT_VIEW',
  MEASURE_UNIT_EDIT: 'MEASURE_UNIT_EDIT',
  // Участник СРП (Просмотр, Ведение)
  SHARING_PARTICIPANT_VIEW: 'SHARING_PARTICIPANT_VIEW',
  SHARING_PARTICIPANT_EDIT: 'SHARING_PARTICIPANT_EDIT',
  // ГСВС (Просмотр, Ведение)
  GSVS_VIEW: 'GSVS_VIEW',
  GSVS_EDIT: 'GSVS_EDIT',
  // Список реорганизованных организаций (Просмотр, Ведение)
  REORGANIZATION_VIEW: 'REORGANIZATION_VIEW',
  REORGANIZATION_EDIT: 'REORGANIZATION_EDIT',
  // ПИН-коды нефтепродуктов (Просмотр, Ведение)
  OIL_PIN_VIEW: 'OIL_PIN_VIEW',
  OIL_PIN_EDIT: 'OIL_PIN_EDIT',
  // ПИН-коды табачный изделий (Просмотр, Ведение)
  TOBACCO_PIN_VIEW: 'TOBACCO_PIN_VIEW',
  TOBACCO_PIN_EDIT: 'TOBACCO_PIN_EDIT',
  // ПИН-коды алкогольной продукции (Просмотр, Ведение)
  ALCOHOL_PIN_VIEW: 'ALCOHOL_PIN_VIEW',
  ALCOHOL_PIN_EDIT: 'ALCOHOL_PIN_EDIT',
  // Объекты ОВД нефтепродуктов (Просмотр, Ведение)
  OIL_OVD_VIEW: 'OIL_OVD_VIEW',
  OIL_OVD_EDIT: 'OIL_OVD_EDIT',
  // Объекты ОВД табачных изделий (Просмотр, Ведение)
  TOBACCO_OVD_VIEW: 'TOBACCO_OVD_VIEW',
  TOBACCO_OVD_EDIT: 'TOBACCO_OVD_EDIT',
  // Лицензии алкогольной продукции (Просмотр, Ведение)
  ALCOHOL_LICENSE_VIEW: 'ALCOHOL_LICENSE_VIEW',
  ALCOHOL_LICENSE_EDIT: 'ALCOHOL_LICENSE_EDIT',
  // Крепость алкогольной продукции и этилового спирта (Просмотр, Ведение)
  ALCOHOL_SPIRIT_PERCENT_VIEW: 'ALCOHOL_SPIRIT_PERCENT_VIEW',
  ALCOHOL_SPIRIT_PERCENT_EDIT: 'ALCOHOL_SPIRIT_PERCENT_EDIT',
  // Ставки акциза (Просмотр, Ведение)
  EXCISE_RATE_VIEW: 'EXCISE_RATE_VIEW',
  EXCISE_RATE_EDIT: 'EXCISE_RATE_EDIT',
  // Товары под запретом (Просмотр, Ведение)
  BANNED_GOOD_VIEW: 'BANNED_GOOD_VIEW',
  BANNED_GOOD_EDIT: 'BANNED_GOOD_EDIT',
  // Пункты пропуска (Просмотр, Ведение)
  PLANNED_CHECKPOINT_VIEW: 'PLANNED_CHECKPOINT_VIEW',
  PLANNED_CHECKPOINT_EDIT: 'PLANNED_CHECKPOINT_EDIT',
  // Список НП признанных банкротами, бездействующими, непричастными к импорту
  TAXPAYER_MARK_VIEW: 'TAXPAYER_MARK_VIEW',
  TAXPAYER_MARK_EDIT: 'TAXPAYER_MARK_EDIT',
  // Органы государственных доходов (Просмотр)
  OGD_VIEW: 'OGD_VIEW',
  // Список ФИО перевозчиков для МЭК (Просмотр, Ведение)
  ECM_CARRIER_VIEW: 'ECM_CARRIER_VIEW',
  ECM_CARRIER_EDIT: 'ECM_CARRIER_EDIT',
  // Список АТС для МЭК (Просмотр, Ведение)
  ECM_AUTO_EDIT: 'ECM_AUTO_EDIT',
  ECM_AUTO_VIEW: 'ECM_AUTO_VIEW',
  // ИС ГО (Просмотр, Ведение)
  ISGO_VIEW: 'ISGO_VIEW',
  ISGO_EDIT: 'ISGO_EDIT',
  /**
   * Просмотр справочника МТТ
   */
  SMALL_RETAIL_OUTLET_VIEW: 'SMALL_RETAIL_OUTLET_VIEW',
  /**
   * Ведение справочника МТТ
   */
  SMALL_RETAIL_OUTLET_EDIT: 'SMALL_RETAIL_OUTLET_EDIT',

  /**
   * Просмотр справочника Участники проекта Е-Tamga
   */
  QOLDAY_VIEW: 'QOLDAY_VIEW',

  /**
   * Просмотр справочника НП для регистрации без биометрии
   */
  BIOMETRICS_NOT_REQUIRED_TAXPAYER_VIEW: 'BIOMETRICS_NOT_REQUIRED_TAXPAYER_VIEW',
  /**
   * Ведение справочника НП для регистрации без биометрии
   */
  BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT: 'BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT',

  /**
   *  Справочник Рисковых НП для прохождения биометрии (Просмотр)
   */
  BIOMETRICS_RISKY_TAXPAYER_VIEW: 'BIOMETRICS_RISKY_TAXPAYER_VIEW',
  /**
   * Справочник Рисковых НП для прохождения биометрии (Ведение)
   */
  BIOMETRICS_RISKY_TAXPAYER_EDIT: 'BIOMETRICS_RISKY_TAXPAYER_EDIT',

  /**
   * Справочник государственных учреждений (Просмотр)
   */
  GOVERNMENT_INSTITUTION_VIEW: 'GOVERNMENT_INSTITUTION_VIEW',
  /**
   * Справочник государственных учреждений (Ведение)
   */
  GOVERNMENT_INSTITUTION_EDIT: 'GOVERNMENT_INSTITUTION_EDIT',

  /* 12. Отчетность:
   *  − Статистический отчет;
   *  − Отчет по зарегистрированным участникам;
   *  - Дашборды в мобильном приложении.
   */

  //Отчет по экспортному контролю
  TRANSIT_REPORT: 'TRANSIT_REPORT',
  // Статистический отчет
  STATISTIC_REPORT: 'STATISTIC_REPORT',
  // Отчет о зарегистрированных участниках
  REGISTERED_USER_REPORT: 'REGISTERED_USER_REPORT',
  // Дашборды в мобильном приложении
  STATISTIC_REPORT_MOBILE_DASHBOARD: 'STATISTIC_REPORT_MOBILE_DASHBOARD',

  // Формирование отчетности МЭК
  SNT_BORDER_CROSSING_REPORT: 'SNT_BORDER_CROSSING_REPORT',

  // Отчет по количеству участников ИС ЭСФ и документообороту
  STATISTIC_DOCFLOW_REPORT: 'STATISTIC_DOCFLOW_REPORT',

  //Просмотр сертификатов CT-1,CT-KZ
  CERTIFICATE_VIEW: 'CERTIFICATE_VIEW',

  /**
   * Отчетность: Выгрузка отчета "Оборот.-сальд. ведомость"
   */
  SALDO_VIEW: 'SALDO_VIEW',

  /* 13. Паспорт прослеживаемости
   *  − Статистический отчет;
   *  − Отчет по зарегистрированным участникам.
   */
  ASSURANCE_VIEW: 'ASSURANCE_VIEW',
  ASSURANCE_ACTIONS: 'ASSURANCE_ACTIONS',

  /**
   * 14. Взаимодействие со странами ЕАЭС
   */
  /**
   * Отправка запроса сведений об операциях, предшествующих перемещению товаров в страны ЕАЭС
   */
  EEC_SEND_TRACEABILITY_GOODS_REQUEST: 'EEC_SEND_TRACEABILITY_GOODS_REQUEST',
  /**
   * Просмотр сведений о товарах и связанных с ними операциях из стран ЕАЭС
   */
  EEC_VIEW_TRACEABILITY_GOODS_INPUT_REQUEST: 'EEC_VIEW_TRACEABILITY_GOODS_INPUT_REQUEST',
  /**
   * Просмотр запросов по предоставлению сведений о товарах и связанных с ними операциях в страны ЕАЭС
   */
  EEC_VIEW_TRACEABILITY_GOODS_OUTPUT_REQUEST: 'EEC_VIEW_TRACEABILITY_GOODS_OUTPUT_REQUEST',
  /**
   * Просмотр сведений об операциях, предшествующих перемещению товаров, подлежащих прослеживаемости из стран ЕАЭС
   */
  EEC_VIEW_OPERATIONS_OUTPUT_REQUEST: 'EEC_VIEW_OPERATIONS_OUTPUT_REQUEST',
  /**
   * Просмотр запросов по предоставлению сведений об операциях, предшествующих перемещению товаров, подлежащих прослеживаемости в страны ЕАЭС
   */
  EEC_VIEW_OPERATIONS_INPUT_REQUEST: 'EEC_VIEW_OPERATIONS_INPUT_REQUEST',
  /**
   * Просмотр запросов по предоставлению Комиссии сведений в целях осуществления контроля и мониторинга исполнения Соглашения
   */
  EEC_VIEW_MONITORING_REQUEST: 'EEC_VIEW_MONITORING_REQUEST',
  /**
   * Журнал транзакций по взаимодействию с ЕАЭС и ЕЭК (просмотр)
   */
  EEC_VIEW_TRANSACTION: 'EEC_VIEW_TRANSACTION',
} as const;

export type AdminPermission = typeof ADMIN_PERMISSION[keyof typeof ADMIN_PERMISSION];
