import React, { useState } from 'react';
import clsx from 'clsx';
import s from './ActionReasonModal.module.scss';
import { Button, FormField, FormTextarea } from 'ui/common';
import { ModalFooter, ModalHeader } from 'services/modal';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';

const schema = yup.object({
  reason: yup.string().required('Обязательное поле'),
});

type Props = ModalChildrenProps & {
  className?: string;
  onSubmit(reason: string): Promise<any>;
};

export const ActionReasonModal: React.FC<Props> = ({ className, onSubmit, onClose = () => {} }) => {
  const locale = useLocale();
  const { loc } = locale;
  const [disabled, setDisabled] = useState(false);

  const initialValues = {};

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    setDisabled(true);
    await onSubmit(values.reason);
    setDisabled(false);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('assurance.reasonModalTitle')}</ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={submitHandler}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {() => (
          <Form className={clsx(className, s.wrapper)}>
            <div className={s.body}>
              <FormField name={`reason`} label={loc('assurance.declineReason')} fast={false}>
                <FormTextarea readOnly={disabled} />
              </FormField>
            </div>
            <ModalFooter>
              <Button type="submit" variant="secondary" onClick={() => onClose()}>
                {loc('common.button.cancel')}
              </Button>
              <Button type="submit" onClick={() => {}} disabled={disabled}>
                {loc('common.button.signAndSend')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};
