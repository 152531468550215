import { queryDeliveryCondition } from 'rest';
import { LocaleStore } from 'services/l10n';

export async function getDeliveryConditionOptions({ loc, choose }: LocaleStore) {
  const res = await queryDeliveryCondition();
  if (Array.isArray(res.data)) {
    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...res.data.map((item: any) => ({
        ...item,
        description: choose([item.descriptionRu, item.descriptionKz]),
        name: choose([item.nameRu, item.nameKz]),
      })),
    ];
  }
  return [];
}

export const getDeliveryConditionOptionLabel = (data: any) => {
  return data.label ?? `${data.code} - ${data.name}`;
};

export const getDeliveryConditionOptionValue = (data: any) => {
  return data.value ?? data.code;
};
