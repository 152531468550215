import React, { ReactNode } from 'react';
import clsx from 'clsx';
import s from './Toast.module.scss';
import { ReactComponent as FlatWarningIcon } from './icons/flat-warning.svg';
import { ReactComponent as FlatErrorIcon } from './icons/flat-error.svg';
import { ReactComponent as FlatSuccesIcon } from './icons/flat-success.svg';
import { ReactComponent as FlatInfoIcon } from './icons/flat-info.svg';
import { SvgIcon } from 'ui/common/elements/svg-icon/SvgIcon';
import DOMPurify from 'dompurify';

type Props = {
  className?: string;
  message?: string;
  type: 'error' | 'success' | 'warning' | 'info';
  action?(): void;
  closeToast?(): void;
  // Варинат default убран, ипользуется только flat
  variant?: 'default' | 'flat';
  children?: ReactNode;
};

const icons = {
  default: {
    error: FlatErrorIcon,
    success: FlatSuccesIcon,
    warning: FlatWarningIcon,
    info: FlatInfoIcon,
  },
  flat: {
    error: FlatErrorIcon,
    success: FlatSuccesIcon,
    warning: FlatWarningIcon,
    info: FlatInfoIcon,
  },
} as const;

export const Toast: React.FC<Props> = ({ className, message, type, variant = 'flat', children }) => {
  const Icon = icons[variant][type];
  return (
    <div className={clsx(s.wrapper, className, s[type], s[variant])}>
      <SvgIcon className={s.icon}>
        <Icon />
      </SvgIcon>
      <div
        className={s.message}
        dangerouslySetInnerHTML={message ? { __html: DOMPurify.sanitize(message) } : undefined}
      >
        {children}
      </div>
    </div>
  );
};
