import React, { ChangeEvent } from 'react';
import clsx from 'clsx';
import s from './Table.module.scss';
import { Button, ButtonIcon, Checkbox } from 'ui/common/controls';
import { RowAction, TableColumn } from './types';
import { getCellContent } from './helpers';

export type TableRowProps = {
  row: any;
  rowIndex: number;
  columns: Array<TableColumn>;
  getRowId: (row: any, index: number) => string | number;
  getRowStyle: (row: any) => { [key: string]: string } | undefined;
  getRowActions?: (row: any) => Array<RowAction>;
  onSelect: (row: any, index: number, selected: boolean, selectOne?: boolean) => void;
  selectable: boolean;
  selected?: boolean;
  actionColumn?: boolean;
  dragable?: boolean;
  dropable?: boolean;
  onDrop?: (item: any, row: any, index: number) => void;
};

export const TableRow = React.forwardRef<any, TableRowProps>(
  (
    { row, columns, getRowStyle, getRowActions, onSelect, selectable, selected = false, actionColumn, rowIndex },
    ref
  ) => {
    const onRowClick = () => {
      if (!selectable) return;
      onSelect(row, rowIndex, true, true);
    };

    const selectHandle = (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      onSelect(row, rowIndex, checked);
    };

    const rowActions = typeof getRowActions === 'function' ? getRowActions(row) : undefined;

    return (
      <tr
        style={getRowStyle(row)}
        className={clsx({ [s.showHover]: !actionColumn, [s.selected]: selected })}
        onClick={() => onRowClick()}
        ref={ref}
      >
        {selectable && (
          <td className={s.checkCell}>
            <Checkbox className={s.checkBox} checked={selected} onChange={selectHandle} />
          </td>
        )}
        {columns.map((col) => {
          const content = getCellContent(row, rowIndex, col);
          return (
            <td
              key={col.name}
              style={{ ...(col.style || {}), ...(col.width ? { width: col.width } : {}) }}
              title={
                col.getTitle
                  ? col.getTitle(row)
                  : typeof content === 'string' || typeof content === 'number'
                  ? String(content)
                  : undefined
              }
            >
              {content}
            </td>
          );
        })}
        {rowActions ? (
          actionColumn ? (
            <td className={s.actionColumn}>
              {rowActions.map((action) => (
                <ButtonIcon
                  key={action.id}
                  className={s.actionIcon}
                  iconName={action.iconName}
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                    if (typeof action.onClick === 'function') {
                      action.onClick(e);
                    }
                  }}
                />
              ))}
            </td>
          ) : (
            <td className={s.actionRow}>
              {rowActions.map((action) => (
                <Button
                  key={action.id}
                  className={s.actionButton}
                  variant="primary"
                  size="small"
                  onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                    if (typeof action.onClick === 'function') {
                      action.onClick(e);
                    }
                  }}
                >
                  {action.label}
                </Button>
              ))}
            </td>
          )
        ) : null}
      </tr>
    );
  }
);
