import React, { useState } from 'react';
import clsx from 'clsx';
import s from './MessageForConfirmationModal.module.scss';
import { ButtonT2 } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { useStore } from 'common/hooks';
import { mutationMessagesConfirm } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { formatFromDate } from 'services/date-time';
import { Lang } from 'services/l10n';
import DOMPurify from 'dompurify';

type Props = ModalChildrenProps & {
  className?: string;
  message: any;
  onDone: () => void;
};

export const MessageForConfirmationModal: React.FC<Props> = ({ className, message, onDone }) => {
  const { locale } = useStore();
  const { loc, lang } = locale;

  const [loading, setLoading] = useState(false);

  const onAccept = async () => {
    setLoading(true);

    try {
      await mutationMessagesConfirm({
        body: {
          messageId: message.id,
        },
      });
      typeof onDone === 'function' && onDone();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      setLoading(false);
    }
  };

  const blockingPDFUrl = isBlockingMessage(message.text || message.textKz || '');

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header className={s.header}>{loc(`c.message.necessarily`)}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <span>
          {formatFromDate(new Date(message.createdTimeStamp))}
          {' - '}
          {loc('c.message.from')} <b>{loc('c.message.isEsf')}</b>:
        </span>
        <div
          style={{ marginTop: '10px' }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(lang === Lang.RU ? message.text : message.textKz) }}
        ></div>
        {!!blockingPDFUrl && (
          <iframe title="blockingPDFUrl" id="blockingPDF" width="100%" height="1080" src={blockingPDFUrl} />
        )}
      </ModalT2Content>
      <ModalT2Footer className={s.footer}>
        <span className={s.caption}>{loc('c.message.forConfirm')}</span>
        <ButtonT2 className={s.button} onClick={onAccept} disabled={loading}>
          {loc('c.message.read')}
        </ButtonT2>
      </ModalT2Footer>
    </div>
  );
};

/**
 *  Проверяет что текст содержит ссылку на файл PDF блокировки и возвращает url для встраивания
 */
function isBlockingMessage(message: string) {
  const blockingNotifyMatch = message.match(/href="(.+blocking\/doc\/(?:BLOCKING|RECOVERY)\/\d+)"/);
  if (blockingNotifyMatch) return blockingNotifyMatch[1] + '?isInline=true';
}
