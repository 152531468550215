import React, { useCallback, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import clsx from 'clsx';
import './Modal.scss';
import s from './Modal.module.scss';
import { ButtonIcon, UITheme } from 'ui/common';
import { ModalChildrenProps } from './types';

if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#root');

type Props = {
  className?: string;
  children: React.ReactElement | ((props: ModalChildrenProps) => React.ReactElement);
  isOpen: boolean;
  onClose?(result?: any): void;
  /**
   * Вызывается после показа модального окна
   */
  onShow?(): void;
  variant?: 'primary';
  center?: boolean;
  marginTop?: number;
  scrollLock?: boolean;
  wait?: boolean;
  theme?: UITheme;
};

export const Modal: React.FC<Props> = ({
  children,
  onClose,
  onShow,
  isOpen,
  center = false,
  marginTop,
  className,
  scrollLock = true,
  wait,
  theme = 'T1',
}) => {
  const [show, setShow] = useState<boolean>(wait ? false : true);

  const onShowRef = useRef(onShow);
  onShowRef.current = onShow;

  const onAfterOpen = () => {
    if (scrollLock) {
      document.querySelector('body')?.addEventListener('wheel', preventScroll, { passive: false });
    }
    !wait && typeof onShowRef.current === 'function' && onShowRef.current();
  };

  const onAfterClose = () => {
    document.querySelector('body')?.removeEventListener('wheel', preventScroll);
    if (wait) setShow(false);
  };

  const onReady = useCallback(() => {
    setShow(true);
    typeof onShowRef.current === 'function' && onShowRef.current();
  }, []);

  const childrenProps = { show, onClose, onReady: wait ? onReady : undefined };

  return isOpen ? (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      className={clsx(s.modal, className)}
      overlayClassName={clsx(s.modalOverlay, s[theme], {
        [s.center]: center,
        [s.waiting]: !show,
      })}
      bodyOpenClassName={s.modalOpened}
      style={{ content: marginTop ? { marginTop } : {} }}
    >
      {typeof onClose === 'function' && (
        <ButtonIcon
          className={s.closeButton}
          iconName={theme === 'T2' ? 'close-bold' : 'close'}
          iconClassName={s.closeIcon}
          onClick={() => onClose()}
        />
      )}
      {typeof children === 'function'
        ? children(childrenProps)
        : 'type' in children
        ? React.cloneElement(children, childrenProps)
        : null}
    </ReactModal>
  ) : null;
};

function preventScroll(e: Event) {
  e.preventDefault();
  e.stopPropagation();
  return false;
}
