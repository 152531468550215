import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getSntTermsRegistrationColumns = ({
  locale: { loc },
}: {
  locale: LocaleStore;
}): Array<TableColumn> => [
  {
    name: 'registrationNumber',
    label: loc('sntTerms.registrationNumber'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'number',
    label: loc('sntTerms.number'),
    style: {
      width: '270px',
    },
  },
  {
    name: 'date',
    label: loc('sntTerms.regDate'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'shippingDate',
    label: loc('sntTerms.shippingDate'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'sntType',
    label: loc('common.snt.type'),
    getContent: (row) => !!row.sntType && loc(`common.snt.type.${row.sntType}`),
    style: {
      width: '200px',
    },
  },
  {
    name: 'status',
    label: loc('common.snt.status'),
    getContent: (row) => !!row.status && loc(`common.snt.status.${row.status}`),
    style: {
      width: '200px',
    },
  },
  {
    name: 'sellerTin',
    label: loc('common.labels.sellerTin'),
    style: {
      width: '250px',
    },
  },
  {
    name: 'sellerName',
    label: loc('common.labels.sellerName'),
    style: {
      width: '250px',
    },
  },
  {
    name: 'customerTin',
    label: loc('common.labels.recipientTin'),
    style: {
      width: '250px',
    },
  },
  {
    name: 'customerName',
    label: loc('common.labels.recipientName'),
    style: {
      width: '250px',
    },
  },
];
