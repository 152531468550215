import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';

export const mutationInvoiceDraftChangeOwner: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body } = variables;

  const formData = convertToFormData({
    ...body,
  });

  const res = await httpClient.put('invoice/drafts/changeOwner', formData);
  if (res?.data?.success === true) {
    return res;
  } else {
    throw res;
  }
};
