import { API_URL } from 'common/constants';
import { solveErrorMessage } from 'common/form/helpers';
import { downloadFile, querySntDownloadXmlSono } from 'rest';
import { ToastService } from 'services/toasts';

type Config = {
  ids: Array<string>;
  isAdmin?: boolean;
};

export const exportSntXml = async (config: Config) => {
  const { ids, isAdmin } = config;
  const url = isAdmin ? `${API_URL}/admin/snt/downloadXml` : `${API_URL}/snt/downloadXml`;
  try {
    await downloadFile({
      url,
      method: 'GET',
      params: { 'ids[]': ids },
    });
  } catch (error) {
    ToastService.showError(solveErrorMessage(error));
    throw error;
  }
  return;
};

export const downloadSntFno = async (sntIdList: string) => {
  try {
    await querySntDownloadXmlSono({ sntIdList });
  } catch (error) {
    ToastService.showError(solveErrorMessage(error));
    throw error;
  }
  return;
};
