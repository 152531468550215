import React, { ChangeEvent, SyntheticEvent } from 'react';
import clsx from 'clsx';
import t1 from './Radio.module.scss';
import t2 from './RadioT2.module.scss';
import { UITheme } from 'ui/common/types';

const styles = {
  T1: t1,
  T2: t2,
};

export type RadioProps = {
  className?: string;
  /**
   * Значение value радио инпута
   */
  radioValue?: any;
  /**
   * Внешнее значение для controled component
   */
  value?: any;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  readOnly?: boolean;
  disabled?: boolean;
  error?: boolean;
  id?: string;
  theme?: UITheme;
};

export const Radio: React.FC<RadioProps> = ({
  className,
  radioValue,
  value,
  checked,
  disabled,
  readOnly,
  onChange,
  error,
  theme = 'T1',
  ...rest
}) => {
  const s = styles[theme];
  const changeHandler = readOnly
    ? (e: SyntheticEvent) => {
        e.preventDefault();
        return false;
      }
    : onChange;

  return (
    <span
      className={clsx(className, s.wrapper, s[theme], {
        [s.readOnly]: readOnly || disabled,
      })}
    >
      <span>
        <input
          type="radio"
          className={s.input}
          onChange={changeHandler}
          readOnly
          disabled={disabled}
          onClick={(e: SyntheticEvent) => e.stopPropagation()}
          //Или явно задаем checked или через проверку value === radioValue
          checked={checked ?? (value && radioValue && value === radioValue)}
          {...rest}
          value={radioValue}
        />
        <span className={s.inner}></span>
      </span>
    </span>
  );
};
