import { AxiosResponse } from 'axios';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';
import { fakeInvitations } from './fakeInvitations';
import { delay } from 'common/utils';

export const queryInvitations: DataQueryFunction = async () => {
  if (process.env.NODE_ENV === 'development') {
    await delay(1000);
    return {
      data: fakeInvitations,
    } as AxiosResponse;
  }

  return httpClient.get(`invitations`);
};
