import React from 'react';
import { FieldProps } from 'formik';
import { Checkbox, CheckboxProps } from '../controls';
import { FormValueProps } from './FormValueProps';

export type FormCheckboxProps = Partial<FieldProps> &
  Omit<CheckboxProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<boolean>) => void;
    /**
     * В чекбоксе не используется, добавлен для совместимости с Input
     */
    changeOnBlur?: boolean;
  };

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  field,
  form,
  meta,
  onValueChange,
  changeOnBlur,
  ...rest
}) => {
  const { name, onChange } = field || {};

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onValueChange && name && form) {
      onValueChange({ value: event.target.checked, name, event, form });
    } else {
      onChange && onChange(event);
    }
  };

  return <Checkbox {...rest} {...field} onChange={onInputChange} checked={!!field?.value} />;
};
