import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from '../PassportDocument.module.scss';
import { PACell } from './PACell';
import { useLocale } from 'services/l10n';
import { CodeValueMeasureUnit } from 'common/components';

type Props = {
  className?: string;
  data: any;
};

/**
 * Приложение 3. ФНО 328.00
 */
export const PassportAttachmentFno: React.FC<Props> = ({ className, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.attachment)}>
      {Array.isArray(data) &&
        data.map((fno, index) => (
          <div key={index} className={s.attInner}>
            <div className={s.attInnerHeader}>{loc('common.fno')}</div>
            <div className={s.attInnerBody}>
              <div className={clsx(s.attTable)}>
                <div className={s.attGrid} style={{ gridTemplateColumns: '1fr 1fr' }}>
                  {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

                  <PACell span={1} label={loc('fno.registrationNumber')} value={fno.registrationNumber} />
                  <PACell span={1} label={loc('fno.registrationDate')} value={fno.submitDate} />

                  {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}
                </div>

                {Array.isArray(fno.products) && (
                  <div className={s.attGrid} style={{ gridTemplateColumns: '70fr 287fr 210fr 287fr 140fr' }}>
                    <PACell span={1} label="№" noValue bb />
                    <PACell span={1} label={loc('common.labels.productName')} bb noValue />
                    <PACell span={1} label={loc('common.labels.productTnvedCode')} bb noValue />
                    <PACell span={1} label={loc('common.labels.measureUnitName')} bb noValue />
                    <PACell span={1} label={loc('common.labels.prodQuantity')} bb noValue />
                    {[...fno.products].map((product: any, index) => (
                      <Fragment key={index}>
                        <PACell span={1} value={product.xmlOrder} bt={false} />
                        <PACell span={1} value={product.productName} bt={false} />
                        <PACell span={1} value={product.tnvedCode} bt={false} />
                        <PACell span={1} bt={false}>
                          <CodeValueMeasureUnit code={product.measureUnitCode} />
                        </PACell>
                        <PACell span={1} value={product.quantity} bt={false} />
                      </Fragment>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};
