import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminNotBiometricsDelete: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { iin } = variables;

  const res = await httpClient.delete('/admin/biometricsNotRequiredTaxpayer/delete', { params: { iin } });
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
