import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { JournalButton, JournalT2 } from 'common/components/journal';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { getZvtJournalTxpColumns, getZvtJournalTxpFilterTags } from './getZvtJournalTxpColumns';
import { ZvtJournalTxpFiltersAdvanced } from './ZvtJournalTxpFiltersAdvanced';
import { formatFromDate } from 'services/date-time';
import { SessionStorageService } from 'services/storage/StorageService';
import { ZvtJournalTxpFiltersMain } from './ZvtJournalTxpFiltersMain';
import { queryZvtExport, queryZvtPrintReport, queryZvtRows } from '../api';
import { getCountryOptionValue } from 'common/components';
import { ReactComponent as OpenIcon } from 'assets/icons/journal-buttons/open.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/journal-buttons/export-pdf.svg';
import { ReactComponent as XlsIcon } from 'assets/icons/journal-buttons/export-xls.svg';
import { ZvtPageTaxpayer } from '../zvt-page-taxpayer/ZvtPageTaxpayer';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { PATH } from 'routing/paths';

const initialPagination = { rows: 15 };

const getRowId = (row: any) => row?.id;

const filterStorageKey = 'zvtFilters';

type Props = {};

/**
 * Страница НП -> Виртуальный склад -> Журнал ЗВТ (заявлений о выпуске товаров )
 * /zvt
 */
export const ZvtJournalTxpPage: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });

  const columns = useMemo(() => getZvtJournalTxpColumns({ locale }), [locale]);

  const filterTags = useMemo(() => getZvtJournalTxpFilterTags({ locale }), [locale]);

  const initialFilter = useMemo(
    () =>
      SessionStorageService.get(filterStorageKey) || {
        dateFrom: formatFromDate(new Date()),
        dateTo: formatFromDate(new Date()),
      },
    []
  );

  const queryProps = useDataQuery(
    queryZvtRows,
    {
      filter: initialFilter,
      pagination: initialPagination,
    },
    {
      preProcessParams: (params) => ({
        ...params,
        filter: {
          ...params.filter,
          departureCountryCode: getCountryOptionValue(params.filter?.departureCountryCode),
        },
      }),
    }
  );

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      icon: XlsIcon,
      onClick: async () => {
        exportJournal();
      },
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      icon: OpenIcon,
      onClick: async (selection) => {
        setNestedPage({ opened: true, row: selection[0] });
      },
    },
    {
      id: 2,
      label: loc('common.button.pdf'),
      icon: PdfIcon,
      onClick: async (selection) => {
        downloadSelection(selection);
      },
    },
  ];

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  const exportJournal = async () => {
    try {
      await queryZvtExport({
        filter: queryProps.filter,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  const downloadSelection = async (selection: any, format: string = 'PDF') => {
    try {
      await queryZvtPrintReport({
        id: getRowId(selection[0]),
        format,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <Fragment>
      <JournalT2
        pageTitle={loc('zvt.journalTitle')}
        columns={columns}
        queryProps={queryProps}
        filterComponentMain={ZvtJournalTxpFiltersMain}
        filterComponentAdvanced={ZvtJournalTxpFiltersAdvanced}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        filterStorageKey={filterStorageKey}
        filterTags={filterTags}
        hidden={nestedPage.opened}
        breadcrumbs={[
          { label: loc('common.main.main'), link: PATH.MAIN },
          { label: loc('common.virtualStore') },
          { label: loc('zvt.journalTitle') },
        ]}
      />
      {nestedPage.opened ? <ZvtPageTaxpayer onClose={closeNestedPage} id={getRowId(nestedPage.row)} /> : null}
    </Fragment>
  );
};
