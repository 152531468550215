import React, { Fragment, useEffect, useState } from 'react';
import { IsgoNotificationModal } from './isgo-notification-modal';
import { Modal } from 'services/modal';
import { getAllInvoicesForConfirm, sendInvoicesToIsgo } from './helpers';
import { useStore } from 'common/hooks';
import { IsgoDeclineReasonModal } from './isgo-decline-reason-modal';

type Props = {
  notification: any;
  onDone: () => void;
};

export const IsgoNotification: React.FC<Props> = ({ notification, onDone }) => {
  const { locale, dialog, auth, user } = useStore();
  const { loc } = locale;
  const [submitting, setSubmitting] = useState(false);
  const [declineModal, setDeclineModal] = useState<undefined | { show: boolean; rows: any[] }>(undefined);

  const [invoices, setInvoices] = useState<Array<any> | undefined>(undefined);

  const onConfirm = async (rows: any[]) => {
    const confirmed = await dialog.show({
      content: loc('c.isgoNotify.note'),
      title: loc('esf.isgoConfirm'),
      center: true,
      confirmButtonText: loc('common.button.send'),
    });

    const userTin = user.currentUser?.currentUserTin;
    if (confirmed && userTin) {
      setSubmitting(true);
      try {
        await sendInvoicesToIsgo({ actionType: 'CONFIRM', auth, userTin, isgoInvoiceIds: rows.map((inv) => inv?.id) });
        onDone();
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  const onDecline = async (reason: string, rows: any[]) => {
    const userTin = user.currentUser?.currentUserTin;
    if (userTin) {
      setSubmitting(true);
      try {
        await sendInvoicesToIsgo({
          actionType: 'DECLINE',
          auth,
          userTin,
          isgoInvoiceIds: rows.map((inv) => inv?.id),
          rejectReason: reason,
        });
        onDone();
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    let ids = Array.isArray(notification.invoices) ? notification.invoices.map((inv: any) => inv.id) : [];
    if (ids.length) {
      getAllInvoicesForConfirm(ids).then((invoices) => setInvoices(invoices));
    }
  }, [notification]);

  return (
    <Fragment>
      {invoices && (
        <Modal isOpen={true} theme="T2" scrollLock={false} marginTop={100}>
          <IsgoNotificationModal
            notification={notification}
            onDone={onDone}
            invoices={invoices}
            onConfirm={onConfirm}
            onDecline={(rows) => setDeclineModal({ show: true, rows })}
            submitting={submitting}
          />
        </Modal>
      )}

      {!!declineModal && (
        <Modal isOpen={!!declineModal?.show} theme="T2" onClose={() => setDeclineModal(undefined)} center>
          <IsgoDeclineReasonModal onSubmit={onDecline} rows={declineModal?.rows} />
        </Modal>
      )}
    </Fragment>
  );
};
