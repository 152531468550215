import { createCachedQuery } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryIsgoSystemsActiveList: DataQueryFunction = createCachedQuery(async () => {
  const res = await httpClient.get(`/isgo/systems/active/list`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
});
