import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './PrintJournalPage.module.scss';
import { useParams } from 'react-router-dom';
import { SessionStorageService } from 'services/storage/StorageService';
import { PrintDataConfig, printJournalKeyPrefix } from './helpers';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
};

export const PrintJournalPage: React.FC<Props> = ({ className }) => {
  const { id } = useParams<{ id: string }>();
  const locale = useLocale();
  const { loc } = locale;
  const [config, setConfig] = useState<PrintDataConfig | null>(null);

  const { title, columns, rows, filter } = config || {};

  useEffect(() => {
    setConfig(SessionStorageService.get(`${printJournalKeyPrefix}${id}`));
  }, [id]);

  return (
    <div className={clsx(className, s.wrapper)}>
      {config && Array.isArray(columns) && Array.isArray(rows) && (
        <Fragment>
          <div className={s.title}>{title}</div>
          <table className={s.gridTable}>
            <thead className={s.gridHeader}>
              <tr>
                <th style={{ width: '20px' }}>№</th>
                {columns.map((col) => (
                  <th key={col.name} style={{ width: col.width }}>
                    {col.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td style={{ textAlign: 'center' }}>{rowIndex + 1}</td>
                  {columns.map((col) => (
                    <td key={col.name}>{row[col.name]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          <div className={s.buttonsContainer}>
            <input
              className={s.printer}
              type="button"
              onClick={() => window.print()}
              value={loc('common.button.print')}
            />
            <input
              className={s.closer}
              type="button"
              onClick={() => window.close()}
              value={loc('common.button.close')}
            />
          </div>

          {Array.isArray(filter) && (
            <div className={s.searchParams}>
              <div className={s.searchParamsTitle}>{loc('print.search')}:</div>
              <table>
                <tbody>
                  {filter.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.label}: </td>
                        <td>{item.value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};
