import { API_URL } from 'common/constants';
import { clearEmptyFieldsDeep } from 'common/utils';
import { DataQueryFunction, downloadFile } from 'rest';

export const queryAdminGovInstReport: DataQueryFunction = (variables: any) => {
  const { filter = {} } = variables || {};

  const criteria = JSON.stringify(clearEmptyFieldsDeep(filter));

  return downloadFile({
    url: `${API_URL}/admin/governmentInstitution/report`,
    method: 'GET',
    params: { criteria },
  });
};
