import { LocaleStore } from 'services/l10n';

export const getSORStatusOptions = ({ loc }: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: true,
    label: loc('small.active.true'),
  },
  {
    value: false,
    label: loc('small.active.false'),
  },
];

export const getSnrTypeOptions = ({ loc }: LocaleStore): Array<{ value: string | number; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: 0,
    label: loc('small.snrType.0'),
  },
  {
    value: 1,
    label: loc('small.snrType.1'),
  },
  {
    value: 2,
    label: loc('small.snrType.2'),
  },
];
