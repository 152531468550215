/**
 * Читает список значений из текстового файла, полученый через выбор файла или через рест.
 * @param file - текстовый файл или Blob
 * @param separator - разделитель значений, по умолчанию - запятая
 * @returns - Массив значений
 */
export const readListFromTextFile = (file: File | Blob, separator: string = ','): Promise<Array<string>> => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = function (ev) {
        // console.log('result', this.result);
        if (this.result && typeof this.result === 'string') {
          resolve(this.result.split(separator).map((item) => item.trim()));
        } else {
          reject(ev);
        }
      };
      reader.onerror = function (ev) {
        reject(ev);
      };
      reader.readAsText(file);
    }
  });
};
