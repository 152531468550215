import { DataMutationFunction } from '../../../types';
import { httpClient } from '../../../httpClient';
import { AxiosResponse } from 'axios';

export const mutationSntDelete: DataMutationFunction<AxiosResponse> = async (variables): Promise<any> => {
  const { ids } = variables || {};

  const res = await httpClient.delete('/snt/delete', { params: { idList: ids.join() } });

  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
