export const PERMISSION = {
  /**
   * Просмотр СФ
   */
  INVOICE_VIEW: 'INVOICE_VIEW',

  /**
   * Создание СФ
   */
  INVOICE_CREATE_REGULAR: 'INVOICE_CREATE_REGULAR',

  /**
   * Создание исправленного СФ
   */
  INVOICE_CREATE_FIXED: 'INVOICE_CREATE_FIXED',

  /**
   * Создание дополнительного СФ
   */
  INVOICE_CREATE_ADDITIONAL: 'INVOICE_CREATE_ADDITIONAL',

  /**
   * Отзыв СФ
   */
  INVOICE_REVOKE: 'INVOICE_REVOKE',

  /**
   * Создание черновика
   */
  DRAFT_CREATE: 'DRAFT_CREATE',

  /**
   * Ответственный работник по расчетам с бюджетом ЮЛ
   */
  USER_BUDGET: 'USER_BUDGET',

  /**
   * Просмотр журнала перерегистрации
   */
  REREGISTRATION_VIEW: 'REREGISTRATION_VIEW',

  /**
   * Добавление сотрудника
   */
  USER_INVITE: 'USER_INVITE',

  /**
   * Изменение полномочий сотрудника
   */
  USER_EDIT: 'USER_EDIT',

  /**
   * Редактирование проекта/контракта
   */
  PROJECT_EDIT: 'PROJECT_EDIT',

  /**
   * Подписывание запроса при изменении проекта/контракта
   */
  PROJECT_SIGN: 'PROJECT_SIGN',

  /**
   * Работа с виртуальным складом
   */
  VIRTUAL_STOCK_WORK: 'VIRTUAL_STOCK_WORK',

  /**
   * Просмотр Форм ВС
   */
  UFORM_VIEW: 'UFORM_VIEW',

  /**
   * Создание Форм ВС
   */
  UFORM_EDIT: 'UFORM_EDIT',

  /**
   * Отмена Форм ВС
   */
  UFORM_CANCEL: 'UFORM_CANCEL',

  /**
   * Создание черновика Формы ВС
   */
  UFORM_DRAFT: 'UFORM_DRAFT',

  /**
   * Просмотр АВР
   */
  AWP_VIEW: 'AWP_VIEW',

  /**
   * Создание АВР
   */
  AWP_CREATE: 'AWP_CREATE',

  /**
   * Подтверждение АВР
   */
  AWP_CONFIRM: 'AWP_CONFIRM',

  /**
   * Расторжение АВР
   */
  AWP_TERMINATE: 'AWP_TERMINATE',

  /**
   * Создание черновика АВР
   */
  AWP_DRAFT: 'AWP_DRAFT',

  /**
   * Просмотр ЭД/ДС
   */
  ECONTRACT_VIEW: 'ECONTRACT_VIEW',

  /**
   * Создание ЭД
   */
  ECONTRACT_CREATE: 'ECONTRACT_CREATE',

  /**
   * Создание ДС
   */
  ECONTRACT_SUPPLEMENTARY_CREATE: 'ECONTRACT_SUPPLEMENTARY_CREATE',

  /**
   * Утверждение ЭД/ДС
   */
  ECONTRACT_APPROVE: 'ECONTRACT_APPROVE',

  /**
   * Отзыв ЭД/ДС
   */
  ECONTRACT_REVOKE: 'ECONTRACT_REVOKE',

  /**
   * Расторжение ЭД/ДС
   */
  ECONTRACT_TERMINATE: 'ECONTRACT_TERMINATE',

  /**
   * Создание черновика ЭД
   */
  ECONTRACT_DRAFT: 'ECONTRACT_DRAFT',

  /**
   * Создание черновика ДС
   */
  ECONTRACT_SUPPLEMENTARY_DRAFT: 'ECONTRACT_SUPPLEMENTARY_DRAFT',

  /**
   * Поиск, просмотр ФНО 328.00
   */
  VIEW_FNO: 'VIEW_FNO',

  /**
   * Поиск, просмотр ДТ
   */
  VIEW_GTD: 'VIEW_GTD',

  /**
   * Черновик СНТ
   */
  SNT_DRAFT: 'SNT_DRAFT',

  /**
   * Просмотр СНТ
   */
  SNT_VIEW: 'SNT_VIEW',

  /**
   * Создание СНТ
   */
  SNT_CREATE: 'SNT_CREATE',

  /**
   * Подтверждение СНТ
   */
  SNT_CONFIRM: 'SNT_CONFIRM',

  /**
   * Подтверждение СНТ
   */
  SNT_CANCEL_CONFIRM: 'SNT_CANCEL_CONFIRM',

  /**
   * Создание РД
   */
  PERMISSIVE_DOCUMENT_CREATE: 'PERMISSIVE_DOCUMENT_CREATE',

  /**
   * Отмена РД
   */
  PERMISSIVE_DOCUMENT_CANCEL: 'PERMISSIVE_DOCUMENT_CANCEL',

  /**
   * Просмотр РД
   */
  PERMISSIVE_DOCUMENT_VIEW: 'PERMISSIVE_DOCUMENT_VIEW',

  /**
   * Просмотр сертификата
   */
  CERTIFICATE_VIEW: 'CERTIFICATE_VIEW',

  /**
   * Просмотр МПТ
   */
  MPT_VIEW: 'MPT_VIEW',

  /**
   * Выгрузка отчета 'Оборотно-сальдовая ведомость'
   */
  SALDO_VIEW: 'SALDO_VIEW',

  /**
   * Просмотр Паспорта прослеживаемости
   */
  ASSURANCE_VIEW: 'ASSURANCE_VIEW',

  /**
   * Отправка ЭСФ в ГО
   */
  ISGO_PROCESS: 'ISGO_PROCESS',
};
