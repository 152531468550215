import { EsfTxpStatus } from '../constants';

/**
 * Проверка на наличие статуса у НП
 * @param statusesValue - Статусы НП
 * @param statusesForCheck - Перечень статусов для проверки (массив или строка).
 * @param every -  Проверяет наличие всех "статусов для проверки" array.every(), по умолчанию проверяет наличие хотябы одного из списка для проверки array.some().
 * @returns - true/false
 */
export function checkStatuses(statusesValue: any, statusesForCheck: Array<string> | string, every: boolean = false) {
  const statusesForCheckArr = Array.isArray(statusesForCheck) ? statusesForCheck : [statusesForCheck];
  if (Array.isArray(statusesValue)) {
    if (every) {
      return statusesForCheckArr.every((st) => statusesValue.includes(st));
    }
    return statusesForCheckArr.some((st) => statusesValue.includes(st));
  }
  return false;
}

/**
 * Офильтровываем невалидные значение статусов (например при добавлении из данных taxpayer)
 */
export function filterValidCustomerStatuses(statuses: Array<string>) {
  const validCustomerStatuses: Array<string> = [
    EsfTxpStatus.COMMITTENT,
    EsfTxpStatus.BROKER,
    EsfTxpStatus.LESSEE,
    EsfTxpStatus.JOINT_ACTIVITY_PARTICIPANT,
    EsfTxpStatus.PUBLIC_OFFICE,
    EsfTxpStatus.NONRESIDENT,
    EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT,
    EsfTxpStatus.PRINCIPAL,
    EsfTxpStatus.RETAIL,
    EsfTxpStatus.INDIVIDUAL,
    EsfTxpStatus.LAWYER,
    EsfTxpStatus.BAILIFF,
    EsfTxpStatus.MEDIATOR,
    EsfTxpStatus.NOTARY,
  ];
  return statuses.filter((st: string) => validCustomerStatuses.includes(st));
}

/**
 * Офильтровываем невалидные значение статусов (например при добавлении из данных taxpayer)
 */
export function filterValidSellerStatuses(statuses: Array<string>) {
  const validCustomerStatuses: Array<string> = [
    EsfTxpStatus.COMMITTENT,
    EsfTxpStatus.BROKER,
    EsfTxpStatus.FORWARDER,
    EsfTxpStatus.LESSOR,
    EsfTxpStatus.SHARING_AGREEMENT_PARTICIPANT,
    EsfTxpStatus.JOINT_ACTIVITY_PARTICIPANT,
    EsfTxpStatus.EXPORTER,
    EsfTxpStatus.TRANSPORTER,
    EsfTxpStatus.PRINCIPAL,
    EsfTxpStatus.LAWYER,
    EsfTxpStatus.BAILIFF,
    EsfTxpStatus.MEDIATOR,
    EsfTxpStatus.NOTARY,
  ];
  return statuses.filter((st: string) => validCustomerStatuses.includes(st));
}
