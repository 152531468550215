import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getBioRiskyJournalColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.binOnly'),
  },
  {
    name: 'taxpayerName',
    label: loc('common.labels.npName'),
    width: 350,
  },
  {
    name: 'createDate',
    label: loc('bioRisky.addDate'),
    width: 250,
  },
  {
    name: 'isActive',
    label: loc('bioRisky.status'),
    getContent: (row) => (typeof row.isActive === 'boolean' ? loc(`bioRisky.status.${row.isActive}`) : null),
    width: 250,
  },
  {
    name: 'currentRiskCategory',
    label: loc('bioRisky.riskCategory'),
    getContent: (row) => (row.currentRiskCategory ? loc(`bioRisky.riskCategory.${row.currentRiskCategory}`) : null),
    width: 250,
  },
  {
    name: 'verificationStatus',
    label: loc('bioRisky.verStatus'),
    getContent: (row) => (row.verificationStatus ? loc(`bioRisky.verStatus.${row.verificationStatus}`) : null),
    width: 300,
  },
  {
    name: 'lastVerificationDate',
    label: loc('bioRisky.verDate'),
  },
  {
    name: 'verificationCount',
    label: loc('bioRisky.verCount'),
  },
  {
    name: 'invoiceCountWaitVerification',
    label: loc('bioRisky.waitCount'),
  },
  {
    name: 'invoiceCountErrorVerification',
    label: loc('bioRisky.errorCount'),
  },
  {
    name: 'invoiceProcessedCount',
    label: loc('bioRisky.invoiceProcessedCount'),
  },
];
