import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './ArmLayoutAside.module.scss';
import { useLocale } from 'services/l10n';
import { MainLogo, NavigationItem } from 'ui/common';
import { useAuth, useUser } from 'common/hooks';
import { useAppMode } from 'common/hooks/useAppMode';
import { generateMenu } from 'common/helpers/menu';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
  minimized: boolean;
};

export const ArmLayoutAside: React.FC<Props> = ({ className, minimized }) => {
  const locale = useLocale();
  const user = useUser();
  const auth = useAuth();
  const { loc } = locale;

  const appMode = useAppMode();

  const menu = useMemo(() => generateMenu(appMode, locale, user), [appMode, locale, user]);

  const logout = async () => {
    try {
      await auth.logout();
    } catch (error) {
      console.dir(error);
    }
  };

  const logoutItem = {
    id: 18,
    label: loc('admin.menu.LOGOUT'),
    iconName: 'detail',
    onClick: logout,
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <Link to={PATH.MAIN} className={s.logoLink}>
          <MainLogo minimized={minimized} text={loc('common.logoTitle')} />
        </Link>
      </div>
      <div className={s.navigation}>
        {menu && menu.map((item) => <NavigationItem key={item.id} item={item} minimized={minimized} />)}
        <NavigationItem item={logoutItem} minimized={minimized} className={s.logout} />
      </div>
    </div>
  );
};
