import { FilterContentProps } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect, FormValueProps } from 'ui/common';
import { fetchProducts } from '../helpers';
import s from '../VirtualLabelChainPage.module.scss';

const getProductsOptionValue = (data: any) => data.id;
const getProductsOptionLabel = (data: any) =>
  `${data.productId} - ${data.name}${data.pinCode ? ` (${data.pinCode})` : ''}`;

type Props = FilterContentProps;

export const VirtualLabelChainFilters: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, setFieldValue } = form;

  const onDocumentChange = async ({ value }: FormValueProps) => {
    setFieldValue('documentNumber', value);
    setFieldValue('_products', await fetchProducts(value));
    setFieldValue('id', '');
  };

  return (
    <Fragment>
      <div>
        <FormField name="documentNumber" label={loc('common.labels.document')}>
          <FormInput className={s.control} onValueChange={onDocumentChange} />
        </FormField>
        <FormField name="id" label={loc('common.labels.prodId')} fast={false}>
          <FormSelect
            options={values._products}
            className={s.control}
            getOptionLabel={getProductsOptionLabel}
            getOptionValue={getProductsOptionValue}
            isDisabled={!(Array.isArray(values._products) && values._products.length)}
          />
        </FormField>
      </div>

      <div className={columnClassName}></div>
      <div className={columnClassName}></div>
    </Fragment>
  );
};
