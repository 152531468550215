export const LAYOUT = {
  EMPTY: 'EMPTY',
  GUEST: 'GUEST',
  GUEST_NOFOOTER: 'GUEST_NOFOOTER',
  TAXPAYER: 'TAXPAYER',
  ARM: 'ARM',
  EAEC_ARM: 'EAEC_ARM',
  EAEC_LOGIN: 'EAEC_LOGIN',
} as const;

export type LayoutName = typeof LAYOUT[keyof typeof LAYOUT];
