type ObjectWithProps = { [key: string]: any };

/**
 * clear falsy props (null, undefined, '', 0, false, NaN) from object (shallow);
 * @returns new object
 */
export function clearFalsyProps(object: ObjectWithProps): ObjectWithProps {
  return Object.keys(object).reduce((acc: ObjectWithProps, key) => {
    if (object[key]) acc[key] = object[key];
    return acc;
  }, {});
}
