import { CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecProductsColumns = ({
  locale: { loc },
  direction,
}: {
  locale: LocaleStore;
  direction: 'input' | 'output';
}): Array<TableColumn> => [
  {
    name: 'index',
    label: loc('eaecActions.p.index'),
    getContent: (_, index) => index + 1,
    style: {
      width: 70,
    },
  },
  {
    name: 'productPosition',
    label: loc('eaecActions.p.productPosition'),
  },
  {
    name: 'tnvedCode',
    label: loc('common.labels.tnvedCode'),
    style: {
      width: 150,
    },
  },
  {
    name: 'productName',
    label: loc('common.labels.productName'),
    style: {
      width: 250,
    },
  },
  {
    name: 'measureUnitCode',
    label: loc('common.labels.measureUnitName'),
    getContent: (row) => (row.measureUnitCode ? <CodeValueMeasureUnit code={row.measureUnitCode} /> : ''),
  },
  {
    name: 'quantity',
    label: loc('common.labels.quantity'),
  },
  {
    name: 'quantitativeMeasureUnitCode',
    label: loc('eaecActions.p.quantitativeMeasureUnitCode'),
    getContent: (row) =>
      row.quantitativeMeasureUnitCode ? <CodeValueMeasureUnit code={row.quantitativeMeasureUnitCode} /> : '',
  },
  {
    name: 'quantitativeQuantity',
    label: loc('eaecActions.p.quantitativeQuantity'),
  },
  {
    name: 'dtRegistrationNumber',
    label: loc('eaecActions.p.dtRegistrationNumber'),
  },
  {
    name: 'positionInDt',
    label: loc('eaecActions.p.positionInDt'),
  },
  {
    name: 'departureCountryCode',
    label: loc('eaecActions.zvtCountry'),
    getContent: (row) => (row.departureCountryCode ? <CodeValueCountry code={row.departureCountryCode} /> : ''),
  },
  ...(direction === 'input'
    ? [
        {
          name: 'operationRequestMessageId',
          label: loc('eaecActions.preId'),
        },
        {
          name: 'operationRequestDate',
          label: loc('eaecActions.requestDateTime'),
        },
      ]
    : []),
];
