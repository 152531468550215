import { API_URL } from 'common/constants';
import { downloadFile } from 'rest';
import { Lang } from 'services/l10n';

type Config = {
  ids: Array<string>;
  isDraft: boolean;
  isAdmin?: boolean;
  lang: Lang;
};

export const downloadEsfPdf = async (config: Config) => {
  const { ids, isAdmin, lang, isDraft } = config;
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url = isAdmin ? `${API_URL}/invoice/printReport/admin/${id}/PDF` : `${API_URL}/invoice/printReport/${id}/PDF`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
        params: { isDraft, language: lang },
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};
