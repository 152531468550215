import React, { FC, useCallback, useState } from 'react';
import clsx from 'clsx';
import s from './Board.module.scss';
import { BoardRow } from './BoardRow';
import { useLocale } from 'services/l10n';
import { Loader, TableColumn } from 'ui';
import { DataQueryProps } from 'rest';
import { BoardButton } from 'pages/main-taxpayer/helpers';
import { BoardFilter } from './BoardFilter';
import { FormikProps } from 'formik';
import { AxiosResponse } from 'axios';

type Props = {
  className?: string;
  columns: Array<TableColumn>;
  getRowId: (row: any, index: number) => string | number;
  selectable?: boolean;
  queryProps: DataQueryProps;
  getRows?: (data: any | undefined, res: AxiosResponse | undefined) => Array<any> | undefined;
  menuButtons: Array<BoardButton>;
  filterComponent?: FC<any>;
  label?: string;
  renderLinkButton?: () => React.ReactNode;
  maxRows?: number;
};

export const Board: React.FC<Props> = ({
  className,
  getRowId,
  columns,
  menuButtons,
  queryProps,
  getRows = (data): Array<any> => (Array.isArray(data?.rows) ? data?.rows : undefined),
  filterComponent: FilterComponent,
  label,
  renderLinkButton,
  maxRows = 5,
}) => {
  const locale = useLocale();
  const { loc } = locale;
  const [exporting, setExporting] = useState(false);

  const { response, data, filter, setFilter, loading } = queryProps;

  const onFilterSubmit = async (values: { [key: string]: string }) => {
    console.log('onFilterSubmit');
    setFilter(values);
  };

  const rows = getRows(data, response);

  const renderFilterContent = useCallback(
    (props: { form: FormikProps<any> }) => (!!FilterComponent ? <FilterComponent {...props} /> : null),
    [FilterComponent]
  );

  return (
    <div className={clsx(className, s.wrapper)}>
      {exporting || loading ? <Loader overlay /> : null}
      <div className={s.filter}>
        <BoardFilter renderContent={renderFilterContent} initialValues={filter} onSubmit={onFilterSubmit} />
      </div>
      <div className={s.buttonsRow}>
        <h3>{label || loc('common.labels.last')}</h3>
        {typeof renderLinkButton === 'function' && renderLinkButton()}
      </div>
      {Array.isArray(rows) &&
        rows
          .slice(0, maxRows)
          .map((row: any, index) => (
            <BoardRow
              key={getRowId(row, index)}
              row={row}
              rowIndex={index}
              columns={columns}
              menuButtons={menuButtons}
              setExporting={setExporting}
            />
          ))}
    </div>
  );
};
