import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import { solveErrorMessage } from 'common/form/helpers';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { ToastService } from 'services/toasts';
import { queryAssuranceReport, queryAssuranceQr, queryAssuranceSearch, queryAssuranceTableReport } from '../api';
import { PassportsJournalFilters } from './PassportsJournalFilters';
import { getPassportsJournalColumns } from './getPassportsJournalColumns';
import { PassportTaxpayerPage } from '../passport-page/PassportTaxpayerPage';
import { formatFromDate } from 'services/date-time';
import { allowDownloadQR } from '../helpers';

const initialFilter = {
  dateTo: formatFromDate(new Date()),
  dateFrom: formatFromDate(new Date()),
};
const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.passportId;

type Props = {};

export const PassportsJournalTaxpayer: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });

  const columns = useMemo(() => getPassportsJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAssuranceSearch, {
    filter: initialFilter,
    pagination: initialPagination,
  });

  const headerButtons = useMemo(() => [], []);

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: (selection: Array<any>) => setNestedPage({ opened: true, row: selection[0] }),
    },
    {
      id: 2,
      label: loc('common.button.pdf'),
      onClick: (selection: Array<any>) => downloadSelectionPDF(selection),
      multiselect: true,
    },
    {
      id: 3,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportSelectionXLS(selection),
      multiselect: true,
    },
    {
      id: 7,
      label: loc('assurance.qrCode'),
      onClick: (selection: Array<any>) => exportQr(selection),
      disabled: (selection: Array<any>) => {
        return !allowDownloadQR(selection[0]);
      },
    },
  ];

  const downloadSelectionPDF = async (selection: Array<any>) => {
    try {
      await queryAssuranceReport({
        ids: selection.map((item) => item.passportId).join(','),
        format: 'PDF',
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const exportSelectionXLS = async (selection: Array<any>) => {
    try {
      await queryAssuranceTableReport({
        ids: selection.map((item) => item.passportId),
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const exportQr = async (selection: Array<any>) => {
    try {
      await queryAssuranceQr({
        regNumber: selection[0]?.passportRegistrationNumber,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  return (
    <Fragment>
      <Journal
        pageTitle={loc('assurance.journalTitle')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={PassportsJournalFilters}
        filterInitialOpened
        defaultFilter={initialFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        hidden={nestedPage.opened}
        getRowId={getRowId}
        theme="T2"
      />
      {nestedPage.opened ? (
        <PassportTaxpayerPage
          onClose={closeNestedPage}
          passportId={nestedPage.row?.stringPassportId && String(nestedPage.row?.stringPassportId)}
        />
      ) : null}
    </Fragment>
  );
};
