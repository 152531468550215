import React, { useCallback } from 'react';
import { queryCountries } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps, UITheme } from 'ui/common';
import { getCountryCode } from '../code-values/CodeValueCountry';

const EAEC_COUNTRY = ['RU', 'KZ', 'AM', 'BY', 'KG'];

const EAEC_COUNTRY_MAP = Object.fromEntries(EAEC_COUNTRY.map((key) => [key, true]));

async function getCountries(): Promise<any[]> {
  const res = await queryCountries();
  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export const getCountryOptionValue = (data: any) => {
  return data?.value ?? getCountryCode(data);
};

export const getCountryOptionLabel = (data: any, locale: LocaleStore) => {
  const { choose } = locale;
  return data?.label ?? `${getCountryCode(data)} - ${choose([data?.nameRu, data?.nameKz])}`;
};

type Props = FormSelectProps & {
  onlyEaec?: boolean;
  excludeKZ?: boolean;
  theme?: UITheme;
};

export const FormCountrySelect: React.FC<Props> = ({ onlyEaec, excludeKZ, theme, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const fetchOptions = useCallback(async () => {
    const countries = await getCountries();
    const options = countries
      .filter((opt) => !!getCountryOptionValue(opt))
      .filter((opt) => {
        if (excludeKZ && getCountryOptionValue(opt) === 'KZ') {
          return false;
        }
        if (onlyEaec && !EAEC_COUNTRY_MAP[getCountryOptionValue(opt)]) {
          return false;
        }
        return true;
      });

    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...options,
    ];
  }, [loc, excludeKZ, onlyEaec]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={fetchOptions}
      isSearchable={true}
      getOptionLabel={(opt) => getCountryOptionLabel(opt, locale)}
      getOptionValue={getCountryOptionValue}
      noOptionsMessage={noOptionsMessage}
      theme={theme}
    />
  );
};
