import { FormikProps } from 'formik';
import React, { createContext, useContext } from 'react';
import { EsfFormValues } from '../types';

export const EsfFormContext = createContext<{
  /**
   * Метод для пересоздания формы с новыми исходными данными.
   * Используется при предзаполнении бланка из других документов
   */
  setInitialData: (data: { values: EsfFormValues; errors?: any }) => void;
  setLoader: (state: boolean) => void;
  /**
   * Сохраняем текущую форму в реф, для доступа к данным формы из мемоизированных компонентов, и чтобы не вызывать лишние рендеры.
   * Для доступа к данным формы используем useFormRef() хук, компонент c memo не будет обновлятся при каждом обновлении формы.
   * Для компонентов где нужно обновление можно использовать хук useFormikContext().
   *  */
  formRef: React.MutableRefObject<FormikProps<EsfFormValues>>;

  /**
   * Ref для определения первого рендера формы.
   * При открытии, предзаполнении (в момент первого рендера) значение true,
   * при остальных обновлениях формы false
   * Используется для определения активности эфектов, чтобы эффекты не срабатывали при первом рендере, при открытии формы
   */
  isFormFirstRenderRef: React.MutableRefObject<boolean>;
}>({
  setInitialData: () => {},
  setLoader: () => {},
  formRef: null as unknown as React.MutableRefObject<FormikProps<EsfFormValues>>,
  isFormFirstRenderRef: null as unknown as React.MutableRefObject<boolean>,
});

export const useFormRef = () => {
  return useContext(EsfFormContext).formRef;
};

export const useIsFormFirstRenderRef = () => {
  return useContext(EsfFormContext).isFormFirstRenderRef;
};
