import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVlcFindDocumentProducts: DataQueryFunction = (variables: any) => {
  const { documentNumber } = variables || {};

  if (!documentNumber || !String(documentNumber).trim()) {
    return Promise.resolve([] as any);
  }

  return httpClient.get(`/admin/virtualLabelChain/findDocumentProducts`, {
    params: { documentNumber },
  });
};
