import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryVirtualLabelChain: DataQueryFunction = (variables: any) => {
  const { filter } = variables || {};

  const { id } = filter || {};

  return httpClient.get(`/admin/virtualLabelChain/virtualLabelId/${id}`);
};
