import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './SmallRetailInfo.module.scss';
import { Button, DataGrid } from 'ui/common';
import { useLocale } from 'services/l10n';
import { ModalContent } from 'services/modal/components/ModalContent';
import { ModalChildrenProps, ModalFooter, ModalHeader } from 'services/modal';
import { contentYesNo } from 'common/components';

type Props = ModalChildrenProps & {
  className?: string;
  data: any;
};

export const SmallRetailInfo: React.FC<Props> = ({ className, data, onClose }) => {
  const locale = useLocale();
  const { loc } = locale;

  const gridData = useMemo(() => {
    return [
      [loc('common.labels.loginIin'), data.tin],
      [loc('common.labels.npName'), data.npName],
      [loc('small.regDate'), data.startDate],
      [loc('common.labels.oked'), data.okedCode],
      [loc('common.labels.oked2'), data.okedCode2],
      [loc('small.onlineKkm'), contentYesNo(data.isOnlineKkm, locale)],
      [loc('small.ndsPayer'), contentYesNo(data.isNdsPayer, locale)],
      [loc('small.snrType'), loc(`small.snrType.${data.snrType}`)],
      [loc('small.closeDate'), data.endDate],
      [loc('small.closeCause'), data.closeCause],
      [loc('common.labels.status'), loc(`small.active.${data.isActive}`)],
    ];
  }, [loc, data, locale]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('small.smallRetail')}</ModalHeader>
      <ModalContent className={s.content}>
        <DataGrid data={gridData} columnClassNames={[s.leftColumn, '']} />
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose}>{loc('common.button.close')}</Button>
      </ModalFooter>
    </div>
  );
};
