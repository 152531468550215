import React from 'react';
import clsx from 'clsx';
import s from './DataGrid.module.scss';

type Props = {
  className?: string;
  columnClassName?: string;
  data: React.ReactNode[][];
  columnStyles?: Array<{ [key: string]: string | undefined }>;
  columnClassNames?: Array<string>;
  align?: 'left' | 'right';
};

export const DataGrid: React.FC<Props> = ({
  className,
  columnClassName,
  data,
  columnStyles = [],
  columnClassNames = [],
  align = 'left',
}) => {
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.alignRight]: align === 'right',
      })}
    >
      {data.map((row, index) => (
        <div key={index} className={s.row}>
          {row.map((cell, idx) => (
            <div
              key={idx}
              className={clsx(s.column, columnClassName, columnClassNames[idx] ? columnClassNames[idx] : undefined)}
              style={columnStyles[idx] ? columnStyles[idx] : {}}
            >
              {cell}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
