import React from 'react';
import clsx from 'clsx';
import s from './ChangeEmailPage.module.scss';
import { CardMain } from 'ui';
import { ChangeEmailForm } from './ChangeEmailForm';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { mutationChangeEmail } from 'rest';
import { useStore } from 'common/hooks';

type Props = {
  className?: string;
};

export const ChangeEmailPage: React.FC<Props> = ({ className }) => {
  const { ncaLayer, locale } = useStore();
  const { loc } = locale;

  const sibmitFrom = async (values: any) => {
    const notation = JSON.stringify(values);
    const signData = await ncaLayer.getSignature(notation);
    return mutationChangeEmail({
      body: { notation, certificate: signData.cert.base64Pem, signature: signData.sign },
    });
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <CardMain className={s.card}>
        <h1>{loc('reset.email')}</h1>
        <ChangeEmailForm onSubmit={sibmitFrom} />
      </CardMain>
      <div className={s.links}>
        <Link to={PATH.MAIN}>{loc('reset.return')}</Link>
      </div>
    </div>
  );
};
