import { stringify } from 'query-string';
import { convertToFormData } from './convertToFormData';

export function downloadFile(config: {
  url: string;
  params?: { [key: string]: any };
  method: 'GET' | 'POST';
  body?: any;
  header?: { name: string; value: string };
}): Promise<any> {
  return new Promise((resolve, reject) => {
    const { url, method, params, body, header } = config;
    const x = new XMLHttpRequest();
    const paramString = stringify(params || {});
    const bodyData = body || convertToFormData(params || {});
    const downloadUrl = method === 'GET' && !!paramString ? url + '?' + paramString : url;

    x.open(method, downloadUrl);
    x.setRequestHeader('Accept', 'application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8');
    if (header) {
      x.setRequestHeader(header.name, header.value);
    }
    x.responseType = 'blob';
    x.onload = function (e) {
      const ctype = this.getResponseHeader('content-type');
      const blob = new Blob([this.response], { type: ctype || undefined });
      const cd = this.getResponseHeader('content-disposition');
      if (cd) {
        const matches = cd.match(/filename=(.+)/);
        const file = matches ? matches[1] : 'unknown';
        (window as any).saveAs(blob, file);
        resolve(true);
      } else {
        try {
          const reader = new FileReader();
          reader.addEventListener('loadend', (e) => {
            const text = e.target?.result;
            try {
              const json = JSON.parse(text as string);
              if (json.message) {
                console.log(json.message);
              }
              if (json.errors && Array.isArray(json.errors)) {
                console.log(json.errors[0].message);
              }
              if (json.success === false) {
                reject(json);
              }
              resolve(json);
            } catch (error) {
              reject('Ошибка загрузки');
            }
          });
          reader.readAsText(blob);
        } catch (e) {
          console.log(e);
          reject('Ошибка загрузки');
        }
      }
    };

    x.onerror = function (e) {
      reject(e);
    };

    x.send(method === 'GET' ? paramString : bodyData);
  });
}
