import React, { ReactNode, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
  /**
   * Запрос на загрузку следующей части списка
   */
  loadNext: () => void;
  /**
   * Последняя загруженная часть списка
   */
  nextRows?: Array<any>;
  /**
   * Передаем полный список строк в функцию children
   */
  children: (rows: Array<any>) => ReactNode;
  /**
   * Остались ли еще строки для загрузки. Если false то больше не пытаемся подгружать список
   */
  hasMore: boolean;
  /**
   * Если первая часть списка. Сбрасываем список и начинаем заново
   */
  isFirstPart?: boolean;
  getRowId?: (row: any, index: number) => string | number;
  renderLoader?: () => ReactNode;
};

export const InfiniteScroll: React.FC<Props> = ({
  className,
  loadNext,
  nextRows,
  children,
  hasMore,
  isFirstPart,
  getRowId = (row) => row.id,
  renderLoader,
}) => {
  const observerTargetRef = useRef(null);

  const [loadingMore, setLoadingMore] = useState(false);

  const loadNextRef = useRef(loadNext);
  loadNextRef.current = loadNext;

  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadNextRef.current();
          setLoadingMore(true);
        }
      },
      { threshold: 1 }
    );

    const observerTarget = observerTargetRef.current;

    if (observerTarget && rows.length && hasMore && nextRows?.length) {
      observer.observe(observerTarget);
    }

    return () => {
      if (observerTarget) {
        observer.unobserve(observerTarget);
      }
    };
  }, [rows]); // eslint-disable-line

  useEffect(() => {
    if (Array.isArray(nextRows)) {
      if (isFirstPart) {
        setRows(nextRows);
      } else {
        setRows((rows) => {
          const idSet = new Set(rows.map((row, index) => getRowId(row, index)));
          return [...rows, ...nextRows.filter((row, index) => !idSet.has(getRowId(row, index)))];
        });
      }
    }
    setLoadingMore(false);
  }, [nextRows, isFirstPart]); // eslint-disable-line

  return (
    <div className={clsx(className)}>
      {typeof children === 'function' && children(rows)}
      <div ref={observerTargetRef}></div>
      {(loadingMore || !rows.length) && typeof renderLoader === 'function' && renderLoader()}
    </div>
  );
};
