export enum EsfSection {
  A = 'A',
  B = 'B',
  C = 'C',
  C1 = 'C1',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
}

export enum EsfVersion {
  V1 = 'InvoiceV1',
  V2 = 'InvoiceV2',
}

export enum EsfTxpStatus {
  /**
   * Комитент
   */
  COMMITTENT = 'COMMITTENT',
  /**
   * Комиссионер
   */
  BROKER = 'BROKER',
  /**
   * Экспедитор
   */
  FORWARDER = 'FORWARDER',
  /**
   * Лизингодатель
   */
  LESSOR = 'LESSOR',
  /**
   * Лизингополучатель
   */
  LESSEE = 'LESSEE',
  /**
   * Участник договора о совместной деятельности
   */
  JOINT_ACTIVITY_PARTICIPANT = 'JOINT_ACTIVITY_PARTICIPANT',
  /**
   * Участник СРП или сделки, заключенной в рамках СРП
   */
  SHARING_AGREEMENT_PARTICIPANT = 'SHARING_AGREEMENT_PARTICIPANT',
  /**
   * Экспортер
   */
  EXPORTER = 'EXPORTER',
  /**
   * Международный перевозчик
   */
  TRANSPORTER = 'TRANSPORTER',
  /**
   * Доверитель
   */
  PRINCIPAL = 'PRINCIPAL',
  /**
   * Адвокат
   */
  LAWYER = 'LAWYER',
  /**
   * Частный судебный исполнитель
   */
  BAILIFF = 'BAILIFF',
  /**
   * Медиатор
   */
  MEDIATOR = 'MEDIATOR',
  /**
   * Нотариус
   */
  NOTARY = 'NOTARY',
  /**
   * Государственное учреждение
   */
  PUBLIC_OFFICE = 'PUBLIC_OFFICE',
  /**
   * Нерезидент
   */
  NONRESIDENT = 'NONRESIDENT',
  /**
   * Розничная реализация
   */
  RETAIL = 'RETAIL',
  /**
   * Физ. лицо
   */
  INDIVIDUAL = 'INDIVIDUAL',
}

export enum Calculation {
  DIRECT = 'DIRECT',
  REVERSE = 'REVERSE',
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

// поле и соответствующее имя поля общей суммы (раздел G)
export const TOTALS_FIELDS_G: { [key: string]: string } = {
  priceWithoutTax: 'totalPriceWithoutTax',
  exciseAmount: 'totalExciseAmount',
  turnoverSize: 'totalTurnoverSize',
  ndsAmount: 'totalNdsAmount',
  priceWithTax: 'totalPriceWithTax',
};

/**
 * Причина выписки на бумажном носителе
 */
export enum EsfPaperReason {
  /**
   * Техническая ошибка или плановые работы
   */
  DOWN_TIME = 'DOWN_TIME',

  /**
   * Блокировка налогоплательщика уполномоченным органом
   */
  UNLAWFUL_REMOVAL_REGISTRATION = 'UNLAWFUL_REMOVAL_REGISTRATION',

  /**
   * Отсутствовало требование по выписке ЭСФ
   */
  MISSING_REQUIREMENT = 'MISSING_REQUIREMENT',
}
