import React, { useEffect, useRef, useState } from 'react';
import { FieldProps } from 'formik';
import { Textarea, TextareaProps } from '../controls';
import { FormValueProps } from './FormValueProps';

export type FormTextareaProps = Partial<FieldProps> &
  Omit<TextareaProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<string>) => void;
    changeOnBlur?: boolean;
  };

export const FormTextarea: React.FC<FormTextareaProps> = ({
  field,
  form,
  meta,
  onValueChange,
  changeOnBlur,
  ...rest
}) => {
  const { name, onChange, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  // using inner state when changeOnBlur = true
  const [inputValue, setInputValue] = useState(value);

  //Костыль для обновления inputValue при changeOnBlur
  const valuesAreEqual = useRef(true);
  valuesAreEqual.current = value === inputValue;

  const onInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (changeOnBlur) {
      setInputValue(e.target.value);
      return;
    }
    if (onValueChange && name && form) {
      onValueChange({ value: e.target.value, name, event: e, form });
    } else {
      onChange && onChange(e);
    }
  };

  const onInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (changeOnBlur && inputValue !== value) {
      if (onValueChange) {
        !!name && !!form && onValueChange({ value: inputValue, name, event: e, form });
      } else {
        typeof setFieldValue === 'function' && !!name && setFieldValue(name, inputValue);
      }
    }
    typeof onBlur === 'function' && onBlur(e);
  };

  useEffect(() => {
    if (!valuesAreEqual.current && changeOnBlur) {
      setInputValue(value);
    }
  }, [value, changeOnBlur]);

  return (
    <Textarea
      {...rest}
      {...field}
      value={(changeOnBlur ? inputValue : field?.value) ?? ''}
      onChange={onInputChange}
      onBlur={onInputBlur}
    />
  );
};
