import React, { useCallback } from 'react';
import { queryCheckpoints } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getCheckpointOptions(
  { loc }: LocaleStore,
  preProcessOptions?: (options: Array<any>) => Array<any>
) {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await queryCheckpoints();
  if (Array.isArray(res.data?.rows)) {
    if (typeof preProcessOptions === 'function') {
      return preProcessOptions(res.data?.rows);
    } else {
      return [...emptyOptions, ...res.data.rows];
    }
  }
  return emptyOptions;
}

type Props = FormSelectProps & {
  preProcessOptions?: (options: Array<any>) => Array<any>;
};

export const FormCheckpointSelect: React.FC<Props> = ({
  preProcessOptions,
  ...rest
}) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const getOptionLabel = useCallback(
    (data) => {
      const type = data.type ? loc(`checkpoint.${data.type}`) : undefined;
      return (
        data.label ?? `(${data.code}) ${choose([data.checkpointRu, data.checkpointKz])} ${type ? ' - ' + type : ''}`
      );
    },
    [choose, loc]
  );

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => getCheckpointOptions(locale, preProcessOptions)}
      isSearchable={true}
      noOptionsMessage={noOptionsMessage}
      getOptionValue={getOptionValue}
      getOptionLabel={getOptionLabel}
    />
  );
};
