import { convertToFormDataDeep } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';
import { clearEmptyFields } from 'common/utils';

export const queryMessagesFilter: DataQueryFunction = async ({ filter }) => {
  // if (process.env.NODE_ENV === 'development') {
  //   return {
  //     data: fakeGetMessageForConfirmation,
  //   } as AxiosResponse;
  // }

  const formData = convertToFormDataDeep(clearEmptyFields(filter));

  return httpClient.post(`/messages/filter`, formData);
};
