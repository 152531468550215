import { EsfStatus } from './constants';

export const ESF_FILTER_STATUSES = [
  EsfStatus.CREATED,
  EsfStatus.DELIVERED,
  EsfStatus.CANCELED,
  EsfStatus.CANCELED_BY_OGD,
  EsfStatus.CANCELED_BY_SNT_DECLINE,
  EsfStatus.CANCELED_BY_SNT_REVOKE,
  EsfStatus.REVOKED,
  EsfStatus.DECLINED,
  EsfStatus.SEND_TO_ISGO,
];

export const ESF_FILTER_IN_WORK_STATUSES = [
  EsfStatus.DRAFT,
  EsfStatus.IMPORTED,
  EsfStatus.FAILED,
  EsfStatus.IN_QUEUE,
  EsfStatus.IN_PROCESSING,
  EsfStatus.WAIT_BIOMETRICS_VERIFICATION,
  EsfStatus.FAILED_BIOMETRICS_VERIFICATION,
];
