import { clearEmptyFields, isEmpty } from 'common/utils';
import { createCachedQuery } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryAdminOGDSearch: DataQueryFunction = createCachedQuery((variables: any) => {
  const { filter = {} } = variables || {};

  let params = clearEmptyFields(filter);

  if (isEmpty(params)) {
    params.root = true;
  }

  const criteria = JSON.stringify(clearEmptyFields(params));

  return httpClient.post(
    `/admin/dict/taxOrganization/search`,
    {},
    {
      params: { criteria },
    }
  );
});
