import { DataQueryFunction, httpClient } from 'rest';

export const queryGetMessagesByInvoiceId: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  // if (false) {
  //   return Promise.resolve({ data: fakeMessages } as AxiosResponse);
  // }

  const res = await httpClient.get(`/messages/getMessagesByInvoiceId/${id}`);

  if (!res.data || res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};
