import { SntProductType, SntStatus, SntTpStatus, SntTransportType, SntType } from 'features/snt';
import { LocaleStore } from 'services/l10n';

export const getSntTermsTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: SntType.PRIMARY_SNT,
    label: loc(`common.snt.type.PRIMARY_SNT`),
  },
  {
    value: SntType.FIXED_SNT,
    label: loc(`common.snt.type.FIXED_SNT`),
  },
];

export const getSntTransferTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: 'IMPORT',
    label: loc(`sntTerms.transfer.IMPORT`),
  },
  {
    value: 'EXPORT',
    label: loc(`sntTerms.transfer.EXPORT`),
  },
  {
    value: 'ONE_PERSON_IN_KZ',
    label: loc(`sntTerms.transfer.TRANSFER`),
  },
  {
    value: 'IN_KZ',
    label: loc(`sntTerms.transfer.IN_KZ`),
  },
];

export const getSntTermsCustomerTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    SntTpStatus.LAWYER,
    SntTpStatus.MEDIATOR,
    SntTpStatus.BAILIFF,
    SntTpStatus.INDIVIDUAL,
    SntTpStatus.RETAIL,
    SntTpStatus.PHARMACEUTIC_PRODUCER,
    SntTpStatus.LESSEE,
    SntTpStatus.COMMITTENT,
    SntTpStatus.BROKER,
    SntTpStatus.SHARING_AGREEMENT_PARTICIPANT,
    SntTpStatus.JOINT_ACTIVITY_PARTICIPANT,
    SntTpStatus.PRINCIPAL,
    SntTpStatus.RETAILER,
    SntTpStatus.SMALL_RETAIL_OUTLET,
  ].map((st) => ({
    value: st,
    label: loc(`snt.tpStatus.${st}`),
  })),
];

export const getSntTermsTransportTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    SntTransportType.AUTOMOBILE,
    SntTransportType.RAILWAY,
    SntTransportType.AIR,
    SntTransportType.MARINE,
    SntTransportType.PIPELINE,
    SntTransportType.MULTIMODAL,
    SntTransportType.OTHER,
    SntTransportType.NO_TRANSPORT,
  ].map((t) => ({
    value: t,
    label: loc(`snt.transportType.${t}`),
  })),
];

export const getTermsSntStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  ...[
    SntStatus.NOT_VIEWED,
    SntStatus.DELIVERED,
    SntStatus.CONFIRMED,
    SntStatus.DECLINED,
    SntStatus.CONFIRMED_BY_OGD,
  ].map((st) => ({
    value: st,
    label: loc(`common.snt.status.${st}`),
  })),
];

export const getTermsSntStatusOptions2 = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  ...[
    SntStatus.NOT_VIEWED,
    SntStatus.DELIVERED,
    SntStatus.CONFIRMED,
    SntStatus.DECLINED,
    SntStatus.CONFIRMED_BY_OGD,
    SntStatus.DECLINED_BY_OGD,
  ].map((st) => ({
    value: st,
    label: loc(`common.snt.status.${st}`),
  })),
];

export const getTermsSntProductTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  ...[
    SntProductType.PRODUCT,
    SntProductType.ETHANOL,
    SntProductType.WINE_MATERIAL,
    SntProductType.BEER,
    SntProductType.ALCOHOL_EXCEPT_BEER,
    SntProductType.OIL,
    SntProductType.BIOFUEL,
    SntProductType.TOBACCO,
    SntProductType.OTHER_PRODUCT_WITH_DIGITAL_MARK,
    SntProductType.EXPORT_CONTROL_PRODUCT,
  ].map((st) => ({
    value: st,
    label: loc(`snt.productType.${st}`),
  })),
];
