import { AppMode } from 'common/constants';
import { EmptyLayout } from 'common/layouts/empty-layout';
import { ArmLayout } from 'common/layouts/arm-layout';
import { EaecLayout } from 'common/layouts/eaec-layout';
import { TaxpayerLayout } from 'common/layouts/taxpayer-layout';
import { GuestLayout } from 'common/layouts/guest-layout';
import { LayoutData } from './types';
import { LAYOUT, LayoutName } from './layouts';
import { LocaleNames } from 'services/l10n';

export const LAYOUT_MAP: { [key in LayoutName]: LayoutData } = {
  [LAYOUT.EMPTY]: {
    component: EmptyLayout,
    appMode: AppMode.GUEST,
  },
  [LAYOUT.GUEST]: {
    component: GuestLayout,
    appMode: AppMode.GUEST,
  },
  [LAYOUT.GUEST_NOFOOTER]: {
    component: GuestLayout,
    appMode: AppMode.GUEST,
    props: {
      variant: 'noFooter',
    },
  },
  [LAYOUT.TAXPAYER]: {
    component: TaxpayerLayout,
    appMode: AppMode.TAXPAYER,
    locales: [],
  },
  [LAYOUT.ARM]: {
    component: ArmLayout,
    appMode: AppMode.ARM,
    locales: [LocaleNames.ADMIN],
  },
  [LAYOUT.EAEC_ARM]: {
    component: ArmLayout,
    appMode: AppMode.EAEC,
    locales: [],
  },
  [LAYOUT.EAEC_LOGIN]: {
    component: EaecLayout,
    appMode: AppMode.EAEC,
  },
};
