import { LocaleStore } from 'services/l10n';
import { SntExportType, SntImportType, SntStatus, SntTransferType, SntType } from './constants';
import { getNotSelectedOptions } from 'common/helpers';

export const SNT_FILTER_OUTBOUND_STATUSES = [
  SntStatus.CREATED,
  SntStatus.NOT_VIEWED,
  SntStatus.DELIVERED,
  SntStatus.CONFIRMED,
  SntStatus.DECLINED,
  SntStatus.CONFIRMED_BY_OGD,
  SntStatus.DECLINED_BY_OGD,
  SntStatus.CANCELED,
  SntStatus.REVOKED,
  SntStatus.SEND_TO_ISGO,
];

export const SNT_FILTER_IN_WORK_STATUSES = [SntStatus.DRAFT, SntStatus.IMPORTED, SntStatus.FAILED];

export const getSntStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return SNT_FILTER_OUTBOUND_STATUSES.map((st) => ({
    value: st,
    label: loc(`common.snt.status.${st}`),
  }));
};

export const getSntStatusInWorkOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => {
  return SNT_FILTER_IN_WORK_STATUSES.map((st) => ({
    value: st,
    label: loc(`common.snt.status.${st}`),
  }));
};

export const getSntTypeOptions = ({ loc }: LocaleStore, withEmpty = true): Array<{ value: any; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  {
    value: SntType.PRIMARY_SNT,
    label: loc(`common.snt.type.PRIMARY_SNT`),
  },
  {
    value: SntType.FIXED_SNT,
    label: loc(`common.snt.type.FIXED_SNT`),
  },
  {
    value: SntType.RETURNED_SNT,
    label: loc(`common.snt.type.RETURNED_SNT`),
  },
];

export const getSntMoveTypeOptions = ({ loc }: LocaleStore, withEmpty = true): Array<{ value: any; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  {
    value: 'importType',
    label: loc(`snt.importType`),
  },
  {
    value: 'exportType',
    label: loc(`snt.exportType`),
  },
  {
    value: 'transferType',
    label: loc(`snt.transferType`),
  },
];

export const getSntImportTypeOptions = (
  locale: LocaleStore,
  withEmpty = true
): Array<{ value: any; label: string }> => [
  ...(withEmpty ? getNotSelectedOptions(locale) : []),
  ...Object.values(SntImportType).map((type) => ({
    value: type,
    label: locale.loc(`snt.importType.${type}`),
  })),
];

export const getSntExportTypeOptions = (
  locale: LocaleStore,
  withEmpty = true
): Array<{ value: any; label: string }> => [
  ...(withEmpty ? getNotSelectedOptions(locale) : []),
  ...Object.values(SntExportType).map((type) => ({
    value: type,
    label: locale.loc(`snt.exportType.${type}`),
  })),
];

export const getSntTransferTypeOptions = (
  locale: LocaleStore,
  withEmpty = true
): Array<{ value: any; label: string }> => [
  ...(withEmpty ? getNotSelectedOptions(locale) : []),
  ...Object.values(SntTransferType).map((type) => ({
    value: type,
    label: locale.loc(`snt.transferType.${type}`),
  })),
];
