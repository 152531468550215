import React, { Fragment, useEffect } from 'react';
import s from './UserContent.module.scss';
import { ModalHeader } from 'services/modal';
import { UserForm } from '../forms';
import { ModalFooter } from 'services/modal/components/ModalFooter';
import { Button } from 'ui/common/controls';
import { mutationGetSendInvitationHash, mutationSendInvitation, queryUser, useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { useStore } from 'common/hooks';

type Props = ModalChildrenProps & {
  className?: string;
  modal?: boolean;
  iin?: number;
  refetchList: () => void;
  newInvitation?: boolean;
};

export const UserContent: React.FC<Props> = ({
  modal,
  iin,
  onReady,
  refetchList,
  onClose = () => {},
  newInvitation,
}) => {
  const { user, auth } = useStore();
  const { data, loading } = useDataQuery(queryUser, { iin }, { skipInitialRequest: newInvitation });

  // const sendInvitation = (values: any) =>
  //   mutationSendInvitation({ body: values });

  const sendInvitation = async (values: any) => {
    console.log('values', values);
    let signData: any = {};
    if (!user.isSuperUser()) {
      const hash = await mutationGetSendInvitationHash({
        body: {
          ...values,
        },
      });
      console.log(hash);
      signData = await auth.getSignature(hash.data?.data?.hash);
      console.log(signData);
    }
    const res = await mutationSendInvitation({
      body: {
        ...values,
        certificate: signData.cert?.base64Pem || '',
        signature: signData.sign || '',
      },
    });
    refetchList();
    onClose();
    return res;
  };

  useEffect(() => {
    if (!loading && typeof onReady === 'function') onReady();
  }, [loading, onReady]);

  // console.log('is super user', user.isSuperUser());

  return !!data || newInvitation ? (
    <Fragment>
      <ModalHeader>Форма пользователя</ModalHeader>
      <UserForm
        onSubmit={sendInvitation}
        data={data}
        renderModalFooter={({ isSubmitting, isValid, dirty }) => (
          <ModalFooter>
            <Button
              // disabled={isSubmitting || !isValid || !dirty}
              type="submit"
              className={s.submit}
              size="small"
            >
              Готово
            </Button>
          </ModalFooter>
        )}
        modal={modal}
      />
    </Fragment>
  ) : null;
};
