import { AppMode } from 'common/constants';

type LinkType = 'PDF' | 'XLSX' | 'PAGE';

export type DocumentLinkParams = {
  docType?: string;
  linkType?: LinkType;
  id?: string | number;
  regNumber?: string;
  appMode?: AppMode;
  newTab?: boolean;
};

export type DocumentTargetUrl = {
  url: string;
  outsideLink: boolean;
};

export function isLinkType(linkType: string): linkType is LinkType {
  return ['PDF', 'XLS', 'PAGE'].includes(linkType);
}
