import { AxiosResponse } from 'axios';
import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminEecTransactionSearch: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  if (process.env.NODE_ENV === 'development') {
    return { data: fakeEecTransactionSearch } as AxiosResponse;
  }

  const criteria = { ...filter };

  const res = await httpClient.get(`/admin/eec/transaction/search`, {
    params: {
      criteria: JSON.stringify(clearEmptyFields(criteria)),
      ...toPaginationParams(pagination),
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};

const fakeEecTransactionSearch = {
  lastBlock: true,
  currPage: 1,
  rsCount: 3,
  rows: [
    {
      messageId: 'ed90ae8f-c78c-1efb-e053-fa12000a2f2f',
      docId: 'ed90ae8f-c78b-1efb-e053-fa12000a2f2f',
      procedureId: 'ed90ae8f-c78d-1efb-e053-fa12000a2f2f',
      conversationId: 'ed90ae8f-c78e-1efb-e053-fa12000a2f2f',
      interactionType: 'CP/P.LS.05/0.0.1/P.LS.05.PRC.001/P.LS.05.TRN.002/P.LS.05.MSG.004',
      sendOrInputDate: '07.12.2022 19:06:28',
      messageCreateDate: '16.11.2022 11:24:09',
      documentGroup: 'TRACEABILITY_DOCUMENT_INPUT',
      transactionStatus: 'IN_PROCESS',
      isActive: true,
      isInitiator: false,
      resultExpireDate: '07.12.2022 19:26:28',
      currentExpireDate: '07.12.2022 19:07:29',
      sendCount: 1,
      signalDeliveredMessageId: '52f91f66-288e-4e84-a9ca-628e0ff2a8d3',
      signalDeliveredDate: '07.12.2022 19:06:29',
      signalInProcessMessageId: '897674bc-ffb7-4115-83c5-305c7bc339dd',
      signalInProcessDate: '07.12.2022 19:06:29',
      resultMessageId: 'd22c2d98-e8a6-415b-9a96-f947f99553cd',
      resultDate: '07.12.2022 19:06:29',
    },
    {
      messageId: 'ed92e45a-b32d-7659-e053-fa12000a2f61',
      docId: 'ed92e45a-b32c-7659-e053-fa12000a2f61',
      procedureId: 'ed92e45a-b32e-7659-e053-fa12000a2f61',
      conversationId: 'ed92e45a-b32f-7659-e053-fa12000a2f61',
      interactionType: 'CP/P.LS.05/0.0.1/P.LS.05.PRC.001/P.LS.05.TRN.002/P.LS.05.MSG.004',
      sendOrInputDate: '25.11.2022 19:08:30',
      messageCreateDate: '16.11.2022 14:02:20',
      documentGroup: 'TRACEABILITY_DOCUMENT_INPUT',
      transactionStatus: 'WAIT_COMMIT',
      isActive: true,
      isInitiator: false,
      resultExpireDate: '25.11.2022 19:09:30',
      currentExpireDate: '25.11.2022 19:28:30',
      sendCount: 1,
      signalDeliveredMessageId: 'f9e8e944-06cf-4e31-9905-10f544709149',
      signalDeliveredDate: '25.11.2022 19:08:30',
      signalInProcessMessageId: 'f0a61f2c-6dd6-4262-b232-402a1ea80a50',
      signalInProcessDate: '25.11.2022 19:08:30',
      resultMessageId: '1b0ccf15-47e2-4a75-a2f9-0b69ce9b6d0e',
      resultDate: '25.11.2022 19:08:30',
    },
    {
      messageId: '556f980d-c9ae-4cf6-bd73-78abf9900dab',
      docId: '7ae646c7-a073-4b6b-8b04-a46de8558a65',
      procedureId: '0f9dd769-86af-47cb-baab-f42852c6cf4c',
      conversationId: '59bff92a-a3bf-44eb-9c22-d21675a4cbe4',
      interactionType: 'CP/P.LS.05/0.0.1/P.LS.05.PRC.001/P.LS.05.TRN.002/P.LS.05.MSG.004',
      sendOrInputDate: '02.12.2022 17:47:05',
      messageCreateDate: '02.12.2022 17:47:02',
      documentGroup: 'TRACEABILITY_DOCUMENT_OUTPUT',
      transactionStatus: 'COMPLETED',
      isActive: false,
      isInitiator: true,
      resultExpireDate: '02.12.2022 18:07:05',
      currentExpireDate: '02.12.2022 19:35:57',
      sendCount: 1,
      signalDeliveredMessageId: 'eed7e72e-7e01-79ad-e053-fa12000a6742',
      signalDeliveredDate: '02.12.2022 17:47:38',
      signalInProcessMessageId: 'eed7e72e-7e04-79ad-e053-fa12000a6742',
      signalInProcessDate: '02.12.2022 17:47:38',
      resultMessageId: 'eed7e72e-7e06-79ad-e053-fa12000a6742',
      resultDate: '02.12.2022 19:34:57',
      signalResultDeliveredMessageId: '33e0c508-1baa-4080-b277-03cbdca1ed59',
      signalResultDeliveredDate: '02.12.2022 20:08:18',
    },
  ],
};
