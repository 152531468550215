import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from 'rest';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryAdminNews: DataQueryFunction = (variables: any) => {
  const { filter, pagination } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  return httpClient.get(`/admin/news/getAllNews`, {
    params: { criteria, ...toPaginationParams(pagination) },
  });
};
