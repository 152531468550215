import React, { Fragment, useMemo, useState } from 'react';
import { UserContent, UserHistoryContent } from './components';
import { queryArmUsersExport, queryUsers, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { getArmUsersColumns } from './getArmUsersColumns';
import { Journal, JournalButton } from 'common/components/journal';
import { Modal } from 'services/modal';
import { ArmUsersFilters } from './ArmUsersFilters';

const initialFilter = { blocked: false };
const initialPagination = { rows: 15 };

const getRowId = (row: any) => row.iin;

type Props = {
  className?: string;
};

const ArmUsersPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const [modal, setModal] = useState<{
    opened: 'none' | 'user' | 'invite' | 'history';
    row: any;
  }>({
    opened: 'none',
    row: null,
  });

  const onCloseModal = () => setModal({ opened: 'none', row: null });

  const columns = useMemo(() => getArmUsersColumns(locale), [locale]);

  const queryProps = useDataQuery(queryUsers, {
    filter: initialFilter,
    pagination: initialPagination,
  });

  const { refetch } = queryProps;

  const headerButtons = useMemo(
    () => [
      {
        id: 1,
        label: 'Пригласить администратора',
        onClick: () => setModal({ opened: 'invite', row: null }),
      },
      {
        id: 2,
        label: loc('common.button.exportExcel'),
        onClick: () =>
          queryArmUsersExport({
            filter: queryProps.filter,
          }),
      },
    ],
    [loc, queryProps.filter]
  );

  const actionButtons = useMemo(
    (): JournalButton[] => [
      {
        id: 1,
        label: loc('common.button.edit'),
        onClick: (selected) => setModal({ opened: 'user', row: selected[0] }),
      },
      {
        id: 2,
        label: loc('common.button.history'),
        onClick: (selected) => setModal({ opened: 'history', row: selected[0] }),
      },
    ],
    [loc]
  );

  return (
    <Fragment>
      <Journal
        pageTitle={loc('armUsers.pageTitle')}
        filterComponent={ArmUsersFilters}
        queryProps={queryProps}
        columns={columns}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        filterInitialOpened
        selectable
        getRowId={getRowId}
      />

      <Modal isOpen={modal.opened === 'user'} onClose={onCloseModal} wait>
        <UserContent modal iin={modal.row?.iin} refetchList={refetch} />
      </Modal>

      <Modal isOpen={modal.opened === 'invite'} onClose={onCloseModal}>
        <UserContent modal newInvitation refetchList={refetch} />
      </Modal>

      <Modal isOpen={modal.opened === 'history'} onClose={onCloseModal} wait>
        <UserHistoryContent modal iin={modal.row?.iin} />
      </Modal>
    </Fragment>
  );
};

export default ArmUsersPage;
