import React, { useCallback, useMemo } from 'react';
import { useLocale } from 'services/l10n/useLocale';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Journal, JournalButton } from 'common/components';
import { getSntTermsRegistrationColumns } from './getSntTermsRegistrationColumns';
import {
  queryAdminSntRegistrationOverdue,
  queryAdminSntRegistrationOverdueReport,
} from '../api';
import { SntTermsRegistrationFilters } from './SntTermsRegistrationFilters';
import { formatFromDate } from 'services/date-time';
import { SessionStorageService } from 'services/storage/StorageService';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.sntId;

const filterStorageKey = 'sntTermsRegistration';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, журнал "Фиксация нарушения сроков оформления СНТ поставщиком"
 * arm/snt-terms-registration
 */
export const SntTermsRegistration: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const columns = useMemo(
    () => getSntTermsRegistrationColumns({ locale }),
    [locale]
  );

  const queryProps = useDataQuery(queryAdminSntRegistrationOverdue, {
    filter: SessionStorageService.get(filterStorageKey) || {
      dateFrom: formatFromDate(new Date()),
      dateTo: formatFromDate(new Date()),
    },
    pagination: initialPagination,
  });

  const exportJournal = useCallback(
    async (format: 'XLSX' | 'CSV') => {
      try {
        await queryAdminSntRegistrationOverdueReport({
          filter: queryProps.filter,
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    },
    [queryProps.filter]
  );

  const headerButtons = useMemo(
    (): Array<JournalButton> => [
      {
        id: 1,
        label: loc('common.button.exportExcel'),
        onClick: (selection: Array<any>) => exportJournal('XLSX'),
      },
      {
        id: 2,
        label: loc('common.button.exportCsv'),
        onClick: (selection: Array<any>) => exportJournal('CSV'),
      },
    ],
    [loc, exportJournal]
  );

  return (
    <Journal
      pageTitle={loc('sntTerms.registration')}
      columns={columns}
      queryProps={queryProps}
      filterComponent={SntTermsRegistrationFilters}
      filterInitialOpened
      filterExpandable
      filterStorageKey={filterStorageKey}
      headerButtons={headerButtons}
      getRowId={getRowId}
    />
  );
};
