/**
 * trim all string properties of object/array (deep)
 * @param object
 * @returns new object
 */
export function trimProps(object: any): any {
  if (typeof object === 'string') {
    return object.trim();
  }
  if (typeof object !== 'object' || object === null) {
    return object;
  }
  if (Array.isArray(object)) {
    return object.map((item) => trimProps(item));
  }
  return Object.entries(object).reduce((acc: any, [key, value]) => {
    if (value && typeof value === 'object' && !(value instanceof File)) {
      acc[key] = trimProps(value);
    } else if (typeof value === 'string') {
      acc[key] = value.trim();
    } else {
      acc[key] = value;
    }
    return acc;
  }, {});
}
