import { clearEmptyFields } from 'common/utils';
import { convertToFormDataDeep, DataMutationFunction, httpClient } from 'rest';

export const mutationInvoiceUploadFile: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post('/invoice/uploadFile', formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
