import React, { Fragment } from 'react';
import s from './SmallRetailContent.module.scss';
import { ModalHeader } from 'services/modal';
import { ModalFooter } from 'services/modal/components/ModalFooter';
import { Button } from 'ui/common/controls';
import { ModalChildrenProps } from 'services/modal/types';
import { mutationAdminSROAdd, mutationAdminSROUpdate } from '../api';
import { SmallRetailForm } from './SmallRetailForm';
import { useLocale } from 'services/l10n';

type Props = ModalChildrenProps & {
  className?: string;
  onSave: () => void;
  data: any;
};

export const SmallRetailContent: React.FC<Props> = ({ onClose = () => {}, onSave = () => {}, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  const saveFormData = async (values: any) => {
    // если есть изначальное значение data, то делаем апдейт данных, иначе создем новую запись
    const mutation = data ? mutationAdminSROUpdate : mutationAdminSROAdd;
    const res = await mutation({
      body: { value: JSON.stringify(values) },
    });
    onSave();
    onClose();
    return res;
  };

  return (
    <Fragment>
      <ModalHeader>{loc('small.smallRetail')}</ModalHeader>
      <SmallRetailForm
        onSubmit={saveFormData}
        data={data}
        renderModalFooter={({ dirty }) => (
          <ModalFooter>
            <Button type="button" variant="secondary" className={s.submit} size="small" onClick={onClose}>
              {loc('common.button.cancel')}
            </Button>
            <Button type="submit" className={s.submit} size="small" disabled={!dirty}>
              {loc('common.button.save')}
            </Button>
          </ModalFooter>
        )}
      />
    </Fragment>
  );
};
