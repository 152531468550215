import { AxiosResponse } from 'axios';
import { DataQueryFunction, httpClient } from 'rest';

export const queryVstoreUserSettingsFind: DataQueryFunction = async () => {
  const res = await httpClient.get(`/vstore/rest/user-settings/find`);
  //Если настроек у пользоваетля нет, то приходит пустой ответ. Возвращаем настройки по умолчанию.
  if (res.data === '') {
    return {
      data: {
        dtZvtSettings: 'PROCESS_ALL',
        sntSettings: 'PROCESS_ALL',
      },
    } as AxiosResponse;
  } else {
    return res;
  }
};
