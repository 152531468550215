import { AxiosResponse } from 'axios';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationIsgoInvoiceActionConfirmSend: DataMutationFunction<AxiosResponse> = async (
  variables
): Promise<any> => {
  const { body } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   throw { data: fakeConfirmSendError };
  // }

  const formData = convertToFormData(body || {});

  const res = await httpClient.post('/isgo/invoice/action/confirm/send', formData);

  if (res?.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
