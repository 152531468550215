import { ADMIN_PERMISSION, AdminPermission } from './adminPermission';

export const ADMIN_PERMISSION_GROUPS: Array<{
  id: number;
  name: string;
  label: string;
  permissions: Array<AdminPermission>;
}> = [
  {
    id: 1,
    name: 'ADMINISTRATION',
    label: 'admin.permissionGroup.ADMINISTRATION',
    permissions: [ADMIN_PERMISSION.USERS_MANAGE, ADMIN_PERMISSION.OGD_USERS_EXPORT, ADMIN_PERMISSION.OGD_USERS_VIEW],
  },
  {
    id: 2,
    name: 'MONITORING',
    label: 'admin.permissionGroup.MONITORING',
    permissions: [
      ADMIN_PERMISSION.SYSTEM_PARAMS,
      ADMIN_PERMISSION.MESSAGES_SEND,
      ADMIN_PERMISSION.MESSAGES_VIEW,
      ADMIN_PERMISSION.NOTIFICATION_TEMPLATES_EDIT,
      ADMIN_PERMISSION.SOFT_MANAGE,
      ADMIN_PERMISSION.MONITORING,
      ADMIN_PERMISSION.STATISTICS_VIEW,
      ADMIN_PERMISSION.USER_ACCESS_INTERNAL_VIEW,
      ADMIN_PERMISSION.USER_ACCESS_EXTERNAL_VIEW,
      ADMIN_PERMISSION.DOWNTIME_VIEW,
      ADMIN_PERMISSION.DOWNTIME_EDIT,
      ADMIN_PERMISSION.NEWS_PUBLISH,
      ADMIN_PERMISSION.NEWS_DELETE,
      ADMIN_PERMISSION.CALENDAR_VIEW,
      ADMIN_PERMISSION.CALENDAR_EDIT,
    ],
  },
  {
    id: 3,
    name: 'REGISTRATION',
    label: 'admin.permissionGroup.REGISTRATION',
    permissions: [
      ADMIN_PERMISSION.TAXPAYER_SEARCH,
      ADMIN_PERMISSION.PERMISSION_VIEW,
      ADMIN_PERMISSION.AGREEMENT_ACTIONS,
      ADMIN_PERMISSION.MPT_VIEW,
      ADMIN_PERMISSION.REREGISTRATION_VIEW,
      ADMIN_PERMISSION.CHECK_ACTIVE_REGISTRATION,
      ADMIN_PERMISSION.TAXPAYER_BLOCK_VIEW,
      ADMIN_PERMISSION.TAXPAYER_BLOCK_EDIT,
    ],
  },
  {
    id: 4,
    name: 'VIRTUAL_STORE',
    label: 'admin.permissionGroup.VIRTUAL_STORE',
    permissions: [
      ADMIN_PERMISSION.VIRTUAL_LABEL_VIEW,
      ADMIN_PERMISSION.VIRTUAL_LABEL_CHAIN_VIEW,
      ADMIN_PERMISSION.TAXPAYER_STORE_VIEW,
      ADMIN_PERMISSION.FNO_VIEW,
      ADMIN_PERMISSION.GTD_VIEW,
      ADMIN_PERMISSION.UFORM_VIEW,
      ADMIN_PERMISSION.CERTIFICATE_VIEW,
      ADMIN_PERMISSION.VIEW_VIRTUAL_LABEL_REMAINDERS,
      ADMIN_PERMISSION.DOCUMENT_ZVT_VIEW,
    ],
  },
  {
    id: 5,
    name: 'INVOICE',
    label: 'admin.permissionGroup.INVOICE',
    permissions: [
      ADMIN_PERMISSION.INVOICE_VIEW,
      ADMIN_PERMISSION.INVOICE_ACTIONS,
      ADMIN_PERMISSION.NDS_CUSTOM_MANIFEST_PAYMENTS_VIEW,
      ADMIN_PERMISSION.ISGO_INVOICE_SEARCH,
      ADMIN_PERMISSION.INVOICE_PRODUCTS_VIEW,
      ADMIN_PERMISSION.ISGO_UNLOCK,
    ],
  },
  {
    id: 6,
    name: 'AWP',
    label: 'admin.permissionGroup.AWP',
    permissions: [ADMIN_PERMISSION.AWP_VIEW],
  },
  {
    id: 7,
    name: 'ECONTRACT',
    label: 'admin.permissionGroup.ECONTRACT',
    permissions: [ADMIN_PERMISSION.ECONTRACT_VIEW],
  },
  {
    id: 8,
    name: 'SNT',
    label: 'admin.permissionGroup.SNT',
    permissions: [
      ADMIN_PERMISSION.SNT_VIEW,
      ADMIN_PERMISSION.SNT_PRODUCTS_VIEW,
      ADMIN_PERMISSION.SNT_EXPIRE_CONTROL,
      ADMIN_PERMISSION.SNT_CONFIRMATION_CONTROL,
    ],
  },
  {
    id: 9,
    name: 'EXPORT_CONTROL',
    label: 'admin.permissionGroup.EXPORT_CONTROL',
    permissions: [
      ADMIN_PERMISSION.TRANSIT_REGISTRY_VIEW,
      ADMIN_PERMISSION.TRANSIT_REGISTRY_EDIT,
      ADMIN_PERMISSION.PERMISSIVE_DOCUMENT_VIEW,
      ADMIN_PERMISSION.SNT_EAES_VIEW,
      ADMIN_PERMISSION.SNT_OGD_ACTIONS,
      ADMIN_PERMISSION.SNT_OGD_EDIT,
    ],
  },
  {
    id: 10,
    name: 'DICTIONARY_VIEW',
    label: 'admin.permissionGroup.DICTIONARY_VIEW',
    permissions: [
      ADMIN_PERMISSION.BANK_VIEW,
      ADMIN_PERMISSION.BIG_COMPANY_VIEW,
      ADMIN_PERMISSION.CURRENCY_RATE_VIEW,
      ADMIN_PERMISSION.MEASURE_UNIT_VIEW,
      ADMIN_PERMISSION.SHARING_PARTICIPANT_VIEW,
      ADMIN_PERMISSION.GSVS_VIEW,
      ADMIN_PERMISSION.REORGANIZATION_VIEW,
      ADMIN_PERMISSION.OIL_PIN_VIEW,
      ADMIN_PERMISSION.TOBACCO_PIN_VIEW,
      ADMIN_PERMISSION.ALCOHOL_PIN_VIEW,
      ADMIN_PERMISSION.OIL_OVD_VIEW,
      ADMIN_PERMISSION.TOBACCO_OVD_VIEW,
      ADMIN_PERMISSION.ALCOHOL_LICENSE_VIEW,
      ADMIN_PERMISSION.ALCOHOL_SPIRIT_PERCENT_VIEW,
      ADMIN_PERMISSION.EXCISE_RATE_VIEW,
      ADMIN_PERMISSION.BANNED_GOOD_VIEW,
      ADMIN_PERMISSION.PLANNED_CHECKPOINT_VIEW,
      ADMIN_PERMISSION.TAXPAYER_MARK_VIEW,
      ADMIN_PERMISSION.OGD_VIEW,
      ADMIN_PERMISSION.ECM_CARRIER_VIEW,
      ADMIN_PERMISSION.ECM_AUTO_VIEW,
      ADMIN_PERMISSION.SMALL_RETAIL_OUTLET_VIEW,
      ADMIN_PERMISSION.ISGO_VIEW,
      ADMIN_PERMISSION.QOLDAY_VIEW,
      ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_VIEW,
      ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_VIEW,
      ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_VIEW,
    ],
  },
  {
    id: 11,
    name: 'DICTIONARY_EDIT',
    label: 'admin.permissionGroup.DICTIONARY_EDIT',
    permissions: [
      ADMIN_PERMISSION.BIG_COMPANY_EDIT,
      ADMIN_PERMISSION.CURRENCY_RATE_EDIT,
      ADMIN_PERMISSION.MEASURE_UNIT_EDIT,
      ADMIN_PERMISSION.SHARING_PARTICIPANT_EDIT,
      ADMIN_PERMISSION.GSVS_EDIT,
      ADMIN_PERMISSION.REORGANIZATION_EDIT,
      ADMIN_PERMISSION.OIL_PIN_EDIT,
      ADMIN_PERMISSION.TOBACCO_PIN_EDIT,
      ADMIN_PERMISSION.ALCOHOL_PIN_EDIT,
      ADMIN_PERMISSION.OIL_OVD_EDIT,
      ADMIN_PERMISSION.TOBACCO_OVD_EDIT,
      ADMIN_PERMISSION.ALCOHOL_LICENSE_EDIT,
      ADMIN_PERMISSION.ALCOHOL_SPIRIT_PERCENT_EDIT,
      ADMIN_PERMISSION.EXCISE_RATE_EDIT,
      ADMIN_PERMISSION.BANNED_GOOD_EDIT,
      ADMIN_PERMISSION.PLANNED_CHECKPOINT_EDIT,
      ADMIN_PERMISSION.TAXPAYER_MARK_EDIT,
      ADMIN_PERMISSION.ECM_CARRIER_EDIT,
      ADMIN_PERMISSION.ECM_AUTO_EDIT,
      ADMIN_PERMISSION.SMALL_RETAIL_OUTLET_EDIT,
      ADMIN_PERMISSION.ISGO_EDIT,
      ADMIN_PERMISSION.BIOMETRICS_NOT_REQUIRED_TAXPAYER_EDIT,
      ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_EDIT,
      ADMIN_PERMISSION.GOVERNMENT_INSTITUTION_EDIT,
    ],
  },
  {
    id: 12,
    name: 'REPORT',
    label: 'admin.permissionGroup.REPORT',
    permissions: [
      ADMIN_PERMISSION.TRANSIT_REPORT,
      ADMIN_PERMISSION.STATISTIC_REPORT,
      ADMIN_PERMISSION.REGISTERED_USER_REPORT,
      ADMIN_PERMISSION.SNT_BORDER_CROSSING_REPORT,
      ADMIN_PERMISSION.STATISTIC_DOCFLOW_REPORT,
      ADMIN_PERMISSION.STATISTIC_REPORT_MOBILE_DASHBOARD,
      ADMIN_PERMISSION.SALDO_VIEW,
    ],
  },
  {
    id: 13,
    name: 'PASSPORT',
    label: 'admin.permissionGroup.PASSPORT',
    permissions: [
      ADMIN_PERMISSION.ASSURANCE_VIEW,
      ADMIN_PERMISSION.ASSURANCE_ACTIONS,
      ADMIN_PERMISSION.EAEC_USERS_MANAGE,
    ],
  },
  {
    id: 14,
    name: 'EAEC',
    label: 'admin.permissionGroup.EAEC',
    permissions: [
      ADMIN_PERMISSION.EEC_SEND_TRACEABILITY_GOODS_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_TRACEABILITY_GOODS_INPUT_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_TRACEABILITY_GOODS_OUTPUT_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_OPERATIONS_INPUT_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_OPERATIONS_OUTPUT_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_MONITORING_REQUEST,
      ADMIN_PERMISSION.EEC_VIEW_TRANSACTION,
    ],
  },
];
