import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntRelatedDocuments: DataQueryFunction = async (variables: any) => {
  const { id } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   return { data: fakeRelatedDocuments } as AxiosResponse;
  // }

  const res = await httpClient.get(`snt/relatedDocuments/${id}`);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};

// const fakeRelatedDocuments = {
//   success: true,
//   data: {
//     value: {
//       sntId: '21512543922',
//       relatedInvoiceList: [
//         {
//           id: '83234324',
//           registrationNumber: 'ESF-123456789021-20240516-00170291',
//           date: '16.05.2024',
//           type: 'ORDINARY_INVOICE',
//           status: 'CREATED',
//           total: 150222.0,
//         },
//         {
//           id: '215146',
//           registrationNumber: 'ESF-123456789021-20240516-00170291',
//           date: '16.05.2024',
//           type: 'ORDINARY_INVOICE',
//           status: 'CREATED',
//           total: 2306000.0,
//         },
//         {
//           id: '2165',
//           registrationNumber: 'ESF-123456789021-20240516-00170291',
//           date: '16.05.2024',
//           type: 'ORDINARY_INVOICE',
//           status: 'CREATED',
//           total: 400500.0,
//         },
//         {
//           id: '8685',
//           registrationNumber: 'ESF-123456789021-20240516-00170291',
//           date: '16.05.2024',
//           type: 'ORDINARY_INVOICE',
//           status: 'CREATED',
//           total: 240500.0,
//         },
//       ],
//       relatedEcontractList: [
//         {
//           id: '2423432',
//           registrationNumber: 'safasfsdfsdfsdfsdfsdf',
//           type: 'SUPPLEMENTARY',
//           status: 'PARTIALLY_VIEWED',
//           total: 1306000.0,
//         },
//         {
//           id: '9764',
//           registrationNumber: 'safasfsdfsdfsdfsdfsdf',
//           type: 'SUPPLEMENTARY',
//           status: 'PARTIALLY_VIEWED',
//           total: 700123.0,
//         },
//         {
//           id: '34',
//           registrationNumber: 'safasfsdfsdfsdfsdfsdf',
//           type: 'SUPPLEMENTARY',
//           status: 'PARTIALLY_VIEWED',
//           total: 700123.0,
//         },
//         {
//           id: '56',
//           registrationNumber: 'safasfsdfsdfsdfsdfsdf',
//           type: 'SUPPLEMENTARY',
//           status: 'PARTIALLY_VIEWED',
//           total: 700123.0,
//         },
//         {
//           id: '12',
//           registrationNumber: 'safasfsdfsdfsdfsdfsdf',
//           type: 'SUPPLEMENTARY',
//           status: 'PARTIALLY_VIEWED',
//           total: 700123.0,
//         },
//       ],
//       relatedSntList: [
//         {
//           id: '0976',
//           registrationNumber: 'SNT-23123123-324234234',
//           type: 'RETURNED_SNT',
//           status: 'DECLINED',
//           total: 800000.0,
//         },
//         {
//           id: '3806',
//           registrationNumber: 'SNT-23123123-324234234',
//           type: 'RETURNED_SNT',
//           status: 'DECLINED',
//           total: 106000.0,
//         },
//       ],
//       sntToFnoRelations: [
//         {
//           id: '3',
//           registrationNumber: 'FNO-987664-00023445',
//           status: 'PROCESSED',
//         },
//         {
//           id: '4',
//           registrationNumber: 'FNO-987664-00023445',
//           status: 'PROCESSED',
//         },
//         {
//           id: '7',
//           registrationNumber: 'FNO-987664-00023445',
//           status: 'PROCESSED',
//         },
//       ],
//     },
//   },
// };
