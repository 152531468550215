import { AxiosResponse } from 'axios';

export type repeatableRequestProps<T> = {
  request: () => Promise<T>;
  pattern: Array<number>;
  isSuccess?: (res?: T) => boolean;
  isError?: (res: T) => boolean;
  onSuccess: (res: T) => void;
};

export function repeatableRequest<T = AxiosResponse>(props: repeatableRequestProps<T>) {
  const { request, pattern, isSuccess = () => true, onSuccess } = props;

  let timeout: ReturnType<typeof setTimeout>;
  let result: any;

  console.log('repeatableRequest');

  function sendRequest(index: number) {
    timeout = setTimeout(function () {
      request()
        .then((res) => {
          result = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          clearTimeout(timeout);
          if (isSuccess(result)) {
            onSuccess(result);
            console.log('repeatableRequest ---- ended success');
          } else {
            if (typeof pattern[index + 1] === 'number') {
              sendRequest(index + 1);
            } else {
              console.log('repeatableRequest ---- ended');
            }
          }
        });
    }, pattern[index]);
  }

  if (Array.isArray(pattern) && pattern.length) {
    sendRequest(0);
  }

  return () => {
    console.log('unsub timeout');
    clearTimeout(timeout);
  };
}
