import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import s from './ArmLayout.module.scss';
import clsx from 'clsx';
import { SvgIcon } from 'ui/common';
import { ArmLayoutAside } from './aside/ArmLayoutAside';
import { ArmLayoutHeader } from './header/ArmLayoutHeader';
import { ArmLayoutFooter } from './footer/ArmLayoutFooter';

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

export const ArmLayout: React.FC<Props> = (props) => {
  const { children } = props;
  const [minimized, setMinimized] = useState(false);

  useEffect(() => {
    console.log('MainLayout MOUNTED!!');
  }, []);

  return (
    <div className={clsx(s.wrapper, { [s.minimized]: minimized })}>
      <div className={s.aside}>
        <ArmLayoutAside minimized={minimized} />
        <div className={s.navToggle} onClick={() => setMinimized((state) => !state)}>
          <SvgIcon name="chevron" />
        </div>
      </div>
      <div className={s.content}>
        <div className={s.innerContainer}>
          <div className={s.header}>
            <ArmLayoutHeader />
          </div>
          {children}
        </div>
      </div>
      <div className={s.footer}>
        <ArmLayoutFooter minimized={minimized} />
      </div>
    </div>
  );
};
