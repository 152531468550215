import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import s from './RegAgreement.module.scss';
import ss from '../../Registration.module.scss';
import { ButtonT2, CheckboxT2, FieldT2 } from 'ui';
import { getAgreementHtml } from '../../helpers';
import { RegAgreementContent } from './RegAgreementContent';
import { RegCredentials } from './RegCredentials';
import { observer } from 'mobx-react-lite';
import { RegistrationStore } from '../../RegistrationStore';
import { useStore } from 'common/hooks';
import { RegType } from 'features/registration/types';

type Props = {
  className?: string;
  regType: RegType;
  regStore: RegistrationStore;
  loading?: boolean;
  onSigned(): void;
  onBack(): void;
};

export const RegAgreement: React.FC<Props> = observer(({ regStore, onBack, onSigned, regType, loading }) => {
  const { locale, auth } = useStore();
  const { loc } = locale;

  const [innerLoading, setInnerLoading] = useState(false);

  const onSign = async () => {
    setInnerLoading(true);
    const agreementHtml = getAgreementHtml();
    try {
      const { cert, sign } = await auth.getSignature(agreementHtml, 'REGISTRATION');
      regStore.setAgreement(regType, (agreement: any) => ({
        ...agreement,
        certificate: cert.base64Pem,
        signature: sign,
        content: agreementHtml,
        tin: regStore.regData?.iin,
        name: regStore.regData?.fio,
      }));
      onSigned();
    } catch (error) {
      console.log(error);
    } finally {
      setInnerLoading(false);
    }
  };

  return (
    <Fragment>
      <div className={clsx(ss.frame)}>
        <div className="agreementHtml">
          <RegAgreementContent />
          <RegCredentials type={regType} regData={regStore.regData} />
        </div>
      </div>

      <div className={s.agreeSection}>
        <FieldT2 label={loc('reg.agreeText')} direction="line-reverse" htmlFor="confirmAgreement">
          <CheckboxT2
            id="confirmAgreement"
            checked={!!regStore.agreements[regType]?.confirmed}
            onChange={(e) => regStore.setAgreement(regType, (data: any) => ({ ...data, confirmed: e.target.checked }))}
            readOnly={!!regStore.agreements[regType]?.signature}
          />
        </FieldT2>
      </div>
      <div className={ss.actions}>
        <Fragment>
          <ButtonT2 size="large" variant="secondary" onClick={() => onBack()}>
            {loc('reg.back')}
          </ButtonT2>
          <ButtonT2
            size="large"
            onClick={() => {
              onSign();
            }}
            disabled={!regStore.agreements[regType]?.confirmed || loading || innerLoading}
          >
            {loc('common.button.sign')}
          </ButtonT2>
        </Fragment>
      </div>
    </Fragment>
  );
});
