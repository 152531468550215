import React from 'react';
import clsx from 'clsx';
import s from './GuestLayoutFooter.module.scss';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
};

export const GuestLayoutFooter: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.inner}>
        <div className={s.col}>
          <ul>
            <li>
              <a href="https://kgd.gov.kz/ru">{loc('common.layout.link1')}</a>
            </li>
            <li>
              <a href="https://www.gov.kz/memleket/entities/minfin?lang=ru">{loc('common.layout.link2')}</a>
            </li>
            <li>
              <a href="https://www.gov.kz/article/128915?lang=ru">{loc('common.layout.link4')}</a>
            </li>
            <li>
              <a href="https://www.akorda.kz/ru">{loc('common.layout.link6')}</a>
            </li>
            <li>
              <a href="https://primeminister.kz/ru">{loc('common.layout.link8')}</a>
            </li>
            <li>
              <a href="http://www.parlam.kz/ru">{loc('common.layout.link9')}</a>
            </li>
          </ul>
        </div>
        <div className={s.col}>
          <ul>
            <li>
              <a href="https://kgd.gov.kz/sites/default/files/osnovnye_polozheniya_raboty_sds_is_esf_1.pdf">
                {loc('common.layout.rules')}
              </a>
            </li>
            <li>
              <a href="https://kgd.gov.kz/sites/default/files/unn/chasto_zadavaemye_voprosy_0.docx">
                {loc('common.layout.faq')}
              </a>
            </li>
          </ul>
        </div>
        <div className={s.col}></div>
      </div>
    </div>
  );
};
