import { LocaleStore } from 'services/l10n';
import { USER_ACCESS_EVENT_LEVEL, USER_ACCESS_MODULE_TYPE } from './userAccessConstants';
import { EXTERNAL_USER_ACCESS_MODULES_MAP, INTERNAL_USER_ACCESS_MODULES_MAP } from './userAccessModulesMap';

export const getUserAccessLevelOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: USER_ACCESS_EVENT_LEVEL.INFO,
    label: loc(`userAccess.eventLevel.INFO`),
  },
  {
    value: USER_ACCESS_EVENT_LEVEL.ERROR,
    label: loc(`userAccess.eventLevel.ERROR`),
  },
];

export const getUserAccessModuleOptions = (
  { loc }: LocaleStore,
  pageType: 'external' | 'internal'
): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...Object.values(USER_ACCESS_MODULE_TYPE)
    .filter(
      (item) => (pageType === 'external' ? EXTERNAL_USER_ACCESS_MODULES_MAP : INTERNAL_USER_ACCESS_MODULES_MAP)[item]
    )
    .map((item) => ({
      value: item,
      label: loc(`userAccess.moduleType.${item}`),
    })),
];

export const getUserAccessTypeOptions = (
  { loc }: LocaleStore,
  module: string,
  pageType: 'external' | 'internal'
): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...(
    (pageType === 'external' ? EXTERNAL_USER_ACCESS_MODULES_MAP : INTERNAL_USER_ACCESS_MODULES_MAP)[module] || []
  ).map((item) => ({
    value: item,
    label: loc(`userAccess.eventType.${item}`),
  })),
];
