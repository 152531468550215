import React from 'react';
import clsx from 'clsx';
import s from './Avatar.module.scss';

type Props = {
  className?: string;
  imageUrl?: string;
  name?: string;
  maxLetters?: 1 | 2 | 3;
};

export const Avatar: React.FC<Props> = ({ className, imageUrl, name, maxLetters = 1 }) => {
  const letters = !imageUrl && name ? name.slice(0, maxLetters) : '';
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.twoLetters]: letters.length === 2,
        [s.threeLetters]: letters.length === 3,
      })}
      style={imageUrl ? { backgroundImage: `url('${imageUrl}')` } : {}}
    >
      {!!letters && <span className={s.letters}>{letters}</span>}
    </div>
  );
};
