import { API_URL, AppMode, DocDirection } from 'common/constants';
import { getLegasyUrl } from 'common/helpers';
import { UserStore } from 'common/stores/user-store';
import { downloadFile } from 'rest';

/**
 * Определяет направление документа ЭД (Входящий, Исходящий)
 */
export const solveEcontractDirection = (
  econtractRow: any,
  user: UserStore
): DocDirection.INBOUND | DocDirection.OUTBOUND => {
  const senderTins: any[] = Array.isArray(econtractRow.senderTinList) ? econtractRow.senderTinList : [];
  if (senderTins.includes(user.currentUser?.currentUserTin)) {
    return DocDirection.OUTBOUND;
  }
  return DocDirection.INBOUND;
};

/**
 * Перенавравоение на страницу бланка ЭД (в старом интерфейсе)
 */
export const navigateToEcontractPage = (mode: 'show' | 'copy', econtractId: string) => {
  if (mode === 'show') {
    window.location.assign(getLegasyUrl(`/e-contract/${econtractId}`));
  } else {
    window.location.assign(getLegasyUrl(`/e-contract/${mode}/${econtractId}`));
  }
};

export const downloadEcontractPdf = async (ids: Array<string>, format: 'PDF' | 'XLSX', appMode?: AppMode) => {
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url =
      appMode === AppMode.ARM
        ? `${API_URL}/admin/econtract/printReport/${id}/${format}`
        : `${API_URL}/econtract/printReport/${id}/${format}`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};
