import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import s from './TaxpayerLayout.module.scss';
import clsx from 'clsx';
import { TaxpayerLayoutAside } from './aside';
import { TaxpayerLayoutHeader } from './header';

type Props = RouteComponentProps & {
  children: React.ReactNode;
};

export const TaxpayerLayout: React.FC<Props> = (props) => {
  const { children } = props;

  useEffect(() => {
    console.log('T2Layout MOUNTED!!');
  }, []);

  return (
    <div className={clsx(s.wrapper)}>
      <TaxpayerLayoutAside className={s.aside} />
      <div className={s.content}>
        <TaxpayerLayoutHeader className={s.header} />
        {children}
      </div>
    </div>
  );
};
