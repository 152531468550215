import { AxiosResponse } from 'axios';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationisgoInvoiceActionCreateHash: DataMutationFunction<AxiosResponse> = async (
  variables
): Promise<any> => {
  const { body } = variables || {};

  const formData = convertToFormData(body || {});

  const res = await httpClient.post('/isgo/invoice/action/create/hash', formData);

  if (res?.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
