import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryMessagesGetOutbound: DataQueryFunction = async () => {
  // if (process.env.NODE_ENV === 'development') {
  //   return {
  //     data: fakeGetMessageForConfirmation,
  //   } as AxiosResponse;
  // }

  return httpClient.get(`/messages/getOutboundMessagesByIdDescWithPaging`);
};
