import React from 'react';
import clsx from 'clsx';
import s from './Dialog.module.scss';
import { ModalChildrenProps } from 'services/modal/types';
import { DialogProps, ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { Button } from 'ui/common';
import { useLocale } from 'services/l10n';
import { useGlobalTheme } from 'common/hooks';

type Props = DialogProps &
  ModalChildrenProps & {
    onConfirm: () => void;
    onReject: () => void;
  };

export const Dialog: React.FC<Props> = ({
  type = 'dialog',
  content,
  title,
  confirmButtonText,
  rejectButtonText,
  onConfirm,
  onReject,
  width,
  style = {},
}) => {
  const locale = useLocale();
  const { loc } = locale;
  const theme = useGlobalTheme();

  const dialogTitle =
    title ||
    (type === 'dialog' ? loc('common.title.confirmAction') : type === 'alert' ? loc('common.title.notice') : '');

  return (
    <div
      className={clsx(s.wrapper, {
        [s.noHeader]: !dialogTitle,
        [s.alert]: type === 'alert',
      })}
      style={{ width: width || 400, ...style }}
    >
      {!!dialogTitle && <ModalHeader theme={theme}>{dialogTitle}</ModalHeader>}
      <ModalContent className={clsx(s.content)} theme={theme}>
        {content}
      </ModalContent>
      <ModalFooter className={s.footer} theme={theme}>
        <Button
          variant={type === 'alert' ? 'primary' : theme === 'T2' ? 'transparent' : 'secondary'}
          onClick={onReject}
          theme={theme}
          size={'small'}
        >
          {rejectButtonText || (type === 'dialog' ? loc('common.button.cancel') : loc('common.button.close'))}
        </Button>
        {type === 'dialog' && (
          <Button onClick={onConfirm} theme={theme} size="small">
            {confirmButtonText || loc('common.button.confirm')}
          </Button>
        )}
      </ModalFooter>
    </div>
  );
};
