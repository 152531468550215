import React, { Fragment, useMemo, useRef } from 'react';
import clsx from 'clsx';
import s from './SntExportReportForm.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Field, FormField, FormMultiselect, FormSelect, Loader, PageFooter } from 'ui/common';
import { useLocale } from 'services/l10n';
import { FormCheckpointSelect, FormDoubleDatePicker, FormOGDSelect } from 'common/components';
import {
  getBorderCrossingCheckpointsPrefixOptions,
  getBorderCrossingTypeOptions,
  getOGDPrefixOptions,
  getOgdMarkOptions,
  getSntStatusOptions,
} from './constants';
import { formatFromDate } from 'services/date-time';
import { ToastService } from 'services/toasts';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { SntBorderCrossingType } from 'features/snt';

const emptyValues = {
  borderCrossingType: SntBorderCrossingType.ALL,
  checkpoint: 'ALL',
  dateFrom: formatFromDate(new Date()),
  dateTo: formatFromDate(new Date()),
  ogdStatuses: [],
  statuses: [],
  ogdCode: 'ALL',
} as any;

// const schema = yup.object({
//   dateFrom: yup.string().required('Даты обязательны для заполнения'),
//   dateTo: yup.string().required('Даты обязательны для заполнения'),
//   ogdStatuses: yup
//     .array()
//     .of(yup.string())
//     .required('Отметки ОГД обязателен для заполнения'),
//   statuses: yup
//     .array()
//     .of(yup.string())
//     .required('Статус СНТ обязателен для заполнения'),
// });

export type SubmitType = 'pdf' | 'xls';

type Props = {
  className?: string;
  onSubmit(values: any, submitType: SubmitType): Promise<any>;
};

export const SntExportReportForm: React.FC<Props> = ({ className, onSubmit }) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitType = useRef<SubmitType>('pdf');

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await onSubmit(values, submitType.current);
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      actions.setErrors(solveFormErrors(error));
      console.error('form error', error);
      actions.setSubmitting(false);
    }
  };

  const ogdMarkOptions = useMemo(() => getOgdMarkOptions(locale), [locale]);
  const borderCrossingTypeOptions = getBorderCrossingTypeOptions(locale);
  const checkpointsPrefixOptions = getBorderCrossingCheckpointsPrefixOptions(locale);
  const ogdPrefixOptions = getOGDPrefixOptions(locale);

  return (
    <Formik
      initialValues={emptyValues}
      onSubmit={submitHandler}
      // validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ setValues, submitForm, errors, values, isSubmitting }) => {
        const dateErrorMessage =
          (errors['dateFrom'] && String(errors['dateFrom'])) || (errors['dateTo'] && String(errors['dateTo']));
        const sntStatusOptions = getSntStatusOptions(locale, values);
        return (
          <Fragment>
            {isSubmitting && <Loader overlay />}
            <Form className={clsx(className, s.wrapper)}>
              <Field
                label={loc('ser.date')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
                errorMessage={dateErrorMessage}
              >
                <FormDoubleDatePicker
                  className={s.control}
                  nameFrom="dateFrom"
                  nameTo="dateTo"
                  error={!!dateErrorMessage}
                />
              </Field>

              <FormField
                name="borderCrossingType"
                label={loc('ser.borderCrossingType')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormSelect
                  options={borderCrossingTypeOptions}
                  fullWidth={false}
                  className={s.control}
                  // isDisabled
                />
              </FormField>

              <FormField
                name="checkpoint"
                label={loc('common.labels.checkpoint')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormCheckpointSelect
                  fullWidth={false}
                  className={s.control}
                  preProcessOptions={(opts) => [
                    ...checkpointsPrefixOptions,
                    ...opts.filter((op) => ['AUTOMOBILE', 'INTERNATIONAL_AIR'].includes(op.type)),
                  ]}
                />
              </FormField>

              <FormField
                name="ogdCode"
                label={loc('common.labels.ogdCode')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormOGDSelect
                  fullWidth={false}
                  className={s.control}
                  preProcessOptions={(opts) => [...ogdPrefixOptions, ...opts]}
                />
              </FormField>

              <FormField
                name="ogdStatuses"
                label={loc('ser.ogdStatuses')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormMultiselect options={ogdMarkOptions} rows={3} />
              </FormField>

              <FormField
                name="statuses"
                label={loc('ser.statuses')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
                fast={false}
              >
                <FormMultiselect
                  options={sntStatusOptions}
                  rows={4}
                  allOption
                  checkOptionsChange
                  updateValueOnOptionsChange
                />
              </FormField>
              <PageFooter className={s.footer}>
                <Button variant="secondary" className={s.button} onClick={() => setValues(emptyValues)}>
                  Очистить
                </Button>
                <Button
                  variant="secondary"
                  className={s.button}
                  onClick={() => {
                    submitType.current = 'xls';
                    submitForm();
                  }}
                >
                  Скачать XLS
                </Button>
                <Button
                  className={s.button}
                  onClick={() => {
                    submitType.current = 'pdf';
                    submitForm();
                  }}
                >
                  Скачать PDF
                </Button>
              </PageFooter>
            </Form>
          </Fragment>
        );
      }}
    </Formik>
  );
};
