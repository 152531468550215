import { API_URL, AppMode } from 'common/constants';
import { DocumentTargetUrl, DocumentLinkParams } from './types';

/**
 * Формирование прямой ссылки на документ (ЭСФ, СНТ, Сертификат, ФНО ...)
 * Если ссылку сформировать невозможно (данных не хватает) возвращает объект с пустым url
 */
export const getDocumentTargetUrl = ({
  id,
  docType,
  appMode,
  linkType,
  regNumber,
}: DocumentLinkParams): DocumentTargetUrl => {
  const result: DocumentTargetUrl = {
    url: '',
    outsideLink: true,
  };

  /**
   * Обязательно наличие типа документа (ЭСФ, СНТ, Сертификат, ФНО ...) и типа ссылки (PDF, XLSX)
   */
  if (!(docType && linkType)) {
    return result;
  }

  // Ссылку на сертификат формируется по рег.номеру (не нужен id)
  if (docType === 'CERTIFICATE_CT') {
    if (appMode === AppMode.ARM) {
      result.url = `${API_URL}/certificate/admin/print/${linkType}?numbers=${regNumber}`;
    }
  }

  if (!id) {
    return result;
  }

  if (linkType === 'PDF' || linkType === 'XLSX') {
    if (appMode === AppMode.ARM) {
      switch (docType) {
        case 'SNT':
          result.url = `${API_URL}/admin/snt/printReport/${id}/${linkType}`;
          break;
        case 'INVOICE':
          result.url = `${API_URL}/invoice/printReport/admin/${id}/${linkType}`;
          break;
        case 'UFORM':
          result.url = `${API_URL}/admin/uform/printReport/${id}/${linkType}`;
          break;
        case 'DOCUMENT_GTD':
          result.url = `${API_URL}/admin/gtd/printReport/${id}/${linkType}`;
          break;
        case 'DOCUMENT_FNO':
          result.url = `${API_URL}/admin/fno/printReport/${id}/${linkType}`;
          break;
        default:
          break;
      }
    }
  }

  return result;
};
