import { FormLocaleDatePicker, FormOGDSelect } from 'common/components/controls';
import React, { Fragment, useEffect, useMemo } from 'react';
import { DT_FORMAT } from 'services/date-time';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect, FormTextarea } from 'ui/common';
import { FormTextarea2, getUserAccessModuleOptions, getUserAccessTypeOptions, parseIpAdresses } from './helpers';
import s from './UserAccessFilter.module.scss';
import DOMPurify from 'dompurify';
import { FilterContentProps } from 'common/components';

export const UserAccessFilterContent: ({
  classNames: { columnClassName },
  form,
  pageType,
  editedTins,
  incompleteResult,
}: FilterContentProps & {
  pageType: 'external' | 'internal';
  editedTins?: any;
  incompleteResult?: any;
}) => JSX.Element = ({ classNames: { columnClassName }, form, pageType, editedTins, incompleteResult }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, setFieldValue, submitCount } = form;

  const userAccessModuleOptions = useMemo(() => getUserAccessModuleOptions(locale, pageType), [locale, pageType]);

  const userAccessTypeOptions = useMemo(
    () => getUserAccessTypeOptions(locale, values.moduleType, pageType),
    [locale, values.moduleType, pageType]
  );

  useEffect(() => {
    setFieldValue('eventType', '');
  }, [values.moduleType, setFieldValue]);

  useEffect(() => {
    setFieldValue('tin', editedTins);
  }, [editedTins, setFieldValue]);

  useEffect(() => {
    console.log(submitCount);
    setFieldValue('ipAddresses', parseIpAdresses(values.ipAddresses).join('\n'));
  }, [submitCount]); // eslint-disable-line

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="dateFrom" label={loc('common.labels.dateTimeFrom')}>
          <FormLocaleDatePicker
            valueFormat={DT_FORMAT.DEFAULT_DATE_TIME}
            showTimeSelect
            placeholderText={loc('common.labels.from')}
          />
        </FormField>
        <FormField name="dateTo" label={loc('common.labels.dateTimeTo')}>
          <FormLocaleDatePicker
            valueFormat={DT_FORMAT.DEFAULT_DATE_TIME}
            showTimeSelect
            placeholderText={loc('common.labels.to')}
          />
        </FormField>
        <FormField name="login" label={loc('common.labels.login')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="tin" label={loc('common.labels.bin')}>
          <FormTextarea2 rows={5} />
        </FormField>
        <div
          className={s.errorincomplete}
          dangerouslySetInnerHTML={incompleteResult ? { __html: DOMPurify.sanitize(incompleteResult) } : undefined}
        />
        {/* <FormField name="fullName" label={loc('common.labels.fio')}>
          <FormInput />
        </FormField> */}
      </div>
      <div className={columnClassName}>
        <FormField name="ipAddresses" label={loc('common.labels.ipAddress')}>
          <FormTextarea rows={5} maxLength={1600} />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="ogdCode" label={loc('common.labels.ogdCode')}>
          <FormOGDSelect />
        </FormField>
        {/* <FormField name="ogdName" label={loc('common.labels.ogd')}>
          <FormInput />
        </FormField> */}
        {/* <FormField name="eventLevel" label={loc('common.labels.eventLevel')}>
          <FormSelect options={userAccessLevelOptions} />
        </FormField> */}
        <FormField name="moduleType" label={loc('common.labels.eventModule')}>
          <FormSelect options={userAccessModuleOptions} />
        </FormField>
        <FormField name="eventType" label={loc('common.labels.eventType')} fast={false}>
          <FormSelect options={userAccessTypeOptions} isDisabled={!values.moduleType} />
        </FormField>
      </div>
    </Fragment>
  );
};
