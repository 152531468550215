import React, { FC, PropsWithChildren } from 'react';
import s from './ButtonIcon.module.scss';
import clsx from 'clsx';
import { SvgIcon } from 'ui/common/elements/svg-icon/SvgIcon';
import { UITheme } from 'ui/common/types';

export type ButtonIconProps = PropsWithChildren<{
  className?: string;
  iconClassName?: string;
  style?: any;
  disabled?: boolean;
  type?: 'submit' | 'button';
  variant?: 'default' | 'primary' | 'tertiary';
  size?: 'default';
  iconName?: string;
  iconComponent?: FC;
  onClick?: (e: React.SyntheticEvent) => void;
  title?: string;
  theme?: UITheme;
  tabIndex?: number;
}>;

export const ButtonIcon: FC<ButtonIconProps> = ({
  className,
  iconClassName,
  style,
  disabled = false,
  type = 'button',
  variant = 'default',
  size = 'default',
  onClick = () => {},
  iconName,
  iconComponent: IconComponent,
  title,
  theme = 'T1',
  tabIndex,
  children,
}) => {
  return (
    <button
      title={title}
      type={type}
      onClick={onClick}
      className={clsx(s.wrapper, className, s[variant], s[size], s[theme])}
      style={style}
      disabled={disabled}
      tabIndex={disabled ? -1 : tabIndex}
    >
      {iconName || IconComponent ? (
        <SvgIcon name={iconName} className={clsx(s.icon, iconClassName)}>
          {!!IconComponent && <IconComponent />}
        </SvgIcon>
      ) : null}
      {children}
    </button>
  );
};
