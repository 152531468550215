import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './Journal.module.scss';
import { DataQueryFilter, DataQueryProps } from 'rest';
import { Button, Loader, PageTitle, Pagination, Table, TableColumn, UITheme } from 'ui/common';
import { FormikProps, isPromise } from 'formik';
import { useLocale } from 'services/l10n';
import { Modal } from 'services/modal';
import { JournalSettings } from './JournalSettings';
import { FilterPanel, FilterClassNames, FilterContentProps } from '../filter-panel';
import { ToastService } from 'services/toasts/ToastService';
import { solveErrorMessage } from 'common/form/helpers';
import { JournalButton } from './types/JournalButton';
import { SessionStorageService } from 'services/storage/StorageService';

const emptyFilter = {};

type Props = {
  className?: string;
  columns?: Array<TableColumn>;
  getRowId?: (row: any, index: number) => string | number;
  selectable?: boolean;
  queryProps: DataQueryProps;
  getRows?: (data: any | undefined) => Array<any>;
  /**
   * значение фильтра при сбросе кнопкой "Очистить",
   * по умолчанию пустой объект {}
   */
  defaultFilter?: DataQueryFilter;
  pageTitle?: string;
  filterComponent?: FC<FilterContentProps & any>;
  filterComponentProps?: { [key: string]: any };
  filterValidationSchema?: any;
  filterInitialOpened?: boolean;
  filterStorageKey?: string;
  filterExpandable?: boolean;
  filterInitialExpanded?: boolean;
  headerButtons?: Array<JournalButton>;
  actionButtons?: Array<JournalButton>;
  hidden?: boolean;
  oneLine?: boolean;
  theme?: UITheme;
  paginationRowsOptions?: Array<{
    value: number;
    label: string;
  }>;
};

export const Journal: React.FC<Props> = ({
  className,
  columns,
  getRowId,
  selectable,
  queryProps,
  getRows = (data): Array<any> => (Array.isArray(data?.rows) ? data?.rows : undefined),
  defaultFilter = emptyFilter,
  pageTitle,
  filterComponent: FilterComponent,
  filterComponentProps,
  filterValidationSchema,
  filterStorageKey,
  filterExpandable,
  filterInitialExpanded,
  headerButtons = [],
  actionButtons = [],
  hidden,
  filterInitialOpened,
  oneLine,
  theme,
  paginationRowsOptions,
}) => {
  const { loc } = useLocale();
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [showJournalSettings, setShowJournalSettings] = useState(false);
  const [exporting, setExporting] = useState(false);

  const [columnsState, setColumnsState] = useState<Array<TableColumn>>((columns || []).filter((col) => !col?.exclude));

  const { data, filter, setFilter, pagination, setPagination, loading, error } = queryProps;

  const initialFilterValues = useMemo(() => filter, [filter]);

  const onFilterSubmit = async (values: { [key: string]: string }) => {
    setFilter(values);
  };

  const rows = getRows(data);

  const onChangeSelected = useCallback((rows) => setSelectedRows(rows), []);

  const renderFilterContent = useCallback(
    (props: { form: FormikProps<any>; classNames: FilterClassNames }) =>
      !!FilterComponent && <FilterComponent {...props} {...filterComponentProps} />,
    [FilterComponent, filterComponentProps]
  );

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (filterStorageKey) {
      SessionStorageService.set(filterStorageKey, filter);
    }
  }, [filter, filterStorageKey]);

  return (
    <div className={clsx(className, s.wrapper, 'container', { hidden })}>
      {exporting && <Loader overlay />}
      <PageTitle theme={theme}>{pageTitle}</PageTitle>
      <FilterPanel
        className={s.filters}
        initialValues={initialFilterValues}
        validationSchema={filterValidationSchema}
        initialOpened={filterInitialOpened}
        expandable={filterExpandable}
        initialExpanded={filterInitialExpanded}
        onSubmit={onFilterSubmit}
        buttonLabel={loc('common.button.filters')}
        theme={theme}
        loading={loading}
        onClear={(form) => {
          form.resetForm();
          form.setValues(defaultFilter);
        }}
        renderContent={FilterComponent ? renderFilterContent : undefined}
        renderHeader={() => (
          <Fragment>
            {headerButtons.map((btn) => (
              <Button
                key={btn.id}
                className={s.headerButton}
                onClick={() => {
                  if (typeof btn.onClick === 'function') {
                    const result = btn.onClick(selectedRows, columnsState);
                    if (isPromise(result)) {
                      setExporting(true);
                      result.finally(() => setExporting(false));
                    }
                    return result;
                  }
                }}
                disabled={typeof btn.disabled === 'function' && btn.disabled(selectedRows)}
              >
                {btn.label}
              </Button>
            ))}
            <Button className={s.headerButton} onClick={() => setShowJournalSettings(true)} variant="secondary">
              {loc('common.title.journalSettings')}
            </Button>
          </Fragment>
        )}
      />

      {!data && loading ? (
        <Loader className={s.loader} />
      ) : !!data && (Array.isArray(rows) || rows === undefined) ? (
        <Fragment>
          {!!actionButtons.length && (
            <div className={s.actionButtons}>
              {actionButtons.map((btn) => (
                <Button
                  key={btn.id}
                  className={s.actionButton}
                  size="small"
                  onClick={() => {
                    if (typeof btn.onClick === 'function') {
                      const result = btn.onClick(selectedRows, columnsState);
                      if (isPromise(result)) {
                        setExporting(true);
                        result.finally(() => setExporting(false));
                      }
                      return result;
                    }
                  }}
                  disabled={
                    !selectedRows.length ||
                    (!btn.multiselect && selectedRows.length > 1) ||
                    (typeof btn.disabled === 'function' && btn.disabled(selectedRows))
                  }
                >
                  {btn.label}
                </Button>
              ))}
            </div>
          )}
          <Table
            columns={columnsState}
            rows={rows}
            selectable={selectable}
            getRowId={getRowId}
            onChangeSelected={onChangeSelected}
            scrollable
            loading={loading}
            loaderRender={() => <Loader />}
            oneLine={oneLine}
          />
          <Pagination
            pageSize={pagination.rows || 10}
            currentPage={pagination.pageNum}
            onPageChange={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
            onPageSizeChange={(rows) => setPagination((state) => ({ pageNum: 1, rows }))}
            className={s.pagination}
            lastPage={data.lastBlock}
            rowsOptions={paginationRowsOptions}
          />
        </Fragment>
      ) : null}

      <Modal isOpen={showJournalSettings} onClose={() => setShowJournalSettings(false)} scrollLock={false}>
        <JournalSettings columns={columnsState} setColumns={setColumnsState} />
      </Modal>
    </div>
  );
};
