import { CodeValueOgd } from 'common/components';
import { capitalizeFirstLetter } from 'common/utils';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { RowMark, TableColumn } from 'ui/common';

export const getPassportsJournalColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'assuranceCorridor',
    label: loc('assurance.assuranceCorridor'),
    getContent: (row) => <RowMark color={row.assuranceCorridor} />,
    getTitle: (row) => loc(`assurance.${row.assuranceCorridor}`),
    style: {
      width: '100px',
      textAlign: 'center',
      paddingLeft: '0',
    },
  },
  {
    name: 'passportRegistrationNumber',
    label: loc('assurance.passportRegistrationNumber'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'passportDate',
    label: loc('assurance.passportDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'passportId',
    label: loc('assurance.passportId'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'assuranceStatus',
    label: loc('assurance.assuranceStatus'),
    getContent: (row) => loc(`assurance.${row.assuranceStatus}`),
    style: {
      width: '200px',
    },
  },
  {
    name: 'statusUpdateDate',
    label: loc('assurance.statusUpdateDate'),
    style: {
      width: '150px',
    },
  },
  {
    name: 'invoiceRegistrationNumber',
    label: loc('assurance.invoiceRegistrationNumber'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'sntRegistrationNumber',
    label: loc('assurance.sntRegistrationNumber'),
    style: {
      width: '350px',
    },
  },
  {
    name: 'exporterTin',
    label: loc('assurance.exporterTin'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'exporterName',
    label: loc('assurance.exporterName'),
    style: {
      width: '300px',
    },
  },
  {
    name: 'exportCountry',
    label: loc('assurance.exportCountry'),
    getContent: (row) => capitalizeFirstLetter(row.exportCountryName, true),
    style: {
      width: '200px',
    },
  },
  {
    name: 'kogd',
    label: loc('common.labels.ogdCode'),
    getContent: (row) => <CodeValueOgd code={row.kogd} />,
    style: {
      width: '350px',
    },
  },
  {
    name: 'ogdEmployeeIin',
    label: loc('assurance.ogdEmployeeIin'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'ogdEmployeeFullName',
    label: loc('assurance.ogdEmployeeFullName'),
    style: {
      width: '200px',
    },
  },
  {
    name: 'rejectionReason',
    label: loc('assurance.rejectionReason'),
    style: {
      width: '250px',
    },
  },
  {
    name: 'cancelReason',
    label: loc('assurance.cancelReason'),
    getContent: (row) => (row.cancelReason ? loc(`assurance.reason.${row.cancelReason}`) : ''),
    style: {
      width: '250px',
    },
  },
  {
    name: 'borderCrossingDate',
    label: loc('assurance.boardingDate'),
    style: {
      width: '200px',
    },
  },
];
