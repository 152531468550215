import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';
import { clearEmptyFields } from 'common/utils';

export const mutationSendInvitation: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const { file, permissions, ...rest } = body;

  const formData = convertToFormData(clearEmptyFields(rest));

  if (permissions) {
    formData.append('permissions', JSON.stringify(permissions));
  }
  if (file) {
    formData.append('file', file);
  }

  const res = await httpClient.post('/arm/sendInvitation', formData);
  if (res.data.success) {
    return res;
  } else {
    console.log('here');
    throw res.data;
  }
};
