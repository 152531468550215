import React from 'react';
import { Tag, TagProps } from 'ui/common/elements/tag';

export const TagT2: React.FC<TagProps> = ({ children, ...rest }) => {
  return (
    <Tag theme="T2" {...rest}>
      {children}
    </Tag>
  );
};
