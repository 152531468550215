import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import ss from '../InsidePage.module.scss';
import s from './InsideNewsPage.module.scss';
import { useParams } from 'react-router-dom';
import { queryGetPublishedNews, queryNews, useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { InsideBreadcrumbs } from '../InsideBreadcrumbs';
import { DT_FORMAT, formatDate } from 'services/date-time';
import { InsideNewsCard } from './InsideNewsCard';
import DOMPurify from 'dompurify';

type Props = {
  className?: string;
};

export const InsideNewsPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { choose } = locale;
  const { id } = useParams<any>();

  const { data } = useDataQuery(queryNews, { id });
  const { data: allData } = useDataQuery(queryGetPublishedNews);

  const newsData = data?.data;

  const moreNews = useMemo(() => {
    if (id && Array.isArray(allData)) {
      const newsIndex = allData.findIndex((news) => String(news.id) === String(id));
      return allData.slice(newsIndex + 1, newsIndex + 3);
    }
  }, [allData, id]);

  return (
    <div className={clsx(className, ss.wrapper, s.wrapper)}>
      {newsData && (
        <InsideBreadcrumbs
          key="data"
          section="news"
          pageCrumbs={[{ label: formatDate(newsData.publishedDate, DT_FORMAT.HUMAN_DATE_TIME, DT_FORMAT.ISO) }]}
        />
      )}
      {newsData && (
        <Fragment>
          <p className={s.date}>{formatDate(newsData.publishedDate, DT_FORMAT.HUMAN_DATE_TIME, DT_FORMAT.ISO)}</p>
          <h1 className={s.title}>{choose([newsData.titleRu, newsData.titleKz])}</h1>
          <div className={ss.content}>
            <article
              className={clsx(ss.article, s.newsBody)}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choose([newsData.txtRu, newsData.txtKz])) }}
            ></article>
            {!!moreNews?.length && (
              <div className={s.more}>
                {moreNews.slice(0, 2).map((news) => (
                  <div key={news.id} className={s.column}>
                    <InsideNewsCard variant="third" data={news} className={s.item} />
                  </div>
                ))}
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};
