import { getMonth, getYear, Locale } from 'date-fns';
import React from 'react';
import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import { ReactComponent as DoubleLeft } from 'assets/icons/chevron-double-left.svg';
import { ReactComponent as DoubleRight } from 'assets/icons/chevron-double-right.svg';
import { ButtonIcon } from '../../button-icon';

type Props = ReactDatePickerCustomHeaderProps & {
  locale?: Locale;
};

export const DatePickerHeader: React.FC<Props> = (props) => {
  const {
    date,
    increaseMonth,
    increaseYear,
    decreaseMonth,
    decreaseYear,
    nextYearButtonDisabled,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    prevYearButtonDisabled,
    locale,
  } = props;
  return (
    <div className="date-picker-header">
      {locale ? locale.localize?.month(getMonth(date)) : getMonth(date)} {getYear(date)}
      {!prevMonthButtonDisabled && (
        <ButtonIcon
          className="date-picker-header__button date-picker-header__monthLeft"
          iconClassName="date-picker-header__icon"
          iconName="chevron-left"
          onClick={() => decreaseMonth()}
          tabIndex={1}
        />
      )}
      {!prevYearButtonDisabled && (
        <ButtonIcon
          className="date-picker-header__button date-picker-header__yearLeft"
          iconClassName="date-picker-header__icon"
          iconComponent={DoubleLeft}
          onClick={() => decreaseYear()}
          tabIndex={2}
        />
      )}
      {!nextYearButtonDisabled && (
        <ButtonIcon
          className="date-picker-header__button date-picker-header__yearRight"
          iconClassName="date-picker-header__icon"
          iconComponent={DoubleRight}
          onClick={() => increaseYear()}
          tabIndex={3}
        />
      )}
      {!nextMonthButtonDisabled && (
        <ButtonIcon
          className="date-picker-header__button date-picker-header__monthRight"
          iconClassName="date-picker-header__icon"
          iconName="chevron-right"
          onClick={() => increaseMonth()}
          tabIndex={4}
        />
      )}
    </div>
  );
};
