import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryInvoiceGetPaperDays: DataQueryFunction = async (variables: any) => {
  const { num, date } = variables || {};

  const res = await httpClient.get(`/invoice/getPaperDays`, {
    params: { num, date },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
