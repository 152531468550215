import {
  FilterT2ContentProps,
  FormDoubleDatePickerT2,
} from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FieldT2, FormFieldT2, FormInputT2 } from 'ui';

type Props = FilterT2ContentProps;

export const ZvtJournalTxpFiltersMain: React.FC<Props> = ({
  form,
  classNames,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const { mainWrapper } = classNames || {};

  return (
    <div className={mainWrapper}>
      <FieldT2 label={loc('zvt.initiated')} style={{ width: '360px' }}>
        <FormDoubleDatePickerT2 nameFrom="dateFrom" nameTo="dateTo" />
      </FieldT2>
      <FormFieldT2
        style={{ marginLeft: 'auto', width: '360px' }}
        name="registrationNumber"
        noMargin
      >
        <FormInputT2
          placeholder={loc('zvt.regNumberPlaceholder')}
          icon="search"
        />
      </FormFieldT2>
    </div>
  );
};
