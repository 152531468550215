import { CodeValueCountry, CodeValueOgd } from 'common/components';
import React from 'react';
import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getEaecOperationsInputColumns = ({ locale: { loc } }: { locale: LocaleStore }): Array<TableColumn> => [
  {
    name: 'requestDocumentId',
    label: loc('eaecActions.requestDocumentId'),
    getContent: (row) => row.messageId,
    style: {
      width: '300px',
    },
  },
  {
    name: 'createDate',
    label: loc('eaecActions.requestDateTime'),
  },
  {
    name: 'requester',
    label: loc('eaecActions.requester'),
    getContent: (row) => [row.ogdIin, row.ogdUserName].filter(Boolean).join(' '),
  },
  {
    name: 'ogdCode',
    label: loc('common.labels.ogdCode'),
    getContent: (row) => <CodeValueOgd code={row.ogdCode} />,
    style: {
      width: '350px',
    },
  },
  {
    name: 'countryCode',
    label: loc('eaecActions.eaecCountry'),
    getContent: (row) => <CodeValueCountry code={row.countryCode} withFlag />,
    style: {
      width: '150px',
    },
  },
  {
    name: 'resultMessageId',
    label: loc('eaecActions.resultDocumentId'),
  },
  {
    name: 'receiveDate',
    label: loc('eaecActions.responseDateTime'),
  },
  {
    name: 'documentId',
    label: loc('eaecActions.documentRegistrationNumber'),
    style: {
      width: '300px',
    },
  },
  {
    name: 'documentDate',
    label: loc('eaecActions.writeOutDate'),
  },
  {
    name: 'objectOrdinal',
    label: loc('eaecActions.p.productPosition'),
  },
  {
    name: 'operationsCount',
    label: loc('eaecActions.operationsCount'),
  },
  {
    name: 'status',
    label: loc('eaecActions.requestStatus'),
    getContent: (row) => (row.status ? loc(`eaecActions.exStatus.${row.status}`) : ''),
  },
];
