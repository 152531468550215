import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryVirtualLabelChainTreePDF: DataQueryFunction = (variables: any) => {
  const { filter = {} } = variables || {};

  const { id } = filter || {};

  return downloadFile({
    url: `${API_URL}/admin/virtualLabelChain/pdfTree/${id}`,
    method: 'GET',
  });
};
