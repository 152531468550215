import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryEaecDownloadChainZip: DataQueryFunction = (variables: any) => {
  const { docId } = variables || {};

  return downloadFile({
    url: `${API_URL}/admin/eec/downloadChainZip`,
    method: 'GET',
    params: {
      docId,
    },
  });
};
