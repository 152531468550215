import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './PassportPage.module.scss';
import { Button, Loader, PageTitle } from 'ui/common';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { mutationAdminAssuranceTakeInWork, queryAdminAssuranceId, queryAdminAssuranceReport } from '../api';
import { PassportDocument } from '../passport/PassportDocument';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Modal } from 'services/modal';
import { ActionReasonModal } from '../components';
import { allowAssurance, allowTakeToWork, changeStatusWithSign } from '../helpers';
import { AppMode } from 'common/constants';
import { PassportHistoryModal } from '../passport-history/PassportHistoryModal';
import { useStore } from 'common/hooks';
import { PassportComments } from '../passport-comments/PassportComments';
import { PassportActionLogModal } from '../passport-action-log/PassportActionLogModal';

type Props = {
  className?: string;
  onClose?: () => void;
  passportId: string;
};

export const PassportArmPage: React.FC<Props> = ({ className, onClose, passportId }) => {
  const { auth, locale } = useStore();
  const { loc } = locale;

  const [reasonModal, setReasonModal] = useState<boolean>(false);
  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const [actionLogModal, setActionLogModal] = useState<boolean>(false);

  const closeReasonModal = () => setReasonModal(false);

  const { data, refetch, error } = useDataQuery(queryAdminAssuranceId, {
    id: passportId,
  });

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  const takeInWork = async () => {
    try {
      await mutationAdminAssuranceTakeInWork({
        id: data.stringPassportId,
      });
      typeof onClose === 'function' && onClose();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const changePasportStatus = async (actionType: string, cause?: string) => {
    try {
      await changeStatusWithSign({
        passportId: data.stringPassportId,
        actionType,
        cause,
        auth,
      });
      typeof onClose === 'function' && onClose();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const downloadPDF = async () => {
    try {
      await queryAdminAssuranceReport({
        ids: [passportId],
        format: 'PDF',
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('assurance.pasport')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <div className={s.inner}>
          <PassportDocument data={data} reloadData={refetch} appMode={AppMode.ARM} />
          <PassportComments passportId={passportId} />
          <div className={s.actionButtons}>
            <Button size="small" onClick={takeInWork} disabled={!allowTakeToWork(data)}>
              {loc('assurance.takeToWork')}
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={() => changePasportStatus('ASSURE_BY_OGD')}
              disabled={!allowAssurance(data)}
            >
              {loc('assurance.assure')}
            </Button>
            <Button
              variant="secondary"
              size="small"
              onClick={() => setReasonModal(true)}
              disabled={!allowAssurance(data)}
            >
              {loc('assurance.denied')}
            </Button>
            <Button className={s.buttonGap} variant="secondary" size="small" onClick={() => setHistoryModal(true)}>
              {loc('assurance.viewHistory')}
            </Button>
            <Button variant="secondary" size="small" onClick={() => setActionLogModal(true)}>
              {loc('assurance.actionLog')}
            </Button>
            <Button variant="secondary" size="small" onClick={() => downloadPDF()}>
              {loc('common.button.pdf')}
            </Button>
            <Button variant="secondary" size="small" onClick={() => typeof onClose === 'function' && onClose()}>
              {loc('common.button.close')}
            </Button>
          </div>
          <Modal isOpen={reasonModal} onClose={closeReasonModal}>
            <ActionReasonModal onSubmit={(cause) => changePasportStatus('DENY_ASSURANCE', cause)} />
          </Modal>
          <Modal isOpen={historyModal} onClose={() => setHistoryModal(false)} scrollLock={false}>
            <PassportHistoryModal
              passportId={data.passportId}
              regNumber={data.registrationNumber}
              regDate={data.inputDate}
            />
          </Modal>

          <Modal isOpen={actionLogModal} onClose={() => setActionLogModal(false)} scrollLock={false}>
            <PassportActionLogModal passportId={data.passportId} />
          </Modal>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
