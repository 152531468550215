import React from 'react';
import './Select.scss';
import clsx from 'clsx';
import { Autocomplete, AutocompleteProps } from './Autocomplete';

export const AutocompleteMUI: React.FC<AutocompleteProps> = ({
  className,
  label,
  // getOptionValue = defaultgetOptionValue,
  ...rest
}) => {
  return (
    <div className={clsx(className, 'select-mui-container')}>
      <Autocomplete {...rest} className={'select-mui'} theme="T2" />
      <label className="select-mui-label">{label}</label>
    </div>
  );
};
