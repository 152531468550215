import React, { Fragment, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import ss from '../../Registration.module.scss';
import s from './RegContract.module.scss';
import {
  AutocompleteMUI,
  ButtonIcon,
  ButtonT2,
  FormFieldT2,
  FormInputMUIT2,
  FormRadioT2,
  FormSelectMUIT2,
  errorToString,
} from 'ui';
import { useLocale } from 'services/l10n';
import { FieldArray, getIn, useFormikContext } from 'formik';
import { FormAutocompleteIIN, FormLocaleDatePickerMUIT2 } from 'common/components';
import { getRegAgreementTypeOptions, getRegContractTypeOptions } from '../../helpers';

type Props = {
  className?: string;
};

export const RegContractOperator: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  const { values, setFieldValue, errors } = useFormikContext<any>();

  const contractTypeOptions = useMemo(() => getRegContractTypeOptions({ locale }), [locale]);
  const agreementTypeOptions = useMemo(() => getRegAgreementTypeOptions({ locale }), [locale]);

  useEffect(() => {
    let shareSum = 0;
    if (Array.isArray(values?.operator?.contractors)) {
      shareSum = values?.operator?.contractors.reduce((acc: number, cont: any) => {
        acc += parseFloat(cont.share);
        return acc;
      }, 0);
      if (shareSum && getIn(values, 'operator._shareSum') !== shareSum) {
        setFieldValue('operator._shareSum', shareSum);
      }
    }
  }, [values, setFieldValue]);

  return (
    <Fragment>
      <div className={clsx(ss.field, ss.doubleField)}>
        <FormFieldT2 className={ss.colField} name="operator.startDate">
          <FormLocaleDatePickerMUIT2 label={loc('reg.startDate')} />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="operator.endDate">
          <FormLocaleDatePickerMUIT2 label={loc('reg.endDate')} />
        </FormFieldT2>
      </div>
      <div className={s.fieldsList}>
        <h4 className={s.fieldsTitle}>{loc('reg.contractors')}</h4>
        {typeof getIn(errors, 'operator.contractors') === 'string' && (
          <span className={s.errorMessage}>{errorToString(getIn(errors, 'operator.contractors'))}</span>
        )}
        <FieldArray name="operator.contractors">
          {({ name, push, remove }) => {
            const array = getIn(values, name);
            return (
              <Fragment>
                {Array.isArray(array) &&
                  array.map((row, index) => (
                    <div key={index} className={clsx(s.fieldsRow, s.contractor)}>
                      <span className={s.rowNumber}>
                        {index + 1}
                        {')'}
                      </span>
                      <FormFieldT2 className={s.rowField} name={`${name}[${index}].iin`}>
                        {/* <FormInputMUIT2 label={loc('common.labels.iin')} /> */}
                        <FormAutocompleteIIN
                          component={AutocompleteMUI}
                          label={loc('common.labels.iin')}
                          registration
                          getOptionLabel={(opt) => `${opt.tin} ${chooseByKey(opt, 'name')}`}
                          onValueChange={({ value }) => {
                            setFieldValue(`${name}[${index}].iin`, value?.tin);
                            setFieldValue(`${name}[${index}].name`, chooseByKey(value, 'name'));
                          }}
                        />
                      </FormFieldT2>
                      <FormFieldT2 className={s.rowField} name={`${name}[${index}].name`}>
                        <FormInputMUIT2 label={loc('common.labels.name')} readOnly />
                      </FormFieldT2>
                      <FormFieldT2 className={s.rowField} name={`${name}[${index}].share`}>
                        <FormInputMUIT2 label={loc('reg.share')} />
                      </FormFieldT2>
                      <ButtonIcon
                        className={s.delBtn}
                        iconName="close"
                        iconClassName={s.delIcon}
                        onClick={() => remove(index)}
                      />
                    </div>
                  ))}
                <div className={clsx(s.buttonsRow)}>
                  <ButtonT2 className={s.fieldsListBtn} variant="outlined" onClick={() => push({})}>
                    {loc('reg.addContractor')}
                  </ButtonT2>
                  {typeof getIn(errors, 'operator._shareSum') === 'string' && (
                    <span className={s.errorMessage}>{errorToString(getIn(errors, 'operator._shareSum'))}</span>
                  )}
                </div>
              </Fragment>
            );
          }}
        </FieldArray>
      </div>

      <div className={s.fieldsList}>
        <h4 className={s.fieldsTitle}>{loc('reg.documents')}</h4>
        {typeof getIn(errors, 'operator.documents') === 'string' && (
          <span className={s.errorMessage}>{errorToString(getIn(errors, 'operator.documents'))}</span>
        )}
        <FieldArray name="operator.documents">
          {({ name, push, remove }) => {
            const array = getIn(values, name);
            return (
              <Fragment>
                {Array.isArray(array) &&
                  array.map((row, index) => (
                    <Fragment key={index}>
                      <div className={clsx(s.fieldsRow, s.document)}>
                        <span className={s.rowNumber}>
                          {index + 1}
                          {')'}
                        </span>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].agreementType`}>
                          <FormSelectMUIT2
                            size="large"
                            label={loc('reg.agreementType')}
                            options={agreementTypeOptions}
                          />
                        </FormFieldT2>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].contractType`}>
                          <FormSelectMUIT2 size="large" label={loc('reg.contractType')} options={contractTypeOptions} />
                        </FormFieldT2>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].num`}>
                          <FormInputMUIT2 label={loc('reg.docNumber')} />
                        </FormFieldT2>
                        <FormFieldT2 className={s.rowField} name={`${name}[${index}].startDate`}>
                          <FormLocaleDatePickerMUIT2 label={loc('common.labels.date')} />
                        </FormFieldT2>
                        <ButtonIcon
                          className={s.delBtn}
                          iconName="close"
                          iconClassName={s.delIcon}
                          onClick={() => remove(index)}
                        />
                      </div>
                      <div className={s.showInEsf}>
                        <FormFieldT2 name="mainDocumentIndex" label={loc('reg.showInEsf')} direction="line-reverse">
                          <FormRadioT2 radioValue={index} />
                        </FormFieldT2>
                      </div>
                    </Fragment>
                  ))}
                <ButtonT2 className={s.fieldsListBtn} variant="outlined" onClick={() => push({})}>
                  {loc('reg.addDoc')}
                </ButtonT2>
              </Fragment>
            );
          }}
        </FieldArray>
      </div>
    </Fragment>
  );
};
