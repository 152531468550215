import { LANG_COOKIE_NAME } from 'common/constants';
import Cookies from 'js-cookie';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { queryVariables } from 'rest/api/variables';

type Props = {
  className?: string;
};

export const Globals: React.FC<Props> = ({ className, children }) => {
  const [loaded, setLoaded] = useState(false);

  const setGlobals = useCallback((variables: any) => {
    let globals = variables;
    if (!globals) {
      process.env.NODE_ENV !== 'production' ? (globals = {}) : console.error('Error loading Globals variables');
    }
    if (globals) {
      globals.permissionsByRole = normalizePermissionsByRole(globals.permissionsByRole);
      globals.serviceRolesHierarchy = normalizeServiceRolesHierarchy(globals.serviceRolesHierarchy);
      Object.defineProperty(window, 'Globals', {
        value: Object.freeze(globals),
        configurable: false,
        writable: process.env.NODE_ENV === 'production' ? false : true,
      });
      setLoaded(true);
    }
  }, []);

  useEffect(() => {
    queryVariables({ language: Cookies.get(LANG_COOKIE_NAME) })
      .then((res) => {
        // console.log(res);
        setGlobals(res?.data);
      })
      .catch(() => setGlobals(null));
  }, [setGlobals]);
  return <Fragment>{loaded ? children : null}</Fragment>;
};

const normalizePermissionsByRole = (permissions: any) => {
  for (let perm in permissions) {
    if (permissions.hasOwnProperty(perm)) {
      if (Array.isArray(permissions[perm])) {
        permissions[perm] = Object.fromEntries(permissions[perm].map((role: any) => [role, true]));
      }
    }
  }
  return permissions;
};

const normalizeServiceRolesHierarchy = (roles: any) => {
  for (let role in roles) {
    if (roles.hasOwnProperty(role)) {
      if (Array.isArray(roles[role])) {
        roles[role] = Object.fromEntries(roles[role].map((r: any) => [r, true]));
      }
    }
  }
  return roles;
};
