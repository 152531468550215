import { API_URL } from 'common/constants';
import { DataMutationFunction, convertToFormData, downloadFile } from 'rest';

export const mutationAdminEaecUserGenerate: DataMutationFunction = async (variables): Promise<any> => {
  const { body = {} } = variables || {};

  const formData = convertToFormData(body);

  return downloadFile({
    url: `${API_URL}/admin/eaec/user/generateUsers`,
    method: 'POST',
    body: formData,
  });
};
