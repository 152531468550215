import React, { useCallback } from 'react';
import { queryDictSubsidyListActive } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps, UITheme } from 'ui/common';

async function getList(): Promise<any[]> {
  const res = await queryDictSubsidyListActive();
  if (Array.isArray(res.data)) {
    return res.data;
  }
  return [];
}

export const getSubsidyOptionValue = (data: any) => {
  return data?.value ?? data?.id;
};

export const getSubsidyOptionLabel = (data: any, locale: LocaleStore) => {
  const { chooseByKey } = locale;
  return data?.label ?? chooseByKey(data, 'name');
};

type Props = FormSelectProps & {
  theme?: UITheme;
};

export const FormSubsidiySelect: React.FC<Props> = ({ theme, ...rest }) => {
  const locale = useLocale();
  const { loc } = locale;

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  const fetchOptions = useCallback(async () => {
    const list = await getList();
    return [
      {
        value: '',
        label: loc('common.option.notSelected'),
      },
      ...list,
    ];
  }, [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={fetchOptions}
      isSearchable={true}
      getOptionLabel={(opt) => getSubsidyOptionLabel(opt, locale)}
      getOptionValue={getSubsidyOptionValue}
      noOptionsMessage={noOptionsMessage}
      theme={theme}
    />
  );
};
