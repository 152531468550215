import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types';

export const queryBiometricsDocumentRequired: DataQueryFunction = async ({ state }) => {
  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1000);
  //   return {
  //     data: {
  //       required: true,
  //       date: '30.11.2023',
  //     },
  //   } as AxiosResponse;
  // }

  const res = await httpClient.get(`/biometrics/document/required`);
  if (res.data) {
    return res;
  } else {
    throw res;
  }
};
