import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './MessagesPage.module.scss';
import { AsideFilter, FieldT2, FormFieldT2, FormInputT2, Loader, PageTitleT2, Tabs } from 'ui';
import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import { MessageModal, MessageRow } from './components';
import { Formik, FormikHelpers } from 'formik';
import { FormDoubleDatePickerT2 } from 'common/components';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { SessionStorageService } from 'services/storage';
import { Modal } from 'services/modal';

const filterStorageKey = 'messagesFilter';

type Props = {
  className?: string;
};

/**
 * Страница сообщений
 * /messages
 */
export const MessagesPage: React.FC<Props> = observer(({ className }) => {
  const { locale, messages } = useStore();
  const { loc } = locale;

  const [messageModal, setMessageModal] = useState<any>(undefined);

  const tabs = [
    {
      name: 'incoming',
      label: loc('c.messages.incoming'),
      width: 200,
    },
    {
      name: 'outcoming',
      label: loc('c.messages.outcoming'),
      width: 200,
    },
    {
      name: 'filtered',
      label: loc('common.button.filters'),
      width: 200,
    },
  ] as const;

  const [activeTab, setActiveTab] = useState<typeof tabs[number]>(tabs[0]);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  const messagesList =
    activeTab.name === 'incoming'
      ? messages.incomingMessages
      : activeTab.name === 'outcoming'
      ? messages.outcomingMessages
      : messages.filteredMessages;

  useEffect(() => {
    messages.fetchIncomingMessages();
    messages.fetchOutcomingMessages();
  }, [messages]);

  const onSubmit = async (values: any, { setSubmitting }: FormikHelpers<any>) => {
    console.log(values);
    try {
      await messages.fetchFilteredMessages(values);
      SessionStorageService.set(filterStorageKey, values);
      setShowFilters(false);
      const filteredTab = tabs.find((tab) => tab.name === 'filtered');
      if (filteredTab) {
        setActiveTab(filteredTab);
      }
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      setSubmitting(false);
    }
  };

  const onTabsChange = (tab: typeof tabs[number]) => {
    if (tab.name === 'filtered') {
      setShowFilters(true);
    } else {
      setActiveTab(tab);
      if (tab.name === 'incoming') {
        messages.fetchIncomingMessages();
      } else {
        messages.fetchOutcomingMessages();
      }
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitleT2>{loc('c.messages.title')}</PageTitleT2>
      <div className={s.card}>
        <div className={s.header}>
          <Tabs
            className={s.tabs}
            tabClassName={s.tab}
            activeTabName={activeTab.name}
            tabs={tabs}
            onTabsChange={onTabsChange}
          />
        </div>

        <div className={s.messagesList}>
          {Array.isArray(messagesList) && messagesList.length ? (
            messagesList.map((msg) => <MessageRow key={msg.id} message={msg} onClick={() => setMessageModal(msg)} />)
          ) : (
            <div className={s.emptyList}>
              {messagesList === null ? (
                <Loader overlay />
              ) : (
                <span className={s.noMessages}>{loc('c.messages.noMessages')}</span>
              )}
            </div>
          )}
        </div>
      </div>

      {showFilters && (
        <Formik
          initialValues={SessionStorageService.get(filterStorageKey) || {}}
          onSubmit={onSubmit}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ isSubmitting }) => {
            return (
              <AsideFilter
                onClose={() => setShowFilters(false)}
                title={loc('common.button.filters')}
                searchText={loc('common.button.search')}
                clearText={loc('common.button.clear')}
                loading={isSubmitting}
              >
                <FormFieldT2 name="num" label={loc('c.messages.num')}>
                  <FormInputT2 />
                </FormFieldT2>
                <FieldT2 label={loc('c.messages.date')}>
                  <FormDoubleDatePickerT2 nameFrom="dateFrom" nameTo="dateTo" placeholderText="" />
                </FieldT2>
                <FormFieldT2 name="senderTin" label={loc('c.messages.senderTin')}>
                  <FormInputT2 />
                </FormFieldT2>
                <FormFieldT2 name="recipientTin" label={loc('c.messages.recipientTin')}>
                  <FormInputT2 />
                </FormFieldT2>
                <FormFieldT2 name="operatorLogin" label={loc('c.messages.operatorLogin')}>
                  <FormInputT2 />
                </FormFieldT2>
                <FormFieldT2 name="operatorFullname" label={loc('c.messages.operatorFullname')}>
                  <FormInputT2 />
                </FormFieldT2>
              </AsideFilter>
            );
          }}
        </Formik>
      )}

      <Modal isOpen={messageModal} onClose={() => setMessageModal(undefined)} marginTop={150}>
        <MessageModal message={messageModal} />
      </Modal>
    </div>
  );
});
