import React from 'react';
import { useLocale } from 'services/l10n';
import { DatePickerProps, DoubleDateWrapper, FormValueProps } from 'ui/common';
import { Field as FormikField, FieldProps } from 'formik';
import { FormLocaleDatePicker } from './FormLocaleDatePicker';

type Props = Omit<DatePickerProps, 'onChange' | 'onBlur' | 'value'> & {
  nameFrom: string;
  nameTo: string;
  onValueChange?: (props: FormValueProps) => void;
};

/**
 * Двойной датапикер (дата с/по) с подключением к форме и локализацией.
 * В форме можно использовать с элементом Field (дополнительно подключать к форме, через FormField не нужно).
 * Использует общие пропсы для каждого пикера, если будет нужно, можно разделить пропсы.
 */
export const FormDoubleDatePicker: React.FC<Props> = ({
  className,
  nameFrom,
  nameTo,
  error,
  onValueChange,
  ...rest
}) => {
  const { loc } = useLocale();
  return (
    <DoubleDateWrapper
      className={className}
      error={error}
      renderLeft={(leftClassName) => (
        <FormikField name={nameFrom}>
          {(fieldProps: FieldProps) => (
            <FormLocaleDatePicker
              {...fieldProps}
              className={leftClassName}
              placeholderText={loc('common.labels.from')}
              onValueChange={onValueChange}
              {...rest}
            />
          )}
        </FormikField>
      )}
      renderRight={(rightClassName) => (
        <FormikField name={nameTo}>
          {(fieldProps: FieldProps) => (
            <FormLocaleDatePicker
              {...fieldProps}
              className={rightClassName}
              placeholderText={loc('common.labels.to')}
              onValueChange={onValueChange}
              {...rest}
            />
          )}
        </FormikField>
      )}
    />
  );
};
