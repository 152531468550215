import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './IsgoDeclineReasonModal.module.scss';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { FormField } from 'ui/common';
import { useLocale } from 'services/l10n';
import { ButtonT2, FormTextareaT2 } from 'ui';

const initialValues = {};

const schema = yup.object({});

type Props = ModalChildrenProps & {
  className?: string;
  rows: any[];
  onSubmit(reason: string, rows: any[]): Promise<any>;
};

export const IsgoDeclineReasonModal: React.FC<Props> = ({ className, onSubmit, onClose, rows }) => {
  const locale = useLocale();
  const { loc } = locale;
  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      onSubmit(values.reason, rows);
    } catch (error) {
      console.error('form error', error);
      actions.setSubmitting(false);
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
        {({ isValid, dirty }) => {
          return (
            <Fragment>
              <Form>
                <ModalT2Header>{loc(`common.cancelReason`)}</ModalT2Header>
                <ModalT2Content className={s.content}>
                  <FormField name="reason" label="">
                    <FormTextareaT2 />
                  </FormField>
                </ModalT2Content>
                <ModalT2Footer>
                  <ButtonT2 variant="transparent" size="small" onClick={onClose}>
                    {loc('common.button.cancel')}
                  </ButtonT2>
                  <ButtonT2 type="submit" className={s.button} size="small" disabled={!isValid || !dirty}>
                    {loc('common.button.signAndSend')}
                  </ButtonT2>
                </ModalT2Footer>
              </Form>
            </Fragment>
          );
        }}
      </Formik>
    </div>
  );
};
