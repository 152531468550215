import React from 'react';
import { svgIconMap } from './svgIconMap';

type Props = {
  name?: string;
  className?: string;
  style?: { [key: string]: string };
  component?: React.FC;
  children?: React.ReactNode;
  title?: string;
};

export const SvgIcon: React.FC<Props> = ({ name, className, style, component: Component, children, title }) => {
  const IconComp = name && svgIconMap[name];
  const icon = IconComp ? <IconComp /> : Component ? <Component /> : React.isValidElement(children) ? children : null;

  return icon
    ? React.cloneElement(icon, {
        className,
        title,
        style,
      })
    : null;
};
