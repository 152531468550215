import { SntBorderCrossingType, SntStatus } from 'features/snt';
import { LocaleStore } from 'services/l10n';

export const getSntStatusOptions = (
  { loc }: LocaleStore,
  values: any
): Array<{ value: any; label: string; disabled?: boolean }> => [
  {
    value: SntStatus.NOT_VIEWED,
    label: loc(`common.snt.status.${SntStatus.NOT_VIEWED}`),
    disabled:
      !values.ogdStatuses.includes('NO_OGD') &&
      (values.ogdStatuses.includes(SntStatus.CONFIRMED_BY_OGD) ||
        values.ogdStatuses.includes(SntStatus.DECLINED_BY_OGD)),
  },
  {
    value: SntStatus.DELIVERED,
    label: loc(`common.snt.status.${SntStatus.DELIVERED}`),
    disabled:
      values.borderCrossingType === SntBorderCrossingType.EXPORT ||
      (!values.ogdStatuses.includes('NO_OGD') &&
        (values.ogdStatuses.includes(SntStatus.CONFIRMED_BY_OGD) ||
          values.ogdStatuses.includes(SntStatus.DECLINED_BY_OGD))),
  },
  {
    value: SntStatus.CANCELED,
    label: loc(`common.snt.status.${SntStatus.CANCELED}`),
  },
  {
    value: SntStatus.REVOKED,
    label: loc(`common.snt.status.${SntStatus.REVOKED}`),
  },
  {
    value: SntStatus.DECLINED,
    label: loc(`common.snt.status.${SntStatus.DECLINED}`),
  },
  {
    value: SntStatus.CONFIRMED,
    label: loc(`common.snt.status.${SntStatus.CONFIRMED}`),
  },
];

export const getOgdMarkOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: 'NO_OGD',
    label: loc(`common.snt.status.NO_OGD`),
  },
  {
    value: SntStatus.CONFIRMED_BY_OGD,
    label: loc(`common.snt.status.${SntStatus.CONFIRMED_BY_OGD}`),
  },
  {
    value: SntStatus.DECLINED_BY_OGD,
    label: loc(`common.snt.status.${SntStatus.DECLINED_BY_OGD}`),
  },
];

export const getBorderCrossingTypeOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: SntBorderCrossingType.ALL,
    label: loc(`ser.all`),
  },
  {
    value: SntBorderCrossingType.IMPORT,
    label: loc(`ser.import`),
  },
  {
    value: SntBorderCrossingType.EXPORT,
    label: loc(`ser.export`),
  },
  {
    value: SntBorderCrossingType.TRANSFER,
    label: loc(`ser.transfer`),
  },
];

export const getBorderCrossingCheckpointsPrefixOptions = ({
  loc,
}: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: 'ALL',
    label: loc(`ser.all`),
  },
  {
    value: 'NONE',
    label: loc(`ser.noCheckpoint`),
  },
];

export const getOGDPrefixOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: 'ALL',
    label: loc(`ser.all`),
  },
];
