import React, { useCallback } from 'react';
import { queryMeasureUnit } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';

async function getMeasureUnitOptions({ loc, choose }: LocaleStore) {
  const emptyOptions = [
    {
      value: '',
      label: loc('common.option.notSelected'),
    },
  ];
  const res = await queryMeasureUnit();
  if (Array.isArray(res.data)) {
    return [...emptyOptions, ...res.data];
  }
  return emptyOptions;
}

type Props = FormSelectProps;

export const FormMeasureUnitSelect: React.FC<Props> = ({ ...rest }) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionLabel = useCallback(
    (data) => {
      return data.label ?? choose([data.nameRu, data.nameKz]);
    },
    [choose]
  );

  const getOptionValue = useCallback((data) => {
    return data.value ?? data.code;
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => getMeasureUnitOptions(locale)}
      isSearchable={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
    />
  );
};
