import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryVirtualLabelChainReport: DataQueryFunction = (variables: any) => {
  const { filter = {}, format } = variables || {};

  const { id } = filter || {};

  return downloadFile({
    url: `${API_URL}/admin/virtualLabelChain/report`,
    method: 'GET',
    params: { virtualLabelId: id, format },
  });
};
