import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';

export const mutationLoginCertInfo: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { data } = variables;

  const res = await httpClient.post('/ajax/login/xmlDsigCertInfo', data, {
    headers: { 'Content-Type': 'application/json' },
    transformRequest: (data) => data,
  });
  if (res.data?.iin && (res.data?.base64Cert || res.data?.base64Pem)) {
    return res;
  } else {
    throw res;
  }
};
