import { LocaleStore } from 'services/l10n';

export enum VerStatus {
  VERIFIED = 'VERIFIED',
  NOT_VERIFIED = 'NOT_VERIFIED',
  WAITING_VERIFICATION = 'WAITING_VERIFICATION',
  NO_INVOICES_FOR_VERIFICATION = 'NO_INVOICES_FOR_VERIFICATION',
}

export enum RiskCategory {
  NEW_REGISTERED = 'NEW_REGISTERED',
  HIGH_RISK = 'HIGH_RISK',
}

export const getVerStatusOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: string | boolean; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  ...Object.values(VerStatus).map((status) => ({
    value: status,
    label: loc(`bioRisky.verStatus.${status}`),
  })),
];

export const getTxpActiveOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: string | boolean; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  {
    value: true,
    label: loc('bioRisky.status.true'),
  },
  {
    value: false,
    label: loc('bioRisky.status.false'),
  },
];

export const getRiskCategoryOptions = (
  { loc }: LocaleStore,
  withEmpty: boolean = true
): Array<{ value: string | boolean; label: string }> => [
  ...(withEmpty
    ? [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ]
    : []),
  ...Object.values(RiskCategory).map((category) => ({
    value: category,
    label: loc(`bioRisky.riskCategory.${category}`),
  })),
];
