import { clearEmptyFields } from 'common/utils';
import { convertToFormDataDeep, DataMutationFunction, httpClient } from 'rest';

export const mutationInvoiceImportFromFile: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  // if (false) {
  //   return { data: fake };
  // }

  const formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post('/invoice/importFromFile', formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};

// const fake = {
//   invalidInvoices: [
//     { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }], num: '789', date: '05.05.2023' },
//     {
//       errors: [
//         {
//           property: 'date',
//           text: "'Дата выписки СФ' отличается от текущей отличается от текущей отличается от текущей отличается от текущей отличается от текущей отличается от текущей",
//         },
//         { property: 'date2', text: "'Дата2 выписки СФ' отличается от текущей" },
//       ],
//       num: '123',
//       date: '10.05.2023',
//     },
//     { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }], num: '1', date: '15.05.2023' },
//     { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }], num: '789', date: '05.05.2023' },

//     { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }], num: '1', date: '15.05.2023' },
//   ],
//   invoiceAmount: 3,
//   successInvoiceAmount: 0,
//   invalidInvoiceAmount: 3,
// };
