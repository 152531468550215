import { FormLocaleDatePicker, FormMeasureUnitSelect, FormTaxpayerStoreSelect } from 'common/components/controls';
import { FilterContentProps } from 'common/components/filter-panel';
import { AppMode } from 'common/constants';
import {
  getEnterpriseOptionsAsync,
  getEnterpriseOptionLabel,
  getEnterpriseOptionValue,
  getNotSelectedOptions,
} from 'common/helpers/options';
import React, { Fragment, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { FormField, FormInput, FormSelect, FormValueProps } from 'ui/common';
import { MIN_PERIOD_DATE } from './helpers';

type Props = FilterContentProps;

export const SaldoReportFiltersTaxpayer: React.FC<Props> = ({ classNames: { columnClassName }, form }) => {
  const { values } = form;
  const locale = useLocale();
  const { loc } = locale;

  const emptyOptions = useMemo(() => getNotSelectedOptions(locale), [locale]);

  const onBranchChange = ({ value, name, form }: FormValueProps<any>) => {
    form.setFieldValue(name, value);
    form.setFieldValue('_storeId', '');
  };

  return (
    <Fragment>
      <div className={columnClassName}>
        <FormField name="_storeId" label={loc('common.labels.storeName')} fast={false}>
          <FormTaxpayerStoreSelect appMode={AppMode.TAXPAYER} tin={values['_branch'] || values.tin} />
        </FormField>
        <FormField name="productId" label={loc('common.labels.productId')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="measureUnitId" label={loc('common.labels.measureUnitName')}>
          <FormMeasureUnitSelect />
        </FormField>
        <FormField name="tnvedCode" label={loc('common.labels.tnvedCode')}>
          <FormInput />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="_period" label={loc('saldo.period')}>
          <FormLocaleDatePicker
            placeholderText={loc('saldo.choosePeriod')}
            showMonthYearPicker
            // ESF-1685 Скрыть возможность выбора месяце до октября 2021
            minDate={MIN_PERIOD_DATE}
          />
        </FormField>
      </div>
      <div className={columnClassName}>
        <FormField name="_branch" label={loc('common.labels.branch')}>
          <FormSelect
            options={emptyOptions}
            getOptionLabel={getEnterpriseOptionLabel}
            getOptionValue={getEnterpriseOptionValue}
            isSearchable
            onValueChange={onBranchChange}
            fetchOptions={() => getEnterpriseOptionsAsync({ locale })}
          />
        </FormField>
      </div>
    </Fragment>
  );
};
