import React from 'react';
import clsx from 'clsx';
import s from './QrCodeModal.module.scss';
import { ButtonT2 } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { getResourceUrl } from 'common/helpers';
import { useStore } from 'common/hooks';

type Props = ModalChildrenProps & {
  className?: string;
  qrUrl?: string;
  type?: 'AUTH' | 'SIGN';
};

export const QrCodeModal: React.FC<Props> = ({ className, onClose, qrUrl, type }) => {
  const { locale } = useStore();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header className={s.header}>{loc(`common.${type}.qr.title`)}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <ol className={s.list}>
          <li>{loc('common.qr.step1')}</li>
          <li>{loc('common.qr.step2')}</li>
          <li>{loc('common.qr.step3')}</li>
        </ol>
        <div className={s.imgContainer}>
          <img src={qrUrl} alt="qr code" />
        </div>
        <a
          className={s.gudeLink}
          href={getResourceUrl(
            `data/downloads/${type === 'AUTH' ? 'Инструкция авторизация QR.docx' : 'Инструкция подписание QR.docx'}`
          )}
        >
          {loc('common.qr.guide')}
        </a>
      </ModalT2Content>
      <ModalT2Footer>
        <ButtonT2 variant="transparent" onClick={onClose}>
          {loc('common.button.cancel')}
        </ButtonT2>
      </ModalT2Footer>
    </div>
  );
};
