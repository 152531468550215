import { getIn, setIn } from 'formik';

/**
 * Поиск данных с обшибками (поле message, или массив errors с ошибками)
 */
export function findErrorData(res: any) {
  return (
    res?.response?.data?.message ||
    res?.response?.data?.errors ||
    res?.data?.message ||
    res?.data?.errors ||
    res?.message ||
    res?.errors ||
    res
  );
}

/**
 * Поиск общего сообщения об ошибке
 */
export function solveErrorMessage(res: any): string {
  const errorData = findErrorData(res);
  if (typeof errorData === 'string') {
    return errorData;
  }
  if (Array.isArray(errorData)) {
    // Ошибки без property считаем общими ошибками
    const error = errorData.filter((err) => !err.property)[0];
    return error?.message || error?.text || '';
  }
  return '';
}

/**
 * Поиск ошибок полей формы
 */
export function solveFormErrors(res: any): { [name: string]: any } {
  const errorData = findErrorData(res);
  return transformFormErrors(errorData);
}

/**
 * Преобразует из массива ошибок Array<{ property: 'customer.name', text: 'error text' }>
 * в объект ошибок для формы { customer: { name: 'error text' } }
 */
export function transformFormErrors(errorData: any): { [name: string]: any } {
  if (Array.isArray(errorData)) {
    return errorData
      .filter((err) => err.property && (err.text || err.message)) // Ошибки с полем property считаем ошибками формы
      .reduce((acc, err) => {
        const existingError = getIn(acc, err.property);
        const errorText = err.text || err.message;
        return setIn(
          acc,
          err.property,
          existingError
            ? Array.isArray(existingError)
              ? [...existingError, errorText]
              : [existingError, errorText]
            : errorText
        );
      }, {});
  }
  return {};
}
