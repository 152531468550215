import { BASE_URL } from 'common/constants/config';
import { createBrowserHistory } from 'history';
import { userConfirmation } from './userConfirmation';

const history = createBrowserHistory({
  getUserConfirmation: userConfirmation,
  basename: BASE_URL,
});

export { history };
