import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './JournalT2.module.scss';
import { DataQueryFilter, DataQueryProps } from 'rest';
import {
  Breadcrumbs,
  Crumb,
  InputPagination,
  Loader,
  PageSizeSelect,
  TableColumn,
  TableTab,
  useIsFirstRenderRef,
} from 'ui/common';
import { FormikProps, isPromise } from 'formik';
import { useLocale } from 'services/l10n';
import { Modal } from 'services/modal';
import { ToastService } from 'services/toasts/ToastService';
import { solveErrorMessage } from 'common/form/helpers';
import { JournalButton } from '../types/JournalButton';
import { SessionStorageService } from 'services/storage/StorageService';
import { PageTitleT2, TableT2, TableT2TotalRow } from 'ui';
import {
  FilterPanelT2,
  FilterT2ClassNames,
  FilterT2ContentProps,
  FilterT2Tag,
} from 'common/components/filter-panel/theme2';
import { ReactComponent as SettingsIcon } from 'assets/icons/journal-buttons/settings.svg';

import { TableT2Tabs } from 'ui/theme2/components/table/TableT2Tabs';
import { JournalT2Settings } from './JournalT2Settings';
import { JournalButtonT2 } from './JournalButtonT2';
import { JournalT2ActionButtons } from './JournalT2ActionButtons';

const emptyFilter = {};

type Props = {
  className?: string;
  columns?: Array<TableColumn>;
  getRowId?: (row: any, index: number) => string | number;
  selectable?: boolean;
  queryProps: DataQueryProps;
  getRows?: (data: any | undefined) => Array<any> | undefined;
  defaultFilter?: DataQueryFilter;
  pageTitle?: string;
  filterComponentMain?: FC<FilterT2ContentProps & any>;
  filterComponentAdvanced?: FC<FilterT2ContentProps & any>;
  filterComponentProps?: { [key: string]: any };
  filterValidationSchema?: any;
  filterStorageKey?: string;
  filterTags?: Array<FilterT2Tag>;
  filterPanelType?: 'modal' | 'aside';
  processFilterBeforeSubmit?: (filter: any, columnsState: Array<TableColumn>) => any;
  headerButtons?: Array<JournalButton>;
  actionButtons?: Array<JournalButton>;
  hidden?: boolean;
  tableTabs?: Array<TableTab>;
  getActiveTabName?: (queryProps: DataQueryProps) => string;
  onTableTabChange?: (tab: TableTab) => void;
  settingsModalWidth?: number;
  breadcrumbs?: Crumb[];
  oldVersionLink?: { label: string; url: string; external: boolean };
  showTotal?: boolean;
  paginationRowsOptions?: Array<{
    value: number;
    label: string;
  }>;
};

export const JournalT2: React.FC<Props> = ({
  className,
  columns,
  getRowId,
  selectable,
  queryProps,
  getRows = (data): Array<any> => (Array.isArray(data?.rows) ? data?.rows : undefined),
  defaultFilter = emptyFilter,
  pageTitle,
  filterComponentMain: FilterComponentMain,
  filterComponentAdvanced: FilterComponentAdvanced,
  filterComponentProps,
  filterValidationSchema,
  filterStorageKey,
  filterPanelType = 'modal',
  processFilterBeforeSubmit = (filter) => filter,
  headerButtons = [],
  actionButtons = [],
  hidden,
  filterTags,
  tableTabs,
  getActiveTabName,
  onTableTabChange,
  settingsModalWidth,
  breadcrumbs,
  oldVersionLink,
  showTotal,
  paginationRowsOptions,
}) => {
  const { loc } = useLocale();
  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [showJournalSettings, setShowJournalSettings] = useState(false);
  const [exporting, setExporting] = useState(false);
  const firstRenderRef = useIsFirstRenderRef();

  const [columnsState, setColumnsState] = useState<Array<TableColumn>>((columns || []).filter((col) => !col?.exclude));

  const { data, filter, setFilter, pagination, setPagination, sorting, setSorting, loading, error } = queryProps;

  const onFilterSubmit = async (values: { [key: string]: string }) => {
    setFilter(processFilterBeforeSubmit(values, columnsState));
  };

  const rows = getRows(data);

  const showResult = !!data && Array.isArray(rows) && !!rows.length;
  const showEmptyResult = !!data && (!rows || (Array.isArray(rows) && rows.length === 0));

  const onChangeSelected = useCallback((rows) => setSelectedRows(rows), []);

  const renderFilterHeader = useCallback(
    (props: { form: FormikProps<any>; classNames: FilterT2ClassNames }) =>
      !!FilterComponentMain ? <FilterComponentMain {...props} {...filterComponentProps} /> : null,
    [FilterComponentMain, filterComponentProps]
  );

  const renderFilterContent = useCallback(
    (props: { form: FormikProps<any>; classNames: FilterT2ClassNames }) =>
      !!FilterComponentAdvanced ? <FilterComponentAdvanced {...props} {...filterComponentProps} /> : null,
    [FilterComponentAdvanced, filterComponentProps]
  );

  const OnButtonClick = (btn: JournalButton) => {
    if (typeof btn.onClick === 'function') {
      const result = btn.onClick(selectedRows, columnsState);
      if (isPromise(result)) {
        setExporting(true);
        result.catch((err) => console.log(err)).finally(() => setExporting(false));
      }
      return result;
    }
  };

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (filterStorageKey) {
      SessionStorageService.set(filterStorageKey, filter);
    }
  }, [filter, filterStorageKey]);

  useEffect(() => {
    if (!firstRenderRef.current) {
      onFilterSubmit(filter);
    }
  }, [columnsState]); // eslint-disable-line

  const enabledActionButtons = selectedRows.length
    ? actionButtons.filter(
        (btn) =>
          !(
            (!btn.multiselect && selectedRows.length > 1) ||
            (typeof btn.disabled === 'function' && btn.disabled(selectedRows))
          )
      )
    : [];

  return (
    <div className={clsx(className, s.wrapper, 'container', { hidden })}>
      {exporting ? <Loader overlay /> : null}
      <PageTitleT2>{pageTitle}</PageTitleT2>
      {(breadcrumbs?.length || oldVersionLink) && (
        <div className={s.linksRow}>
          {!!breadcrumbs?.length && <Breadcrumbs crumbs={breadcrumbs} variant="taxpayer" />}
          {oldVersionLink ? (
            oldVersionLink.external ? (
              <a className={s.oldVersionLink} href={oldVersionLink.url}>
                {oldVersionLink.label}
              </a>
            ) : null
          ) : null}
        </div>
      )}
      <FilterPanelT2
        className={s.filters}
        filterValues={filter}
        defaultValues={defaultFilter}
        validationSchema={filterValidationSchema}
        onSubmit={onFilterSubmit}
        renderHeader={renderFilterHeader}
        renderContent={renderFilterContent}
        filterTags={filterTags}
        advancedPanelType={filterPanelType}
        loading={loading}
      />

      <div className={s.actionRow}>
        {!!enabledActionButtons.length && (
          <JournalT2ActionButtons actionButtons={enabledActionButtons} onActionClick={(btn) => OnButtonClick(btn)} />
        )}
        {showResult && (
          <InputPagination
            className={s.inputPagination}
            currentPage={pagination.pageNum}
            onPageChange={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
            lastPage={data.lastBlock}
            firstTitle={loc('common.labels.firstPage')}
            nextTitle={loc('common.labels.nextPage')}
            prevTitle={loc('common.labels.prevPage')}
            theme="T2"
          />
        )}
      </div>
      <div className={s.header}>
        <TableT2Tabs
          className={s.tableTabs}
          tabs={tableTabs}
          activeTabName={typeof getActiveTabName === 'function' ? getActiveTabName(queryProps) : undefined}
          onTabsChange={(tab) => (typeof onTableTabChange === 'function' ? onTableTabChange(tab) : undefined)}
          disabled={loading}
        />
        <div className={s.headerButtons}>
          {headerButtons
            .filter((btn) => !(typeof btn.disabled === 'function' && btn.disabled(selectedRows)))
            .map((btn) => (
              <JournalButtonT2
                key={btn.id}
                className={s.headerButton}
                iconClassName={s.headerButtonIcon}
                button={btn}
                onClick={() => OnButtonClick(btn)}
              />
            ))}
          <JournalButtonT2
            className={s.headerButton}
            iconClassName={s.headerButtonIcon}
            button={{
              id: 'Settings',
              label: loc('common.title.journalSettingsT2'),
              icon: SettingsIcon,
              variant: 'icon',
            }}
            onClick={() => setShowJournalSettings(true)}
          />
        </div>
      </div>

      {showResult ? (
        <Fragment>
          <TableT2
            columns={columnsState}
            rows={rows}
            selectable={selectable}
            getRowId={getRowId}
            onChangeSelected={onChangeSelected}
            scrollable
            loading={loading}
            loaderRender={() => <Loader className={s.tableLoader} />}
            resizableColumns
            onColumnWidthChange={(widths) => console.log('hear we go!', widths)}
            renderFooter={
              showTotal
                ? (columns) => <TableT2TotalRow rows={rows} columns={columns} isSelectableTable={selectable} />
                : undefined
            }
            sortColumnName={sorting.sort}
            sortOrder={sorting.order}
            onColumnHeadClick={(column) =>
              setSorting((state) => {
                if (state.sort === column.name) {
                  return { ...state, order: state.order === 'desc' ? 'asc' : 'desc' };
                } else {
                  return { sort: column.name, order: 'desc' };
                }
              })
            }
          />
        </Fragment>
      ) : (
        <div className={s.noResult}>
          {loading ? (
            <Loader overlay className={s.loader} />
          ) : showEmptyResult ? (
            <span className={s.emptyMessage}>{loc('common.emptyResult')}</span>
          ) : error ? (
            <Fragment>
              <span className={s.errorLabel}>{loc('common.errorResult')}:</span>
              <span className={s.errorMessage}>{solveErrorMessage(error)}</span>
            </Fragment>
          ) : null}
        </div>
      )}

      {showResult && (
        <div className={s.pagination}>
          <PageSizeSelect
            pageSize={pagination.rows || 10}
            onPageSizeChange={(rows) => setPagination((state) => ({ pageNum: 1, rows }))}
            theme="T2"
            rowsOptions={paginationRowsOptions}
          />
          <span className={s.paginationLabel}>{loc('common.labels.pageSize')}</span>
        </div>
      )}

      <Modal isOpen={showJournalSettings} onClose={() => setShowJournalSettings(false)} theme="T2" scrollLock={false}>
        <JournalT2Settings columns={columnsState} setColumns={setColumnsState} width={settingsModalWidth} />
      </Modal>
    </div>
  );
};
