import React from 'react';
import clsx from 'clsx';
import s from './LogButton.module.scss';
import { observer } from 'mobx-react-lite';
import { useLocale } from 'services/l10n';
import { SvgIcon } from 'ui/common';
import { useAuth } from 'common/hooks';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as LoginIcon } from 'assets/icons/login.svg';

type Props = {
  className?: string;
  buttonClassName?: string;
  action: 'login' | 'logout';
  reversedColor?: boolean;
};

export const LogButton: React.FC<Props> = observer(({ className, action, reversedColor }) => {
  const { loc } = useLocale();

  const auth = useAuth();

  const logout = async () => {
    try {
      await auth.logout();
    } catch (error) {
      console.dir(error);
    }
  };

  const login = async () => {
    console.log('login');
  };

  return action === 'logout' ? (
    <button className={clsx(className, s.textButton, s.wrapper, { [s.reversed]: reversedColor })} onClick={logout}>
      <span className={s.logText}>{loc('common.button.logout')}</span>
      <SvgIcon className={s.icon}>
        <LogoutIcon />
      </SvgIcon>
    </button>
  ) : (
    <button className={clsx(className, s.textButton, s.wrapper, { [s.reversed]: reversedColor })} onClick={login}>
      <span className={s.logText}>{loc('common.button.login')}</span>
      <SvgIcon className={s.icon}>{<LoginIcon />}</SvgIcon>
    </button>
  );
});
