import { useUser } from 'common/hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import { routesEaecUserConfig, routesProtectedConfig, routesGuestConfig } from 'routing/routes';
import { generateRoutes } from '../helpers';
import { SwitchRoutes } from './SwitchRoutes';

type Props = {};

export const RoutesProvider: React.FC<Props> = observer(() => {
  const [startingApp, setStartingApp] = useState(true);

  const user = useUser();

  const routes = useMemo(() => {
    // return generateRoutes([routesEaecUserConfig]);
    return user.isAuthed
      ? user.isEaecUser
        ? generateRoutes([routesEaecUserConfig])
        : generateRoutes([routesProtectedConfig])
      : generateRoutes([routesGuestConfig]);
  }, [user.isAuthed, user.isEaecUser]);

  // console.log(toJS(user));

  useEffect(() => {
    user
      .fetch()
      .catch((e) => console.error(e))
      .finally(() => setStartingApp(false));
  }, []); // eslint-disable-line

  useEffect(() => {
    console.log('user', toJS(user));
  }, [user.currentUser, user.eaecUser, user]);

  return startingApp ? null : <SwitchRoutes routes={routes} />;
});
