import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import s from './UserForm.module.scss';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { FormField, FormInput, FormSelect, FormTextarea, FormUploader } from 'ui/common';
import { getOptionsUserServiceRole, getOptionsYesNo } from 'common/helpers/options';
import { useLocale } from 'services/l10n';
import { UserFormPermissions } from './UserFormPermissions';
import { clearPrivateProps } from 'common/utils';
import { PERMISSION_STATUS } from 'common/constants';
import { useUser } from 'common/hooks';
import { FormAutocompleteIIN, FormOGDSelect } from 'common/components/controls';
import { resolveTaxpayerFio } from 'common/helpers/taxpayer';
import { FormCheckpointSelect } from 'common/components/controls/FormCheckpointSelect';
import { checkServiceRoleHierarchy } from '../helpers';
import { solveFormErrors } from 'common/form/helpers';

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
  data?: any;
  renderModalFooter?: (props: FormikProps<any>) => React.ReactNode;
  modal?: boolean;
};

const schema = yup.object({});

export const UserForm: React.FC<Props> = ({ className, onSubmit, data, renderModalFooter, modal }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { currentUser } = useUser();
  const initialValues = useMemo(
    () =>
      data
        ? {
            role: data.userServiceRoleType,
            iin: { tin: data.iin },
            name: data.name,
            email: data.email,
            ipAddress: data.ipAddress || '',
            ogdCode: data.ogdCode,
            blocked: data.blocked,
            mobileUser: data.mobileUser,
            mobile: data.mobile,
            blockingReason: data.blockingReason,
            checkpointCode: Number(data.checkpointCode) || data.checkpointCode,
            permissions: Object.fromEntries(
              Array.isArray(data.adminPermissionInfos)
                ? data.adminPermissionInfos
                    .filter((perm: any) => !!perm.permission)
                    .map((perm: any) => [perm.permission, perm.status === PERMISSION_STATUS.CONFIRMED])
                : []
            ),
            create: false,
          }
        : // Начальные данные при приглашении администратора
          {
            isNew: true,
            permissions: {},
          },
    [data]
  );

  const submitHandler = async (values: any, form: FormikHelpers<any>) => {
    console.log('values', values);
    const formValues = clearPrivateProps(values);
    try {
      await onSubmit({
        ...formValues,
        iin: formValues.iin?.tin,
        permissions: Object.entries(formValues.permissions)
          .filter((perm) => perm[1])
          .map((perm) => perm[0]),
      });
    } catch (error) {
      console.log('error', error);

      if (typeof error === 'string') {
        form.setErrors({ iin: error });
      } else {
        form.setErrors(solveFormErrors(error));
      }

      // todo подумать как сделать получше, вынести как хелпер
      setTimeout(() => {
        const firstError = document.querySelector(
          'form input.control-error, form textarea.control-error, form .control-error input, form select.control-error'
        );
        if (firstError) {
          (firstError as HTMLElement).focus();
        }
      }, 100);

      form.setSubmitting(false);
    }
  };

  const filterRoleOptions = useCallback(
    (option: any) => checkServiceRoleHierarchy(currentUser?.userServiceRoleType as string, option.value),
    [currentUser]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(props) => {
        const { setFieldValue, values } = props;
        return (
          <Form className={clsx(className, s.wrapper, { [s.modal]: modal })}>
            <div className={s.container}>
              <div className={s.column}>
                <FormField name="role" label="Роль в системе">
                  <FormSelect options={getOptionsUserServiceRole(locale)} filterOption={filterRoleOptions} />
                </FormField>
                <FormField name="iin" label="ИИН">
                  <FormAutocompleteIIN
                    onValueChange={({ value }) => {
                      console.log(value);
                      setFieldValue('iin', value || undefined);
                      setFieldValue('name', resolveTaxpayerFio(value, locale));
                    }}
                  />
                </FormField>
                <FormField name="name" label="ФИО">
                  <FormInput />
                </FormField>
                <FormField name="email" label="Email">
                  <FormInput />
                </FormField>
                <FormField name="ipAddress" label="IP-адрес">
                  <FormInput />
                </FormField>
                <FormField name="mobile" label="Телефон">
                  <FormInput />
                </FormField>
                <FormField name="ogdCode" label="Код ОГД">
                  <FormOGDSelect />
                </FormField>
                <FormField name="checkpointCode" label="Пункт пропуска">
                  <FormCheckpointSelect />
                </FormField>
                <FormField name="blocked" label="Заблокирован">
                  <FormSelect options={getOptionsYesNo(locale)} />
                </FormField>
                <FormField name="mobileUser" label="Пользователь мобильного приложения КГД">
                  <FormSelect options={getOptionsYesNo(locale)} />
                </FormField>
                <FormField
                  name="blockingReason"
                  label={values.blocked === false ? loc('armUsers.recoveryReason') : loc('armUsers.blockingReason')}
                  fast={false}
                >
                  <FormTextarea />
                </FormField>
                <FormField name="file" label="Прикрепить документ">
                  <FormUploader label="Выберите файл" />
                </FormField>
              </div>
              <UserFormPermissions columnClassName={s.column} permissionStatuses={data?.adminPermissionInfos} />
            </div>
            {typeof renderModalFooter === 'function' && renderModalFooter(props)}
          </Form>
        );
      }}
    </Formik>
  );
};
