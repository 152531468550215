import React from 'react';
import { FieldProps } from 'formik';
import { CheckboxMultiselect, CheckboxMultiselectProps } from '../components';
import { FormValueProps } from './FormValueProps';

type Props = Partial<FieldProps> &
  Omit<CheckboxMultiselectProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps) => void;
  };

export const FormMultiselect: React.FC<Props> = ({ field, form, meta, onValueChange, ...rest }) => {
  const { name, onBlur, value } = field || {};
  const { setFieldValue } = form || {};

  const onSelectChange = (val: any) => {
    if (onValueChange && name && form) {
      onValueChange({ value: val, name, form });
    } else if (typeof setFieldValue === 'function' && name) {
      setFieldValue(name, val);
    }
  };

  return (
    <CheckboxMultiselect
      {...rest}
      {...{
        name,
        onChange: onSelectChange,
        onBlur,
        value: Array.isArray(value) ? value : [],
      }}
    />
  );
};
