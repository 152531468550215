import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import s from './MainLoginForm.module.scss';
import { ButtonT2, FormFieldT2, FormInputT2 } from 'ui';
import { useLocale } from 'services/l10n';
import { Link } from 'react-router-dom';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import { PATH } from 'routing/paths';
import { LEGACY_REGISTRATION_URL } from 'common/constants';

type Props = {
  onSubmit(values: any): Promise<any>;
  initialValues?: any;
};

const schema = yup.object({
  login: yup.string().trim().required('Введите логин'),
  password: yup.string().trim().required('Введите пароль'),
});

export const MainLoginForm: React.FC<Props> = ({ onSubmit, initialValues = { login: '', password: '' } }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [loginReadonly, setLoginReadonly] = useState(true);

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await onSubmit({
        login: values.login.trim(),
        password: values.password.trim(),
      });
    } catch (error) {
      console.dir(error);
      const message = solveErrorMessage(error);
      actions.setErrors({ login: message });
      ToastService.showError(message);
      actions.setSubmitting(false);
    }
  };

  // Открывает поле ИИН при одновременном нажатии кнопок zxc
  useEffect(() => {
    const pressed: any = {};
    const keyDown = (e: KeyboardEvent) => {
      const code = e.code;
      pressed[code] = true;
      if (Object.keys(pressed).sort().join('') === 'KeyCKeyXKeyZ') {
        setLoginReadonly(false);
        removeEventListeners();
      }
    };

    const keyUp = (e: KeyboardEvent) => {
      const code = e.code;
      delete pressed[code];
    };

    const removeEventListeners = () => {
      document.removeEventListener('keydown', keyDown);
      document.removeEventListener('keyup', keyUp);
    };

    document.addEventListener('keydown', keyDown);
    document.addEventListener('keyup', keyUp);
    return () => {
      removeEventListeners();
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validationSchema={schema}
      validateOnBlur={false}
      validateOnChange={false}
      validateOnMount={false}
    >
      {({ isSubmitting }) => (
        <Form className={s.form}>
          <FormFieldT2 name="login" errorVariant="inside-icon" fast={false}>
            <FormInputT2 className={s.control} placeholder={loc('common.main.loginIin')} readOnly={loginReadonly} />
          </FormFieldT2>

          <FormFieldT2 name="password" errorVariant="inside-icon">
            <FormInputT2 type="password" className={s.control} placeholder={loc('common.main.password')} />
          </FormFieldT2>

          <ButtonT2 className={s.button} disabled={isSubmitting} type="submit" size="large" fullWidth>
            {loc('common.main.login')}
          </ButtonT2>
          <div className={s.links}>
            <Link to={PATH.RESET_PASSWORD}>{loc('common.main.forget')}</Link>
            <Link to={LEGACY_REGISTRATION_URL}>{loc('common.main.reg2')}</Link>
          </div>
        </Form>
      )}
    </Formik>
  );
};
