import React, { useCallback } from 'react';
import { queryCurency } from 'rest';
import { LocaleStore, useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps } from 'ui/common';
import { getCurencySymbol } from '../code-values';

async function getCurencyOptions({ loc }: LocaleStore) {
  const emptyOption = {
    value: '',
    label: loc('common.option.notSelected'),
  };
  const res = await queryCurency();
  if (Array.isArray(res.data)) {
    return [emptyOption, ...res.data];
  }
  return [emptyOption];
}

type Props = FormSelectProps;

export const FormCurencySelect: React.FC<Props> = ({ ...rest }) => {
  const locale = useLocale();
  const { loc, choose } = locale;

  const getOptionLabel = useCallback(
    (data) => {
      return data.label ?? `${getCurencySymbol(data)} - ${choose([data.nameRu, data.nameKz])}`;
    },
    [choose]
  );

  const getOptionValue = useCallback((data) => {
    return data.value ?? getCurencySymbol(data);
  }, []);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  return (
    <FormSelect
      {...rest}
      fetchOptions={() => getCurencyOptions(locale)}
      isSearchable={true}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      noOptionsMessage={noOptionsMessage}
    />
  );
};
