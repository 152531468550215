import { read, utils } from 'xlsx';
/**
 * Читает список значений из ексель файла xlsx, полученый через выбор файла или через рест.
 * @param file - текстовый файл или Blob
 * @returns - Массив значений
 */
export const readListFromXLSXFile = (file: File | Blob): Promise<Array<string>> => {
  console.log('file', file);
  return new Promise<any>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as any);
      const workbook = read(data, { type: 'array' });
      const firstSheet = workbook.SheetNames[0];
      const excelRows: any[] = utils.sheet_to_json(workbook.Sheets[firstSheet]);
      console.log('excelRows', excelRows);
      let list: any[] = [];
      if (excelRows.length) {
        const columnName = Object.keys(excelRows[0])[0];
        list = [columnName, ...excelRows.map((row) => row[columnName])];
        console.log({ list, columnName });
      }
      resolve(list);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsArrayBuffer(file);
  });
};
