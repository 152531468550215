import React, { useState } from 'react';
import clsx from 'clsx';
import s from './UnsignedAgreementModal.module.scss';
import ss from '../Registration.module.scss';
import { ButtonT2 } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { useStore } from 'common/hooks';
import { getAgreementHtml } from '../helpers';
import { mutationAgreementsSave } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { RegAgreementContent } from '../steps/reg-agreement/RegAgreementContent';
import { RegCredentials } from '../steps/reg-agreement/RegCredentials';

type Props = ModalChildrenProps & {
  className?: string;
  agreementData: any;
  onDone: () => void;
};

/**
 * Пока не доделываю, жду дизайн, возможно этой проверки не будет
 */
export const UnsignedAgreementModal: React.FC<Props> = ({ className, agreementData, onDone }) => {
  const { locale, auth } = useStore();
  const { loc } = locale;

  const { registrationType, date } = agreementData;

  const [submitting, setSubmitting] = useState(false);

  const onSign = async () => {
    setSubmitting(true);
    const agreementHtml = getAgreementHtml();
    try {
      const { cert, sign } = await auth.getSignature(agreementHtml, 'REGISTRATION');
      try {
        await mutationAgreementsSave({
          data: {
            signedAgreement: {
              certificate: cert.base64Pem,
              signature: sign,
              content: agreementHtml,
            },
            registrationType: registrationType,
            date,
          },
        });
        ToastService.showSuccess('Success');
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header className={s.header}>{loc(`reAgreement.title`)}</ModalT2Header>

      <ModalT2Content className={s.content}>
        <div className={clsx(ss.frame)}>
          <div className="agreementHtml">
            <RegAgreementContent contentClassName={s.agreementContent} />
            <RegCredentials type={registrationType} regData={agreementData} unsigned />
          </div>
        </div>

        {/* <div className={s.agreeSection}>
          <FieldT2 label={loc('reg.agreeText')} direction="line-reverse" htmlFor="confirmAgreement">
            <CheckboxT2 id="confirmAgreement" checked={confirmed} onChange={(e) => setConfirmed(!!e.target.checked)} />
          </FieldT2>
        </div> */}
      </ModalT2Content>
      <ModalT2Footer>
        <ButtonT2 onClick={onSign} loading={submitting} size="small">
          {loc('common.button.sign')}
        </ButtonT2>
      </ModalT2Footer>
    </div>
  );
};
