import React from 'react';
import clsx from 'clsx';
import s from './Tag.module.scss';
import { ButtonIcon } from 'ui/common/controls';
import { UITheme } from 'ui/common/types';

export type TagProps = {
  className?: string;
  closeClassName?: string;
  onClose?: (e: React.SyntheticEvent) => void;
  theme?: UITheme;
};

export const Tag: React.FC<TagProps> = ({ className, closeClassName, children, onClose, theme = 'T1' }) => {
  return (
    <span className={clsx(className, s.wrapper, s[theme])}>
      {children}
      <ButtonIcon
        className={clsx(s.button, closeClassName)}
        iconClassName={s.icon}
        iconName="close"
        onClick={onClose}
      />
    </span>
  );
};
