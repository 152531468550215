import clsx from 'clsx';
import { ADMIN_PERMISSION_GROUPS, PERMISSION_STATUS } from 'common/constants';
import { getIn, useFormikContext } from 'formik';
import React, { Fragment, useEffect, useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Accordion, FormCheckbox, FormField, Status, StatusConfig } from 'ui/common';
import { checkAdminPermissionByRole } from '../helpers/checkAdminRolePermission';
import s from './UserFormPermissions.module.scss';

type Props = {
  columnClassName?: string;
  permissionStatuses?: Array<any>;
};

export const UserFormPermissions: React.FC<Props> = ({ columnClassName, permissionStatuses }) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { loc } = useLocale();

  const groups = useMemo(
    () =>
      ADMIN_PERMISSION_GROUPS.map((group) => ({
        ...group,
        permissions: group.permissions.filter((p) => checkAdminPermissionByRole(values.role, p)),
      })).filter((g) => g.permissions.length),
    [values.role]
  );

  const statuses = useMemo(
    () =>
      Array.isArray(permissionStatuses)
        ? Object.fromEntries(permissionStatuses.map((s) => [s.permission, s.status]))
        : {},
    [permissionStatuses]
  );

  const statusConfig: StatusConfig = {
    [PERMISSION_STATUS.TO_CONFIRM]: {
      label: 'В ожидании',
      color: '#FA8C16',
    },
  };

  const onAllChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // выделяем все чекбоксы
    groups.forEach((group) =>
      group.permissions.forEach((perm) => setFieldValue(`permissions.${perm}`, e.target.checked))
    );
    setFieldValue(e.target.name, e.target.checked);
  };

  const onGroupChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    group: {
      id: number;
      name: string;
      label: string;
      permissions: string[];
    }
  ) => {
    e.stopPropagation();
    group.permissions.forEach((perm) => setFieldValue(`permissions.${perm}`, e.target.checked));
    setFieldValue(e.target.name, e.target.checked);
  };

  // апдейт чекбокса группы
  useEffect(() => {
    // console.time('groups');
    groups.forEach((group) => {
      const groupCheckboxValue = getIn(values, `permissions._${group.name}`);
      if (!groupCheckboxValue && group.permissions.length && group.permissions.every((p) => !!values.permissions[p])) {
        setFieldValue(`permissions._${group.name}`, true);
      }
      if (groupCheckboxValue && group.permissions.some((p) => !values.permissions[p])) {
        setFieldValue(`permissions._${group.name}`, false);
      }
    });
    // console.timeEnd('groups');
  }, [values.permissions, setFieldValue, groups]); // eslint-disable-line

  // апдейт чекбокса "все полномочия"
  useEffect(() => {
    // console.time('all');
    const groupAllValue = getIn(values, `permissions._all]`);
    const allPermissions = groups.flatMap((g) => g.permissions);

    if (!groupAllValue && allPermissions.length && allPermissions.every((p) => !!values.permissions[p])) {
      setFieldValue(`permissions._all`, true);
    }
    if (groupAllValue && allPermissions.some((p) => !values.permissions[p])) {
      console.log('here');
      setFieldValue(`permissions._all`, false);
    }
    // console.timeEnd('all');
  }, [values.permissions, setFieldValue, groups]); // eslint-disable-line

  return (
    <div className={clsx(s.wrapper, columnClassName)}>
      {!!groups.length && (
        <FormField
          name={`permissions.['_all']`}
          label={loc(`armUsers.allPermissions`)}
          direction="line-reverse"
          className={s.allField}
          labelClassName={s.groupLabel}
        >
          <FormCheckbox
            onValueChange={({ event }) => event && onAllChange(event as React.ChangeEvent<HTMLInputElement>)}
          />
        </FormField>
      )}

      {groups.map((group) => (
        <Accordion
          key={`${group.id}${values.role}`}
          headerClassName={s.accHeader}
          animated
          renderHeader={() => (
            <Fragment>
              <FormField name={`permissions.['_${group.name}']`} direction="line-reverse" className={s.groupField}>
                <FormCheckbox
                  onValueChange={({ event }) =>
                    event && onGroupChange(event as React.ChangeEvent<HTMLInputElement>, group)
                  }
                />
              </FormField>
              <span className={s.groupLabel}>{loc(group.label)}</span>
            </Fragment>
          )}
        >
          {group.permissions.map((permission: string) => {
            const toConfirm = statuses[permission] === PERMISSION_STATUS.TO_CONFIRM;
            return (
              <FormField
                key={permission}
                name={`permissions.${permission}`}
                label={loc(`admin.permission.${permission}`)}
                direction="line-reverse"
                className={s.permissionField}
                noMargin
              >
                {(props: any) => (
                  <Fragment>
                    <FormCheckbox {...props} />
                    <Status
                      value={toConfirm ? PERMISSION_STATUS.TO_CONFIRM : ''}
                      config={statusConfig}
                      className={s.status}
                    />
                  </Fragment>
                )}
              </FormField>
            );
          })}
        </Accordion>
      ))}
    </div>
  );
};
