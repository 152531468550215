import { LocaleStore } from 'services/l10n';
import { EeacExStatus, EecDocumentGroup } from './constants';

export const getEaecActiveOptions = ({ loc }: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  {
    value: true,
    label: loc('eaecActions.active.true'),
  },
  {
    value: false,
    label: loc('eaecActions.active.false'),
  },
];

export const getEaecCodeOptions = ({ loc }: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...['P.LS.05.MSG.004', 'P.LS.05.MSG.006', 'P.LS.05.MSG.007', 'P.LS.05.MSG.008'].map((code) => ({
    value: code,
    label: `${code} - ${loc(`eaecActions.envelopeCode.${code}`)}`,
  })),
];

export const getEaecDocumentOutputStatusOptions = ({
  loc,
}: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    EeacExStatus.SENT,
    EeacExStatus.RECEIVED,
    EeacExStatus.IN_PROCESSING,
    EeacExStatus.DOCUMENT_ADDED,
    EeacExStatus.DOCUMENT_CHANGED,
    EeacExStatus.DOCUMENT_CANCELED,
    EeacExStatus.DOCUMENT_EXCLUDED,
    EeacExStatus.ERROR,
    EeacExStatus.PROCESSING_ERROR,
    EeacExStatus.WAITING_TIME_EXPIRED,
  ].map((status) => ({
    value: status,
    label: loc(`eaecActions.exStatus.${status}`),
  })),
];

export const getEaecDocumentInputStatusOptions = ({
  loc,
}: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    EeacExStatus.RECEIVED,
    EeacExStatus.IN_PROCESSING,
    EeacExStatus.DOCUMENT_ADDED,
    EeacExStatus.DOCUMENT_CHANGED,
    EeacExStatus.DOCUMENT_CANCELED,
    EeacExStatus.DOCUMENT_EXCLUDED,
    EeacExStatus.ERROR,
  ].map((status) => ({
    value: status,
    label: loc(`eaecActions.exStatus.${status}`),
  })),
];

export const getEaecOperationsOutputStatusOptions = ({
  loc,
}: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    EeacExStatus.RECEIVED,
    EeacExStatus.IN_PROCESSING,
    EeacExStatus.DATA_PROVIDED,
    EeacExStatus.DATA_ABSENT,
    EeacExStatus.ERROR,
    EeacExStatus.WAITING_TIME_EXPIRED,
  ].map((status) => ({
    value: status,
    label: loc(`eaecActions.exStatus.${status}`),
  })),
];

export const getEaecOperationsInputStatusOptions = ({
  loc,
}: LocaleStore): Array<{ value: string | boolean; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    EeacExStatus.SENT,
    EeacExStatus.RECEIVED,
    EeacExStatus.IN_PROCESSING,
    EeacExStatus.DATA_PROVIDED,
    EeacExStatus.DATA_ABSENT,
    EeacExStatus.ERROR,
    EeacExStatus.WAITING_TIME_EXPIRED,
  ].map((status) => ({
    value: status,
    label: loc(`eaecActions.exStatus.${status}`),
  })),
];

export const getEaecMonitoringStatusOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...[
    EeacExStatus.DATA_PROVIDED,
    EeacExStatus.DATA_ABSENT,
    EeacExStatus.RECEIVED,
    EeacExStatus.IN_PROCESSING,
    EeacExStatus.ERROR,
    EeacExStatus.WAITING_TIME_EXPIRED,
  ].map((status) => ({
    value: status,
    label: loc(`eaecActions.exStatus.${status}`),
  })),
];

export const getEaecDocumentGroupOptions = ({ loc }: LocaleStore): Array<{ value: any; label: string }> => [
  {
    value: '',
    label: loc('common.option.notSelected'),
  },
  ...Object.values(EecDocumentGroup).map((group) => ({
    value: group,
    label: loc(`eaecActions.documentGroup.${group}`),
  })),
];
