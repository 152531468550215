import { clearEmptyFields } from 'common/utils';
import { convertToFormData, DataMutationFunction, httpClient } from 'rest';

export const mutationAdminAssuranceChangeStatus: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData({
    actionInfo: JSON.stringify(clearEmptyFields(body)),
  });

  const res = await httpClient.post('/admin/assurance/changeStatus', formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
