import { AxiosInstance, AxiosResponse } from 'axios';
import { RootStore } from 'common/stores/RootStore';
import { history } from 'services/history/history';

// const fakeResponse = {
//   data: null,
//   request: {
//     responseURL: 'https://test2.esf.kgd.gov.kz:8443/esf-web/login',
//   },
//   status: 200,
//   statusText: '',
//   headers: {},
//   config: {},
// };

const DIALOG_ID = 'session_closed';

let isRedirecting = false;

/**
 * Проерка что урл из списка исключений.
 * @returns boolean
 */
const isExcludedUrl = (url?: string) => {
  return ['/ajax/login', '/eaec/login', '/logoutAndLogin', '/eaec/signout'].includes(url || '');
};

/**
 * Создаем интерсептор для проверки окончания времени сессии.
 * На бекенде нет кода ответа для окончания сессии, бекенд просто перенаправляет запрос на страницу логина.
 * Проверяем куда был перенаправлен запрос, если урл заканчивается на /login, то считаем что сессия завершена.
 * Показываем диалог пользователю, и через 20 секунд перенаравлям на корневую страницу.
 * TODO в идеале сделать, чтобы бек возвращал 401 для истекшей сессии, но это возможно сломает старый фронт (нужно изучить это вопрос)
 * @param httpClient
 * @param deps
 */
export function createSessionInterceptor(
  httpClient: AxiosInstance,
  deps: {
    rootStore?: RootStore;
  }
) {
  httpClient.interceptors.response.use(
    (response) => {
      const res: AxiosResponse<any> = response;
      if (
        res.status === 200 &&
        (res?.request?.responseURL || '').endsWith('/app') &&
        !isExcludedUrl(res?.config?.url)
      ) {
        console.log('запрос вернул редирект на /app');
        console.dir(res);
        showRedirectAlert(deps.rootStore);
      }
      return response;
    },
    (error) => {
      // В development режиме, через прокси, редиректа на login не происходит, поэтому проверяем по косвенным признакам (может работать некорректно)
      if (
        process.env.NODE_ENV === 'development' &&
        error?.request?.responseURL === '' &&
        error?.request?.status === 0 &&
        error?.message === 'Network Error' &&
        !isExcludedUrl(error?.config?.url)
      ) {
        console.log('ошибка похожая на истекшую сессию');
        console.dir(error);
        showRedirectAlert(deps.rootStore);
      }
      return Promise.reject(error);
    }
  );
}

function showRedirectAlert(rootStore: RootStore | undefined) {
  if (rootStore) {
    const { locale, dialog, user } = rootStore;
    if (!isRedirecting && user.isAuthed) {
      isRedirecting = true;
      const { loc } = locale;
      dialog
        .showAlert({
          content: loc('common.sessionClosed'),
          id: DIALOG_ID,
        })
        .then(() => {
          redirect(rootStore);
        });
      setTimeout(() => {
        redirect(rootStore);
      }, 20 * 1000);
    }
  } else {
    console.log('нет rootStore');
  }
}

async function redirect(rootStore: RootStore) {
  if (isRedirecting) {
    const { user, dialog } = rootStore;
    await user.fetch();
    dialog.cancel(DIALOG_ID);
    history.push('/');
    isRedirecting = false;
  }
}
