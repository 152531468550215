import React, { Fragment, useRef } from 'react';
import clsx from 'clsx';
import s from './BioWarning.module.scss';
import { useLocale } from 'services/l10n';
import { Toast, useAnimateDisplayNone } from 'ui';
import { useCheckBiometricsRequired } from 'features/biometrics';
import { sanitize } from 'dompurify';

type Props = {
  className?: string;
};

export const BioWarning: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const ref = useRef(null);

  const { data } = useCheckBiometricsRequired({ showWarning: false });

  const { hidden, visualyHidden } = useAnimateDisplayNone(ref, data?.required !== true);

  return data?.required === true ? (
    <div
      ref={ref}
      className={clsx(className, s.wrapper, {
        [s.hidden]: hidden,
        [s.visualyHidden]: visualyHidden,
      })}
    >
      <Toast className={s.toast} variant="flat" type="warning">
        <Fragment>
          <h3>{loc('bio_risky.message.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: sanitize(loc('bio_risky.message.content', [data.date])) }} />
          <h4>
            <a href="/esf-web/biometrics/document/oauth2/authorization/did">{loc('bio_risky.message.link')}</a>
          </h4>
        </Fragment>
      </Toast>
    </div>
  ) : null;
};
