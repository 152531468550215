import React, { useState } from 'react';
import clsx from 'clsx';
import s from './ImportFromFileModal.module.scss';
import { Button, Heading, Loader, Uploader } from 'ui/common';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { LocaleStore, useLocale } from 'services/l10n';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = {
  className?: string;
  title?: string;
  onClose?: () => void;
  /**
   * Callback для загрузки файла, срабатывает сразу после выбора файла
   * Должен вернуть результат загрузки или undefined
   */
  uploadFile: (files: File[]) => Promise<any>;
  /**
   * Callback для импорта файла
   * Должен вернуть результат импорта
   */
  onSubmit: (files: File[]) => Promise<any>;
  downloadResult: () => Promise<any>;
  exampleLink?: string;
  exampleLabel?: string;
  renderImportResult?: (result: any, classes: any, locale: LocaleStore) => React.ReactNode;
};

export const ImportFromFileModal: React.FC<Props> = ({
  className,
  title,
  onClose,
  onSubmit,
  uploadFile,
  downloadResult,
  exampleLink,
  exampleLabel,
  renderImportResult = defaultRenderImportResult,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const [uploadResult, setUploadResult] = useState<any>(undefined);
  const [importResult, setImportResult] = useState<any>(undefined);

  const submitHandler = async () => {
    setLoading(true);
    try {
      const res = await onSubmit(files);
      setImportResult(res?.data);
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const removeFile = (file: File) => {
    setImportResult(undefined);
    setUploadResult(undefined);
    setFiles(() => []);
  };

  const addFile = async (files: Array<File>) => {
    setImportResult(undefined);
    setUploadResult(undefined);
    setLoading(true);
    try {
      const res = await uploadFile(files);
      setFiles(() => [files[0]]);
      setUploadResult(res?.data);
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onDownloadResult = async () => {
    setLoading(true);
    try {
      await downloadResult();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const exampleLinkLabel = exampleLabel || loc('common.expamleFileLink');

  console.log({ uploadResult });

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{title || loc('common.importFromFile')}</ModalHeader>
      <ModalContent className={s.content}>
        {!!exampleLink && (
          <div className={s.expampleLink}>
            <a href={exampleLink} target="__blank" rel="noopener noreferrer">
              {exampleLinkLabel}
            </a>
          </div>
        )}
        <Uploader
          label={loc('common.button.chooseFile')}
          onSelect={addFile}
          files={files}
          onRemove={removeFile}
          multiple={false}
        />
        {importResult ? (
          renderImportResult(importResult, s, locale)
        ) : uploadResult ? (
          <div className={s.result}>
            <p className={s.resultItem}>
              {loc('common.labels.recordsCount')}: {uploadResult?.recordCount}
            </p>
          </div>
        ) : null}
        {loading && <Loader className={s.loader} size="small" />}
      </ModalContent>
      <ModalFooter>
        {importResult ? (
          <Button className={s.button} onClick={onDownloadResult} disabled={loading}>
            {loc('common.button.downloadResult')}
          </Button>
        ) : (
          <Button className={s.button} onClick={submitHandler} disabled={!files.length || loading}>
            {loc('common.button.import')}
          </Button>
        )}
        <Button className={s.button} variant="secondary" onClick={onClose}>
          {loc('common.button.close')}
        </Button>
      </ModalFooter>
    </div>
  );
};

const defaultRenderImportResult = (result: any, classes: any, locale: LocaleStore) => {
  const { loc } = locale;
  return (
    <div className={classes.result}>
      <Heading className={classes.importedTitle} tag="h3">
        {loc('common.title.importedCount')}:
      </Heading>
      <p className={classes.resultItem}>
        {loc('common.labels.total')}: {result?.generalCount}
      </p>
      <p className={clsx(classes.resultItem, classes.resultSuccess)}>
        {loc('common.labels.succeded')}: {result?.successCount}
      </p>
      <p className={clsx(classes.resultItem, classes.resultError)}>
        {loc('common.labels.erroneously')}: {result?.errorCount}
      </p>
    </div>
  );
};
