import { FilterContentProps, FormDoubleDatePicker } from 'common/components';
import React, { Fragment } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput } from 'ui/common';

type Props = FilterContentProps;

export const GovInstJournalFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection } = classNames || {};

  return (
    <Fragment>
      <div className={gridWrapper}>
        <div className={gridSection}>
          <FormField name="tin" label={loc('common.labels.binOnly')}>
            <FormInput maxLength={12} onlyDigits />
          </FormField>

          <Field label={loc('govInst.createDate')} noMargin>
            <FormDoubleDatePicker nameFrom="createDateFrom" nameTo="createDateTo" maxDate={new Date()} />
          </Field>
        </div>
      </div>
    </Fragment>
  );
};
