import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import s from './GuestLayout.module.scss';
import clsx from 'clsx';
import { GuestLayoutFooter } from './GuestLayoutFooter';
import { GuestLayoutHeader } from './GuestLayoutHeader';

type Props = RouteComponentProps & {
  children: React.ReactNode;
  variant?: 'main' | 'noFooter';
};

export const GuestLayout: React.FC<Props> = ({ children, variant = 'main' }) => {
  useEffect(() => {
    console.log('MainLayout MOUNTED!!');
  }, []);

  return (
    <div className={clsx(s.wrapper, s[variant])}>
      <div className={s.header}>
        <GuestLayoutHeader />
      </div>
      <div className={s.content}>{children}</div>
      {variant !== 'noFooter' && (
        <div className={s.footer}>
          <GuestLayoutFooter />
        </div>
      )}
    </div>
  );
};
