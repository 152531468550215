import React, { SyntheticEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './TreeNode.module.scss';
import { SvgIcon } from 'ui/common/elements';
import { ReactComponent as MinusCircle } from './icons/minus-circle-bold.svg';
import { ReactComponent as PlusCircle } from './icons/plus-circle-bold.svg';
import { ReactComponent as Arrow } from './icons/arrow-right-bold.svg';
import { TreeStore } from './TreeStore';
import { observer } from 'mobx-react-lite';

type Props = {
  className?: string;
  data: any;
  renderContent: (data: any) => React.ReactNode | string;
  getChildren: (data: any) => Array<any>;
  getId?: (data: any) => string | number;
  onClick?: (data: any, e: SyntheticEvent) => void;
  isChildNode?: boolean;
  expandButtonColor?: string;
  getChildrenLineColors?: (data: any) => Array<string>;
  store?: TreeStore;
};

export const TreeNode: React.FC<Props> = observer((props) => {
  const {
    className,
    data,
    renderContent,
    getChildren,
    getId = (data) => data.id,
    isChildNode,
    expandButtonColor = '#262626',
    getChildrenLineColors = () => ['#262626'],
    store,
  } = props;

  const [expandedState, setExpandedState] = useState(!isChildNode);

  // если нет сторы используется внутренне состояние открыт/закрыт
  const expanded = store ? store.nodes[getId(data)].expanded : expandedState;

  const inner = useRef<HTMLDivElement>(null);

  const childNodes = getChildren(data);

  const childrenLineColors = getChildrenLineColors(data);

  const onExpandButtonClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (store) {
      store.setExpanded(getId(data), !expanded);
    } else {
      setExpandedState((state) => !state);
    }
  };

  // console.log('render nodeID ' + getId(data), expanded);

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.isChild]: isChildNode,
        [s.expanded]: expanded,
      })}
      style={{
        ['--children-line-color1' as any]: childrenLineColors[0],
        ['--children-line-color2' as any]: childrenLineColors[1],
      }}
    >
      <div className={s.nodeRow}>
        {renderContent(data)}
        {isChildNode && Array.isArray(childNodes) && !!childNodes.length && (
          <div className={s.expandButton} onClick={onExpandButtonClick} style={{ color: expandButtonColor }}>
            <SvgIcon className={s.expandIcon}>{expanded ? <MinusCircle /> : <PlusCircle />}</SvgIcon>
          </div>
        )}
      </div>
      {Array.isArray(childNodes) && expanded && (
        <div className={s.childNodes}>
          <div className={s.childNodesOuter}>
            <div className={s.childNodesInner} ref={inner}>
              {childNodes.map((child, index) => (
                <div
                  className={clsx(s.child, {
                    [s.firstChild]: index === 0,
                    [s.lastChild]: childNodes.length - 1 === index,
                    [s.doubleLines]: childrenLineColors.length === 2,
                  })}
                  key={String(getId(child))}
                >
                  <SvgIcon className={s.arrow}>
                    <Arrow />
                  </SvgIcon>
                  <TreeNode {...props} data={child} isChildNode={true} expandButtonColor={childrenLineColors[0]} />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
});
