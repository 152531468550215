import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import s from './DataGridT2.module.scss';

type Props = {
  className?: string;
  columnClassName?: string;
  rowClassName?: string;
  rowStyles?: CSSProperties;
  data: React.ReactNode[][];
  data2?: React.ReactNode[][];
  columnStyles?: Array<CSSProperties>;
  columnClassNames?: Array<string>;
  align?: 'left' | 'right';
};

export const DataGridT2: React.FC<Props> = ({
  className,
  columnClassName,
  rowClassName,
  data,
  data2,
  columnStyles = [],
  rowStyles,
  columnClassNames = [],
  align = 'left',
}) => {
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.alignRight]: align === 'right',
      })}
    >
      {[data, data2].map(
        (dt, dtIndex) =>
          Array.isArray(dt) && (
            <div key={dtIndex} className={s.dataColumn}>
              {dt.map((row, index) => (
                <div key={index} className={clsx(s.row, rowClassName)} style={rowStyles}>
                  {row.map((cell, idx) => (
                    <div
                      key={idx}
                      className={clsx(
                        s.column,
                        columnClassName,
                        columnClassNames[idx] ? columnClassNames[idx] : undefined
                      )}
                      style={columnStyles[idx] ? columnStyles[idx] : {}}
                    >
                      {cell}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )
      )}
    </div>
  );
};
