import React, { Fragment, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './VirtualLabelCard.module.scss';
import { SvgIcon } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { ReactComponent as UndoIcon } from '../icons/undo.svg';
import { API_URL } from 'common/constants';

enum FORM_TYPE {
  /**
   * По умолчанию
   */
  DEFAULT_FORM = 'DEFAULT_FORM',

  /**
   * Списание
   */
  WRITE_OFF_FORM = 'WRITE_OFF_FORM',

  /**
   * Возврат
   */
  RETURN_FORM = 'RETURN_FORM',

  /**
   * Детализация
   */
  DETAILING_FORM = 'DETAILING_FORM',
}

const getRows = ({
  locale,
  data,
}: {
  locale: LocaleStore;
  data: any;
}): Array<{
  name: string;
  label: string | ((expanded: boolean) => string);
  value: string;
  content?: React.ReactNode;
  featured?: boolean;
  changed?: boolean;
  exclude?: boolean;
}> => {
  const { loc } = locale;

  const attrChangesMap =
    Array.isArray(data?.vlAttributeChanges) && data?.vlAttributeChanges.length
      ? Object.fromEntries(data?.vlAttributeChanges.map((attr: string) => [attr, true]))
      : {};

  return [
    {
      name: 'prodId',
      label: loc('common.labels.prodId'),
      value: data?.virtualLabelNodeDetail?.productId,
      content: <div className={s.idWrapper}>{data?.virtualLabelNodeDetail?.productId}</div>,
      featured: true,
    },
    {
      name: 'iin',
      label: loc('common.labels.iin'),
      value: data?.virtualLabelNodeDetail?.tin || data?.sntCustomerTin,
    },
    {
      name: 'taxpayerName',
      label: (expanded: boolean) => loc(expanded ? 'common.labels.npName' : 'common.labels.npName.short'),
      value: data?.shortTaxpayerName || data?.taxpayerName || data?.sntCustomerName, // ESF-2123 Указать получателя в СНТ (sntCustomerName)
      featured: true,
    },
    {
      name: 'originTru',
      label: loc('vl.originCode'),
      value: data?.originTru,
      changed: !!attrChangesMap['ORIGIN_TYPE'],
    },
    {
      name: 'name',
      label: loc('common.labels.name'),
      value: data?.virtualLabelNodeDetail?.name,
      changed: !!attrChangesMap['NAME'],
    },
    {
      name: 'tnvedCode',
      label: loc('common.labels.tnvedCode'),
      value: data?.virtualLabelNodeDetail?.tnvedCode,
      changed: !!attrChangesMap['TNVED_CODE'],
    },
    {
      name: 'measureUnitName',
      label: loc('common.labels.measureUnitName'),
      value: data?.virtualLabelNodeDetail?.measureUnitName,
      changed: !!attrChangesMap['MEASURE_UNIT'],
    },
    {
      name: 'actualQuantity',
      label: (expanded: boolean) => loc(expanded ? 'common.labels.prodQuantity' : 'common.labels.prodQuant'),
      value: data?.virtualLabelNodeDetail?.actualQuantity,
      featured: true,
      changed: !!attrChangesMap['QUANTITY'],
    },
    {
      name: 'price',
      label: loc('vl.unitPrice'),
      value: data?.virtualLabelNodeDetail?.price,
      featured: true,
      changed: !!attrChangesMap['PRICE'],
    },
    {
      name: 'priceSum',
      label: loc('common.labels.sum'),
      value: data?.virtualLabelNodeDetail?.priceSum,
      changed: !!attrChangesMap['PRICE_SUM'],
    },
    {
      name: 'pinCode',
      label: loc('common.labels.pinCode'),
      value: data?.virtualLabelNodeDetail?.pinCode,
    },
    {
      name: 'dutyType',
      label: loc('vl.dutyType'),
      value: loc(`vl.dutyType.${data?.virtualLabelNodeDetail?.dutyType}`),
    },
    {
      name: 'balance',
      label: loc('vl.balance'),
      value: data?.balance,
    },
  ];
};

type Props = {
  className?: string;
  data: any;
  isSelected?: boolean;
};

export const VirtualLabelCard: React.FC<Props> = ({ className, data, isSelected }) => {
  const locale = useLocale();
  const { loc } = locale;
  const [expanded, setExpanded] = useState(false);

  const formType = data?.vlFormType || FORM_TYPE.DEFAULT_FORM;

  const rows = useMemo(() => getRows({ locale, data }).filter((row) => !row.exclude), [data, locale]);

  const bodyHeight = (expanded ? rows.length : rows.filter((row) => row.featured).length) * 24 + 20;

  const documentLink = useMemo(() => {
    const docType = data?.virtualLabelDocumentSummaries?.[0]?.documentType;
    const id = data?.virtualLabelDocumentSummaries?.[0]?.stringDocumentId;

    if (!id) {
      return '';
    } else if (docType === 'SNT') {
      return `${API_URL}/admin/snt/printReport/${id}/PDF`;
    } else if (docType === 'INVOICE') {
      return `${API_URL}/invoice/printReport/admin/${id}/PDF`;
    } else if (docType === 'UFORM') {
      return `${API_URL}/admin/uform/printReport/${id}/PDF`;
    } else if (docType === 'DOCUMENT_GTD') {
      return `${API_URL}/admin/gtd/printReport/${id}/PDF`;
    } else if (docType === 'DOCUMENT_FNO') {
      return `${API_URL}/admin/fno/printReport/${id}/PDF`;
    } else {
      return '';
    }
  }, [data]);

  // console.log('rows l', rows.length);

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.expanded]: expanded,
        [s.skinReturn]: formType === FORM_TYPE.RETURN_FORM,
        [s.skinWriteoff]: formType === FORM_TYPE.WRITE_OFF_FORM,
        [s.skinDetail]: formType === FORM_TYPE.DETAILING_FORM,
        [s.selected]: isSelected,
        'selectedCard--forFocus': isSelected,
      })}
      tabIndex={isSelected ? 0 : undefined}
    >
      <div
        className={clsx(s.header, {
          [s.hasHeaderContentBottom]: data?.detailingType,
        })}
      >
        <div className={s.headerContent}>
          <div className={s.headerContentTop}>
            {formType === FORM_TYPE.DETAILING_FORM && <SvgIcon className={s.icon} name="edit" />}
            {formType === FORM_TYPE.WRITE_OFF_FORM && <SvgIcon className={s.icon} name="delete-outline" />}
            {formType === FORM_TYPE.RETURN_FORM && (
              <Fragment>
                <SvgIcon className={s.icon}>
                  <UndoIcon />
                </SvgIcon>
                <span className={s.skinName}>{loc('vl.return')}</span>
              </Fragment>
            )}

            <span className={s.docType}>
              {data?.virtualLabelDocumentSummaries?.[0]?.documentType === 'UFORM'
                ? loc(`vl_uFormType.${data?.virtualLabelDocumentSummaries?.[0]?.uFormType}`)
                : loc(`vl_docType.${data?.virtualLabelDocumentSummaries?.[0]?.documentType}`)}
              :
            </span>

            {documentLink ? (
              <a className={s.regNumber} href={documentLink} target="_blank" rel="noopener noreferrer">
                {data?.virtualLabelDocumentSummaries?.[0]?.registrationNumber}
              </a>
            ) : (
              <span className={s.regNumber}>{data?.virtualLabelDocumentSummaries?.[0]?.registrationNumber}</span>
            )}
          </div>
          <div className={s.headerContentBottom}>
            <span>{loc('vl.detailingType')}:&nbsp;</span>
            <span>{loc(`vl.detailingType.${data?.detailingType}`)}</span>
          </div>
        </div>
        <div className={s.expandButton} onClick={() => setExpanded((state) => !state)}>
          <SvgIcon name={expanded ? 'caret-up' : 'caret-down'} className={s.icon} />
        </div>
      </div>
      <div className={s.body} style={{ height: `${bodyHeight}px` }}>
        <span className={s.divider}></span>

        {rows.map((row) => {
          // <strong /> tag для рядов котрые должны отображаться в сокращенной карточке (для стилей)
          const Tag = row.featured ? 'strong' : 'div';
          return (
            <Tag
              key={row.name}
              className={clsx(s.row, {
                [s.highlight]: row.changed,
              })}
            >
              <span className={clsx(s.cell, s.left)}>
                {typeof row.label === 'function' ? row.label(expanded) : row.label}
              </span>
              <span className={clsx(s.cell, s.right)} title={row.value}>
                {row.content || row.value}
              </span>
            </Tag>
          );
        })}
      </div>
    </div>
  );
};
