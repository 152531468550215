import React, { Fragment, useState } from 'react';
import clsx from 'clsx';
import s from './DevPage.module.scss';
import { DevLoginForm } from './DevLoginForm';
import { Button, PageTitle, Select } from 'ui/common';
import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
};

export const DevPage: React.FC<Props> = observer(({ className }) => {
  const { user, auth } = useStore();
  const [asSuperUser, setAsSuperUser] = useState(false);

  const submitLogin = (values: any) => {
    return auth.login(values.login, values.password);
  };

  const submitSuperUser = (values: any) => {
    return auth.login(values.login, values.password, true);
  };

  const onSelectProfileChange = (profileId: string) => {
    if (profileId !== user.currentUser?.currentProfileId) {
      user.changeProfile(profileId);
    }
  };

  return (
    <div className={clsx(className, s.wrapper, 'container')}>
      <PageTitle>Development Page</PageTitle>
      {user.isAuthed ? (
        <Fragment>
          <div className={s.section}>
            <Button onClick={() => auth.logout()}>Log Out</Button>
          </div>

          <div className={s.section}>
            <Select
              className={s.control}
              options={user.currentUser?.profiles}
              getOptionLabel={(item) => item.name}
              getOptionValue={(item) => item.id}
              value={user.currentUser?.currentProfileId}
              onChange={onSelectProfileChange}
            />
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {auth.loginFormShowed ? (
            // {true ? (
            <DevLoginForm onSubmit={submitLogin} initialValues={{ login: auth.iin, password: '' }} />
          ) : (
            <Button onClick={() => auth.startAuthProcess()}>Получить сертификат</Button>
          )}

          <div className={s.section}>
            {asSuperUser ? (
              // {true ? (
              <DevLoginForm onSubmit={submitSuperUser} initialValues={{ login: 'super_admin', password: '' }} />
            ) : (
              <Button onClick={() => setAsSuperUser(true)}>Войти как super_admin</Button>
            )}
          </div>
        </Fragment>
      )}
      <br />
      <br />
      <br />
      <code>
        {!user.isAuthed && (
          <Fragment>
            <p>
              <Link className={s.link} to={PATH.MAIN}>
                Main page
              </Link>
            </p>
            <p>
              <Link className={s.link} to={PATH.EAEC_LOGIN}>
                Eaec login page
              </Link>
            </p>
          </Fragment>
        )}
        {!!user.isAuthed && (
          <Fragment>
            <p>
              <Link className={s.link} to={PATH.MAIN}>
                Main page
              </Link>
            </p>
            <p>
              <Link className={s.link} to={PATH.ARM}>
                ARM page
              </Link>
            </p>

            <p>
              <Link className={s.link} to={PATH.ASSURANCE_JOURNAL}>
                Taxpayer page
              </Link>
            </p>
          </Fragment>
        )}
      </code>
    </div>
  );
});
