import React from 'react';
import clsx from 'clsx';
import s from './FeedbackModal.module.scss';
import { ButtonT2, FormFieldT2, FormSelectT2, FormTextareaT2, FormUploader } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { useStore } from 'common/hooks';
import { Form, Formik, FormikHelpers } from 'formik';
import { mutationSendFeedback } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { getNotSelectedOptions } from 'common/helpers';

const initialValues = {};

type Props = ModalChildrenProps & {
  className?: string;
};

export const FeedbackModal: React.FC<Props> = ({ className, onClose }) => {
  const { locale } = useStore();
  const { loc } = locale;

  const options = [
    ...getNotSelectedOptions(locale),
    ...['TECHNICAL', 'METHODOLOGY', 'IMPROVEMENT', 'PILOT'].map((type) => ({
      label: loc(`c.feedback.${type}`),
      value: type,
    })),
  ];

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    const { setSubmitting } = form;
    console.log('Submit', values);

    try {
      await mutationSendFeedback({
        body: values,
      });
      ToastService.showSuccess(loc('c.feedback.success'));
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      setSubmitting(false);
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header>{loc(`c.feedback`)}</ModalT2Header>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }) => {
          return (
            <Form>
              <ModalT2Content className={s.content}>
                <FormFieldT2 label={loc('c.feedback.category')} name="category">
                  <FormSelectT2 className={s.categorySelect} options={options} />
                </FormFieldT2>
                <FormFieldT2 name="text">
                  <FormTextareaT2 />
                </FormFieldT2>
                <FormFieldT2 name="attachments">
                  <FormUploader
                    label={loc('common.button.chooseFiles')}
                    fileInputClassName={s.fileInput}
                    theme="T2"
                    multiple
                  />
                </FormFieldT2>
              </ModalT2Content>
              <ModalT2Footer>
                <ButtonT2 variant="transparent" onClick={onClose}>
                  {loc('common.button.cancel')}
                </ButtonT2>
                <ButtonT2 type="submit" loading={isSubmitting}>
                  {loc('common.button.send')}
                </ButtonT2>
              </ModalT2Footer>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
