import { FilterContentProps, FormCountrySelect, FormDoubleDatePicker, FormListUploader } from 'common/components';
import React, { useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getEaecOperationsInputStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const EaecOperationsInputFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection, gridSectionExpandalbe } = classNames || {};

  const requestStatusOptions = useMemo(() => getEaecOperationsInputStatusOptions(locale), [locale]);

  return (
    <div className={gridWrapper}>
      <div className={gridSection}>
        <FormField name="messageId" label={loc('eaecActions.requestDocumentId')}>
          <FormInput />
        </FormField>
        <Field label={loc('eaecActions.requestDate')}>
          <FormDoubleDatePicker nameFrom="fromRequestDate" nameTo="toRequestDate" maxDate={new Date()} />
        </Field>
        <FormField name="status" label={`${loc('eaecActions.requestStatus')}:`}>
          <FormSelect options={requestStatusOptions} />
        </FormField>
        <FormField name="eaecCountryCode" label={loc('eaecActions.eaecCountry')}>
          <FormCountrySelect onlyEaec excludeKZ />
        </FormField>
      </div>
      <div className={gridSectionExpandalbe}>
        <div className={gridSection}>
          <FormField name="resultMessageId" label={loc('eaecActions.resultDocumentId')}>
            <FormInput />
          </FormField>
          <Field label={loc('eaecActions.responseDate')}>
            <FormDoubleDatePicker nameFrom="fromResponseDate" nameTo="toResponseDate" />
          </Field>
          <div></div>
          <div></div>
          <div>
            <FormField name="documentRegistrationNumber" label={loc('eaecActions.documentRegistrationNumber')}>
              <FormInput />
            </FormField>
            <FormListUploader name="documentRegistrationNumbers" fileType="xlsx" />
          </div>
          <div>
            <FormField name="senderTin" label={loc('eaecActions.eaecTaxpayerId')}>
              <FormInput />
            </FormField>
            <FormListUploader name="senderTins" fileType="xlsx" />
          </div>
          <div>
            <FormField name="tnvedCode" label={loc('common.labels.tnvedCode')}>
              <FormInput />
            </FormField>
            <FormListUploader name="tnvedCodes" fileType="xlsx" />
          </div>
        </div>
      </div>
    </div>
  );
};
