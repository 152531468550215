import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import { solveErrorMessage } from 'common/form/helpers';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { Modal } from 'services/modal';
import { ToastService } from 'services/toasts';
import {
  mutationAdminAssuranceTakeInWork,
  queryAdminAssuranceReport,
  queryAdminAssuranceTableReport,
  queryAdminAssuranceSearch,
} from '../api';
import { ActionReasonModal } from '../components';
import { allowAssurance, allowTakeToWork, changeStatusWithSign } from '../helpers';
import { PassportArmPage } from '../passport-page/PassportArmPage';
import { PassportsJournalFilters } from './PassportsJournalFilters';
import { getPassportsJournalColumns } from './getPassportsJournalColumns';
import { formatFromDate } from 'services/date-time';
import { SessionStorageService } from 'services/storage';
import { useStore } from 'common/hooks';

const filterStorageKey = 'sntTermsConfirmation';

const defaultFilter = {
  dateTo: formatFromDate(new Date()),
  dateFrom: formatFromDate(new Date()),
};
const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.passportId;

type Props = {};

export const PassportsJournalArm: React.FC<Props> = () => {
  const { auth, locale } = useStore();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });
  const [reasonModal, setReasonModal] = useState<{
    opened: boolean;
    passport: any;
  }>({
    opened: false,
    passport: undefined,
  });

  const closeReasonModal = () =>
    setReasonModal({
      opened: false,
      passport: undefined,
    });

  const columns = useMemo(() => getPassportsJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminAssuranceSearch, {
    filter: SessionStorageService.get(filterStorageKey) || defaultFilter,
    pagination: initialPagination,
  });

  const headerButtons = [
    {
      id: 31,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal(selection, 'XLSX'),
      multiselect: true,
    },
    {
      id: 32,
      label: loc('common.button.exportCsv'),
      onClick: (selection: Array<any>) => exportJournal(selection, 'CSV'),
      multiselect: true,
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: (selection: Array<any>) => setNestedPage({ opened: true, row: selection[0] }),
    },
    {
      id: 2,
      label: loc('common.button.pdf'),
      onClick: (selection: Array<any>) => downloadSelectionPDF(selection),
      multiselect: true,
    },

    {
      id: 4,
      label: loc('assurance.takeToWork'),
      onClick: (selection: Array<any>) => takeInWork(selection),
      disabled: (selection: Array<any>) => {
        return !allowTakeToWork(selection[0]);
      },
    },
    {
      id: 5,
      label: loc('assurance.assure'),
      onClick: (selection: Array<any>) => changePassportStatus(selection[0], 'ASSURE_BY_OGD'),
      disabled: (selection: Array<any>) => {
        return !allowAssurance(selection[0]);
      },
    },
    {
      id: 6,
      label: loc('assurance.denied'),
      onClick: (selection: Array<any>) => setReasonModal({ opened: true, passport: selection[0] }),
      disabled: (selection: Array<any>) => {
        return !allowAssurance(selection[0]);
      },
    },
  ];

  const downloadSelectionPDF = async (selection: Array<any>) => {
    try {
      await queryAdminAssuranceReport({
        ids: selection.map((item) => item.passportId).join(','),
        format: 'PDF',
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const exportJournal = async (selection: Array<any>, format: string) => {
    try {
      await queryAdminAssuranceTableReport({
        ids: selection.map((item) => item.passportId),
        filter: queryProps.filter,
        format,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const changePassportStatus = async (passport: any, actionType: string, cause?: string) => {
    try {
      await changeStatusWithSign({
        passportId: String(passport.passportId),
        actionType,
        cause,
        auth,
      });
      queryProps.refetch();
      closeReasonModal();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const takeInWork = async (selection: Array<any>) => {
    try {
      await mutationAdminAssuranceTakeInWork({
        id: selection[0].passportId,
      });
      queryProps.refetch();
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  return (
    <Fragment>
      <Journal
        pageTitle={loc('assurance.journalTitle')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={PassportsJournalFilters}
        filterInitialOpened
        defaultFilter={defaultFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        hidden={nestedPage.opened}
        getRowId={getRowId}
        filterStorageKey={filterStorageKey}
      />
      {nestedPage.opened ? (
        <PassportArmPage
          onClose={closeNestedPage}
          passportId={nestedPage.row?.stringPassportId && String(nestedPage.row?.stringPassportId)}
        />
      ) : null}

      <Modal isOpen={reasonModal.opened} onClose={closeReasonModal}>
        <ActionReasonModal onSubmit={(cause) => changePassportStatus(reasonModal.passport, 'DENY_ASSURANCE', cause)} />
      </Modal>
    </Fragment>
  );
};
