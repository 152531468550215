import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const querySntDownloadXmlSono: DataQueryFunction = (variables: any) => {
  const { sntIdList } = variables || {};

  return downloadFile({
    url: `${API_URL}/snt/downloadXmlSono`,
    method: 'GET',
    params: { sntIdList, sntVersion: 'SntV1' },
  });
};
