import React, { Fragment, useEffect, useMemo } from 'react';
import { FormFieldT2, FormInputT2, FormSelectT2 } from 'ui';
import { useLocale } from 'services/l10n';
import { getDirectionOptions } from 'common/helpers';
import { useFormikContext } from 'formik';

type Props = {};

export const EcontractBoardFilter: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const directionOptions = useMemo(() => getDirectionOptions(locale, false), [locale]);

  const { values, setFieldValue } = useFormikContext<any>();

  useEffect(() => {
    if (values.registrationNumber) {
      setFieldValue('registrationNumber', '');
    }
  }, [values.direction]); // eslint-disable-line

  return (
    <Fragment>
      <FormFieldT2 name="direction" style={{ width: '200px' }}>
        <FormSelectT2 options={directionOptions} hideSelectedOptions />
      </FormFieldT2>
      <FormFieldT2 style={{ width: '360px', flexGrow: 1 }} name="registrationNumber" noMargin>
        <FormInputT2 placeholder={loc('c.econtract.regNumberPlaceholder')} icon="search" debounceTimer={500} />
      </FormFieldT2>
    </Fragment>
  );
};
