import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './NavigationItem.module.scss';
import { Link } from 'react-router-dom';
import { SvgIcon } from 'ui/common/elements/svg-icon';
import { NavigationSubItem } from './NavigationSubItem';
import { UITheme } from 'ui/common/types';
import { MenuItem } from './MenuItem';

type Props = {
  className?: string;
  item: MenuItem;
  minimized?: boolean;
  theme?: UITheme;
};

export const NavigationItem: React.FC<Props> = ({ className, item, minimized, theme = 'T1' }) => {
  const { label, iconName, iconComp: IconComp, link, externalLink, childrenItems, childrenDirUp, onClick } = item;

  const content = () => (
    <Fragment>
      <span className={s.iconContainer}>
        {IconComp ? (
          <SvgIcon className={s.icon}>
            <IconComp />
          </SvgIcon>
        ) : (
          <SvgIcon name={iconName} className={s.icon} />
        )}
      </span>
      <span className={s.text}>{label}</span>
      {!!childrenItems?.length && <SvgIcon name="chevron" className={s.chevron} />}
    </Fragment>
  );

  return (
    <div
      className={clsx(className, s.wrapper, s[theme], {
        [s.minimized]: minimized,
      })}
    >
      {link ? (
        externalLink ? (
          <a href={link} className={s.link}>
            {content()}
          </a>
        ) : (
          <Link to={link} className={s.link}>
            {content()}
          </Link>
        )
      ) : (
        <div className={s.link} onClick={onClick}>
          {content()}
        </div>
      )}
      {!!childrenItems?.length && (
        <div
          className={clsx(s.subMenu, {
            [s.directionUp]: childrenDirUp,
          })}
        >
          {childrenItems.map((item) => (
            <NavigationSubItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
};
