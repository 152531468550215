export enum EcontractStatus {
  /**
   * Документ сохранен в виде черновика
   */
  DRAFT = 'DRAFT',

  /**
   * ЭД импортирован пользователем вручную
   */
  IMPORTED = 'IMPORTED',

  /**
   * Документ зарегистрирован в Системе
   */
  CREATED = 'CREATED',

  /**
   * Документ принят в Систему и находитсчя на обработке
   */
  IN_PROCESSING = 'IN_PROCESSING',

  /**
   * Обработан
   */
  PROCESSED = 'PROCESSED',

  /**
   * Документ ожидает подписания участниками инициирующей стороны
   */
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',

  /**
   * Документ не просмотрен ни одним участником второй стороны
   */
  NOT_VIEWED = 'NOT_VIEWED',

  /**
   * Документ просмотрен частью участников второй стороны
   */
  PARTIALLY_VIEWED = 'PARTIALLY_VIEWED',

  /**
   * Документ просмотрен всеми участниками второй стороны
   */
  VIEWED = 'VIEWED',

  /**
   * Документ утвержден частью учатсников второй стороны
   */
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',

  /**
   * Документ утвержден всеми участниками второй стороны
   */
  APPROVED = 'APPROVED',

  /**
   * В процессе расторжения
   */
  IN_PROCESS_OF_TERMINATION = 'IN_PROCESS_OF_TERMINATION',

  /**
   * Расторжение подтверждено частью участников второй стороны
   */
  PARTIALLY_TERMINATED = 'PARTIALLY_TERMINATED',

  /**
   * Документ расторгнут в одностороннем порядке
   */
  TERMINATED_UNILATERALLY = 'TERMINATED_UNILATERALLY',

  /**
   * Документ расторгнут по согласованию со всеми участниками
   */
  TERMINATED = 'TERMINATED',

  /**
   * Закрыт по истечении срока действия договора
   */
  CLOSED = 'CLOSED',

  /**
   * Отклонен участниками второй стороны
   */
  DECLINED = 'DECLINED',

  /**
   * Документ отозван одним из участников инициирущей стороны
   */
  REVOKED = 'REVOKED',

  /**
   * Документ не прошёл проверки
   */
  FAILED = 'FAILED',
}

export const ECONTRACT_STATUS_CLASSES: { [key in EcontractStatus]?: string } = {
  [EcontractStatus.DRAFT]: 'statusColorGray',
  [EcontractStatus.IMPORTED]: 'statusColorGray',
  [EcontractStatus.CREATED]: 'statusColorYellow',
  [EcontractStatus.IN_PROCESSING]: 'statusColorYellow',
  [EcontractStatus.WAITING_FOR_CONFIRMATION]: 'statusColorYellow',
  [EcontractStatus.NOT_VIEWED]: 'statusColorYellow',
  [EcontractStatus.PARTIALLY_VIEWED]: 'statusColorYellow',
  [EcontractStatus.VIEWED]: 'statusColorGreen',
  [EcontractStatus.PARTIALLY_APPROVED]: 'statusColorYellow',
  [EcontractStatus.APPROVED]: 'statusColorGreen',
  [EcontractStatus.IN_PROCESS_OF_TERMINATION]: 'statusColorYellow',
  [EcontractStatus.PARTIALLY_TERMINATED]: 'statusColorBlack',
  [EcontractStatus.TERMINATED_UNILATERALLY]: 'statusColorBlack',
  [EcontractStatus.TERMINATED]: 'statusColorBlack',
  [EcontractStatus.CLOSED]: 'statusColorBlack',
  [EcontractStatus.DECLINED]: 'statusColorRed',
  [EcontractStatus.REVOKED]: 'statusColorBlack',
  [EcontractStatus.FAILED]: 'statusColorRed',
};

export const ECONTRACT_FILTER_OUTBOUND_STATUSES = [
  EcontractStatus.NOT_VIEWED,
  EcontractStatus.PARTIALLY_VIEWED,
  EcontractStatus.VIEWED,
  EcontractStatus.REVOKED,
  EcontractStatus.PARTIALLY_APPROVED,
  EcontractStatus.APPROVED,
  EcontractStatus.DECLINED,
  EcontractStatus.TERMINATED,
  EcontractStatus.CLOSED,
  EcontractStatus.IN_PROCESS_OF_TERMINATION,
  EcontractStatus.TERMINATED_UNILATERALLY,
];

export const ECONTRACT_FILTER_IN_WORK_STATUSES = [
  EcontractStatus.WAITING_FOR_CONFIRMATION,
  EcontractStatus.FAILED,
  EcontractStatus.DRAFT,
  EcontractStatus.IMPORTED,
];
