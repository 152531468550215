export enum ASSURANCE_CORRIDOR {
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  YELLOW = 'YELLOW',
  RED = 'RED',
}

export enum AssuranceStatus {
  /**
   * В обработке
   */
  IN_PROCESSING = 'IN_PROCESSING',

  /**
   * Заверено ИС ЭСФ
   */
  ASSURED_BY_ESF = 'ASSURED_BY_ESF',

  /**
   * Отправлено на проверку в ОГД
   */
  SEND_FOR_VERIFICATION = 'SEND_FOR_VERIFICATION',

  /**
   * На рассмотрении в ОГД
   */
  UNDER_CONSIDERATION = 'UNDER_CONSIDERATION',

  /**
   * Заверено ОГД после проверки
   */
  ASSURED_AFTER_VERIFICATION = 'ASSURED_AFTER_VERIFICATION',

  /**
   * Отказано в заверении
   */
  DENIED_ASSURANCE = 'DENIED_ASSURANCE',

  /**
   * Аннулировано
   */
  CANCELED = 'CANCELED',
}

/**
 * Значение Активности (СНТ и ЭСФ) для Статусов ПП
 * true - "Действующий"
 * false - "Недействующий"
 * undefined - не используется
 */
export const DOC_ACTIVITY: { [key in AssuranceStatus]: boolean } = {
  [AssuranceStatus.IN_PROCESSING]: true,
  [AssuranceStatus.ASSURED_BY_ESF]: true,
  [AssuranceStatus.SEND_FOR_VERIFICATION]: true,
  [AssuranceStatus.UNDER_CONSIDERATION]: true,
  [AssuranceStatus.ASSURED_AFTER_VERIFICATION]: true,
  [AssuranceStatus.DENIED_ASSURANCE]: false,
  [AssuranceStatus.CANCELED]: false,
};
