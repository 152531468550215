import React from 'react';
import clsx from 'clsx';
import s from './PassportVerifyPage.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { Button, FormField, FormInput, FormUploader, FormValueProps } from 'ui/common';
import { useLocale } from 'services/l10n';
import { clearEmptyFields } from 'common/utils';
import { solveFormErrors } from 'common/form/helpers';

const initialValues: any = {};

const schema = yup.object({});

type Props = {
  className?: string;
  onSubmit(values: any): Promise<any>;
};

export const PassportVerifyForm: React.FC<Props> = ({ className, onSubmit }) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitHandler = async (values: any, formHelpers: FormikHelpers<any>) => {
    try {
      await onSubmit(clearEmptyFields(values));
    } catch (error) {
      formHelpers.setErrors(solveFormErrors(error));
    }
    formHelpers.setSubmitting(false);
  };

  const onFileChange = ({ name, form, value }: FormValueProps<File | File[]>) => {
    form.setFieldValue(name, value);
    form.setFieldValue('passportId', '');
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
      {({ values, isSubmitting }) => {
        return (
          <Form className={clsx(className)}>
            <FormField name="passportId" label={loc('common.labels.documentId')} fast={false}>
              <FormInput fullWidth={false} disabled={!!values.file} maxLength={16} />
            </FormField>

            <FormField name="file">
              <FormUploader
                className={s.uploader}
                label={loc('passport.choseQR')}
                fileAppearance="image"
                onValueChange={onFileChange}
              />
            </FormField>

            <Button
              className={s.submitButton}
              type="submit"
              disabled={!(values.passportId || values.file) || isSubmitting}
            >
              {loc('passport.verify')}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
