import React from 'react';
import clsx from 'clsx';
import s from './BioRiskyHistoryModal.module.scss';
import { ModalContent, ModalHeader } from 'services/modal';
import { Loader, Table, TableColumn } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { queryAdminRiskyTaxpayerHistory } from '../api';

const getRowId = (row: any) => row?.id;

type Props = ModalChildrenProps & {
  className?: string;
  tin: string;
};

const getColumns = ({ loc, choose }: LocaleStore): Array<TableColumn> => [
  {
    name: 'riskCategory',
    label: loc('bioRisky.riskCategory'),
    getContent: (row) => (row.riskCategory ? loc(`bioRisky.riskCategory.${row.riskCategory}`) : null),
    style: {
      width: '25%',
    },
  },
  {
    name: 'changeDate',
    label: loc('bioRisky.changeDate'),
    style: {
      width: '25%',
    },
  },
  {
    name: 'isActive',
    label: loc('bioRisky.status'),
    getContent: (row) => (typeof row.isActive === 'boolean' ? loc(`bioRisky.status.${row.isActive}`) : null),
    style: {
      width: '25%',
    },
  },
  {
    name: 'adminIinAndFio',
    label: loc('bioRisky.adminIinAndFio'),
    getContent: (row) => `(${row.armUserLogin}) ${row.armUserFio}`,
    style: {
      width: '25%',
    },
  },
];

export const BioRiskyHistoryModal: React.FC<Props> = ({ className, tin }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data } = useDataQuery(queryAdminRiskyTaxpayerHistory, { tin });

  const columns = getColumns(locale);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('bioRisky.history')}</ModalHeader>
      <ModalContent className={s.content}>
        {!data ? <Loader /> : <Table columns={columns} rows={data} variant="clean" getRowId={getRowId} />}
      </ModalContent>
    </div>
  );
};
