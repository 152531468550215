import { UserStore } from 'common/stores/user-store';
import { EsfDirection, EsfStatus, EsfType, resolveSellerTins, solveEsfDirection } from 'features/esf';

type SelectionState = {
  status?: EsfStatus;
  type?: EsfType;
  sellerTins: Array<string[]>;
};

const selectionCache: {
  selection?: Array<any>;
  state: SelectionState;
} = { selection: undefined, state: { sellerTins: [] } };

// создаем состояние для каждой выборки в журнале (статус, тип, бины продавца для проверки направления),
// сохраняем в кеш, чтобы не расчитывать для каждой кнопки и условия
// Сохраняем (статус, тип) первого элемента, или undefined если статусы в списке разные, чтоб не было разношерстных статусов СФ
// в sellerTins сохраняем бины продавцов, для вычисления направления ЭСФ
function getState(selection: Array<any>) {
  if (selectionCache.selection === selection) {
    return selectionCache.state;
  } else {
    const state: SelectionState = { sellerTins: [] };
    selection.forEach((item, index) => {
      const status = item.invoiceStatus;
      const type = item.invoice?.invoiceType;
      state.sellerTins[index] = resolveSellerTins(item);
      if (index === 0) {
        state.status = status;
        state.type = type;
      } else {
        if (status !== state.status) {
          state.status = undefined;
        }
        if (type !== state.type) {
          state.type = undefined;
        }
      }
    });
    selectionCache.selection = selection;
    selectionCache.state = state;
    console.log('new state', selectionCache.state);
    return selectionCache.state;
  }
}

/**
 * Возвращает статус первого элемента, или undefined если статусы в списке разные, чтоб не было разношерстных статусов СФ
 */
export const getSelectionStatus = (selection: Array<any>): any => {
  const state = getState(selection);
  // console.log(state?.status);
  return state?.status;
};

/**
 * Возвращает тип первого элемента, или undefined если типы в списке разные, чтоб не было разношерстных типов СФ
 */
export const getSelectionType = (selection: Array<any>): any => {
  const state = getState(selection);
  return state?.type;
};

/**
 * Возвращает направление первого элемента, или undefined если направления в списке разные, чтоб не было разношерстных типов СФ
 */
export const getSelectionDirection = (selection: Array<any>, user: UserStore): any => {
  const state = getState(selection);
  let resDirection: EsfDirection | undefined = undefined;
  selection.forEach((item, index) => {
    let direction = solveEsfDirection(item, user);
    state.sellerTins[index] = resolveSellerTins(item);
    if (index === 0) {
      resDirection = direction;
    } else {
      if (resDirection !== direction) {
        resDirection = undefined;
      }
    }
  });
  console.log(resDirection);
  return resDirection;
};
