import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from '../PassportDocument.module.scss';
import { PACell } from './PACell';
import { useLocale } from 'services/l10n';
import { CodeValueCountry, CodeValueMeasureUnit } from 'common/components';
import { Accordion } from 'ui';
import { numberWithSpaces, toFixedFloat } from 'common/utils';

/**
 * коды таможенной пошлины, специальной, антидемпинговой, компенсационной пошлинам.
 */
const PAYMENT_DETAILS_CODES = ['ИУ', 'ВУ', 'УМ', 'ВВ'];

type Props = {
  className?: string;
  data: any;
};

/**
 * Приложение 2. Декларация на товары
 */
export const PassportAttachmentDt: React.FC<Props> = ({ className, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, s.attachment)}>
      {Array.isArray(data) &&
        data.map((gdt, index) => (
          <div key={index} className={s.attInner}>
            <div className={clsx(s.attInnerHeader, s.gdtHeader)}>
              {loc('gtd.cargoPlacesAndGoodsDesc')}
              <PACell
                span={1}
                label={loc('gtd.sellerCountry')}
                value={() => <CodeValueCountry code={gdt.tradeCountryCode} capitalize />}
              />
              <PACell span={1} label={loc('gtd.registrationNumber')} value={gdt.declarationNum} />
            </div>
            <div className={clsx(s.gdtBody)}>
              {Array.isArray(gdt.goodsList) &&
                gdt.goodsList.map((goods: any, index: any) => {
                  const detailsPayments: Array<any> | null = Array.isArray(goods.payments)
                    ? goods.payments.filter((payment: any) => PAYMENT_DETAILS_CODES.includes(payment.paymentCode))
                    : null;
                  const detailsPaymentsTotal =
                    detailsPayments && detailsPayments.reduce((acc, payment) => acc + Number(payment.paymentAmount), 0);

                  return (
                    <Accordion
                      key={index}
                      className={s.accordion}
                      iconClassName={s.accordionIcon}
                      headerClassName={s.accordionHeader}
                      openedClassName={s.accordionOpened}
                      renderHeader={() => <span className={s.gdtFullDesc}>{goods.fullDescription}</span>}
                      initialOpened={false}
                    >
                      <div className={s.attInnerBody}>
                        <div className={clsx(s.attTable)}>
                          <div className={s.attGrid} style={{ gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <PACell span={1} label={loc('gtd.tnvedCode')} value={goods.tnvedCode} />
                            <PACell span={1} label={loc('gtd.gross')} value={goods.grossWeightQuantity} />
                            <PACell span={1} label={loc('gtd.originCountryCode')} value={goods.originCountryCode} />

                            <PACell
                              span={1}
                              label={loc('gtd.preference')}
                              value={[
                                goods.preference?.customsTax,
                                goods.preference?.customsDuty,
                                goods.preference?.excise,
                                goods.preference?.rate,
                              ]
                                .filter(Boolean)
                                .join('')}
                            />
                            <PACell span={1} label={loc('gtd.net')} value={goods.netWeightQuantity} />
                            <PACell
                              span={1}
                              label={loc('gtd.procedure')}
                              value={`${goods.procedure?.mainCustomsModeCode || ''}${
                                goods.procedure?.precedingCustomsModeCode || ''
                              } | ${goods.procedure?.goodsTransferFeature || ''}`}
                            />
                          </div>

                          <div className={s.attGrid} style={{ gridTemplateColumns: '1fr 1fr' }}>
                            <PACell
                              span={1}
                              label={loc('gtd.extraMeasureUnit')}
                              value={<CodeValueMeasureUnit code={goods.measureUnitQualifierCode} />}
                            />
                            <PACell
                              span={1}
                              label={loc('gtd.quantityInExtraMeasureUnit')}
                              value={goods.goodsQuantity}
                            />

                            <PACell
                              span={1}
                              label={loc('gtd.customsCost')}
                              value={numberWithSpaces(goods.customsCost)}
                            />
                            <PACell
                              span={1}
                              label={loc('gtd.statisticsCost')}
                              value={numberWithSpaces(goods.statisticalCost)}
                            />
                          </div>

                          <div
                            className={s.attGrid}
                            style={{ gridTemplateColumns: '148fr 70fr 235fr 235fr 235fr 70fr' }}
                          >
                            <PACell span={1} label={loc('gtd.additionalInfo')} noValue />
                            <PACell span={5}>
                              {Array.isArray(goods.presentedDocuments) &&
                                goods.presentedDocuments.map((doc: any, index: number) => (
                                  <div key={index} className={s.gdtDocRow}>
                                    <span>{doc.modeCode}</span>
                                    <span>{doc.number}</span>
                                    <span>{doc.date}</span>
                                    <span>{doc.name}</span>
                                  </div>
                                ))}
                            </PACell>

                            {Array.isArray(goods.payments) && (
                              <Fragment>
                                <PACell span={1} spanRows={goods.payments.length + 2} label={loc('gtd.cop')} noValue />

                                <PACell span={1} label={loc('gtd.cop.typeCode')} noValue bb />
                                <PACell span={1} label={loc('gtd.cop.baseOfPayments')} noValue textAlign="right" bb />
                                <PACell span={1} label={loc('gtd.cop.rate')} noValue textAlign="right" bb />
                                <PACell span={1} label={loc('gtd.cop.sum')} noValue textAlign="right" bb />
                                <PACell span={1} label={loc('gtd.cop.spCode')} noValue bb />

                                {goods.payments.map((payment: any, index: any) => (
                                  <Fragment key={index}>
                                    <PACell span={1} value={payment.paymentModeCode} bt={false} />
                                    <PACell
                                      span={1}
                                      value={numberWithSpaces(payment.taxBase)}
                                      bt={false}
                                      textAlign="right"
                                    />
                                    <PACell span={1} value={payment.rate} bt={false} textAlign="right" />
                                    <PACell
                                      span={1}
                                      value={numberWithSpaces(payment.paymentAmount)}
                                      bt={false}
                                      textAlign="right"
                                    />
                                    <PACell span={1} value={payment.paymentCode} bt={false} />
                                  </Fragment>
                                ))}
                                <PACell span={1} noValue bt={false} />
                                <PACell span={1} noValue bt={false} />
                                <PACell span={1} noValue bt={false} />
                                <PACell
                                  span={1}
                                  value={`${loc('common.labels.total')} ${numberWithSpaces(goods.paymentAmountSum)}`}
                                  bt={false}
                                  textAlign="right"
                                />
                                <PACell span={1} noValue bt={false} />
                              </Fragment>
                            )}

                            {Array.isArray(detailsPayments) && (
                              <Fragment>
                                <PACell
                                  span={4}
                                  spanRows={detailsPayments.length + 2}
                                  label={loc('gtd.calculationDetails')}
                                  noValue
                                />
                                <PACell span={1} label={loc('gtd.cop.sum')} noValue bb textAlign="right" />
                                <PACell span={1} label={loc('gtd.cop.spCode')} noValue bb />

                                {detailsPayments.map((payment: any, index) => (
                                  <Fragment key={index}>
                                    <PACell
                                      span={1}
                                      value={numberWithSpaces(payment.paymentAmount)}
                                      bt={false}
                                      textAlign="right"
                                    />
                                    <PACell span={1} value={payment.paymentCode} bt={false} />
                                  </Fragment>
                                ))}
                                <PACell
                                  span={1}
                                  value={`${loc('common.labels.total')} ${numberWithSpaces(
                                    toFixedFloat(detailsPaymentsTotal, 0)
                                  )}`}
                                  bt={false}
                                  textAlign="right"
                                />
                                <PACell span={1} noValue bt={false} />
                              </Fragment>
                            )}
                          </div>
                        </div>

                        {!!goods.changes && (
                          <Fragment>
                            <h3>{loc('gtd.historyChanges')}</h3>

                            {Object.keys(goods.changes)
                              .sort()
                              .map((key: any) => [key, goods.changes[key]])
                              .map(
                                ([key, changeList]) =>
                                  Array.isArray(changeList) && (
                                    <div key={key} className={s.gdtChanges}>
                                      <div className={s.gdtChangesRow}>
                                        <div>
                                          <span className={s.attCellLabel}>{loc('gtd.productNumber')}</span>
                                          <span className={s.attCellValue}>{goods.rowNum}</span>
                                        </div>
                                        <div>
                                          <span className={s.attCellLabel}>{loc('gtd.changeDate')}</span>
                                          <span className={s.attCellValue}>{gdt.versionDocumentDateMap?.[key]}</span>
                                        </div>
                                      </div>

                                      {changeList
                                        .filter((change) => change.valueChanged)
                                        .map((change, index) => (
                                          <div key={index} className={s.gdtChangesRow}>
                                            <div>
                                              <span className={s.attCellLabel}>
                                                {CHANGES_LABELS_LOCALES[change.fieldName]
                                                  ? loc(CHANGES_LABELS_LOCALES[change.fieldName])
                                                  : change.fieldName}
                                              </span>
                                              <span className={s.attCellValue}>
                                                <del>{change.previousValue}</del>
                                                {change.fieldName === 'fullDescription' ? (
                                                  <br />
                                                ) : (
                                                  <span>&nbsp;&nbsp;&nbsp;</span>
                                                )}
                                                {change.currentValue}
                                              </span>
                                            </div>
                                          </div>
                                        ))}
                                    </div>
                                  )
                              )}
                          </Fragment>
                        )}
                      </div>
                    </Accordion>
                  );
                })}
            </div>
          </div>
        ))}
    </div>
  );
};

const CHANGES_LABELS_LOCALES: any = {
  tnvedCode: 'gtd.tnvedCode',
  originCountryCode: 'gtd.originCountryCode',
  grossWeightQuantity: 'gtd.gross',
  netWeightQuantity: 'gtd.net',
  goodsQuantity: 'gtd.quantityInExtraMeasureUnit',
  measureUnitQualifierCode: 'gtd.extraMeasureUnit',
  'preference.customsTax': '',
  'preference.customsDuty': '',
  'preference.excise': '',
  'preference.rate': '',
  'procedure.mainCustomsModeCode': 'gtd.procedure',
  'procedure.precedingCustomsModeCode': '',
  'procedure.goodsTransferFeature': '',
  customsCost: 'gtd.customsCost',
  statisticalCost: 'gtd.statisticsCost',
  'payments.paymentModeCode': 'gtd.cop.typeCode',
  'payments.taxBase': 'gtd.cop.baseOfPayments',
  'payments.rate': 'gtd.cop.rate',
  'payments.paymentAmount': 'gtd.cop.sum',
  'payments.paymentCode': 'gtd.cop.spCode',
  paymentAmountSum: 'common.labels.total',
  fullDescription: 'gtd.cargoPlacesAndGoodsDesc',
};
