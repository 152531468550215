import React from 'react';
import clsx from 'clsx';
import s from './EaecCodeGeneratonModal.module.scss';
import { ModalChildrenProps, ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { Button, FormField, FormInput } from 'ui';
import { Form, Formik, FormikHelpers } from 'formik';
import { FormCountrySelect } from 'common/components';
import { mutationAdminEaecUserGenerate } from '../code-generation/api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = ModalChildrenProps & {
  className?: string;
};

export const EaecCodeGeneratonModal: React.FC<Props> = ({ className, onClose }) => {
  const locale = useLocale();
  const { loc } = locale;

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    try {
      await mutationAdminEaecUserGenerate({ body: values });
      typeof onClose === 'function' && onClose(true);
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      form.setSubmitting(false);
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('eaec.generation')}</ModalHeader>
      <Formik initialValues={{ userCount: 1, countryCode: '' }} onSubmit={onSubmit}>
        {({ values, isSubmitting }) => (
          <Form>
            <ModalContent className={s.content}>
              <FormField name="countryCode" label={loc('common.labels.country')}>
                <FormCountrySelect onlyEaec />
              </FormField>
              <FormField name="userCount" label={loc('eaec.codes')}>
                <FormInput type="number" preProccessValue={preProccessUserCount} />
              </FormField>
            </ModalContent>
            <ModalFooter>
              <Button type="submit" disabled={!(values.countryCode && values.userCount)} loading={isSubmitting}>
                {loc('eaec.generateXlsx')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const preProccessUserCount = (value: string) => {
  const minValue = 1;
  const maxValue = 1000;
  const num = parseInt(value.replace(/[^0-9-]/g, ''));
  return String(num < minValue ? minValue : num > maxValue ? maxValue : num);
  // return String(num);
};
