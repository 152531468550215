import { API_URL } from 'common/constants';
import { clearEmptyFieldsDeep } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryAdminAssuranceTableReport: DataQueryFunction = (variables: any) => {
  const { filter, format, ids } = variables || {};

  const criteria = { passportIds: ids, ...filter };
  if (criteria.statuses) {
    criteria.statuses = [criteria.statuses];
  }
  if (criteria.corridors) {
    criteria.corridors = [criteria.corridors];
  }

  return downloadFile({
    url: `${API_URL}/admin/assurance/tableReport`,
    method: 'GET',
    params: {
      criteria: JSON.stringify(clearEmptyFieldsDeep(criteria)),
      format,
    },
  });
};
