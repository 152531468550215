import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormDataDeep } from 'rest/helpers';
import { clearEmptyFieldsDeep, clearPrivateProps } from 'common/utils';

export const mutationRegistrationV2Total: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  const formData = convertToFormDataDeep(clearEmptyFieldsDeep(clearPrivateProps(body)));

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1500);
  //   throw {
  //     success: false,
  //     errors: [
  //       {
  //         property: 'individualAgreement',
  //         text: 'Регистрация адвоката/нотариуса недоступна для лиц, имеющих активный регистрационный учет в качестве ИП',
  //       },
  //       { property: 'notaryAgreement', text: 'Налогоплательщик не имеет регистрации соответствующего типа' },
  //       {
  //         property: 'physicalAgreement',
  //         text: 'Для подписания соглашения используйте сертификат подписи текущего физического лица',
  //       },
  //       {
  //         property: 'privateAgreement',
  //         text: 'ИП ЧЗЧП текущего физического лица, ошибка',
  //       },
  //       {
  //         property: 'privateAgreement.certificate',
  //         text: 'Сертификат подписи текущего физического лица отозван',
  //       },
  //     ],
  //   };
  // }

  const res = await httpClient.post('/registrationV2/total', formData);
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
