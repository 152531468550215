import { action, makeObservable, observable } from 'mobx';
import {
  queryMessagesFilter,
  queryMessagesGetInbound,
  queryMessagesGetOutbound,
  queryMessagesNewInboundCount,
} from 'rest';

/**
 * Стор функционала "Сообщения"
 */
export class MessagesStore {
  incomingMessages: Array<any> | null = null;
  outcomingMessages: Array<any> | null = null;
  filteredMessages: Array<any> | null = null;
  countOfUnread: number = 0;

  constructor() {
    makeObservable(this, {
      incomingMessages: observable,
      outcomingMessages: observable,
      filteredMessages: observable,
      countOfUnread: observable,
      fetchIncomingMessages: action,
      fetchOutcomingMessages: action,
      fetchFilteredMessages: action,
    });
  }

  fetchIncomingMessages = async () => {
    const { data } = await queryMessagesGetInbound();
    if (Array.isArray(data)) {
      this.incomingMessages = data;
    }
    this.countOfUnread = 0;
    return data;
  };

  fetchOutcomingMessages = async () => {
    const { data } = await queryMessagesGetOutbound();
    if (Array.isArray(data)) {
      this.outcomingMessages = data;
    }
    return data;
  };

  fetchFilteredMessages = async (filter: { [key: string]: string }) => {
    const { data } = await queryMessagesFilter({ filter });
    if (Array.isArray(data)) {
      this.filteredMessages = data;
    }
    return data;
  };

  fetchUnreadCounter = async () => {
    const { data } = await queryMessagesNewInboundCount();
    const count = parseInt(data);
    if (!isNaN(count)) {
      this.countOfUnread = count;
    }
    return data;
  };
}
