import React, { Fragment, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { ImportFromFileModal, Journal, JournalButton } from 'common/components';
import { getBioRiskyJournalColumns } from './getBioRiskyJournalColumns';
import { BioRiskyJournalFilters } from './BioRiskyJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { rowsOptionsVariant3 } from 'ui';
import { Modal } from 'services/modal';
import { BioRiskyEditModal } from '../bio-risky-edit-modal/BioRiskyEditModal';
import {
  queryAdminRiskyTaxpayerReport,
  queryAdminRiskyTaxpayerSearch,
  mutationAdminRiskyTaxpayerUploadFile,
  mutationAdminRiskyTaxpayerImportFromFile,
  queryAdminRiskyTaxpayerImportResult,
} from '../api';
import { formatFromDate } from 'services/date-time';
import { useStore } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';
import { BioRiskyAddModal } from '../bio-risky-add/BioRiskyAddModal';
import { BioRiskyUsers } from '../bio-risky-users/BioRiskyUsers';
import { BioRiskyHistoryModal } from '../bio-risky-history-modal/BioRiskyHistoryModal';
import { getResourceUrl } from 'common/helpers';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.id;

const filterStorageKey = 'BioRiskyTaxpaers';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, Справочники -> "Рисковые НП для прохождения биометрии"
 * arm/bio-risky
 */
export const BioRiskyJournal: React.FC<Props> = ({ className }) => {
  const { locale, user } = useStore();
  const { loc } = locale;

  const [taxpayerModal, setTaxpayerModal] = useState<undefined | { show: 'add' } | { show: 'edit' | 'show'; row: any }>(
    undefined
  );
  const [historyModal, setHistoryModal] = useState<
    | undefined
    | {
        show: true;
        row: any;
      }
  >(undefined);
  const [usersPage, setUsersPage] = useState<undefined | { opened: true; row: any }>(undefined);
  const [importModal, setImportModal] = useState<boolean>(false);

  const columns = useMemo(() => getBioRiskyJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(
    queryAdminRiskyTaxpayerSearch,
    {
      filter: SessionStorageService.get(filterStorageKey) || {
        createDateFrom: formatFromDate(new Date()),
        createDateTo: formatFromDate(new Date()),
      },
      pagination: initialPagination,
    },
    { preProcessParams: (params) => ({ ...params, filter: preProcessFilter(params.filter) }) }
  );

  const exportJournal = async (selection: Array<any>, format: string) => {
    const { tinList, ...rest } = preProcessFilter(queryProps.filter);
    try {
      const res = await queryAdminRiskyTaxpayerReport({
        filter: { ...rest, tinList: selection.length ? selection.map((s) => s.tin) : tinList },
        format,
      });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.add'),
      onClick: () => setTaxpayerModal({ show: 'add' }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_EDIT),
    },
    {
      id: 2,
      label: loc('common.button.import'),
      onClick: () => setImportModal(true),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_EDIT),
    },
    {
      id: 3,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal(selection, 'XLSX'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.edit'),
      onClick: (selected) => setTaxpayerModal({ show: 'edit', row: selected[0] }),
      disabled: () => !user.checkAdminPermission(ADMIN_PERMISSION.BIOMETRICS_RISKY_TAXPAYER_EDIT),
    },
    {
      id: 3,
      label: loc('bioRisky.users'),
      onClick: (selected) => setUsersPage({ opened: true, row: selected[0] }),
    },
    {
      id: 4,
      label: loc('bioRisky.history'),
      onClick: (selected) => setHistoryModal({ show: true, row: selected[0] }),
    },
  ];

  const onCloseModal = (needRefetch?: boolean) => {
    setTaxpayerModal(undefined);
    if (needRefetch) {
      queryProps.refetch();
    }
  };

  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('admin.menu.BIO_RISKY')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={BioRiskyJournalFilters}
        filterInitialOpened
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        paginationRowsOptions={rowsOptionsVariant3}
        hidden={usersPage?.opened}
      />
      {usersPage?.opened ? <BioRiskyUsers txpData={usersPage.row} onClose={() => setUsersPage(undefined)} /> : null}
      <Modal isOpen={taxpayerModal?.show === 'add'} onClose={onCloseModal} center>
        <BioRiskyAddModal />
      </Modal>
      {taxpayerModal?.show === 'edit' && (
        <Modal isOpen={true} onClose={onCloseModal} center>
          <BioRiskyEditModal data={taxpayerModal.row} />
        </Modal>
      )}
      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={mutationAdminRiskyTaxpayerImportFromFile}
          uploadFile={async (files: File[]) => {
            await mutationAdminRiskyTaxpayerUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryAdminRiskyTaxpayerImportResult}
          exampleLink={getResourceUrl('/data/downloads/ImportBIN.xlsx')}
        />
      </Modal>

      <Modal isOpen={!!historyModal?.show} onClose={() => setHistoryModal(undefined)} scrollLock={false}>
        <BioRiskyHistoryModal tin={historyModal?.row?.tin} />
      </Modal>
    </Fragment>
  );
};

const preProcessFilter = ({ iin, tinList, ...rest }: any) => ({
  ...rest,
  tinList: [iin, tinList].filter(Boolean).flat(),
});
