import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryAdminAssuranceReport: DataQueryFunction = (variables: any) => {
  const { ids, format } = variables || {};

  return downloadFile({
    url: `${API_URL}/admin/assurance/report`,
    method: 'GET',
    params: { passportIds: ids, format },
  });
};
