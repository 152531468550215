import { API_URL } from 'common/constants';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryAssuranceQr: DataQueryFunction = (variables: any) => {
  const { regNumber } = variables || {};

  return downloadFile({
    url: `${API_URL}/assurance/qr/${regNumber}`,
    method: 'GET',
  });
};
