import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useLocale } from 'services/l10n/useLocale';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { Journal, JournalButton } from 'common/components';
import { getEaecMonitoringJournalColumns } from './getEaecMonitoringJournalColumns';
import { EaecMonitoringJournalFilters } from './EaecMonitoringJournalFilters';
import { SessionStorageService } from 'services/storage/StorageService';
import { EaecMonitoringProducts } from '../eaec-monitoring-products/EaecMonitoringProducts';
import { Loader } from 'ui';
import { Modal } from 'services/modal';
import {
  queryAdminEecMonitoringGenerateReport,
  queryAdminEecMonitoringSearch,
  queryEaecDownloadChainZip,
} from '../api';
import { EaecHistoryModal } from '../eaec-history/EaecHistoryModal';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row?.messageId;
const getDocId = (row: any) => row?.docId;

const filterStorageKey = 'EaecMonitoring';

type Props = {
  className?: string;
};

/**
 * Страница АРМ, журнал "Предоставление Комиссии сведений в целях осуществления
 * контроля и мониторинга исполнения Соглашения"
 * arm/eaec-monitoring
 */
export const EaecMonitoringJournal: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });

  const [modal, setModal] = useState<{
    show: boolean;
    row: any;
  }>({
    show: false,
    row: undefined,
  });

  const [exporting, setExporting] = useState(false);

  const columns = useMemo(() => getEaecMonitoringJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminEecMonitoringSearch, {
    filter:
      SessionStorageService.get(filterStorageKey) ||
      {
        // dateFrom: formatFromDate(new Date()),
        // dateTo: formatFromDate(new Date()),
      },
    pagination: initialPagination,
  });

  const exportJournal = async (format: string) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryAdminEecMonitoringGenerateReport({
          filter: queryProps.filter,
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  const exportSelectionXML = async (selection: Array<any>) => {
    if (!exporting) {
      setExporting(true);
      try {
        await queryEaecDownloadChainZip({
          docId: getDocId(selection[0]),
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      } finally {
        setExporting(false);
      }
    }
  };

  const headerButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.exportExcel'),
      onClick: (selection: Array<any>) => exportJournal('XLSX'),
    },
    {
      id: 2,
      label: loc('common.button.exportPdf'),
      onClick: (selection: Array<any>) => exportJournal('PDF'),
    },
  ];

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: (selection: Array<any>) => setNestedPage({ opened: true, row: selection[0] }),
    },
    {
      id: 2,
      label: loc('common.button.xml'),
      onClick: (selection: Array<any>) => exportSelectionXML(selection),
    },
    {
      id: 3,
      label: loc('common.button.history'),
      onClick: (selected) => setModal({ show: true, row: selected[0] }),
    },
  ];

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  const onCloseModal = () => setModal({ show: false, row: undefined });

  return (
    <Fragment>
      {exporting && <Loader overlay />}
      <Journal
        pageTitle={loc('agreements.title')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={EaecMonitoringJournalFilters}
        filterInitialOpened
        filterStorageKey={filterStorageKey}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
        hidden={nestedPage.opened}
      />

      {nestedPage.opened ? <EaecMonitoringProducts onClose={closeNestedPage} docId={getDocId(nestedPage.row)} /> : null}

      <Modal isOpen={modal.show} onClose={onCloseModal}>
        <EaecHistoryModal docId={getDocId(modal.row)} />
      </Modal>
    </Fragment>
  );
};
