import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';
import { Modal } from 'services/modal';
import { ArmInviteModal } from './arm-invite';
import { MessageForConfirmationModal } from './message-for-confirmation';
import { toJS } from 'mobx';
import { IsgoNotification } from 'features/isgo';
import { UnsignedAgreementModal } from 'features/registration';

type Props = {
  className?: string;
};

export const UserChecksModals: React.FC<Props> = observer(({ className }) => {
  const { userChecks, user } = useStore();

  console.log(toJS(userChecks.checksQueue), toJS(userChecks.currentCheckModal));

  return (
    <Fragment>
      {userChecks.currentCheckModal?.type === 'invite' && (
        <Modal isOpen={true} theme="T2" onClose={() => userChecks.nextCheck()} marginTop={100}>
          <ArmInviteModal
            inviteData={userChecks.currentCheckModal.data}
            onDone={() => {
              userChecks.setArmInvite(undefined);
              userChecks.nextCheck();
            }}
          />
        </Modal>
      )}

      {userChecks.currentCheckModal?.type === 'agreement' && (
        <Modal
          isOpen={true}
          theme="T2"
          scrollLock={false}
          onClose={user.currentUser?.currentProfileId === 'individual' ? undefined : () => userChecks.nextCheck()}
        >
          <UnsignedAgreementModal
            agreementData={userChecks.currentCheckModal.data}
            onDone={() => userChecks.nextCheck()}
          />
        </Modal>
      )}

      {/* {userChecks.currentCheckModal?.type === 'sntExpired' && (
        <Modal isOpen={true} theme="T2" scrollLock={false}>
          <SntExpiredModal sntData={userChecks.currentCheckModal.data} onDone={() => userChecks.nextCheck()} />
        </Modal>
      )} */}

      {userChecks.currentCheckModal?.type === 'isgoNotifications' && (
        <IsgoNotification notification={userChecks.currentCheckModal.data} onDone={() => userChecks.nextCheck()} />
      )}

      {userChecks.currentCheckModal?.type === 'messageForConfirmation' && (
        <Modal isOpen={true} theme="T2" center>
          <MessageForConfirmationModal
            message={userChecks.currentCheckModal.data}
            onDone={() => userChecks.nextCheck()}
          />
        </Modal>
      )}
    </Fragment>
  );
});
