import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './InputMUI.module.scss';
import { InputProps } from './types';
import { nanoid } from 'nanoid';
import { SvgIcon } from 'ui/common/elements';

export const InputMUI: React.FC<InputProps> = ({
  className,
  inputClassName,
  error,
  fullWidth = true,
  value,
  readOnly,
  output,
  theme = 'T1',
  icon,
  iconSide = 'right',
  iconClassName,
  onIconClick,
  suffix,
  suffixClassName,
  variant,
  placeholder,
  label,
  id,
  ...rest
}) => {
  const inputId = useMemo(() => id || nanoid(5), []); // eslint-disable-line

  return (
    <span
      className={clsx(s.wrapper, className, s[theme], icon ? s[`withIcon-${iconSide}`] : '', {
        [s.error]: error,
        [s.fullWidth]: fullWidth,
      })}
    >
      <input
        {...rest}
        id={inputId}
        className={clsx(inputClassName, s.input, s[theme], {
          'control-error': error,
          [s.hasValue]: !!value,
          [s.output]: output,
        })}
        value={value}
        readOnly={readOnly || output}
      />
      <label className={s.innerLabel} htmlFor={inputId}>
        {label}
      </label>
      {!!icon && (
        <span
          className={clsx(s.iconWrapper, {
            [s.clickable]: typeof onIconClick === 'function',
          })}
          onClick={typeof onIconClick === 'function' ? (e) => onIconClick(e, value, rest.name) : undefined}
        >
          <SvgIcon
            className={clsx(iconClassName, s.icon)}
            {...(typeof icon === 'string' ? { name: icon } : { component: icon })}
          />
        </span>
      )}
      {/* {suffix && <span className={clsx(s.suffix, suffixClassName)}>{suffix}</span>} */}
    </span>
  );
};
