import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './UserDetailsPage.module.scss';
import { Heading, PageTitle } from 'ui/common/elements';
import { useLocale } from 'services/l10n';
import { Accordion, Button, DataGrid } from 'ui/common';
import { ADMIN_PERMISSION_GROUPS, LEGACY_URL } from 'common/constants';
import { useUser } from 'common/hooks';
import { resolveTaxpayerFio } from 'common/helpers/taxpayer';

const columnStyles = [{ width: '400px' }, { width: '400px' }];

type Props = {
  className?: string;
};

export const UserDetailsPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const user = useUser();

  const userData = useMemo(() => {
    const { login, email, mobile, passportNum, issuedBy, issueDate, taxpayer } = user.currentUser || {};
    return [
      [loc('common.labels.fioShort'), resolveTaxpayerFio(taxpayer, locale)],
      [loc('common.labels.loginIin'), login || ''],
      [loc('common.labels.email'), email || ''],
      [loc('common.labels.mobile'), mobile || ''],
      [loc('ud.passportNumber'), passportNum || ''],
      [loc('ud.passportDate'), issueDate || ''],
      [loc('ud.passportBy'), issuedBy || ''],
    ];
  }, [locale, loc, user]);

  const permissions = useMemo(
    () =>
      ADMIN_PERMISSION_GROUPS.map((group) => ({
        ...group,
        permissions: group.permissions.filter((permission) => user.checkAdminPermission(permission)),
      })).filter((g) => g.permissions.length),
    [user]
  );

  const navigateToChangePassword = () => {
    const changePasswordLink = user.isSuperUser()
      ? `${LEGACY_URL}/resetSuperUserPasswordRequest`
      : `${LEGACY_URL}/viewResetPasswordForm`;
    window.location.assign(changePasswordLink);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('ud.myReq')}</PageTitle>
      <div className={clsx(s.section, 'container')}>
        <Heading className={s.title} tag="h2">
          {loc('common.title.commonInfo')}
        </Heading>
        <DataGrid data={userData} columnStyles={columnStyles} />
      </div>
      <div className={clsx(s.section, s.passwordSection, 'container')}>
        <Button onClick={navigateToChangePassword}>{loc('ud.changePassword')}</Button>
      </div>
      <div className={clsx(s.section, 'container')}>
        <Heading className={s.title} tag="h2">
          {loc('ud.permissions')}
        </Heading>

        {permissions.map((group) => (
          <Accordion
            key={group.id}
            headerClassName={s.accHeader}
            renderHeader={() => <span className={s.groupLabel}>{loc(group.label)}</span>}
            animated
            initialOpened={false}
          >
            {group.permissions.map((permission: string) => {
              return (
                <div key={permission} className={s.permissonLabel}>
                  {loc(`admin.permission.${permission}`)}
                </div>
              );
            })}
          </Accordion>
        ))}
      </div>
    </div>
  );
};
