import React from 'react';
import clsx from 'clsx';
import s from './KkmStub.module.scss';
import { Heading, PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { useGlobalTheme } from 'common/hooks';

type Props = {
  className?: string;
};

export const KkmStub: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const theme = useGlobalTheme();

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle theme={theme}>{loc('kkm.journalTitle')}</PageTitle>
      <div className={s[`container-${theme}`]}>
        <Heading tag="h3" className={s.title}>
          {loc('kkm.stub.title')}
        </Heading>
        {/* <p className={s.text}>{loc('kkm.stub.text')}</p> */}
      </div>
    </div>
  );
};
