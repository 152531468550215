import { solveErrorMessage } from 'common/form/helpers';
import { useEffect, useRef, useState } from 'react';
import { Lang, useLocale } from 'services/l10n';
import { ToastService } from 'services/toasts';
import { useUser } from './useUser';
import { queryGetVersion } from 'rest';

export const useLangToggle = () => {
  const { updateSessionLang, setLang } = useLocale();
  const [loading, setLoading] = useState(false);
  const isMounted = useRef(true);
  const user = useUser();

  const changeLang = async (lang: Lang) => {
    if (user.isUserAuthed) {
      return changeSessionLang(lang);
    } else {
      try {
        await queryGetVersion({ lang });
        setLang(lang);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const changeSessionLang = async (newLang: Lang) => {
    setLoading(true);
    try {
      await updateSessionLang(newLang);
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return { changeLang, loading };
};
