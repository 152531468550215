/**
 * Check if empty Object or Array or String
 * @param obj any
 * @returns boolean
 * - (null, undefined, '', [], {}) => true
 * - (0, false, true, NaN, 123, 'foo', [1,2,3], { foo: 'bar'}) => false
 */
export function isEmpty(obj: any): boolean {
  if (obj == null || obj === undefined) {
    return true;
  }
  if (typeof obj == 'number' || typeof obj == 'boolean' || typeof obj == 'function') {
    return false;
  }
  if (Array.isArray(obj)) {
    return !obj.length;
  }
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
}
