import { AxiosError } from 'axios';
import { findErrorData, transformFormErrors } from 'common/form/helpers';
import differenceInDays from 'date-fns/differenceInDays';
import debounce from 'debounce-promise';
import { EsfType } from 'features/esf';
import { FormikProps } from 'formik';
import { EsfTxpStatus } from 'pages/esf/constants';
import { preprocessFormData, preSetErrors } from 'pages/esf/helpers';
import { EsfFormValues } from 'pages/esf/types';
import { mutationInvoiceValidate, queryInvoiceIsNationalBank, queryInvoiceNumber } from 'rest';
import { formatToDate } from 'services/date-time';
import { LocaleStore } from 'services/l10n';
import { DialogStore } from 'services/modal';

const debouncedInvoiceValidate = debounce(mutationInvoiceValidate, 100);

export const validateForm = async ({
  form,
  beforeSend = false,
  locale,
  dialog,
}: {
  form: FormikProps<EsfFormValues>;
  beforeSend: boolean;
  locale: LocaleStore;
  dialog: DialogStore;
}) => {
  const resultErrors = [];
  const { values, setErrors } = form;
  let errorData;
  console.log('here');
  try {
    await debouncedInvoiceValidate({
      body: { data: preprocessFormData(values) },
    });
  } catch (error) {
    console.log(error);
    errorData = findErrorData(error);
    if (Array.isArray(errorData)) {
      resultErrors.push(...errorData);
    } else {
      return false;
    }
  }

  const frontErrorData = await frontValidateForm({ form, beforeSend, locale, dialog });
  if (Array.isArray(frontErrorData)) {
    resultErrors.push(...frontErrorData);
  } else {
    return false;
  }

  typeof setErrors === 'function' && setErrors(preSetErrors(transformFormErrors(resultErrors)));

  return !resultErrors.length;
};

export const frontValidateForm = async ({
  form,
  beforeSend = false,
  locale,
  dialog,
}: {
  form: FormikProps<EsfFormValues>;
  beforeSend: boolean;
  locale: LocaleStore;
  dialog: DialogStore;
}): Promise<Array<{ property: string; text: string }>> => {
  const { values } = form;
  const { sellers, customers, publicOffice } = values;
  const { loc } = locale;
  const frontErrors: Array<{ property: string; text: string }> = [];

  // ###* Проверка на то что дата выписки основного сф отличается от даты оборота не больше, чем на 15 дней ###
  if (beforeSend && values.invoiceType === EsfType.ORDINARY_INVOICE && values.date !== values.turnoverDate) {
    const date = formatToDate(values.date);
    const turnoverDate = formatToDate(values.turnoverDate);
    console.log(date, turnoverDate);
    if (date && turnoverDate) {
      console.log(differenceInDays(date, turnoverDate));
      if (differenceInDays(date, turnoverDate) > 15) {
        // frontErrors.push({ property: 'turnoverDate', text: loc('esf.frontError.dateNotMatch') });
        dialog.showAlert({ content: loc('esf.frontError.dateNotMatch'), center: true, id: 'dateNotMatch' });
      }
    }
  }

  // ###* Проверка на отсутствие ФЛ среди УСД ###
  if (sellers.length > 1) {
    sellers.forEach((seller, index) => {
      if (seller._taxpayer?.type === 'INDIVIDUAL') {
        frontErrors.push({ property: `sellers[${index}].tin`, text: loc('esf.frontError.flNotParticipant') });
      }
    });
  }
  if (customers.length > 1) {
    customers.forEach((customer, index) => {
      if (customer._taxpayer?.type === 'INDIVIDUAL') {
        frontErrors.push({ property: `customers[${index}].tin`, text: loc('esf.frontError.flNotParticipant') });
      }
    });
  }

  // ###* Проверка на фейковый аккаунт ###
  (['sellers', 'customers'] as const).forEach((side) => {
    values[side].forEach((p, index) => {
      if (p._taxpayer?.state === 'FAKE') {
        frontErrors.push({ property: `${side}[${index}].tin`, text: loc('esf.frontError.fakeBin') });
      }
    });
  });

  // валидации СФ перед её отправкой, сюда стоит добавлять проверки с отдельными ajax запросами
  if (beforeSend) {
    //
    //

    //###* Проверка уникальности номера + даты СФ ###
    if (values.num && values.date) {
      try {
        const { data } = await queryInvoiceNumber({ num: values.num, date: values.date });
        if (data[0]) {
          frontErrors.push({ property: 'num', text: loc('esf.frontError.numExist') });
        }
      } catch (error) {
        if ((error as AxiosError)?.response?.status === 404) {
          console.log('invoice not found');
        }
        console.error('queryInvoiceNumber error');
      }
    }

    // ###* Проверка обязательности заполнения раздела С1, если у получателя БИН Национального банка в случае категории "E - государственное учреждение" ###
    if (
      customers[0]?._taxpayer &&
      customers[0]?.statuses &&
      customers[0].statuses.includes(EsfTxpStatus.PUBLIC_OFFICE) &&
      !publicOffice?.iik
    ) {
      try {
        const { data } = await queryInvoiceIsNationalBank({ tin: customers[0]._taxpayer.tin });
        if (data === false) {
          frontErrors.push({ property: 'publicOffice.iik', text: loc('esf.frontError.publicOfficeRequired') });
        }
      } catch (error) {
        console.log('queryInvoiceIsNationalBank error', error);
      }
    }
  }
  return frontErrors;
};
