import { convertToFormDataDeep, DataMutationFunction, httpClient } from 'rest';

export const mutChangeConfirmDeadlineDate: DataMutationFunction = async (
  variables = {}
): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormDataDeep(body);

  const res = await httpClient.post(
    '/admin/snt/changeConfirmDeadlineDate',
    formData
  );
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
