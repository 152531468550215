import { AxiosResponse } from 'axios';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const querySntGetActionNotification: DataQueryFunction = async (variables: any) => {
  const { idList, actionType } = variables || {};

  if (process.env.NODE_ENV === 'development') {
    return { data: fakeActionNotification } as AxiosResponse;
  }

  const res = await httpClient.get(`snt/getActionNotification`, { params: { idList, actionType } });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};

const fakeActionNotification = [
  {
    documentId: 20867703244857344,
    property: 'importType',
    notificationCode: 'SNT_CANCEL_CONFIRM_WARNING',
    text: 'SNT_CANCEL_CONFIRM_WARNING',
  },
];
