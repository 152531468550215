import React, { Fragment, useRef } from 'react';
import clsx from 'clsx';
import s from './StatisticReportForm.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, Field, FormField, FormSelect, FormValueProps, Loader, PageFooter } from 'ui/common';
import { useLocale } from 'services/l10n';
import { FormDoubleDatePicker, FormLocaleDatePicker, FormOGDSelect } from 'common/components';
import { ToastService } from 'services/toasts';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { getOptionsYesNo } from 'common/helpers/options';
import { DT } from 'services/date-time';
import { clearPrivateProps } from 'common/utils';
import { UserStore } from 'common/stores/user-store';
import { USER_SERVICE_ROLE } from 'common/constants';
import { subMonths, subYears } from 'date-fns';
import * as yup from 'yup';

export type SubmitType = 'PDF' | 'XLSX';

const MIN_DATE = new Date('2014-01-01');
const MAX_DATE = subMonths(new Date(), 1);

const schema = yup.object({
  dateTo: yup.string().trim().required('Дата по, обязательно для заполнения'),
});

type Props = {
  className?: string;
  onSubmit(values: any, submitType: SubmitType): Promise<any>;
  initialValues: any;
  user: UserStore;
};

export const StatisticReportForm: React.FC<Props> = ({ className, onSubmit, initialValues, user }) => {
  const locale = useLocale();
  const { loc } = locale;

  const submitType = useRef<SubmitType>('PDF');

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await onSubmit(
        clearPrivateProps({
          ...values,
          dateFrom: DT.startOfMonth(values.dateFrom),
          dateTo: DT.endOfMonth(values.dateTo),
        }),
        submitType.current
      );
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      actions.setErrors(solveFormErrors(error));
      console.error('form error', error);
      actions.setSubmitting(false);
    }
  };

  const onYearChange = ({ value, name, form }: FormValueProps) => {
    form.setFieldValue(name, value);
    form.setFieldValue('dateFrom', '');
    form.setFieldValue('dateTo', DT.endOfYear(value));
    if (user.currentUser?.userServiceRoleType !== USER_SERVICE_ROLE.OGD_ADMIN) {
      form.setFieldValue('ogdCode', '');
    }
    form.setFieldValue('growingTotal', true);
  };

  const onAnyChange = ({ form, name, value }: FormValueProps) => {
    form.setFieldValue(name, value);
    form.setFieldValue('_year', '');
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={submitHandler}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={schema}
    >
      {({ setValues, submitForm, errors, isSubmitting, resetForm }) => {
        const dateErrorMessage =
          (errors['dateFrom'] && String(errors['dateFrom'])) ||
          (errors['dateTo'] && String(errors['dateTo'])) ||
          (errors['datePeriod'] && String(errors['datePeriod']));

        return (
          <Fragment>
            {isSubmitting && <Loader overlay />}
            <Form className={clsx(className, s.wrapper)}>
              <Field
                label={loc('stats.period')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
                errorMessage={dateErrorMessage}
              >
                <FormDoubleDatePicker
                  className={s.control}
                  nameFrom="dateFrom"
                  nameTo="dateTo"
                  error={!!dateErrorMessage}
                  showMonthYearPicker
                  onValueChange={onAnyChange}
                  minDate={MIN_DATE}
                  maxDate={MAX_DATE}
                />
              </Field>

              <FormField
                name="growingTotal"
                label={loc('stats.growingTotal')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormSelect
                  options={getOptionsYesNo(locale, false)}
                  fullWidth={false}
                  className={s.control}
                  onValueChange={onAnyChange}
                  // isDisabled
                />
              </FormField>

              <FormField
                name="ogdCode"
                label={loc('common.labels.ogdCode')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormOGDSelect
                  fullWidth={false}
                  className={s.control}
                  isDisabled={user?.currentUser?.userServiceRoleType === USER_SERVICE_ROLE.OGD_ADMIN}
                  withoutThirdLevel
                />
              </FormField>

              <div className={s.divider}></div>

              <FormField
                name="_year"
                label={loc('stats.year')}
                direction="line"
                labelClassName={s.label}
                className={s.field}
              >
                <FormLocaleDatePicker
                  showYearPicker
                  className={s.control}
                  minDate={MIN_DATE}
                  maxDate={subYears(new Date(), 1)}
                  onValueChange={onYearChange}
                />
              </FormField>

              <PageFooter className={s.footer}>
                <Button
                  variant="secondary"
                  className={s.button}
                  onClick={() => {
                    resetForm();
                    setValues(initialValues);
                  }}
                >
                  Очистить
                </Button>
                <Button
                  variant="secondary"
                  className={s.button}
                  onClick={() => {
                    submitType.current = 'XLSX';
                    submitForm();
                  }}
                >
                  Скачать XLS
                </Button>
                <Button
                  className={s.button}
                  onClick={() => {
                    submitType.current = 'PDF';
                    submitForm();
                  }}
                >
                  Скачать PDF
                </Button>
              </PageFooter>
            </Form>
          </Fragment>
        );
      }}
    </Formik>
  );
};
