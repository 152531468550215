export function preloadImage(url: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = function () {
      resolve();
    };
    img.onerror = function (err) {
      reject(err);
    };
  });
}

export function preloadImages(imageUrls: Array<string>): Promise<void[]> {
  const imagePromiseArr = imageUrls.map((url) => preloadImage(url));
  return Promise.all(imagePromiseArr);
}
