import { LocaleStore } from 'services/l10n';
/**
 *
 * @param value - true => Да, false => Нет, undefined, null => ''
 * @param locale - если передать сервис локализации то вернет локализованную строку
 * @returns Ключ 'common.yes' | 'common.no' или Строку локализации (Да, Нет, или пустая строка)
 */
export function contentYesNo(value: any, locale?: LocaleStore) {
  const key = value ? 'common.yes' : value === false ? 'common.no' : '';
  return locale ? locale.loc(key) : key;
}
