import { UserStore } from 'common/stores/user-store';
import { mutationInvoiceConfirm } from 'rest';
import { EsfDirection, EsfStatus, solveEsfDirection } from 'features/esf';

export const confirmEsf = async (ids: Array<string>) => {
  try {
    const res = await mutationInvoiceConfirm({
      body: {
        list: JSON.stringify(ids),
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export const confirmEsfSelection = async ({
  selection,
  user,
  getRowId,
}: {
  selection: Array<any>;
  user: UserStore;
  getRowId: (row: any) => any;
}) => {
  const unconfirmed = selection.filter(
    (item) => solveEsfDirection(item, user) === EsfDirection.INBOUND && item.invoiceStatus === EsfStatus.CREATED
  );
  if (unconfirmed.length) {
    return await confirmEsf(unconfirmed.map(getRowId));
  }
};
