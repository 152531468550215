import React, { Fragment, ReactElement, ReactNode, useMemo } from 'react';
import clsx from 'clsx';
import s from './Tooltip.module.scss';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { nanoid } from 'nanoid';

type Props = {
  className?: string;
  content?: ReactNode;
  children: ReactElement;
  place?: 'bottom-right' | 'bottom-left' | 'bottom';
};

const placeMap = {
  'bottom-right': 'bottom-end',
  'bottom-left': 'bottom-start',
  bottom: 'bottom',
} as const;

export const Tooltip: React.FC<Props> = ({ className, children, content, place = 'bottom' }) => {
  const childClassName = useMemo(() => `tooltip_${nanoid(5)}`, []);

  return (
    <Fragment>
      {React.cloneElement(children, { className: clsx(children.props.className, childClassName) })}
      <ReactTooltip className={s.tooltip} anchorSelect={`.${childClassName}`} place={placeMap[place]}>
        {content}
      </ReactTooltip>
    </Fragment>
  );
};
