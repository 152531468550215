export class StorageService {
  private storage: Storage;
  private prefix: string = 'REACT';

  constructor(storage: Storage) {
    this.storage = storage;
  }

  get(key: string) {
    const value = this.storage.getItem(`${this.prefix}_${key}`);
    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (error) {}
    } else {
      return value;
    }
  }

  set(key: string, value?: any) {
    if (value !== 'undefined') {
      this.storage.setItem(`${this.prefix}_${key}`, JSON.stringify(value));
    }
  }

  remove(key: string) {
    this.storage.removeItem(`${this.prefix}_${key}`);
  }

  clear() {
    this.storage.clear();
  }
}

export const LocalStorageService = new StorageService(window.localStorage);
export const SessionStorageService = new StorageService(window.sessionStorage);
