import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React, { Fragment, ReactNode, useEffect } from 'react';

type Props = {
  children: (count: number) => ReactNode;
};

export const NotificationsCounterProvider: React.FC<Props> = observer(({ children }) => {
  const { notifications } = useStore();

  useEffect(() => {
    notifications.updateUnreadMessagesCount();
  }, [notifications]);

  return <Fragment> {children(notifications.countOfUnread)}</Fragment>;
});
