import React, { useState } from 'react';
import clsx from 'clsx';
import s from './RegTypeSelect.module.scss';
import ss from '../../Registration.module.scss';
import { ReactComponent as IconFl } from 'assets/icons/registration/fl.svg';
import { ReactComponent as IconIp } from 'assets/icons/registration/ip.svg';
import { ReactComponent as IconComp } from 'assets/icons/registration/comp.svg';
import { ReactComponent as IconCheck } from 'assets/icons/registration/check.svg';
import { ButtonT2, CheckboxT2, FieldT2, Loader, SvgIcon } from 'ui';
import { observer } from 'mobx-react-lite';
import { clearFalsyProps, isEmpty } from 'common/utils';
import { SessionStorageService } from 'services/storage';
import { API_URL } from 'common/constants';
import { useStore } from 'common/hooks';
import { queryTaxpayerAutocompleteByCert } from 'rest';
import { useDialog } from 'services/modal';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { PATH } from 'routing/paths';
import { useHistory } from 'react-router-dom';
import { RegActionPage, RegSubType, RegType } from 'features/registration/types';

export const REGISTRATION_SUB_TYPES_STORAGE_KEY = 'registrationSubTypes';

type Props = RegActionPage & {
  className?: string;
};

export const RegTypeSelect: React.FC<Props> = observer(({ className, regStore, onNext, onBack }) => {
  const { locale, ncaLayer } = useStore();
  const { loc } = locale;

  const dialog = useDialog();
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);

  const selectedTypes = regStore.selectedTypes;
  const selectedSubTypes = regStore.selectedSubTypes;
  const nonResident = regStore.nonResident;
  const biometricsNotRequired = regStore.biometricsNotRequired;
  const registrationByCertificate = regStore.registrationByCertificate;

  const regTypes = [
    {
      name: RegType.INDIVIDUAL,
      label: (selTypes: { [key in RegType]?: boolean }) =>
        selTypes[RegType.ENTERPRISE] ? loc(`reg.type.flComp`) : loc(`reg.type.fl`),
      icon: IconFl,
      disabled: (selTypes: { [key in RegType]?: boolean }) => selTypes[RegType.ENTERPRISE],
    },
    {
      name: RegType.ENTREPRENEUR,
      label: loc(`reg.type.ip`),
      icon: IconIp,
      disabled: (selTypes: { [key in RegType]?: boolean }) => selTypes[RegType.NOTARY] || selTypes[RegType.LAWYER],
    },
    {
      name: RegType.ENTERPRISE,
      label: loc(`reg.type.comp`),
      icon: IconComp,
    },
    {
      name: RegType.NOTARY,
      label: loc(`common.profileType.NOTARY`),
      disabled: (selTypes: { [key in RegType]?: boolean }) => selTypes[RegType.ENTREPRENEUR],
    },
    {
      name: RegType.LAWYER,
      label: loc(`common.profileType.LAWYER`),
      disabled: (selTypes: { [key in RegType]?: boolean }) => selTypes[RegType.ENTREPRENEUR],
    },
    {
      name: RegType.MEDIATOR,
      label: loc(`common.profileType.MEDIATOR`),
    },
    {
      name: RegType.BAILIFF,
      label: loc(`common.profileType.BAILIFF`),
      className: s.bailiff,
    },
  ];

  const onSelect = (typeName: RegType) => {
    regStore.setSelectedTypes((state: any) => {
      const newState = { ...state, [typeName]: !state[typeName] };
      if (newState[RegType.ENTERPRISE]) {
        newState[RegType.INDIVIDUAL] = true;
      }
      return clearFalsyProps(newState);
    });
    regStore.setSelectedSubTypes({});
    regStore.setNonResident(false);
  };

  const onSelectSubType = (typeName: RegSubType) => {
    regStore.setSelectedSubTypes((state: any) => {
      return clearFalsyProps({ ...state, [typeName]: !state[typeName] });
    });
    regStore.setNonResident(false);
  };

  const checkCertificate = async () => {
    setLoading(true);
    let cert;

    try {
      cert = await ncaLayer.getCertificate();
    } catch (error) {
      console.log(error);
      setLoading(false);
      return;
    }
    try {
      const { data: taxpayer } = await queryTaxpayerAutocompleteByCert({ cert: cert.base64Pem });

      // Проверка сертификата для Юр.лица
      if (selectedTypes[RegType.ENTERPRISE] && !taxpayer.taxpayerBin) {
        ToastService.showError(loc('reg.enterpriseError'));
        return;
      }

      // Проверка для НЕ резидента
      if (nonResident) {
        if (taxpayer.taxpayerIin?.resident !== false) {
          await dialog.showAlert({ content: loc('reg.youAreResident'), center: true, width: 500 });
          regStore.setNonResident(false);
          return;
        }
      }

      // Проверка для для лиц с органиченными возможностями
      if (biometricsNotRequired) {
        if (taxpayer.biometricsRequired !== false) {
          await dialog.showAlert({ content: loc('reg.biometricsRequired'), center: true, width: 500 });
          history.push(PATH.REGISTRATION);
          return;
        }
      }

      // Пользователь с таким ИИН уже зарегистрирован!
      if (
        selectedTypes[RegType.INDIVIDUAL] &&
        !selectedTypes[RegType.ENTREPRENEUR] &&
        !selectedTypes[RegType.ENTERPRISE] &&
        taxpayer.isUserRegistered
      ) {
        return await dialog.showAlert({ content: loc('reg.userExist'), center: true });
      }
      // Юридическое лицо уже зарегистрировано в системе!
      if (selectedTypes[RegType.ENTERPRISE]) {
        const companies = taxpayer.taxpayerCompanies?.filter((c: any) => c.tin === taxpayer.taxpayerBin?.tin);
        if (companies?.length === 0) {
          return await dialog.showAlert({ content: loc('reg.enterpriseExist'), center: true });
        } else if (companies[0].enterpriseAdministrator !== taxpayer.taxpayerIin?.tin) {
          return await dialog.showAlert({ content: loc('reg.enterpriseWrongAdmin'), center: true });
        }
      }

      regStore.setTaxpayerFromCertificate(taxpayer);
      onNext();
      // Если пользовватель уже зарегистрирован как ФЛ, удаляем этот тип
      if (taxpayer?.isUserRegistered) {
        delete regStore.selectedTypes.INDIVIDUAL;
      } else {
        regStore.selectedTypes.INDIVIDUAL = true;
      }
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const onTypeSelectSubmit = async () => {
    if (registrationByCertificate) {
      await checkCertificate();
    } else {
      SessionStorageService.set(REGISTRATION_SUB_TYPES_STORAGE_KEY, selectedSubTypes);
      window.location.assign(
        `${API_URL}/registration/oauth2/authorization/did?registrationTypes=${Object.keys(selectedTypes).join(',')}`
      );
    }
  };

  return (
    <div className={clsx(className, s.wrapper, ss.container)}>
      {loading && <Loader className={s.loader} />}
      <h2>{loc('reg.register')}</h2>
      <div className={s.typeGrid}>
        {regTypes.map((type) => (
          <button
            key={type.name}
            className={clsx(s.typeButton, type.className, {
              [s.typeActive]: selectedTypes[type.name],
              [s.disabled]: typeof type.disabled === 'function' ? type.disabled(selectedTypes) : false,
            })}
            onClick={() => onSelect(type.name)}
          >
            {!!type.icon && (
              <div className={s.typeIcon}>
                <SvgIcon component={type.icon} />
              </div>
            )}
            {typeof type.label === 'function' ? type.label(selectedTypes) : type.label}
            <SvgIcon className={s.typeActiveIcon} component={IconCheck} />
          </button>
        ))}
      </div>
      <div className={ss.caption}>{loc('reg.typeCaption')}</div>
      <div className={s.checkboxes}>
        <FieldT2 className={s.field} label={loc('reg.OPERATOR')} direction="line-reverse" htmlFor="regOPERATOR">
          <CheckboxT2
            id="regOPERATOR"
            onChange={() => onSelectSubType(RegSubType.OPERATOR)}
            checked={selectedSubTypes.operator || false}
            disabled={!selectedTypes[RegType.ENTERPRISE]}
          />
        </FieldT2>
        <FieldT2 className={s.field} label={loc('reg.ATTORNEY')} direction="line-reverse" htmlFor="regATTORNEY">
          <CheckboxT2
            id="regATTORNEY"
            onChange={() => onSelectSubType(RegSubType.ATTORNEY)}
            checked={selectedSubTypes.attorney || false}
            disabled={!(selectedTypes[RegType.ENTERPRISE] || selectedTypes[RegType.ENTREPRENEUR])}
          />
        </FieldT2>
        <FieldT2 className={s.field} label={loc('reg.nonResident')} direction="line-reverse" htmlFor="nonResident">
          <CheckboxT2
            id="nonResident"
            onChange={() => regStore.setNonResident(!nonResident)}
            checked={nonResident}
            disabled={
              !(
                (Object.keys(selectedTypes).length === 1 && selectedTypes[RegType.INDIVIDUAL]) ||
                (Object.keys(selectedTypes).length === 2 &&
                  selectedTypes[RegType.INDIVIDUAL] &&
                  selectedTypes[RegType.ENTERPRISE])
              ) ||
              selectedSubTypes[RegSubType.ATTORNEY] ||
              selectedSubTypes[RegSubType.OPERATOR]
            }
          />
        </FieldT2>
      </div>
      <div className={ss.actions}>
        <ButtonT2 size="large" variant="secondary" onClick={onBack}>
          {loc('reg.back')}
        </ButtonT2>
        <ButtonT2 size="large" onClick={onTypeSelectSubmit} disabled={isEmpty(selectedTypes)}>
          {registrationByCertificate ? loc('reg.certificateChoose') : loc('reg.digitalId')}
        </ButtonT2>
      </div>
    </div>
  );
});
