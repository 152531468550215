import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryCheckRegistered: DataQueryFunction = async (variables: any) => {
  const { iin } = variables || {};

  const res = await httpClient.get(`/checkRegistered/${iin}`);
  if (res.data.success) {
    return res;
  } else {
    throw res;
  }
};
