import React from 'react';
import clsx from 'clsx';
import s from './RegDoneStep.module.scss';
import { ButtonT2, CardMain } from 'ui';
import { Link } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { RegistrationStore } from '../../RegistrationStore';
import { useLocale } from 'services/l10n';
import { capitalizeFirstLetter } from 'common/utils';

type Props = {
  className?: string;
  regStore: RegistrationStore;
};

export const RegDoneStep: React.FC<Props> = ({ className, regStore }) => {
  const locale = useLocale();
  const { loc } = locale;

  const firstName = regStore.did?.didAuthData?.firstName;
  const email = regStore.regData?.email;

  return (
    <div className={clsx(className, s.wrapper)}>
      <CardMain className={s.card}>
        <div className={s.successText}>
          {loc('reg.grats', [capitalizeFirstLetter(firstName)])}
          <br />
          {loc('reg.youveDone')}
        </div>
        <div className={s.subText}>{loc('reg.mailSend', [email])}</div>
        <Link to={PATH.MAIN}>
          <ButtonT2 className={s.doneButton} fullWidth size="large">
            {loc('reg.toMain')}
          </ButtonT2>
        </Link>
      </CardMain>
    </div>
  );
};
