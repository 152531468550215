import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocale } from 'services/l10n';
import { Button, UITheme } from 'ui/common';
import s from './BackButton.module.scss';

type Props = {
  label?: string;
  className?: string;
  onClick?: (e: React.SyntheticEvent) => void;
  theme?: UITheme;
};

export const BackButton: FC<Props> = ({ className, label, onClick, theme }) => {
  const { loc } = useLocale();

  const history = useHistory();
  const clickHandle = (e: React.SyntheticEvent) => {
    if (typeof onClick === 'function') {
      onClick(e);
    } else {
      history.goBack();
    }
  };
  return (
    <Button
      onClick={clickHandle}
      variant={theme === 'T1' ? 'secondary' : 'outlined'}
      iconName="chevron-left"
      className={className}
      iconClassName={s.icon}
      theme={theme}
    >
      {label || loc('common.return')}
    </Button>
  );
};
