import { DataMutationFunction } from '../../../types';
import { httpClient } from '../../../httpClient';
import { convertToFormData } from '../../../helpers';

export const mutationInvoiceSendSignedImported: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  const formData = convertToFormData(body);

  const res = await httpClient.post('/invoice/sendSignedImported', formData);
  if (res?.data?.success === false || res?.data?.errors) {
    throw res;
  } else {
    return res;
  }
};
