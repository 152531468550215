import React, { Fragment, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import s from './FilterPanelT2.module.scss';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { FilterT2ClassNames, FilterT2Tag } from './FilterPanelT2Types';
import { useLocale } from 'services/l10n';
import { solveFormErrors } from 'common/form/helpers';
import { isEmpty, trimProps } from 'common/utils';
import { ButtonIcon, ButtonT2, TagT2 } from 'ui';
import { ScrollingLine } from './ScrollingLine';

type Props = {
  className?: string;
  filterValues?: any;
  defaultValues?: any;
  validationSchema?: any;
  onSubmit?: (values: any) => Promise<any>;
  renderHeader?: (props: { form: FormikProps<any>; classNames: FilterT2ClassNames }) => React.ReactNode;
  renderContent?: (props: { form: FormikProps<any>; classNames: FilterT2ClassNames }) => React.ReactNode;
  buttonLabel?: string;
  submitButtonLabel?: string;
  clearButtonLabel?: string;
  errors?: any;
  filterTags?: Array<FilterT2Tag>;
  advancedPanelType?: 'modal' | 'aside';
  loading?: boolean;
};

export const FilterPanelT2: React.FC<Props> = ({
  className,
  filterValues = {},
  defaultValues,
  validationSchema,
  onSubmit = async (values: any) => console.log(values),
  renderContent,
  renderHeader,
  buttonLabel,
  submitButtonLabel,
  clearButtonLabel,
  errors,
  filterTags,
  advancedPanelType = 'modal',
  loading,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const [showAdvanced, setShowAdvanced] = useState<boolean>(false);

  const mainFormRef = useRef<FormikProps<any> | undefined>(undefined);

  const formSubmitHandler = async (values: any, form: FormikHelpers<any>) => {
    console.log(values);
    setShowAdvanced(false);
    const trimedValues = trimProps(values);
    try {
      await onSubmit(trimedValues);
    } catch (error) {
      form.setErrors(solveFormErrors(error));
    }
    form.setSubmitting(false);
  };

  const onClear = (submit?: boolean) => {
    if (mainFormRef.current) {
      mainFormRef.current.resetForm();
      mainFormRef.current.setValues(defaultValues);
    }
    if (submit && mainFormRef.current) {
      formSubmitHandler(defaultValues, mainFormRef.current);
    }
  };

  const onTagRemove = async (tag: FilterT2Tag) => {
    let newFilterValues: any = {};
    if (typeof tag.onRemove === 'function') {
      newFilterValues = tag.onRemove(filterValues);
    } else {
      newFilterValues = { ...filterValues };
      const names = Array.isArray(tag.name) ? tag.name : [tag.name];
      names.forEach((n) => delete newFilterValues[n]);
    }
    if (mainFormRef.current) {
      formSubmitHandler(newFilterValues, mainFormRef.current);
    }
  };

  const hideAdvanced = (form: FormikHelpers<any> | undefined) => {
    setShowAdvanced(false);
    if (form) {
      form.resetForm();
    }
  };

  useEffect(() => {
    console.log('errors effect');
    mainFormRef.current?.setErrors(solveFormErrors(errors));
  }, [errors]);

  useEffect(() => {
    if (showAdvanced) {
      const handler = (e: KeyboardEvent) => {
        if (['Escape'].includes(e.key)) {
          hideAdvanced(mainFormRef.current);
          e.preventDefault();
        }
      };

      document.addEventListener('keyup', handler);
      return () => {
        document.removeEventListener('keyup', handler);
      };
    }
  }, [showAdvanced]);

  const classNames: FilterT2ClassNames = {
    mainWrapper: s.mainWrapper,
    advancedWrapper: s.advancedWrapper,
    columnWide: s.columnWide,
    labelWide: s.labelWide,
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <Formik
          initialValues={filterValues}
          onSubmit={formSubmitHandler}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {(form) => {
            mainFormRef.current = form;
            return (
              <Form className={clsx(s.mainForm)}>
                {typeof renderHeader === 'function' &&
                  renderHeader({
                    form,
                    classNames,
                  })}
                <div className={s.navWrapper}>
                  <ButtonT2 type="submit" variant="primary" size="small" disabled={loading}>
                    {submitButtonLabel || loc('common.button.search')}
                  </ButtonT2>
                  <ButtonT2 variant="transparent" size="small" onClick={() => onClear(true)}>
                    {clearButtonLabel || loc('common.button.clear')}
                  </ButtonT2>
                  <ButtonT2
                    className={s.advancedButton}
                    iconName={showAdvanced ? 'chevron-up' : 'chevron-down'}
                    iconSide="right"
                    iconClassName={s.chevronIcon}
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowAdvanced(true);
                    }}
                  >
                    {buttonLabel || loc('common.button.advancedSearch')}
                  </ButtonT2>
                </div>
                {showAdvanced && (
                  <Fragment>
                    <div className={s.overlay} onClick={() => hideAdvanced(form)} />
                    {advancedPanelType === 'modal' ? (
                      <div className={s.advancedModal}>
                        {typeof renderContent === 'function' &&
                          renderContent({
                            form,
                            classNames,
                          })}
                        <div className={s.advancedFooter}>
                          <ButtonT2 type="submit" variant="primary" size="small">
                            {submitButtonLabel || loc('common.button.search')}
                          </ButtonT2>
                          <ButtonT2 variant="transparent" size="small" onClick={() => onClear(false)}>
                            {clearButtonLabel || loc('common.button.clear')}
                          </ButtonT2>
                        </div>
                      </div>
                    ) : (
                      <div className={s.advancedAside}>
                        <div className={s.advancedHeader}>
                          {loc('common.button.filters')}
                          <ButtonIcon
                            className={s.closeButton}
                            iconName="close-bold"
                            iconClassName={s.closeIcon}
                            onClick={() => hideAdvanced(form)}
                          />
                        </div>
                        {typeof renderContent === 'function' &&
                          renderContent({
                            form,
                            classNames,
                          })}
                        <div className={s.advancedFooter}>
                          <ButtonT2 type="submit" variant="primary" size="small" disabled={loading}>
                            {submitButtonLabel || loc('common.button.search')}
                          </ButtonT2>
                          <ButtonT2 variant="transparent" size="small" onClick={() => onClear(false)}>
                            {clearButtonLabel || loc('common.button.clear')}
                          </ButtonT2>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
      {Array.isArray(filterTags) && (
        <ScrollingLine className={s.activeFilters}>
          {filterTags.map((tag) => {
            const names = Array.isArray(tag.name) ? tag.name : [tag.name];
            if (names.some((n) => !(isEmpty(filterValues[n]) || filterValues[n] === false))) {
              return (
                <TagT2 key={names[0]} className={s.filterTag} onClose={() => onTagRemove(tag)}>
                  {`${tag.label ? `${tag.label}: ` : ''}`}
                  {typeof tag.getValue === 'function' ? tag.getValue(filterValues) : filterValues[names[0]]}
                </TagT2>
              );
            } else {
              return undefined;
            }
          })}
        </ScrollingLine>
      )}
    </div>
  );
};
