import { getLegasyUrl } from 'common/helpers';
import { EsfMode } from '../constants';
import { PATH } from 'routing/paths';
import { generatePath } from 'react-router-dom';
import { stringify } from 'query-string';
import { History } from 'history';

/**
 * Перенавравоение на страницу бланка (в старом интерфейсе)
 */
export const navigateToEsfPageLegacy = ({
  mode,
  id,
  reasonPaper,
}: {
  mode: string;
  id?: string;
  // Причина выписки на бумажном носителе
  reasonPaper?: string;
}) => {
  if (mode === EsfMode.new) {
    window.location.assign(getLegasyUrl('/esf/new'));
  } else if (mode === EsfMode.newPaper && reasonPaper) {
    window.location.assign(getLegasyUrl(`/esf/paper/${reasonPaper}`));
  } else if (id) {
    let oldMode = mode;
    switch (mode) {
      case EsfMode.draft:
        oldMode = 'drafts';
        break;
      case EsfMode.copyDraft:
        oldMode = 'copy_draft';
        break;
      case EsfMode.fixed:
        oldMode = 'corrected';
        break;
      case EsfMode.fromSnt:
        oldMode = 'from_snt';
        break;
      default:
        break;
    }

    window.location.assign(getLegasyUrl(`/esf/${oldMode}/${id}`));
  }
};

/**
 * Перенавравоение на страницу бланка (новые страницы)
 */
export const navigateToEsfPage = ({
  mode,
  selection,
  reasonPaper,
  history,
}: {
  mode: string;
  selection?: any[];
  // Причина выписки на бумажном носителе
  reasonPaper?: string;
  history: History;
}) => {
  if (mode === EsfMode.new) {
    history.push(
      generatePath(PATH.ESF_BLANK_NEW, {
        mode,
      })
    );
  } else if (mode === EsfMode.newPaper && reasonPaper) {
    history.push(
      `${generatePath(PATH.ESF_BLANK_NEW, {
        mode,
      })}?${stringify({ reasonPaper })}`
    );
  } else if (selection?.[0]?.stringInvoiceId) {
    history.push(
      generatePath(PATH.ESF_BLANK, {
        mode,
        id: selection[0].stringInvoiceId,
      })
    );
  }
};
