import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './ArmInviteModal.module.scss';
import { ButtonT2, FormFieldT2, FormRadioT2 } from 'ui';
import { ModalChildrenProps, ModalT2Content, ModalT2Footer, ModalT2Header } from 'services/modal';
import { useStore } from 'common/hooks';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { mutationAdminApproveInvitation, queryAdminGetApproveInvitationHash } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = ModalChildrenProps & {
  className?: string;
  inviteData: any;
  onDone: () => void;
};

export const ArmInviteModal: React.FC<Props> = ({ className, inviteData, onClose, onDone }) => {
  const { locale, auth } = useStore();
  const { loc } = locale;

  const initialValues = useMemo(
    () =>
      Array.isArray(inviteData.permissions)
        ? Object.fromEntries(inviteData.permissions.map((p: string) => [p, true]))
        : {},
    [inviteData]
  );

  const setAll = (value: boolean, form: FormikProps<any>) => {
    const { values, setValues } = form;
    setValues(Object.fromEntries(Object.keys(values).map((key) => [key, value])));
  };

  const onSubmit = async (values: any, form: FormikHelpers<any>) => {
    const { setSubmitting } = form;
    console.log('Submit', values);

    let hash: string = '';

    try {
      const { data } = await queryAdminGetApproveInvitationHash({ data: values });
      hash = data;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      setSubmitting(false);
    }

    if (hash) {
      const { sign, cert } = await auth.getSignature(hash);
      try {
        await mutationAdminApproveInvitation({
          body: {
            data: JSON.stringify(values),
            certificate: cert.base64Pem,
            signature: sign,
            verificationKey: inviteData.verificationKey,
          },
        });
        ToastService.showSuccess(loc('adminInvite.success'));
        onDone();
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
        setSubmitting(false);
      }
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header className={s.header}>{loc(`adminInvite.title`)}</ModalT2Header>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(form) => {
          return (
            <Form>
              <ModalT2Content className={s.content}>
                {Array.isArray(inviteData.permissions) && inviteData.permissions.length ? (
                  <Fragment>
                    <p>
                      {loc('adminInvite.text.common1')} <b>{loc(`admin.user.role.${inviteData.invitationRole}`)}</b>
                    </p>
                    <p>{loc('adminInvite.text.common2')}</p>
                    <br />

                    <div className={clsx(s.invitationRow, s.invitationRowHeader)}>
                      <div className={s.column1}>{loc('adminInvite.text.permission')}</div>
                      <div className={s.column2}>{loc('adminInvite.text.accept')}</div>
                      <div className={s.column3}>{loc('adminInvite.text.reject')}</div>
                    </div>

                    <div className={clsx(s.invitationRow, s.invitationRowAll)}>
                      <div className={s.column1}></div>
                      <div className={s.column2}>
                        <ButtonT2 size="xsmall" variant="secondary" onClick={() => setAll(true, form)}>
                          {loc('adminInvite.text.all')}
                        </ButtonT2>
                      </div>
                      <div className={s.column3}>
                        <ButtonT2 size="xsmall" variant="secondary" onClick={() => setAll(false, form)}>
                          {loc('adminInvite.text.all')}
                        </ButtonT2>
                      </div>
                    </div>

                    {inviteData.permissions.map((permission: string) => (
                      <div key={permission} className={s.invitationRow}>
                        <div className={s.column1}>
                          <span>{loc(`admin.permission.${permission}`)}</span>
                        </div>
                        <div className={s.column2}>
                          <FormFieldT2 name={permission}>
                            <FormRadioT2 radioValue={true} />
                          </FormFieldT2>
                        </div>
                        <div className={s.column3}>
                          <FormFieldT2 name={permission}>
                            <FormRadioT2 className={s.rejectButton} radioValue={false} />
                          </FormFieldT2>
                        </div>
                      </div>
                    ))}
                  </Fragment>
                ) : null}
              </ModalT2Content>
              <ModalT2Footer>
                <ButtonT2 variant="transparent" onClick={onClose}>
                  {loc('common.button.cancel')}
                </ButtonT2>
                <ButtonT2 type="submit">{loc('common.button.confirm')}</ButtonT2>
              </ModalT2Footer>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
