import { action, makeObservable, observable } from 'mobx';
import { RootStore } from 'common/stores/RootStore';

import { DialogProps } from './types';

type DialogState = {
  show: boolean;
  props: DialogProps;
};

export class DialogStore {
  private rootStore: RootStore;
  state: DialogState = {
    show: false,
    props: {},
  };
  private resolve: null | ((value: (boolean | null) | PromiseLike<boolean | null>) => void) = null;

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      state: observable,
      show: action,
      showAlert: action,
      close: action,
    });
    this.rootStore = rootStore;
  }

  /**
   * Открывает окно с диалогом
   * @returns Promise<boolean | null>
   * резолвится с true если пользователь согласился;
   * резолвится с false если пользователь отказался;
   * в остальных случаях null (закрыл окно, окно закрылось само)
   */
  show = (props: DialogProps) => {
    if (this.state.show) {
      this.reject();
    }
    return new Promise<boolean | null>((resolve) => {
      this.resolve = resolve;
      this.state = { show: true, props };
    });
  };

  showAlert = (props: DialogProps) => {
    return this.show({ type: 'alert', ...props });
  };

  close = () => {
    this.state = { show: false, props: {} };
    this.resolve = null;
  };

  confirm = () => {
    if (typeof this.resolve === 'function') {
      this.resolve(true);
    }
    this.close();
  };

  /**
   * Отказ в диалоге. Окно закрывается и отдает false в результат.
   * @param id - можно передать id окна, чтобы отменять конкретное окно, если id не совпадает то ничего не произойдет
   */
  reject = (id?: string) => {
    if (typeof id === 'string' && id !== this.state?.props?.id) {
      return;
    }
    if (typeof this.resolve === 'function') {
      this.resolve(false);
    }
    this.close();
  };

  /**
   * Отмена диалога. Окно закрывается и отдает null в результат.
   * @param id - можно передать id окна, чтобы отменять конкретное окно, если id не совпадает то ничего не произойдет
   */
  cancel = (id?: string) => {
    if (typeof id === 'string' && id !== this.state?.props?.id) {
      return;
    }
    if (typeof this.resolve === 'function') {
      this.resolve(null);
    }
    this.close();
  };

  getId = () => {
    return this.state.props.id;
  };
}
