import React from 'react';
import clsx from 'clsx';
import s from './Textarea.module.scss';
import { UITheme } from 'ui/common/types';

export type TextareaProps = {
  className?: string;
  type?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  error?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  rows?: number;
  theme?: UITheme;
  /**
   * Отображение значения без возможности редактирования
   * Аналог readonly, но визуально другой (в дизайне T2 пунктирная обводка),
   * для информационных полей, не предназначенных для редактрования
   */
  output?: boolean;
  maxLength?: number;
};

export const Textarea: React.FC<TextareaProps> = ({
  className,
  error,
  fullWidth = true,
  rows = 4,
  value,
  theme = 'T1',
  readOnly,
  output,
  ...rest
}) => {
  return (
    <textarea
      {...rest}
      className={clsx(className, s.wrapper, s[theme], {
        [s.error]: error,
        [s.fullWidth]: fullWidth,
        [s.hasValue]: !!value,
        [s.output]: output,
      })}
      rows={rows}
      value={value}
      readOnly={readOnly || output}
    />
  );
};
