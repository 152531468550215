import { MutableRefObject, useEffect, useRef, useState } from 'react';

export function useAnimateDisplayNone(ref: MutableRefObject<any>, isHidden: boolean) {
  const [state, setState] = useState({ visualyHidden: isHidden, hidden: isHidden });

  const startValueHidden = useRef<boolean | null>(isHidden);

  useEffect(() => {
    // console.log('useAnimateDisplayNone effect', { isHidden, startValueHidden: startValueHidden.current });
    const elem = ref.current;
    if (elem && elem.addEventListener) {
      // console.log('has elem');
      const transitionendListener = () => {
        // console.log('transitionendListener runs');
        setState({ hidden: true, visualyHidden: true });
      };
      let timeout: any;
      // hidden animation
      if (isHidden && startValueHidden.current !== isHidden) {
        // console.log('start hiding');
        setState({ hidden: false, visualyHidden: true });
        startValueHidden.current = null;
        elem.addEventListener('transitionend', transitionendListener);
        // showin animation
      } else if (!isHidden && startValueHidden.current !== isHidden) {
        // console.log('start showing');
        setState({ hidden: false, visualyHidden: true });
        startValueHidden.current = null;
        timeout = setTimeout(() => {
          // console.log('timeout runs');
          setState({ hidden: false, visualyHidden: false });
        }, 20);
      }
      return () => {
        if (timeout) clearTimeout(timeout);
        if (elem) elem.removeEventListener('transitionend', transitionendListener);
      };
    }
  }, [isHidden]); // eslint-disable-line

  // console.log({ ...state, isHidden });

  return state;
}
