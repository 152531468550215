import { API_URL } from 'common/constants';
import { downloadFile } from 'rest';

type Config = {
  ids: Array<string>;
  isDraft: boolean;
  isAdmin?: boolean;
};

export const exportEsfXml = async (config: Config) => {
  const { ids, isAdmin, isDraft } = config;
  const url = isAdmin ? `${API_URL}/admin/search/downloadXml` : `${API_URL}/invoice/downloadXml`;
  try {
    await downloadFile({
      url,
      method: 'GET',
      params: { 'ids[]': ids, draft: isDraft },
    });
  } catch (error) {
    throw error;
  }
  return;
};
