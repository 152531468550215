import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useScrollDrag = ({
  elementRef,
  verticalDocumentScroll = false,
  alt,
}: {
  elementRef?: MutableRefObject<HTMLDivElement | null>;
  verticalDocumentScroll?: boolean;
  alt?: boolean;
}) => {
  const cursorStartX = useRef(0);
  const cursorStartY = useRef(0);
  const elementStartTop = useRef(0);
  const elementStartLeft = useRef(0);

  const onMouseMove = useCallback(
    (e) => {
      if (elementRef?.current) {
        // console.log(e.clientX, e.clientY);

        // How far the mouse has been moved
        const dx = e.clientX - cursorStartX.current;
        const dy = e.clientY - cursorStartY.current;

        // Scroll the element
        elementRef.current.scrollLeft = elementStartLeft.current - dx;
        if (verticalDocumentScroll) {
          document.documentElement.scrollTop = elementStartTop.current - dy;
        } else {
          elementRef.current.scrollTop = elementStartTop.current - dy;
        }
      }
    },
    [elementRef, verticalDocumentScroll]
  );

  const onMouseUp = useCallback(
    (e: MouseEvent) => {
      if (elementRef?.current) {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
        document.body.style.cursor = 'auto';
        elementRef.current.style.cursor = 'grab';
      }
    },
    [onMouseMove, elementRef]
  );

  const onMouseDown = useCallback(
    (e: MouseEvent) => {
      if (elementRef?.current) {
        cursorStartX.current = e.clientX;
        cursorStartY.current = e.clientY;
        elementStartLeft.current = elementRef.current.scrollLeft || 0;
        elementStartTop.current = verticalDocumentScroll
          ? document.documentElement.scrollTop
          : elementRef.current.scrollTop || 0;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        elementRef.current.style.cursor = 'grabbing';
      }
    },
    [onMouseMove, onMouseUp, elementRef, verticalDocumentScroll]
  );

  useEffect(() => {
    console.log('effect scroll');
    const elem = elementRef?.current;
    if (elem) {
      elem.addEventListener('mousedown', onMouseDown);
      return () => {
        elem.removeEventListener('mousedown', onMouseDown);
      };
    }
  }, [onMouseDown, elementRef]);

  return null;
};
