import React from 'react';
import clsx from 'clsx';
import s from './CountryFlag.module.scss';
import belarus from './images/belarus.png';
import armenia from './images/armenia.png';
import kazakhstan from './images/kazakhstan.png';
import kyrgyzstan from './images/kyrgyzstan.png';
import russia from './images/russia.png';

const FLAGS: { [key: string]: string } = {
  RU: russia,
  KZ: kazakhstan,
  AM: armenia,
  BY: belarus,
  KG: kyrgyzstan,
};

type Props = {
  className?: string;
  countryCode?: string;
};

export const CountryFlag: React.FC<Props> = ({ className, countryCode }) => {
  const imagePath = countryCode && FLAGS[countryCode];

  if (!imagePath) {
    return null;
  }

  return (
    <span className={clsx(className, s.wrapper)}>
      <span style={imagePath ? { backgroundImage: `url(${imagePath})` } : {}} className={s.flag}></span>
    </span>
  );
};
