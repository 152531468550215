import { clearEmptyFields } from 'common/utils';
import { convertToFormDataDeep, DataMutationFunction, httpClient } from 'rest';

export const mutationAdminAssuranceCommentPost: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {}, passportId } = variables;

  const formData = convertToFormDataDeep(clearEmptyFields(body));

  const res = await httpClient.post(`/admin/assurance/assurance/comment/post/${passportId}`, formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
