import React, { Suspense } from 'react';
import { Redirect, matchPath, withRouter, RouteComponentProps, Route } from 'react-router-dom';
import { RouteData } from '../types';
import { nanoid } from 'nanoid';
import { LayoutContainer } from './LayoutContainer';
import { LocalesLoader } from 'services/l10n/LocalesLoader';

type Props = RouteComponentProps & {
  routes: Array<RouteData>;
};

export const SwitchRoutes = withRouter(({ routes, location, history }: Props) => {
  const { pathname } = location;

  let match: any;
  const matchedRoute = routes.find((route) => {
    match = matchPath(pathname, {
      path: route.path,
      exact: route.exact,
    });
    return !!match;
  });

  if (!matchedRoute || !match) return null;

  const { layout, redirect, component: Component, update, path, exact, locales } = matchedRoute;

  const routeProps = {
    history,
    location,
    match,
  };

  const routeKey = update === 'always' ? nanoid() : update === 'path' ? pathname : '';

  // console.log('match', match);

  return (
    <LayoutContainer {...routeProps} layoutName={layout}>
      {redirect ? (
        <Redirect to={redirect} />
      ) : Component ? (
        <Suspense fallback={<div></div>}>
          <Route
            key={routeKey}
            render={(props) => (
              <LocalesLoader key={path} localeNames={locales}>
                <Component {...props} />
              </LocalesLoader>
            )}
            path={path}
            exact={exact}
          />
        </Suspense>
      ) : null}
    </LayoutContainer>
  );
});
