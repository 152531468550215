import React, { Fragment, useMemo, useState } from 'react';
import clsx from 'clsx';
import ss from '../../Registration.module.scss';
import s from './RegDataStep.module.scss';
import { FormFieldT2, FormInputMUIT2, errorToString } from 'ui';
import { useLocale } from 'services/l10n';
import { useFormikContext } from 'formik';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { FormLocaleDatePickerMUIT2 } from 'common/components';
import { RegistrationStore } from '../../RegistrationStore';

type Props = {
  className?: string;
  regStore: RegistrationStore;
};

export const RegDataFromCertificate: React.FC<Props> = ({ regStore }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { values, errors } = useFormikContext<any>();

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => setShowPassword((state) => !state);

  const gridFields = [
    {
      name: 'fio',
      label: loc('common.labels.fioShort'),
    },
    {
      name: 'iin',
      label: loc('common.labels.iinOnly'),
    },
  ];

  const isIndividualRegistred = useMemo(() => !!regStore.taxpayerFromCertificate?.isUserRegistered, [regStore]);

  return (
    <Fragment>
      <div className={s.regDataGrid}>
        {gridFields.map((field) => (
          <div key={field.name} className={s.gridCell}>
            <label>{field.label}</label>
            <span>{values[field.name]}</span>
            {!!errors[field.name] && <span className={s.errorMessage}>{errorToString(errors[field.name])}</span>}
          </div>
        ))}
      </div>

      <div className={clsx(ss.field, ss.doubleField)}>
        <FormFieldT2 className={ss.colField} name="email">
          <FormInputMUIT2 label={loc('reg.email')} autoComplete="off " />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="mobile">
          <FormInputMUIT2 label={loc('reg.phone')} autoComplete="off " />
        </FormFieldT2>
      </div>
      <div className={clsx(ss.field, ss.doubleField)}>
        <FormFieldT2 className={ss.colField} name="passportNum">
          <FormInputMUIT2 label={loc('reg.passportNum')} autoComplete="off " />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="issuedBy">
          <FormInputMUIT2 label={loc('reg.issuedBy')} autoComplete="off " />
        </FormFieldT2>
        <FormFieldT2 className={ss.colField} name="issueDate" style={{ width: '18%' }}>
          {/* <FormInputMUIT2 label={loc('reg.issueDate')} autoComplete="off " /> */}
          <FormLocaleDatePickerMUIT2 label={loc('reg.issueDate')} />
        </FormFieldT2>
      </div>
      <div className={clsx(ss.field, ss.doubleField, { [s.showPassword]: showPassword })}>
        <FormFieldT2 className={clsx(ss.colField, s.passwordField)} name="password" fast={false}>
          <FormInputMUIT2
            type={showPassword ? 'text' : 'password'}
            label={isIndividualRegistred ? loc('reg.password.current') : loc('reg.password')}
            autoComplete={isIndividualRegistred ? undefined : 'new-password'}
            icon={EyeIcon}
            onIconClick={toggleShowPassword}
            iconClassName={s.eye}
          />
        </FormFieldT2>
        {!isIndividualRegistred && (
          <FormFieldT2 className={ss.colField} name="repeatPassword" fast={false}>
            <FormInputMUIT2
              type={showPassword ? 'text' : 'password'}
              label={loc('reg.confirm')}
              autoComplete="new-password"
            />
          </FormFieldT2>
        )}
      </div>
    </Fragment>
  );
};
