import debounce from 'debounce-promise';
import React, { useCallback, useMemo } from 'react';
import { queryVstoreTnvedCriteria } from 'rest';
import { useLocale } from 'services/l10n';
import { FormAutocomplete, FormAutocompleteProps } from 'ui/common';

type Props = FormAutocompleteProps & {
  date?: string;
  className?: string;
};

export const FormAutocompleteTnved: React.FC<Props> = ({ field, date, className, ...rest }) => {
  const loadOptions = useMemo(() => getLoadOptions(date), [date]);
  const { chooseByKey } = useLocale();

  const getOptionLabel = useCallback(
    (data: any) => {
      return `${data?.code} ${chooseByKey(data, 'name')}`;
    },
    [chooseByKey]
  );

  return (
    <FormAutocomplete
      className={className}
      variant="product-table"
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getSingleValue={getFormAutocompleteTnvedSingleValue}
      field={
        field
          ? {
              ...field,
              value: typeof field.value === 'string' ? { code: field.value } : field.value,
            }
          : undefined
      }
      {...rest}
    />
  );
};

const debouncedQueryVstoreTnvedCriteria = debounce(queryVstoreTnvedCriteria, 300);

function getLoadOptions(date?: string) {
  return async function (term: string) {
    console.log({ date, term });
    if (term.length >= 5) {
      const { data } = await debouncedQueryVstoreTnvedCriteria({ code: term, date });
      return Object.values(data).filter(Boolean);
    }
    return [];
  };
}

export const getFormAutocompleteTnvedSingleValue = (data: any) => {
  return data?.code || '';
};
