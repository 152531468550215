import { queryEnterpriseList } from 'rest';
import { LocaleStore } from 'services/l10n';
import { getNotSelectedOptions } from '.';

export const getEnterpriseOptionsAsync = async ({ locale }: { locale: LocaleStore }): Promise<any[]> => {
  const res = await queryEnterpriseList();
  if (Array.isArray(res.data)) {
    return [...getNotSelectedOptions(locale), ...res.data];
  }
  return getNotSelectedOptions(locale);
};

export const getEnterpriseOptionLabel = (data: any) => {
  return data.label ?? `${data.tin} - ${data.name}`;
};

export const getEnterpriseOptionValue = (data: any) => {
  return data.value ?? data.tin;
};
