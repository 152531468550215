import { API_URL } from 'common/constants';
import { downloadFile } from 'rest';

type Config = {
  ids: Array<string>;
  format: 'PDF' | 'XLSX';
  isAdmin?: boolean;
};

export const printReportSnt = async (config: Config) => {
  const { ids, isAdmin, format } = config;
  const queries: Promise<any>[] = [];
  ids.forEach((id) => {
    const url = isAdmin
      ? `${API_URL}/admin/snt/printReport/${id}/${format}`
      : `${API_URL}/snt/printReport/${id}/${format}`;
    queries.push(
      downloadFile({
        url,
        method: 'GET',
      })
    );
  });

  try {
    const res = await Promise.all(queries);
    return res;
  } catch (error) {
    throw error;
  }
};
