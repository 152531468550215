import React from 'react';
import clsx from 'clsx';
import s from './EaecLayoutFooter.module.scss';
import { useLocale } from 'services/l10n';
import { SUPPORT_EMAIL, SUPPORT_PHONE } from 'common/constants';

type Props = {
  className?: string;
};

export const EaecLayoutFooter: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.inner}>
        <div className={s.col}>
          <span>
            {loc('common.layout.support')}: <b>{SUPPORT_PHONE}</b> (<span>{loc('common.layout.hours')}</span>),
            <br />
            <b>{SUPPORT_EMAIL}</b> (<span style={{ textTransform: 'lowercase' }}>{loc('common.layout.24.7')}</span>)
          </span>
        </div>
        <div className={s.col}></div>
      </div>
    </div>
  );
};
