import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';
import s from './EaecOperationsPage.module.scss';
import { Loader, PageTitle, Table } from 'ui/common';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { queryEaecOperationInputId, queryEaecOperationOutputId } from '../api';
import { getEaecOperationsColumns } from './getEaecOperationsColumns';

type Props = {
  className?: string;
  onClose?: () => void;
  id: string;
  direction: 'input' | 'output';
};

const getProductId = (row: any) => row.id;

export const EaecOperationsPage: React.FC<Props> = ({ className, onClose, id, direction }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, error } = useDataQuery(direction === 'input' ? queryEaecOperationInputId : queryEaecOperationOutputId, {
    id: id,
  });

  const productsColumns = useMemo(() => getEaecOperationsColumns({ locale, direction }), [locale, direction]);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('eaecActions.operationInfoFull')}</PageTitle>
      <BackButton onClick={onClose} />
      {!!data ? (
        <Table
          columns={productsColumns}
          className={s.table}
          rows={data.eecGoodsOperations}
          getRowId={getProductId}
          scrollable
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
