import React from 'react';
import s from './MainLogo.module.scss';
import clsx from 'clsx';

type Props = {
  className?: string;
  minimized?: boolean;
  text?: string;
  onClick?(): void;
};

export const MainLogo: React.FC<Props> = ({ className, minimized, text, onClick = () => {} }) => {
  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.minimized]: minimized,
      })}
      onClick={onClick}
    >
      <div className={s.logo} />
      <div className={s.text}>{text}</div>
    </div>
  );
};
