import React, { useMemo } from 'react';
import { PATH } from 'routing/paths';
import { useLocale } from 'services/l10n';
import { Breadcrumbs, Crumb } from 'ui';

type Props = {
  className?: string;
  section: 'news' | 'forDevelopers' | 'help';
  pageCrumbs?: Array<Crumb>;
};

export const InsideBreadcrumbs: React.FC<Props> = ({ className, section, pageCrumbs = [] }) => {
  const locale = useLocale();
  const { loc } = locale;

  const fullCrumbs = useMemo(
    () => {
      const crumbs: Array<Crumb> = [{ label: loc('common.main.main'), link: PATH.MAIN }];
      if (section === 'news') {
        crumbs.push(
          {
            label: loc('common.nav.news'),
            link: pageCrumbs.length ? `${PATH.PAGES}${PATH.NEWS}` : undefined,
          },
          ...pageCrumbs
        );
      } else if (section === 'forDevelopers') {
        crumbs.push({ label: loc('common.nav.developers') }, ...pageCrumbs);
      } else if (section === 'help') {
        crumbs.push({ label: loc('inside.help') }, ...pageCrumbs);
      }
      return crumbs;
    },
    [] // eslint-disable-line
  );

  return <Breadcrumbs crumbs={fullCrumbs} className={className} />;
};
