import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './JournalSettings.module.scss';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormCheckbox, FormField, TableColumn } from 'ui/common';
import * as yup from 'yup';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { ModalChildrenProps } from 'services/modal/types';
import { useLocale } from 'services/l10n';

type Props = ModalChildrenProps & {
  className?: string;
  columns: Array<TableColumn>;
  setColumns: (columns: Array<TableColumn>) => void;
};

const schema = yup.object({});

// Убираем точки и брекеты из имен, чобы получить плоскую структуру в values формы
const encodeFieldName = (columnName: string) => {
  return columnName.replace(/[.[\]]/g, '$');
};

export const JournalSettings: React.FC<Props> = ({ className, columns, setColumns, onClose }) => {
  const { loc } = useLocale();
  const submitHandler = (values: any, actions: FormikHelpers<any>) => {
    setColumns(
      columns.map((col) => ({
        ...col,
        hidden: !values[encodeFieldName(col.name)],
      }))
    );
    if (typeof onClose === 'function') onClose();
  };

  const initialValues = useMemo(
    () => Object.fromEntries(columns.map((col) => [encodeFieldName(col.name), !col.hidden])),
    [columns]
  );

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('common.title.journalSettings')}</ModalHeader>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
        {({ values }) => (
          <Form>
            <ModalContent className={s.content}>
              <div className={s.body}>
                {/* {console.log(values)} */}
                {columns
                  .filter((item) => !item.hideInSettings)
                  .map((item) => (
                    <FormField
                      key={item.name}
                      name={encodeFieldName(item.name)}
                      direction="line-reverse"
                      className={s.field}
                      labelClassName={s.label}
                      label={item.label}
                    >
                      <FormCheckbox />
                    </FormField>
                  ))}
              </div>
            </ModalContent>
            <ModalFooter>
              <Button
                // disabled={!isValid}
                type="submit"
                className={s.submit}
                size="small"
              >
                {loc('common.button.done')}
              </Button>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </div>
  );
};
