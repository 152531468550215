import { AxiosResponse } from 'axios';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';
import { OFFLINE_DEV } from 'common/constants';

export const queryVariables: DataQueryFunction = async ({ language }) => {
  if (process.env.NODE_ENV === 'development' && OFFLINE_DEV) {
    return { data: {} } as AxiosResponse;
  }

  return httpClient.get(`variables`, { params: { language } });
};
