import clsx from 'clsx';
import React, { SyntheticEvent, useMemo } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';
import { Locale } from 'date-fns';
import { DT_FORMAT } from 'services/date-time';
import { UITheme } from 'ui/common/types';
import { DatePickerHeader, DatePickerInput } from './components';

export type DatePickerProps = {
  onChange: (value: string, event: React.SyntheticEvent<any, Event> | undefined) => void;
  onBlur?: (e: SyntheticEvent) => void;
  name?: string;
  value?: any;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  includeDates?: Date[];
  error?: boolean;
  valueFormat?: string;
  showTimeSelect?: boolean;
  placeholderText?: string;
  timeCaption?: string;
  locale?: Locale;
  formatFromDate?: (date: Date, format: string) => string;
  formatToDate?: (value: string, format: string) => Date | undefined;
  showMonthYearPicker?: boolean;
  showYearPicker?: boolean;
  maxDate?: Date | null | undefined;
  minDate?: Date | null | undefined;
  theme?: UITheme;
  size?: 'small' | 'default' | 'large';
  variant?: 'mui';
  /**
   * используется только в варианте 'material'
   */
  label?: string;
};

export const DatePicker: React.FC<DatePickerProps> = ({
  className,
  onChange,
  name,
  value,
  valueFormat: propsValueFormat,
  showTimeSelect,
  placeholderText = 'Выберите дату',
  locale,
  formatFromDate = (date) => String(date.valueOf()),
  formatToDate = (value) => new Date(Number(value)) || undefined,
  error,
  disabled,
  readOnly,
  showMonthYearPicker = false,
  showYearPicker = false,
  theme = 'T1',
  size = 'default',
  variant,
  label,
  ...rest
}) => {
  const valueFormat = propsValueFormat || showTimeSelect ? DT_FORMAT.DEFAULT_DATE_TIME : DT_FORMAT.DEFAULT_DATE;

  const selected = useMemo(() => {
    if (!value) return null;
    return formatToDate(value, valueFormat);
  }, [value, valueFormat, formatToDate]);

  const onDateChange = (date: Date | [Date, Date] | null, event?: React.SyntheticEvent<any, Event>) => {
    const newValue = date && !Array.isArray(date) ? formatFromDate(date, valueFormat) : '';
    console.log({ newValue });
    onChange(newValue, event);
  };

  return (
    <div
      className={clsx('data-picker', className, `data-picker--${theme}`, `data-picker--${size}`, {
        error: error,
        hasValue: !!selected,
        'data-picker--mui': variant === 'mui',
      })}
    >
      <ReactDatePicker
        name={name}
        selected={selected}
        onChange={onDateChange}
        dateFormat={
          showYearPicker ? 'yyyy' : showMonthYearPicker ? 'MM.yyyy' : showTimeSelect ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'
        }
        locale={locale}
        placeholderText={placeholderText}
        showTimeSelect={showTimeSelect}
        disabled={disabled || readOnly}
        showMonthYearPicker={showMonthYearPicker}
        showYearPicker={showYearPicker}
        className={clsx({
          'control-error': error,
        })}
        yearItemNumber={15}
        renderCustomHeader={(props) => <DatePickerHeader {...props} locale={locale} />}
        showPopperArrow={false}
        customInput={
          !showYearPicker && !showMonthYearPicker ? (
            <DatePickerInput label={label} variant={variant} showTimeSelect={showTimeSelect} />
          ) : undefined
        }
        preventOpenOnFocus
        {...rest}
      />
    </div>
  );
};
