import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import s from './UserInfoT2.module.scss';
import { useUser } from 'common/hooks';
import { SvgIcon, useOutsideClick2 } from 'ui/common';
import { ProfileSelect } from 'common/components';

type Props = {
  className?: string;
};

export const UserInfoT2: React.FC<Props> = ({ className }) => {
  const { currentUser } = useUser();
  const { taxpayer } = currentUser || {};

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<any>();
  useOutsideClick2(
    dropdownRef,
    () => {
      if (showDropdown) {
        setShowDropdown(false);
      }
    },
    showDropdown
  );

  const currentProfile =
    currentUser &&
    Array.isArray(currentUser?.profiles) &&
    currentUser.profiles.find((p) => p.id === currentUser.currentProfileId);

  return taxpayer ? (
    <div className={clsx(className, s.wrapper)} onClick={() => setShowDropdown(true)}>
      <div className={s.inner}>
        <div className={s.name}>{!!currentProfile && currentProfile.name}</div>
        <SvgIcon className={s.chevronIcon} name="chevron-down" />
        <div className={s.tin}>ИИН {taxpayer.tin}</div>
      </div>
      {showDropdown && (
        <div ref={dropdownRef} className={s.dropdown}>
          <ProfileSelect />
        </div>
      )}
    </div>
  ) : null;
};
