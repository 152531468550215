import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { DataQueryFunction, downloadFile } from 'rest';

export const queryZvtExport: DataQueryFunction<{ filter: any }> = async (variables) => {
  const { filter } = variables || {};

  const criteria = JSON.stringify(clearEmptyFields(filter));

  const res = await downloadFile({
    url: `${API_URL}/vstore/rest/zvt/export`,
    method: 'GET',
    params: { criteria },
  });
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
