import { clearEmptyFields } from 'common/utils';
import { toPaginationParams } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction, DataQueryVariables } from '../../types';

export const queryUsers: DataQueryFunction = (variables: DataQueryVariables) => {
  const { filter, pagination } = variables || {};

  let params = clearEmptyFields(filter);

  const criteria = JSON.stringify(clearEmptyFields(params));

  return httpClient.get(`/arm/users`, {
    params: { criteria, ...toPaginationParams(pagination) },
  });
};
