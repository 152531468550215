import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './EaecProductsPage.module.scss';
import { Button, Loader, PageTitle, Table } from 'ui/common';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { mutationAdminEecRequestGoodsOperations, queryEaecDocumentInputId, queryEaecDocumentOutputId } from '../api';
import { getEaecProductsColumns } from './getEaecProductsColumns';
import { useStore } from 'common/hooks';

type Props = {
  className?: string;
  onClose?: () => void;
  id: string;
  direction: 'input' | 'output';
};

const getProductId = (row: any) => row.productPosition;

export const EaecProductsPage: React.FC<Props> = ({ className, onClose, id, direction }) => {
  const { locale, auth } = useStore();
  const { loc } = locale;

  const { data, error } = useDataQuery(direction === 'input' ? queryEaecDocumentInputId : queryEaecDocumentOutputId, {
    id: id,
  });

  const [selected, setSelected] = useState<any[]>([]);

  const productsColumns = useMemo(() => getEaecProductsColumns({ locale, direction }), [locale, direction]);

  const selectionPreRequest = async (selection: Array<any>) => {
    const hash = JSON.stringify({
      documentNumber: data.documentRegistrationNumber,
      docCreateDate: data.documentCreateDate,
      objectOrdinal: selection[0].productPosition,
      invoiceDocId: data.documentId,
      eecCountry: data.senderCountryCode,
    });
    const signData = await auth.getSignature(hash);
    try {
      await mutationAdminEecRequestGoodsOperations({
        body: {
          certificate: signData.cert?.base64Pem || '',
          signature: signData.sign || '',
          request: hash,
        },
      });
      ToastService.showSuccess(loc('eaecActions.requestSent'));
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitle>{loc('eaecActions.productsInfo')}</PageTitle>
      <BackButton onClick={onClose} />
      <div className={s.actions}>
        {direction === 'input' && (
          <Button onClick={() => selectionPreRequest(selected)} disabled={selected.length !== 1}>
            {loc('eaecActions.preRequest')}
          </Button>
        )}
      </div>
      {!!data ? (
        <Table
          columns={productsColumns}
          className={s.table}
          rows={data.eecDocumentProducts}
          getRowId={getProductId}
          scrollable
          selectable
          onChangeSelected={(s) => setSelected(s)}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};
