import { LocaleStore } from 'services/l10n';
import { TableColumn } from 'ui/common';

export const getGovInstJournalColumns = ({
  locale: { loc, chooseByKey },
}: {
  locale: LocaleStore;
}): Array<TableColumn> => [
  {
    name: 'tin',
    label: loc('common.labels.binOnly'),
  },
  {
    name: 'name',
    label: loc('common.labels.companyName2'),
    getContent: (row) => chooseByKey(row, 'name') || null,
    width: 350,
  },
  {
    name: 'createDate',
    label: loc('govInst.createDate'),
    width: 250,
  },
];
