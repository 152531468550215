/**
 * режим в котором открыт бланк (новый, просмотр, редактирование)
 * определяет намерение пользователя, какую операцию делает пользователь
 * это значение определяет путь бланка /snt/{mode}/id
 */
export enum SntMode {
  show = 'show',
  new = 'new',
  draft = 'draft',
  newPaper = 'new-paper',
  copy = 'copy',
  fixed = 'fixed',
  return = 'return',
}

export enum SntType {
  /**
   * Первичная СНТ
   */
  PRIMARY_SNT = 'PRIMARY_SNT',

  /**
   * СНТ на возврат
   */
  RETURNED_SNT = 'RETURNED_SNT',

  /**
   * Исправленная СНТ
   */
  FIXED_SNT = 'FIXED_SNT',
}

export enum SntStatus {
  /**
   * Черновик
   */
  DRAFT = 'DRAFT',

  /**
   * Не просмотрен
   */
  NOT_VIEWED = 'NOT_VIEWED',

  /**
   * Доставлен
   */
  DELIVERED = 'DELIVERED',

  /**
   * Созданный
   */
  CREATED = 'CREATED',

  /**
   * Черновик импортирован
   */
  IMPORTED = 'IMPORTED',

  /**
   * Ошибочный
   */
  FAILED = 'FAILED',

  /**
   * Подтвержден
   */
  CONFIRMED = 'CONFIRMED',

  /**
   * Отклонен
   */
  DECLINED = 'DECLINED',

  /**
   * Подтвержден инспектором ОГД
   */
  CONFIRMED_BY_OGD = 'CONFIRMED_BY_OGD',

  /**
   * Отклонен инспектором ОГД
   */
  DECLINED_BY_OGD = 'DECLINED_BY_OGD',

  /**
   * Аннулирован
   */
  CANCELED = 'CANCELED',

  /**
   * Отозван
   */
  REVOKED = 'REVOKED',

  /**
   * Заблокирован ИС ЭСФ
   */
  SEND_TO_ISGO = 'SEND_TO_ISGO',
}

export const SNT_STATUS_CLASSES: { [key in SntStatus]?: string } = {
  [SntStatus.DRAFT]: 'statusColorGray',
  [SntStatus.NOT_VIEWED]: 'statusColorYellow',
  [SntStatus.DELIVERED]: 'statusColorYellow',
  [SntStatus.IMPORTED]: 'statusColorGray',
  [SntStatus.FAILED]: 'statusColorRed',
  [SntStatus.CONFIRMED]: 'statusColorGreen',
  [SntStatus.DECLINED]: 'statusColorRed',
  [SntStatus.CONFIRMED_BY_OGD]: 'statusColorYellow',
  [SntStatus.DECLINED_BY_OGD]: 'statusColorRed',
  [SntStatus.CANCELED]: 'statusColorBlack',
  [SntStatus.REVOKED]: 'statusColorBlack',
  [SntStatus.SEND_TO_ISGO]: 'statusColorBlack',
};

export enum SntBorderCrossingType {
  /**
   * Ввоз в РК
   */
  IMPORT = 'IMPORT',

  /**
   * Вывоз из РК
   */
  EXPORT = 'EXPORT',

  /**
   * Трансфер в рамках ЕАЭС
   */
  TRANSFER = 'TRANSFER',

  /**
   * Ввоз и вывоз в/из РК
   */
  ALL = 'ALL',
}

export const enum SntProductType {
  /**
   * Данные по товарам
   */
  PRODUCT = 'PRODUCT',

  /**
   * Этиловый спирт
   */
  ETHANOL = 'ETHANOL',

  /**
   * Виноматериал
   */
  WINE_MATERIAL = 'WINE_MATERIAL',

  /**
   * Пиво и пивные напитки
   */
  BEER = 'BEER',

  /**
   *Алкогольная продукция (кроме пива и пивного напитка)
   */
  ALCOHOL_EXCEPT_BEER = 'ALCOHOL_EXCEPT_BEER',

  /**
   * Данные по отдельным видам нефтепродуктов
   */
  OIL = 'OIL',

  /**
   * Данные по биотопливу
   */
  BIOFUEL = 'BIOFUEL',

  /**
   * Данные по табачной продукции (исключая цифровую маркировку)
   */
  TOBACCO = 'TOBACCO',

  /**
   * Данные по другим товарам (цифровая маркировка)
   */
  OTHER_PRODUCT_WITH_DIGITAL_MARK = 'OTHER_PRODUCT_WITH_DIGITAL_MARK',

  /**
   * Данные по товарам, подлежащим экспортному контролю ( двойного назначения, военного назначения)
   */
  EXPORT_CONTROL_PRODUCT = 'EXPORT_CONTROL_PRODUCT',
}

export enum SntTpStatus {
  /**
   * Адвокат
   */
  LAWYER = 'LAWYER',

  /**
   * Нотариус
   */
  NOTARY = 'NOTARY',

  /**
   * Медиатор
   */
  MEDIATOR = 'MEDIATOR',

  /**
   * Частный судебный исполнитель
   */
  BAILIFF = 'BAILIFF',

  /**
   * Физическое лицо
   */
  INDIVIDUAL = 'INDIVIDUAL',

  /**
   * Розничная реализация
   */
  RETAIL = 'RETAIL',

  /**
   * Розничный реализатор
   */
  RETAILER = 'RETAILER',

  /**
   * Фармацевтический производитель
   */
  PHARMACEUTIC_PRODUCER = 'PHARMACEUTIC_PRODUCER',

  /**
   * Лизингополучатель
   */
  LESSEE = 'LESSEE',

  /**
   *  Лизингодатель
   */
  LESSOR = 'LESSOR',

  /**
   * Доверитель
   */
  PRINCIPAL = 'PRINCIPAL',

  /**
   * Комитент
   */
  COMMITTENT = 'COMMITTENT',

  /**
   * Комиссионер
   */
  BROKER = 'BROKER',

  /**
   * Участник СРП или сделки, заключенной в рамках СРП
   */
  SHARING_AGREEMENT_PARTICIPANT = 'SHARING_AGREEMENT_PARTICIPANT',

  /**
   * Участник договора совместной деятельности
   */
  JOINT_ACTIVITY_PARTICIPANT = 'JOINT_ACTIVITY_PARTICIPANT',

  /**
   * Малая торговая точка
   */
  SMALL_RETAIL_OUTLET = 'SMALL_RETAIL_OUTLET',

  /**
   * Залогодержатель/хранитель
   */
  PAWNEE_CUSTODIAN = 'PAWNEE_CUSTODIAN',
}

export enum SntTransportType {
  /**
   * Автомобильный (а)
   */
  AUTOMOBILE = 'AUTOMOBILE',

  /**
   * Железнодорожный (b)
   */
  RAILWAY = 'RAILWAY',

  /**
   * Воздушный (c)
   */
  AIR = 'AIR',

  /**
   * Морской или внутренний водный
   */
  MARINE = 'MARINE',

  /**
   * Трубопровод (e)
   */
  PIPELINE = 'PIPELINE',

  /**
   * Мультимодальный (f)
   */
  MULTIMODAL = 'MULTIMODAL',

  /**
   * Прочие (g)
   */
  OTHER = 'OTHER',

  /**
   * Без транспорта
   * Этого значения нет на беке, используется для фильтра в журнале "Фиксация нарушения сроков..."
   */
  NO_TRANSPORT = 'NO_TRANSPORT',
}

export enum SntExportType {
  /**
   * Вывоз товаров с территории РК (Экспорт)
   */
  EXPORT = 'EXPORT',

  /**
   * Вывоз на переработку
   */
  EXPORT_FOR_PROCESSING = 'EXPORT_FOR_PROCESSING',

  /**
   * Временный вывоз
   */
  TEMPORARY_EXPORT = 'TEMPORARY_EXPORT',

  /**
   * Вывоз временно ввезенного товара
   */
  EXPORT_OF_TEMPORARY_IMPORTED_PRODUCT = 'EXPORT_OF_TEMPORARY_IMPORTED_PRODUCT',

  /**
   * Вывоз товаров с территории СЭЗ
   */
  EXPORT_FROM_SEZ = 'EXPORT_FROM_SEZ',

  // /**
  //  * Заправка воздушного судна
  //  */
  // AIRCRAFT_REFUELING = 'AIRCRAFT_REFUELING',
}

export enum SntImportType {
  /**
   * Ввоз товаров на территорию РК (Импорт)
   */
  IMPORT = 'IMPORT',

  /**
   * Ввоз на переработку
   */
  IMPORT_FOR_PROCESSING = 'IMPORT_FOR_PROCESSING',

  /**
   * Временный ввоз
   */
  TEMPORARY_IMPORT = 'TEMPORARY_IMPORT',

  /**
   * Ввоз временно вывезенного товара
   */
  IMPORT_OF_TEMPORARY_EXPORTED_PRODUCT = 'IMPORT_OF_TEMPORARY_EXPORTED_PRODUCT',

  /**
   * Ввоз товаров на территорию СЭЗ
   */
  IMPORT_IN_SEZ = 'IMPORT_IN_SEZ',
}

export enum SntTransferType {
  /**
   * В пределах одного лица на территории РК (A 9.1)
   */
  ONE_PERSON_IN_KZ = 'ONE_PERSON_IN_KZ',

  /**
   * В пределах одного лица в рамках ЕАЭС (A 9.2)
   */
  ONE_PERSON_IN_EAEU = 'ONE_PERSON_IN_EAEU',

  /**
   * Иное перемещение (A 9.3)
   */
  OTHER = 'OTHER',
}
