import React from 'react';
import { FieldProps } from 'formik';
import { Radio, RadioProps } from '../controls';
import { FormValueProps } from './FormValueProps';

export type FormRadioProps = Partial<FieldProps> &
  Omit<RadioProps, 'name' | 'value' | 'onChange' | 'onBlur'> & {
    onValueChange?: (props: FormValueProps<string | boolean>) => void;
    /**
     * В случае радио этот параметр не работает, исключаем его, чтобы он не попал на DOM элемент
     */
    changeOnBlur?: boolean;
  };

export const FormRadio: React.FC<FormRadioProps> = ({
  field,
  form,
  meta,
  onValueChange,
  radioValue,
  changeOnBlur,
  ...rest
}) => {
  const { name, onChange } = field || {};
  const { setFieldValue } = form || {};

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onValueChange && name && form) {
      onValueChange({ value: radioValue, name, event: e, form });
      return;
    }
    if (setFieldValue && name) {
      setFieldValue(name, radioValue);
      return;
    }
    onChange && onChange(e);
  };

  // console.log(String(field?.value), radioValue);

  const checked = field?.value === radioValue;

  return <Radio {...rest} {...field} onChange={onInputChange} checked={checked} radioValue={radioValue} />;
};
