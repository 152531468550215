import React, { Fragment, useEffect } from 'react';
import clsx from 'clsx';
import s from './VirtualLabelChainPage.module.scss';
import { VirtualLabelTree } from './components/VirtualLabelTree';
import { useDataQuery } from 'rest';
import { Button, Loader, PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { FilterPanel } from 'common/components';
import { VirtualLabelChainFilters } from './components/VirtualLabelChainFilters';
import { solveErrorMessage } from 'common/form/helpers';
import { ToastService } from 'services/toasts';
import * as yup from 'yup';
import { queryVirtualLabelChain, queryVirtualLabelChainReport, queryVirtualLabelChainTreePDF } from './api';
import { useQueryParams } from 'common/hooks';
import { fetchProducts } from './helpers';

const schema = yup.object({
  id: yup.string().trim().required('Id товара является обязательным'),
});

type Props = {
  className?: string;
};

export const VirtualLabelChainPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const queryParams = useQueryParams();

  const initialFilter = {
    id: Number(queryParams.id) || undefined,
    documentNumber: queryParams.documentNumber || undefined,
  };

  const { data, filter, setFilter, loading, error, setData } = useDataQuery(
    queryVirtualLabelChain,
    {
      filter: initialFilter,
    },
    {
      skipInitialRequest: true,
    }
  );

  console.log(filter);

  const onFilterSubmit = async (values: any) => {
    setData(null);
    setFilter(values);
  };

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  const exportReport = async (format: string) => {
    try {
      await queryVirtualLabelChainReport({ filter, format });
    } catch (e) {
      console.log('exportReport', e);
      ToastService.showError(solveErrorMessage(e));
    }
  };

  const exportTreePDF = async () => {
    try {
      await queryVirtualLabelChainTreePDF({ filter });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  const headerButtons = [
    {
      id: 1,
      label: loc('common.button.pdf'),
      onClick: () => exportTreePDF(),
    },
    {
      id: 2,
      label: loc('common.button.xlsx'),
      onClick: () => exportReport('XLSX'),
    },
  ];

  // Если при старте страницы есть query параметры id и documentNumber,
  // подгружаем список продуктов и сразу запрашиваем цепочку
  // ?id=103188&documentNumber=KZ-SNT-0001-123456789021-20210728-32827287
  useEffect(() => {
    if (queryParams.id && queryParams.documentNumber) {
      fetchProducts(String(queryParams.documentNumber)).then((prods) => {
        if (prods.length) {
          setFilter((filter) => ({ ...filter, _products: prods }));
        }
      });
    }
  }, []); // eslint-disable-line

  return (
    <div className={clsx(className, s.wrapper, 'container')}>
      <PageTitle>{loc('admin.menu.VIRTUAL_LABEL_CHAIN')}</PageTitle>
      <FilterPanel
        className={s.filters}
        initialValues={filter}
        validationSchema={schema}
        initialOpened={true}
        loading={loading}
        onSubmit={onFilterSubmit}
        buttonLabel={loc('common.button.filters')}
        onClear={(form) => {
          form.resetForm();
          form.setValues(initialFilter);
        }}
        renderContent={({ form, classNames }) => <VirtualLabelChainFilters form={form} classNames={classNames} />}
        renderHeader={() => (
          <Fragment>
            {headerButtons.map((btn) => (
              <Button
                key={btn.id}
                className={s.headerButton}
                onClick={() => {
                  if (typeof btn.onClick === 'function') {
                    return btn.onClick();
                  }
                }}
              >
                {btn.label}
              </Button>
            ))}
          </Fragment>
        )}
      />
      <div className={s.treeWrapper}>
        {loading && <Loader overlay className={s.loader} />}
        {!!data && <VirtualLabelTree data={data} selectedId={filter.id} />}
      </div>
    </div>
  );
};
