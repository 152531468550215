import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { queryVirtualLabelIdByProductId } from './api';
import { openInNewTab } from 'common/utils/openInNewTab';
import { AppMode, BASE_URL } from 'common/constants';
import { PATH } from 'routing/paths';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = {
  className?: string;
  productId: string;
  documentRegNumber: string;
  appMode?: AppMode;
  newTab?: boolean;
};

export const VirtualChainLink: React.FC<Props> = ({ className, children, productId, documentRegNumber, newTab }) => {
  const history = useHistory();

  const onClick: MouseEventHandler<HTMLAnchorElement> = async (e) => {
    e.preventDefault();

    if (productId) {
      try {
        const res = await queryVirtualLabelIdByProductId({
          productId,
          documentNumber: documentRegNumber,
        });
        console.log(res);
        redirectToLink(res.data?.data);
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    }
  };

  const redirectToLink = (productId: string) => {
    if (productId && documentRegNumber) {
      const link = `${PATH.ARM}${PATH.VIRTUAL_LABEL_CHAIN}?id=${productId}&documentNumber=${documentRegNumber}`;
      if (newTab) {
        const fullUrl = `${BASE_URL}${link}`;
        console.log({ fullUrl });
        openInNewTab(fullUrl);
      } else {
        history.push(link);
      }
    }
  };

  return (
    <a
      className={clsx(className)}
      href={`${PATH.ARM}${PATH.VIRTUAL_LABEL_CHAIN}/${productId}`}
      onClick={onClick}
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
