import React from 'react';
import clsx from 'clsx';
import s from '../board/Board.module.scss';
import { Form, Formik, FormikContextType, FormikProps } from 'formik';
import { solveFormErrors } from 'common/form/helpers';
import { FormikOnChange } from 'common/form';

type Props = {
  className?: string;
  initialValues?: any;
  validationSchema?: any;
  onSubmit?: (values: any) => Promise<any>;
  renderContent?: (props: { form: FormikProps<any> }) => React.ReactNode;
  errors?: any;
  loading?: boolean;
};

export const BoardFilter: React.FC<Props> = ({
  className,
  onSubmit = () => Promise.resolve(),
  renderContent,
  initialValues,
  validationSchema,
}) => {
  const formSubmitHandler = async (form: FormikContextType<any>) => {
    try {
      await onSubmit(form.values);
    } catch (error) {
      form.setErrors(solveFormErrors(error));
    }
    form.setSubmitting(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={() => console.log('onSubmit')} validationSchema={validationSchema}>
      {(form) => {
        return (
          <Form className={clsx(className, s.form)}>
            {typeof renderContent === 'function' &&
              renderContent({
                form,
              })}
            <FormikOnChange onChange={formSubmitHandler} debounceTimer={50} />
          </Form>
        );
      }}
    </Formik>
  );
};
