import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import s from './SaldoReportPage.module.scss';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as yup from 'yup';
import { SaldoReportFiltersTaxpayer } from './SaldoReportFiltersTaxpayer';
import { Button, Loader, SvgIcon } from 'ui/common';
import { useLocale } from 'services/l10n';
import { ReactComponent as Picture1 } from './assets/content_fill.svg';
import { ReactComponent as Picture2 } from './assets/content_ready.svg';
import { ToastService } from 'services/toasts';
import { solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { AppMode } from 'common/constants';
import { DT } from 'services/date-time';
import { SaldoReportFiltersArm } from './SaldoReportFiltersArm';

const schema = yup.object({});

type Props = {
  className?: string;
  onSubmit(values: any, format: string): Promise<any>;
  appMode: AppMode;
  initialValues: any;
};

export const SaldoReportForm: React.FC<Props> = ({ className, onSubmit, appMode, initialValues }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [content, setContent] = useState<'empty' | 'fill' | 'done'>('fill');

  const format = useRef<string>('');

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    try {
      await onSubmit(
        {
          ...values,
          startDate: DT.startOfMonth(values._period),
          endDate: DT.endOfMonth(values._period),
          taxpayerStoreIds: values._storeId ? [values._storeId] : undefined,
        },
        format.current
      );
    } catch (error) {
      console.error('form error', error);
      ToastService.showError(solveErrorMessage(error as any));
      actions.setErrors(solveFormErrors(error));
    }
    actions.setSubmitting(false);
  };

  const changeContent = (content: 'fill' | 'done') => {
    setContent('empty');
    setTimeout(() => {
      setContent(content);
    }, 500);
  };

  const onClear = (form: FormikProps<any>) => {
    form.resetForm();
    if (content === 'done') {
      changeContent('fill');
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema}>
      {(form) => {
        const { isSubmitting, submitForm } = form;
        return (
          <Form className={clsx(className, s.form)}>
            {isSubmitting && <Loader overlay />}
            <div className={s.filters}>
              <div className={s.content}>
                {appMode === 'ARM' ? (
                  <SaldoReportFiltersArm form={form} classNames={{ columnClassName: s.column }} />
                ) : (
                  <SaldoReportFiltersTaxpayer form={form} classNames={{ columnClassName: s.column }} />
                )}
              </div>
              <div className={s.footer}>
                <Button variant="secondary" size="small" onClick={() => onClear(form)}>
                  {loc('common.button.clear')}
                </Button>
              </div>
            </div>

            <div className={s.contentContainer}>
              <div
                className={clsx(s.content, {
                  [s.showContent]: content === 'done',
                })}
              >
                <div className={s.pictureContainer}>
                  <SvgIcon className={s.picture} component={Picture2} />
                  {/* <SvgIcon className={s.picture} component={Picture1} /> */}
                </div>
                <div className={s.actions}>
                  <div className={s.actionText}>{loc('saldo.ready')}</div>
                </div>
              </div>

              <div
                className={clsx(s.content, {
                  [s.showContent]: content === 'fill',
                })}
              >
                <div className={s.pictureContainer}>
                  <SvgIcon className={s.picture} component={Picture1} />
                </div>
                <div className={s.actions}>
                  <div className={s.actionText}>{loc('saldo.askFill')}</div>
                  <Button
                    className={s.button}
                    onClick={() => {
                      format.current = 'XLSX';
                      submitForm();
                    }}
                  >
                    {loc('common.button.xlsx')}
                  </Button>
                  <Button
                    className={s.button}
                    onClick={() => {
                      format.current = 'PDF';
                      submitForm();
                    }}
                  >
                    {loc('common.button.pdf')}
                  </Button>
                  <Button
                    className={s.button}
                    onClick={() => {
                      format.current = 'CSV';
                      submitForm();
                    }}
                  >
                    {loc('common.button.downloadCsv')}
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
