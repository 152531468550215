import React from 'react';
import clsx from 'clsx';
import s from './NewsBlock.module.scss';
import { useLocale } from 'services/l10n';
import { DT_FORMAT, formatDate } from 'services/date-time';
import { generatePath, Link } from 'react-router-dom';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
  data: any;
};

export const NewsBlockItem: React.FC<Props> = ({ className, data }) => {
  const locale = useLocale();
  const { choose } = locale;
  return (
    <Link
      to={`${PATH.PAGES}${generatePath(PATH.INSIDE_NEWS_ITEM, { id: data.id })}`}
      className={clsx(className, s.newsItem)}
    >
      <p className={s.date}>{formatDate(data.publishedDate, DT_FORMAT.DEFAULT_DATE_TIME, DT_FORMAT.ISO)}</p>
      <p className={s.title}>{choose([data.titleRu, data.titleKz])}</p>
    </Link>
  );
};
