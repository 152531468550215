import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import s from './PassportPage.module.scss';
import { Button, Loader, PageTitleT2 } from 'ui';
import { useLocale } from 'services/l10n';
import { BackButton } from 'common/components';
import { useDataQuery } from 'rest';
import { queryAdminAssuranceReport, queryAssuranceId } from '../api';
import { PassportDocument } from '../passport/PassportDocument';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { AppMode } from 'common/constants';
import { Modal } from 'services/modal';
import { PassportHistoryModal } from '../passport-history/PassportHistoryModal';

type Props = {
  className?: string;
  onClose?: () => void;
  passportId: string;
};

export const PassportTaxpayerPage: React.FC<Props> = ({ className, onClose, passportId }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [historyModal, setHistoryModal] = useState<boolean>(false);
  const { data, refetch, error } = useDataQuery(queryAssuranceId, {
    id: passportId,
  });

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
      typeof onClose === 'function' && onClose();
    }
  }, [error, onClose]);

  const downloadPDF = async () => {
    try {
      await queryAdminAssuranceReport({
        ids: [passportId],
        format: 'PDF',
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  };

  return (
    <div className={clsx(className, 'container', s.wrapper)}>
      <PageTitleT2>{loc('assurance.pasport')}</PageTitleT2>
      <BackButton onClick={onClose} />
      {!!data ? (
        <div className={s.inner}>
          <PassportDocument data={data} reloadData={refetch} appMode={AppMode.TAXPAYER} />
          <div className={s.actionButtons}>
            <Button size="small" onClick={() => downloadPDF()}>
              {loc('common.button.pdf')}
            </Button>
            <Button variant="secondary" size="small" onClick={() => setHistoryModal(true)}>
              {loc('assurance.viewHistory')}
            </Button>
            <Button
              className={s.buttonGap}
              variant="secondary"
              size="small"
              onClick={() => typeof onClose === 'function' && onClose()}
            >
              {loc('common.button.close')}
            </Button>
          </div>
          <Modal isOpen={historyModal} onClose={() => setHistoryModal(false)}>
            <PassportHistoryModal
              passportId={data.passportId}
              regNumber={data.registrationNumber}
              regDate={data.inputDate}
            />
          </Modal>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};
