import React from 'react';
import s from './ProductTable.module.scss';
import clsx from 'clsx';
import { ProductTableColumn } from './types';

type Props = {
  className?: string;
  columns: Array<ProductTableColumn>;
  /**
   * Словарь значений для ряда Итого: имя поля итого -> значение
   */
  totalsValues: any;
  /**
   * Таблица соответствия для ряда Итого: имя столбца -> имя поля итого
   */
  totalsFields: { [key: string]: string };
};

export const ProductTableTotalsRow: React.FC<Props> = ({ className, columns, totalsValues, totalsFields }) => {
  return (
    <tr className={clsx(className, s.row, s.totalRow)}>
      <td></td>
      {columns.map((col) => {
        return (
          <td key={col.name} className={clsx(s.column)}>
            {totalsValues[totalsFields[col.name || '']] || ''}
          </td>
        );
      })}
    </tr>
  );
};
