import React, { FC, Fragment, useEffect } from 'react';
import s from './ModalJournalContentT2.module.scss';
import clsx from 'clsx';
import { useLocale } from 'services/l10n';
import { DataQueryFilter, DataQueryProps } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { FilterPanelModal, FilterT2ContentProps } from 'common/components';
import { InputPagination, Loader, PageSizeSelect, TableColumn, TableT2 } from 'ui';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { SessionStorageService } from 'services/storage';

const emptyFilter = {};

type Props = ModalChildrenProps & {
  className?: string;
  modal?: boolean;
  onChangeSelected?: (selection: Array<any>) => void;
  columns?: Array<TableColumn>;
  queryProps: DataQueryProps;
  filterComponent: FC<FilterT2ContentProps & any>;
  filterStorageKey?: string;
  defaultFilter?: DataQueryFilter;
  getRows?: (data: any | undefined) => Array<any> | undefined;
  getRowId?: (row: any, index: number) => string | number;
  /**
   * Режим выбора строк в таблице: Множествееный (multi), только одну строку (single)
   */
  selectableMode?: 'multi' | 'single';
};

export const ModalJournalContentT2: React.FC<Props> = ({
  className,
  onChangeSelected,
  columns,
  queryProps,
  filterComponent: FilterComponent,
  filterStorageKey,
  defaultFilter = emptyFilter,
  getRows = (data): Array<any> => (Array.isArray(data?.rows) ? data?.rows : undefined),
  getRowId,
  selectableMode,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const { data, loading, error, filter, setFilter, pagination, setPagination } = queryProps;

  const onFilterSubmit = async (values: { [key: string]: string }) => {
    setFilter(values);
  };

  const rows = getRows(data);

  const showResult = !!data && Array.isArray(rows) && !!rows.length;

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (filterStorageKey) {
      SessionStorageService.set(filterStorageKey, filter);
    }
  }, [filter, filterStorageKey]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <FilterPanelModal
        className={s.filters}
        initialValues={filter}
        onSubmit={onFilterSubmit}
        onClear={(form) => form.setValues(defaultFilter)}
        renderContent={(form, classNames) => <FilterComponent form={form} classNames={classNames} />}
      />
      <div className={s.content}>
        {showResult ? (
          <Fragment>
            <TableT2
              className={s.table}
              columns={columns}
              rows={rows}
              selectable={true}
              selectableMode={selectableMode}
              onChangeSelected={onChangeSelected}
              getRowId={getRowId}
              scrollable
              loading={loading}
              loaderRender={() => <Loader />}
            />
            <div className={s.paginationRow}>
              <PageSizeSelect
                pageSize={pagination.rows || 15}
                onPageSizeChange={(rows) => setPagination((state) => ({ pageNum: 1, rows }))}
                theme="T2"
              />
              <span className={s.paginationLabel}>{loc('common.labels.pageSize')}</span>
              <InputPagination
                onPageChange={(pageNum) => setPagination((state) => ({ ...state, pageNum }))}
                currentPage={pagination.pageNum}
                maxLength={3}
                lastPage={data.lastBlock}
                theme="T2"
                className={s.paginationInput}
              />
            </div>
          </Fragment>
        ) : loading ? (
          <Loader className={s.loader} />
        ) : null}
      </div>
    </div>
  );
};
