import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';

export const mutationNoAuthSignQr: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body, wssId, docType } = variables;

  const res = await httpClient.post(
    `/mgovSign/raw/noAuth/sign/qr?wssId=${wssId}${docType ? `&documentTypeForSign=${docType}` : ''}`,
    JSON.stringify(body),
    {
      headers: { 'Content-Type': 'application/json' },
      transformRequest: (data) => data,
      responseType: 'blob',
    }
  );

  if (res.data instanceof Blob) {
    return res;
  } else {
    throw res;
  }
};
