import React from 'react';
import clsx from 'clsx';
import s from './SelectMethodModal.module.scss';
import { ModalT2Content, ModalT2Header } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ButtonT2 } from 'ui';

type Props = {
  className?: string;
  onSelect: (method: 'CERT' | 'QR') => void;
  type?: 'AUTH' | 'SIGN';
};

export const SelectMethodModal: React.FC<Props> = ({ className, onSelect, type = 'AUTH' }) => {
  const locale = useLocale();
  const { loc } = locale;
  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalT2Header>{loc(`common.${type}.method`)}</ModalT2Header>
      <ModalT2Content className={s.content}>
        <ButtonT2 onClick={() => onSelect('CERT')}>{loc(`common.${type}.cert`)}</ButtonT2>
        <ButtonT2 onClick={() => onSelect('QR')}>{loc(`common.${type}.qr`)}</ButtonT2>
      </ModalT2Content>
    </div>
  );
};
