import React from 'react';
import clsx from 'clsx';
import s from './NewsBlock.module.scss';
import { queryGetPublishedNews, useDataQuery } from 'rest';
import { NewsBlockItem } from './NewsBlockItem';
import { Link } from 'react-router-dom';
import { useLocale } from 'services/l10n';
import { PATH } from 'routing/paths';

type Props = {
  className?: string;
};

export const NewsBlock: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data } = useDataQuery(queryGetPublishedNews);

  return (
    <div className={clsx(className, s.wrapper, { [s.transparent]: !data })}>
      {Array.isArray(data) && data.slice(0, 3).map((news) => <NewsBlockItem key={news.id} data={news} />)}
      <Link className={s.newsLink} to={`${PATH.PAGES}${PATH.NEWS}`}>
        {loc('common.nav.allNews')}
      </Link>
    </div>
  );
};
