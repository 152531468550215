import { action, makeObservable, observable } from 'mobx';

export type TreeStoreNode = {
  expanded: boolean;
  root: boolean;
  parentId: string | number | undefined;
};

export class TreeStore {
  nodes: { [key: string]: TreeStoreNode } = {};

  constructor(nodes: { [key: string]: TreeStoreNode }) {
    this.nodes = nodes;
    makeObservable(this, {
      nodes: observable,
      setExpanded: action,
      setExpandedAll: action,
    });
  }

  setExpanded(id: string | number | Array<string | number>, expanded: boolean) {
    const ids = Array.isArray(id) ? id : [id];
    ids.forEach((nodeId) => {
      this.nodes[nodeId].expanded = expanded;
    });
  }

  setExpandedAll(expanded: boolean) {
    Object.keys(this.nodes).forEach((id) => {
      if (!this.nodes[id].root) {
        this.nodes[id].expanded = expanded;
      }
    });
  }

  // открытие ветки с выбранным Id
  openSelectedIdBranch = (id: string | number) => {
    const ids: Array<string | number> = [];

    const checkParent = (id: string | number) => {
      const parentId = this.nodes[id]?.parentId;
      if (parentId && this.nodes[parentId]) {
        ids.push(parentId);
        checkParent(parentId);
      }
    };

    checkParent(id);
    this.setExpanded(ids, true);
  };
}
