import { API_URL } from 'common/constants';
import { DataQueryFunction, downloadFile } from 'rest';
import { createCriteria } from './queryAdminMessagesSearch';

export const queryAdminMessagesExport: DataQueryFunction = async (variables: any) => {
  const { filter, format } = variables || {};

  const criteria = createCriteria(filter);

  const res = await downloadFile({
    url: `${API_URL}/admin/messages/export`,
    method: 'GET',
    params: { criteria, reportFormat: format },
  });
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
