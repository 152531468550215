import React, { useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import s from 'features/registration/Registration.module.scss';
import { CardMain, Loader } from 'ui';
import { useLocale } from 'services/l10n';
import { observer } from 'mobx-react-lite';
import { queryRegistrationOauth2State } from 'rest';
import { SessionStorageService } from 'services/storage';
import { useQueryParams } from 'common/hooks';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { useDialog } from 'services/modal';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { RegSelectedSubTypes, RegStep, RegistrationStore } from 'features/registration';
import {
  REGISTRATION_SUB_TYPES_STORAGE_KEY,
  RegTypeSelect,
} from 'features/registration/steps/reg-type-select/RegTypeSelect';
import { RegDoneStep } from 'features/registration/steps/reg-done/RegDoneStep';
import { RegDataStep } from 'features/registration/steps/reg-data/RegDataStep';
import { RegContract } from 'features/registration/steps/reg-contract/RegContract';
import { RegAgreementStep } from 'features/registration/steps/reg-agreement/RegAgreementStep';

type Props = {
  className?: string;
};

export const MainRegistrationPage: React.FC<Props> = observer(({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const dialog = useDialog();
  const history = useHistory();

  const { state, biometricsNotRequired } = useQueryParams();
  const selectedSubTypes: RegSelectedSubTypes | undefined = useMemo(() => {
    if (state) {
      return SessionStorageService.get(REGISTRATION_SUB_TYPES_STORAGE_KEY);
    } else {
      // SessionStorageService.remove(REGISTRATION_SUB_TYPES_STORAGE_KEY);
    }
  }, []); // eslint-disable-line

  console.log({ biometricsNotRequired });

  const regStore = useRef(
    new RegistrationStore(state ? 'regData' : 'typeSelect', biometricsNotRequired === 'true')
  ).current;
  // const regStore = useRef(new RegistrationStore('contract')).current;

  const tabs = useMemo((): { name: RegStep; label: string }[] => {
    const tabs: { name: RegStep; label: string }[] = [
      {
        name: 'typeSelect',
        label: loc('reg.typeSelect'),
      },
      {
        name: 'regData',
        label: loc('reg.regData'),
      },
    ];

    if (state && (selectedSubTypes?.attorney || selectedSubTypes?.operator)) {
      tabs.push({
        name: 'contract',
        label: loc('reg.contract'),
      });
    }

    tabs.push({
      name: 'agreement',
      label: loc('reg.agreement'),
    });

    return tabs;
  }, [loc, state, selectedSubTypes]);

  useEffect(() => {
    const fetchDigitalIDState = async (state: string) => {
      try {
        const { data } = await queryRegistrationOauth2State({ state });
        console.log(data);

        if (Array.isArray(data.registrationTypes)) {
          const selectedTypes = Object.fromEntries(data.registrationTypes.map((type: string) => [type, true]));
          // Если пользовватель уже зарегистрирован как ФЛ, удаляем этот тип
          if (data.activeRegistrationTypes?.includes('INDIVIDUAL')) {
            delete selectedTypes.INDIVIDUAL;
          } else {
            selectedTypes.INDIVIDUAL = true;
          }

          if (selectedTypes.ENTERPRISE && !data?.enterprises?.length) {
            const confirm = await dialog.show({
              content: loc('reg.noEnterprises'),
              center: true,
              confirmButtonText: loc('reg.continue'),
            });
            if (confirm) {
              delete selectedTypes.ENTERPRISE;
            } else {
              history.push(PATH.REGISTRATION);
            }
          }
          regStore.setDid(data);
          regStore.setSelectedTypes(selectedTypes);
        }
        if (selectedSubTypes) {
          regStore.setSelectedSubTypes(selectedSubTypes);
        }
      } catch (error: any) {
        if (error?.data?.errorCode) {
          ToastService.showError(loc(`reg.didError.${error.data.errorCode}`, '', error.data.errorCode));
        } else {
          ToastService.showError(solveErrorMessage(error));
        }
        regStore.setCurrentStep('typeSelect');
      }
    };

    if (typeof state === 'string') {
      fetchDigitalIDState(state);
    }
  }, []); // eslint-disable-line

  // console.log(toJS(regStore.selectedSubTypes));
  // console.log(toJS(regStore.selectedTypes));

  useEffect(() => {
    console.log('mount registration');
  }, []);

  return (
    <div className={clsx(className, s.wrapper)}>
      {regStore.currentStep === 'done' ? (
        <RegDoneStep regStore={regStore} />
      ) : (
        <CardMain className={s.card}>
          <h1>{loc('reg.title')}</h1>
          <div className={clsx(s.container, s.tabs)}>
            {tabs.map((tab, index) => (
              <div
                key={tab.name}
                className={clsx(s.tab, {
                  [s.activeTab]: regStore.currentStep === tab.name,
                })}
                onClick={() => {}}
              >
                <div className={s.tabNumber}>{index + 1}</div>
                {tab.label}
              </div>
            ))}
          </div>
          <hr className={s.hr} />
          {regStore.currentStep === 'typeSelect' && (
            <RegTypeSelect
              regStore={regStore}
              onNext={() => regStore.setCurrentStep('regData')}
              onBack={() => history.push(PATH.MAIN)}
            />
          )}
          {regStore.currentStep === 'regData' &&
            (regStore.did || regStore.taxpayerFromCertificate ? (
              <RegDataStep
                regStore={regStore}
                onBack={() => {
                  regStore.registrationByCertificate
                    ? regStore.setCurrentStep('typeSelect')
                    : history.push(PATH.REGISTRATION);
                }}
                onNext={() => {
                  if (regStore.selectedSubTypes.attorney || regStore.selectedSubTypes.operator) {
                    regStore.setCurrentStep('contract');
                  } else {
                    regStore.setCurrentStep('agreement');
                  }
                }}
              />
            ) : (
              <Loader className={s.loader} />
            ))}
          {regStore.currentStep === 'contract' && (
            <RegContract
              regStore={regStore}
              onBack={() => {
                regStore.setCurrentStep('regData');
              }}
              onNext={() => {
                regStore.setCurrentStep('agreement');
              }}
            />
          )}
          {regStore.currentStep === 'agreement' && (
            <RegAgreementStep
              regStore={regStore}
              onBack={() => regStore.setCurrentStep('regData')}
              onNext={() => regStore.setCurrentStep('done')}
            />
          )}
        </CardMain>
      )}
    </div>
  );
});
