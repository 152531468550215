import { DataQueryFunction, httpClient } from 'rest';

export const queryEaecDocumentOutputRelated: DataQueryFunction = async (variables: any) => {
  const { docId } = variables || {};

  const res = await httpClient.get(`/admin/eec/traceability/document/output/related`, {
    params: {
      docId,
    },
  });
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
