import React, { useCallback, useMemo, useRef } from 'react';
import clsx from 'clsx';
import s from './VirtualLabelTree.module.scss';
import { VirtualLabelCard } from './VirtualLabelCard';
import { TreeNode } from 'common/components';
import { TreeStore, TreeStoreNode } from 'common/components/tree-node/TreeStore';
import { Button } from 'ui/common';
import { useScrollDrag } from 'common/hooks/useScrollDrag';
import { useLocale } from 'services/l10n';

const getNodeId = (data: any) => data?.virtualLabelNodeDetail?.id;

const getChildren = (data: any): Array<any> => (Array.isArray(data?.childNodes) ? data?.childNodes : []);

const getChildrenLineColor = (data: any) => {
  let movementColor = '#262626';
  if (data?.vsMovementType === 'OTHER') {
    movementColor = '#0000CD';
  } else if (data?.vsMovementType === 'EAEC') {
    movementColor = 'green';
  } else if (data?.vsMovementType === 'KZ') {
    movementColor = '#faad14';
  } else if (data?.vsMovementType === 'ORIGIN_5') {
    movementColor = '#f5222d';
  }
  if (data?.isBalanceUform) {
    return [movementColor, 'DeepPink'];
  } else {
    return [movementColor];
  }
};

type Props = {
  className?: string;
  data: any;
  selectedId: string;
};

export const VirtualLabelTree: React.FC<Props> = ({ className, data, selectedId }) => {
  const locale = useLocale();
  const { loc } = locale;

  console.log(data);

  const ref = useRef<any>();

  useScrollDrag({ elementRef: ref, verticalDocumentScroll: true });

  const treeStore: TreeStore = useMemo(() => {
    const nodes: { [key: string]: TreeStoreNode } = {};

    const regiterNode = (node: any, root: boolean, parentId?: number) => {
      const nodeId = getNodeId(node);
      nodes[nodeId] = {
        expanded: root ? true : false,
        root,
        parentId,
      };
      const children = getChildren(node);
      if (children.length) {
        children.forEach((childNode) => regiterNode(childNode, false, nodeId));
      }
    };

    regiterNode(data, true);
    console.log('nodes', nodes);

    const store = new TreeStore(nodes);
    return store;
  }, [data]);

  const focusSelected = useCallback(() => {
    treeStore.openSelectedIdBranch(Number(selectedId));
    setTimeout(() => {
      const card = document.querySelector('.selectedCard--forFocus');
      if (card) {
        (card as HTMLDivElement).focus();
      }
    }, 500);
  }, [treeStore, selectedId]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <div className={s.header}>
        <Button className={s.button} variant="outlined" size="small" onClick={() => treeStore.setExpandedAll(true)}>
          {loc('common.button.openAll')}
        </Button>
        <Button className={s.button} variant="outlined" size="small" onClick={() => treeStore.setExpandedAll(false)}>
          {loc('common.button.closeAll')}
        </Button>
        <Button className={s.button} variant="outlined" size="small" onClick={focusSelected}>
          {loc('common.button.find')}
        </Button>
      </div>
      <div className={s.scrollContainer} ref={ref}>
        <TreeNode
          store={treeStore}
          data={data}
          getChildren={getChildren}
          renderContent={(nodeData: any) => (
            <VirtualLabelCard
              data={nodeData}
              isSelected={!!selectedId && String(nodeData?.virtualLabelNodeDetail?.id) === String(selectedId)}
            />
          )}
          getId={getNodeId}
          getChildrenLineColors={getChildrenLineColor}
        />
      </div>
    </div>
  );
};
