import { BASE_URL } from 'common/constants';
import { httpClient } from 'rest/httpClient';
import { DataQueryFunction } from 'rest/types/DataQueryFunction';

export const queryLocaleJson: DataQueryFunction = async (variables) => {
  const { lang, name } = variables;

  return httpClient.get(`/locales/${`${name}_${lang}.json`}`, {
    baseURL: BASE_URL,
  });
};
