import React from 'react';
import clsx from 'clsx';
import s from './LangToggleT2.module.scss';
import { observer } from 'mobx-react-lite';
import { Lang, useLocale } from 'services/l10n';
import { useLangToggle } from 'common/hooks';

type Props = {
  className?: string;
  reversedColor?: boolean;
};

export const LangToggleT2: React.FC<Props> = observer(({ className, reversedColor }) => {
  const { lang } = useLocale();
  const { changeLang, loading } = useLangToggle();

  return (
    <div className={clsx(className, s.wrapper, { [s.reversed]: reversedColor })}>
      <button
        className={clsx(s.textButton, s.langButton, {
          [s.inactive]: lang !== Lang.RU,
        })}
        onClick={() => changeLang(Lang.RU)}
        disabled={loading}
      >
        Рус
      </button>
      <button
        className={clsx(s.textButton, s.langButton, {
          [s.inactive]: lang !== Lang.KK,
        })}
        onClick={() => changeLang(Lang.KK)}
        disabled={loading}
      >
        Қаз
      </button>
      {/* <button className={clsx(s.textButton, s.eyeButton, s.inactive)}>
        <SvgIcon>
          <BlindIcon />
        </SvgIcon>
      </button> */}
    </div>
  );
});
