import { solveErrorMessage } from 'common/form/helpers';
import { AuthStore } from 'common/stores/auth-store';
import {
  mutationIsgoInvoiceActionConfirmHash,
  mutationIsgoInvoiceActionConfirmRelated,
  mutationIsgoInvoiceActionConfirmSend,
} from 'rest';
import { ToastService } from 'services/toasts';

export const getAllInvoicesForConfirm = async (ids: Array<string>): Promise<Array<any>> => {
  const { data } = await mutationIsgoInvoiceActionConfirmRelated({ body: { isgoInvoiceIds: ids.join() } });
  const invoices = data?.data?.isgoInvoiceList;
  if (Array.isArray(invoices)) {
    return invoices;
  } else {
    throw new Error('Invoices data is not an Array');
  }
};

export const sendInvoicesToIsgo = async ({
  isgoInvoiceIds,
  actionType,
  rejectReason,
  auth,
  userTin,
}: {
  isgoInvoiceIds: Array<string>;
  actionType: 'CONFIRM' | 'DECLINE';
  rejectReason?: string;
  auth: AuthStore;
  userTin: string;
}) => {
  const body = {
    isgoInvoiceIds,
    actionType,
    rejectReason,
  };

  let hashData: { [key: string]: string } = {};

  try {
    const { data } = await mutationIsgoInvoiceActionConfirmHash({ body });
    hashData = data?.data?.hashList;
  } catch (error) {
    ToastService.showError(solveErrorMessage(error));
    throw error;
  }

  const hashArray = Object.entries(hashData).map((entry) => ({ hash: entry[1], id: entry[0] }));

  if (hashArray?.length) {
    try {
      const { sign, cert } = await auth.getSignature(hashArray);

      const actionInfos = Object.entries(sign).map(([key, value]) => ({
        isgoInvoiceActionBody: hashData[key],
        isgoCreateInvoiceActionId: key,
        tin: userTin,
        signature: value,
        certificate: cert.base64Pem,
        signatureType: 'COMPANY',
        version: 'IsgoInvoiceActionVersionV1',
      }));

      try {
        await mutationIsgoInvoiceActionConfirmSend({
          body: {
            confirmActionInfos: JSON.stringify(actionInfos),
          },
        });
        return true;
      } catch (error: any) {
        if (Array.isArray(error?.data?.data)) {
          error?.data?.data.forEach((err: any) => {
            if (err.text || err.errorCode) {
              ToastService.showError(err.text || err.errorCode);
            }
          });
        } else {
          ToastService.showError(solveErrorMessage(error));
        }
        throw error;
      }
    } catch (error) {
      throw error;
    }
  } else {
    const error = new Error('Ошибка получения даных hash');
    ToastService.showError(solveErrorMessage(error));
    throw error;
  }
};
