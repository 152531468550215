import { AxiosResponse } from 'axios';
import { convertToFormData } from 'rest/helpers';
import { httpClient } from 'rest/httpClient';
import { DataMutationFunction } from 'rest/types';

export const mutationInvoiceHashImported: DataMutationFunction<AxiosResponse> = async (variables): Promise<any> => {
  const { body } = variables || {};

  // if (true) {
  //   return { data: fakeData };
  // }

  const formData = convertToFormData(body || {});

  const res = await httpClient.post('/invoice/hashImported', formData);

  if (res?.data?.success === false) {
    throw res;
  } else {
    return res;
  }
};

export const fakeHashDataErrors = {
  '21264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '21264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '21264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '21264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '21264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },

  '22264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '22264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '22264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '22264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '22264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },

  '23264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '23264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '23264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '23264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '23264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },

  '24264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '24264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '24264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '24264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '24264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },

  '25264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '25264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '25264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '25264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '25264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },

  '26264773376679936': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '26264773475557376': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '26264805240602624': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '26264773608644608': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
  '26264805496528896': { errors: [{ property: 'date', text: "'Дата выписки СФ' отличается от текущей" }] },
};
