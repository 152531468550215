import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormData } from '../../helpers';

export const mutationInvoiceDraftCreate: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { draft, ...rest } = variables;

  const formData = convertToFormData({
    draft: JSON.stringify(draft),
    ...rest,
  });

  const res = await httpClient.post('invoice/drafts/create', formData);
  if (res?.data?.success === true) {
    return res;
  } else {
    throw res?.data?.message;
  }
};
