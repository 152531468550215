import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryAdminGetApproveInvitationHash: DataQueryFunction = async ({ data }) => {
  const res = await httpClient.get(`/admin/getApproveInvitationHash`, {
    params: { data: JSON.stringify(data) },
    responseType: 'text',
  });

  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
