import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './StatisticReportPage.module.scss';
import { PageTitle } from 'ui/common';
import { StatisticReportForm, SubmitType } from './StatisticReportForm';
import { useLocale } from 'services/l10n';
import { useCheckAdminPermission, useUser } from 'common/hooks';
import { ADMIN_PERMISSION, USER_SERVICE_ROLE } from 'common/constants';
import { queryStatisticReport } from './api/queryStatisticReport';
import { formatFromDate } from 'services/date-time';
import { subMonths } from 'date-fns';

type Props = {
  className?: string;
};

/**
 * Страница АРМ "Отчетность" -> "Отчет по количеству участников ИС ЭСФ и документообороту"
 * arm/statistic-docflow-report
 */
export const StatisticReportPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;
  const user = useUser();

  const onSubmit = (values: any, type: SubmitType) => {
    return queryStatisticReport({
      filter: values,
      format: type,
    });
  };

  const initialValues = useMemo(() => {
    let ogdCode = '';
    if (
      [USER_SERVICE_ROLE.CA_USER, USER_SERVICE_ROLE.IS_USER, USER_SERVICE_ROLE.SUPPORT].includes(
        user.currentUser?.userServiceRoleType || ''
      )
    ) {
      ogdCode = '0101';
    } else if (user.currentUser?.userServiceRoleType === USER_SERVICE_ROLE.OGD_ADMIN) {
      ogdCode = user.currentUser?.armUserOgdCode || '';
    }
    return {
      dateTo: formatFromDate(subMonths(new Date(), 1)),
      growingTotal: true,
      ogdCode,
    };
  }, [user]);

  useCheckAdminPermission(ADMIN_PERMISSION.STATISTIC_DOCFLOW_REPORT);

  return (
    <Fragment>
      <PageTitle>{loc('stats.title')}</PageTitle>
      <div className={clsx(className, s.wrapper)}>
        <StatisticReportForm onSubmit={onSubmit} initialValues={initialValues} user={user} />
      </div>
    </Fragment>
  );
};
