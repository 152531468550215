import React, { useMemo } from 'react';
import clsx from 'clsx';
import s from './SaldoReportPage.module.scss';
import { PageTitle } from 'ui/common';
import { useLocale } from 'services/l10n';
import { SaldoReportForm } from './SaldoReportForm';
import { AppMode } from 'common/constants';
import { useUser } from 'common/hooks';
import { queryVstoreSaldoExport } from './api';
import { clearPrivateProps } from 'common/utils';

type Props = {
  className?: string;
};

/**
 * НП -> Виртуальный склад -> Оборотная ведомость по определению товарного баланса
 * /saldo-report
 */
export const SaldoReportPageTaxpayer: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const user = useUser();

  const initialValues = useMemo(
    () => ({
      tin: user.currentUser?.currentUserTin,
    }),
    [user]
  );

  const downloadReport = (values: any, format: string) => {
    console.log('downloadReport', format);
    return queryVstoreSaldoExport({
      filter: clearPrivateProps({
        ...values,
        tin: values._branch || values.tin,
      }),
      format,
    });
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitle>{loc('saldo.title')}</PageTitle>
      <SaldoReportForm onSubmit={downloadReport} appMode={AppMode.TAXPAYER} initialValues={initialValues} />
    </div>
  );
};
