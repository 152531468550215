import { useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PATH } from 'routing/paths';
import { useUser } from './useUser';
import { AdminPermission } from 'common/constants';

/**
 * Проверяет, есть ли права у АРМ пользователя, и если права нет то перенавравляет на главную страницу АРМ (/arm)
 * Работет только в production сборке (в development и test режимах проверка отключена)
 * @param permissions Имя права или массив с правами
 * @param every если true, то проверяет что есть ВСЕ права из массива (every). По умолчанию false, проверяет что есть хотя бы одно право из массива (some)
 * @returns undefined
 */
export const useCheckAdminPermission = (permissions: AdminPermission | AdminPermission[], every?: boolean) => {
  const user = useUser();
  const history = useHistory();

  useLayoutEffect(() => {
    if (!user.checkAdminPermission(permissions, every) && process.env.NODE_ENV === 'production') {
      history.push(PATH.ARM);
    }
  }, [user, history, every, permissions]);
};
