import React, { Fragment, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './RegAgreement.module.scss';
import ss from '../../Registration.module.scss';
import { useLocale } from 'services/l10n';
import { ButtonT2 } from 'ui';
import { AGREEMENTS_FIELD_NAMES, prepareTotalRegData } from '../../helpers';
import { RegCredentials } from './RegCredentials';
import { observer } from 'mobx-react-lite';
import { RegAgreement } from './RegAgreement';
import { mutationRegistrationV2Total } from 'rest';
import { ToastService } from 'services/toasts';
import { findErrorData, solveErrorMessage, solveFormErrors } from 'common/form/helpers';
import { getIn } from 'formik';
import { RegActionPage, RegType } from 'features/registration/types';

type Props = RegActionPage & {
  className?: string;
};

export const RegAgreementStep: React.FC<Props> = observer(({ className, onBack, onNext, regStore }) => {
  const locale = useLocale();
  const { loc } = locale;

  const [loading, setLoading] = useState(false);

  const [agreementType, setAgreementType] = useState<RegType | undefined>(undefined);

  const [totalError, setTotalError] = useState<any>(undefined);

  const formErrors = useMemo(() => solveFormErrors(totalError), [totalError]);

  let enableComplete = true;
  for (let type in regStore.selectedTypes) {
    if (!regStore.agreements[type as RegType]?.signature) {
      enableComplete = false;
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      await mutationRegistrationV2Total({ body: prepareTotalRegData(regStore.regData, regStore.agreements) });
      onNext();
    } catch (error) {
      console.error(error);
      const errorMessage = solveErrorMessage(error);
      if (errorMessage && typeof errorMessage === 'string') {
        ToastService.showError(errorMessage);
      } else {
        setTotalError(error);
      }
      setLoading(false);
    }
  };

  //Вывод ошибок не соответсвующих полям формы
  useEffect(() => {
    const exclude = regStore.selectedTypesArray.map((type) => AGREEMENTS_FIELD_NAMES[type]);
    const errors = findErrorData(totalError);
    if (Array.isArray(errors)) {
      errors.forEach((error) => {
        if (typeof error.text === 'string' && !exclude.includes(error.property)) {
          ToastService.showError(error.text);
        }
      });
    }
  }, [totalError, regStore.selectedTypesArray]);

  return (
    <div className={clsx(className, s.wrapper, ss.container)}>
      {agreementType ? (
        <RegAgreement
          regType={agreementType}
          onBack={() => setAgreementType(undefined)}
          onSigned={() => setAgreementType(undefined)}
          regStore={regStore}
          loading={loading}
        />
      ) : (
        <Fragment>
          {regStore.selectedTypesArray.map((type) => {
            const errorMessage = getIn(formErrors, AGREEMENTS_FIELD_NAMES[type]);
            return (
              <Fragment key={type}>
                <h2>{loc(`reg.agreement.${type}`)}</h2>
                <div className={clsx(ss.frame)}>
                  <RegCredentials regData={regStore.regData} type={type} preview />
                  <hr className={ss.hr} />
                  <div className={s.signArea}>
                    <ButtonT2 variant="outlined" onClick={() => setAgreementType(type)}>
                      {loc('reg.familiarize')}
                    </ButtonT2>
                    <ButtonT2 className={regStore.agreements[type]?.signature ? s.signedButton : ''} disabled>
                      {loc('reg.signed')}
                    </ButtonT2>
                  </div>
                </div>
                {errorMessage && typeof errorMessage === 'string' && (
                  <span className={s.errorMessage}>{errorMessage}</span>
                )}
              </Fragment>
            );
          })}

          <div className={ss.actions}>
            <ButtonT2 size="large" variant="secondary" onClick={() => onBack()}>
              {loc('reg.back')}
            </ButtonT2>
            <ButtonT2 size="large" onClick={onSubmit} disabled={loading || !enableComplete} loading={loading}>
              {loc('reg.complete')}
            </ButtonT2>
          </div>
        </Fragment>
      )}
    </div>
  );
});
