import React, { Fragment } from 'react';
import clsx from 'clsx';
import ss from '../InsidePage.module.scss';
import s from './InsideNewsListPage.module.scss';
import { InsideNewsCard } from './InsideNewsCard';
import { queryGetPublishedNews, useDataQuery } from 'rest';
import { InsideBreadcrumbs } from '../InsideBreadcrumbs';
import { useLocale } from 'services/l10n';

type Props = {
  className?: string;
};

export const InsideNewsListPage: React.FC<Props> = ({ className }) => {
  const { data } = useDataQuery(queryGetPublishedNews);
  const locale = useLocale();
  const { loc } = locale;

  return (
    <div className={clsx(className, ss.wrapper)}>
      <InsideBreadcrumbs section="news" />
      <div className={ss.content}>
        <article className={ss.article}>
          <h2>{loc('common.nav.news')}</h2>
          {Array.isArray(data) && (
            <Fragment>
              {!!data[0] && <InsideNewsCard data={data[0]} variant="main" />}
              <div className={s.grid}>
                <div className={s.column}>
                  {data.slice(1, 3).map((item) => (
                    <InsideNewsCard key={item.id} data={item} className={s.item} variant="second" />
                  ))}
                </div>
                <div className={s.column}>
                  {data.slice(3, 6).map((item) => (
                    <InsideNewsCard key={item.id} data={item} className={s.item} variant="third" />
                  ))}
                </div>
              </div>
            </Fragment>
          )}
        </article>
      </div>
    </div>
  );
};
