import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './SntExportReportPage.module.scss';
import { PageTitle } from 'ui/common';
import { SntExportReportForm, SubmitType } from './SntExportReportForm';
import { useLocale } from 'services/l10n';
// import { queryRemainsExport, querySntBorderCrossingExport } from 'rest';
import { querySntBorderCrossingExport } from 'rest';
import { useCheckAdminPermission } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';

type Props = {
  className?: string;
};

/**
 * Страница АРМ "Отчетность" -> "Отчет по СНТ в рамках МЭК"
 * arm/snt-border-crossing-report
 */
export const SntExportReportPage: React.FC<Props> = ({ className }) => {
  const locale = useLocale();
  const { loc } = locale;

  const onSubmit = (values: any, type: SubmitType) => {
    const filter = { ...values };
    if (['ALL', 'NONE'].includes(filter.checkpoint)) {
      filter.automobileCheckpointFilterType = filter.checkpoint;
    } else {
      filter.automobileCheckpointFilterType = 'PART';
      filter.automobileCheckpointIds = [filter.checkpoint];
    }
    delete filter.checkpoint;
    if (filter.ogdCode && filter.ogdCode !== 'ALL') {
      filter.ogdCodes = [filter.ogdCode];
    }
    delete filter.ogdCode;
    return querySntBorderCrossingExport({
      filter,
      format: type === 'pdf' ? 'PDF' : '',
    });
  };

  useCheckAdminPermission(ADMIN_PERMISSION.SNT_BORDER_CROSSING_REPORT);

  return (
    <Fragment>
      <PageTitle>{loc('ser.pageTitle')}</PageTitle>
      <div className={clsx(className, s.wrapper)}>
        <SntExportReportForm onSubmit={onSubmit} />
      </div>
    </Fragment>
  );
};
