type ObjectWithProps = { [key: string]: any };

/**
 * clear empty props (null, undefined, ''), e.g. from Forms values (shallow);
 * @returns new object
 */
export function clearEmptyFields(object?: ObjectWithProps): ObjectWithProps {
  if (!object) return {};
  return Object.keys(object).reduce((acc: ObjectWithProps, key) => {
    if (object[key] !== '' && object[key] !== null && object[key] !== undefined) acc[key] = object[key];
    return acc;
  }, {});
}
