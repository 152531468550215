import {
  FilterT2ContentProps,
  FormCountrySelect,
  FormDoubleDatePickerT2,
} from 'common/components';
import React from 'react';
import { useLocale } from 'services/l10n';
import { FieldT2, FormFieldT2, FormInputT2, FormSelectT2 } from 'ui';
import { getZvtStatusOptions } from '../constants';

type Props = FilterT2ContentProps;

export const ZvtJournalTxpFiltersAdvanced: React.FC<Props> = ({
  form,
  classNames,
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const { advancedWrapper, columnWide, labelWide } = classNames || {};

  const statusOptions = getZvtStatusOptions(locale);

  return (
    <div className={advancedWrapper}>
      <div className={columnWide}>
        <FormFieldT2
          name="status"
          label={loc('zvt.status')}
          labelClassName={labelWide}
          fast={false}
          direction="line"
          innerFullWidth
        >
          <FormSelectT2 options={statusOptions} />
        </FormFieldT2>
        <FieldT2
          label={loc('zvt.initiated')}
          labelClassName={labelWide}
          direction="line"
          innerFullWidth
        >
          <FormDoubleDatePickerT2 nameFrom="dateFrom" nameTo="dateTo" />
        </FieldT2>
        <FormFieldT2
          name="registrationNumber"
          label={loc('zvt.regNumberZvt')}
          labelClassName={labelWide}
          direction="line"
          innerFullWidth
        >
          <FormInputT2 />
        </FormFieldT2>
        <FormFieldT2
          name="gtdRegNum"
          label={loc('zvt.regNumberDt')}
          labelClassName={labelWide}
          direction="line"
          innerFullWidth
        >
          <FormInputT2 />
        </FormFieldT2>

        <FormFieldT2
          name="departureCountryCode"
          label={loc('zvt.depCountry')}
          labelClassName={labelWide}
          fast={false}
          direction="line"
          innerFullWidth
        >
          <FormCountrySelect theme="T2" valueAsOption />
        </FormFieldT2>
      </div>
    </div>
  );
};
