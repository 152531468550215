import { API_URL } from 'common/constants';
import { clearEmptyFields } from 'common/utils';
import { downloadFile } from 'rest/helpers';
import { DataQueryFunction } from 'rest/types';

export const queryAssuranceTableReport: DataQueryFunction = (variables: any) => {
  const { ids } = variables || {};

  return downloadFile({
    url: `${API_URL}/assurance/tableReport`,
    method: 'GET',
    params: {
      criteria: JSON.stringify(clearEmptyFields({ passportIds: ids })),
    },
  });
};
