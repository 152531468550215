import { useStore } from 'common/hooks';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';

type Props = {};

export const PushNotifications: React.FC<Props> = observer(() => {
  const { pushNotifications } = useStore();

  useEffect(() => {
    console.log('INIT WEBSOCKET');
    pushNotifications.initWebsocket();
    return () => {
      console.log('DISCONECT WEBSOCKET');
      pushNotifications.disconnect();
    };
  }, []); // eslint-disable-line

  return null;
});
