import React from 'react';
import clsx from 'clsx';
import s from './LangToggle.module.scss';
import { observer } from 'mobx-react-lite';
import { Lang, useLocale } from 'services/l10n';
import { Button } from 'ui/common';
import { useLangToggle } from 'common/hooks';

type Props = {
  className?: string;
  buttonClassName?: string;
};

export const LangToggle: React.FC<Props> = observer(({ className, buttonClassName }) => {
  const { lang } = useLocale();
  const { changeLang, loading } = useLangToggle();

  const toggleLang = () => {
    changeLang(lang === Lang.RU ? Lang.KK : Lang.RU);
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <Button variant="outlined" onClick={toggleLang} className={buttonClassName} disabled={loading}>
        {lang}
      </Button>
    </div>
  );
});
