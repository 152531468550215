import { DataQueryFunction, httpClient } from 'rest';

export const queryAdminAssuranceActions: DataQueryFunction = async (variables: any) => {
  const { passportId } = variables || {};

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(2000);
  //   return { data: fakeActionsLog } as AxiosResponse;
  // }

  const res = await httpClient.get(`/admin/assurance/actions/${passportId}`);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
