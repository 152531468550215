import { useEffect, useRef } from 'react';
import { debounce, throttle } from 'common/utils';

export const useOnResize = (
  onResize: (ev?: UIEvent) => any,
  options: { debounceTimer?: number; throttleTimer?: number; immidiate?: boolean }
) => {
  const listenerRef = useRef(onResize);
  listenerRef.current = onResize;

  useEffect(() => {
    const onWindowResize = (ev?: UIEvent) => {
      listenerRef.current(ev);
    };

    const listener: (ev?: UIEvent) => any = options.throttleTimer
      ? throttle(onWindowResize)
      : options.debounceTimer
      ? debounce(onWindowResize, options.debounceTimer)
      : onWindowResize;

    window.addEventListener('resize', listener);
    if (options.immidiate) {
      onWindowResize();
    }
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []); // eslint-disable-line

  return;
};
