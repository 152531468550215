import React from 'react';
import clsx from 'clsx';
import s from './Pagination.module.scss';
import { PageSizeSelect } from './page-size-select';
import { InputPagination } from './InputPagination';

type Props = {
  className?: string;
  currentPage?: number;
  pageSize: number;
  onPageChange: (value: number) => void;
  onPageSizeChange?: (value: number) => void;
  maxLength?: number;
  lastPage?: boolean;
  rowsOptions?: Array<{
    value: number;
    label: string;
  }>;
};

export const Pagination: React.FC<Props> = ({
  className,
  currentPage = 1,
  pageSize,
  onPageChange,
  maxLength = 3,
  lastPage,
  onPageSizeChange,
  rowsOptions,
}) => {
  return (
    <div className={clsx(s.wrapper, className)}>
      <InputPagination
        onPageChange={onPageChange}
        currentPage={currentPage}
        maxLength={maxLength}
        lastPage={lastPage}
      />
      <PageSizeSelect pageSize={pageSize} onPageSizeChange={onPageSizeChange} rowsOptions={rowsOptions} />
    </div>
  );
};
