import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './LoadedCount.module.scss';
import { ButtonIcon } from 'ui/common/controls';

type Props = {
  className?: string;
  label?: string;
  list?: Array<string>;
  onClear?(): void;
  noMargin?: boolean;
};

export const LoadedCount: React.FC<Props> = ({ className, label, noMargin, onClear, list }) => {
  return (
    <div
      className={clsx(className, s.wrapper, { [s.noMargin]: noMargin })}
      title={Array.isArray(list) ? list.join(', ') : undefined}
    >
      {list?.length ? (
        <Fragment>
          <span className={s.label}>{label}</span>
          <span className={s.count}>{list?.length}</span>
          <ButtonIcon className={s.clearButton} iconName="close" iconClassName={s.icon} onClick={onClear} />
        </Fragment>
      ) : (
        <span>&nbsp;</span>
      )}
    </div>
  );
};
