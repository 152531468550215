import { DialogStore } from 'services/modal/dialog';
import { LocaleStore } from 'services/l10n/LocaleStore';
import { UserStore } from './user-store';
import { NCALayerStore } from 'services/nca-layer';
import { AuthStore } from './auth-store/AuthStore';
import { PushNotificationStore } from 'services/push-notifications';
import { MessagesStore } from 'features/messages';
import { NotificationsStore } from 'features/notifications/NotificationsStore';
import { UserChecksStore } from 'features/user-checks-modals/UserChecksStore';

export class RootStore {
  locale: LocaleStore = new LocaleStore(this);
  user: UserStore = new UserStore(this);
  auth: AuthStore = new AuthStore(this);
  userChecks: UserChecksStore = new UserChecksStore(this);
  dialog: DialogStore = new DialogStore(this);
  ncaLayer: NCALayerStore = new NCALayerStore(this);
  /**
   * Push уведомления
   */
  pushNotifications: PushNotificationStore = new PushNotificationStore(this);
  /**
   * Сообщения
   */
  messages: MessagesStore = new MessagesStore();
  /**
   * Оповещения
   */
  notifications: NotificationsStore = new NotificationsStore(this);
}
