import React, { Fragment } from 'react';
import clsx from 'clsx';
import s from './NavigationSubItem.module.scss';
import { Link } from 'react-router-dom';
import { MenuItem } from './MenuItem';

type Props = {
  className?: string;
  item: MenuItem;
  minimized?: boolean;
  dense?: boolean;
};

export const NavigationSubItem: React.FC<Props> = ({ className, item, minimized, dense }) => {
  const { label, link, externalLink } = item;

  const content = () => (
    <Fragment>
      <span className={s.text}>{label}</span>
    </Fragment>
  );

  return (
    <div
      className={clsx(className, s.wrapper, {
        [s.minimized]: minimized,
        // [s.dense]: dense,
      })}
    >
      {!!link &&
        (externalLink ? (
          <a href={link} className={s.link}>
            {content()}
          </a>
        ) : (
          <Link to={link} className={s.link}>
            {content()}
          </Link>
        ))}
    </div>
  );
};
