import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from '../PassportDocument.module.scss';
import { PACell } from './PACell';
import { useLocale } from 'services/l10n';
import { getIn } from 'formik';
import { Field, RadioT2 } from 'ui';
import { queryTransportType, useDataQuery } from 'rest';
import { numberWithSpaces } from 'common/utils';
import { CodeValueMeasureUnit } from 'common/components';

type Props = {
  className?: string;
  data: any;
};

/**
 * Приложение 1. Электронный счёт фактура
 */
export const PassportAttachmentEsf: React.FC<Props> = ({ className, data }) => {
  const locale = useLocale();
  const { loc, chooseByKey } = locale;

  const { data: ttData } = useDataQuery(queryTransportType);

  const transportType = useMemo(() => {
    const ttCode = getIn(data, 'invoice.deliveryTerm.transportTypeCode');
    if (ttCode && Array.isArray(ttData)) {
      const ttType = ttData.find((tt) => tt.code === ttCode);
      if (ttType) {
        return ttType;
      }
    }
    return '';
  }, [ttData, data]);

  const products = getIn(data, 'invoice.productSet.products');

  return (
    <div className={clsx(className, s.attachment)}>
      <div className={clsx(s.attTable)}>
        <div className={s.attGrid} style={{ gridTemplateColumns: '150fr 236fr 125fr 36fr 155fr 6fr 160fr 160fr' }}>
          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={5} label={loc('esf.registrationNumber')} value={data.registrationNumber} />
          <PACell span={2} label={loc('esf.turnoverDate')} value={data.invoice?.turnoverDate} />
          <PACell span={1} label={loc('esf.invoiceDate')} value={data.invoice?.date} />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={1} label={loc('common.labels.sellerTin')} value={getIn(data, 'invoice.sellers[0].tin')} />
          <PACell span={2} label={loc('common.labels.sellerName')} value={getIn(data, 'invoice.sellers[0].name')} />
          <PACell span={5} label={loc('esf.sellerAddress')} value={getIn(data, 'invoice.sellers[0].address')} />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={1} label={loc('common.labels.recipientTin')} value={getIn(data, 'invoice.customers[0].tin')} />
          <PACell
            span={2}
            label={loc('common.labels.recipientName')}
            value={getIn(data, 'invoice.customers[0].name')}
          />
          <PACell span={4} label={loc('esf.recipientAddress')} value={getIn(data, 'invoice.customers[0].address')} />
          <PACell
            span={1}
            label={loc('esf.recipientCountryCode')}
            value={getIn(data, 'invoice.customers[0].countryCode')}
          />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={2}>
            <Field label={loc('esf.deliveryContractSh')} direction="line-reverse">
              <RadioT2 checked={getIn(data, 'invoice.deliveryTerm.hasContract')} />
            </Field>
            <Field label={loc('esf.noDeliveryContractSh')} direction="line-reverse">
              <RadioT2 checked={!getIn(data, 'invoice.deliveryTerm.hasContract')} />
            </Field>
          </PACell>
          <PACell
            span={2}
            label={loc('common.labels.contractNum')}
            value={getIn(data, 'invoice.deliveryTerm.contractNum')}
          />
          <PACell
            span={2}
            label={loc('common.labels.contractDate')}
            value={getIn(data, 'invoice.deliveryTerm.contractDate')}
          />
          <PACell span={1} label={loc('esf.transportTypeCode')} value={chooseByKey(transportType, 'name')} />
          <PACell span={1} label={loc('esf.destination')} value={getIn(data, 'invoice.deliveryTerm.destination')} />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={1} label={loc('esf.consignorTin')} value={getIn(data, 'invoice.consignor.tin')} />
          <PACell span={7} label={loc('esf.consignorName')} value={getIn(data, 'invoice.consignor.name')} />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={1} label={loc('esf.consigneeTin')} value={getIn(data, 'invoice.consignee.tin')} />
          <PACell span={2} label={loc('esf.consigneeName')} value={getIn(data, 'invoice.consignee.name')} />
          <PACell span={4} label={loc('esf.consigneeAddress')} value={getIn(data, 'invoice.consignee.address')} />
          <PACell
            span={1}
            label={loc('esf.consigneeCountryCode')}
            value={getIn(data, 'invoice.consignee.countryCode')}
          />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}

          <PACell span={2}>
            <Field label={loc('c.esf.type.FIXED_INVOICE.f')} direction="line-reverse">
              <RadioT2 />
            </Field>
          </PACell>

          <PACell span={5} label={loc('esf.registrationNumber')} value={''} />
          <PACell span={1} label={loc('esf.invoiceDate')} value={''} />

          {/* /////////////////////////////////////////////////////////////////////////////// 
          ////////////////////////////////////////////////////////////////////////////////// */}
          <PACell span={2}>
            <Field label={loc('c.esf.type.ADDITIONAL_INVOICE.f')} direction="line-reverse">
              <RadioT2 />
            </Field>
          </PACell>
          <PACell span={5} label={loc('esf.registrationNumber')} value={''} />
          <PACell span={1} label={loc('esf.invoiceDate')} value={''} />
        </div>

        {Array.isArray(products) && (
          <div className={s.attGrid} style={{ gridTemplateColumns: 'repeat(8, 1fr)' }}>
            <PACell span={1} label={loc('esf.p.description.sh')} noValue />
            <PACell span={1} label={loc('esf.p.tnvedName.sh')} noValue />
            <PACell span={1} label={loc('esf.p.unitCode')} noValue />
            <PACell span={1} label={loc('common.labels.measureUnitName')} noValue />
            <PACell span={1} label={loc('esf.p.quantity')} noValue />
            <PACell span={1} label={loc('esf.p.productDeclaration.sh')} noValue />
            <PACell span={1} label={loc('esf.p.productNumberInDeclaration.sh')} noValue />
            <PACell span={1} label={loc('esf.p.unitPrice.sh')} noValue />
            {products.map((product, index) => (
              <Fragment key={index}>
                <PACell span={1} value={product.description} />
                <PACell span={1} value={product.tnvedName} />
                <PACell span={1} value={product.unitCode} />
                <PACell span={1} value={<CodeValueMeasureUnit code={product.unitNomenclature} />} />
                <PACell span={1} value={product.quantity} />
                <PACell span={1} value={product.productDeclaration} />
                <PACell span={1} value={product.productNumberInDeclaration} />
                <PACell span={1} value={product.unitPrice} />
              </Fragment>
            ))}
            <PACell
              span={8}
              value={
                <span>
                  {loc('assurance.esfTotal')}:{' '}
                  {numberWithSpaces(getIn(data, 'invoice.productSet.totalPriceWithoutTax'))}
                </span>
              }
              textAlign="right"
            />
          </div>
        )}
      </div>
    </div>
  );
};
