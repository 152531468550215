import clsx from 'clsx';
import { Journal, JournalButton } from 'common/components/journal';
import { ImportFromFileModal } from 'common/components';
import { solveErrorMessage } from 'common/form/helpers';
import { getResourceUrl } from 'common/helpers';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { Modal } from 'services/modal';
import { ToastService } from 'services/toasts';
import { Heading } from 'ui/common';
import {
  mutationAdminSROImportCloseFromFile,
  mutationAdminSROImportFromFile,
  mutationAdminSROUploadFile,
  queryAdminSROExport,
  queryAdminSROImportCloseFileMeta,
  queryAdminSROImportCloseResult,
  queryAdminSROImportResult,
  queryAdminSROSearch,
} from '../api';
import { SmallRetailContent } from '../small-retail-form/SmallRetailContent';
import { SmallRetailInfo } from '../small-retail-form/SmallRetailInfo';
import { getSmallRetailColumns } from './getSmallRetailColumns';
import { SmallRetailFilters } from './SmallRetailFilters';

const initialFilter = {};
const initialPagination = { rows: 10 };

const getRowId = (row: any, index: number) => row.revisionId || index;

type Props = {};

export const SmallRetailDictionary: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;
  const [modal, setModal] = useState<{
    opened: 'none' | 'show' | 'edit';
    row: any;
  }>({
    opened: 'none',
    row: undefined,
  });

  const [importModal, setImportModal] = useState<boolean>(false);
  const [importClosingModal, setImportClosingModal] = useState<boolean>(false);

  const columns = useMemo(() => getSmallRetailColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminSROSearch, {
    filter: initialFilter,
    pagination: initialPagination,
  });

  const exportXLS = useCallback(async () => {
    try {
      await queryAdminSROExport({
        filter: queryProps.filter,
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [queryProps.filter]);

  const headerButtons = useMemo(
    (): JournalButton[] => [
      {
        id: 1,
        label: loc('common.button.add'),
        onClick: (selected) => setModal({ opened: 'edit', row: undefined }),
      },
      {
        id: 2,
        label: loc('common.button.import'),
        onClick: () => setImportModal(true),
      },
      {
        id: 3,
        label: loc('common.button.exportExcel'),
        onClick: () => exportXLS(),
      },
      {
        id: 4,
        label: loc('small.importClosing'),
        onClick: () => setImportClosingModal(true),
      },
    ],
    [loc, exportXLS]
  );

  const actionButtons = useMemo(
    (): JournalButton[] => [
      {
        id: 1,
        label: loc('common.button.viewing'),
        onClick: (selected) => setModal({ opened: 'show', row: selected[0] }),
      },
      {
        id: 2,
        label: loc('common.button.edit'),
        onClick: (selected) => setModal({ opened: 'edit', row: selected[0] }),
      },
    ],
    [loc]
  );

  const onCloseModal = () => setModal({ opened: 'none', row: undefined });
  const onCloseImportModal = () => {
    queryProps.refetch();
    setImportModal(false);
  };
  const onCloseImportClosingModal = () => {
    setImportClosingModal(false);
  };

  return (
    <Fragment>
      <Journal
        pageTitle={loc('small.smallRetailDict')}
        columns={columns}
        queryProps={queryProps}
        filterComponent={SmallRetailFilters}
        defaultFilter={initialFilter}
        filterInitialOpened
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        selectable
      />

      <Modal isOpen={modal.opened !== 'none'} onClose={onCloseModal}>
        {modal.opened === 'edit' ? (
          <SmallRetailContent data={modal.row} onSave={queryProps.refetch} />
        ) : (
          <SmallRetailInfo data={modal.row} />
        )}
      </Modal>

      <Modal isOpen={importModal} onClose={onCloseImportModal}>
        <ImportFromFileModal
          onSubmit={mutationAdminSROImportFromFile}
          uploadFile={async (files: File[]) => {
            await mutationAdminSROUploadFile({ body: { file: files[0] } });
            return undefined;
          }}
          downloadResult={queryAdminSROImportResult}
          exampleLink={getResourceUrl('/data/downloads/МТТ.xlsx')}
        />
      </Modal>

      <Modal isOpen={importClosingModal} onClose={onCloseImportClosingModal}>
        <ImportFromFileModal
          title={loc('small.importClosingTitle')}
          onSubmit={mutationAdminSROImportCloseFromFile}
          uploadFile={async (files: File[]) => {
            await mutationAdminSROUploadFile({ body: { file: files[0] } });
            const uploadResult = await queryAdminSROImportCloseFileMeta();
            return uploadResult;
          }}
          downloadResult={queryAdminSROImportCloseResult}
          exampleLink={getResourceUrl('/data/downloads/Закрытие записей в МТТ.xlsx')}
          renderImportResult={(result, classes) => (
            <div className={classes.result}>
              <Heading className={classes.importedTitle} tag="h3">
                {loc('small.importResult')}:
              </Heading>
              <p className={classes.resultItem}>
                {loc('small.importClosingFound')}: {result?.foundCount}
              </p>
              <p className={clsx(classes.resultItem, classes.resultSuccess)}>
                {loc('small.importClosingClosed')}: {result?.closedCount}
              </p>
              <p className={clsx(classes.resultItem, classes.resultError)}>
                {loc('small.importClosingAlready')}: {result?.alreadyClosedCount}
              </p>
            </div>
          )}
        />
      </Modal>
    </Fragment>
  );
};
