import React, { useEffect } from 'react';
import clsx from 'clsx';
import s from './ConfirmationHistoryModal.module.scss';
import { ModalContent, ModalHeader } from 'services/modal';
import { Loader, Table } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { queryGetSntConfirmationHistory } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';

type Props = ModalChildrenProps & {
  className?: string;
  id: any;
};

const getColumns = ({ loc }: LocaleStore) => [
  {
    name: 'userName',
    label: loc('common.labels.ogdEmployeeFio'),
    style: {
      width: '20%',
    },
  },
  {
    name: 'userTin',
    label: loc('common.labels.ogdEmployeeIin'),
    style: {
      width: '20%',
    },
  },
  {
    name: 'date',
    label: loc('sntTerms.changeDate'),
    style: {
      width: '12%',
    },
  },
  {
    name: 'expireDateBefore',
    label: loc('sntTerms.expireDateBefore'),
    style: {
      width: '12%',
    },
  },
  {
    name: 'expireDateAfter',
    label: loc('sntTerms.expireDateAfter'),
    style: {
      width: '12%',
    },
  },
  {
    name: 'changeReason',
    label: loc('sntTerms.changeReason'),
    style: {
      width: '24%',
    },
  },
];

const getRowId = (row: any, index: number) => index;

export const ConfirmationHistoryModal: React.FC<Props> = ({
  className,
  id,
}) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data, loading, error } = useDataQuery(
    queryGetSntConfirmationHistory,
    {
      id,
    }
  );

  const columns = getColumns(locale);

  useEffect(() => {
    if (error) {
      ToastService.showError(solveErrorMessage(error));
    }
  }, [error]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('sntTerms.confirmationHistory')}</ModalHeader>
      <ModalContent className={s.content}>
        {loading && !data ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            rows={data?.rows}
            variant="clean"
            getRowId={getRowId}
          />
        )}
      </ModalContent>
    </div>
  );
};
