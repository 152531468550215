import { solveErrorMessage } from 'common/form/helpers';
import { useStore } from 'common/hooks';
import { EsfStatus } from 'features/esf';
import { EsfVersion } from 'pages/esf/constants';
import React, { useRef } from 'react';
import { Fragment, useCallback, useState } from 'react';
import {
  mutationInvoiceCreate,
  mutationInvoiceDecline,
  mutationInvoiceDeleteFailed,
  mutationInvoiceHash,
  mutationInvoiceHashDrafts,
  mutationInvoiceHashImported,
  mutationInvoiceListHash,
  mutationInvoiceRevoke,
  mutationInvoiceSendSignedDrafts,
  mutationInvoiceSendSignedImported,
  mutationInvoiceUnrevoke,
  mutationInvoiceWithReasonListHash,
  queryInvoiceActiveRelatedInvoices,
  queryInvoiceId,
} from 'rest';
import { formatFromDate } from 'services/date-time';
import { ToastService } from 'services/toasts';

export type EsfSignDialogState = {
  name?: 'reason' | 'any';
  related?: EsfRelatedData;
  ids?: string[];
  action?: 'decline' | 'revoke' | 'unrevoke';
};

export type EsfRelatedData = {
  docs: Array<{ id: string; num: string; date: string }>;
  state: boolean;
};

export const useSignatureEsfForm = () => {
  const { locale, dialog, auth } = useStore();
  const { loc } = locale;
  const [dialogState, setDialogState] = useState<EsfSignDialogState>({});

  const dialogResolveRef = useRef<any>(null);

  /**
   * Метод для создания нового ЭСФ
   */
  const actionCreate = async (formValues: any, version: EsfVersion) => {
    let hashRes;
    let hashData;

    //Получение хеша
    try {
      hashRes = await mutationInvoiceHash({
        body: {
          invoice: JSON.stringify(formValues),
          localTime: formatFromDate(new Date()),
          version,
        },
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }

    hashData = await solveHashData(hashRes.data);

    //Подпись
    const signResult = await auth.getSignature(hashData);

    //Отправка данных
    try {
      const res = await mutationInvoiceCreate({
        body: {
          invoiceInfo: JSON.stringify({
            invoiceBody: hashData,
            signature: signResult.sign,
            hash: hashData,
            version: version,
          }),
          certificate: signResult.cert.base64Pem,
          localTime: formatFromDate(new Date()),
          draftId: formValues?.invoice?.draftId,
          importedId: formValues?.invoice?.importedId,
        },
      });
      await dialog.showAlert({ content: loc('esf.esfCreated') });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  /**
   * Метод для оправки Импортированных и Черновиков
   */
  const actionSend = async (ids: Array<string>, status: EsfStatus.IMPORTED | EsfStatus.DRAFT) => {
    let hashRes;
    let hashData;

    const hashMutation = status === EsfStatus.IMPORTED ? mutationInvoiceHashImported : mutationInvoiceHashDrafts;

    //Получение хеша
    try {
      hashRes = await hashMutation({
        body: { list: JSON.stringify(ids), localTime: formatFromDate(new Date()) },
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }

    hashData = await solveHashData(hashRes.data);

    //Подпись
    const signResult = await auth.getSignature(hashData);

    const signMutation =
      status === EsfStatus.IMPORTED ? mutationInvoiceSendSignedImported : mutationInvoiceSendSignedDrafts;

    //Отправка данных
    try {
      const res = await signMutation({
        body: {
          signatures: JSON.stringify(signResult.sign),
          certificate: signResult.cert.base64Pem,
          localTime: formatFromDate(new Date()),
        },
      });
      await dialog.showAlert({ content: loc('esf.esfCreated') });
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  /**
   * Метод для 'Отзыва' (revoke), 'Отклонения' (decline) и 'Отказа от отзыва' (unrevoke) ЭСФ
   */
  const actionRevokeDecline = async (ids: Array<string>, action: 'decline' | 'revoke' | 'unrevoke') => {
    const { data: esfData } = await queryInvoiceId({ id: ids[0] });
    const inputDate = formatFromDate(new Date(esfData?.inputDate));
    let relatedData;

    if (action === 'revoke') {
      try {
        relatedData = await getRelatedData(esfData?.invoice?.num, inputDate);
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
        throw error;
      }
    } else {
      relatedData = {
        docs: [
          {
            id: ids[0],
            num: esfData?.invoice?.num,
            date: formatFromDate(new Date(esfData?.inputDate)),
          },
        ],
        state: true,
      };
    }

    console.log('related', relatedData);
    const dialogValues = await showSignDialog({ name: 'reason', action, ids, related: relatedData });
    console.log(dialogValues);

    let hashRes;
    let hashData;

    const reason = solveReasonText(dialogValues.reason, dialogValues.section);

    console.log(reason);

    //Получение хеша
    try {
      hashRes = await mutationInvoiceWithReasonListHash({
        body: {
          list: JSON.stringify(ids?.map((id) => ({ id, reason }))),
          localTime: formatFromDate(new Date()),
        },
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }

    hashData = await solveHashData(hashRes.data);

    //Подпись
    const signResult = await auth.getSignature(hashData);
    console.log(signResult);

    const mutation =
      action === 'revoke'
        ? mutationInvoiceRevoke
        : action === 'decline'
        ? mutationInvoiceDecline
        : mutationInvoiceUnrevoke;

    //Отправка данных
    try {
      const res = await mutation({
        body: {
          list: JSON.stringify(ids?.map((id) => ({ id, reason }))),
          certificate: signResult.cert.base64Pem,
          signature: signResult.sign,
        },
      });
      if (Array.isArray(res.data?.statusList) && res.data?.statusList.length) {
        res.data.statusList.forEach((st: any) => {
          st.errorCode && ToastService.showError(st.errorText || st.errorCode);
        });
      }
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  /**
   * Метод для удаления ошибочных ЭСФ
   */
  const actionDeleteFailed = async (ids: Array<string>) => {
    let hashRes;
    let hashData;

    //Получение хеша
    try {
      hashRes = await mutationInvoiceListHash({
        body: { list: JSON.stringify(ids), localTime: formatFromDate(new Date()) },
      });
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }

    hashData = await solveHashData(hashRes.data);

    //Подпись
    const signResult = await auth.getSignature(hashData);

    //Отправка данных
    try {
      const res = await mutationInvoiceDeleteFailed({
        body: {
          list: JSON.stringify(ids),
          certificate: signResult.cert.base64Pem,
          signature: signResult.sign,
        },
      });
      if (Array.isArray(res.data?.statusList) && res.data?.statusList.length) {
        res.data.statusList.forEach((st: any) => {
          st.errorCode && ToastService.showError(st.errorCode);
        });
      }
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      throw error;
    }
  };

  const solveHashData = async (resData: any) => {
    let hash: any;
    if (resData?.hash) {
      hash = resData.hash;
    } else {
      hash = [];
      let errorHashes: any[] = [];
      Object.keys(resData).forEach((key) => {
        if (resData[key]?.errors?.length) {
          errorHashes.push(resData[key]);
        } else {
          hash.push({ id: key, hash: resData[key]?.hash });
        }
      });
      console.log({ hash, errorHashes });
      // Если есть ошибки в хешах, то уведомляем пользователя
      if (errorHashes.length) {
        const errorText = hash.length ? loc('esf.not_all_pass') : loc('esf.not_pass');
        await dialog.showAlert({
          content: (
            <Fragment>
              <div>{errorText}:</div>
              <ul>
                {errorHashes.map((errorHash, hIndex) => {
                  if (Array.isArray(errorHash.errors)) {
                    return errorHash.errors.map((error: any, eIndex: number) => (
                      <li key={`${hIndex}-${eIndex}`}>{error.text}</li>
                    ));
                  }
                  return null;
                })}
              </ul>
            </Fragment>
          ),
          center: true,
        });
        throw errorText;
      }
    }
    return hash;
  };

  // Запрашиваем данные по связаным ЭСФ
  const getRelatedData = async (num: string, date: string) => {
    const relatedData: EsfRelatedData = { state: false, docs: [] };
    const relatedInvoices = await queryInvoiceActiveRelatedInvoices({
      num,
      date,
    });
    if (Array.isArray(relatedInvoices?.data) && relatedInvoices.data.length) {
      relatedInvoices.data.forEach((inv: any) => {
        if (inv?.id && inv?.num && inv?.date) {
          relatedData.docs.push({
            id: inv.id,
            num: inv.num,
            date: inv.date,
          });
        }
      });
      if (relatedData.docs.length) {
        relatedData.state = true;
      }
    }
    return relatedData;
  };

  const showSignDialog = (props: EsfSignDialogState) => {
    setDialogState(props);
    return new Promise<any>((resolve, reject) => {
      dialogResolveRef.current = { resolve, reject };
    });
  };

  const onDialogClose = useCallback(() => {
    setDialogState({});
    if (typeof dialogResolveRef.current?.reject === 'function') {
      dialogResolveRef.current?.reject(null);
    }
    dialogResolveRef.current = null;
  }, []);

  const onDialogSubmit = async (values: any) => {
    console.log('onDialogSubmit', values);
    // const reason = `${values.section || ''}${values.reason ? `: ${values.reason}` : ''}`;
    if (typeof dialogResolveRef.current?.resolve === 'function') {
      dialogResolveRef.current?.resolve(values);
    }
    setDialogState({});
    dialogResolveRef.current = null;
  };

  const solveReasonText = (reason: string, section: string) => `${section || ''}${reason ? `: ${reason}` : ''}`;

  return {
    dialogState,
    onDialogClose,
    onDialogSubmit,
    actionCreate,
    actionSend,
    actionDeleteFailed,
    actionRevokeDecline,
  };
};
