import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import s from './SettingsVstore.module.scss';
import { FormFieldT2, FormRadioT2, Loader, PageTitleT2 } from 'ui';
import { Form, Formik, FormikContextType, FormikHelpers } from 'formik';
import * as yup from 'yup';
import { FormikOnChange } from 'common/form';
import { useDataQuery } from 'rest';
import { queryVstoreUserSettingsFind, queryVstoreUserSettingsHash } from './api';
import { mutVstoreUserSettingsSave } from './api/mutVstoreUserSettingsSave';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { useStore } from 'common/hooks';

const schema = yup.object({});

type Props = {
  className?: string;
};

/**
 * Страница НП "Настройки" -> "Прием данных на ВС"
 * /settings-vs
 */
export const SettingsVstore: React.FC<Props> = ({ className }) => {
  const { locale, auth } = useStore();
  const { loc } = locale;

  const [sending, setSending] = useState(false);

  const { data, refetch } = useDataQuery(queryVstoreUserSettingsFind);

  const initialValues = useMemo(() => {
    return {
      dtZvtSettings: data?.dtZvtSettings,
      sntSettings: data?.sntSettings,
      sntTransferSettings: data?.sntTransferSettings,
    };
  }, [data]);

  const submitHandler = async (values: any, actions: FormikHelpers<any>) => {
    console.log(values);
  };

  const onFormChange = async (newContext: FormikContextType<any>, oldContext: FormikContextType<any>) => {
    if (oldContext.values !== newContext.values && newContext.dirty) {
      setSending(true);
      try {
        const hash = await queryVstoreUserSettingsHash({ ...newContext.values });
        const signData = await auth.getSignature(hash.data);
        console.log(signData);
        await mutVstoreUserSettingsSave({
          body: {
            jsonBody: JSON.stringify({
              xmlBody: hash.data,
              signature: signData.sign,
              signatureType: 'COMPANY',
              certificate: signData.cert.base64Pem,
            }),
          },
        });
        refetch();
      } catch (error) {
        const message = solveErrorMessage(error);
        if (!(message === 'action.canceled' || message === 'canceled by user')) {
          ToastService.showError(solveErrorMessage(error));
        }
        newContext.resetForm();
      } finally {
        setSending(false);
      }
    }
  };

  return (
    <div className={clsx(className, s.wrapper)}>
      <PageTitleT2>{loc('settings.moveVsTitle')}</PageTitleT2>
      {!data ? (
        <Loader />
      ) : (
        <Formik initialValues={initialValues} onSubmit={submitHandler} validationSchema={schema} enableReinitialize>
          {({ values }) => (
            <Form className={s.form}>
              <p className={s.subtitle}>{loc('settings.moveVsZvt')}</p>
              <FormFieldT2
                name="dtZvtSettings"
                label={loc('settings.moveVsZvtAll')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="PROCESS_ALL" />
              </FormFieldT2>
              <FormFieldT2
                name="dtZvtSettings"
                label={loc('settings.moveVs')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="USE_VSTORE_FLAG" />
              </FormFieldT2>
              <p className={s.subtitle}>{loc('settings.moveVsImport')}</p>
              <FormFieldT2
                name="sntSettings"
                label={loc('settings.moveSnt')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="PROCESS_ALL" />
              </FormFieldT2>
              <FormFieldT2
                name="sntSettings"
                label={loc('settings.moveSnt2')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="USE_VSTORE_FLAG" />
              </FormFieldT2>

              <p className={s.subtitle}>{loc('settings.sntTransferSettings')}</p>
              <FormFieldT2
                name="sntTransferSettings"
                label={loc('settings.sntTransferSettingsAll')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="PROCESS_ALL" />
              </FormFieldT2>
              <FormFieldT2
                name="sntTransferSettings"
                label={loc('settings.sntTransferSettings2')}
                direction="line-reverse"
                readOnly={sending}
                fast={false}
              >
                <FormRadioT2 radioValue="USE_VSTORE_FLAG" />
              </FormFieldT2>

              <FormikOnChange onChange={onFormChange} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
