export const preproccessUACriteria = (filter: any) => {
  const parsedIpAdresses = parseIpAdresses(filter.ipAddresses);
  return {
    ...filter,
    ipAddresses: parsedIpAdresses.length ? parsedIpAdresses : undefined,
  };
};

export const parseIpAdresses = (ipAddresses: string): string[] => {
  return typeof ipAddresses === 'string'
    ? ipAddresses
        .split(/[\s,]+/)
        .filter(Boolean)
        .slice(0, 100)
    : [];
};
