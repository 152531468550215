import React, { useEffect, useMemo, useRef } from 'react';
import { FormikContextType, useFormikContext } from 'formik';
import { debounce } from 'common/utils';

type Props = {
  onChange: (newContext: FormikContextType<any>, oldContext: FormikContextType<any>) => void;
  debounceTimer?: number;
};

export const FormikOnChange: React.FC<Props> = ({ onChange, debounceTimer = 0 }) => {
  const context = useFormikContext<any>();

  const oldContext = useRef<FormikContextType<any> | null>(null);
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const debouncedOnChange = useMemo(
    () =>
      debounce(
        (newContext: FormikContextType<any>, oldContext: FormikContextType<any>) =>
          onChangeRef.current(newContext, oldContext),
        debounceTimer
      ),
    [] // eslint-disable-line
  );

  useEffect(() => {
    if (oldContext.current?.values !== context.values) {
      if (oldContext.current) {
        debounceTimer ? debouncedOnChange(context, oldContext.current) : onChange(context, oldContext.current);
      }
      oldContext.current = context;
    }
  }, [context, onChange]); // eslint-disable-line
  return null;
};
