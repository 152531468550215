import { clearEmptyFields } from 'common/utils';
import { convertToFormDataDeep, DataQueryFunction, httpClient, toPaginationParams } from 'rest';

export const queryAdminSntRegistrationOverdue: DataQueryFunction = async (variables: any) => {
  const { filter, pagination } = variables || {};

  const formData = convertToFormDataDeep({
    criteria: JSON.stringify(clearEmptyFields(filter)),
    ...toPaginationParams(pagination),
  });

  const res = await httpClient.post(`/admin/snt/registrationOverdue`, formData);
  if (res.data?.success === false || !res.data) {
    throw res;
  } else {
    return res;
  }
};
