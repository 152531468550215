import React from 'react';
import { toast, Slide, ToastOptions } from 'react-toastify';
import { Toast } from 'ui/common/components/toast/Toast';
import './ToastifyStyles.scss';

export type ToastServiceShowOptions = Omit<ToastOptions, 'type'> & { variant?: 'default' | 'flat' };

export abstract class ToastService {
  static config: ToastOptions = {
    position: toast.POSITION.BOTTOM_RIGHT,
    hideProgressBar: true,
    draggable: false,
    transition: Slide,
    closeButton: false,
    autoClose: 6000,
    icon: false,
  };

  static show(type: 'error' | 'success' | 'warning' | 'info', message: string, options: ToastServiceShowOptions = {}) {
    const { variant, ...rest } = options;
    if (!message) return;
    toast(<Toast message={message} type={type} variant={variant} />, { ...rest, type });
  }

  static showError = this.show.bind(this, 'error');
  static showInfo = this.show.bind(this, 'info');
  static showWarning = this.show.bind(this, 'warning');
  static showSuccess = this.show.bind(this, 'success');

  static reset = () => toast.dismiss();
}
