import React from 'react';
import clsx from 'clsx';
import s from './EaecHistoryModal.module.scss';
import { ModalContent, ModalHeader } from 'services/modal';
import { Loader, Table } from 'ui/common';
import { LocaleStore, useLocale } from 'services/l10n';
import { useDataQuery } from 'rest';
import { ModalChildrenProps } from 'services/modal/types';
import { queryAdminEecHistory } from '../api';

const getRowId = (row: any) => row?.id;

type Props = ModalChildrenProps & {
  className?: string;
  docId: string;
};

const getColumns = ({ loc, choose }: LocaleStore) => [
  {
    name: 'date',
    label: loc('eaecActions.statusDate'),
    style: {
      width: '50%',
    },
  },
  {
    name: 'status',
    label: loc('common.labels.status'),
    getContent: (row: any) => (row.status ? loc(`eaecActions.exStatus.${row.status}`) : ''),
    style: {
      width: '50%',
    },
  },
];

export const EaecHistoryModal: React.FC<Props> = ({ className, docId }) => {
  const locale = useLocale();
  const { loc } = locale;
  const { data } = useDataQuery(queryAdminEecHistory, { docId });

  const columns = getColumns(locale);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('eaecActions.statusHistory')}</ModalHeader>
      <ModalContent className={s.content}>
        {!data ? <Loader /> : <Table columns={columns} rows={data.rows} variant="clean" getRowId={getRowId} />}
      </ModalContent>
    </div>
  );
};
