import { DT_FORMAT } from './format';
import {
  differenceInDays,
  endOfMonth,
  endOfYear,
  format,
  formatISO,
  isValid,
  parse,
  parseISO,
  startOfMonth,
  startOfYear,
} from 'date-fns';
import { kk, ru } from 'date-fns/locale';

export const formatToDate = (value: string | undefined, from: string = DT_FORMAT.DEFAULT_DATE): Date | undefined => {
  let date: Date | undefined = undefined;
  if (value) {
    try {
      if (from === DT_FORMAT.ISO) {
        date = parseISO(value);
      } else {
        date = parse(value, from, new Date());
      }
    } catch (error) {
      console.error('formatToDate:error', error);
    }
  }
  return isValid(date) ? date : undefined;
};

export const formatFromDate = (date: Date | undefined, to: string = DT_FORMAT.DEFAULT_DATE, lang?: string): string => {
  let result: string = '';
  if (date) {
    try {
      if (to === DT_FORMAT.ISO) {
        result = formatISO(date);
      } else {
        result = format(date, to, {
          locale: lang === 'kk' ? kk : ru,
        });
      }
    } catch (error) {
      console.error('formatFromDate:error', error);
    }
  }
  return result;
};

export const formatDate = (
  value: string,
  to: string = DT_FORMAT.DEFAULT_DATE,
  from: string = DT_FORMAT.DEFAULT_DATE,
  lang?: string
): string => {
  return formatFromDate(formatToDate(value, from), to, lang);
};

export abstract class DT {
  static endOfMonth(value: string, format: string = DT_FORMAT.DEFAULT_DATE): string {
    const date = formatToDate(value, format);
    if (date) {
      return formatFromDate(endOfMonth(date), format);
    } else {
      return '';
    }
  }

  static startOfMonth(value: string, format: string = DT_FORMAT.DEFAULT_DATE): string {
    const date = formatToDate(value, format);
    if (date) {
      return formatFromDate(startOfMonth(date), format);
    } else {
      return '';
    }
  }

  static endOfYear(value: string, format: string = DT_FORMAT.DEFAULT_DATE): string {
    const date = formatToDate(value, format);
    if (date) {
      return formatFromDate(endOfYear(date), format);
    } else {
      return '';
    }
  }

  static startOfYear(value: string, format: string = DT_FORMAT.DEFAULT_DATE): string {
    const date = formatToDate(value, format);
    if (date) {
      return formatFromDate(startOfYear(date), format);
    } else {
      return '';
    }
  }

  static daysFromToday(value: string, format: string = DT_FORMAT.DEFAULT_DATE): number {
    const date = formatToDate(value, format);
    console.log(value, format, date);
    if (!isValid(date)) {
      return NaN;
    }
    const days = differenceInDays(new Date(), date || new Date());
    console.log(days);
    return days;
  }
}
