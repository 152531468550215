import React, { Fragment, useMemo } from 'react';
import clsx from 'clsx';
import s from './BioRiskyEditModal.module.scss';
import { ModalContent, ModalFooter, ModalHeader } from 'services/modal';
import { useLocale } from 'services/l10n';
import { ModalChildrenProps } from 'services/modal/types';
import { Form, Formik, FormikHelpers } from 'formik';
import { Button, FormField, FormInput, FormSelect, FormTextarea } from 'ui';
import { mutationAdminRiskyTaxpayerChangeStatus } from '../api';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { getRiskCategoryOptions, getTxpActiveOptions } from '../helpers';

type Props = ModalChildrenProps<boolean> & {
  className?: string;
  data?: any;
};

export const BioRiskyEditModal: React.FC<Props> = ({ className, onClose, data }) => {
  const locale = useLocale();
  const { loc } = locale;

  const sibmitHandler = async (values: any, form: FormikHelpers<any>) => {
    const { tin, isActive, currentRiskCategory } = values;
    try {
      const res = await mutationAdminRiskyTaxpayerChangeStatus({
        body: { tin, isActive, riskCategory: currentRiskCategory },
      });
      typeof onClose === 'function' && onClose(true);
      return res;
    } catch (error) {
      ToastService.showError(solveErrorMessage(error));
      form.setSubmitting(false);
    }
  };

  const txpStatusOptions = useMemo(() => getTxpActiveOptions(locale, false), [locale]);
  const riskCategoryOptions = useMemo(() => getRiskCategoryOptions(locale, false), [locale]);

  return (
    <div className={clsx(className, s.wrapper)}>
      <ModalHeader>{loc('bioRisky.edit')}</ModalHeader>
      <Formik<any> initialValues={data} onSubmit={sibmitHandler}>
        {({ values, isSubmitting, dirty }) => (
          <Form>
            <Fragment>
              <ModalContent className={s.content}>
                <FormField name="tin" label={loc('common.labels.iin')} readOnly>
                  <FormInput />
                </FormField>
                <FormField name="taxpayerName" label={loc('common.labels.companyName')} readOnly>
                  <FormTextarea rows={3} />
                </FormField>
                <FormField name="createDate" label={loc('bioRisky.addDate')} readOnly>
                  <FormInput />
                </FormField>
                <FormField name="isActive" label={loc('bioRisky.status')}>
                  <FormSelect options={txpStatusOptions} />
                </FormField>
                <FormField name="currentRiskCategory" label={loc('bioRisky.riskCategory')} readOnly>
                  <FormSelect options={riskCategoryOptions} />
                </FormField>
              </ModalContent>
              <ModalFooter>
                <Button
                  size="small"
                  variant="transparent"
                  onClick={() => typeof onClose === 'function' && onClose(false)}
                >
                  {loc('common.button.cancel')}
                </Button>
                <Button type="submit" size="small" disabled={!dirty} loading={isSubmitting}>
                  {loc('common.button.save')}
                </Button>
              </ModalFooter>
            </Fragment>
          </Form>
        )}
      </Formik>
    </div>
  );
};
