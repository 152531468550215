import { convertToFormDataDeep } from 'rest/helpers';
import { httpClient } from '../../httpClient';
import { DataQueryFunction } from '../../types';

export const queryTaxpayerAutocompleteByCert: DataQueryFunction = async ({ cert }) => {
  const formData = convertToFormDataDeep({ cert });

  const res = await httpClient.post(`/taxpayer/autocompleteByCert`, formData);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
