import React, { useMemo } from 'react';
import './Select.scss';
import ReactAsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { components as InnerComponents, InputActionMeta } from 'react-select';
import { UITheme } from 'ui/common/types';
import { AutocompleteInputWithPattern } from './AutocompleteInputWithPattern';
import { isEmpty } from 'common/utils';

export type AutocompleteProps = {
  value?: any;
  inputValue?: string;
  onChange?: (value: any) => void;
  onInputChange?: (value: string, actionMeta: InputActionMeta) => void;
  onBlur?: (value: any) => void;
  variant?: 'default' | 'product-table' | 'mui';
  className?: string;
  options?: Array<any>;
  placeholder?: string;
  loadOptions?: (value: string) => Promise<any[]>;
  defaultOptions?: boolean;
  getOptionLabel?: (option: any) => any;
  getSingleValue?: (data: any) => any;
  filterOption?: (option: any, rawInput: string) => boolean;
  isDisabled?: boolean;
  readOnly?: boolean;
  noOptionsMessage?(obj: { inputValue: string }): string | null;
  fullWidth?: boolean;
  components?: any;
  error?: boolean;
  theme?: UITheme;
  inputPattern?: RegExp;
  menuWidth?: number | string;
  /**
   * используется только в варианте 'mui'
   */
  label?: string;
};

export const Autocomplete: React.FC<AutocompleteProps> = ({
  className,
  options,
  variant = 'default',
  value = null,
  inputValue,
  loadOptions = () => {},
  defaultOptions,
  getSingleValue = (opt) => opt?.value,
  filterOption,
  noOptionsMessage = () => null,
  onChange,
  onInputChange,
  fullWidth = true,
  components,
  placeholder = '',
  error,
  theme = 'T1',
  isDisabled,
  readOnly,
  inputPattern,
  menuWidth = '100%',
  ...rest
}) => {
  const onChangeOption = (opt: any) => typeof onChange === 'function' && onChange(opt);

  const SingleValue = useMemo(
    () =>
      ({ children, ...props }: any) => {
        // console.log('innerProps', props);
        return <InnerComponents.SingleValue {...props}>{getSingleValue(props.data)}</InnerComponents.SingleValue>;
      },
    [getSingleValue]
  );

  const hasValue: boolean = !isEmpty(value);

  return (
    <ReactAsyncSelect
      classNamePrefix={'select'}
      {...rest}
      value={value}
      inputValue={inputValue}
      filterOption={filterOption}
      className={clsx(className, 'select', 'select--autocomplete', `select${theme}`, {
        fullWidth,
        'select--error control-error': error,
        productTable: variant === 'product-table',
        hasValue,
      })}
      onChange={onChangeOption}
      onInputChange={onInputChange}
      loadOptions={loadOptions}
      isSearchable={true}
      defaultOptions={defaultOptions}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage}
      components={{
        SingleValue,
        IndicatorsContainer: () => <></>,
        ...(inputPattern
          ? {
              Input: (props: any) => <AutocompleteInputWithPattern {...props} pattern={inputPattern} maxLength={12} />,
            }
          : {}),
        ...components,
      }}
      isClearable
      backspaceRemovesValue
      isDisabled={isDisabled || readOnly}
      styles={{
        menu: (base) => ({ ...base, '--menu-width': typeof menuWidth === 'number' ? `${menuWidth}px` : menuWidth }),
      }}
    />
  );
};
