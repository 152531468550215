import { Journal } from 'common/components/journal';
import { JournalButton } from 'common/components/journal/types/JournalButton';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useDataQuery } from 'rest';
import { useLocale } from 'services/l10n';
import { DT_FORMAT, formatFromDate } from 'services/date-time';
import { getAdminMessageJournalColumns } from './getAdminMessageJournalColumns';
import { queryAdminMessagesExport, queryAdminMessagesSearch } from '../api';
import { AdminMessageJournalFilters } from './AdminMessageJournalFilters';
import { ToastService } from 'services/toasts';
import { solveErrorMessage } from 'common/form/helpers';
import { useCheckAdminPermission } from 'common/hooks';
import { ADMIN_PERMISSION } from 'common/constants';
import { AdminMessagePage } from '../admin-message-page/AdminMessagePage';
import { subMinutes } from 'date-fns';
import { SessionStorageService } from 'services/storage';

const initialPagination = { rows: 10 };

const getRowId = (row: any) => row.id;

const filterStorageKey = 'adminMessagesFilter';

type Props = {};

export const AdminMessageJournal: React.FC<Props> = () => {
  const locale = useLocale();
  const { loc } = locale;

  const initialFilter = useMemo(
    () =>
      SessionStorageService.get(filterStorageKey) || {
        sendDateTo: formatFromDate(new Date(), DT_FORMAT.DEFAULT_DATE_TIME),
        sendDateFrom: formatFromDate(subMinutes(new Date(), 10), DT_FORMAT.DEFAULT_DATE_TIME),
      },
    []
  );

  const defaultFilter = {};

  useCheckAdminPermission(ADMIN_PERMISSION.MESSAGES_VIEW);

  const [nestedPage, setNestedPage] = useState<{
    opened: boolean;
    row: any;
  }>({
    opened: false,
    row: undefined,
  });

  const columns = useMemo(() => getAdminMessageJournalColumns({ locale }), [locale]);

  const queryProps = useDataQuery(queryAdminMessagesSearch, {
    filter: initialFilter,
    pagination: initialPagination,
  });

  const exportJournal = useCallback(
    async (format: 'XLSX' | 'CSV') => {
      try {
        await queryAdminMessagesExport({
          filter: queryProps.filter,
          format,
        });
      } catch (error) {
        ToastService.showError(solveErrorMessage(error));
      }
    },
    [queryProps.filter]
  );

  const closeNestedPage = useCallback(() => {
    setNestedPage({ opened: false, row: null });
  }, []);

  const headerButtons: Array<JournalButton> = useMemo(
    () => [
      {
        id: 10,
        label: loc('common.button.exportExcel'),
        onClick: () => exportJournal('XLSX'),
      },
      {
        id: 11,
        label: loc('common.button.exportCsv'),
        onClick: () => exportJournal('CSV'),
      },
    ],
    [loc, exportJournal]
  );

  const actionButtons: Array<JournalButton> = [
    {
      id: 1,
      label: loc('common.button.view'),
      onClick: (selection: Array<any>) =>
        // setModal({ opened: true, row: selection[0] }),
        setNestedPage({ opened: true, row: selection[0] }),
    },
  ];

  return (
    <Fragment>
      <Journal
        pageTitle={loc('adminMessages.journalTitle')}
        columns={columns}
        selectable
        queryProps={queryProps}
        filterComponent={AdminMessageJournalFilters}
        filterInitialOpened
        defaultFilter={defaultFilter}
        headerButtons={headerButtons}
        actionButtons={actionButtons}
        getRowId={getRowId}
        oneLine
        hidden={nestedPage.opened}
        filterStorageKey={filterStorageKey}
      />

      {nestedPage.opened ? <AdminMessagePage data={nestedPage.row} onClose={closeNestedPage} /> : null}
    </Fragment>
  );
};
