import { DataMutationFunction } from '../../types';
import { httpClient } from '../../httpClient';
import { convertToFormDataDeep } from 'rest/helpers';

export const mutationChangeEmail: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;
  const formData = convertToFormDataDeep(body);

  const res = await httpClient.put('/changeEmail', formData);
  if (res.data?.success) {
    return res;
  } else {
    throw res;
  }
};
