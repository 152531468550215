import { LEGACY_URL } from 'common/constants';
import { clearSlashes } from 'common/utils';

export const getLegasyUrl = (url: string): string => {
  return `${LEGACY_URL}/#!${url || ''}`;
};

/**
 * Альтернативный вариант формирования урла в старом дизайне, без хеша
 */
export const getLegasyUrlAlt = (url: string): string => {
  return `${LEGACY_URL}${url || ''}`;
};

export const getArmLegasyUrl = (pageName?: string): string => {
  const baseUrl = `${LEGACY_URL}/admin_index`;
  return pageName ? `${baseUrl}?page=${pageName}` : baseUrl;
};

export const getVStoreLegasyUrl = (pageName?: string): string => {
  const baseUrl = `${LEGACY_URL}/vstore`;
  return pageName ? `${baseUrl}?page=${pageName}` : baseUrl;
};

export function getResourceUrl(filePath: string) {
  return clearSlashes(`${LEGACY_URL}/${(window as any).Globals?.rootResourceUrl}/${filePath}`);
}
