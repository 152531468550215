import { FilterContentProps, FormCountrySelect, FormDoubleDatePicker, FormListUploader } from 'common/components';
import React, { useMemo } from 'react';
import { useLocale } from 'services/l10n';
import { Field, FormField, FormInput, FormSelect } from 'ui/common';
import { getEaecActiveOptions, getEaecCodeOptions, getEaecDocumentInputStatusOptions } from '../helpers';

type Props = FilterContentProps;

export const EaecDocumentsInputFilters: React.FC<Props> = ({ form, classNames }) => {
  const locale = useLocale();
  const { loc } = locale;

  const { gridWrapper, gridSection, gridSectionExpandalbe, gridPaddingContainer } = classNames || {};

  const activeOptions = useMemo(() => getEaecActiveOptions(locale), [locale]);
  const codeOptions = useMemo(() => getEaecCodeOptions(locale), [locale]);
  const statusOptions = useMemo(() => getEaecDocumentInputStatusOptions(locale), [locale]);

  return (
    <div className={gridWrapper}>
      <div className={gridSection}>
        <div className={gridPaddingContainer}>
          <Field label={loc('eaecActions.documentCreateDate')}>
            <FormDoubleDatePicker nameFrom="fromCreateDate" nameTo="toCreateDate" maxDate={new Date()} />
          </Field>
        </div>
        <div className={gridPaddingContainer}>
          <FormField name="eaecCountryCode" label={loc('eaecActions.eaecCountry')}>
            <FormCountrySelect onlyEaec excludeKZ />
          </FormField>
        </div>
        <div>
          <FormField name="documentRegistrationNumber" label={loc('eaecActions.documentRegistrationNumber')}>
            <FormInput />
          </FormField>
          <FormListUploader name="documentRegistrationNumbers" fileType="xlsx" />
        </div>
        <div>
          <FormField name="customerTin" label={loc('eaecActions.taxpayerId')}>
            <FormInput />
          </FormField>
          <FormListUploader name="customerTins" fileType="xlsx" />
        </div>
      </div>
      <div className={gridSectionExpandalbe}>
        <div className={gridSection}>
          <FormField name="envelopeCode" label={`${loc('eaecActions.envelopeCode')}:`}>
            <FormSelect options={codeOptions} />
          </FormField>
          <FormField name="messageId" label={loc('eaecActions.messageId')}>
            <FormInput />
          </FormField>
          <FormField name="zvtSenderCountry" label={loc('eaecActions.zvtCountry')}>
            <FormCountrySelect />
          </FormField>
          <FormField name="isActive" label={`${loc('eaecActions.active')}:`}>
            <FormSelect options={activeOptions} />
          </FormField>

          <div>
            <FormField name="senderTin" label={loc('eaecActions.eaecTaxpayerId')}>
              <FormInput />
            </FormField>
            <FormListUploader name="senderTins" fileType="xlsx" />
          </div>
          <div>
            <FormField name="tnvedCode" label={loc('common.labels.tnvedCode')}>
              <FormInput />
            </FormField>
            <FormListUploader name="tnvedCodes" fileType="xlsx" />
          </div>
          <div>
            <FormField name="dtNumber" label={loc('eaecActions.dtRegistrationNumber')}>
              <FormInput />
            </FormField>
            <FormListUploader name="dtNumbers" fileType="xlsx" />
          </div>
          <div></div>
          <FormField name="status" label={`${loc('eaecActions.eaecStatus')}:`}>
            <FormSelect options={statusOptions} />
          </FormField>
          <Field label={loc('eaecActions.writeOutDate')}>
            <FormDoubleDatePicker nameFrom="fromCreateInvoiceDate" nameTo="toCreateInvoiceDate" />
          </Field>
        </div>
      </div>
    </div>
  );
};
