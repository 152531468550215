import React, { useEffect, useRef } from 'react';
import { components, InputProps } from 'react-select';

type Props = InputProps<any, true> & {
  pattern: RegExp;
  maxLength: number;
};

export const AutocompleteInputWithPattern: React.FC<Props> = ({ pattern, maxLength, ...rest }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const handler = (e: any) => {
      // console.log(e.target?.value, e);
      if ((e.data || '').match(pattern)) {
        console.log('blocked');
        e.preventDefault();
      }
    };
    if (inputRef.current) {
      inputRef.current.addEventListener('beforeinput', handler);
      inputRef.current.maxLength = maxLength;
    }
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener('beforeinput', handler);
      }
    };
  }, []); // eslint-disable-line

  return (
    <components.Input
      {...rest}
      innerRef={(el) => {
        if (!inputRef.current) {
          inputRef.current = el;
        }
        if (rest.innerRef) rest.innerRef(el);
      }}
    />
  );
};

// /[^0-9]/g
