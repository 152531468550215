import { DataMutationFunction, httpClient } from 'rest';

export const mutationAdminGovInstAdd: DataMutationFunction = async (variables = {}): Promise<any> => {
  const { body = {} } = variables;

  // if (process.env.NODE_ENV === 'development') {
  //   await delay(1500);
  //   return { data: { success: false } };
  // }

  const res = await httpClient.post('/admin/governmentInstitution/add', body);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
