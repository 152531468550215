import { DataQueryFunction, httpClient } from 'rest';

export const queryAssuranceHistory: DataQueryFunction = async (variables: any) => {
  const { passportId } = variables || {};

  const res = await httpClient.get(`/assurance/history/${passportId}`);
  if (res.data?.success === false) {
    throw res.data;
  } else {
    return res;
  }
};
