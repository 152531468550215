import React, { useCallback, useEffect, useState } from 'react';
import { queryTaxpayerStores, queryVstoreTaxpayerStores } from 'rest';
import { useLocale } from 'services/l10n';
import { FormSelect, FormSelectProps, UITheme } from 'ui/common';
import { AppMode } from 'common/constants';

export const getTaxpayerStoresOptionLabel = (data: any) => {
  return data.label ?? `${data.id} - ${data.storeName}`;
};

export const getTaxpayerStoresOptionValue = (data: any) => {
  return data.value ?? data.id;
};

type Props = FormSelectProps & {
  appMode: AppMode;
  theme?: UITheme;
  tin?: string;
  status?: 'VALID';
  isShowLesseeStores?: boolean;
};

export const FormTaxpayerStoreSelect: React.FC<Props> = ({
  appMode,
  theme,
  tin,
  status,
  isShowLesseeStores,
  ...rest
}) => {
  const locale = useLocale();
  const { loc } = locale;

  const [options, setOptions] = useState<any[]>([]);

  const noOptionsMessage = useCallback(() => loc('common.option.noOptions'), [loc]);

  useEffect(() => {
    const fetchOptions = async () => {
      const { loc } = locale;
      const emptyOptions = [
        {
          value: '',
          label: loc('common.option.notSelected'),
        },
      ];
      let res: any;
      if (appMode === AppMode.ARM && tin?.length === 12) {
        res = await queryTaxpayerStores({ tin });
      }
      if (appMode === AppMode.TAXPAYER) {
        res = await queryVstoreTaxpayerStores({ tin, status, isShowLesseeStores });
      }
      setOptions([...emptyOptions, ...(Array.isArray(res?.data) ? res.data : [])]);
    };

    fetchOptions();
  }, [tin, status, isShowLesseeStores, appMode, locale]);

  return (
    <FormSelect
      {...rest}
      options={options}
      isSearchable={true}
      getOptionLabel={getTaxpayerStoresOptionLabel}
      getOptionValue={getTaxpayerStoresOptionValue}
      noOptionsMessage={noOptionsMessage}
      theme={theme}
      isDisabled={options.length < 2}
    />
  );
};
